import React from 'react';

export const DownloadIcon = ({ width = '32', height = '32' }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 32 32">
    <g data-name="Group 8372" transform="translate(-1121 -317)">
      <rect
        data-name="Rectangle 17010"
        width="32"
        height="32"
        rx="16"
        transform="rotate(180 576.5 174.5)"
        style={{ fill: '#c6ffef' }}
      />
      <path
        data-name="Path 16224"
        d="m5.634 4.79 1.922-1.931v8.052a.84.84 0 1 0 1.679 0V2.859l1.923 1.931A.843.843 0 1 0 12.35 3.6L8.992.239a.868.868 0 0 0-1.192 0L4.441 3.6a.843.843 0 0 0 1.193 1.19zm10.319 3.6a.84.84 0 0 0-.84.84v5.038a.84.84 0 0 1-.84.84H2.519a.84.84 0 0 1-.84-.84V9.231a.84.84 0 1 0-1.679 0v5.038a2.519 2.519 0 0 0 2.519 2.519h11.754a2.519 2.519 0 0 0 2.519-2.519V9.231a.84.84 0 0 0-.84-.839z"
        transform="rotate(180 572.843 170.697)"
        style={{ fill: '#23c3a4' }}
      />
    </g>
  </svg>
);
