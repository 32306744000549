const teamActivityFields = [
  {
    label: 'Movement',
    name: 'movement',
    required: false,
    type: 'string',
  },
  {
    label: 'Customer Name',
    name: 'customerName',
    required: false,
    type: 'string',
  },
  {
    label: 'ID',
    name: 'id',
    required: false,
    type: 'string',
  },
  {
    label: 'Note',
    name: 'note',
    required: false,
    type: 'string',
  },
];

const movements = [
  {
    teamMember: 'Adam W',
    movement: 'Add money',
    customerName: 'Miranda Prats',
    id: '4ec8-9fce-6da7768a8ac',
    note: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut sit amet neque semper, lobortis purus ac, dapibus enim. Morbi mollis tempus gravida. Cras fermentum convallis mauris et congue. Sed eu mauris vel velit fringilla finibus. Donec et diam ut leo faucibus ultricies non vitae nisi. Aenean non ex tortor',
  },
  {
    teamMember: 'Rose McLaren',
    movement: 'Add money',
    customerName: 'Pere Prim',
    id: '70f4b19f-c1ea-4ec8',
    note: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut sit amet neque semper, lobortis purus ac, dapibus enim. Morbi mollis tempus gravida. Cras fermentum convallis mauris et congue. Sed eu mauris vel velit fringilla finibus. Donec et diam ut leo faucibus ultricies non vitae nisi. Aenean non ex tortor',
  },
  {
    teamMember: 'Eva C',
    movement: 'Add money',
    customerName: 'Linda Bella',
    id: '70f4b19f-6da7768a8',
    note: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut sit amet neque semper, lobortis purus ac, dapibus enim. Morbi mollis tempus gravida. Cras fermentum convallis mauris et congue. Sed eu mauris vel velit fringilla finibus. Donec et diam ut leo faucibus ultricies non vitae nisi. Aenean non ex tortor',
  },
];

const TeamMember = 'Andrea Stephen';

export { movements, teamActivityFields, TeamMember };
