import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
  label: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingRight: '16px',
  },
  sideLabel: {
    display: 'flex',
    flexDirection: 'row',
    gap: '8px',
    alignItems: 'center',
  },
  userLogsContainer: {
    margin: '12px 16px 24px 16px',
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
    fontSize: '14px',
    height: '228px',
    overflowY: 'scroll',
    width: 'inherit',
  },
  dateValueRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    borderBottom: '1px solid #f2f2f4',
    // margin: '8px 32px 0px 16px',
    margin: '10px 16px 20px 16px',
  },
  userLogRow: {
    display: 'flex',
    flexDirection: 'row',
    gap: '8px',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingBottom: '8px',
    borderBottom: '1px solid #f2f2f4',
  },
  bulletPoint: {
    width: '8px',
    height: '8px',
    borderRadius: '16px',
    backgroundColor: '#1fe3ac',
  },
  rhsRow: {
    fontSize: '16px',
    fontWeight: '500'
  },
  lhsRow: {
    color: '#a5a6ae'
  },
  paginationContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: '8px',
    justifyContent: 'center',
    alignItems: 'center',
  },
  arrowIcon: {
    height: '24px',
    width: '24px',
  },
  paginationBtn: {
    height: '24px',
    width: '24px',
    borderRadius: '24px',
    '&:hover': {
      backgroundColor: 'white',
    },
  },
}));
