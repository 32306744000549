import { makeStyles } from '@mui/styles';

import tickInputActive from '../../../../assets/icons/tick-input-active-icon.svg';
import tickInputInactive from '../../../../assets/icons/tick-input-icon.svg';

export const useStyles = makeStyles(theme => ({
  sectionContainer: {
    background: theme.palette.white,
    borderRadius: '0px 16px 16px 16px',
    margin: '16px 20px',
    minHeight: '544px',
    paddingBottom: '47px',
    fontFamily: theme.typography.default,
    fontWeight: 'bold',
    color: theme.palette.icons.nav,
    overflowY: "hidden",
    '& > aside': {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-end',
      gap: '50px',
      marginRight: '8px',
    },
    '& hr': {
      border: 'none',
      borderTop: '1px dotted #a5a6ae',
      height: '1px',
      width: '95%',
      margin: 'auto',
      marginTop: '40px',
    },
  },
  subAsideContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: '11px',
    fontSize: '18px',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginRight: '48px',
    marginBottom: '23px',
    marginTop: '-8px',
    position: 'relative',
    fontWeight: '500',
    cursor: 'pointer',
  },
  templatesUnderline: {
    position: 'absolute',
    width: '169.5px',
    height: '3px',
    borderTop: '3px solid #19365e',
    bottom: '-25px',
    right: '-13px',
    zIndex: '1',
  },
  feesUnderLine: {
    position: 'absolute',
    width: '169.5px',
    height: '3px',
    borderTop: '3px solid #19365e',
    bottom: '-25px',
    right: '-31px',
    zIndex: '1',
  },
  underLineDisabled: {
    borderTop: '3px solid #a5a6ae',
  },
  headerContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: '16px',
    paddingTop: '25px',
    fontSize: '24px',
  },
  horizRule: {
    margin: '0 24px',
    borderTop: '1px solid #e6e6e6',
  },
  feeInputRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  feesInputContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '40px',
    fontSize: '18px',
    fontWeight: 'normal',
    marginTop: '24px',
    '& input': {
      flexGrow: '1',
      height: '48px',
      borderRadius: '8px',
      border: '1px solid #e6e6e6',
      marginTop: '11px',
    },
    '& input + $valueRhs': {
      backgroundImage: `url(${tickInputInactive})`,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
    },
    '& input:focus + $valueRhs': {
      border: '2px solid #085ccc',
      backgroundImage: `url(${tickInputActive})`,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
    },
    '& input:focus + $valueRhsPercentage': {
      border: '2px solid #085ccc',
    },
  },
  valueContainer: {
    position: 'relative',
    marginLeft: '24px',
    '& input::-webkit-outer-spin-button, input::-webkit-inner-spin-button': {
      WebkitAppearance: 'none',
      margin: 0,
    },
    '& input[type=number]': {
      MozAppearance: 'textfield',
      fontSize: '20px',
      fontFamily: theme.typography.default,
      color: theme.palette.icons.nav,
      paddingLeft: '8px',
    },
  },
  valueRhs: {
    position: 'absolute',
    height: '48px',
    width: '48px',
    borderRadius: '0px 8px 8px 0px',
    background: theme.palette.transactions.background,
    border: '1px solid #e6e6e6',
    textAlign: 'center',
    top: '32.5px',
    right: '0px',
    padding: '8px',
  },
  valueRhsPercentage: {
    position: 'absolute',
    height: '48px',
    width: '48px',
    borderRadius: '0px 8px 8px 0px',
    background: theme.palette.transactions.background,
    border: '1px solid #e6e6e6',
    textAlign: 'center',
    top: '32.5px',
    right: '0px',
    padding: '8px',
    fontSize: '24px',
  },
  defaultBtnStyle: {
    borderRadius: '8px',
    border: 'none',
    height: '48px',
    width: '136px',
    fontSize: '18px',
    color: theme.palette.icons.nav,
    fontFamily: theme.typography.default,
    fontWeight: '500',
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flext-start',
    gap: '12px',
    alignItems: 'center',
    paddingLeft: '12px',
  },
  btnContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: '40px',
    marginTop: '57px',
    marginRight: '24px',
  },
  btnCancel: {
    backgroundColor: '#e2ecff',
  },
  btnSave: {
    backgroundColor: '#c6ffef',
  },
  saveAsTemplate: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    marginTop: '15px',
    marginRight: '24px',
  },
  templateSavedMsg: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    marginTop: '15px',
    marginRight: '24px',
    fontSize: '18px',
    animation: '$templateSavedMsgEffect 5000ms',
  },
  '@keyframes templateSavedMsgEffect': {
    '0%': {
      opacity: 0,
    },
    '100%': {
      opacity: 1,
    },
  },
  saveTemplateContent: {
    height: '48px',
    borderRadius: '8px',
    background: '#9fe3ff',
    display: 'flex',
    flexDirection: 'row',
    gap: '12px',
    paddingLeft: '12px',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    fontSize: '18px',
    '& input': {
      width: '176px',
      height: '32px',
      borderRadius: '8px',
      border: 'none',
      paddingLeft: '8px',
      margin: '0 8px 0 12px',
      fontSize: '18px',
      fontFamily: theme.typography.default,
      color: theme.palette.icons.nav,
    },
    '& button': {
      border: 'none',
      background: 'rgba(0,0,0,0)',
      fontSize: '18px',
      fontFamily: theme.typography.default,
      color: theme.palette.icons.nav,
      fontWeight: '500',
      marginRight: '24px',
      cursor: 'pointer',
    },
  },
  disabledTemplate: {
    background: '#f2f2f4',
    '& button': {
      color: '#a5a6ae',
      cursor: 'auto',
    },
  },
  disabledColor: {
    color: '#a5a6ae',
    cursor: 'auto',
  },
}));
