import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(theme => ({
  root: {},
  icon: {
    marginLeft: '-12px',
    display: 'flex',
    '&:svg': {
      width: '1.5rem',
    },
    '& .MuiSvgIcon-root': {
      width: '2.4rem',
      height: '2.4rem',
    },
  },
  adaptWidth: {
    width: '88px',
  },
  maxWidth: {
    width: '100%',
  },
  additional: {
    justifyContent: 'flex-start',
    gap: '8px',
    boxShadow: 'none',
    textTransform: 'capitalize',
    color: '#fff',
    fontWeight: 'bold',
    fontFamily: 'Gilroy, Roboto , Helvetica, Arial, sans-serif',
    borderRadius: '2rem',
    fontSize: '1.8rem',
  },
  primary: {
    backgroundColor: '#c6ffef',
    color: theme.palette.icons.nav,
    fontSize: '14px',
    width: '88px',
    height: '32px',
    fontWeight: '500',
    '&:hover': {
      backgroundColor: '#c6ffef',
      boxShadow: 'none',
    },
  },
  alert: {
    backgroundColor: theme.palette.error.main,
    '&:hover': {
      backgroundColor: theme.palette.error.light,
    },
    '&:active': {
      backgroundColor: theme.palette.error.dark,
    },
  },
  secondary: {
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.secondary.dark,
    '&:hover': {
      backgroundColor: theme.palette.greyMid.mid,
    },
    '&:active': {
      backgroundColor: theme.palette.greyMid.dark,
    },
  },
  warning: {
    backgroundColor: theme.palette.warning.main,
    '&:hover': {
      backgroundColor: theme.palette.warning.light,
    },
    '&:active': {
      backgroundColor: theme.palette.warning.dark,
    },
  },
}));
