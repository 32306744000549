const setDefaultValues = options => {
  const values = {};

  options.forEach(option => {
    values[option] = 'write';
  });

  return values;
};

export { setDefaultValues };
