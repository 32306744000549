import { Typography } from '@mui/material';
import React, { Fragment } from 'react';
import { uid } from 'react-uid';

import { PageMenu } from './page-menu';
import { ToolbarButtons } from './toolbar-buttons';

export const ToolbarContent = ({
  pagesProperties,
  entity,
  classes,
  onCreate,
  setActiveCards,
  activeCards,
}) => {
  const onOpenSelectedCard = card => {
    const newCards = [...activeCards];
    newCards.push(card);
    setActiveCards(newCards);
  };

  return pagesProperties.map(elem => {
    if (entity !== elem.entity) {
      return null;
    }

    return (
      <Fragment key={uid(elem.title)}>
        <div className={classes.iconTitleWrapper}>
          <Typography variant="h2" sx={{ color: '#19365e' }}>
            {elem.title}
          </Typography>
          {elem.title === 'Metrics Dashboard' && <Typography variant="h5">All Status</Typography>}
        </div>
        {elem?.menu && <PageMenu menu={elem.menu} onOpenSelectedCard={onOpenSelectedCard} />}
        {elem?.buttons && (
          <ToolbarButtons entity={entity} buttons={elem?.buttons ?? []} onCreate={onCreate} />
        )}
      </Fragment>
    );
  });
};
