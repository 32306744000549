import validator from 'validator';

export const getSortCodeFromIban = iban => {
  if (typeof iban === 'string' && validator.isIBAN(iban)) return iban.slice(8, 14);

  return '';
};

export const getAccountNoFromIban = iban => {
  if (typeof iban === 'string' && validator.isIBAN(iban)) return iban.slice(-8);

  return '';
};
