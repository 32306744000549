import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  formControl: {
    width: '100%',
    backgroundColor: theme.palette.white,
    '& .MuiSelect-select.MuiSelect-select': {
      backgroundColor: theme.palette.white,
      marginTop: '1rem',
      padding: '0 1rem 1rem',
    },
    '& .MuiInput-formControl': {
      border: `0.1rem solid ${theme.palette.secondary.dark}`,
      borderRadius: '0.4rem',
    },
    '& .MuiInput-underline:before': {
      display: 'none',
    },
  },
  cardNoShadow: {
    boxShadow: 'none',
    marginTop: '1em',
  },
  label: {
    fontWeight: 'bold',
    transform: 'translate(1rem, -1rem)',
  },
  icon: {
    cursor: 'pointer',
    color: theme.palette.greyMid.dark,
    position: 'absolute',
    right: 0,
    width: '2.7rem',
    height: '3rem',
    pointerEvents: 'none',
  },
  row: {
    marginBottom: '3.2rem',
  },
}));
