const fieldNamesEnum = Object.freeze({
  PROFITMARGINTYPE: 'profitMargin-type',
  PROFITMARGINVALUE: 'profitMargin-value',
  FEEPERCENTAGE: 'fee-percentage',
  FEEABSOLUTE: 'fee-absolute',
});

const fieldValuesEnum = Object.freeze({
  PERCENTAGE: 'percentage',
  ABSOLUTE: 'absolute',
});

const profitOptions = [
  { value: fieldValuesEnum.PERCENTAGE, label: 'Percentage' },
  { value: fieldValuesEnum.ABSOLUTE, label: 'Absolute (pips)' },
];

const transactionsFieldHeaders = [
  { label: 'Internal Id', key: 'InternalId' },
  { label: 'Sender', key: 'Sender' },
  { label: 'Type', key: 'Type' },
  { label: 'Beneficiary', key: 'Beneficiary' },
  { label: 'External User From', key: 'ExternalUserFrom' },
  { label: 'Status', key: 'Status' },
  { label: 'Origin Currency', key: 'Origin Currency' },
  { label: 'Dest Currency', key: 'Dest Currency' },
  { label: 'Sending Amount', key: 'Send Amount' },
  { label: 'Receiving Amount', key: 'Receiving Amount' },
  { label: 'Exchange Rate', key: 'Exchange Rate' },
  { label: 'Fee', key: 'Fee' },
  { label: 'Purpose', key: 'Purpose' },
  { label: 'Reference', key: 'Ref' },
];

const currenciesFieldHeaders = [
  { label: 'ID', key: 'ID' },
  { label: 'ISO', key: 'ISO' },
  { label: 'Status', key: 'Status' },
  { label: 'Name', key: 'Name' },
  { label: 'WalletBgColor', key: 'WalletBgColor' },
  { label: 'WalletTextColor', key: 'WalletTextColor' },
  { label: 'Created', key: 'Created' },
  { label: 'Updated', key: 'Updated' },
];

const customersFieldHeaders = [
  { label: 'Name', key: 'Name' },
  { label: 'Status', key: 'Status' },
  { label: 'Created', key: 'Created' },
  { label: 'Account Number', key: 'Account Number' },
  { label: 'Phone Number', key: 'Phone Number' },
  { label: 'Date of Birth', key: 'Date of Birth' },
  { label: 'Email', key: 'Email' },
];

// Back end 'refrence' spelt incorrectly yet to be corrected
const transactionSearchFieldsEnum = Object.freeze({
  name: 'Name',
  transactionId: 'Transaction ID',
  refrence: 'Reference',
  accountNumber: 'Account Number',
});

export {
  currenciesFieldHeaders,
  customersFieldHeaders,
  fieldNamesEnum,
  fieldValuesEnum,
  profitOptions,
  transactionSearchFieldsEnum,
  transactionsFieldHeaders,
};
