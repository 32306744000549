import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(theme => ({
  importButton: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '152px',
    height: '32px',
    border: '1px solid #1fe3ac',
    color: theme.palette.icons.nav,
    textTransform: 'none',
    borderRadius: '16px',
    marginRight: '0.8rem',
    marginLeft: '1rem',
    '& label:hover': {
      cursor: 'pointer',
    },
  },
  importIcon: {
    marginTop: '7px',
    marginLeft: '-4px',
  },
}));
