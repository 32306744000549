export const statusMap={
  before_pending: 'In Progress',
  error: 'Cancelled',
  expired: 'Cancelled',
  failed: 'Cancelled',
  cancelled: 'Cancelled',
  hold: 'Held',
  payment_failed: 'Cancelled',
  pending: 'In Progress',
  rejected: 'Cancelled',
  started: 'In Progress',
  success: 'Settled',
  settled: 'Settled',
  accepted: 'In Progress',
  executed: 'In Progress',
}


