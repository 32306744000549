/* eslint-disable no-restricted-syntax */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { entitiesEnum } from '../../../../constants';
import { graphqlApiDecorator } from '../../../../decorators';
import { queries } from '../../../../graphql';
import { saveEntity } from '../../../../services';
import { SmallSideRectangle } from '../../../icons';
import { PairsCancelIcon, PairsSaveIcon } from '../../../icons/pairs';
import { CurrenciesInputField } from '.';
import { useStyles } from './currencies.hooks';
import { LoadingModal, SavedModal, WarningModal } from './modal';

/**
 * Currencies sub component of SetupPage component. Makes use of redux to set the Currency Pairs obj
 * from user selection of sending and receiving currencies.
 * @param {Array} data array of pairs data to render of type Pair
 * @param {function} handleRefreshData this eventually calles the fetchEntity() in the useGraphList() custom hook
 */
export const Currencies = ({ data, handleRefreshData }) => {
  const [showSavedModal, setShowSavedModal] = useState(false);
  const [showWarningModal, setShowWarningModal] = useState(false);
  const [warningMessage, setWarningMessage] = useState('');
  const [currencies, setCurrencies] = useState({});
  const [loading, setLoading] = useState(false);
  const classes = useStyles();
  const currenciesDataObj = useSelector(state => state.pairsCurrenciesObj);
  const [currencyUpdateObj, setCurrencyUpdateObj] = useState({});
  const dispatch = useDispatch();

  const fetchCurrencies = async () => {
    const queryTitle = 'listCurrencys';

    try {
      const { items } = await graphqlApiDecorator(queries, queryTitle);
      const currenciesObj = {};
      items.forEach(item => {
        currenciesObj[item.id] = item.name;
      });
      setCurrencies(currenciesObj);
    } catch (err) {
      setWarningMessage(err.message);
      setShowWarningModal(true);
    }
  };

  useEffect(() => {
    fetchCurrencies();
  }, []);

  useEffect(() => {
    let oneSendingCurrencySelected = false;
    let oneReceivingCurrencySelected = false;

    if (currenciesDataObj['Sending Currency'] && currenciesDataObj['Sending Currency'].length === 1)
      oneSendingCurrencySelected = true;

    if (
      currenciesDataObj['Receiving Currency'] &&
      currenciesDataObj['Receiving Currency'].length === 1
    )
      oneReceivingCurrencySelected = true;

    if (
      oneSendingCurrencySelected &&
      oneReceivingCurrencySelected &&
      currenciesDataObj['Sending Currency'][0] === currenciesDataObj['Receiving Currency'][0]
    ) {
      setWarningMessage('Sending and receiving currency are the same');
      setShowWarningModal(true);

      return;
    }
    if (
      oneSendingCurrencySelected &&
      oneReceivingCurrencySelected &&
      data &&
      currenciesDataObj['Sending Currency'][0] !== currenciesDataObj['Receiving Currency'][0]
    ) {
      const pairData = data.filter(pair => {
        if (
          pair.from?.iso === currenciesDataObj['Sending Currency'][0] &&
          pair.to?.iso === currenciesDataObj['Receiving Currency'][0]
        ) {
          return true;
        }

        return false;
      })[0];

      if (pairData?.status === 'Active') {
        setCurrencyUpdateObj({ status: 'Active' });
      } else {
        setCurrencyUpdateObj({ status: 'Disabled' });
      }
    }

    if (
      (currenciesDataObj['Sending Currency'] &&
        currenciesDataObj['Receiving Currency'] &&
        currenciesDataObj['Sending Currency']?.length > 1) ||
      currenciesDataObj['Receiving Currency']?.length > 1
    ) {
      // Multiple currencies selected so don't set Active or Inactive status on checkbox
      setCurrencyUpdateObj({});
    }
  }, [currenciesDataObj, data]);

  const save = async () => {
    const sendingCurrencies = currenciesDataObj['Sending Currency'];
    const receivingCurrencies = currenciesDataObj['Receiving Currency'];

    if (!sendingCurrencies || !receivingCurrencies) {
      setWarningMessage('Please select both sending and receiving currencies');
      setShowWarningModal(true);

      return;
    }

    if (!Object.keys(currencyUpdateObj).length) {
      setWarningMessage('Please select the status');
      setShowWarningModal(true);

      return;
    }

    if (
      sendingCurrencies.length === 1 &&
      receivingCurrencies.length === 1 &&
      sendingCurrencies[0] === receivingCurrencies[0]
    ) {
      setWarningMessage('Sending and Receiving currencies are the same');
      setShowWarningModal(true);

      return;
    }

    setLoading(true);

    try {
      for (const sendingCurrency of sendingCurrencies) {
        for (const receivingCurrency of receivingCurrencies) {
          if (sendingCurrency !== receivingCurrency) {
            const identifiedPairInData = data.find(pair => {
              if (pair.from?.iso === sendingCurrency && pair.to?.iso === receivingCurrency) {
                return true;
              }

              return false;
            });

            if (identifiedPairInData) {
              const { id } = identifiedPairInData;
              const values = { ...currencyUpdateObj, id };
              const saveObj = {
                entity: entitiesEnum.PAIR,
                values,
                id,
                hasOrder: false,
              };

              await saveEntity(saveObj);
            }
          }
        }
      }
      setLoading(false);
      setShowSavedModal(true);
    } catch (err) {
      setLoading(false);
      setWarningMessage(err.message);
      setShowWarningModal(true);
    }
  };

  const closeSavedModal = async () => {
    setShowSavedModal(false);
    await handleRefreshData();
  };

  const clearPairsCurrenciesObj = () => {
    dispatch({ type: 'CLEAR_PAIRS_CURRENCIES_OBJ', payload: {} });
  };

  const changeStatus = event => {
    setCurrencyUpdateObj({ status: event.target.value });
  };

  return (
    <section className={classes.sectionContainer}>
      <header className={classes.headerContainer}>
        <SmallSideRectangle />
        <p>Currencies</p>
      </header>
      <main className={classes.contentContainer}>
        <div className={classes.sectionInputContainer}>
          <div className={classes.rowInputContainer}>
            <CurrenciesInputField field="Sending Currency" currencies={currencies} />
            <div className={classes.emptyDiv} />
          </div>
          <div className={classes.rowInputContainer}>
            <CurrenciesInputField field="Receiving Currency" currencies={currencies} />
            <div className={classes.statusRadioButtonContainer}>
              <p>Status</p>
              <div className={classes.radioButtons}>
                <div>
                  <input
                    type="radio"
                    id="status_active"
                    name="status"
                    value="Active"
                    onChange={changeStatus}
                    checked={currencyUpdateObj.status === 'Active'}
                  />
                  <label htmlFor="status_active">Active</label>
                </div>
                <div>
                  <input
                    type="radio"
                    id="status_inactive"
                    name="status"
                    value="Disabled"
                    onChange={changeStatus}
                    checked={currencyUpdateObj.status === 'Disabled'}
                  />
                  <label htmlFor="status_inactive">Inactive</label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={classes.buttonsContainer}>
          <button
            type="button"
            className={`${classes.defaultBtnStyle} ${classes.btnCancel}`}
            onClick={clearPairsCurrenciesObj}
          >
            <PairsCancelIcon />
            Cancel
          </button>
          <button
            type="button"
            className={`${classes.defaultBtnStyle} ${classes.btnSave}`}
            onClick={save}
          >
            <PairsSaveIcon />
            Save
          </button>
        </div>
        <SavedModal open={showSavedModal} onClose={closeSavedModal} />
        <WarningModal
          open={showWarningModal}
          onClose={() => setShowWarningModal(false)}
          message={warningMessage}
        />
        <LoadingModal open={loading} onClose={() => setLoading(false)} />
      </main>
    </section>
  );
};
