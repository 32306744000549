import React from 'react';

export const OMR = ({ width = '45', height = '45' }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 47.998 48">
    <g data-name="Group 8514">
      <path
        data-name="Path 10199"
        d="M11880 3696a24 24 0 1 0-24-24 24 24 0 0 0 24 24z"
        transform="translate(-11856.001 -3648)"
        style={{ fill: '#f0f0f0' }}
      />
      <path
        data-name="Path 10200"
        d="M11856 3700.211a24.068 24.068 0 0 0 12.283 21.082v-42.163a24.065 24.065 0 0 0-12.283 21.081z"
        transform="translate(-11856.001 -3676.212)"
        style={{ fill: '#d80027' }}
      />
      <path
        data-name="Path 10201"
        d="M11902.51 3831.655a24.139 24.139 0 0 0 0-16.695h-45.02a24.139 24.139 0 0 0 0 16.695l22.511 2.087z"
        transform="translate(-11856.001 -3799.308)"
        style={{ fill: '#d80027' }}
      />
      <path
        data-name="Path 10202"
        d="M11894.438 4008.692a24.037 24.037 0 0 0 22.538-15.652h-45.076a24.04 24.04 0 0 0 22.538 15.652z"
        transform="translate(-11870.44 -3960.693)"
        style={{ fill: '#6da544' }}
      />
      <path
        data-name="Path 10203"
        d="M11856 3679.993a23.935 23.935 0 0 0 17.4 23.174v-46.347a23.935 23.935 0 0 0-17.4 23.173z"
        transform="translate(-11856.001 -3655.993)"
        style={{ fill: '#d80027' }}
      />
      <path
        data-name="Path 10204"
        d="m11941.137 3739.254-2.17-2.213-1.443 1.476-1.452-1.476-2.169 2.213 1.451 1.475-1.451 1.477 2.169 2.213 1.452-1.476 1.443 1.476 2.17-2.213-1.443-1.477z"
        transform="translate(-11926.574 -3728.693)"
        style={{ fill: '#f0f0f0' }}
      />
    </g>
  </svg>
);
