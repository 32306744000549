import * as React from 'react';
import { useEffect, useState } from 'react';
import { useStyles } from '../multicurrency.hooks';
import { Loader } from '../../../loader';
import { graphqlApiDecorator } from '../../../../decorators';
import { queries } from '../../../../graphql';
import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';

export function MccBalances() {
  const [currencies, setCurrencies] = useState([]);
  const [loading, setLoading] = useState(false);
  const classes = useStyles();

  const columns = ['currency', 'actual', 'available'];
  useEffect(() => {
    setLoading(true);
    getListBalances().then(res => {
      setLoading(false);
      if (currencies)
        setCurrencies(res);
    });
  }, []);


  const getListBalances = async () => {
    const data = await graphqlApiDecorator(queries, 'listMCCYBalances', { limit: 250 });
    return data ?? [];
  };

  return (
    <div className={classes.sectionContainer}>
      <div className={classes.container}>
        {loading ? (
          <Loader />
        ) : (
          <table className={classes.tableContainer}>
            <thead className={classes.tableHead}>
              <tr className={`${classes.tableFields} ${classes.centerlize}`}>
                <th>Currency</th>
                <th>Actual Balance</th>
                <th>Available Balance</th>
              </tr>
            </thead>
            <tbody>
              {currencies?.map(row => (
                <tr
                  className={`${classes.tableRow} ${classes.centerlize}`}
                  hover
                  key={row.key}
                >
                  {columns.map(head => (
                    <td className={classes.tableCell} id={head}>
                      {row[head]}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
}
