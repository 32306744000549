import React, { useEffect, useState } from 'react';

import { Loader } from '../../components/loader';
import { SavedModal } from '../../components/pairs/components/setup/modal';
import { useStyles } from '../../components/pairs/components/table/pairs-table.hooks';
import { FXTradesPage } from '../../components/multi-currency/componenets/fX-trades';
import { FXTradeHistory } from '../../components/multi-currency/componenets/fX-trade-history';
import { InternationalPayments } from '../../components/multi-currency/componenets/international-payment';
import { MccBalances } from '../../components/multi-currency/componenets/mcc-balances';
import { TransactionsTab } from '../../components/multi-currency/componenets/TransactionsTab';

/**
 * PairsTable component where the parent component is EntityTable. This is displayed if the entity
 * is pairs as it is a completely different UI design than the GenericTable for other entities
 * @param {Array} data array of pairs data to render
 * @param {boolean} loading true if loading, false if not
 * @param {function} handleRefreshData this eventually calles the fetchEntity() in the useGraphList() custom hook
 */

export const MultiCurrenciesTable = ({ data, loading, handleRefreshData }) => {
  const FX_Trades = 'FXTrades';
  const International_Payments = 'InternationalPayments';
  const FX_Trade_History = 'FXTradeHistory';
  const MCC_Balances = 'MCCBalances';
  const Transactions = 'Transactions';
  const [tabSelected, setTabSelected] = useState(FX_Trades);
  const [showWarningModal, setShowWarningModal] = useState(true);
  const [warningMessage, setWarningMessage] = useState('');
  const classes = useStyles();

  const handleTabSelect = tab => {
    setTabSelected(tab);
    localStorage.setItem('lastSelectedTab', tab);
  };

  useEffect(() => {
    const lastSelectedTab = localStorage.getItem('lastSelectedTab');

    if (
      lastSelectedTab &&
      [FX_Trades, International_Payments, FX_Trade_History, MCC_Balances, Transactions].includes(
        lastSelectedTab
      )
    ) {
      setTabSelected(lastSelectedTab);
    }
  }, []);

  return (
    <div>
      {loading ? (
        <Loader />
      ) : (
        <>
          <div className={classes.tab}>
            <div className={classes.togglerContainer}>
              <div
                className={
                  tabSelected === FX_Trades
                    ? `${classes.selectedContainer} ${classes.itemContainer}`
                    : `${classes.itemContainer} ${classes.notSelected}`
                }
                onClick={() => handleTabSelect(FX_Trades)}
              >
                FX Trades
              </div>
              <div
                className={
                  tabSelected === International_Payments
                    ? `${classes.selectedContainer} ${classes.itemContainer}`
                    : `${classes.itemContainer} ${classes.notSelected}`
                }
                onClick={() => handleTabSelect(International_Payments)}
              >
                International Payments
              </div>
              <div
                className={
                  tabSelected === FX_Trade_History
                    ? `${classes.selectedContainer} ${classes.itemContainer}`
                    : `${classes.itemContainer} ${classes.notSelected}`
                }
                onClick={() => handleTabSelect(FX_Trade_History)}
              >
                FX Trade History
              </div>
              <div
                className={
                  tabSelected === MCC_Balances
                    ? `${classes.selectedContainer} ${classes.itemContainer}`
                    : `${classes.itemContainer} ${classes.notSelected}`
                }
                onClick={() => handleTabSelect(MCC_Balances)}
              >
                MCC Balances
              </div>
              <div
                className={
                  tabSelected === Transactions
                    ? `${classes.selectedContainer} ${classes.itemContainer}`
                    : `${classes.itemContainer} ${classes.notSelected}`
                }
                onClick={() => handleTabSelect(Transactions)}
              >
                Transactions
              </div>
            </div>
          </div>

          <div className={classes.boxContent}>
            {tabSelected === FX_Trades && (
              <section className={classes.sectionContainer}>
                <FXTradesPage />
              </section>
            )}
            {tabSelected === International_Payments && (
              <section className={classes.sectionContainer}>
                <InternationalPayments />
              </section>
            )}
            {tabSelected === MCC_Balances && (
              <section className={classes.sectionContainer}>
                <MccBalances />
              </section>
            )}
            {tabSelected === FX_Trade_History && (
              <section className={classes.sectionContainer}>
                <FXTradeHistory />
              </section>
            )}
            {tabSelected === Transactions && (
              <section className={classes.sectionContainer}>
                <TransactionsTab />
              </section>
            )}
          </div>
        </>
      )}
    </div>
  );
};
