import { makeStyles } from '@mui/styles';

import { accountStatus } from '../../../../theme/styles';

export const useStyles = makeStyles(theme => ({
  popoverContainer: {
    padding: theme.spacing(1),
  },
  rowImage: {
    maxWidth: '5rem',
  },
  accountStatus,
}));
