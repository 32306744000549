import React from 'react';

export const TransactionsIcon = ({ className, color }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 32 33.064">
    {/* prettier-ignore */}
    <g data-name="Group 7284">
    <path data-name="Union 101" 
      d="M21.7 32H4.289A4.258 4.258 0 0 1 0 27.824V.8A.81.81 0 0 1 .817 0a.827.827 0 0 1 .407.108L6.771 3.2a.277.277 0 0 0 .273 0L12.592.113a.848.848 0 0 1 .817 0l5.542 3.084a.279.279 0 0 0 .274 0L24.77.109A.828.828 0 0 1 25.888.4.791.791 0 0 1 26 .8v27.02A4.255 4.255 0 0 1 21.711 32zM1.78 2.414a.268.268 0 0 0-.137.234v25.184a2.638 2.638 0 0 0 2.657 2.59h17.4a2.64 2.64 0 0 0 2.66-2.59V2.647a.27.27 0 0 0-.136-.234.283.283 0 0 0-.276 0l-4.43 2.451a1 1 0 0 1-.585.077 1.7 1.7 0 0 1-.257-.068l-5.54-3.083a.277.277 0 0 0-.273 0L7.352 4.857a.969.969 0 0 1-.852.017L2.055 2.412a.281.281 0 0 0-.136-.035.278.278 0 0 0-.139.037zm6 22.833a.791.791 0 1 1 0-1.581h10.443a.791.791 0 1 1 0 1.581zm10.443-6.757H7.778a.809.809 0 0 1-.815-.791v-.026a.809.809 0 0 1 .815-.762h10.447a.828.828 0 0 1 .578.232.776.776 0 0 1 0 1.117.832.832 0 0 1-.575.23zm-8.732-7.528a.814.814 0 0 1 .842-.817H15.7a.813.813 0 0 1 .553.214.781.781 0 0 1 .036 1.119.847.847 0 0 1-.623.25H10.26a.785.785 0 0 1-.769-.766z" 
      transform="translate(3 .564)"
      fill={color}
      className={className}
    />
    <path data-name="Rectangle 17107" 
      transform="translate(0 .564)" 
      style={{fill: 'none'}}
      d="M0 0h32v32H0z"/>
    </g>
  </svg>
);
