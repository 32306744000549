import { TableCell } from '@mui/material';
import clsx from 'clsx';
import React from 'react';

import { getKycResults, getKycStatuses } from '../../../../utils';
import { useStyles } from './kyc-status-flags.hooks';

const renderKycFlag = (style, status, result) => {
  const { isUnstarted, isPending, isCompleted } = getKycStatuses(status);
  const { isResultFail, isResultSuccess } = getKycResults(result);
  const isRejected = isCompleted && isResultFail;
  const isFulfilled = isCompleted && isResultSuccess;

  return (
    <div
      className={clsx(style.flag, {
        [style.pending]: isPending,
        [style.rejected]: isRejected,
        [style.accepted]: isFulfilled,
      })}
    >
      {isUnstarted ? '' : 'KYC'}
    </div>
  );
};

export const KycStatusFlags = ({ status, result, classes, appStatus }) => {
  const style = useStyles();
  const isAppStatusActive = appStatus === 'active';

  return (
    <TableCell className={classes.tableCell} style={{ border: 'none' }}>
      <div className={style.kycContainer}>
        {renderKycFlag(style, status, result)}
        <div
          className={clsx(style.flag, style.default, {
            [style.active]: isAppStatusActive,
          })}
        >
          APP
        </div>
      </div>
    </TableCell>
  );
};
