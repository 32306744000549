import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import React, { useState, useEffect } from 'react';

import { RedDeleteIcon } from '../../../../icons/pairs';
import { useStyles } from './delete-modal.hooks';

export const DeleteModal = ({
  cancel,
  deleteIt,
  onClose,
  open,
  header,
  content,
  buttonEnabled = true,
}) => {
  const classes = useStyles();
  const [isButtonEnabled, setIsButtonEnabled] = useState(buttonEnabled ? true : false);
  const [secondsLeft, setSecondsLeft] = useState(5);

  useEffect(() => {
    if (open && !isButtonEnabled) {
      setIsButtonEnabled(false);
      setSecondsLeft(5);

      const countdown = setInterval(() => {
        setSecondsLeft(prevSeconds => {
          if (prevSeconds === 1) {
            clearInterval(countdown);
            setIsButtonEnabled(true);
            return prevSeconds;
          }
          return prevSeconds - 1;
        });
      }, 1000);

      return () => clearInterval(countdown);
    }
  }, [open]);

  useEffect(() => {
    if (!buttonEnabled) setIsButtonEnabled(false);
  }, [onClose]);

  return (
    <Modal open={open} onClose={onClose}>
      <Box>
        <div className={classes.deleteModal}>
          <div className={classes.redDeleteIcon}>
            <RedDeleteIcon />
          </div>
          <header>{header}</header>
          <p className={classes.modalMessage}>{content}</p>
          <section className={classes.cancelDelSection}>
            <div
              // className={classes.deleteIt}
              onClick={deleteIt}
              style={{
                cursor: isButtonEnabled ? 'pointer' : 'not-allowed',
                opacity: isButtonEnabled ? 1 : 0.5,
                color: !buttonEnabled && '#fc3333',
              }}
            >
              {buttonEnabled
                ? 'Delete it'
                : isButtonEnabled
                ? 'Close it'
                : `Close it (${secondsLeft}s)`}
            </div>
            <div
              onClick={cancel}
              style={{
                color: !buttonEnabled && '#0069f8',
              }}
            >
              Cancel
            </div>
          </section>
        </div>
      </Box>
    </Modal>
  );
};
