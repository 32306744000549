export const passwordValidator = enteredPw => {
  if (
    enteredPw.length > 7 &&
    !!enteredPw.match(/[A-Z]/g) &&
    !!enteredPw.match(/[a-z]/g) &&
    !!enteredPw.match(/[0-9]/g) &&
    !!enteredPw.match(/[^a-zA-Z\d\s:]/) &&
    !enteredPw.match(/\s/g)
  )
    return true;

  return false;
};
