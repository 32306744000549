import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
  buttonsWrapper: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    paddingTop: '2rem',
  },
  buttonDiv: {
    margin: '0 1rem',
    position: 'relative',
  },
}));
