import React from 'react';

export const NumberOfPending = ({ className, color }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40">
    <g data-name="Group 10336">
      <g data-name="Group 10292" transform="translate(-328 -504)">
        <rect
          data-name="Rectangle 16974"
          width="40"
          height="40"
          rx="8"
          transform="translate(328 504)"
          fill="#a5a6ae"
        />
      </g>
      <g data-name="Group 10298">
        <path
          data-name="Path 23420"
          d="M-17674.479-5649.852a1.686 1.686 0 0 1-1.189-.493l-6.811-6.81a1.682 1.682 0 0 1 0-2.381 1.685 1.685 0 0 1 2.383 0l5.617 5.618 10.451-10.452a1.685 1.685 0 0 1 2.381 0 1.683 1.683 0 0 1 0 2.382l-11.641 11.643a1.687 1.687 0 0 1-1.191.493z"
          transform="translate(17692.06 5676.953)"
          fill="#fff"
        />
      </g>
    </g>
  </svg>
);
