import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getFormattedDisplayDate } from '../../../../../utils';
import { CloseIcon } from '../../../../icons';
import { useStyles } from './customer-filter-summary.hooks';

export const CustomerFilterSummary = () => {
  const customerFilterObj = useSelector(state => state.customerFilterObj);
  const dispatch = useDispatch();
  const classes = useStyles();
  const cancelFilter = () => {
    dispatch({ type: 'SET_CUSTOMER_FILTER_OBJ', payload: {} });
  };

  if (
    (Object.keys(customerFilterObj).length > 0 &&
      Object.values(customerFilterObj.status).some(status => !!status)) ||
    Object.values(customerFilterObj).some(value => {
      if (typeof value === 'object') return false;

      return !!value;
    })
  ) {
    return (
      <div className={classes.container}>
        <div className={classes.root}>
          {Object.values(customerFilterObj.status).some(status => !!status) && (
            <div className={classes.statuses}>
              <aside>Status:</aside>
              {customerFilterObj.status.created && <p>Created</p>}
              {customerFilterObj.status.rejected && <p>Rejected</p>}
              {customerFilterObj.status.closed && <p>Closed</p>}
              {customerFilterObj.status.active && <p>Active</p>}
              {customerFilterObj.status.suspended && <p>Suspended</p>}
              <div className={classes.verticalLine} />
            </div>
          )}
          {customerFilterObj.dateFrom && (
            <div className={classes.statuses}>
              <aside>Date From:</aside>
              <p>{getFormattedDisplayDate(customerFilterObj.dateFrom)}</p>
              <div className={classes.verticalLine} />
            </div>
          )}
          {customerFilterObj.dateTo && (
            <div className={classes.statuses}>
              <aside>Date To:</aside>
              <p>{getFormattedDisplayDate(customerFilterObj.dateTo)}</p>
              <div className={classes.verticalLine} />
            </div>
          )}
        </div>
        <div className={classes.closeIcon} onClick={cancelFilter}>
          <CloseIcon />
        </div>
      </div>
    );
  }

  return null;
};
