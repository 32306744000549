import React from 'react';

export const AED = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="45" height="45" viewBox="0 0 45 45">
    <defs>
      <clipPath id="0li750g1ma">
        <path
          data-name="Rectangle 16823"
          transform="translate(-.325 -.138)"
          style={{ fill: '#fff' }}
          d="M0 0h45v45H0z"
        />
      </clipPath>
    </defs>
    <g
      data-name="Group 6264"
      transform="translate(.325 .138)"
      style={{ clipPath: 'url(#0li750g1ma)' }}
    >
      <path
        data-name="Path 10812"
        d="M15526.419 10076.841a22.42 22.42 0 1 0-22.42-22.42 22.419 22.419 0 0 0 22.42 22.42z"
        transform="translate(-15503.975 -10032.289)"
        style={{ fill: '#f0f0f0' }}
      />
      <path
        data-name="Path 10813"
        d="m12.698 29.926 1.955 13.233a22.442 22.442 0 0 0 28.822-13.233z"
      />
      <path
        data-name="Path 10814"
        d="m15648.7 10046.626 1.955-13.233a22.445 22.445 0 0 1 28.822 13.233z"
        transform="translate(-15636.002 -10032.289)"
        style={{ fill: '#6da544' }}
      />
      <path
        data-name="Path 10815"
        d="M15504 10068.928a22.436 22.436 0 0 0 14.625 21.029v-42.057a22.434 22.434 0 0 0-14.625 21.028z"
        transform="translate(-15503.975 -10046.797)"
        style={{ fill: '#a2001d' }}
      />
    </g>
  </svg>
);
