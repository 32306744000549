import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: 'white',
    display: 'flex',
    flexDirection: 'column',
    borderRadius: '0 16px 16px 16px',
    flexGrow: '1',
    alignItems: 'center',
    justifyContent: 'center',
  },
  grid: {},
  content: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  contentBody: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));
