import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(theme => ({
  modalPaper: {
    backgroundColor: theme.palette.white,
    boxShadow: theme.shadows[5],
    padding: '2.8rem',
    outline: 'none',
    borderRadius: '0.4rem',
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: '1rem',
    marginBottom: '1em',
  },
  x: {
    width: 'fit-content',
    marginLeft: 'auto',
    cursor: 'pointer',
    color: theme.palette.text.secondary,
  },
  tableCell: {
    width: '21rem',
    height: '4rem',
    border: 'none',
    padding: '1rem',
    backgroundColor: theme.palette.white,
  },
  input: {
    border: `0.1rem solid ${theme.palette.secondary.dark}`,
    borderRadius: '0.4rem',
    padding: '0.5rem',
    width: '100%',
  },
}));
