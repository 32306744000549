import { TableCell } from '@mui/material';
import React from 'react';

import { entitiesEnum } from '../../../../../constants';
import { RightArrowIcon } from '../../currencies-table/icons';
import { FieldValue } from '../../field-value';
import { useStyles } from './countries-table-cell.hooks';

export const CountriesTableCell = ({ tableField, data, isHovering }) => {
  const classes = useStyles();

  const fieldValueProps = {
    field: tableField,
    value: data[tableField.name],
    entity: entitiesEnum.COUNTRY,
  };

  if (tableField.name === 'active') {
    return (
      <TableCell className={classes.tableCell} align="justify">
        <div className={classes.withArrowIcon}>
          <FieldValue {...fieldValueProps} />
          <RightArrowIcon active={isHovering || false} />
        </div>
      </TableCell>
    );
  }

  return (
    <TableCell className={classes.tableCell}>
      <FieldValue {...fieldValueProps} />
    </TableCell>
  );
};
