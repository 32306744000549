import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(theme => ({
  tableHeadCell: {
    backgroundColor: theme.palette.white,
    border: 'none',
    color: theme.palette.text.secondary,
    whiteSpace: 'nowrap',
    padding: '1.2rem',
    fontSize: '1.4rem',
  },
  tableHeadCellCountries: {
    backgroundColor: theme.palette.white,
    paddingBottom: '19px',
    borderBottom: '1px solid #aeabab',
    color: theme.palette.icons.nav,
    whiteSpace: 'nowrap',
    padding: '1.2rem',
    fontSize: '20px',
  },
  tableHeadRow: {
    position: 'sticky',
    top: '0',
    zIndex: 3,
  },
}));
