import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(theme => ({
  root: {},
  row: {
    height: '112px',
    display: 'flex',
    alignItems: 'center',
    backgroundColor: theme.palette.white,
    padding: '0 3.2rem',
    borderRadius: '0 0 15px 15px',
    boxShadow: '0 1px 6px 0 rgba(0, 0, 0, 0.08)',
    zIndex: 1,
  },
  limitation: {
    fontFamily: 'Gilroy, Roboto , Helvetica, Arial, sans-serif',
    width: '140px',
    height: '32px',
    borderRadius: '16px',
    border: '1px solid #2BE4B0',
    fontWeight: '500',
    fontSize: '13px',
    textAlign: 'center',
    paddingTop: '5px',
    cursor: 'pointer',
    color: '#19365e',
    overflow:"hidden"
  },
  spacer: {
    flexGrow: 1,
  },
  topBarButton: {
    minWidth: '20rem',
    fontSize: '1.2rem',
    padding: '1rem',
  },
  importButton: {
    marginRight: '0.8rem',
  },
  exportButton: {
    marginRight: '0.8rem',
  },
  iconTitleWrapper: {
    // display: 'flex',
    color: theme.palette.text.primary,
    alignItems: 'center',
    justifyContent: 'space-between',
    marginRight: '2rem',
    '& .MuiIcon-root': {
      width: '2.4rem',
      height: '2.4rem',
    },
    '& .MuiSvgIcon-root': {
      width: '2.4rem',
      height: '2.4rem',
    },
  },
  icon: {
    color: theme.palette.text.primary,
    marginRight: '1.6rem',
  },
  filterIcon: {
    marginLeft: '1em',
    cursor: 'pointer',
  },
  advancedSearch: {
    width: '160px',
    height: '32px',
    borderRadius: '16px',
    backgroundColor: '#c6ffef',
    padding: '8px',
    fontSize: '14px',
    color: '#19365e',
    fontWeight: '500',
    fontFamily: theme.typography.default,
    cursor: 'pointer',
    marginLeft: '16px',
    marginRight: '8px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '8px',
    [theme.breakpoints.down('1200')]: {
      borderRadius: '20px',
    },
  },
  refreshIcon: {
    cursor: 'pointer',
    marginRight: '16px',
  },
}));
