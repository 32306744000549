import { getBackofficeConfig } from '../config/backoffice.config';
import schema from '../graphql/schema.json';
import { fieldsMapper } from '../mappers';
import { capitalizeString, lowerCaseString } from './strings.utils';

const isManualIdFieldShown = entity => getBackofficeConfig().entitiesWithManualIds.includes(entity);

const compareFieldsOrder = order => (fieldA, fieldB) =>
  order.indexOf(fieldA.name) - order.indexOf(fieldB.name);

const getEntityFromSchema = name => {
  const { __schema } = schema.data;
  const schemaEntities = __schema.types.filter(t => t.name.toLowerCase() === name?.toLowerCase());

  if (schemaEntities.length !== 1) {
    return null;
  }

  return schemaEntities[0];
};

const getRelatedEntityFieldName = (entity, relatedEntity) =>
  `${lowerCaseString(entity)}${capitalizeString(relatedEntity)}Id`;

const getEntityLabel = entityRecord => {
  if (!entityRecord) {
    return '';
  }

  const { email, name, label, lastName, title, code } = entityRecord;

  if (email && name) {
    return `${email} - ${name} ${lastName}`;
  }

  if (name && lastName) {
    return `${name} ${lastName}`;
  }

  return label || name || title || code || '';
};

const isEntityCanBeEdited = name => !getBackofficeConfig().editExcludeEntities.includes(name);

const isEntityCanBeRemoved = name => !getBackofficeConfig().removeExcludeEntities.includes(name);

const getMappedEntity = entity => {
  const schemaEntity = getEntityFromSchema(entity);
  const fields = schemaEntity?.fields.map(fieldsMapper);
  const tableFields = [];

  let hasOrder = false;

  fields?.forEach(field => {
    const { name } = field;

    if (name === 'order') {
      hasOrder = true;
    }

    if (
      name.indexOf('richText') === 0 ||
      getBackofficeConfig().tableExcludesFields.includes(name)
    ) {
      return;
    }

    tableFields.push(field);
  });

  const order = getBackofficeConfig().tableFieldsOrder[entity];
  const tableFieldsSorted = order ? tableFields.sort(compareFieldsOrder(order)) : tableFields;

  return {
    name: entity,
    fields,
    tableFields: tableFieldsSorted,
    hasOrder,
  };
};

const hasEntityImport = entity => getBackofficeConfig().entitiesImport.includes(entity);

const hasEntityExport = entity => getBackofficeConfig().entitiesExport.includes(entity);

export {
  getEntityFromSchema,
  getEntityLabel,
  getMappedEntity,
  getRelatedEntityFieldName,
  hasEntityExport,
  hasEntityImport,
  isEntityCanBeEdited,
  isEntityCanBeRemoved,
  isManualIdFieldShown,
};
