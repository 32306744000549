import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { BigSaveIcon, LeftArrowIcon, SideRectangle } from '../../components/icons';
import { graphqlApiDecorator } from '../../decorators';
import { mutations, queries } from '../../graphql';
import { SavedModal, WarningModal } from '../currency-requirements/modal';
import { useStyles } from './country-edit.hooks';

/**
 * Detailed page of a Country when a specific country is clicked on the CountriesTableBody component
 * Features included editing the default currency and status of the currency (whether it can be used on the app).
 */
export const CountryEdit = () => {
  const [showSavedModal, setShowSavedModal] = useState(false);
  const [showWarningModal, setShowWarningModal] = useState(false);
  const [warningMessage, setWarningMessage] = useState('');
  const [selectedCountry, setSelectedCountry] = useState('');
  const [selectedCurrency, setSelectedCurrency] = useState('');
  const [countries, setCountryList] = useState([]);
  const [countryData, setCountryData] = useState({});
  const [currencies, setCurrencyList] = useState([]);
  const [statusActive, setStatusActive] = useState(true);
  const navigate = useNavigate();
  const { id } = useParams();
  const classes = useStyles();

  const selectProps = {
    PaperProps: {
      style: {
        maxHeight: 400,
      },
    },
  };

  const selectSX = {
    height: '48px',
    width: '100%',
    borderRadius: '8px',
    color: '#19365e',
    fontSize: '20px',
  };

  const sortByName = (a, b) => {
    const textA = a.name.toLowerCase();
    const textB = b.name.toLowerCase();

    return textA.localeCompare(textB);
  };

  const fetchCountryDetails = async () => {
    try {
      const queryTitle = 'getCountry';
      const country = await graphqlApiDecorator(queries, queryTitle, { id });
      if (country) {
        setSelectedCountry(country.iso);
        setCountryData(country);
        setStatusActive(country.active);
      }
    } catch (err) {
      setWarningMessage(err.message);
      setShowWarningModal(true);
    }
  };

  const fetchAvailableCurrencies = async () => {
    try {
      const queryTitle = 'listCurrencys';
      const currencyList = await graphqlApiDecorator(queries, queryTitle);
      if (currencyList) {
        const { items } = currencyList;
        items.sort(sortByName);
        setCurrencyList(items);
      }
    } catch (err) {
      setWarningMessage(err.message);
      setShowWarningModal(true);
    }
  };

  const fetchAvailableCountries = async () => {
    try {
      const queryTitle = 'listCountrys';
      const limit = 250;
      const countryList = await graphqlApiDecorator(queries, queryTitle, { limit });
      if (countryList) {
        const { items } = countryList;
        items.sort(sortByName);
        setCountryList(items);
      }
    } catch (err) {
      setWarningMessage(err.message);
      setShowWarningModal(true);
    }
  };

  useEffect(() => {
    fetchAvailableCountries();
    fetchAvailableCurrencies();
    fetchCountryDetails();
  }, []);

  useEffect(() => {
    if (selectedCountry && Object.keys(countryData).length) {
      setSelectedCurrency(countryData.defaultCurrency?.iso || '');
      document.title = `Edit Country: ${countryData.name}`;
    }
  }, [selectedCountry, countryData]);

  const handleCountrySelectChange = event => {
    setSelectedCountry(event.target.value);
    navigate(`/country/${event.target.value}`);
    window.location.reload();
  };

  const handleCurrencySelectChange = event => {
    setSelectedCurrency(event.target.value);
  };

  const saveCountry = async () => {
    const mutationName = 'updateCountry';
    const input = {
      id,
      countryDefaultCurrencyId: selectedCurrency,
      active: statusActive,
    };

    try {
      const result = await graphqlApiDecorator(mutations, mutationName, { input });
      if (result) {
        setShowSavedModal(true);
      }
    } catch (err) {
      setWarningMessage(err.message);
      setShowWarningModal(true);
    }
  };

  return (
    <>
      <div className={classes.toolbar}>
        <div>
          <header>Edit Country</header>
          <div
            className={classes.navigateBack}
            onClick={() => {
              navigate(`/country`);
            }}
          >
            <LeftArrowIcon />
            <p>Go Back</p>
          </div>
        </div>
        <button type="submit" className={classes.saveBtn} onClick={saveCountry}>
          <BigSaveIcon />
          <p>Save</p>
        </button>
      </div>
      {Object.keys(countryData).length && (
        <main className={classes.editContainer}>
          <div className={classes.containerLabel}>
            <SideRectangle color="#1fe3ac" />
            <header>Country & Currency</header>
          </div>
          <div className={classes.content}>
            <p>Status</p>
            <section className={classes.radioButtons}>
              <div>
                <input
                  type="radio"
                  id="active"
                  name="countryStatus"
                  checked={statusActive}
                  onChange={() => setStatusActive(true)}
                />
                <label htmlFor="active">Active</label>
              </div>
              <div>
                <input
                  type="radio"
                  id="inactive"
                  name="countryStatus"
                  checked={!statusActive}
                  onChange={() => setStatusActive(false)}
                />
                <label htmlFor="inactive">Inactive</label>
              </div>
            </section>
            <section className={classes.nameCurrencyRow}>
              <div>
                <p>Country Name</p>
                {selectedCountry && (
                  <Select
                    id="country-name-btn"
                    value={selectedCountry}
                    sx={selectSX}
                    onChange={handleCountrySelectChange}
                    MenuProps={selectProps}
                  >
                    {countries.length > 0 &&
                      countries.map(country => (
                        <MenuItem key={country.id} value={country.iso}>
                          {country.name}
                        </MenuItem>
                      ))}
                  </Select>
                )}
              </div>
              <div>
                <p>Default Currency</p>
                <Select
                  id="currency-name-btn"
                  value={selectedCurrency}
                  sx={selectSX}
                  onChange={handleCurrencySelectChange}
                  MenuProps={selectProps}
                >
                  {currencies.length > 0 &&
                    currencies.map(currency => (
                      <MenuItem key={currency.id} value={currency.iso}>
                        {currency.name}
                      </MenuItem>
                    ))}
                </Select>
              </div>
            </section>
          </div>
        </main>
      )}
      <SavedModal open={showSavedModal} onClose={() => setShowSavedModal(false)} />
      <WarningModal
        open={showWarningModal}
        onClose={() => setShowWarningModal(false)}
        message={warningMessage}
      />
    </>
  );
};
