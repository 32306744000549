import Avatar from '@mui/material/Avatar';
import { findFlagUrlByIso3Code } from 'country-flags-svg';
import React from 'react';

import { useStyles } from './info.hooks';

export const InfoInput = ({ id, type, value, onChange, fullWidth = false }) => {
  const classes = useStyles();
  const style = fullWidth ? `${classes.labelAndInput} ${classes.fullWidth}` : classes.labelAndInput;

  return (
    <div className={style}>
      <label htmlFor={id}>{id}</label>
      <input type={type} id={id} value={value} onChange={onChange} />
      {id === 'Country' && (
        <div className={classes.countryAvatar}>
          <Avatar src={findFlagUrlByIso3Code(value??'')} alt="country" sx={{ width: 16, height: 16 }} />
        </div>
      )}
    </div>
  );
};
