import React from 'react';

export const EliteIcon = ({ active = true }) => {
  if (active)
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
        <g data-name="Group 8312" transform="translate(-496 -608)">
          <circle
            data-name="Ellipse 1545"
            cx="16"
            cy="16"
            r="16"
            transform="translate(496 608)"
            style={{ fill: '#0cc5ff' }}
          />
          <path
            data-name="Icon awesome-crown"
            d="M15.84 13.44H3.36a.481.481 0 0 0-.48.48v.96a.481.481 0 0 0 .48.48h12.48a.481.481 0 0 0 .48-.48v-.96a.481.481 0 0 0-.48-.48zm1.92-9.6a1.44 1.44 0 0 0-1.44 1.44 1.411 1.411 0 0 0 .132.594l-2.172 1.3a.959.959 0 0 1-1.326-.348L10.509 2.55a1.44 1.44 0 1 0-1.818 0L6.246 6.828a.959.959 0 0 1-1.326.348l-2.169-1.3a1.44 1.44 0 1 0-1.311.844 1.47 1.47 0 0 0 .231-.02l2.169 5.78h11.52l2.169-5.78a1.47 1.47 0 0 0 .231.024 1.44 1.44 0 0 0 0-2.88z"
            transform="translate(502.4 616)"
            style={{ fill: '#fff' }}
          />
        </g>
      </svg>
    );

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 40 40">
      <g data-name="Group 8312" transform="translate(-496 -608)">
        <circle
          data-name="Ellipse 1545"
          cx="20"
          cy="20"
          r="20"
          transform="translate(496 608)"
          style={{ fill: '#f2f2f4' }}
        />
        <path
          data-name="Icon awesome-crown"
          d="M19.8 16.8H4.2a.6.6 0 0 0-.6.6v1.2a.6.6 0 0 0 .6.6h15.6a.6.6 0 0 0 .6-.6v-1.2a.6.6 0 0 0-.6-.6zm2.4-12a1.8 1.8 0 0 0-1.8 1.8 1.763 1.763 0 0 0 .165.742L17.85 8.97a1.2 1.2 0 0 1-1.658-.435l-3.056-5.347a1.8 1.8 0 1 0-2.273 0L7.808 8.535a1.2 1.2 0 0 1-1.658.435L3.439 7.342A1.8 1.8 0 1 0 1.8 8.4a1.838 1.838 0 0 0 .289-.03L4.8 15.6h14.4l2.711-7.23a1.838 1.838 0 0 0 .289.03 1.8 1.8 0 0 0 0-3.6z"
          transform="translate(504 618)"
          style={{ fill: '#a5a6ae' }}
        />
      </g>
    </svg>
  );
};
