import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
  rowContainer: {
    backgroundColor: 'white',
    height: '64px',
    borderRadius: '8px',
    display: 'flex',
    flexDirection: 'row',
    gap: '16px',
    alignItems: 'center',
    padding: '8px 24px',
    '& aside': {
      borderRadius: '8px',
      backgroundColor: '#f2f2f4',
    },
  },
  dateContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '8px',
    '& aside': {
      width: '64px',
      height: '16px',
    },
  },
  iconContainer: {
    width: '32px',
    height: '32px',
    borderRadius: '4px',
    backgroundColor: '#f2f2f4',
  },
  nameContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
    '& aside:first-child': {
      width: '136px',
      height: '16px',
    },
    '& aside:nth-child(2)': {
      width: '88px',
      height: '16px',
    },
  },
  refContainer: {
    marginLeft: '40%',
    '& aside': {
      width: '136px',
      height: '16px',
    },
  },
  statusContainer: {
    marginLeft: '75px',
    '& aside': {
      width: '104px',
      height: '24px',
      borderRadius: '0 12px 12px 12px',
    },
  },
  amountContainer: {
    flexGrow: '1',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    '& aside': {
      width: '48px',
      height: '16px',
    },
  },
}));
