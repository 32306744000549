import React from 'react';

export const BlankWallet = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="200" height="136" viewBox="0 0 200 136">
    <defs>
      <clipPath id="clip-path">
        <path
          id="Rectangle_16536"
          data-name="Rectangle 16536"
          d="M14 0h155.93a14 14 0 0 1 14 14v53.411H0V14A14 14 0 0 1 14 0z"
          style={{ fill: 'none' }}
        />
      </clipPath>
      <clipPath id="clip-path-2">
        <path
          id="Rectangle_16534"
          data-name="Rectangle 16534"
          d="M14 0h155.93a14 14 0 0 1 14 14v53.411H0V14A14 14 0 0 1 14 0z"
          style={{ fill: '#d29fff' }}
        />
      </clipPath>
      <linearGradient
        id="linear-gradient"
        x1=".5"
        y1=".952"
        x2=".5"
        y2=".59"
        gradientUnits="objectBoundingBox"
      >
        <stop offset="0" stopColor="#000d09" stopOpacity=".161" />
        <stop offset="1" stopColor="#545d5a" stopOpacity="0" />
      </linearGradient>
      <style />
    </defs>
    <g id="Group_10048" data-name="Group 10048" transform="translate(-1120 -208)">
      <g
        id="Rectangle_17507"
        data-name="Rectangle 17507"
        transform="translate(1120 208)"
        style={{ fill: '#fff', stroke: '#f2f2f4', strokeWidth: '2px' }}
      >
        <rect width="200" height="136" rx="16" style={{ stroke: 'none' }} />
        <rect x="1" y="1" width="198" height="134" rx="15" style={{ fill: 'none' }} />
      </g>
      <g id="Group_8455" data-name="Group 8455" transform="translate(1128 216)">
        <path
          id="Union_407"
          data-name="Union 407"
          d="M15.982 88A15.983 15.983 0 0 1 0 72.018V14A14 14 0 0 1 14 0h155.93a14 14 0 0 1 14 14v58.018A15.982 15.982 0 0 1 167.948 88z"
          transform="translate(.07)"
          style={{ fill: '#f2f2f4' }}
        />
      </g>
      <path
        id="Subtraction_4"
        data-name="Subtraction 4"
        d="M168 19.894H16a15.9 15.9 0 0 1-11.314-4.687A15.9 15.9 0 0 1 0 3.893V.24a16.072 16.072 0 0 0 16 15.746h152A16.021 16.021 0 0 0 184 0v3.893a15.9 15.9 0 0 1-4.687 11.314A15.9 15.9 0 0 1 168 19.894z"
        transform="translate(1128 284.106)"
        style={{ opacity: '.12', fill: 'url(#linear-gradient)' }}
      />
      <rect
        id="Rectangle_18918"
        data-name="Rectangle 18918"
        width="112"
        height="16"
        rx="8"
        transform="translate(1128 312)"
        style={{ fill: '#f2f2f4' }}
      />
    </g>
  </svg>
);
