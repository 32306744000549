import React from 'react';

export const LeftArrowIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="8" height="16" viewBox="0 0 8 16">
    <path
      data-name="Path 2"
      d="M7 7a.762.762 0 0 1-.514-.2L-.787.138a.628.628 0 0 1 0-.943A.775.775 0 0 1 .242-.8L7 5.391 13.758-.8a.775.775 0 0 1 1.029 0 .628.628 0 0 1 0 .943L7.514 6.8A.762.762 0 0 1 7 7z"
      transform="rotate(90 3 4)"
      style={{ fill: '#19365e' }}
    />
  </svg>
);
