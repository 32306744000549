import React from 'react';

import { KixySideNavBarLogo } from '../../../../../components/icons';
import { useStyles } from './blankSidebar-nav.hooks';

export const BlankSidebarNav = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.logo}>
        <KixySideNavBarLogo />
      </div>
    </div>
  );
};
