import { TextField } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledTextField = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: `${theme.palette.primary.main} !important`,
  },
  '& label': {
    color: theme.palette.primary.main,
  },
}));
