const pairStatusEnum = Object.freeze({
  Active: 'Active',
  Disabled: 'Disabled',
});

const pairFeeTypeEnum = Object.freeze({
  Percentage: 'Percentage',
  Absolute: 'Absolute',
});

export { pairFeeTypeEnum, pairStatusEnum };
