import { Document, Font, Image, Page, StyleSheet, Text, View } from '@react-pdf/renderer';
import React from 'react';

import { palette } from '../../../theme/palette';
import RobotoBold from './fonts/Roboto-Bold.ttf';
import Roboto from './fonts/Roboto-Regular.ttf';
import Logo from './logo/Kixy_Logo.png';

Font.register({
  family: 'Roboto',
  fontWeight: 'normal',
  src: Roboto,
});

Font.register({
  family: 'Roboto',
  fontWeight: 'bold',
  src: RobotoBold,
});

const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    fontFamily: 'Roboto',
    color: palette.icons.nav,
    fontSize: '12px',
  },
  title: {
    fontSize: '18px',
    margin: 10,
    marginLeft: 20,
    padding: 10,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  section: {
    margin: 10,
    marginLeft: 20,
    padding: 10,
    fontWeight: 'bold',
  },
  contentRow: {
    flexDirection: 'row',
    marginLeft: 30,
    marginBottom: 5,
  },
  lhsText: {
    width: 200,
  },
  logo: {
    width: 100,
  },
});

export const PdfExportService = ({ headerTitle, pdfDetails }) => {
  const {
    transactionDetailsLHS,
    transactionDetailsRHS,
    remittingAccountDetails,
    beneficiaryAccountDetails,
    remittingInstitutionDetails,
    beneficiaryInstitutionDetails,
  } = pdfDetails;

  const titleParts = headerTitle.split(' to ');
  const titlePart1 = titleParts[0];
  const titlePart2 = titleParts[1];

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.title}>
          <Image src={Logo} style={styles.logo} />
          <View
            style={{ borderLeft: `1px solid ${palette.icons.nav}`, height: 50, marginLeft: 20 }}
          />
          <View>
            <Text style={{ paddingLeft: 20 }}>{`${titlePart1} to:`}</Text>
            <Text style={{ paddingLeft: 20, fontWeight: 'bold' }}>{titlePart2}</Text>
          </View>
        </View>
        <View style={styles.section}>
          <Text>Transaction Details</Text>
        </View>
        {Object.keys(transactionDetailsLHS).map(item => (
          <View key={item} style={styles.contentRow}>
            <View style={styles.lhsText}>
              <Text>{item}</Text>
            </View>
            <View>
              <Text>{transactionDetailsLHS[item]}</Text>
            </View>
          </View>
        ))}
        {Object.keys(transactionDetailsRHS).map(item => (
          <View key={item} style={styles.contentRow}>
            <View style={styles.lhsText}>
              <Text>{item}</Text>
            </View>
            <View>
              <Text>{transactionDetailsRHS[item]}</Text>
            </View>
          </View>
        ))}
        <View style={styles.section}>
          <Text>Remitting Account</Text>
        </View>
        {Object.keys(remittingAccountDetails).map(item => (
          <View key={item} style={styles.contentRow}>
            <View style={styles.lhsText}>
              <Text>{item}</Text>
            </View>
            <View>
              <Text>{remittingAccountDetails[item]}</Text>
            </View>
          </View>
        ))}
        <View style={styles.section}>
          <Text>Beneficiary Account</Text>
        </View>
        {Object.keys(beneficiaryAccountDetails).map(item => (
          <View key={item} style={styles.contentRow}>
            <View style={styles.lhsText}>
              <Text>{item}</Text>
            </View>
            <View>
              <Text>{beneficiaryAccountDetails[item]}</Text>
            </View>
          </View>
        ))}
        <View style={styles.section}>
          <Text>Remitting Institution</Text>
        </View>
        {Object.keys(remittingInstitutionDetails).map(item => (
          <View key={item} style={styles.contentRow}>
            <View style={styles.lhsText}>
              <Text>{item}</Text>
            </View>
            <View>
              <Text>{remittingAccountDetails[item]}</Text>
            </View>
          </View>
        ))}
        <View style={styles.section}>
          <Text>Beneficiary Institution</Text>
        </View>
        {Object.keys(beneficiaryInstitutionDetails).map(item => (
          <View key={item} style={styles.contentRow}>
            <View style={styles.lhsText}>
              <Text>{item}</Text>
            </View>
            <View>
              <Text>{beneficiaryInstitutionDetails[item]}</Text>
            </View>
          </View>
        ))}
      </Page>
    </Document>
  );
};
