import React, { useEffect, useState } from 'react';

import { pairFeeTypeEnum } from '../../../../constants';
import { getSellRateFromAbsolute, getSellRateFromPercentage } from '../../../../utils';
import { RightPointer } from '../../../icons';
import { ReturnCountryIcon } from '../../../icons/countries/ReturnCountryIcon';
import { SmallRightArrow } from '../../../icons/pairs';
import { useStyles } from './pairs-row-details.hooks';

/**
 * Parent component is PairsRow.
 * This takes the specific pair data (type Pair) and renders every row for that country.
 * @param {object} data the data of type Pair
 * @param {function} updatePairsRowDataObj this function saves the data
 * @param {object} rate object of type {amount: float, from: string, to: string}
 * @param {function} setupPage This will set the sending and receiving currency in redux and
 * change the tab to the setup of that pair
 * @param {function} sellRateNegative function that changes a boolean and will not save
 * if rate is negative
 */
export const PairsRowDetails = ({
  data,
  updatePairsRowDataObj,
  rate,
  setupPage,
  sellRateNegative,
}) => {
  const [profitMarginSell, setProfitMarginSell] = useState(() => {
    if (data.profitMargin) return data.profitMargin.type;

    return pairFeeTypeEnum.Percentage;
  });
  const [sellRate, setSellRate] = useState('');
  const classes = useStyles();
  const [hover, setHover] = useState(false);

  useEffect(() => {
    if (rate) {
      if (data?.profitMargin?.type === pairFeeTypeEnum.Percentage && rate.amount) {
        setSellRate(getSellRateFromPercentage(rate.amount, data.profitMargin.value));
      }

      if (data?.profitMargin?.type === pairFeeTypeEnum.Absolute && rate.amount) {
        setSellRate(getSellRateFromAbsolute(rate.amount, data.profitMargin.value));
      }
    }
  }, [data, rate]);

  useEffect(() => {
    if (sellRate < 0) sellRateNegative(true);
    if (sellRate > 0) sellRateNegative(false);
  }, [sellRate]);

  const preventMinus = event => {
    if (event.code === 'Minus') event.preventDefault();
  };

  if (rate)
    return (
      <div
        className={classes.mainContainer}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      >
        <div className={classes.rowDataContainer} key={data.id}>
          <div className={classes.currenciesContainer}>
            <ReturnCountryIcon country={data.from?.iso} />
            <p>{data.from?.iso}</p>
            <RightPointer />
            <p>{data.to?.iso}</p>
            <ReturnCountryIcon country={data.to?.iso} />
          </div>
          <div className={classes.flatRateContainer}>{rate && rate.amount}</div>
          <div className={classes.profitMarginSellContainer}>
            <select
              className={classes.profitMarginSellSelectBox}
              name="profitMarginSell"
              defaultValue={profitMarginSell}
              onChange={event => {
                setProfitMarginSell(event.target.value);
              }}
            >
              <option value={pairFeeTypeEnum.Percentage}>Percentage</option>
              <option value={pairFeeTypeEnum.Absolute}>Absolute</option>
            </select>

            {profitMarginSell === pairFeeTypeEnum.Percentage && (
              <div className={`${classes.defaultInputContainer} ${classes.relContainer}`}>
                <input
                  className={classes.defaultInputBox}
                  type="number"
                  name="percentageInput"
                  placeholder={
                    data.profitMargin?.type === pairFeeTypeEnum.Percentage
                      ? data.profitMargin.value
                      : undefined
                  }
                  onChange={event => {
                    updatePairsRowDataObj(event, pairFeeTypeEnum.Percentage, data.id);
                    if (event.target.value) {
                      setSellRate(getSellRateFromPercentage(rate.amount, event.target.value));
                    }

                    if (!event.target.value)
                      setSellRate(
                        getSellRateFromPercentage(rate.amount, data.profitMargin?.value) || ''
                      );
                  }}
                  min="0"
                  onKeyPress={preventMinus}
                />
                <div className={classes.percentageIcon}>%</div>
              </div>
            )}
            {profitMarginSell === pairFeeTypeEnum.Absolute && (
              <div className={classes.defaultInputContainer}>
                <input
                  className={classes.defaultInputBox}
                  type="number"
                  name="absoluteInput"
                  placeholder={
                    data.profitMargin?.type === pairFeeTypeEnum.Absolute
                      ? data.profitMargin.value
                      : undefined
                  }
                  onChange={event => {
                    updatePairsRowDataObj(event, pairFeeTypeEnum.Absolute, data.id);
                    if (event.target.value) {
                      setSellRate(getSellRateFromAbsolute(rate.amount, event.target.value));
                    }

                    if (!event.target.value)
                      setSellRate(
                        getSellRateFromAbsolute(rate.amount, data.profitMargin?.value) || ''
                      );
                  }}
                  min="0"
                  onKeyPress={preventMinus}
                />
              </div>
            )}
          </div>
          <div className={`${classes.defaultInputContainer} ${classes.sellRates}`}>
            <p>{sellRate}</p>
            <div className={classes.smallRightArrow} onClick={() => setupPage(data)}>
              {hover ? <SmallRightArrow active /> : <SmallRightArrow />}
            </div>
          </div>
        </div>
        <div className={classes.horizRule} />
      </div>
    );

  return null;
};
