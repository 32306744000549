import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
  field: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '2rem',
    borderBottom: '0.1rem solid lightgray',
    padding: '1rem 2rem',
  },
  fieldLabel: {
    flex: '0 0 30%',
  },
  fieldValue: {
    flex: 1,
    padding: '0 2rem',
  },
}));
