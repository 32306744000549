import React from 'react';

export const MAD = ({ width = '45', height = '45' }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 48 48">
    <g data-name="Group 8510">
      <path
        data-name="Path 10095"
        d="M13704 1872a24 24 0 1 0-24-24 24 24 0 0 0 24 24z"
        transform="translate(-13680.002 -1824)"
        style={{ fill: '#d80027' }}
      />
      <path
        data-name="Path 10096"
        d="M13814.441 1933.754h-11.363l-3.508-9.584-3.509 9.584h-11.361l9.188 5.923-3.507 9.584 9.188-5.923 9.191 5.923-3.51-9.584zm-17.978 5.182 1.189-3.241h3.834l1.189 3.241-3.106 2-3.106-2zm4.314-5.182h-2.416l1.208-3.3zm3.765 3.982-.756-2.041h3.921zm-9.189-2.041-.756 2.041-3.164-2.041zm-.816 8.485 1.21-3.3 1.967 1.262zm6.892-2.041 1.965-1.262 1.21 3.3z"
        transform="translate(-13775.571 -1913.936)"
        style={{ fill: '#ffda44' }}
      />
    </g>
  </svg>
);
