import React from 'react';

export const RightPointer = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="9.5" viewBox="0 0 16 9.5">
    <path
      data-name="Union 473"
      d="M-3220.824-1282.752a.749.749 0 0 1 .063-1.058l3.027-2.689h-12.6a.709.709 0 0 1-.659-.75.709.709 0 0 1 .659-.75h12.623l-3.047-2.687a.75.75 0 0 1-.067-1.058.749.749 0 0 1 1.059-.067l4.52 3.986a.751.751 0 0 1 .254.561.754.754 0 0 1-.252.563l-4.519 4.014a.749.749 0 0 1-.5.189.75.75 0 0 1-.561-.254z"
      transform="translate(3230.994 1292)"
      style={{ fill: '#19365e' }}
    />
  </svg>
);
