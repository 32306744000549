import { Button, IconButton, InputAdornment, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Loader } from '../../../components/loader';
import SearchIcon from '@mui/icons-material/Search';

import Box from '@mui/material/Box';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

import Pagination from '@mui/material/Pagination';
import { useNavigate } from 'react-router-dom';
import { getCurrentUser } from 'services';
import CustomTextField from './Dashboard-content.hook';

export const DashboardContent = () => {
  const [rows, setRows] = useState([]);
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(0);
  const [rowCount, setRowCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [columns, setColumns] = useState([]);
  const [baseUrl, setBaseUrl] = useState();
  const [operations, setOperations] = useState([]);
  const [filtersSchema, setFiltersSchema] = useState(null);
  const navigate = useNavigate();
  const [accessToken, setAccessToken] = useState();
  const [selectedField, setSelectedField] = useState('');
  const [searchValue, setSearchValue] = useState('');

  const fetchAccessToken = async () => {
    try {
      const data = await getCurrentUser();
      setAccessToken(data.accessToken);

      if (data) {
        fetchAdminMenu(data.accessToken);
      }
    } catch (error) {
      console.error('Error fetching access token:', error);
    }
  };

  const formatString = str => {
    return str
      .replace(/([a-z])([A-Z])/g, '$1 $2')
      .replace(/^\w|\s\w/g, match => match.toUpperCase());
  };

  const handleOperationClick = (operationType, id, url) => {
    switch (operationType) {
      case 'DETAIL':
        navigate(`/form?id=${id}&baseUrl=${url}&tag=${operationType}`);
        break;
      case 'CREATE':
        navigate(`/form?baseUrl=${url}&tag=${operationType}`);
        break;
      case 'EXTERNAL':
        navigate(`/kyb?id=${id}&baseUrl=${url}&tag=DETAIL`);
        break;
      default:
        break;
    }
  };

  const fetchAdminMenu = async token => {
    setLoading(true);
    try {
      const response = await fetch('https://api.test.kixy.com/user/admin-menu', {
        headers: {
          // Accept: 'application/schema+json',
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response.json();
      const resource = data.resources[0];
      setBaseUrl(data.resources[0].baseUrl);

      const schemaResponse = await fetch(`https://api.test.kixy.com/user/v1/admin/user/company`, {
        headers: {
          Accept: 'application/schema+json',
          Authorization: `Bearer ${token}`,
        },
      });
      const schemaData = await schemaResponse.json();

      if (schemaData) {
        const columns = Object.entries(schemaData.properties).map(([key, value]) => {
          const column = {
            field: key,
            headerName: formatString(value.localization),
            height: '100px',
            flex: 1,
            editable: false,
            // renderCell: params =>
            //   params.field === 'action' ? (
            //     <Button variant="contained" color="primary" size="small">
            //       Detail
            //     </Button>
            //   ) : (
            //     params.value
            //   ),
          };

          if (value.format === 'email') {
            column.type = 'email';
          }
          if (value.format === 'button') {
            column.type = 'button';
            column.editable = false;
          }

          return column;
        });

        if (data.resources[0].operations) {
          const ops = Object.values(resource.operations).filter(op => op.role === 'ROLE_ADMIN');
          setOperations(ops);
          ops.map(item => {
            if (item.operationType === 'LIST') {
              setFiltersSchema(item.filtersSchema);
            }
          });

          columns.push({
            field: 'actions',
            headerName: 'Actions',
            renderCell: params => (
              <div
                style={{
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '8px',
                  alignContent: 'center',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                {ops.map(op =>
                  op.operationType === 'LIST' ? (
                    ''
                  ) : (
                    <Button
                      key={op.operationType}
                      style={{ fontWeight: 'bold', fontSize: '10px' }}
                      variant="contained"
                      color="primary"
                      size="small"
                      onClick={() =>
                        handleOperationClick(op.operationType, params.id, data.resources[0].baseUrl)
                      }
                    >
                      {op.operationType === 'EXTERNAL' ? 'KYB' : op.operationType}
                    </Button>
                  )
                )}
              </div>
            ),
          });
        }

        setColumns(columns);
      }
    } catch (error) {
      console.error('Failed to fetch admin menu:', error);
    } finally {
      setLoading(false);
    }
  };

  const fetchRows = async (page, pageSize) => {
    setLoading(true);
    try {
      const response = await fetch(
        `https://api.test.kixy.com/user/v1/admin/user/company?page=${page}&pageSize=${pageSize}`,
        {
          headers: {
            // Accept: 'application/schema+json',
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      const data = await response.json();
      setRows(data);
      setRowCount(data.length);
    } catch (error) {
      console.error('Failed to fetch rows:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAccessToken();
  }, []);

  useEffect(() => {
    if (columns.length > 0) {
      fetchRows(page, pageSize);
    }
  }, [page, pageSize, columns]);

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const handlePageSizeChange = event => {
    setPageSize(parseInt(event.target.value, 10));
    setPage(1);
  };

  const handleFieldChange = event => {
    setSelectedField(event.target.value);
  };

  const handleValueChange = event => {
    setSearchValue(event.target.value);
  };

  const handleSearch = () => {
    setSearchValue('');
  };

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <Box
          sx={{
            height: 'auto',
            width: '100%',
            borderRadius: '0 16px 16px 16px',
            padding: '20px 10px',
            background: 'white',
            overflow: 'hidden',
          }}
        >
          {filtersSchema && (
            <Box display="flex" alignItems="center" mb="10px" style={{ borderRadius: '16px' }}>
              <CustomTextField
                variant="outlined"
                placeholder="Search"
                value={searchValue}
                onChange={handleValueChange}
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <FormControl variant="outlined" size="small">
                        <Select
                          value={selectedField}
                          onChange={handleFieldChange}
                          displayEmpty
                          renderValue={selected =>
                            selected ? filtersSchema.properties[selected].localization : 'Name'
                          }
                        >
                          {Object.keys(filtersSchema.properties).map(key => (
                            <MenuItem key={key} value={key}>
                              {filtersSchema.properties[key].localization}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={handleSearch}>
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
          )}

          {operations.map(
            op =>
              op.operationType === 'CREATE' && (
                <Box display="flex" justifyContent="flex-end" marginBottom="20px">
                  <Button
                    type="button"
                    variant="contained"
                    color="primary"
                    onClick={() => handleOperationClick('CREATE', '', baseUrl)}
                  >
                    Create
                  </Button>
                </Box>
              )
          )}

          {columns.length > 0 && (
            <Box>
              <DataGrid
                rows={rows}
                columns={columns}
                style={{ height: '880px' }}
                paginationMode="server"
                hideFooter
                rowCount={rowCount}
                loading={loading}
                // pageSize={pageSize}
                // onPageSizeChange={handlePageSizeChange}
                // page={page}
                // onPageChange={handlePageChange}
                // initialState={{
                //   pagination: {
                //     paginationModel: {
                //       pageSize: 10,
                //     },
                //   },
                // }}
                checkboxSelection
                disableRowSelectionOnClick
                slots={{ toolbar: GridToolbar }}
                slotProps={{
                  toolbar: {
                    showQuickFilter: true,
                  },
                }}
                getRowHeight={() => 100}
              />
              <Box display="flex" marginTop={'20px'} justifyContent={'end'} alignItems="center">
                <TextField
                  select
                  label="Page Size"
                  value={pageSize}
                  onChange={handlePageSizeChange}
                  variant="outlined"
                  size="small"
                  sx={{ minWidth: 120, marginRight: 2 }}
                >
                  <MenuItem value={10}>10</MenuItem>
                  <MenuItem value={20}>20</MenuItem>
                  <MenuItem value={50}>50</MenuItem>
                </TextField>
                <Pagination
                  count={Math.ceil(rowCount / pageSize)}
                  page={page}
                  onChange={handlePageChange}
                />
              </Box>
            </Box>
          )}
        </Box>
      )}
    </>
  );
};
