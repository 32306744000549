import React from 'react';

import { MainButton } from '../../buttons';
import { useStyles } from './modal-buttons.hooks';

export const ModalButtons = ({ buttonText1, buttonText2, onClick1, onClick2, icon }) => {
  const classes = useStyles();

  return (
    <div className={classes.buttonsWrapper}>
      <div className={classes.buttonDiv}>
        <MainButton icon="" onClick={onClick1} text={buttonText1} design="secondary" />
      </div>
      <div className={classes.buttonDiv}>
        <MainButton icon={icon} onClick={onClick2} text={buttonText2} design="primary" />
      </div>
    </div>
  );
};
