import { makeStyles } from '@mui/styles';
import deleteModalBckgrnd from '../../../assets/icons/alertPopupBckgrnd.svg';

export const useStyles = makeStyles(theme => ({
  "[data-disabled]": {
    background: "gray"
  },
  modalFooter: {
    display: 'flex',
    textAlign: 'center',
    justifyContent: 'space-around',
    marginTop: '25px',
  },
  maxWidth100:{
    maxWidth: '100%',
    overflow:"hidden"
  },
  failText: {
    textOverflow: "clip",
    whiteSpace: 'break-spaces',
    color: theme.palette.fail.color,
  },
  insideBody: {
    background: theme.palette.transactions.background,
    padding: '16px',
    marginBottom: '16px',
    '& div:not(:last-child)': {
      marginBottom: '2px',
    },
  },
  contentRow: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  modalButton: {
    background: 'none',
    color: theme.palette.blueModalText,
    fontSize: '18px',
    fontWeight: '500',
    border: 'none',
    outline: 'none',
    cursor: 'pointer',
    '&[data-disabled="true"]':{
      color: theme.palette.secondary.dark
    }
  },
  innerModal: {
    position: 'relative',
    height: '100%',
    padding: '47px 16px 16px 16px',
    '& > header': {
      //   marginTop: '44px',
      textAlign: 'center',
      fontWeight: '500',
    },
    '& > p': {
      textAlign: 'center',
      //   marginTop: '22px',
    },
    '& > section': {
      textAlign: 'center',
      //   marginTop: '22px',
    },
  },
  modalBody: {
    textAlign: 'center',
    marginTop: '10px',
  },
  modal: {
    position: 'absolute',
    display: 'flex',
    flexDirection: 'column',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '440px',
    borderRadius: '15px',
    background: theme.palette.white,
    border: 'none',
    fontSize: '18px',
    fontFamily: theme.typography.default,
    color: theme.palette.icons.nav,
  },
  successText: {
    color: theme.palette.successText.color,
  },
  topOfModal: {
    position: 'absolute',
    width: '100%',
    top: 0,
    left: 0,
  },
  iconPlaceholder: {
    position: 'relative',
    marginInline: 'auto',
    width: '85px',
    // padding: '10px 15px',
    textAlign: 'center',
    height: '85px',
    background: theme.palette.white,
    borderTopLeftRadius: '100%',
    borderTopRightRadius: '100%',
    padding: '10px',
    transform: 'translateY(-47px)',
    '& svg': {
      zIndex: '10',
    },
    '&::before , &::after': {
      content: "''",
      position: 'absolute',

      height: '10px',
      width: '20px',
      top: '50%',
      transform: 'translateY(-50%)',
    },
    '&::after': {
      right: ' -20px',
      borderRadius: '0 0 0 10px',
      boxShadow: '-10px 0 0 0 #fff',
    },
    '&::before': {
      left: '-20px',
      borderRadius: '0 0 10px 0',
      boxShadow: '10px 0 0 0 #fff',
    },
  },
  text:{
    'font-family': 'Roboto',
    'font-size': '16px',
    'font-weight': '500',
  },
  success:{
    "font-size": "18px",
    "font-weight": "500",
    "font-stretch": "normal",
    "font-style": "normal",
    "line-height": "1.33",
    "letter-spacing": "normal",
    "text-align": "center",
    "color": "#23c3a4",
    },
  error: {
    "font-size": "18px",
    "font-weight": "500",
    "font-stretch": "normal",
    "font-style": "normal",
    "line-height": "1.33",
    "letter-spacing": "normal",
    "text-align": "center",
    "color": "#fc3333",
  }

}));
