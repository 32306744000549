import React from 'react';

export const EditIcon = ({ width = '24px', height = '24px' }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 24 24">
    <g data-name="Group 10182" transform="translate(-616 -1512)">
      <circle
        data-name="Ellipse 1858"
        cx="12"
        cy="12"
        r="12"
        transform="translate(616 1512)"
        style={{ fill: '#c6ffef' }}
      />
      <path
        data-name="Union 916"
        d="M1.85 12A1.851 1.851 0 0 1 0 10.15V2.9a1.848 1.848 0 0 1 1.85-1.85h3.655a.41.41 0 0 1 .411.385.554.554 0 0 1 0 .062.412.412 0 0 1-.385.438.371.371 0 0 1-.057 0H1.846a.964.964 0 0 0-.965.965v7.2a.968.968 0 0 0 .969.97h7.2a.965.965 0 0 0 .965-.97V6.519a.442.442 0 1 1 .881-.074.369.369 0 0 1 0 .08v3.627A1.778 1.778 0 0 1 9.195 12H1.85zm.45-2.292a.666.666 0 0 1-.145-.421l.526-2.636a.225.225 0 0 1 .123-.2L9.119.138a.442.442 0 0 1 .625-.017l2.118 2.118a.442.442 0 0 1 .016.625L5.572 9.173a1.084 1.084 0 0 1-.235.144l-2.61.523h-.122a.413.413 0 0 1-.305-.132zm1.256-2.72-.332 1.788 1.77-.331h.019L10.849 2.6l.044-.044L9.44 1.107z"
        transform="translate(622 1518)"
        style={{ fill: '#19365e' }}
      />
    </g>
  </svg>
);
