import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Card, CardContent, Grid } from '@mui/material';
import React from 'react';

import { connectString } from '../../../../utils';
import { BackButton, MainButton } from '../../../buttons';
import { pageMenu } from '../../user-item.constants';
import { PageNavigation } from '../page-navigation';
import { useStyles } from './user-item-header.hooks';

const mappedMenu = pageMenu.map(menuItem => connectString(menuItem));

export const UserItemHeader = ({ onOpenSelectedCard, handleSubmit, activeCard, headerRef }) => {
  const classes = useStyles();

  const activeTabIndex = mappedMenu.indexOf(activeCard);
  const activeTab = activeTabIndex > -1 ? activeTabIndex : 0;

  return (
    <Card classes={{ root: classes.cardNoShadow }}>
      <CardContent>
        <Grid
          className={classes.header}
          container
          item
          justifyContent="flex-end"
          alignItems="center"
          spacing={0}
          xs={12}
          md={12}
          wrap="nowrap"
        >
          <Grid
            container
            item
            justifyContent="space-between"
            xs={9}
            wrap="nowrap"
            style={{ marginRight: '1em' }}
          >
            <PageNavigation
              menu={pageMenu}
              onOpenSelectedCard={onOpenSelectedCard}
              activeTab={activeTab}
              headerRef={headerRef}
            />
          </Grid>
          <Grid item xs={1} className={classes.backButtonWrapper}>
            <BackButton />
          </Grid>
          <Grid item xs={2}>
            <MainButton
              type="submit"
              icon={<CheckCircleIcon />}
              onClick={handleSubmit}
              text="Save"
              maxWidth
              design="primary"
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};
