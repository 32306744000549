const transactionSearchOptions = Object.freeze({
  NAME: 'Name',
  TRANSACTION_ID: 'Transaction ID',
  REFERENCE: 'Reference',
  ACCOUNT_NUMBER: 'Account Number',
});

const customerSearchOptions = Object.freeze({
  name: 'Name',
  email: 'Email',
  accountNumber: 'Account No.',
  customerId: 'Internal Id',
  phoneNo: 'Phone No.',
  dateOfBirth: 'Date of Birth',
});

export { customerSearchOptions, transactionSearchOptions };
