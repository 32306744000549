import React from 'react';

export const KixyTitle = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="81" height="41.044" viewBox="0 0 81 41.044">
    <g data-name="Group 10178">
      <path
        data-name="Union 920"
        d="M65.616 39.733A9.672 9.672 0 0 1 63 38.891q-1.643-.756-1.642-2.042a2.455 2.455 0 0 1 .088-.577 2.545 2.545 0 0 1 .754-1.155 1.711 1.711 0 0 1 1.111-.4 1.688 1.688 0 0 1 .666.133q1.376.62 2.442.977a7.889 7.889 0 0 0 2.486.356q6.881 0 6.882-7.06v-.887a8.091 8.091 0 0 1-3.087 2.486 10.062 10.062 0 0 1-4.328.887 7.242 7.242 0 0 1-5.748-2.353 9.011 9.011 0 0 1-2.066-6.171V9.81a2.122 2.122 0 0 1 .577-1.532 2.006 2.006 0 0 1 1.511-.6 2.039 2.039 0 0 1 1.532.622 2.1 2.1 0 0 1 .6 1.508v12.167A6.227 6.227 0 0 0 66.06 26.3a5.059 5.059 0 0 0 3.907 1.4 5.741 5.741 0 0 0 4.174-1.6 5.51 5.51 0 0 0 1.643-4.13V9.81a2.118 2.118 0 0 1 .577-1.532 2 2 0 0 1 1.509-.6 2.033 2.033 0 0 1 1.53.622 2.1 2.1 0 0 1 .6 1.51v19.18q0 5.549-3.063 8.3a11.635 11.635 0 0 1-8.08 2.752 16.9 16.9 0 0 1-3.241-.309zm-45.461-9.277-8.938-11.513 3.3-3.133 9.105 11.761a2.3 2.3 0 0 1 .489 1.464 2 2 0 0 1-.733 1.6 2.5 2.5 0 0 1-1.666.621 1.978 1.978 0 0 1-1.557-.8zM.6 30.546a2.149 2.149 0 0 1-.6-1.554V2.221A2.049 2.049 0 0 1 .621.711 2.157 2.157 0 0 1 2.219.09a2.122 2.122 0 0 1 1.576.6 2.072 2.072 0 0 1 .6 1.532v13.805L20.155.754A2.554 2.554 0 0 1 21.842 0a1.763 1.763 0 0 1 1.376.667 2.139 2.139 0 0 1 .582 1.421 2.133 2.133 0 0 1-.621 1.376l-7.363 6.959-.008-.009-1.685 1.609-1.466 1.385.006.009-3.296 3.143a15.913 15.913 0 0 0-4.973 11.552v.88a2.113 2.113 0 0 1-.621 1.554 2.163 2.163 0 0 1-1.6.62 2.092 2.092 0 0 1-1.573-.62zm52.663.31a13.2 13.2 0 0 1 0-22.865 2.078 2.078 0 1 1 2.082 3.6 9.05 9.05 0 0 0 0 15.673 2.077 2.077 0 1 1-2.081 3.6z"
        transform="translate(.5 .5)"
        style={{ fill: '#19365e', stroke: 'transparent', strokeMiterlimit: 10 }}
      />
      <path
        data-name="Path 9145"
        d="M23.159 7.249a2.109 2.109 0 1 1-2.108-2.108 2.109 2.109 0 0 1 2.108 2.108"
        transform="translate(9.861 3.041)"
        style={{ fill: '#1fe3ac' }}
      />
      <path
        data-name="Path 9146"
        d="M26.742 28.586A2.078 2.078 0 0 1 25.7 24.71a9.049 9.049 0 0 0 0-15.673 2.078 2.078 0 1 1 2.081-3.6 13.2 13.2 0 0 1 0 22.866 2.066 2.066 0 0 1-1.038.279"
        transform="translate(12.687 3.051)"
        style={{ fill: '#1fe3ac' }}
      />
      <path
        data-name="Path 9147"
        d="M21.117 13.6a2.109 2.109 0 0 0-2.108 2.108l-.067 6.63a2.109 2.109 0 1 0 4.218 0l.066-6.63a2.109 2.109 0 0 0-2.109-2.108"
        transform="translate(9.861 7.22)"
        style={{ fill: '#1fe3ac' }}
      />
    </g>
  </svg>
);
