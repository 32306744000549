import React from 'react';

export const MessageBlank = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="56" viewBox="0 0 799.999 56">
    <path
      data-name="Union 890"
      d="M10920 2329a16 16 0 0 1-16-16v-16h784a16 16 0 1 1 0 32zm702-40a6 6 0 0 1 0-12h76a6 6 0 1 1 0 12zm-686 0a8 8 0 1 1 0-16h32a8 8 0 1 1 0 16zm-32-8a8 8 0 1 1 8 8 8 8 0 0 1-8-8z"
      transform="translate(-10904.001 -2272.999)"
      style={{ fill: '#f2f2f4' }}
    />
  </svg>
);
