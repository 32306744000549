import { Drawer } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import clsx from 'clsx';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { KixySideNavBarLogo, LogoutSidebarIcon } from '../../../../components/icons';
import { UserContext } from '../../../../contexts/user-role';
import { signOut } from '../../../../services';
import { SidebarNav } from './components';
import { fetchAndUpdatePages } from './sidebar.constants';
import { useStyles } from './sidebar.hooks';

const crossMatch = (arr1, arr2) => (arr1 ?? []).some(item => (arr2 ?? []).includes(item));

export const Sidebar = props => {
  const { open, variant, onClose, className, ...rest } = props;
  const { userRoles, setUserRoles, setUserName } = useContext(UserContext);
  const navigate = useNavigate();
  const classes = useStyles();
  const [pages, setPages] = useState([]);
  
  useEffect(() => {
    const updatePages = async () => {
      try {
        const updatedPages = await fetchAndUpdatePages();
        setPages(updatedPages);
      } catch (error) {
        console.error('Error updating pages:', error);
      }
    };

    updatePages();
  }, []);

  const handleLogout = async () => {
    window.sessionStorage.clear();
    await signOut();
    setUserName(null);
    setUserRoles([]);
    navigate('/sign-in');
  };

  const pagesForUser = pages.filter(item => {
    if (!item.roles) {
      return true;
    }

    return crossMatch(userRoles, item.roles);
  });

  return (
    <Drawer
      anchor="left"
      classes={{ paper: classes.drawer }}
      onClose={onClose}
      open={open}
      variant={variant}
    >
      <div {...rest} className={clsx(classes.root, className)}>
        <div className={classes.logo}>
          <KixySideNavBarLogo className={classes.logo} />
        </div>
        <SidebarNav className={classes.nav} pages={pagesForUser} />
        <div className={classes.logout} onClick={handleLogout}>
          <Tooltip title="Log out">
            <IconButton>
              <LogoutSidebarIcon />
            </IconButton>
          </Tooltip>
        </div>
      </div>
    </Drawer>
  );
};
