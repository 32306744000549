import React from 'react';

export const TRY = ({ width = '45', height = '45' }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 48 48">
    <defs>
      <clipPath id="4luls2crba">
        <path data-name="Rectangle 16644" style={{ fill: '#fff' }} d="M0 0h48v48H0z" />
      </clipPath>
    </defs>
    <g data-name="Group 8522">
      <g data-name="Group 107" style={{ clipPath: 'url(#4luls2crba)' }}>
        <path
          data-name="Path 10592"
          d="M10056 10079.6a24 24 0 1 0-24-24 24 24 0 0 0 24 24z"
          transform="translate(-10032.001 -10031.6)"
          style={{ fill: '#d80027' }}
        />
        <path
          data-name="Path 10593"
          d="m10246.688 10240.8 1.969 2.71 3.188-1.031-1.969 2.71 1.969 2.709-3.187-1.031-1.969 2.709v-3.355l-3.187-1.031 3.188-1.041z"
          transform="translate(-10223.672 -10221.188)"
          style={{ fill: '#f0f0f0' }}
        />
        <path
          data-name="Path 10594"
          d="M10114.26 10213.631a6.782 6.782 0 1 1 3.226-12.749 8.35 8.35 0 1 0 0 11.934 6.655 6.655 0 0 1-3.226.815z"
          transform="translate(-10096.614 -10182.854)"
          style={{ fill: '#f0f0f0' }}
        />
      </g>
    </g>
  </svg>
);
