import React from 'react';

export const SmallSaveBtn = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40">
    <g data-name="Group 8571" transform="translate(-480 -440)">
      <circle
        data-name="Ellipse 1515"
        cx="20"
        cy="20"
        r="20"
        transform="translate(480 440)"
        style={{ fill: '#c6ffef' }}
      />
      <path
        data-name="Path 19478"
        d="M19.721 5.306 14.694.279A.956.956 0 0 0 14.021 0H2.963A2.967 2.967 0 0 0 0 2.963v14.074A2.966 2.966 0 0 0 2.963 20h14.074A2.966 2.966 0 0 0 20 17.037V5.979a.952.952 0 0 0-.279-.673M14.074 18.1H5.926v-6.142h8.149zm4.026-1.063a1.06 1.06 0 0 1-1.063 1.063h-1.058v-7.09a.952.952 0 0 0-.952-.952H4.973a.953.953 0 0 0-.952.952v7.09H2.963A1.06 1.06 0 0 1 1.9 17.037V2.963A1.06 1.06 0 0 1 2.963 1.9h1.058v3.631a.953.953 0 0 0 .952.952h8.042a.952.952 0 0 0 0-1.9H5.926V1.9h7.7L18.1 6.373z"
        transform="translate(490 450)"
        style={{ fill: '#19365e' }}
      />
    </g>
  </svg>
);
