import { makeStyles } from '@mui/styles';
import React from 'react';

import { GenericEditForm } from '../../components';

const useStyles = makeStyles(() => ({
  root: {
    padding: '3.2rem',
  },
}));

export const GenericEdit = ({ entity }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <GenericEditForm entity={entity} />
    </div>
  );
};
