export const adminRows = [
  {
    name: 'Amir Zarifnia',
    email: 'amir@kixy.com',
    role: 'Owner',
    lastSeen: 'Online',
    status: 'active',
  },
  {
    name: 'Mehrdad Sadeghi',
    email: 'mehrdad@kixy.com',
    role: 'Role 1',
    lastSeen: '20 Minutes',
    status: 'active',
  },
  {
    name: 'Peyman Gholipour',
    email: 'peyman@kixy.com',
    role: 'Role 2',
    lastSeen: '7 Days',
    status: 'active',
  },
  {
    name: 'Tamara Veron',
    email: 'tamara@kixy.com',
    role: 'Compliance Officer',
    lastSeen: '1 Year',
    status: 'inactive',
  },
  {
    name: 'Silky Ng',
    email: 'silky@kixy.com',
    role: 'Developer',
    lastSeen: '5 Minutes',
    status: 'active',
  },
  {
    name: 'Kasem Yassine',
    email: 'kasem@kixy.com',
    role: 'Mobile App Developer',
    lastSeen: '2 Minutes',
    status: 'active',
  },
  {
    name: 'Mohammad Sarkandi',
    email: 'mohammad@kixy.com',
    role: 'BackEnd Developer',
    lastSeen: '2 Minutes',
    status: 'active',
  },
];
