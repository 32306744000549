import clsx from 'clsx';
import React, { useContext, useState } from 'react';

import { UserContext } from '../../contexts/user-role';
import { SignIn } from '../../views';
import { Sidebar } from './components';
import { BlankSidebarNav } from './components/sidebar/components/blankSidebar-nav';
import { useStyles } from './main.hooks';

export const Main = ({ children }) => {
  const { userName } = useContext(UserContext);
  const classes = useStyles();
  const isDesktop = true;

  const [openSidebar, setOpenSidebar] = useState(false);

  const handleSidebarClose = () => {
    setOpenSidebar(false);
  };

  const shouldOpenSidebar = isDesktop ? true : openSidebar;

  return userName ? (
    <div
      className={clsx({
        [classes.root]: true,
        [classes.shiftContent]: isDesktop,
      })}
    >
      <Sidebar
        onClose={handleSidebarClose}
        open={shouldOpenSidebar}
        variant={isDesktop ? 'persistent' : 'temporary'}
      />
      <main className={classes.content}>{children}</main>
    </div>
  ) : (
    <div className={classes.loginContent}>
      <BlankSidebarNav />
      <div className={classes.contentContainer}>
        <div className={classes.loginToolbar}>
          <header>Login</header>
        </div>
        <div className={classes.signInContainer}>
          <SignIn />
        </div>
      </div>
    </div>
  );
};
