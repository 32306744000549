const pageMenu = ['User data', 'KYC/KYB status', 'Wallets', 'Transactions', 'Notifications'];

const userItemFields = {
  settingsFieldsHidden: [
    'membership',
    'accountType',
    'accountStatus',
    'phoneNumberVerified',
    'emailVerified',
    'smsMarketingConsent',
    'pushMarketingConsent',
    'emailMarketingConsent',
  ],
  settingsFields: ['accountType', 'accountStatus'],
  informationFields: [
    'country',
    'city',
    'address',
    'birthdate',
    'postalCode',
    'phoneCode',
    'phoneNumber',
  ],
};

const currencyFlag = {
  GBP: '🇬🇧',
  USD: '🇺🇸',
  EUR: '🇪🇺',
  JPY: '🇯🇵',
  CNY: '🇨🇳',
  INR: '🇮🇳',
  NZD: '🇳🇿',
  AUD: '🇦🇺',
  CAD: '🇨🇦',
  CHF: '🇨🇭',
  NOK: '🇳🇴',
  SEK: '🇸🇪',
  AFN: '🇦🇫',
  ALL: '🇦🇱',
  DZD: '🇩🇿',
  AOA: '🇦🇴',
  ARS: '🇦🇷',
  MORE: '',
};

const visibleElementsCount = 5;

const NO_AUTO_FIELDS = ['kyc', 'poundsAccount'];

const entity = 'UserProfile';

const initialState = {
  confirmModalState: {
    isShown: false,
  },
  activeCard: '',
  confirmOptions: {},
};

export {
  currencyFlag,
  entity,
  initialState,
  NO_AUTO_FIELDS,
  pageMenu,
  userItemFields,
  visibleElementsCount,
};
