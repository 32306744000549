/* eslint-disable react/jsx-no-useless-fragment */
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { PDFDownloadLink } from '@react-pdf/renderer';
import React, { useEffect, useRef, useState } from 'react';
import { CSVLink } from 'react-csv';
import { useNavigate, useParams } from 'react-router-dom';

import { AddAttachmentIcon, ArrowDownIcon, DownloadIcon } from '../../components/icons';
import { Loader } from '../../components/loader';
import { entitiesEnum } from '../../constants';
import { graphqlApiDecorator } from '../../decorators';
import { queries } from '../../graphql';
import { editTransaction, getEntity, getFileURL, uploadFile } from '../../services';
import { getCurrencySymbol, getFormattedDisplayDate, getFormattedDisplayTime } from '../../utils';
import {
  DoubleArrowIcon,
  RedDeleteIcon,
  RedirectLinkIcon,
  SideRectangleIcon,
  SmallDeleteIcon,
} from './icons';
import { PdfExportService } from './pdfExport/pdfExportService';
import {
  modifyBeneficiaryAccountDetails,
  modifyRemittingAccountDetails,
  modifyRemittingInstitutionDetails,
  transactionDetailsObj,
} from './transaction-details.utils';
import { TransactionDetailsCard } from './transaction-details-card';
import { useStyles } from './transaction-details-page.hooks';

const XLSX = require('xlsx');

// This displays more details about the transaction from the id
export const TransactionDetailsPage = ({ entity }) => {
  const { id } = useParams();
  const [transactionData, setTransactionData] = useState({});
  const [balanceLogsData, setBalanceLogsData] = useState({});
  const [loading, setLoading] = useState(true);
  const [fileNameAttachments, setFileNameAttachments] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [addAttachmentDropdownSelected, setAddAttachmentDropdownSelected] = useState(false);
  const [downloadDropdownSelected, setDownloadDropdownSelected] = useState(false);
  const [open, setOpen] = useState(false);
  const [attachmentToDelete, setAttachmentToDelete] = useState(null);
  const classes = useStyles();
  const navigate = useNavigate();

  const cancel = () => setOpen(false);
  const inputFile = useRef(null);
  const attachmentRef = useRef(null);
  const downloadRef = useRef(null);
  let headerTitle = '';
  const remittingUserProfileID = transactionData.debtorUserId ?? '';
  const beneficiaryUserProfileID = transactionData.creditorUserId ?? '';

  if (transactionData.creditorUserId) {
    headerTitle = `Incoming Transaction to ${transactionData.creditorName}`;
  }

  if (transactionData.subType === 'TRANSFER' || transactionData.subType === 'DIRECT_DEBIT') {
    let beneficiaryName = '';
    if (transactionData.creditorAccount && transactionData.subType === 'TRANSFER') {
      beneficiaryName = transactionData.creditorName || '';
    }

    if (transactionData.creditorUserId) {
      beneficiaryName = transactionData.creditorName || '';
    }
    headerTitle = `Outgoing Transaction to ${beneficiaryName}`;
  }

  if (transactionData.subType === 'CONVERT') {
    headerTitle = `Exchange Transaction to ${transactionData.creditorName}`;
  }

  headerTitle = headerTitle.replace('undefined', '');

  useEffect(() => {
    const checkIfClickedOutside = event => {
      if (
        addAttachmentDropdownSelected &&
        attachmentRef.current &&
        !attachmentRef.current.contains(event.target)
      ) {
        setAddAttachmentDropdownSelected(false);
      }

      if (
        downloadDropdownSelected &&
        downloadRef.current &&
        !downloadRef.current.contains(event.target)
      ) {
        setDownloadDropdownSelected(false);
      }
    };

    document.addEventListener('click', checkIfClickedOutside);

    return () => {
      document.removeEventListener('click', checkIfClickedOutside);
    };
  }, [addAttachmentDropdownSelected, downloadDropdownSelected]);

  useEffect(() => {
    const fetchEntity = async () => {
      const { data } = await getEntity(entity, id);
      setTransactionData(data);
      if (data.attachment) setFileNameAttachments(data.attachment.split(','));
      try {
        const balanceLogs = await graphqlApiDecorator(queries, 'listBalanceLogs', {
          transactionId: data.metadataId,
        });
        setBalanceLogsData(balanceLogs.items);
        setLoading(false);
      } catch (error) {
        console.log('error fetching balanceLogs');
      }
    };
    fetchEntity();
  }, []);

  useEffect(() => {
    if (headerTitle) document.title = `Transaction Details: ${headerTitle}`;
  }, [headerTitle]);

  useEffect(() => {
    const uploadAttachment = async () => {
      const timestamp = +new Date();
      const fileName = `adminAttachment-${timestamp}-${selectedFile.name}`
        .replace(/\s/g, '-')
        .replace(/á|à|â|é|è|ê|í|ì|î|ó|ò|ô|ú|ù|û|ç|ñ`/gi, '-');
      const result = await uploadFile(selectedFile, fileName);
      if (result.success) {
        // Add fileName to adminAttachments property for transaction record
        const adminAttachments = transactionData.attachment
          ? `${transactionData.attachment},${fileName}`
          : fileName;
        const updateAdminAttachment = await editTransaction({
          id,
          adminAttachments,
        });
        if (updateAdminAttachment.success) setFileNameAttachments(prev => [...prev, fileName]);
      }
    };
    if (selectedFile) {
      uploadAttachment();
    }
  }, [selectedFile]);

  const handleFileUpload = event => {
    setSelectedFile(event.target.files[0]);
  };

  const getAttachmentFileName = fileName => {
    // Get specific name of attachment file
    if (fileName) {
      const attachmentFileName = fileName.split('adminAttachment-')[1];

      return attachmentFileName.slice(14);
    }

    return '';
  };

  const {
    transactionDetailsLHS,
    transactionDetailsRHS,
    remittingAccountDetails,
    beneficiaryAccountDetails,
    remittingInstitutionDetails,
    beneficiaryInstitutionDetails,
  } = transactionDetailsObj(transactionData);

  const pdfDetails = {
    transactionDetailsLHS,
    transactionDetailsRHS,
    remittingAccountDetails,
    beneficiaryAccountDetails,
    remittingInstitutionDetails,
    beneficiaryInstitutionDetails,
  };

  const csvData = [
    {
      ...transactionDetailsLHS,
      ...transactionDetailsRHS,
      ...modifyRemittingAccountDetails(remittingAccountDetails),
      ...modifyBeneficiaryAccountDetails(beneficiaryAccountDetails),
      ...modifyRemittingInstitutionDetails(remittingInstitutionDetails),
      ...beneficiaryInstitutionDetails,
    },
  ];

  const addAttachment = () => {
    inputFile.current.click();
  };

  const deleteAttachment = async fileName => {
    const adminAttachmentsArray = fileNameAttachments.filter(attachment => attachment !== fileName);
    const adminAttachments = adminAttachmentsArray.join(',');
    const deleteFile = await editTransaction({ id, adminAttachments });
    if (deleteFile.success) setFileNameAttachments(adminAttachments.split(','));
  };

  const downloadExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(csvData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Transaction Details');
    XLSX.writeFile(workbook, `${headerTitle}.xlsx`);
    setDownloadDropdownSelected(false);
  };

  const formatAmount = item =>
    `${getCurrencySymbol(item.currency)}${Number(item.amount).toLocaleString()}`;

  const getFormattedDateAndTime = timeStamp =>
    `${getFormattedDisplayDate(timeStamp)} | ${getFormattedDisplayTime(timeStamp)}`;

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          <div className={classes.toolbar}>
            <header>{headerTitle}</header>
            <aside>
              <div className={classes.defaultRHSaside}>
                <div className={classes.outlineContainer} ref={attachmentRef}>
                  <div className={classes.icon} onClick={addAttachment}>
                    <input
                      type="file"
                      ref={inputFile}
                      style={{ display: 'none' }}
                      onChange={handleFileUpload}
                    />
                    <AddAttachmentIcon />
                  </div>
                  <div className={classes.defaultRHSasideLabel}>
                    {fileNameAttachments.length > 0 && fileNameAttachments[0] ? (
                      <p>{`Added (${fileNameAttachments.length})`}</p>
                    ) : (
                      <p>Add</p>
                    )}
                    <p>Attachment</p>
                  </div>
                  {fileNameAttachments.length > 0 && fileNameAttachments[0] && (
                    <div
                      className={classes.iconDropdown}
                      onClick={() =>
                        setAddAttachmentDropdownSelected(!addAttachmentDropdownSelected)
                      }
                    >
                      <ArrowDownIcon />
                    </div>
                  )}
                  {addAttachmentDropdownSelected && (
                    <div className={classes.listDropdown}>
                      {fileNameAttachments.length > 0 &&
                        fileNameAttachments[0] &&
                        fileNameAttachments.map(attachment => (
                          <div
                            key={attachment}
                            className={`${classes.listRow} ${classes.listRowAttachmentItem}`}
                          >
                            {attachment ? (
                              <>
                                <a href={getFileURL(attachment)} download>
                                  {getAttachmentFileName(attachment)}
                                </a>
                                <div
                                  onClick={() => {
                                    setAttachmentToDelete(attachment);
                                    setOpen(true);
                                  }}
                                >
                                  <SmallDeleteIcon />
                                </div>
                              </>
                            ) : (
                              <p>No attachments</p>
                            )}
                          </div>
                        ))}
                    </div>
                  )}
                </div>
              </div>
              <div className={classes.outlineContainer} ref={downloadRef}>
                <div className={classes.defaultRHSaside}>
                  <div className={classes.icon}>
                    <PDFDownloadLink
                      document={
                        <PdfExportService pdfDetails={pdfDetails} headerTitle={headerTitle} />
                      }
                      fileName={`${headerTitle}.pdf`}
                    >
                      <DownloadIcon width="40" height="40" />
                    </PDFDownloadLink>
                  </div>
                  <div className={classes.defaultRHSasideLabel}>
                    <p>Download</p>
                    <p>Transaction Details</p>
                  </div>
                  <div
                    className={classes.iconDropdown}
                    onClick={() => setDownloadDropdownSelected(!downloadDropdownSelected)}
                  >
                    <ArrowDownIcon />
                  </div>
                  {downloadDropdownSelected && (
                    <div className={`${classes.listDropdown}`}>
                      <div className={`${classes.listRow} ${classes.listDropdownDownload}`}>
                        <PDFDownloadLink
                          document={
                            <PdfExportService pdfDetails={pdfDetails} headerTitle={headerTitle} />
                          }
                          fileName={`${headerTitle}.pdf`}
                        >
                          {({ loading }) => (loading ? 'Loading document...' : 'Download PDF')}
                        </PDFDownloadLink>
                      </div>
                      <div
                        className={`${classes.listRow} ${classes.listDropdownDownload}`}
                        onClick={downloadExcel}
                      >
                        Download Excel
                      </div>
                      <div className={`${classes.listRow} ${classes.listDropdownDownload}`}>
                        <CSVLink filename={`${headerTitle}.csv`} data={csvData}>
                          <p>Download CSV</p>
                        </CSVLink>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </aside>
          </div>
          <div className={classes.mainTransactionContainer}>
            <div className={classes.defaultContainerHeader}>
              <div className={classes.headerSection}>
                <SideRectangleIcon />
                <header>Transaction Details</header>
              </div>
            </div>
            <section className={classes.transactionDetailsContent}>
              <aside className={classes.transactionDetailsContentLHS}>
                <TransactionDetailsCard detailsObj={transactionDetailsLHS} />
              </aside>
              <div className={classes.verticalLine} />
              <aside className={classes.transactionDetailsContentRHS}>
                <TransactionDetailsCard detailsObj={transactionDetailsRHS} />
              </aside>
            </section>
          </div>
          <section className={classes.sectionRow}>
            <div className={classes.transactionDetailsContainer}>
              <div className={classes.defaultContainerHeader}>
                <div className={classes.headerSection}>
                  <SideRectangleIcon />
                  <header>Remitting Account</header>
                </div>

                {remittingUserProfileID && (
                  <div
                    style={{ cursor: 'pointer' }}
                    onClick={() =>
                      navigate(
                        `/${entitiesEnum.USERPROFILE.toLowerCase()}/${remittingUserProfileID}`
                      )
                    }
                  >
                    <RedirectLinkIcon />
                  </div>
                )}
              </div>
              <section className={classes.transactionDetailsContent}>
                <aside className={classes.transactionDetailsContentOneSide}>
                  <TransactionDetailsCard detailsObj={remittingAccountDetails} />
                </aside>
              </section>
            </div>
            <div className={classes.doubleArrow}>
              <DoubleArrowIcon />
            </div>
            <div className={classes.transactionDetailsContainer}>
              <div className={classes.defaultContainerHeader}>
                <div className={classes.headerSection}>
                  <SideRectangleIcon />
                  <header>Beneficiary Account</header>
                </div>
                {beneficiaryUserProfileID && (
                  <div
                    style={{ cursor: 'pointer' }}
                    onClick={() =>
                      navigate(
                        `/${entitiesEnum.USERPROFILE.toLowerCase()}/${beneficiaryUserProfileID}`
                      )
                    }
                  >
                    <RedirectLinkIcon />
                  </div>
                )}
              </div>
              <section className={classes.transactionDetailsContent}>
                <aside className={classes.transactionDetailsContentOneSide}>
                  <TransactionDetailsCard detailsObj={beneficiaryAccountDetails} />
                </aside>
              </section>
            </div>
          </section>
          <section className={classes.sectionRow}>
            <div className={classes.transactionDetailsContainer}>
              <div className={classes.defaultContainerHeader}>
                <div className={classes.headerSection}>
                  <SideRectangleIcon />
                  <header>Remitting Institution</header>
                </div>
              </div>
              <section className={classes.transactionDetailsContent}>
                <aside className={classes.transactionDetailsContentOneSide}>
                  <TransactionDetailsCard detailsObj={remittingInstitutionDetails} />
                </aside>
              </section>
            </div>
            <div className={classes.doubleArrow}>
              <DoubleArrowIcon />
            </div>
            <div className={classes.transactionDetailsContainer}>
              <div className={classes.defaultContainerHeader}>
                <div className={classes.headerSection}>
                  <SideRectangleIcon />
                  <header>Beneficiary Institution</header>
                </div>
              </div>
              <section className={classes.transactionDetailsContent}>
                <aside className={classes.transactionDetailsContentOneSide}>
                  <TransactionDetailsCard detailsObj={beneficiaryInstitutionDetails} />
                </aside>
              </section>
            </div>
          </section>
          <section className={classes.sectionRow}>
            <div className={classes.balanceLogsContainer}>
              <section className={classes.transactionDetailsContent}>
                <div className={classes.balanceLogs}>
                  {balanceLogsData?.length ? (
                    balanceLogsData.map(item => (
                      <div key={item.id} className={classes.userLogRow}>
                        <aside className={classes.lhsRow}>
                          <div className={classes.bulletPoint} />
                          <span style={{ color: '#a5a6ae' }}>Amount transferred:</span>
                          <p style={{ fontWeight: '500', width: '78px' }}>{formatAmount(item)}</p>
                          <span style={{ color: '#a5a6ae' }}>Wallet: </span>
                          <span style={{ fontWeight: '500' }}>{`${item.currency}`}</span>
                          <span style={{ color: '#a5a6ae' }}>User ID: </span>
                          <span style={{ fontWeight: '500' }}>{item.id}</span>
                        </aside>
                        <aside className={classes.rhsRow}>
                          <p>{getFormattedDateAndTime(item.createdTimestamp)}</p>
                        </aside>
                      </div>
                    ))
                  ) : (
                    <p>No balance logs</p>
                  )}
                </div>
              </section>
            </div>
          </section>
          <Modal open={open} onClose={() => setOpen(false)}>
            <Box>
              <div className={classes.deleteModal}>
                <div className={classes.redDeleteIcon}>
                  <RedDeleteIcon />
                </div>
                <header>Delete File</header>
                <p>{`Delete: ${getAttachmentFileName(attachmentToDelete)} ?`}</p>
                <section className={classes.cancelDelSection}>
                  <div className={classes.cancel} onClick={cancel}>
                    Cancel
                  </div>
                  <div
                    className={classes.deleteIt}
                    onClick={() => {
                      deleteAttachment(attachmentToDelete);
                      setOpen(false);
                      setAddAttachmentDropdownSelected(false);
                    }}
                  >
                    Delete it
                  </div>
                </section>
              </div>
            </Box>
          </Modal>
        </>
      )}
    </>
  );
};
