import { transactionSortEnum } from '../constants';

export const sortTransactionData = (tableBodyData, transactionSort) => {
  if (transactionSort === transactionSortEnum.DATENEWEST) {
    const sortedData = [...tableBodyData];
    sortedData.sort((a, b) => +new Date(b.createdAt) - +new Date(a.createdAt));

    return sortedData;
  }

  if (transactionSort === transactionSortEnum.DATEOLDEST) {
    const sortedData = [...tableBodyData];
    sortedData.sort((a, b) => +new Date(a.createdAt) - +new Date(b.createdAt));

    return sortedData;
  }

  if (transactionSort === transactionSortEnum.AMOUNTLARGEST) {
    const sortedData = [...tableBodyData];
    sortedData.sort((a, b) => b.sendingAmount - a.sendingAmount);

    return sortedData;
  }

  if (transactionSort === transactionSortEnum.AMOUNTSMALLEST) {
    const sortedData = [...tableBodyData];
    sortedData.sort((a, b) => a.sendingAmount - b.sendingAmount);

    return sortedData;
  }

  return tableBodyData;
};
