import React from 'react';

export const NewPlusIcon = ({ width = '26', height = '26', color = '#1fe3ac' }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 26.002 26.002">
    <path
      data-name="Union 835"
      d="M12959-4329a13 13 0 0 1 13-13 13 13 0 0 1 13 13 13 13 0 0 1-13 13 13 13 0 0 1-13-13zm4.729-8.274a11.613 11.613 0 0 0-3.428 8.274 11.611 11.611 0 0 0 3.428 8.271 11.621 11.621 0 0 0 8.271 3.428 11.626 11.626 0 0 0 8.273-3.428 11.624 11.624 0 0 0 3.429-8.271 11.626 11.626 0 0 0-3.429-8.274 11.626 11.626 0 0 0-8.273-3.429 11.622 11.622 0 0 0-8.272 3.431zm7.623 14.273v-5.338h-5.335a.649.649 0 0 1-.652-.648.65.65 0 0 1 .652-.652h5.335v-5.332a.649.649 0 0 1 .648-.652.649.649 0 0 1 .651.652v5.332h5.335a.649.649 0 0 1 .648.652.648.648 0 0 1-.648.648h-5.335v5.339a.649.649 0 0 1-.651.648.648.648 0 0 1-.649-.648z"
      transform="translate(-12958.999 4342.001)"
      style={{ fill: color }}
    />
  </svg>
);
