import { Grid, Typography } from '@mui/material';
import React from 'react';

import { LineWithArrow } from '../../line-with-arrow';
import { useStyles } from './pairs-header.hooks';

export const PairsHeader = () => {
  const classes = useStyles();

  return (
    <Grid container spacing={0} className={classes.root}>
      <Grid item xs={1.5} />
      <Grid item xs={2}>
        <Typography className={classes.title}>Flat Rates</Typography>
        <LineWithArrow />
      </Grid>
      <Grid item xs={3.5}>
        <Typography className={classes.title}>Profit Margin Sell</Typography>
        <LineWithArrow />
      </Grid>
      <Grid item xs={2}>
        <Typography className={classes.title}>Sells Rates</Typography>
        <LineWithArrow />
      </Grid>
      <Grid item xs={3}>
        <Typography className={classes.title}>Fee</Typography>
        <LineWithArrow />
      </Grid>
      <Grid item xs={9} />
      <Grid item xs={3} className={classes.subTitleWrapper}>
        <Typography className={classes.subTitle}>Percentage %</Typography>
        <Typography className={classes.subTitle}>Absolute</Typography>
      </Grid>
    </Grid>
  );
};
