import React from 'react';

export const ArrowUpIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="22" height="12" viewBox="0 0 22 12">
    <path
      data-name="Path 2"
      d="M10 11a1 1 0 0 1-.707-.293l-10-10a1 1 0 0 1 0-1.414 1 1 0 0 1 1.414 0L10 8.586l9.293-9.293a1 1 0 0 1 1.414 0 1 1 0 0 1 0 1.414l-10 10A1 1 0 0 1 10 11z"
      transform="rotate(180 10.5 5.5)"
      style={{ fill: '#19365e' }}
    />
  </svg>
);
