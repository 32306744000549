import { TableCell } from '@mui/material';
import React from 'react';

import { entitiesEnum } from '../../../../../constants';
import { FilterRange } from '../../filter-range';
import { FilterSelectEntity } from '../../filter-select-entity';
import { FilterSelectEnum } from '../../filter-select-enum';
import { FilterString } from '../../filter-string';
import { useStyles } from '../generic-table-head.hooks';

export const CommonTableCell = ({ entity, field, filters, setFilters }) => {
  const classes = useStyles();
  const isCountry = entity === entitiesEnum.COUNTRY;
  const { label, filter } = field;
  const capitalizedLabel = label.charAt(0).toUpperCase() + label.slice(1);

  let cellContent = capitalizedLabel === 'Active' ? 'Status' : capitalizedLabel;

  const cellProps = {
    field,
    filters,
    setFilters,
  };

  if (filter?.type === 'select') {
    if (filter.from === 'entity') {
      cellContent = <FilterSelectEntity {...cellProps} />;
    }

    if (filter.from === 'enum') {
      cellContent = <FilterSelectEnum {...cellProps} />;
    }
  }

  if (filter?.type === 'string') {
    cellContent = <FilterString {...cellProps} />;
  }

  if (filter?.type === 'range') {
    cellContent = <FilterRange {...cellProps} />;
  }

  return (
    <TableCell className={isCountry ? classes.tableHeadCellCountries : classes.tableHeadCell}>
      {cellContent}
    </TableCell>
  );
};
