import React from 'react';

export const BHD = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="45" height="45" viewBox="0 0 45 45">
    <g data-name="Group 6262">
      <path
        data-name="Path 9257"
        d="M7318.5 957a22.5 22.5 0 1 0-22.5-22.5 22.5 22.5 0 0 0 22.5 22.5z"
        transform="translate(-7296 -912)"
        style={{ fill: '#f0f0f0' }}
      />
      <path
        data-name="Path 9258"
        d="M7410.1 912a23.052 23.052 0 0 0-12.452 3.621l5.886 3.878-7.363 3.75 7.363 3.75-7.363 3.749 7.363 3.75-7.363 3.749 7.363 3.75-7.363 3.75 7.363 3.749-5.886 3.88A23.058 23.058 0 0 0 7410.1 957a22.5 22.5 0 1 0 0-45z"
        transform="translate(-7387.982 -912)"
        style={{ fill: '#d80027' }}
      />
    </g>
  </svg>
);
