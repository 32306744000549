import React from 'react';

export const ZAR = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48.001" viewBox="0 0 48 48.001">
    <g data-name="Group 8521">
      <path
        data-name="Path 10437"
        d="M14616 7344a24 24 0 1 0-24-24 24 24 0 0 0 24 24z"
        transform="translate(-14592.003 -7296.001)"
        style={{ fill: '#f0f0f0' }}
      />
      <path data-name="Path 10438" d="M7.175 40.997a23.715 23.715 0 0 1 0-33.992l14.125 17z" />
      <path
        data-name="Path 10439"
        d="m14630.032 7436.422-18.05-11.911a22.881 22.881 0 0 0-1.481 2.968l9.138 8.943-9.138 8.942a23.9 23.9 0 0 0 1.473 2.965z"
        transform="translate(-14608.73 -7412.421)"
        style={{ fill: '#ffda44' }}
      />
      <path
        data-name="Path 10440"
        d="M14670.45 7384.842h-26.885l-13.816-13.862a23.845 23.845 0 0 0-3.838 5.027l11.907 11.97-11.917 11.968a24.224 24.224 0 0 0 3.848 5.027l13.816-13.861h26.885a23.944 23.944 0 0 0 0-6.269z"
        transform="translate(-14622.657 -7363.975)"
        style={{ fill: '#6da544' }}
      />
      <path
        data-name="Path 10441"
        d="M14692.1 7631.514a24.794 24.794 0 0 0 14.919 4.943 24.422 24.422 0 0 0 23.659-17.677h-25.536z"
        transform="translate(-14683.52 -7588.457)"
        style={{ fill: '#0052b4' }}
      />
      <path
        data-name="Path 10442"
        d="M14730.679 7313.678a24.424 24.424 0 0 0-23.659-17.678 24.788 24.788 0 0 0-14.919 4.944l13.042 12.733z"
        transform="translate(-14683.52 -7296.001)"
        style={{ fill: '#d80027' }}
      />
    </g>
  </svg>
);
