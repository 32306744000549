import API, {graphqlOperation} from '@aws-amplify/api';
import {queries} from "../graphql";

const graphqlBatchApiDecorator = async (listQuery) => {
  try {
    const data = [];
    const titles = listQuery.map(({title}) => title)
    const requests = listQuery.map(({title, options}) => API.graphql(graphqlOperation(queries[title], options)));
    await Promise.all(requests)
      .then(responses => {
        // Process the responses here
        responses.forEach(response => {
          titles.map((title) => {
            if (response?.data?.hasOwnProperty(title)) {
                data.push(response.data);
            }
          });
        })

      })
    return data
  } catch (e) {
    console.log(e)
  }
};

export {graphqlBatchApiDecorator};
