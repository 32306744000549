import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { Button, TextField, Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import { setNewPassword } from '../../../services';
import { passwordValidator } from '../../../utils';
import { PasswordValidation } from './forgotPassword/password-validation';
import { useStyles } from './set-initial-password.hooks';
import { messages } from './sign-in.constants';
import { LoginTextField } from './textField/loginTextField';

export const SetInitialPassword = ({ user, onChangePasswordSuccess }) => {
  const classes = useStyles();
  const [error, setError] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPw, setShowConfirmPw] = useState(false);
  const [enteredPw, setEnteredPw] = useState('');
  const [confirmPw, setConfirmPw] = useState('');
  const [disableBtn, setDisableBtn] = useState(true);

  const visibilityIconStyle = { fontSize: 24, color: '#e6e6e6' };
  const textFieldStyle = {
    fontSize: '14px',
    color: '#19365e',
    borderRadius: '8px',
  };
  const { handleSubmit, register, errors } = useForm();

  const handleClickShowPassword = () => setShowPassword(!showPassword);

  const handleSignIn = async values => {
    if (enteredPw !== confirmPw || !passwordValidator(enteredPw)) return;
    const { password } = values;
    setError('');

    if (password) {
      const { success, message } = await setNewPassword(user, password);
      if (success) {
        onChangePasswordSuccess(user);
      } else {
        setError(messages.errors[message]);
      }
    }
  };

  useEffect(() => {
    if (passwordValidator(enteredPw) && passwordValidator(confirmPw) && enteredPw === confirmPw) {
      setDisableBtn(false);
    } else {
      setDisableBtn(true);
    }
  }, [enteredPw, confirmPw]);

  return (
    <form className={classes.form} onSubmit={handleSubmit(handleSignIn)}>
      <Typography className={classes.title} variant="h2">
        You should setup your password
      </Typography>
      <TextField
        {...register('password', { required: 'Required' })}
        className={classes.textField}
        onChange={event => {
          if (event.key !== 'Enter') setEnteredPw(event.target.value);
        }}
        value={enteredPw}
        error={!!errors?.password}
        fullWidth
        type={showPassword ? 'text' : 'password'}
        onKeyDown={event => {
          if (event.key === 'Enter') {
            event.preventDefault();
            handleSubmit(handleSignIn)();
          }
        }}
        InputProps={{
          style: textFieldStyle,
          endAdornment: (
            <InputAdornment position="end">
              <IconButton aria-label="toggle password visibility" onClick={handleClickShowPassword}>
                {showPassword ? (
                  <VisibilityOffIcon sx={visibilityIconStyle} />
                ) : (
                  <VisibilityIcon sx={visibilityIconStyle} />
                )}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <p className={classes.confirmPwText}>Confirm Password</p>
      <LoginTextField
        showPw={showConfirmPw}
        value={confirmPw}
        onChange={e => setConfirmPw(e.target.value)}
        handleShowPw={() => setShowConfirmPw(!showConfirmPw)}
      />
      {error && <Typography className={classes.error}>{error}</Typography>}
      <PasswordValidation password={enteredPw} />
      <Button
        className={classes.signInButton}
        color="primary"
        fullWidth
        size="large"
        type="submit"
        variant="contained"
        disabled={disableBtn}
        sx={{
          '&.Mui-disabled': {
            backgroundColor: '#d9e1e7',
            color: 'white',
          },
        }}
      >
        Save
      </Button>
    </form>
  );
};
