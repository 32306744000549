import React from 'react';

export const InactiveStatus = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40">
    <g data-name="Group 8227">
      <g data-name="Ellipse 1455" style={{ stroke: '#a5a6ae', fill: '#fff' }}>
        <circle cx="20" cy="20" r="20" style={{ stroke: 'none' }} />
        <circle cx="20" cy="20" r="19.5" style={{ fill: 'none' }} />
      </g>
      <g
        data-name="Ellipse 1456"
        transform="translate(10 10)"
        style={{ fill: '#f1f1f3', stroke: '#a5a6ae' }}
      >
        <circle cx="10" cy="10" r="10" style={{ stroke: 'none' }} />
        <circle cx="10" cy="10" r="9.5" style={{ fill: 'none' }} />
      </g>
    </g>
  </svg>
);
