import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(theme => ({
  formControl: {
    backgroundColor: theme.palette.white,
    width: '100%',
    '& .MuiSelect-select.MuiSelect-select': {
      backgroundColor: theme.palette.white,
    },
    '& .MuiSelect-select': {
      fontSize: '1.8rem',
      paddingTop: '1.2rem',
      paddingBottom: '1.2rem',
      height: '4.5rem',
      boxSizing: 'border-box',
      border: `0.1rem ${theme.palette.newDesign.border} solid !important`,
    },
    '& .MuiFilledInput-root.MuiFilledInput-underline.MuiInputBase-root': {
      backgroundColor: theme.palette.white,
    },
    '& .MuiFormLabel-root.Mui-focused': {
      color: theme.palette.text.secondary,
    },
    '& .MuiFilledInput-root.MuiFilledInput-underline:before': {
      display: 'none',
    },
    '& .MuiFilledInput-root.MuiFilledInput-underline:after': {
      display: 'none',
    },
  },
  icon: {
    cursor: 'pointer',
    top: 'unset',
    position: 'absolute',
    right: '8%',
    width: '2.7rem',
    height: '3rem',
    pointerEvents: 'none',
    backgroundColor: theme.palette.white,
    color: theme.palette.primary.nav,
  },
}));
