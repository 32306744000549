/* eslint-disable no-nested-ternary */
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Card, CardContent, Table, TableBody, TableHead } from '@mui/material';
import Button from '@mui/material/Button';
import clsx from 'clsx';
import React, { useEffect, useRef, useState } from 'react';
import ReactPaginate from 'react-paginate';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { entitiesEnum } from '../../constants';
import { removeEntity, saveEntity } from '../../services';
import {
  getElementTop,
  getFilteredTableFields,
  isEntityCanBeEdited,
  isEntityCanBeRemoved,
  setElementHeight,
} from '../../utils';
import { Loader } from '../loader';
import { CreateAdminModal, EditAdminModal } from '../modal';
import { ActionModal, CustomersTableHead, GenericTableBody, GenericTableHead } from './components';
import { CurrenciesTable } from './components/currencies-table/currencies-table';
import {
  CustomerFilterSummary,
  TransactionFilterSummary,
} from './components/generic-table-body/components';
import { CountriesTableBody } from './components/generic-table-body/countries-table-body';
import { CustomersTable } from './customers-table/customers-table';
import { useStyles } from './generic-table.hook';

/**
 * GenericTable component that renders the entity data into a table from the EntityTable parent component.
 * Some props are not used but are kept for potential future use.
 * @param {Object[]} data - the array of entity data of the entity type
 * @param {string} entity - name of the entity. A full list is in the
 * entitiesEnum Object in src/constants/entities.js
 * @param {boolean} error - flag that indicates an error occurred.
 * @param {boolean} loading - flag that indicates data is loading.
 * @param {function} onRefreshData - function that will fetch the entity data again
 * @param {boolean} canOrder - flag that indicates if data can be ordered.
 * @param {number} page - page number of the paginated entity data
 * @param {boolean} hasNextPage - a flag indicating if there is another pagination token for the next page
 * @param {function} setFilters - not used but calls the setFilters() useState hook on EntityTable component
 * @param {Object} filters - not used but this prop is for the GenericTableHead component to filter out prop
 * @param {number} limit - the entity request limit
 * @param {function} searchTransactions - this calls the searchTransactions() in useGraphList hook
 * @param {boolean} isSearchMode - flag indicating whether user is in search mode for transactions
 * @param {boolean} hasNextPageTransactionSearchByName - flag indicating if there is another page in pagination
 * for transaction search results
 * @param {function} setTransactionSearchResultsPage - a useState hook to set the page of paginated results for
 * transactions
 */

const handleIsPaginationEnabled = entity => {
  const isTransactionEntity = entity === entitiesEnum.TRANSACTION;
  const isCustomersEntity = entity === entitiesEnum.USERPROFILE;

  return isTransactionEntity || isCustomersEntity;
};

export const GenericTable = ({
  showCreateAdminModal,
  setShowCreateAdminModal,
  data,
  entity,
  error,
  loading,
  onRefreshData,
  tableFields,
  onRemoveItem,
  canOrder,
  page,
  setFilters,
  filters,
  limit,
  searchTransactions,
  isSearchMode,
  hasNextPageTransactionSearchByName,
  noOfPagesTransactionSearchResults,
  transactionSearchResultsPage,
  setTransactionSearchResultsPage,
  customerSearchResultsPage,
  hasNextPageCustomerSearch,
  noOfPagesCustomerSearchResults,
  setCustomerSearchResultsPage,
}) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const tableRef = useRef();
  const [removingItem, setRemovingItem] = useState({});
  const [showActionModal, setShowActionModal] = useState(false);
  const [showEditAdminModal, setShowEditAdminModal] = useState(false);
  const [adminToEditData, setAdminToEditData] = useState({});
  const [editingIndex, setEditingIndex] = useState('');
  const [actionKind, setActionKind] = useState('');
  const [tableTop, setTableTop] = useState(0);
  const [disablingAccount, setDisablingAccount] = useState({});
  const filteredTableFields = getFilteredTableFields(tableFields, entity);
  const isEdited = isEntityCanBeEdited(entity);
  const isRemoved = isEntityCanBeRemoved(entity);
  const isPaginationEnabled = handleIsPaginationEnabled(entity, page);
  const isTransaction = entity === entitiesEnum.TRANSACTION;
  const isCustomers = entity === entitiesEnum.USERPROFILE;
  const isCurrencies = entity === entitiesEnum.CURRENCY;
  const isCountries = entity === entitiesEnum.COUNTRY;
  const transactionFilterObj = useSelector(state => state.transactionFilterObj);
  const dispatch = useDispatch();
  // Customer Table states
  const [isAllCustomerRecordsChecked, setIsAllCustomerRecordsChecked] = useState(false);
  const [customerCheckedRecords, setCustomerCheckedRecords] = useState([]);

  let wrapperPadding = 16;

  if (isCustomers) wrapperPadding = 40;
  const paginationHeight = 67;

  if (entity === entitiesEnum.TRANSACTION) wrapperPadding = 24;

  const paginationArrowStyles = { height: '24px', width: '24px' };

  useEffect(() => {
    // Remove previous search queries
    if (!isSearchMode) dispatch({ type: 'SET_SEARCH_TRANSACTION_SEARCH_QUERY', payload: '' });
    dispatch({ type: 'SET_USER_TRANSACTION_SEARCH_QUERY', payload: '' });
    dispatch({ type: 'SET_SEARCH_CURRENCY_QUERY', payload: '' });
    dispatch({ type: 'SET_TRANSACTION_SORT', payload: '' });
    dispatch({ type: 'SET_USER_TRANSACTION_SORT', payload: '' });
    dispatch({ type: 'SET_CUSTOMER_SORT', payload: '' });
  }, [entity]);

  const handleRemoveEntity = (id, index) => {
    setActionKind('remove');
    setRemovingItem({ id, index });
    setShowActionModal(true);
  };

  const handleDisableAccount = (active, id) => {
    setActionKind('disable');
    setDisablingAccount({ active, id });
    setShowActionModal(true);
  };

  const handleConfirmDisable = async () => {
    const { active, id } = disablingAccount;
    await saveEntity({ entity, values: { active: !active, id }, id });
    setShowActionModal(false);
    onRefreshData();
  };

  const handleCloseModal = () => {
    setShowActionModal(false);
  };

  const handleConfirmRemove = async () => {
    const { id, index } = removingItem;
    if (onRemoveItem) {
      onRemoveItem(index);
      handleCloseModal();

      return;
    }
    await removeEntity(entity, id);
    setShowActionModal(false);
    onRefreshData();
  };

  const handleCloseCreateAdminModal = () => {
    setShowCreateAdminModal(false);
  };

  const handleEditAdmin = (adminData, index) => {
    setShowEditAdminModal(true);
    setAdminToEditData(adminData);
    setEditingIndex(index);
  };

  const handleCloseEditAdminModal = () => {
    setShowEditAdminModal(false);
  };

  const renderGenericTableHead = () => {
    const genericTableHeadProps = {
      entity,
      filters,
      setFilters,
      isEntityCanBeEdited: isEdited,
      isEntityCanBeRemoved: isRemoved,
      tableFields: filteredTableFields,
    };

    if (isTransaction) return null;
    if (isCustomers)
      return (
        <CustomersTableHead
          isAllRecordsChecked={isAllCustomerRecordsChecked}
          setIsAllRecordsChecked={setIsAllCustomerRecordsChecked}
        />
      );
    if (isCurrencies) return null;

    return <GenericTableHead {...genericTableHeadProps} />;
  };

  const onClickedTransactionRow = rowData => {
    navigate(`/${entity.toLowerCase()}/${rowData.id}`);
  };

  const previousBtnClassName = transactionSearchResultsPage
    ? clsx(classes.activeLink, classes.btnLink)
    : clsx(classes.inactiveLink, classes.btnLink);

  const prevBtnClassNameCustomer = customerSearchResultsPage
    ? clsx(classes.activeLink, classes.btnLink)
    : clsx(classes.inactiveLink, classes.btnLink);

  const nextBtnClassName = hasNextPageTransactionSearchByName
    ? clsx(classes.activeLink, classes.btnLink)
    : clsx(classes.inactiveLink, classes.btnLink);

  const nextBtnClassNameCustomer = hasNextPageCustomerSearch
    ? clsx(classes.activeLink, classes.btnLink)
    : clsx(classes.inactiveLink, classes.btnLink);

  const renderGenericTableBody = () => {
    const genericTableBodyProps = {
      loading,
      error,
      data,
      canOrder,
      entity,
      tableFields: filteredTableFields,
      onClickedTransactionRow,
      onDisableAccount: handleDisableAccount,
      onEditAdmin: handleEditAdmin,
      onRemoveEntity: handleRemoveEntity,
      page,
      limit,
      searchTransactions,
      isSearchMode,
    };

    if (isCustomers)
      return (
        <CustomersTable
          data={data}
          entity={entity}
          loading={loading}
          isAllRecordsChecked={isAllCustomerRecordsChecked}
          setIsAllRecordsChecked={setIsAllCustomerRecordsChecked}
          checkedRecords={customerCheckedRecords}
          setCheckedRecords={setCustomerCheckedRecords}
        />
      );
    if (isCurrencies) return <CurrenciesTable {...genericTableBodyProps} />;
    if (isCountries) return <CountriesTableBody data={data} tableColumns={filteredTableFields} />;

    return <GenericTableBody {...genericTableBodyProps} />;
  };

  useEffect(() => {
    const genericTableTop = getElementTop(tableRef);
    setTableTop(genericTableTop);
  }, [entity]);

  let indent = tableTop + wrapperPadding;

  if (isPaginationEnabled) {
    indent += paginationHeight;
  }

  const handlePaginationPageClick = event => {
    if (isTransaction) setTransactionSearchResultsPage(event.selected);
    if (isCustomers) setCustomerSearchResultsPage(event.selected);
  };

  const previousPage = () => {
    let disabled = false;
    if (isCustomers) disabled = customerSearchResultsPage === 0;
    if (isTransaction) disabled = transactionSearchResultsPage === 0;

    return (
      <Button
        type="submit"
        disabled={disabled}
        onClick={() => {
          if (isTransaction) setTransactionSearchResultsPage(prev => prev - 1);
          if (isCustomers) setCustomerSearchResultsPage(prev => prev - 1);
        }}
        className={isTransaction ? previousBtnClassName : prevBtnClassNameCustomer}
      >
        <ArrowBackIcon sx={paginationArrowStyles} color={previousBtnClassName} />
      </Button>
    );
  };

  const nextPage = () => {
    let disabled = false;
    if (isCustomers) disabled = !hasNextPageCustomerSearch;
    if (isTransaction) disabled = !hasNextPageTransactionSearchByName;

    return (
      <Button
        type="submit"
        disabled={disabled}
        className={isTransaction ? nextBtnClassName : nextBtnClassNameCustomer}
        onClick={() => {
          if (isTransaction) setTransactionSearchResultsPage(prev => prev + 1);
          if (isCustomers) setCustomerSearchResultsPage(prev => prev + 1);
        }}
      >
        <ArrowForwardIcon
          sx={paginationArrowStyles}
          color={isTransaction ? nextBtnClassName : nextBtnClassNameCustomer}
        />
      </Button>
    );
  };

  return (
    <>
      {isTransaction && <TransactionFilterSummary isSearchMode={isSearchMode} />}
      {isCustomers && <CustomerFilterSummary />}
      <Card
        className={clsx(
          classes.root,
          isTransaction && classes.backgroundTransaction,
          isCustomers && classes.backgroundCustomers,
          isCurrencies && classes.backgroundCurrencies
        )}
        id="teamlist"
        ref={tableRef}
        style={{
          height: setElementHeight(indent),
        }}
      >
        {loading ? (
          <Loader />
        ) : (
          <CardContent
            className={
              isTransaction
                ? `${classes.content} ${classes.backgroundTransaction}`
                : classes.content
            }
          >
            <div className={classes.inner}>
              <Table
                className={
                  isCountries || isCustomers ? classes.tableRootCountryEntity : classes.tableRoot
                }
              >
                <TableHead>{renderGenericTableHead()}</TableHead>
                <TableBody>{renderGenericTableBody()}</TableBody>
              </Table>
            </div>
          </CardContent>
        )}
        <ActionModal
          onClose={handleCloseModal}
          onShow={showActionModal}
          actionKind={actionKind}
          confirmRemove={handleConfirmRemove}
          confirmDisable={handleConfirmDisable}
          classes={classes}
          disablingAccount={disablingAccount}
        />
        <CreateAdminModal
          onClose={handleCloseCreateAdminModal}
          onShow={showCreateAdminModal}
          onCreate={saveEntity}
          onRefreshData={onRefreshData}
          entity={entity}
          styles={classes}
        />
        <EditAdminModal
          onClose={handleCloseEditAdminModal}
          onShow={showEditAdminModal}
          onSave={saveEntity}
          onDelete={handleRemoveEntity}
          entity={entity}
          styles={classes}
          data={adminToEditData}
          index={editingIndex}
          onRefreshData={onRefreshData}
        />
      </Card>
      {isCustomers && noOfPagesCustomerSearchResults && (
        <div className={classes.paginationContainer}>
          <ReactPaginate
            className={classes.pageNumbers}
            activeClassName={classes.activePage}
            breakLabel="..."
            nextLabel={nextPage()}
            onPageChange={handlePaginationPageClick}
            pageRangeDisplayed={10}
            pageCount={noOfPagesCustomerSearchResults}
            previousLabel={previousPage()}
            renderOnZeroPageCount={null}
          />
        </div>
      )}
      {isTransaction &&
        noOfPagesTransactionSearchResults &&
        Object.keys(transactionFilterObj).length === 0 && (
          <div className={classes.paginationContainer}>
            <ReactPaginate
              className={classes.pageNumbers}
              activeClassName={classes.activePage}
              breakLabel="..."
              nextLabel={nextPage()}
              onPageChange={handlePaginationPageClick}
              pageRangeDisplayed={10}
              pageCount={noOfPagesTransactionSearchResults}
              previousLabel={previousPage()}
              renderOnZeroPageCount={null}
              forcePage={transactionSearchResultsPage}
            />
          </div>
        )}
    </>
  );
};
