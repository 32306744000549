import { IconButton, Tooltip } from '@mui/material';
import React from 'react';

import { palette } from '../../theme/palette';
import { IntercomIcon } from './components';

export const IntercomButton = ({ profileUrl, title = 'View on Intercom', accountStatus }) => {
  const { intercomIcon } = palette.icons;
  const isButtonDisabled = accountStatus === 'suspended' || !profileUrl;

  const handleOnClick = event => {
    event.stopPropagation();
    if (isButtonDisabled) return;
    window.open(profileUrl, '_blank');
  };
  const iconColor = isButtonDisabled ? intercomIcon.disabled : intercomIcon.default;

  return (
    <Tooltip title={title} placement="top">
      <span>
        <IconButton onClick={handleOnClick} disabled={isButtonDisabled} size="large">
          <IntercomIcon iconColor={iconColor} />
        </IconButton>
      </span>
    </Tooltip>
  );
};
