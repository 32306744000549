import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(theme => ({
  rowContainer: {
    background: theme.palette.white,
    display: 'flex',
    flexDirection: 'row',
    height: '104px',
    borderRadius: '0px 15px 15px 15px',
    boxShadow: '0 2px 12px 0 rgba(0, 0, 0, 0.08)',
    margin: '16px 24px',
    fontSize: '28px',
    fontFamily: theme.typography.default,
    color: theme.palette.icons.nav,
    fontWeight: 'bold',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingRight: '46px',
    position: 'relative',
    zIndex: '1',
  },
  leftContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: '16px',
  },
  textContainer: {
    margin: 'auto',
  },
  dropDownContainer: {
    background: theme.palette.white,
    margin: '-32px 24px 0 24px',
    fontFamily: theme.typography.default,
    color: theme.palette.icons.nav,
  },
  selectedColumns: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    paddingTop: '56px',
    fontSize: '20px',
    fontWeight: 'bold',
    '& p': {
      flex: '1',
      paddingLeft: '40px',
    },
  },
  flatRates: {
    marginLeft: '24px',
  },
  profitMarginSell: {
    marginLeft: '-65px',
  },
  rightContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: '42px',
    alignItems: 'center',
  },
  smallSaveBtn: {
    cursor: 'pointer',
  },
  arrowContainer: {
    cursor: 'pointer',
  },
  arrowUp: {
    marginBottom: '10px',
  },
  horizRule: {
    borderTop: '1px solid #aeabab',
    width: '98%',
    margin: '16px auto 0 auto',
    paddingBottom: '16px',
  },
  endRowContainer: {
    background: theme.palette.white,
    borderRadius: '0px 0px 15px 15px',
    height: '128px',
    margin: '0px 24px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  bigSaveBtn: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    gap: '16px',
    alignItems: 'center',
    background: '#c6ffef',
    borderRadius: '8px',
    fontSize: '24px',
    color: theme.palette.icons.nav,
    fontFamily: theme.typography.default,
    textAlign: 'center',
    width: '168px',
    height: '56px',
    marginTop: '24px',
    marginRight: '40px',
    cursor: 'pointer',
  },
  searchBarContainer: {
    position: 'relative',
  },
  searchIconContainer: {
    position: 'absolute',
    top: '8px',
    left: '16px',
  },
  searchBar: {
    width: '672px',
    height: '40px',
    fontSize: '16px',
    borderRadius: '20px',
    border: '1px solid #e6e6e6',
    paddingLeft: '48px',
  },
}));
