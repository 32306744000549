import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(theme => ({
  root: {
    borderRadius: '0.4rem',
    alignItems: 'center',
    padding: '0.8rem',
    display: 'flex',
  },
  icon: {
    marginLeft: '0.8rem',
    color: theme.palette.text.secondary,
  },
}));
