import React, { Fragment } from 'react';
import { uid } from 'react-uid';

import { BackButton, MainButton } from '../../../components';
import { useStyles } from './toolbar-buttons.hooks';

export const ToolbarButtons = ({ entity, buttons, onCreate, isSubPage }) => {
  const classes = useStyles();

  const onClick = (_button, _entity) => {
    onCreate(_entity);
  };

  return (
    <div className={classes.buttonsWrapper}>
      {isSubPage && <BackButton />}

      {buttons.map(button => (
        <Fragment key={uid(button)}>
          <div className={classes.buttonDiv}>
            <MainButton
              onClick={() => onClick(button, entity)}
              icon={button?.icon ?? ''}
              text={button?.text ?? ''}
              design="primary"
            />
          </div>
        </Fragment>
      ))}
    </div>
  );
};
