import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(theme => ({
  defaultBtnStyle: {
    borderRadius: '8px',
    border: 'none',
    height: '48px',
    width: 'max(146px,136px)',
    textAlign: 'center',
    fontSize: '18px',
    color: theme.palette.icons.nav,
    fontFamily: theme.typography.default,
    fontWeight: '500',
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flext-start',
    gap: '12px',
    alignItems: 'center',
    paddingLeft: '12px',
  },
  btnCancel: {
    backgroundColor: '#e2ecff',
  },
  btnSave: {
    backgroundColor: '#c6ffef',
  },
  btnEdit: {
    backgroundColor: '#9fe3ff',
  },
  btnDelete: {
    backgroundColor: '#ffccd4',
  },
}));
