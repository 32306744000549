import { Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';

import { ClosingCard } from '../closing-card';
import { allFields } from './beneficiary-requirements.constants';
import { useStyles } from './beneficiary-requirements.hooks';
import { RequirementField } from './components/requirement-field';

export const BeneficiaryRequirements = ({ watch, field, register, setValue }) => {
  const classes = useStyles();
  const requirementsValue = watch(field.name);
  const country = watch('id');

  const [requirements, setRequirements] = useState({});
  const [activeCard, setActiveCard] = useState('');

  useEffect(() => {
    register(field.name);
  }, []);

  useEffect(() => {
    if (requirementsValue) {
      setRequirements(JSON.parse(requirementsValue));
    }
  }, [requirementsValue]);

  const currencies = Object.keys(requirements);

  return (
    <section>
      <Typography variant="h2">
        {field.name === 'personalRequirements' ? 'Personal' : 'Company'} Requirements
      </Typography>
      {currencies.map(currency => {
        const cardTitle = `${field.name}-${currency}`;

        return (
          <ClosingCard
            key={cardTitle}
            active={activeCard === cardTitle}
            setActiveCard={setActiveCard}
            card={cardTitle}
            title={currency}
            className={classes.accordion}
          >
            {allFields.map(requirementFieldName => (
              <RequirementField
                key={`${cardTitle}-${requirementFieldName}`}
                {...{
                  requirements,
                  currency,
                  country,
                  requirementFieldName,
                  setRequirements,
                  setValue,
                  fieldName: field.name,
                }}
              />
            ))}
          </ClosingCard>
        );
      })}
    </section>
  );
};
