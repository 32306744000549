import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { TextField } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import React from 'react';

import { useStyles } from './loginTextField.hooks';

export const LoginTextField = ({ showPw = false, value, onChange, handleShowPw }) => {
  const classes = useStyles();
  const visibilityIconStyle = { fontSize: 24, color: '#e6e6e6' };
  const textFieldStyle = { fontSize: '14px', color: '#19365e' };

  return (
    <TextField
      fullWidth
      value={value}
      onChange={onChange}
      className={classes.textField}
      type={showPw ? 'text' : 'password'}
      InputProps={{
        style: textFieldStyle,
        endAdornment: (
          <InputAdornment position="end">
            <IconButton aria-label="toggle new password visibility" onClick={handleShowPw}>
              {showPw ? (
                <VisibilityOffIcon sx={visibilityIconStyle} />
              ) : (
                <VisibilityIcon sx={visibilityIconStyle} />
              )}
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
};
