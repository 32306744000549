import React, { useEffect, useState } from 'react';
import Highcharts from 'highcharts';
import HighchartsMore from 'highcharts/highcharts-more';
import HighchartsExportData from 'highcharts/modules/export-data';
import HighchartsAccessibility from 'highcharts/modules/accessibility';
import { graphqlApiDecorator } from 'decorators';
import { queries } from '../../graphql';
import { Loader } from 'components/loader';

HighchartsMore(Highcharts);
HighchartsExportData(Highcharts);
HighchartsAccessibility(Highcharts);

const GaugeChart = ({ riskRate, type, loading }) => {

  const handleRiskRate = () => {
    if (riskRate) {
      switch (riskRate) {
        case 0:
          return 25;
        case 1:
          return 75;
        case 2:
          return 125;
        case 3:
          return 175;
        default:
          return 25;
      }
    }
    else {
      return 0;
    }
  };

  useEffect(() => {
    const chartOptions = {
      chart: {
        type: 'gauge',
        plotBackgroundColor: null,
        plotBackgroundImage: null,
        plotBorderWidth: 0,
        plotShadow: false,
        height: '70%',
      },
      title: {
        text: '',
      },
      pane: {
        startAngle: -90,
        endAngle: 90,
        background: null,
        center: ['50%', '75%'],
        size: '110%',
      },
      tooltip: {
        enabled: false,
      },
      exporting: {
        enabled: false,
      },
      yAxis: {
        min: 0,
        max: 200,
        tickPosition: 'inside',
        tickColor: Highcharts.defaultOptions.chart.backgroundColor || '#FFFFFF',
        tickLength: 20,
        tickWidth: 0,
        minorTickInterval: null,
        labels: {
          distance: 20,
          style: {
            display: 'none',
            fontSize: '14px',
          },
        },
        lineWidth: 0,
        plotBands: [
          {
            from: 0,
            to: 60,
            color: '#55BF3B',
            thickness: 25,
            borderRadius: '50%',
            label: {
              text: 'Low',
              align: 'center',
              verticalAlign: 'middle',
              rotation: -70,
              style: {
                color: '#000',
                fontSize: '14px',
                fontWeight: 'bold',
              },
              y: 20,
              x: 20,
            },
          },
          {
            from: 50,
            to: 110,
            color: '#DDDF0D',
            thickness: 25,
            label: {
              text: 'Medium',
              align: 'center',
              verticalAlign: 'middle',
              rotation: -28,
              style: {
                color: '#000',
                fontSize: '14px',
                fontWeight: 'bold',
              },
              y: 13,
              x: 60,
            },
          },
          {
            from: 100,
            to: 150,
            color: 'orange',
            thickness: 25,
            zIndex: 1,
            label: {
              text: 'High',
              align: 'center',
              verticalAlign: 'middle',
              rotation: 25,
              style: {
                color: '#000',
                fontSize: '14px',
                fontWeight: 'bold',
              },
              y: 3,
              x: -60,
            },
          },
          {
            from: 145,
            to: 199,
            color: 'red',
            thickness: 25,
            borderRadius: '50%',
            label: {
              text: 'Very High',
              align: 'center',
              verticalAlign: 'middle',
              rotation: 70,
              style: {
                color: '#000',
                fontSize: '14px',
                fontWeight: 'bold',
              },
              x: -17,
            },
          },
        ],
      },
      series: [
        {
          data: [handleRiskRate()],
          dataLabels: {
            format: '',
            borderWidth: 0,
            color:
              (Highcharts.defaultOptions.title &&
                Highcharts.defaultOptions.title.style &&
                Highcharts.defaultOptions.title.style.color) ||
              '#333333',
            style: {
              fontSize: '16px',
            },
          },
          dial: {
            radius: '80%',
            backgroundColor: 'gray',
            baseWidth: 12,
            baseLength: '0%',
            rearLength: '0%',
          },
          pivot: {
            backgroundColor: 'gray',
            radius: 6,
          },
        },
      ],
    };

    Highcharts.chart('container', chartOptions);
  }, [riskRate]);

  return (
    <>
      {type === 'account' ? (
        <figure className="highcharts-figure" style={{ width: '400px' }}>
          <div id="container" style={{ width: '100%', marginTop: '-20px' }}></div>
        </figure>
      ) : (
        <figure className="highcharts-figure" style={{ width: '500px' }}>
          {loading ? <Loader /> : <div id="container" style={{ width: '100%' }}></div>}
        </figure>
      )}
    </>
  );
};

export default GaugeChart;
