import React from 'react';

export const KixySmileLogo = ({ width = '16px', height = '21px' }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 16.096 21.311">
    <path
      data-name="Union 175"
      d="M7107.958-761.582a1.656 1.656 0 0 1 .7-2.216 7.845 7.845 0 0 0 4.2-6.982 7.846 7.846 0 0 0-4.2-6.983 1.655 1.655 0 0 1-.7-2.216 1.61 1.61 0 0 1 2.185-.707 11.129 11.129 0 0 1 5.953 9.906 11.129 11.129 0 0 1-5.953 9.905 1.592 1.592 0 0 1-.742.185 1.619 1.619 0 0 1-1.443-.892zm-6.958-.865v-5.753a1.663 1.663 0 0 1 1.622-1.7 1.663 1.663 0 0 1 1.622 1.7v5.755a1.664 1.664 0 0 1-1.622 1.7 1.664 1.664 0 0 1-1.622-1.702zm.035-16.776a1.765 1.765 0 0 1 1.751-1.778 1.765 1.765 0 0 1 1.753 1.778 1.765 1.765 0 0 1-1.753 1.778 1.765 1.765 0 0 1-1.751-1.778z"
      transform="translate(-7100.5 781.501)"
      style={{ fill: '#1fe3ac', stroke: 'transparent', strokeMiterlimit: '10' }}
    />
  </svg>
);
