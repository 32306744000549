import Avatar from '@mui/material/Avatar';
import React, { useEffect, useState } from 'react';

import { getProfilePictureURL } from '../../../../services';
import { BeneficiaryModal } from '../../../modal';
import { useStyles } from './beneficiaries.hooks';
import { BeneficiaryBlank } from './blank-templates';
import { GenericProfilePic, KixyLogoCircle, SideRectangle } from './icons';

/**
 * Sub-component of the CustomerDetailPage parent component that renders the customer's beneficiaries.
 * If the beneficiary is also a Kixy customer, then you can click on beneficiary to navigate to a customer
 * detail page of that customer.
 * @param {object} data the user profile data of type userProfile
 * @param {function} navigateUserProfile - this will navigate to the customer detail page of the selected Kixy beneficiary
 */
export const Beneficiaries = ({ data, navigateUserProfile }) => {
  const classes = useStyles();
  const [beneficiaryData, setBeneficiaryData] = useState(null);
  const [openBeneficiaryModal, setOpenBeneficiaryModal] = useState(false);
  const [listData, setListData] = useState([]);
  const getProfilePic = async id => {
    const profilePictureURL = await getProfilePictureURL(id);

    return profilePictureURL;
  };
  const [dataObj, setDataObj] = useState('Beneficiaries');

  const shareholders = JSON.parse(data.shareholders);

  const setProfilePic = async data => {
    if (data) {
      const beneficiaryDataWithProfilePic = await Promise.all(
        data.map(async item => ({
          ...item,
          profilePictureURL: item?.userProfileId ? await getProfilePic(item?.userProfileId) : '',
        }))
      );

      setListData(beneficiaryDataWithProfilePic);
    }
  };

  useEffect(() => {
    const { beneficiaries } = data;
    setProfilePic(beneficiaries);
  }, [data]);

  const navigateToUserProfile = id => {
    if (id) {
      navigateUserProfile(id);
    }
  };

  return (
    <div>
      <main>
        <section className={classes.label}>
          <SideRectangle />
          <header>Beneficiaries</header>
          {data.accountType === 'company' && (
            <div className={classes.customerFilterModalContainer}>
              <div className={classes.statusRow}>
                <div
                  className={
                    dataObj === 'Beneficiaries'
                      ? `${classes.createdActive} ${classes.defaultStatus}`
                      : classes.defaultStatus
                  }
                  onClick={() => handleToggle('Beneficiaries')}
                >
                  Beneficiaries
                </div>
                <div
                  className={
                    dataObj === 'Shareholders'
                      ? `${classes.createdActive} ${classes.defaultStatus}`
                      : classes.defaultStatus
                  }
                  onClick={() => handleToggle('Shareholders')}
                >
                  Shareholders
                </div>
              </div>
            </div>
          )}
        </section>
        {dataObj === 'Beneficiaries' ? (
          <section className={classes.beneficiariesContainer}>
            {listData?.length ? (
              listData.map(item => {
                let beneficiaryName = '';
                if (item.name) beneficiaryName = `${item.name} ${item.lastName || ''}`;
                if (!beneficiaryName && item.bankDetails) {
                  beneficiaryName = item.bankDetails.find(
                    el => el.name === 'beneficiary_company_name'
                  )?.value;
                }

                return (
                  <div
                    key={item.id}
                    className={classes.beneficiaryRow}
                    onClick={() => {
                      if (item.userProfileId) {
                        // Kixy User
                        navigateToUserProfile(item.userProfileId);
                      } else {
                        // Non Kixy User
                        setBeneficiaryData(item);
                        setOpenBeneficiaryModal(true);
                      }
                    }}
                  >
                    {item.profilePictureURL ? (
                      <div className={classes.profilePicContainer}>
                        <Avatar src={item.profilePictureURL} alt="profile pic" />
                        <div className={classes.smallIcon}>
                          <KixyLogoCircle />
                        </div>
                      </div>
                    ) : (
                      <GenericProfilePic />
                    )}
                    <div className={classes.beneficiaryText}>
                      <h3>{beneficiaryName}</h3>
                      {item.currency &&
                        item.bankDetails &&
                        item.bankDetails.find(el => el.name === 'acct_number')?.value && (
                          <div className={classes.accountDetails}>
                            <strong>{item.currency.iso}</strong>
                            <p>{`\u00A0account ending in\u00A0`}</p>
                            <strong>
                              {item.bankDetails
                                .find(el => el.name === 'acct_number')
                                ?.value.slice(-4)}
                            </strong>
                          </div>
                        )}
                      {item.currency && item.bankDetails.find(el => el.name === 'iban')?.value && (
                        <div className={classes.accountDetails}>
                          <strong>IBAN</strong>
                          <p>{`\u00A0account ending in\u00A0`}</p>
                          <strong>
                            {item.bankDetails.find(el => el.name === 'iban')?.value.slice(-4)}
                          </strong>
                        </div>
                      )}
                    </div>
                  </div>
                );
              })
            ) : (
              <div className={classes.noBeneficiaries}>
                {[...Array(9).keys()].map(e => (
                  <BeneficiaryBlank key={e} />
                ))}
              </div>
            )}
          </section>
        ) : (
          <section className={classes.beneficiariesContainer}>
            {shareholders?.length ? (
              shareholders.map(item => {
                let shareholderName = '';
                if (item.firstName) shareholderName = `${item.firstName} ${item.lastName || ''}`;

                return (
                  <div key={item.id} className={classes.beneficiaryRow}>
                    {item?.profilePictureURL ? (
                      <div className={classes.profilePicContainer}>
                        <Avatar src={item.profilePictureURL} alt="profile pic" />
                        <div className={classes.smallIcon}>
                          <KixyLogoCircle />
                        </div>
                      </div>
                    ) : (
                      <GenericProfilePic />
                    )}
                    <div className={classes.beneficiaryText}>
                      <h3>{shareholderName}</h3>
                    </div>
                  </div>
                );
              })
            ) : (
              <div className={classes.noBeneficiaries}>
                {[...Array(9).keys()].map(e => (
                  <BeneficiaryBlank key={e} />
                ))}
              </div>
            )}
          </section>
        )}
      </main>
      <BeneficiaryModal
        open={openBeneficiaryModal}
        onClose={() => setOpenBeneficiaryModal(false)}
        data={beneficiaryData}
      />
    </div>
  );
};
