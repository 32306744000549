import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(theme => ({
  label: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    '& aside': {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      gap: '8px',
      marginRight: '16px',
    },
  },
  filterIcon: {
    padding: '6px',
    cursor: 'pointer',
  },
  filterSummary: {
    position: 'relative',
    top: '22px',
  },
  transactionsContainer: {
    overflowY: 'auto',
    height: '224px',
    backgroundColor: '#f2f2f4',
    borderRadius: '8px',
    padding: '8px',
    margin: '16px',
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
  },
  transactionsTableRow: {
    '& .MuiTableCell-root': {
      background: 'white',
      padding: '10px 15px',
      borderRadius: '0 8px 8px 8px',
      boxShadow: 'rgba(0, 0, 0, 0.08)',
      '&:hover': {
        background: theme.palette.primary.light,
      },
    },
  },
  transactionBlank: {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
  },
}));
