import React from 'react';

export const LogoutSidebarIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 31.8 32.013">
    <defs>
      <clipPath id="kvpjf5wz4a">
        <path data-name="Rectangle 18141" style={{ fill: 'none' }} d="M0 0h31.8v32.013H0z" />
      </clipPath>
    </defs>
    <g data-name="Group 8598" style={{ clipPath: 'url(#kvpjf5wz4a)' }}>
      <path
        data-name="Path 20071"
        d="M9.8 30.8a17.5 17.5 0 0 1-5.1-3.4 14.727 14.727 0 0 1-3.4-5.1A16.8 16.8 0 0 1 0 16.1a16.223 16.223 0 0 1 1.7-7.2 17.348 17.348 0 0 1 4.6-5.5.93.93 0 0 1 1.1 1.5A14.05 14.05 0 1 0 30 16.1a13.878 13.878 0 0 0-5.6-11.2.93.93 0 0 1 1.1-1.5 16.324 16.324 0 0 1 4.6 5.5 16.223 16.223 0 0 1 1.7 7.2 15.008 15.008 0 0 1-1.3 6.2 17.5 17.5 0 0 1-3.4 5.1 15.72 15.72 0 0 1-5.1 3.4 14.9 14.9 0 0 1-6.2 1.2 16.05 16.05 0 0 1-6-1.2m6.3-13.6a.9.9 0 0 1-.9-.9L15.1.9a.9.9 0 1 1 1.8 0l.1 15.3a.871.871 0 0 1-.9 1"
        style={{ fill: '#1fe3ac' }}
      />
    </g>
  </svg>
);
