import { Card, CardContent, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';

const useStyles = makeStyles(() => ({
  cardNoShadow: {
    boxShadow: 'none',
  },
}));

export const UserNoInfo = ({ text }) => {
  const classes = useStyles();

  return (
    <Card classes={{ root: classes.cardNoShadow }}>
      <CardContent>
        <Typography>{text}</Typography>
      </CardContent>
    </Card>
  );
};
