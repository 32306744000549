import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useStyles } from './input-modal.hooks';
// This is a popup modal for the currencies section in pairs. User can select multiple choices
// and these choices are updated in the redux reducer.
export const InputModal = ({ field, closeModal, currencies }) => {
  const [allItemsSelected, setAllItemsSelected] = useState(false);
  const [btnDisabled, setBtnDisabled] = useState(true);
  const dispatch = useDispatch();
  const selectedItemsFromRedux = useSelector(state => state.pairsCurrenciesObj)[field];
  const [selectedItems, setSelectedItems] = useState(selectedItemsFromRedux || []);
  const classes = useStyles();

  const isCurrency = field === 'Sending Currency';
  const isReceivingCurrency = field === 'Receiving Currency';

  const itemClicked = item => {
    setSelectedItems(prev => {
      let newArr = [...prev];
      if (newArr.includes(item)) {
        newArr = newArr.filter(el => el !== item);
      } else {
        newArr.push(item);
      }

      return newArr;
    });
  };

  const allItemsClicked = () => {
    if (!allItemsSelected) setSelectedItems([...Object.keys(currencies)]);
    if (allItemsSelected) setSelectedItems([]);
  };

  const cacheSelected = () => {
    dispatch({ type: 'SET_PAIRS_CURRENCIES_OBJ', payload: { [field]: selectedItems } });
    closeModal(false);
  };

  useEffect(() => {
    if (selectedItems.length === Object.keys(currencies).length && !allItemsSelected)
      setAllItemsSelected(true);
    if (selectedItems.length !== Object.keys(currencies).length && allItemsSelected)
      setAllItemsSelected(false);

    if (selectedItems.length === 0) setBtnDisabled(true);
    if (selectedItems.length > 0) setBtnDisabled(false);
  }, [selectedItems]);

  return (
    <div className={classes.modalContent}>
      <header>{field}</header>
      <label className={classes.container}>
        Select all {isCurrency || isReceivingCurrency ? 'Currencies' : 'Countries'}
        <input type="checkbox" onChange={allItemsClicked} checked={allItemsSelected} />
        <span className={classes.checkmark} />
      </label>

      <main>
        {Object.keys(currencies).length
          ? Object.keys(currencies).map(item => (
              <label key={item} className={classes.container}>
                {(isCurrency || isReceivingCurrency) && `${item} - ${currencies[item]}`}
                <input
                  type="checkbox"
                  value={item}
                  checked={
                    allItemsSelected && selectedItems.includes(item)
                      ? true
                      : selectedItems.includes(item)
                  }
                  onChange={() => itemClicked(item)}
                />
                <span className={classes.checkmark} />
              </label>
            ))
          : null}
      </main>
      <div className={classes.btnContainer}>
        <button type="button" disabled={btnDisabled} onClick={cacheSelected}>
          Done
        </button>
      </div>
    </div>
  );
};
