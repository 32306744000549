import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
  input: {
    display: 'none',
  },
  imgUploaded: {
    maxWidth: '98%',
  },
  imgListItemContainer: {
    position: 'relative',
    '&:hover > div': {
      display: 'block',
    },
  },
  removeButton: {
    position: 'absolute',
    display: 'none',
    top: '0.5rem',
    left: '0.5rem',
  },
}));
