const getFieldType = type => {
  switch (type) {
    case 'int':
    case 'float':
      return 'number';
    case 'date':
      return 'date';
    case 'timestamp':
      return 'datetime-local';
    default:
      return 'text';
  }
};

const getFieldWidth = type => {
  switch (type) {
    case 'int':
    case 'float':
    case 'date':
    case 'timestamp':
    case 'enumSelect':
      return 3;
    default:
      return 4;
  }
};

export { getFieldType, getFieldWidth };
