/* eslint-disable no-unused-vars */
import SortIcon from '@mui/icons-material/Sort';
import { Box, FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Modal from '@mui/material/Modal';
import Tooltip from '@mui/material/Tooltip';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { TableExport, TableImport } from '../../../components';
import { FilterIcon, RefreshIcon } from '../../../components/icons';
import { customerSearchOptions, entitiesEnum, transactionSearchOptions } from '../../../constants';
import { hasEntityExport, hasEntityImport } from '../../../utils';
import {
  CustomerFilterModal,
  CustomerSortModal,
  TransactionFilterModal,
  TransactionSortModal,
} from './modal/index';
// import { pagesProperties } from './pages-properties';
import { getPagesProperties } from './pages-properties';
import { SearchBar } from './search-bar';
import { SearchBarDropDown } from './search-bar-dropdown';
import { useStyles } from './toolbar.hooks';
import { ToolbarContent } from './toolbar-content';
import { CustomerFilterModalByType } from './modal/customer-filter-modal-by-type';

/**
 * A general toolbar that is displayed for entities of USERPROFILE, TRANSACTION, CURRENCIES, COUNTRIES, PAIRS
 * @param {string} entity
 * @param {Object} listData - contains the data for the entity selected
 * @param {function} onCreateAdmin - not used but kept for potential use
 * @param {function} setActiveCards - not used but kept for potential use
 * @param {array} activeCards - not used but kept for potential use
 */
export const GenericToolbar = ({
  entity,
  onCreateAdmin,
  setActiveCards,
  activeCards,
  listData,
}) => {
  const navigate = useNavigate();
  const classes = useStyles();
  const hasImport = hasEntityImport(entity);
  const hasExport = hasEntityExport(entity);
  const dispatch = useDispatch();
  const [transactionSearchQuery, setTransactionSearchQuery] = useState('');
  const [customerSearchQuery, setCustomerSearchQuery] = useState('');
  const searchCurrencyQuery = useSelector(state => state.searchCurrencyQuery);
  const pairsSearchQuery = useSelector(state => state.pairsSearchQuery);
  const filterObj = useSelector(state => state.transactionFilterObj);
  const transactionSort = useSelector(state => state.transactionSort);
  const [filterModal, setFilterModal] = useState(false);
  const [openAdvancedSearchModal, setOpenAdvancedSearchModal] = useState(false);
  const [itemSelected, setItemSelected] = useState(transactionSearchOptions.NAME);
  const [customerSearchField, setCustomerSearchField] = useState(customerSearchOptions.name);
  const [openSortModal, setOpenSortModal] = useState(false);
  const [openCustomerSortModal, setOpenCustomerSortModal] = useState(false);
  const [flterByAccountType, setFilterByAccountType] = useState(false);
  const isFiltered = obj => Object.keys(obj).length > 0;
  const {
    fetchCustomerDataFromQuery,
    fetchTransactionDataFromQuery,
    isSearchMode,
    setIsSearchMode,
    setNoOfResultsTransactions,
    setNoOfResultsCustomers,
    noOfResultsCustomers,
    noOfResultsTransactions,
  } = listData;

  const isTransaction = entity === entitiesEnum.TRANSACTION;
  const isCustomer = entity === entitiesEnum.USERPROFILE;
  const customerSort = useSelector(state => state.customerSort);
  const isPair = entity === entitiesEnum.PAIR;
  const isCurrency = entity === entitiesEnum.CURRENCY;

  const resultsPerPage = [10, 15, 20, 25, 30, 35, 40];
  const [pagesProperties, setPagesProperties] = useState([]);

  useEffect(() => {
    const fetchPages = async () => {
      const updatedPagesProperties = await getPagesProperties();
      setPagesProperties(updatedPagesProperties);
    };

    fetchPages();
  }, [])

  useEffect(() => {
    // Remove previous search queries and reset when navigating entities
    setTransactionSearchQuery('');
    setCustomerSearchQuery('');
    setIsSearchMode(false);
    setItemSelected(transactionSearchOptions.NAME);
    setCustomerSearchField(customerSearchOptions.name);
  }, [entity]);

  const onCreate = entity => {
    if (entity === entitiesEnum.TEAM) {
      onCreateAdmin();
    } else {
      navigate(`/${entity.toLowerCase()}/0`);
    }
  };

  const handleTransactionSearchChange = event => {
    setTransactionSearchQuery(event.target.value);
  };

  const clearTransactionQuery = () => {
    setTransactionSearchQuery('');
  };

  const handleCustomerSearchChange = event => {
    setCustomerSearchQuery(event.target.value);
  };

  const clearCustomerQuery = () => {
    setCustomerSearchQuery('');
  };

  const handlePairsSearchChange = event => {
    dispatch({ type: 'SET_PAIRS_SEARCH_QUERY', payload: event.target.value });
  };

  const clearPairsQuery = () => {
    dispatch({ type: 'SET_PAIRS_SEARCH_QUERY', payload: '' });
  };

  const handleSearchCurrencyChange = event => {
    dispatch({ type: 'SET_SEARCH_CURRENCY_QUERY', payload: event.target.value });
  };

  const clearCurrencyQuery = () => {
    dispatch({ type: 'SET_SEARCH_CURRENCY_QUERY', payload: '' });
  };

  const handleOpenFilterModal = () => setFilterModal(true);
  const handleCloseFilterModal = () => setFilterModal(false);

  const handleCloseAdvancedSearchModal = () => setOpenAdvancedSearchModal(false);

  const handleTransactionSubmit = event => {
    event.preventDefault();

    if (transactionSearchQuery) {
      fetchTransactionDataFromQuery({ itemSelected, query: transactionSearchQuery });
    }
  };

  const handleCustomerSubmit = event => {
    event.preventDefault();

    if (customerSearchQuery) {
      const key = Object.keys(customerSearchOptions).find(
        key => customerSearchOptions[key] === customerSearchField
      );
      fetchCustomerDataFromQuery({ key, query: customerSearchQuery });
    }
  };

  const handleOpenSortModal = () => setOpenSortModal(true);

  const handleOpenCustomerSortModal = () => setOpenCustomerSortModal(true);

  const resetSearchAndFilters = () => {
    dispatch({ type: 'SET_CUSTOMER_SORT', payload: '' });
    dispatch({ type: 'SET_CUSTOMER_FILTER_BY_ACCOUNT_TYPE', payload: '' });
    setIsSearchMode(false);
    setFilterByAccountType(false);
    setCustomerSearchField(customerSearchOptions.name);
    setCustomerSearchQuery('');
    fetchCustomerDataFromQuery({ query: '', key: 'name' });
  };

  return (
    <div className={clsx(classes.root)}>
      <div className={classes.row} style={{ minWidth: '1200px' }}>
        <ToolbarContent
          pagesProperties={pagesProperties}
          entity={entity}
          classes={classes}
          onCreate={() => onCreate(entity)}
          setActiveCards={setActiveCards}
          activeCards={activeCards}
        />
        <span className={classes.spacer} />
        {isTransaction && (
          <>
            {isSearchMode && (
              <div
                className={classes.refreshIcon}
                onClick={() => {
                  // Clear the cache
                  window.sessionStorage.clear();
                  setItemSelected(transactionSearchOptions.NAME);
                  setIsSearchMode(false);
                  setTransactionSearchQuery('');
                  dispatch({ type: 'SET_TRANSACTION_SORT', payload: '' });
                  dispatch({ type: 'SET_TRANSACTION_FILTER_OBJ', payload: {} });
                  fetchTransactionDataFromQuery({ itemSelected: null, query: null });
                }}
              >
                <Tooltip title="Refresh">
                  <IconButton>
                    <RefreshIcon />
                  </IconButton>
                </Tooltip>
              </div>
            )}
            <SearchBarDropDown
              searchQuery={transactionSearchQuery}
              changeQuery={handleTransactionSearchChange}
              handleSubmit={handleTransactionSubmit}
              clearQuery={clearTransactionQuery}
              itemSelected={itemSelected}
              setItemSelected={setItemSelected}
              entity={entitiesEnum.TRANSACTION}
            />
          </>
        )}

        {isCustomer && (
          <>
            {(isSearchMode || flterByAccountType) && (
              <div className={classes.refreshIcon} onClick={resetSearchAndFilters}>
                <Tooltip title="Back to Default">
                  <IconButton>
                    <RefreshIcon />
                  </IconButton>
                </Tooltip>
              </div>
            )}
            <SearchBarDropDown
              searchQuery={customerSearchQuery}
              changeQuery={handleCustomerSearchChange}
              handleSubmit={handleCustomerSubmit}
              clearQuery={clearCustomerQuery}
              itemSelected={customerSearchField}
              setItemSelected={setCustomerSearchField}
              entity={entitiesEnum.USERPROFILE}
            />
          </>
        )}

        {(isTransaction || isCustomer) && (
          <Box sx={{ minWidth: 110, marginLeft: '8px', marginRight: '8px' }}>
            <FormControl fullWidth>
              <InputLabel id="no-of-results-per-page">Results per page</InputLabel>
              <Select
                style={{ height: '32px', borderRadius: '12px' }}
                labelId="no-of-results-per-page"
                value={isTransaction ? noOfResultsTransactions : noOfResultsCustomers}
                label="Results per page"
                onChange={e => {
                  if (isTransaction) setNoOfResultsTransactions(e.target.value);
                  if (isCustomer) setNoOfResultsCustomers(e.target.value);
                }}
              >
                {resultsPerPage.map(value => (
                  <MenuItem value={value} key={value}>
                    {value}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        )}

        {(isPair) && (
          <SearchBar
            searchQuery={pairsSearchQuery}
            changeQuery={handlePairsSearchChange}
            clearQuery={clearPairsQuery}
          />
        )}
        {isCurrency && (
          <SearchBar
            searchQuery={searchCurrencyQuery}
            changeQuery={handleSearchCurrencyChange}
            clearQuery={clearCurrencyQuery}
          />
        )}
        {hasImport && <TableImport entity={entity} />}
        {hasExport && <TableExport entity={entity} listData={listData} />}
        {isTransaction && (
          <>
            <div className={classes.filterIcon} onClick={handleOpenFilterModal}>
              <Tooltip title="Filter Results">
                <IconButton>
                  <FilterIcon active={isFiltered(filterObj)} />
                </IconButton>
              </Tooltip>
            </div>
            <div onClick={handleOpenSortModal}>
              <Tooltip title="Sort Results">
                <IconButton>
                  <SortIcon
                    sx={{ fontSize: '28px', color: transactionSort ? '#1fe3ac' : '#19365e' }}
                  />
                </IconButton>
              </Tooltip>
            </div>
          </>
        )}
        {isTransaction && (
          <>
            <Modal open={filterModal} onClose={handleCloseFilterModal}>
              <Box>
                <TransactionFilterModal
                  onClose={handleCloseFilterModal}
                  searchTransactions={listData.searchTransactions}
                  isSearchMode={listData.isSearchMode}
                />
              </Box>
            </Modal>
            <Modal open={openSortModal} onClose={() => setOpenSortModal(false)}>
              <Box>
                <TransactionSortModal
                  entity={entitiesEnum.TRANSACTION}
                  onClose={() => setOpenSortModal(false)}
                />
              </Box>
            </Modal>
          </>
        )}
        {isCustomer && (
          <>
            <div>
              <Box>
                <CustomerFilterModalByType
                  listData={listData}
                  setValue={() => {
                    setFilterByAccountType(true);
                  }}
                />
              </Box>
            </div>
            <div onClick={handleOpenCustomerSortModal}>
              <Tooltip title="Sort Results">
                <IconButton>
                  <SortIcon
                    sx={{ fontSize: '28px', color: customerSort ? '#1fe3ac' : '#19365e' }}
                  />
                </IconButton>
              </Tooltip>
            </div>
            <Modal open={openAdvancedSearchModal} onClose={handleCloseAdvancedSearchModal}>
              <Box>
                <CustomerFilterModal onClose={handleCloseAdvancedSearchModal} />
              </Box>
            </Modal>
            <Modal open={openCustomerSortModal} onClose={() => setOpenCustomerSortModal(false)}>
              <Box>
                <CustomerSortModal onClose={() => setOpenCustomerSortModal(false)} />
              </Box>
            </Modal>
          </>
        )}
      </div>
    </div>
  );
};
