import React from 'react';

export const PLN = ({ width = '45', height = '45' }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 48 48">
    <g data-name="Group 8515">
      <path
        data-name="Path 10262"
        d="M13704 4608a24 24 0 1 0-24-24 24 24 0 0 0 24 24z"
        transform="translate(-13680.002 -4560)"
        style={{ fill: '#f0f0f0' }}
      />
      <path
        data-name="Path 10263"
        d="M13728 4816c0 12.327-10.744 22.321-24 22.321s-24-9.994-24-22.321"
        transform="translate(-13680.002 -4790.321)"
        style={{ fill: '#d80027' }}
      />
    </g>
  </svg>
);
