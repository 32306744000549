import { makeStyles } from '@mui/styles';

import DropDownArrow from '../../../../assets/icons/arrowDownIcon.svg';

export const useStyles = makeStyles(theme => ({
  mainContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
    fontSize: '18px',
    color: theme.palette.icons.nav,
    fontFamily: theme.typography.default,
    fontWeight: 'bold',
    flexBasis: '50%',
    cursor: 'pointer',
    '& p': {
      fontWeight: 'normal',
    },
    '& select, input': {
      height: '48px',
      borderRadius: '8px',
      border: '1px solid #e6e6e6',
      fontSize: '18px',
      color: theme.palette.icons.nav,
      paddingLeft: '16px',
    },
    '& > select': {
      appearance: 'none',
      background: `url(${DropDownArrow})`,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'bottom 18px right 10px',
    },
  },
  inputContainer: {
    height: '48px',
    borderRadius: '8px',
    border: 'solid 1px #e6e6e6',
    fontSize: '20px',
    fontWeight: 'normal',
    padding: '10px',
    flexGrow: '1',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  bigSize: {
    flexGrow: '7',
  },
  modalStyle: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '704px',
    height: 'auto',
    borderRadius: '15px',
    background: theme.palette.white,
    border: 'none',
    boxShadow: 24,
    p: 4,
  },
}));
