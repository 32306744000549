import React from 'react';

export const NumberOfBusinessAccounts = ({ className, color }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40">
    <g data-name="Group 10291" transform="translate(-608 -816)">
      <rect
        data-name="Rectangle 17426"
        width="40"
        height="40"
        rx="8"
        transform="translate(608 816)"
        fill="#85e2ff"
      />
      <g data-name="Group 8386">
        <path
          data-name="Subtraction 9"
          d="M16731.506 5752.484h-18.012a.944.944 0 0 1-.211-.023 2.975 2.975 0 0 1-.725-.713.96.96 0 0 1-.023-.208v-6.459a23.188 23.188 0 0 0 2.668 1.075v1.2a.741.741 0 1 0 1.469 0v-.764a22.78 22.78 0 0 0 5.779.743h.1a22.892 22.892 0 0 0 5.824-.756v.777a.741.741 0 1 0 1.471 0v-1.218a23.376 23.376 0 0 0 2.619-1.062v6.463a.954.954 0 0 1-.959.945zm-9-6.791a21.5 21.5 0 0 1-6.145-.9l-1.15-.408v.029a22.155 22.155 0 0 1-2.668-1.163v-3.451a.956.956 0 0 1 .959-.949h4.379v.025h9.182v-.025h4.451a.956.956 0 0 1 .961.949v3.448a22.035 22.035 0 0 1-2.619 1.15v-.009l-.684.244a21.449 21.449 0 0 1-6.672 1.061zm3.053-8.362h-6.111v-1.458a.2.2 0 0 1 .189-.21h5.732a.2.2 0 0 1 .189.21v1.457z"
          transform="translate(-16094 -4908)"
          fill="#fff"
        />
      </g>
    </g>
  </svg>
);
