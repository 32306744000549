import { Typography } from '@mui/material';
import React from 'react';

export const MenuItem = ({ title, active, classes, onClick }) => (
  <div className={active ? classes.divSelected : classes.menuDiv} onClick={onClick}>
    <Typography
      classes={{ root: active ? classes.selected : classes.menuItem }}
      variant="subtitle1"
    >
      {title}
    </Typography>
  </div>
);
