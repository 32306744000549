import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(theme => ({
  customerFilterModalContainer: {
    backgroundColor: 'white',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '480px',
    height: 'auto',
    borderRadius: '15px',
    zIndex: 5,
    fontFamily: theme.typography.default,
    color: theme.palette.icons.nav,
    padding: '21px',
    fontSize: '14px',
    '& header': {
      fontSize: '20px',
      fontWeight: 'bold',
      textAlign: 'center',
    },
  },
  statusRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '16px',
    marginTop: '16px',
  },
  defaultStatus: {
    width: '72px',
    height: '24px',
    borderRadius: '12px',
    backgroundColor: '#f1f1f3',
    color: '#a5a6ae',
    fontSize: '12px',
    textAlign: 'center',
    padding: '5px 6px',
    cursor: 'pointer',
  },
  createdActive: {
    backgroundColor: '#0cc5ff',
    color: theme.palette.icons.nav,
  },
  rejectedActive: {
    backgroundColor: '#f8d753',
    color: theme.palette.icons.nav,
  },
  closedActive: {
    backgroundColor: '#fc3333',
    color: theme.palette.icons.nav,
  },
  activeActive: {
    backgroundColor: '#1fe3ac',
    color: theme.palette.icons.nav,
  },
  suspendedActive: {
    backgroundColor: '#a5a6ae',
    color: theme.palette.icons.nav,
  },
  inputRow: {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
    marginTop: '11px',
    '& input': {
      border: '1px solid #e6e6e6',
      borderRadius: '8px',
      height: '32px',
      padding: '8px',
      color: theme.palette.icons.nav,
      fontFamily: theme.typography.default,
      fontWeight: '500',
    },
    '& input[type="text"]:focus, textarea': {
      outline: 'none !important',
    },
    '& input[type="date"]:focus, textarea': {
      outline: 'none !important',
    },
    '& input[type="number"]:focus, textarea': {
      outline: 'none !important',
    },
  },
  dateRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: '16px',
    justifyContent: 'space-between',
    '& section': {
      display: 'flex',
      flexDirection: 'row',
      gap: '16px',
      alignItems: 'center',
    },
    '& input': {
      border: '1px solid #e6e6e6',
      borderRadius: '8px',
      height: '32px',
      padding: '8px',
      color: theme.palette.icons.nav,
      fontFamily: theme.typography.default,
      fontWeight: '500',
      cursor: 'pointer',
    },
    '& input[type="text"]:focus, textarea': {
      outline: 'none !important',
    },
    '& input[type="date"]:focus, textarea': {
      outline: 'none !important',
    },
    '& input[type="number"]:focus, textarea': {
      outline: 'none !important',
    },
  },
  searchBtn: {
    display: 'flex',
    flexDirection: 'row',
    gap: '39%',
    alignItems: 'center',
    paddingLeft: '16px',
    width: '432px',
    height: '40px',
    borderRadius: '8px',
    border: 'none',
    marginTop: '32px',
    backgroundColor: '#d9e1e7',
    color: 'white',
    fontSize: '18px',
  },
  enabledSearch: {
    backgroundColor: theme.palette.icons.nav,
    color: 'white',
    cursor: 'pointer',
  },
}));
