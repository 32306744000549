import React from 'react';

export const ChatIcon = ({ width = '24', height = '24', active = false }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 24 24">
    <g data-name="Group 8807">
      <g data-name="Ellipse 1529" style={{ fill: active ? '#F8D753' : '#fff', stroke: '#e6e6e6' }}>
        <circle cx="12" cy="12" r="12" style={{ stroke: 'none' }} />
        <circle cx="12" cy="12" r="11.5" style={{ fill: 'none' }} />
      </g>
      <path
        data-name="Icon awesome-sms"
        d="M6 2.25c-3.314 0-6 2.182-6 4.875a4.327 4.327 0 0 0 1.336 3.063 5.963 5.963 0 0 1-1.284 2.246.186.186 0 0 0-.035.2.184.184 0 0 0 .171.113 5.36 5.36 0 0 0 3.3-1.2A7.148 7.148 0 0 0 6 12c3.314 0 6-2.182 6-4.875S9.314 2.25 6 2.25zM3 8.625h-.281a.188.188 0 0 1-.187-.187v-.375a.188.188 0 0 1 .188-.187h.288c.141 0 .244-.082.244-.155a.124.124 0 0 0-.052-.09l-.513-.441a.866.866 0 0 1-.312-.659.953.953 0 0 1 .994-.9h.286a.188.188 0 0 1 .188.188v.371a.188.188 0 0 1-.187.188h-.288c-.141 0-.244.082-.244.155a.124.124 0 0 0 .049.089l.513.441A.866.866 0 0 1 4 7.72a.951.951 0 0 1-1 .905zm4.5-.187a.188.188 0 0 1-.188.188h-.374a.188.188 0 0 1-.187-.187v-1.6l-.582 1.308a.187.187 0 0 1-.335 0L5.25 6.839v1.6a.188.188 0 0 1-.187.188h-.375a.188.188 0 0 1-.188-.189V6a.376.376 0 0 1 .375-.375h.375a.373.373 0 0 1 .335.206l.415.83.415-.83a.376.376 0 0 1 .335-.206h.375A.376.376 0 0 1 7.5 6zm1.132.188h-.288a.188.188 0 0 1-.187-.187v-.376a.188.188 0 0 1 .188-.187h.288c.141 0 .244-.082.244-.155a.124.124 0 0 0-.049-.089l-.513-.441A.866.866 0 0 1 8 6.532a.953.953 0 0 1 1-.905h.286a.188.188 0 0 1 .188.188v.375a.188.188 0 0 1-.187.188h-.294c-.141 0-.244.082-.244.155a.124.124 0 0 0 .049.089l.513.441a.866.866 0 0 1 .312.659.949.949 0 0 1-.991.903z"
        transform="translate(6 4.75)"
        style={{ fill: active ? '#fff' : '#b5b5b5' }}
      />
    </g>
  </svg>
);
