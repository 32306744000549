import { Box, Tab, Tabs } from '@mui/material';
import React from 'react';
import { uid } from 'react-uid';

import { useStyles } from './pairs-interval.hooks';
import { intervalHandler } from './pairs-interval.utils';

export const PairsIntervals = ({ intervals, intervalIndex, setIntervalIndex }) => {
  const classes = useStyles();

  const handleChange = (event, newIntervalIndex) => {
    setIntervalIndex(newIntervalIndex);
  };

  return (
    <Box className={classes.root}>
      <Box className={classes.menu}>
        <Tabs value={intervalIndex} onChange={handleChange}>
          {intervals.map(item => {
            const label = intervalHandler(item.start, item.end);

            return <Tab label={label} key={uid(item.id)} />;
          })}
        </Tabs>
      </Box>
    </Box>
  );
};
