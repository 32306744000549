import React from 'react';

export const GreenEdit = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="23.751"
    height="23.751"
    viewBox="0 0 23.751 23.751"
  >
    <path
      data-name="Union 916"
      d="M3.661 23.751A3.664 3.664 0 0 1 0 20.09V5.739a3.657 3.657 0 0 1 3.661-3.661H10.9a.812.812 0 0 1 .813.762 1.1 1.1 0 0 1 0 .122.816.816 0 0 1-.762.867.733.733 0 0 1-.114 0H3.654a1.907 1.907 0 0 0-1.911 1.91v14.249a1.916 1.916 0 0 0 1.918 1.92h14.254a1.91 1.91 0 0 0 1.911-1.92V12.9a.875.875 0 1 1 1.743-.146.731.731 0 0 1 0 .158v7.18a3.518 3.518 0 0 1-3.37 3.654h-.187zm.9-4.537a1.318 1.318 0 0 1-.287-.833l1.04-5.218a.446.446 0 0 1 .243-.4L18.049.273A.876.876 0 0 1 19.286.24l4.192 4.191a.876.876 0 0 1 .033 1.238L11.028 18.155a2.146 2.146 0 0 1-.466.285L5.4 19.477h-.244a.817.817 0 0 1-.597-.263zm2.484-5.384-.665 3.54 3.5-.656.037-.007L21.474 5.156l.088-.088-2.878-2.876z"
      style={{ fill: '#23c3a4' }}
    />
  </svg>
);
