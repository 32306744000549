import React from 'react';

export const BusinessIcon = ({ active = true }) => {
  if (active)
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 80 80">
        <g data-name="Group 8409">
          <g
            data-name="Rectangle 17426"
            style={{ fill: '#85e2ff', stroke: '#19365e', strokeWidth: '2px' }}
          >
            <rect width="80" height="80" rx="16" style={{ stroke: 'none' }} />
            <rect x="1" y="1" width="78" height="78" rx="15" style={{ fill: 'none' }} />
          </g>
          <g data-name="Group 8386">
            <path
              data-name="Union 467"
              d="M1313-2561a2 2 0 0 1-2-2v-17a2 2 0 0 1 2-2h8v-2a2 2 0 0 1 2-2h6a2 2 0 0 1 2 2v2h8a2 2 0 0 1 2 2v17a2 2 0 0 1-2 2z"
              transform="translate(-1286 2614)"
              style={{ fill: '#fff' }}
            />
            <path
              data-name="Union 466"
              d="M7751.465-753a3.457 3.457 0 0 1-3.467-3.446v-16.44a3.457 3.457 0 0 1 3.467-3.446h6.125l.029-2.043a2.516 2.516 0 0 1 2.392-2.624h7.974a2.516 2.516 0 0 1 2.392 2.624l-.021 2.043h6.175a3.457 3.457 0 0 1 3.467 3.446v16.44a3.457 3.457 0 0 1-3.467 3.446zm-1.332-3.446a1.33 1.33 0 0 0 1.332 1.327h25.066a1.332 1.332 0 0 0 1.334-1.327v-9.047a32.414 32.414 0 0 1-3.644 1.486v1.706a1.1 1.1 0 0 1-1.021 1.169 1.1 1.1 0 0 1-1.022-1.169v-1.089a31.442 31.442 0 0 1-8.179 1.059 31.5 31.5 0 0 1-8.109-1.038v1.068a1.1 1.1 0 0 1-1.022 1.169 1.1 1.1 0 0 1-1.022-1.169v-1.679a32.619 32.619 0 0 1-3.712-1.507v9.041zm23.142-9.673.947-.335v.007a30.637 30.637 0 0 0 3.644-1.607v-4.831a1.329 1.329 0 0 0-1.334-1.324h-6.2v.035h-12.773v-.035h-6.1a1.328 1.328 0 0 0-1.332 1.324v4.839a30.865 30.865 0 0 0 3.712 1.629v-.037l1.6.566a29.638 29.638 0 0 0 17.836-.232zm-5.023-10.213v-2.043a.281.281 0 0 0-.266-.292h-7.974a.279.279 0 0 0-.265.292v2.043z"
              transform="translate(-7723.998 807)"
              style={{ fill: '#19365e' }}
            />
          </g>
        </g>
      </svg>
    );

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40">
      <g data-name="Group 9006" transform="translate(-456 -544)">
        <rect
          data-name="Rectangle 16973"
          width="40"
          height="40"
          rx="8"
          transform="translate(456 544)"
          style={{ fill: '#f2f2f4' }}
        />
        <g data-name="Group 8589">
          <path
            data-name="Union 467"
            d="M2 18a2 2 0 0 1-2-2V4.88a2 2 0 0 1 2-2h5.413a2 2 0 0 1-.079-.56V2a2 2 0 0 1 2-2h3.334a2 2 0 0 1 2 2v.32a2 2 0 0 1-.079.56H20a2 2 0 0 1 2 2V16a2 2 0 0 1-2 2z"
            transform="translate(465 555)"
            style={{ fill: '#fff' }}
          />
          <path
            data-name="Union 466"
            d="M2.492 20A2.477 2.477 0 0 1 0 17.538V5.8a2.477 2.477 0 0 1 2.492-2.466h4.4l.021-1.459A1.8 1.8 0 0 1 8.634 0h5.731a1.8 1.8 0 0 1 1.719 1.874l-.014 1.46h4.438A2.477 2.477 0 0 1 23 5.8v11.738A2.477 2.477 0 0 1 20.508 20zm-.958-2.462a.953.953 0 0 0 .958.948h18.016a.954.954 0 0 0 .958-.948v-6.462a23.365 23.365 0 0 1-2.619 1.062v1.219a.74.74 0 1 1-1.469 0v-.778a22.727 22.727 0 0 1-5.878.756 22.768 22.768 0 0 1-5.829-.741v.763a.791.791 0 0 1-.735.835.791.791 0 0 1-.735-.835v-1.2a23.526 23.526 0 0 1-2.668-1.076v6.458zm16.633-6.91.68-.24a22.064 22.064 0 0 0 2.619-1.148V5.8a.952.952 0 0 0-.958-.946h-4.454v.025h-9.18v-.03H2.492a.952.952 0 0 0-.958.946v3.457A22.253 22.253 0 0 0 4.2 10.415v-.026l1.148.4a21.43 21.43 0 0 0 12.819-.16zm-3.611-7.3V1.874a.2.2 0 0 0-.191-.209H8.634a.2.2 0 0 0-.19.209v1.46z"
            transform="translate(465 554)"
            style={{ fill: '#a5a6ae' }}
          />
        </g>
      </g>
    </svg>
  );
};
