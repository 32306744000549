const compareByValue = valueName => (a, b) => {
  if (a[valueName] > b[valueName]) {
    return 1;
  }

  if (b[valueName] > a[valueName]) {
    return -1;
  }

  return 0;
};

const compareByDate =
  ({ canOrder } = {}) =>
  (a, b) => {
    if (canOrder) {
      return a.order - b.order;
    }

    return +new Date(b.createdAt) - +new Date(a.createdAt);
  };

const compareByCurrencyName = (a, b) => {
  const currencyA = a.name?.toLowerCase();
  const currencyB = b.name?.toLowerCase();

  if (currencyA < currencyB) return -1;
  if (currencyA > currencyB) return 1;

  return 0;
};

export { compareByCurrencyName, compareByDate, compareByValue };
