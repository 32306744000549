import { useStyles } from './modal.hooks';
import React from 'react';

export const ModalBodyContent = ({ obj }) => {
  const classes = useStyles();
  const className= obj['success']? classes['success']: obj['danger']? classes['error']:''
  return (
    <div className={classes.insideBody}>
      {Object.keys(obj)?.filter(item=> item !=='error' || item!== 'success')?.map(item => {
        return (
          <div className={classes.contentRow} key={item}>
            <div>{item}:</div>
            <div className={classes.text}>{obj?.[item]}</div>
          </div>
        );
      })}
      <div className={className}></div>
    </div>
  );
};
