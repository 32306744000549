import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    [theme.breakpoints.up('sm')]: {},
  },
  shiftContent: {
    paddingLeft: '8.5rem',
  },
  content: {
    height: '100%',
    overflowY: 'auto',
  },
  loginContent: {
    display: 'flex',
    flexDirection: 'row',
    height: '100%',
  },
  contentContainer: {
    height: '100%',
    flexGrow: '1',
    display: 'flex',
    flexDirection: 'column',
  },
  loginToolbar: {
    height: '112px',
    backgroundColor: 'white',
    borderRadius: '0 0 16px 16px',
    '& header': {
      margin: '36px 48px',
      fontSize: '30px',
      color: theme.palette.icons.nav,
      fontFamily: theme.typography.default,
      fontWeight: 'bold',
    },
  },
  signInContainer: {
    flexGrow: '1',
    padding: '24px',
    display: 'flex',
    flexDirection: 'column',
  },
}));
