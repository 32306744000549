import LinkIcon from '@mui/icons-material/Link';
import clsx from 'clsx';
import React from 'react';

import { getFileURL } from '../../../../services';
import {
  getDateByLocale,
  getEntityLabel,
  getLabelOverride,
  splitAndCapitalizeString,
} from '../../../../utils';
import { useStyles } from './field-value.hooks';

export const FieldValue = ({ field, value, entity }) => {
  const classes = useStyles();

  if (value === null || value === '' || (Array.isArray(value) && value.every(v => v === null))) {
    return '-';
  }

  if (field.type === 'float' || field.type === 'int') {
    return value;
  }

  if (field.name === 'active') {
    return value ? 'Enabled' : 'Disabled';
  }

  if (field.name === 'accountStatus') {
    return (
      <span className={clsx(classes.accountStatus, value)}>
        {splitAndCapitalizeString({ str: value })}
      </span>
    );
  }

  if (field.type === 'boolean') {
    return value ? 'Yes' : 'No';
  }

  if (field.type === 'enumSelect') {
    return getLabelOverride(value) || value;
  }

  if (field.type === 'timestamp' || field.name === 'timestamp') {
    return getDateByLocale({ date: new Date(value), withTime: true });
  }

  if (field.type === 'date') {
    return getDateByLocale({ date: new Date(value) });
  }

  if (field.type === 'translation') {
    return value.es.substr(0, 80);
  }

  if (field.type === 'imageUpload' && value) {
    const imgPath = `${entity.toLowerCase()}/${field.name}/${value}`;

    return <img alt="upload" className={classes.rowImage} src={getFileURL(imgPath)} />;
  }

  if (field.type === 'string') {
    if (field.name.slice(-3) === 'URL' && value) {
      return (
        <a href={value} rel="noopener noreferrer" target="_blank">
          <LinkIcon />
        </a>
      );
    }

    return value;
  }

  if (field.type === 'entitySelect' || field.type === 'entitySelectMultiple') {
    return getEntityLabel(value);
  }

  return null;
};
