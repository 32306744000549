import React from 'react';

import { AccountStatus, ColorDot, DashedLine, InactiveStatus, SideRectangle } from './icons';
import { useStyles } from './kycKyb.hooks';

/**
 * Sub-component of the CustomerDetailPage parent component. This is a read-only component
 * that renders the Kyc status of the customer
 * @param {object} data the user profile data of type userProfile
 */
export const KycKyb = ({ data }) => {
  const classes = useStyles();

  return (
    <div>
      <div className={classes.header}>
        <aside className={classes.label}>
          <SideRectangle />
          <header>KYC/KYB</header>
        </aside>
      </div>
      <main className={classes.mainContainer}>
        <section className={classes.statusContainer}>
          <div className={classes.dashedLine}>
            <DashedLine />
          </div>
          <div className={classes.targetStatusContainer}>
            <div className={classes.unstartedTarget}>
              {data?.kyc?.status === 'unstarted' ? (
                <AccountStatus status="suspended" />
              ) : (
                <InactiveStatus />
              )}
            </div>
            <div className={classes.pendingTarget}>
              {data?.kyc?.status === 'pending' ? <AccountStatus /> : <InactiveStatus />}
            </div>
            <div className={classes.createdTarget}>
              {data?.kyc?.status === 'completed' ? (
                <AccountStatus status="created" />
              ) : (
                <InactiveStatus />
              )}
            </div>
          </div>
          <div className={classes.dotContainer}>
            <div>
              <ColorDot color="#a5a6ae" />
            </div>
            <div>
              <ColorDot color="#f8d753" />
            </div>
            <div>
              <ColorDot color="#0cc5ff" />
            </div>
          </div>
          <div className={classes.textContainer}>
            <div className={data?.kyc?.status === 'unstarted' ? classes.highlightText : null}>
              Unstarted
            </div>
            <div className={data?.kyc?.status === 'pending' ? classes.highlightText : null}>
              Pending
            </div>
            <div className={data?.kyc?.status === 'completed' ? classes.highlightText : null}>
              Created
            </div>
          </div>
        </section>
      </main>
    </div>
  );
};
