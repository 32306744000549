import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import { Grid } from '@mui/material';
import React, { useEffect } from 'react';
import { Controller } from 'react-hook-form';

import { getFileURL, uploadFile } from '../../../../services';
import { HSpacer } from '../../../h-spacer';
import { useStyles } from './form-image-upload-field.hooks';

export const FormImageUploadField = ({
  field,
  register,
  rules,
  setValue,
  watch,
  entity,
  control,
}) => {
  const { name } = field;
  const entityURL = entity.toLowerCase();
  const classes = useStyles();

  useEffect(() => {
    register(name, rules);
  }, []);

  const handleFileUpload = async event => {
    const file = event.target.files[0];
    if (file) {
      const timestamp = +new Date();
      const filename = `${timestamp}-${file.name}`
        .replace(/\s/g, '-')
        .replace(/á|à|â|é|è|ê|í|ì|î|ó|ò|ô|ú|ù|û|ç|ñ`/gi, '-');
      const fileLocation = `${entityURL}/${name}/${filename}`;
      const { success } = await uploadFile(file, fileLocation);
      setValue(name, success ? filename : '');
    }
  };

  const handleFileRemove = () => {
    setValue(name, '');
  };

  const value = watch(name, '');
  const fileLocation = getFileURL(`${entityURL}/${name}/${value}`);

  return (
    <Controller
      render={({ field, formState }) => (
        <Grid container>
          <Grid item xs={6}>
            {value && <img alt="" className={classes.imgUploaded} src={fileLocation} />}
          </Grid>
          <Grid item xs={1}>
            <div>
              <input
                accept="image/*"
                className={classes.input}
                id={`${name}-image-upload`}
                type="file"
                onChange={handleFileUpload}
                {...field}
                {...formState}
              />
              <label htmlFor={`${name}-image-upload`} className={classes.secondary}>
                <AddPhotoAlternateIcon className={classes.icon} />
                Upload image
              </label>
            </div>
            <HSpacer />
            <div>
              {value && (
                <span className={classes.secondary} onClick={handleFileRemove}>
                  <DeleteForeverOutlinedIcon className={classes.icon} />
                  Delete image
                </span>
              )}
            </div>
          </Grid>
        </Grid>
      )}
      control={control}
      defaultValue={[]}
      name={name}
      rules={rules}
    />
  );
};
