import { Input, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import React from 'react';

import { useStyles } from './modal-data-form.hooks';

export const ModalDataForm = ({ onChange, styles, data, active = true }) => {
  const classes = useStyles();

  return (
    <Table>
      <TableHead>
        <TableRow className={styles.tableHeadRow} style={{ height: '4.2rem' }}>
          <TableCell className={styles.tableHeadCell} style={{ color: '#454846' }}>
            Name
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody className={styles.tableRoot}>
        <TableRow className={styles.tableRow} hover style={{ height: '4.2rem' }}>
          <TableCell className={classes.tableCell}>
            <Input
              className={classes.input}
              style={!active ? { color: '#a6acb2' } : { backgroundColor: '#fcfcfc' }}
              disableUnderline
              onChange={onChange}
              name="name"
              defaultValue={data?.name ?? ''}
              placeholder="Name"
              readOnly={!active}
            />
          </TableCell>
        </TableRow>
      </TableBody>
      <TableHead>
        <TableRow className={styles.tableHeadRow} style={{ height: '4.2rem' }}>
          <TableCell className={styles.tableHeadCell} style={{ color: '#454846' }}>
            E-mail
          </TableCell>
          <TableCell className={styles.tableHeadCell} style={{ color: '#454846' }}>
            Password
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody className={styles.tableRoot}>
        <TableRow className={styles.tableRow} hover style={{ height: '4.2rem' }}>
          <TableCell className={classes.tableCell}>
            <Input
              className={classes.input}
              style={!active ? { color: '#a6acb2' } : { backgroundColor: '#fcfcfc' }}
              disableUnderline
              onChange={onChange}
              name="email"
              defaultValue={data?.email ?? ''}
              placeholder="Email"
              readOnly={!active}
            />
          </TableCell>
          <TableCell className={classes.tableCell}>
            <Input
              className={classes.input}
              style={!active ? { color: '#a6acb2' } : { backgroundColor: '#fcfcfc' }}
              disableUnderline
              onChange={onChange}
              name="password"
              placeholder="Password"
              readOnly={!active}
            />
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
};
