import { List } from '@mui/material';
import clsx from 'clsx';
import React from 'react';
import { uid } from 'react-uid';

import { useStyles } from './sidebar-nav.hooks';
import { SidebarNavItem } from './sidebar-nav-item';

export const SidebarNav = props => {
  const { pages, className, userName, ...rest } = props;
  const classes = useStyles();
  return (
    <List {...rest} className={clsx(classes.root, className)}>
      {pages.map(page => (
        <SidebarNavItem
          page={page}
          active={window.location.pathname === page.href}
          key={uid(page)}
        />
      ))}
    </List>
  );
};
