import { response } from '../services';

const requestDecorator = async (promise, { shouldReturnData } = {}) => {
  try {
    if (shouldReturnData) {
      return await promise;
    }

    await promise;

    return response(true);
  } catch (error) {
    // TODO: remove console.log and throw a custom error
    // eslint-disable-next-line no-console
    console.log('Error in promise: ', error);

    return response(false, error.code, error);
  }
};

export { requestDecorator };
