import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import EditIcon from '@mui/icons-material/Edit';
import { Button, Fab, Grid, Modal, Typography } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import ReactPlayer from 'react-player';

import { getFileURL } from '../../../../services';
import { getEnumLabel, getMappedEntity, getSubObjectValidations } from '../../../../utils';
import { GenericTable } from '../../../generic-table';
import { HSpacer } from '../../../h-spacer';
import { SubObjectForm } from '../../../sub-object-form';
import { FormField } from '../../index';
import { BlockTitleValue } from './block-title-value';
import { useStyles } from './form-sub-object-list-field.hooks';

export const FormSubObjectListField = ({
  entity,
  field,
  id,
  register,
  watch,
  setValue,
  rules,
  isReadOnly,
}) => {
  const [errorMessage, setErrorMessage] = useState('');
  const [editingData, setEditingData] = useState(null);
  const classes = useStyles();
  const formRef = useRef();
  const { fields, tableFields } = getMappedEntity(field.relatedEntity);
  const data = watch(field.name, []) || [];
  const entityURL = entity.toLowerCase();

  useEffect(() => {
    register(field.name, rules);
  }, []);

  const {
    handleSubmit,
    formState: { errors },
    setValue: nestedSetValue,
    control,
    register: nestedRegister,
    getValues,
    watch: nestedWatch,
  } = useForm();

  const handleFormSubmit = (values, event) => {
    if (formRef.current !== event.target) {
      return false;
    }

    setErrorMessage('');

    const validation = getSubObjectValidations(entity, field.name);
    if (validation && !validation.validate(data, values)) {
      setErrorMessage(validation.errorMessage);

      return false;
    }

    const newData = [...data, values];
    setValue(field.name, newData);
    fields.forEach(f => nestedSetValue(f.name, f.type === 'string' ? '' : null));

    return true;
  };

  const handleRemoveItem = index => {
    const newData = data.filter((d, i) => i !== index);
    setValue(field.name, newData);
  };

  const handleMoveContentBlock = (currentIndex, newIndex) => {
    const newData = [...data];
    const movingItem = data[currentIndex];
    newData.splice(currentIndex, 1);
    newData.splice(newIndex, 0, movingItem);
    setValue(field.name, newData);
  };

  const handleSaveSubObject = (values, index) => {
    const newData = [...data];
    newData[index] = { ...values };
    setValue(field.name, newData);
    setEditingData(null);
  };

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="h2">{field.label}</Typography>
        </Grid>
        <HSpacer />
        <Grid item xs={12}>
          {field.name === 'content' ? (
            data.map((block, index) => (
              <div className={classes.contentBlock} key={`${block.type}-${block.title}`}>
                <Typography className={classes.contentBlockTitle}>
                  {getEnumLabel(block.type)}
                </Typography>
                {block.title && <Typography>Título: {block.title}</Typography>}
                {block.videoSubtype && <Typography>Video subtype: {block.videoSubtype}</Typography>}
                {block.url && (
                  <>
                    {block.type === 'video_embed' && (
                      <ReactPlayer controls height="36rem" url={block.url} width="64rem" />
                    )}
                    {block.type === 'video_link' && (
                      <div>
                        <a href={block.url} rel="noopener noreferrer" target="_blank">
                          Link
                        </a>
                      </div>
                    )}
                    {block.type === 'link' && (
                      <div>
                        <a href={block.url} rel="noopener noreferrer" target="_blank">
                          <Typography>
                            <span dangerouslySetInnerHTML={{ __html: block.text }} />
                          </Typography>
                        </a>
                      </div>
                    )}
                    {block.type === 'tweet' && (
                      <div>
                        <a href={block.url} rel="noopener noreferrer" target="_blank">
                          See Tweet
                        </a>
                      </div>
                    )}
                  </>
                )}
                {block.bgColor && (
                  <BlockTitleValue
                    title="BG Color"
                    value={<Typography>{block.bgColor}</Typography>}
                  />
                )}
                {block.caption && (
                  <BlockTitleValue
                    title="Caption"
                    value={<Typography>{block.caption}</Typography>}
                  />
                )}
                {block.subcaption && (
                  <BlockTitleValue
                    title="Caption 2"
                    value={<Typography>{block.subcaption}</Typography>}
                  />
                )}
                {block.challenge && (
                  <BlockTitleValue
                    title="Challenge"
                    value={
                      <Typography>
                        <span dangerouslySetInnerHTML={{ __html: block.challenge }} />
                      </Typography>
                    }
                  />
                )}
                {block.context && (
                  <BlockTitleValue
                    title="Context"
                    value={
                      <Typography>
                        <span dangerouslySetInnerHTML={{ __html: block.context }} />
                      </Typography>
                    }
                  />
                )}
                {block.goal && (
                  <BlockTitleValue
                    title="Goal"
                    value={
                      <Typography>
                        <span dangerouslySetInnerHTML={{ __html: block.goal }} />
                      </Typography>
                    }
                  />
                )}
                {block.text && block.type !== 'link' && (
                  <Typography>
                    <span dangerouslySetInnerHTML={{ __html: block.text }} />
                  </Typography>
                )}
                {block.blockListImage &&
                  block.blockListImage.length > 0 &&
                  block.blockListImage.map(img => (
                    <img
                      alt=""
                      className={classes.imgUploaded}
                      key={img}
                      src={getFileURL(`${entityURL}/blockListImage/${img}`)}
                    />
                  ))}
                {block.blockImage && (
                  <img
                    alt=""
                    className={classes.imgUploaded}
                    key={block.blockImage}
                    src={getFileURL(`${entityURL}/blockImage/${block.blockImage}`)}
                  />
                )}
                {!isReadOnly && (
                  <div className={classes.contentBlockButtons}>
                    {index > 0 && (
                      <Fab
                        color="secondary"
                        component="span"
                        onClick={() => handleMoveContentBlock(index, index - 1)}
                      >
                        <ArrowUpwardIcon />
                      </Fab>
                    )}
                    {index < data.length - 1 && (
                      <Fab
                        color="secondary"
                        component="span"
                        onClick={() => handleMoveContentBlock(index, index + 1)}
                      >
                        <ArrowDownwardIcon />
                      </Fab>
                    )}
                    <Fab
                      color="secondary"
                      component="span"
                      onClick={() => setEditingData({ data: block, index })}
                    >
                      <EditIcon />
                    </Fab>
                    <Fab color="secondary" component="span" onClick={() => handleRemoveItem(index)}>
                      <DeleteForeverIcon />
                    </Fab>
                  </div>
                )}
              </div>
            ))
          ) : (
            <GenericTable
              data={data || []}
              entity={field.relatedEntity}
              isReadOnly
              onEditData={setEditingData}
              onRemoveItem={handleRemoveItem}
              tableFields={tableFields}
              disableFullPageScroll
            />
          )}
        </Grid>
        <HSpacer />
        <HSpacer />
        {!isReadOnly && (
          <>
            <Grid item xs={12}>
              <Typography variant="h4">Add new</Typography>
            </Grid>
            <HSpacer />
            <form
              className={classes.subEntityForm}
              onSubmit={handleSubmit(handleFormSubmit)}
              ref={formRef}
            >
              <Grid container item spacing={4} xs={12}>
                {fields.map(subEntityField => {
                  const fieldProps = {
                    entity,
                    field: subEntityField,
                    control,
                    getValues,
                    register: nestedRegister,
                    setValue: nestedSetValue,
                    subObject: field.name,
                    errors: errors[field.name],
                    rules: { required: field.required },
                    watch: nestedWatch,
                  };

                  return <FormField id={id} key={subEntityField.name} {...fieldProps} />;
                })}
              </Grid>
              <HSpacer />
              <Grid alignItems="center" container item justifyContent="flex-end" xs={12}>
                <Typography align="right" className={classes.errorMessage}>
                  {errorMessage}
                </Typography>
                <Button
                  color="primary"
                  size="large"
                  type="submit"
                  variant="contained"
                  className={classes.button}
                >
                  Add
                </Button>
              </Grid>
            </form>
          </>
        )}
      </Grid>
      <Modal
        aria-describedby="simple-modal-description"
        aria-labelledby="simple-modal-title"
        className={classes.modal}
        onClose={() => setEditingData(null)}
        open={!!editingData}
      >
        <div className={classes.modalPaper}>
          <SubObjectForm
            allData={data}
            data={editingData?.data}
            dataIndex={editingData?.index}
            entity={entity}
            fields={fields}
            id={id}
            onCancel={() => setEditingData(null)}
            onSave={handleSaveSubObject}
            subObject={field}
          />
        </div>
      </Modal>
    </>
  );
};
