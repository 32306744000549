import React from 'react';
import copyIcon from '../../../../assets/icons/copyIcon.svg';

import { useStyles } from '../multicurrency.hooks';

export const CopyButton = ({ onClick, text = 'Copy Info', textClass={} }) => {
  const classes = useStyles();

  return (
    <button role={'button'} className={classes.copyInfoButton} type="button" onClick={onClick}>
      <p className={classes.textOverflow+" "+textClass}>{text}</p>
      <img src={copyIcon} alt="copy" />
    </button>
  );
};
