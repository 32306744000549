import { TableCell, TableRow } from '@mui/material';
import React from 'react';

import { useStyles } from './customers-table-head.hooks';

export const CustomersTableHead = ({ isAllRecordsChecked, setIsAllRecordsChecked }) => {
  const classes = useStyles();

  return (
    <TableRow className={classes.tableRow}>
      <TableCell className={classes.nameTableCell}>
        <input
          type="checkbox"
          id="nameCheckbox"
          checked={isAllRecordsChecked}
          onChange={() => setIsAllRecordsChecked(!isAllRecordsChecked)}
        />
        Name
      </TableCell>
      <TableCell className={classes.tableCell}>Status</TableCell>
      <TableCell className={classes.tableCell}>Account Type</TableCell>
      {/* <TableCell className={classes.tableCell}>Account Number</TableCell> */}
      <TableCell className={classes.tableCell}>Phone Number</TableCell>
      <TableCell className={classes.tableCell}>Date of Birth</TableCell>
      <TableCell className={classes.tableCell}>Email</TableCell>
    </TableRow>
  );
};
