import React from 'react';

export const KixySideNavBarLogo = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="48" height="24.027" viewBox="0 0 48 24.027">
    <defs>
      <clipPath id="3eox9mdnna">
        <path data-name="Rectangle 16537" style={{ fill: 'none' }} d="M0 0h48v24.027H0z" />
      </clipPath>
    </defs>
    <g data-name="Group 7299">
      <g data-name="Group 6235" style={{ clipPath: 'url(#3eox9mdnna)' }}>
        <path
          data-name="Path 9141"
          d="M51.835 5.513a1.259 1.259 0 0 1 .359.905v11.508a6.358 6.358 0 0 1-1.838 4.981 6.981 6.981 0 0 1-4.848 1.651 10.138 10.138 0 0 1-1.945-.186 5.812 5.812 0 0 1-1.572-.506q-.986-.453-.985-1.226a1.471 1.471 0 0 1 .053-.346 1.527 1.527 0 0 1 .453-.693 1.025 1.025 0 0 1 .666-.239 1.014 1.014 0 0 1 .4.08q.826.373 1.465.586a4.736 4.736 0 0 0 1.492.213q4.128 0 4.129-4.236v-.533a4.859 4.859 0 0 1-1.852 1.492 6.034 6.034 0 0 1-2.6.533 4.346 4.346 0 0 1-3.449-1.412 5.408 5.408 0 0 1-1.239-3.7V6.418a1.273 1.273 0 0 1 .346-.919 1.2 1.2 0 0 1 .906-.36 1.224 1.224 0 0 1 .919.373 1.259 1.259 0 0 1 .359.905v7.3a3.738 3.738 0 0 0 .772 2.6 3.035 3.035 0 0 0 2.344.839 3.443 3.443 0 0 0 2.5-.958 3.3 3.3 0 0 0 .986-2.478v-7.3a1.269 1.269 0 0 1 .354-.92 1.2 1.2 0 0 1 .905-.36 1.221 1.221 0 0 1 .919.373"
          transform="translate(-4.194 -.532)"
          style={{ fill: '#fff' }}
        />
        <path
          data-name="Path 9142"
          d="M35.812 19.216a1.247 1.247 0 0 0 .626-2.326 5.429 5.429 0 0 1 0-9.4 1.247 1.247 0 0 0-1.248-2.161 7.923 7.923 0 0 0 0 13.719 1.239 1.239 0 0 0 .623.168"
          transform="translate(-3.232 -.534)"
          style={{ fill: '#fff' }}
        />
        <path
          data-name="Path 9143"
          d="M5.62 9.936 7.6 8.05v-.005l.879-.831 1.011-.966v.005l4.41-4.175a1.28 1.28 0 0 0 .373-.826A1.285 1.285 0 0 0 13.931.4a1.058 1.058 0 0 0-.826-.4 1.532 1.532 0 0 0-1.012.453L2.637 9.616V1.332a1.245 1.245 0 0 0-.36-.919 1.276 1.276 0 0 0-.946-.36 1.294 1.294 0 0 0-.958.374A1.23 1.23 0 0 0 0 1.332v16.063a1.289 1.289 0 0 0 .36.932 1.254 1.254 0 0 0 .946.372 1.3 1.3 0 0 0 .958-.372 1.267 1.267 0 0 0 .373-.932v-.528A9.546 9.546 0 0 1 5.62 9.936z"
          style={{ fill: '#fff' }}
        />
        <path
          data-name="Path 9144"
          d="m14.948 17.637-5.463-7.056-1.978 1.88 5.363 6.908a1.187 1.187 0 0 0 .932.48 1.5 1.5 0 0 0 1-.373 1.2 1.2 0 0 0 .439-.959 1.381 1.381 0 0 0-.293-.879"
          transform="translate(-.777 -1.095)"
          style={{ fill: '#fff' }}
        />
        <path
          data-name="Path 9145"
          d="M21.472 6.406a1.265 1.265 0 1 1-1.265-1.265 1.265 1.265 0 0 1 1.265 1.265"
          transform="translate(-1.96 -.532)"
          style={{ fill: '#1fe3ac' }}
        />
        <path
          data-name="Path 9146"
          d="M25.91 19.216a1.247 1.247 0 0 1-.626-2.326 5.429 5.429 0 0 0 0-9.4 1.247 1.247 0 0 1 1.249-2.158 7.923 7.923 0 0 1 0 13.719 1.239 1.239 0 0 1-.623.168"
          transform="translate(-2.552 -.534)"
          style={{ fill: '#1fe3ac' }}
        />
        <path
          data-name="Path 9147"
          d="M20.246 13.6a1.265 1.265 0 0 0-1.265 1.265l-.04 3.978a1.265 1.265 0 1 0 2.531 0l.039-3.978a1.265 1.265 0 0 0-1.265-1.265"
          transform="translate(-1.96 -1.407)"
          style={{ fill: '#1fe3ac' }}
        />
      </g>
    </g>
  </svg>
);
