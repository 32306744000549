import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(theme => ({
  modalContainer: {
    backgroundColor: 'white',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '480px',
    height: '318px',
    borderRadius: '15px',
    zIndex: 5,
    fontFamily: theme.typography.default,
    color: theme.palette.icons.nav,
    padding: '21px',
    fontSize: '14px',
  },
  modalTitleContainer: {
    textAlign: 'center',
    fontSize: '20px',
    fontFamily: theme.typography.default,
    fontWeight: 'bold',
    marginBottom: '1.5em',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    gap: '16px',
    paddingTop: '24px',
    '& > div': {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      gap: '8px',
    },
  },
  sortButton: {
    backgroundColor: theme.palette.icons.nav,
    borderRadius: '8px',
    width: 'auto',
    height: '40px',
    border: 'none',
    color: 'white',
    cursor: 'pointer',
  },
}));
