import React from 'react';

export const ExportIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
    <path
      data-name="Union 468"
      d="M0 12a12 12 0 1 1 12 12A12.014 12.014 0 0 1 0 12zm1.46 0A10.54 10.54 0 1 0 12 1.461 10.551 10.551 0 0 0 1.46 12zm9.834 5.032-.024-7.388a.52.52 0 0 0-.33-.484.523.523 0 0 0-.571.126l-1.738 1.837a.73.73 0 0 1-1.282-.423.785.785 0 0 1 .24-.6l3.88-4.1a.737.737 0 0 1 1.051-.02l4.095 4.1a.724.724 0 0 1 .213.516.714.714 0 0 1-.213.518.737.737 0 0 1-1.038 0l-1.932-1.931a.521.521 0 0 0-.891.372c.013 2.061.01 2.872.006 4.1 0 .8-.006 1.771-.006 3.379a.73.73 0 1 1-1.459 0z"
      style={{ fill: '#1fe3ac' }}
    />
  </svg>
);
