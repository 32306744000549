import { FormControlLabel } from '@mui/material';
import React, { useEffect } from 'react';

import { useStyles } from './form-boolean-field.hooks';
import { IOSSwitch } from './ios-switch';

export const FormBooleanField = ({ register, field, rules, setValue, watch }) => {
  const classes = useStyles();
  const { label, name } = field;

  useEffect(() => {
    register(name, rules);
  }, []);
  const checked = watch(name, false) ?? false;

  return (
    <FormControlLabel
      control={
        <IOSSwitch
          color="primary"
          checked={checked}
          onChange={() => setValue(name, !checked)}
          name={name}
        />
      }
      label={label || name}
      labelPlacement="start"
      classes={classes}
    />
  );
};
