import { MuiButton } from './MuiButton';
import { MuiIconButton } from './MuiIconButton';
import { MuiPaper } from './MuiPaper';
import { MuiTableCell } from './MuiTableCell';
import { MuiTableHead } from './MuiTableHead';
import { MuiTableRow } from './MuiTableRow';
import { MuiTypography } from './MuiTypography';

export const overrides = {
  MuiButton,
  MuiIconButton,
  MuiPaper,
  MuiTableCell,
  MuiTableHead,
  MuiTypography,
  MuiTableRow,
};
