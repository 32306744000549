import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(theme => ({
  root: {
    border: 'none',
  },
  formControl: {
    border: 'none',
    width: '100%',
    backgroundColor: theme.palette.background.default,
    '&:focus': {
      border: 'none',
    },
    '& .MuiInputLabel-animated': {
      color: theme.palette.text.secondary,
      transform: 'translate(1rem, -0.5rem)',
      fontWeight: 'bold',
    },
    '& .MuiInputLabel-shrink': {
      transform: 'translate(1rem, -0.5rem)',
    },
    '& .MuiFormLabel-root.Mui-focused': {
      color: theme.palette.text.secondary,
    },
    '& .MuiFilledInput-root.MuiFilledInput-underline:before': {
      display: 'none',
    },
    '& .MuiFilledInput-root.MuiFilledInput-underline:after': {
      display: 'none',
    },
  },
  icon: {
    cursor: 'pointer',
    backgroundColor: theme.palette.background.default,
    color: theme.palette.greyMid.dark,
    top: '1.7rem',
    position: 'absolute',
    right: '0.5rem',
    width: '2.7rem',
    height: '3rem',
    pointerEvents: 'none',
  },
}));
