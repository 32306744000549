import React from 'react';

export const DottedLineIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="433" height="1" viewBox="0 0 433 1">
    <path
      data-name="Path 19577"
      d="M-1010.933 1802v432"
      transform="rotate(90 611.533 1622.967)"
      style={{ fill: 'none', stroke: '#a5a6ae', strokeLinecap: 'round', strokeDasharray: '1 4' }}
    />
  </svg>
);
