import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import React, { useEffect, useState } from 'react';
import { useStyles } from './internationalPayment.hook';
import InputBase from '@mui/material/InputBase';
import { IntPaymentButton } from './IntPaymentButton';
import { useGraphList } from '../../../../hooks';
import { entitiesEnum } from '../../../../constants';
import { ReturnCountryIcon } from '../../../icons/countries/ReturnCountryIcon';
import { graphqlApiDecorator } from '../../../../decorators';
import { mutations, queries } from '../../../../graphql';
import { makeHVIPayment } from '../../../../graphql/mutations';
import {Snackbar, TextField} from '@mui/material';
import { Alert } from '@mui/lab';
import { SuccessModal } from '../../setup/success-modal';
import { ModalBodyContent } from '../../setup/modal-body-content';
import { ConfirmationModal } from '../../setup/confirmation-modal';
import { FailModal } from '../../setup/fail-modal';
import { SelectArrowDownIcon } from '../../../icons';
import {numberWithCommas} from "../../../../utils";
import {SelectWithSearch} from "./selectWithSearch";

const sortByName = (a, b) => {
  const textA = a.name.toLowerCase();
  const textB = b.name.toLowerCase();

  return textA.localeCompare(textB);
};
export const InternationalPayments = () => {
  const [amount, setAmount] = useState('');
  const [amountCurrency, setAmountCuurency] = useState('GBP');
  const [reference, setReference] = useState('');
  const [benName, setBenName] = useState('');
  const [benAddress, setBenAddress] = useState('');
  const [benCity, setBenCity] = useState('');
  const [benPostCode, setBenPostCode] = useState('');
  const [benCountry, setBenCountry] = useState('');
  const [swift, setSwift] = useState('');
  const [iban, setIban] = useState('');
  const [accountNumber, setAccountNumber] = useState('');
  const [pureCountries, setPureCountries] = useState([]);
  const [countries, setCountries] = useState([]);
  const [financialInstitutionCountry, setFinancialInstitutionCountry] = useState([]);
  const [error, setError] = useState('');
  const [state, setState] = useState('');
  const [balance, setBalance] = useState('');
  const [requirements, setRequirement] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [serverError, setServerError] = useState();
  const classes = useStyles();
  const { data: currencies } = useGraphList({
    entity: entitiesEnum.CURRENCY,
  });
  const fetchAvailableCountries = async () => {
    try {
      const queryTitle = 'listCountrys';
      const limit = 250;
      const countryList = await graphqlApiDecorator(queries, queryTitle, { limit });
      if (countryList) {
        const { items } = countryList;
        items.sort(sortByName);
        setCountries(items);
        setPureCountries(items);
      }
    } catch (err) {
      setError(err);
    }
  };

  const fetchCurrencyRequirement=async ()=> {
    try {
      const data = await graphqlApiDecorator(queries, 'listCurrencyRequirements', {filter: {currency: amountCurrency}});
      if (data){
        if (data?.items?.[0]?.requirements){
          const list= JSON.parse(data?.items?.[0]?.requirements)
          const result = list.reduce((obj, item) => {
            if (item.status === 'mandatory' || item.status === 'visible')
              obj[item.field] = item;

            return obj;
          }, {})
          setRequirement(result)
        }

      }
    }catch (e) {
      console.log(e)
      setError(e)
    }

  }

  const fetchBalances= async ()=> {
    const data = await graphqlApiDecorator(queries, 'listMCCYBalances', { limit: 250 });
    const list= data ?? [];
    const result=list.filter(item=>{
      if (item['currency'] === amountCurrency){
        setBalance(Intl.NumberFormat('en-IN').format(item['available']))
      }
      return item['currency']=== amountCurrency
    })
    if (result.length ===0){
      setBalance('0')
    }
  }

  useEffect(() => {
    fetchCurrencyRequirement();
    fetchAvailableCountries();
  }, []);

  useEffect(()=>{
    fetchCurrencyRequirement();
    fetchBalances()

  },[amountCurrency])



  const makePayment = async () => {
    setIsLoading(true)
    console.log(amount.replaceAll(',',''))
    const res = await graphqlApiDecorator(mutations, 'makeHVIPayment', {
      input: {
        currency: amountCurrency,
        amount: parseFloat(amount.replaceAll(',','')),
        reference,
        beneficiaryName: benName,
        beneficiaryAddress: benAddress,
        beneficiaryCity: benCity,
        beneficiaryPostcode: benPostCode,
        beneficiaryCountry: benCountry,
        beneficiarySwift: swift,
        beneficiaryIBAN: iban,
        beneficiaryCountryCode: benCountry,
        beneficiaryAccountNumber: accountNumber,
      },
    }).finally(res=>{
      setIsLoading(false)
    });

    if (res) {
      if (res.error){
        setState('fail')
        setServerError(res.error)
      }else{
        setState('success')
      }

      setError(res.errors?.[0]);
    }
  };

  let input = {
    'Currency Amount': amount + ' ' + amountCurrency,
    Beneficiary: benName,
    ...requirements['bic_swift'] && {Swift: swift},
    ...requirements['iban'] && {'IBAN/Account Number': iban || accountNumber},
    Reference: reference,
  };
  const modals = {
    confirmation: (
      <ConfirmationModal
        open={true}
        onClose={() => setState('')}
        title={'FX Trade'}
        isDisabled={isLoading}
        extraFntionality={makePayment}
      >
        <ModalBodyContent obj={input} />
      </ConfirmationModal>
    ),
    success: (
      <SuccessModal
        open={true}
        onClose={() => setState('')}
        title={'FX Trade'}
        extraFntionality={() => setState('')}
      >
        <ModalBodyContent obj={input} />
      </SuccessModal>
    ),
    fail: (
      <FailModal
        open={true}
        onClose={() => setState('')}
        title={'FX Trade'}
        extraFntionality={makePayment}
        errorMessage={serverError}
        isDisabled={isLoading}
      >
        <ModalBodyContent obj={input} />
      </FailModal>
    ),
  }[state];

  const handleChange = (e) => {
    const inputValue = e.target.value;
    // Remove all commas from the input string
    const cleanedValue = inputValue.replaceAll(',', '');
    const values=cleanedValue.split('.')
    // Check if the cleaned value is a valid float number
    if (!isNaN(values?.[0]) && cleanedValue.trim() !== '') {
      // Convert the cleaned value to a float
      // Convert the float back to a formatted string with thousand separators
      let formattedNumber
      if (values.length>1)
        formattedNumber = numberWithCommas(values[0])+"."+values[1];
      else
        formattedNumber=numberWithCommas(values[0])

      setAmount(formattedNumber);
    }
    else {
      setAmount(inputValue);
    }
  };
  return (
    <div className={classes.sectionContainer}>
      <section className={classes.fxTradesSection}>
        <div className={classes.fxTradesInputSection}>
          <div className={classes.inputItem}>
            <p style={{ marginBottom: '10px' }}>Currency Amount</p>
            <div className={classes.comboInputSelectContainer}>
              <InputBase
                onChange={handleChange}
                value={amount}
                inputProps={{"maxLength": 20}}
                className={classes.inputNumber}
              />
              <Select
                id="CURRRENCY_AMOUNT"
                value={amountCurrency}
                IconComponent={SelectArrowDownIcon}
                className={classes.selectCombo}
                sx={{
                  borderWidth: '0',
                  width: '100%',
                  borderRadius: '8px',
                  textAlign: 'right',
                }}
                onChange={e => setAmountCuurency(e.target.value)}
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: 400,
                    },
                  },
                }}
              >
                {Object.values(currencies).map(currency => (
                  <MenuItem className={classes.selectOption} value={currency.iso}>
                    <ReturnCountryIcon country={currency?.iso} />
                    <p className={classes.abbrCurrency}>{currency?.iso}</p>
                    <p className='currencyName'>{currency.name}</p>
                  </MenuItem>
                ))}
              </Select>
            </div>
            {balance ? <div className={classes.feedback}>available {balance}</div>: <div className={classes.feedback}>...loading balance</div>}
          </div>
          <div className={classes.inputItem}>
            <p style={{ marginBottom: '10px' }}>Referrence</p>
            <InputBase
              onChange={e => setReference(e.target.value)}
              value={reference}
              className={classes.inputBase}
              id="demo-customized-textbox"
            />
          </div>
          <h3 className='BeneficiaryContent'>Beneficiary:</h3>
          <div className={classes.IntPaymentContainer}>
            <div className={classes.inputItem}>
              <p style={{ marginBottom: '10px' }}>Name</p>
              <InputBase
                onChange={e => setBenName(e.target.value)}
                value={benName}
                className={classes.inputBase}
              />
            </div>
            <div className={classes.inputItem}>
              <p style={{ marginBottom: '10px' }}>Address</p>
              <InputBase
                onChange={e => setBenAddress(e.target.value)}
                value={benAddress}
                className={classes.inputBase}
              />
            </div>
            <div className={classes.inputItem}>
              <p style={{ marginBottom: '10px' }}>City</p>
              <InputBase
                onChange={e => setBenCity(e.target.value)}
                value={benCity}
                className={classes.inputBase}
              />
            </div>
            <div className={classes.inputItem}>
              <p style={{ marginBottom: '10px' }}>Post Code</p>
              <InputBase
                onChange={e => setBenPostCode(e.target.value)}
                value={benPostCode}
                className={classes.inputBase}
              />
            </div>
            <SelectWithSearch Icon={SelectArrowDownIcon} title={"Country"} options={countries} value={benCountry}
                              onChange={(e)=>{setBenCountry(e.target.value);setCountries(pureCountries)}}/>
            {requirements['bic_swift'] &&
                <div className={classes.inputItem}>
                  <p style={{marginBottom: '10px'}}>Swift</p>

                  <InputBase
                      onChange={e => setSwift(e.target.value)}
                      value={swift}
                      className={classes.inputBase}
                  />
                </div>}
            {requirements['iban'] && <div className={classes.inputItem}>
              <p style={{ marginBottom: '10px' }}>IBAN</p>
              <InputBase
                onChange={e => setIban(e.target.value)}
                value={iban}
                className={classes.inputBase}
              />
            </div>}
            <div className={classes.inputItem}>
              <p style={{ marginBottom: '10px' }}>Account Number</p>
              <InputBase
                onChange={e => setAccountNumber(e.target.value)}
                value={accountNumber}
                className={classes.inputBase}
              />
            </div>
            <SelectWithSearch Icon={SelectArrowDownIcon} options={countries} value={financialInstitutionCountry} onChange={(e)=>{setFinancialInstitutionCountry(e.target.value)}} title={"Financial Institution Country"}/>
          </div>
          <div>
            <IntPaymentButton onClick={() => setState('confirmation')} />
          </div>
        </div>
      </section>
      <Snackbar open={!!error} autoHideDuration={6000} onClose={() => setError('')}>
        <Alert onClose={() => setError('')} severity="error" sx={{ width: '100%' }}>
          {error?.message}
        </Alert>
      </Snackbar>
      {modals}
    </div>
  );
};
