const KycStatuses = {
  UNSTARTED: 'unstarted',
  PENDING: 'pending',
  COMPLETED: 'completed',
};

const KycResults = {
  GREEN: 'GREEN',
  RED: 'RED',
};

const KycResultStates = {
  PASSED: 'Passed',
  FAILED: 'Failed',
};

export { KycResults, KycResultStates, KycStatuses };
