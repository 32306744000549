import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import React, { useEffect, useState } from 'react';

import { useGraphList } from '../../../../hooks';
import { toStringOrEmptyString } from '../../../../utils';
import { useStyles } from './filter-select-entity.hooks';

export const FilterSelectEntity = ({ field, filters, setFilters }) => {
  const {
    label,
    filter,
    filter: { schemaField },
  } = field;
  const ALL_OPTIONS = { value: 'all', label };

  const classes = useStyles();

  const value = filters[schemaField] || 'all';
  const [selectList, setSelectList] = useState([]);

  const { data } = useGraphList({ entity: filter.entity, getAll: true, sorted: true });

  useEffect(() => {
    if (!data) return;
    const newSelectList = data.map(el => ({ value: el[filter.value], label: el[filter.label] }));
    setSelectList([ALL_OPTIONS, ...newSelectList]);
  }, [data]);

  const onChange = e => {
    const newFilters = { ...filters, [schemaField]: e.target.value };
    setFilters(newFilters);
  };

  return (
    <TextField
      className={classes.root}
      id="name"
      select
      value={toStringOrEmptyString(value)}
      onChange={onChange}
      InputProps={{ className: classes.label, disableUnderline: true }}
    >
      {selectList.map(option => (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      ))}
    </TextField>
  );
};
