import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { styled } from '@mui/styles';
import React from 'react';
import { Controller } from 'react-hook-form';
import { uid } from 'react-uid';

import { useStyles } from './form-enum-select-field.hooks';

const StyledSelect = styled(Select)(({ theme }) => ({
  'label + &': {
    border: 'none',
  },
  backgroundColor: theme.palette.background.default,
  '& .MuiSelect-select.MuiSelect-select': {
    backgroundColor: theme.palette.background.default,
  },
}));

export const FormEnumSelectField = ({ errors, field, type = 'text', control, rules }) => {
  const { label, name, options } = field;
  const labelId = `label-select-${name}`;
  const classes = useStyles();
  const Icon = () => <KeyboardArrowDownIcon className={classes.icon} />;

  return (
    <FormControl className={classes.formControl}>
      <InputLabel id={labelId}>{label}</InputLabel>
      <Controller
        render={({ field, formState }) => {
          if (!field.value) {
            field.value = '';
          }

          return (
            <StyledSelect
              IconComponent={Icon}
              variant="filled"
              {...field}
              {...formState}
              label={label || name}
              fullWidth
              error={!!errors}
            >
              {options.map(option => {
                // TODO: delete the condition below in https://jira.itransition.com/browse/KPAY-269
                const activeStatuses = ['active', 'suspended'];
                const isMenuItemHidden =
                  name === 'accountStatus' && !activeStatuses.includes(option.value);

                return (
                  <MenuItem key={uid(option)} value={option.value} disabled={isMenuItemHidden}>
                    {option.label}
                  </MenuItem>
                );
              })}
            </StyledSelect>
          );
        }}
        control={control}
        defaultValue=""
        labelId={labelId}
        name={name}
        rules={rules}
        type={type}
      />
    </FormControl>
  );
};
