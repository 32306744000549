import React from 'react';

import LoaderImage from '../../assets/images/loader.gif';
import { useStyles } from './loader.hooks';

export const Loader = ({ width = '40rem' }) => {
  const classes = useStyles();

  return <img alt="loader" src={LoaderImage} style={{ width }} className={classes.loader} />;
};
