import React from 'react';

export const SendButton = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
    <g data-name="Group 8260">
      <rect
        data-name="Rectangle 17188"
        width="32"
        height="32"
        rx="16"
        style={{ fill: '#c6ffef' }}
      />
      <path
        data-name="Path 19464"
        d="M16 .616a.658.658 0 0 0-.034-.175c-.01-.019-.017-.041-.026-.059a.653.653 0 0 0-.128-.193.659.659 0 0 0-.193-.128.637.637 0 0 0-.061-.023.606.606 0 0 0-.172-.033.514.514 0 0 0-.052-.005.665.665 0 0 0-.2.034L.438 5.177a.656.656 0 0 0-.05 1.217L6.77 9.23l2.837 6.383a.657.657 0 0 0 .6.388h.026a.656.656 0 0 0 .592-.438L15.967.869A.647.647 0 0 0 16 .666a.476.476 0 0 0 0-.05m-3.14 1.6L7.122 7.953 2.429 5.868zm-2.727 11.356L8.048 8.879l5.735-5.736z"
        transform="translate(7 9)"
        style={{ fill: '#19365e' }}
      />
    </g>
  </svg>
);
