import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(theme => ({
  root: {
    boxShadow: 'none',
  },
  content: {
    width: '100%',
  },
  icon: {
    color: theme.palette.greyMid.dark,
  },
  bullet: {
    width: '1.2rem',
    height: '1.2rem',
    borderRadius: '100%',
    backgroundColor: theme.palette.greyMid.main,
  },
  bulletOn: {
    backgroundColor: theme.palette.primary.main,
  },
  preferredList: {
    display: 'flex',
    width: '11rem',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  tableRoot: {
    borderSpacing: '0 1rem',
    borderCollapse: 'separate',
  },
  tableHeadRow: {
    border: 'none',
  },
  tableHeadCell: {
    maxWidth: '9rem',
    backgroundColor: theme.palette.white,
    border: 'none',
    color: theme.palette.text.secondary,
  },
  tableCell: {
    borderTop: `0.1rem solid ${theme.palette.greyMid.main}`,
    borderBottom: `0.1rem solid ${theme.palette.greyMid.main}`,
    color: theme.palette.text.secondary,
    fontSize: '1.6rem',
    padding: '1rem',

    '&:first-child': {
      borderTopLeftRadius: '0.4rem',
      borderBottomLeftRadius: '0.4rem',
      borderLeft: `0.1rem solid ${theme.palette.greyMid.main}`,
    },
    '&:last-child': {
      borderTopRightRadius: '0.4rem',
      borderBottomRightRadius: '0.4rem',
      borderRight: `0.1rem solid ${theme.palette.greyMid.main}`,
      textAlign: 'end',
    },
  },
  tableRow: {
    backgroundColor: theme.palette.background.default,
    '&:hover': {
      backgroundColor: theme.palette.background.hoverDefault,
    },
    minHeight: '6.2rem',
  },
  notificationTextarea: {
    '& .MuiFormControl-root': {
      marginTop: 0,
    },
  },
}));
