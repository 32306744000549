import SearchIcon from '@mui/icons-material/Search';
import InputBase from '@mui/material/InputBase';
import React from 'react';

import { toStringOrEmptyString } from '../../../../utils';
import { useStyles } from './filter-string.hooks';

export const FilterString = ({ field, filters, setFilters }) => {
  const {
    label,
    filter: { schemaField },
  } = field;
  const classes = useStyles();
  const value = filters[schemaField] || '';

  const onChange = e => {
    const newFilters = { ...filters, [schemaField]: e.target.value };
    setFilters(newFilters);
  };

  return (
    <div className={classes.search}>
      <div className={classes.searchIcon}>
        <SearchIcon />
      </div>
      <InputBase
        placeholder={label}
        classes={{
          root: classes.inputRoot,
          input: classes.inputInput,
        }}
        inputProps={{ 'aria-label': 'search' }}
        value={toStringOrEmptyString(value)}
        onChange={onChange}
      />
    </div>
  );
};
