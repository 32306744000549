import React from 'react';

import { EditFeesIcon } from '../../../icons/pairs';
import { useStyles } from './button.hooks';

export const EditButton = ({ edit }) => {
  const classes = useStyles();

  return (
    <button
      className={`${classes.defaultBtnStyle} ${classes.btnEdit}`}
      type="button"
      onClick={edit}
    >
      <EditFeesIcon edit />
      Edit
    </button>
  );
};
