import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(theme => ({
  kycContainer: {
    display: 'flex',
  },
  flag: {
    width: '3.2rem',
    height: '2rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '0.3rem',
    color: theme.palette.white,
    fontWeight: 'bold',
    fontSize: '1.2rem',
    margin: '0 0.5rem',
  },
  default: {
    backgroundColor: theme.palette.greyMid.dark,
  },
  pending: {
    backgroundColor: theme.palette.warning.main,
  },
  rejected: {
    backgroundColor: theme.palette.error.light,
  },
  accepted: {
    backgroundColor: theme.palette.primary.main,
  },
  active: {
    backgroundColor: theme.palette.primary.main,
  },
}));
