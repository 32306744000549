import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
  button: {
    display: 'flex',
    '&:hover': {
      cursor: 'pointer',
    },
  },
}));
