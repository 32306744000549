/* eslint-disable no-restricted-syntax */
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { entitiesEnum } from '../../../../constants';
import { saveEntity } from '../../../../services';
import { checkOverlap } from '../../../../utils';
import { SmallSideRectangle } from '../../../icons';
import { PlusIcon, TemplateIcon } from '../../../icons/pairs';
import { AllTemplates } from './allTemplates';
import { useStyles } from './fees.hooks';
import { FeesInputs } from './fees-inputs';
import { FeesTable } from './feesTable';
import { SavedModal, WarningModal } from './modal';

/**
 * Sub Component of SetupPage for pairs. This displays the fees for the selected pair and
 * the user is able to edit the conversion fees for the pair at certain intervals.
 * @param {Array} data array of pairs data to render of type Pair
 * @param {function} handleRefreshData this eventually calles the fetchEntity() in the useGraphList() custom hook
 */
export const Fees = ({ data, handleRefreshData }) => {
  const [isNewFee, setIsNewFee] = useState(true);
  const [startValue, setStartValue] = useState(0);
  const [endValue, setEndValue] = useState(0);
  const [percentage, setPercentage] = useState(0);
  const [absValue, setAbsValue] = useState(0);
  const [templateName, setTemplateName] = useState('');
  const [showSavedMsg, setShowSavedMsg] = useState(false);
  const [indexToOverwrite, setIndexToOverwrite] = useState(null);
  const [feesData, setFeesData] = useState([]);
  const [feeId, setFeeId] = useState(null);
  const [showSavedModal, setShowSavedModal] = useState(false);
  const [showWarningModal, setShowWarningModal] = useState(false);
  const [warningMessage, setWarningMessage] = useState('');
  const [isActive, setIsActive] = useState(false);
  const currenciesDataObj = useSelector(state => state.pairsCurrenciesObj);

  const classes = useStyles();

  const TEMPLATES = 'feesTemplates';

  useEffect(() => {
    let oneSendingCurrencySelected = false;
    let oneReceivingCurrencySelected = false;

    if (currenciesDataObj['Sending Currency'] && currenciesDataObj['Sending Currency'].length === 1)
      oneSendingCurrencySelected = true;

    if (
      currenciesDataObj['Receiving Currency'] &&
      currenciesDataObj['Receiving Currency'].length === 1
    )
      oneReceivingCurrencySelected = true;

    if (!currenciesDataObj['Sending Currency'] || !currenciesDataObj['Sending Currency']) {
      setIsActive(false);
    }
    if (
      oneSendingCurrencySelected &&
      oneReceivingCurrencySelected &&
      currenciesDataObj['Sending Currency'][0] === currenciesDataObj['Receiving Currency'][0]
    ) {
      setIsActive(false);
    }

    if (
      oneSendingCurrencySelected &&
      oneReceivingCurrencySelected &&
      data &&
      currenciesDataObj['Sending Currency'][0] !== currenciesDataObj['Receiving Currency'][0]
    ) {
      const { rates, id, status } = data.filter(pair => {
        if (
          pair.from?.iso === currenciesDataObj['Sending Currency'][0] &&
          pair.to?.iso === currenciesDataObj['Receiving Currency'][0]
        ) {
          return true;
        }

        return false;
      })[0];

      if (status && status === 'Active') {
        setIsActive(true);
      } else {
        setIsActive(false);
      }

      setFeesData(rates);
      setFeeId(id);
    } else {
      setFeesData([]);
      setFeeId(null);
    }
  }, [data, currenciesDataObj]);

  const editTemplateFee = (fee, index) => {
    // When user clicks on edit icon, the FeesInputs will be populated with the values
    // to allow user to edit and save
    // Show Fees Inputs

    setStartValue(fee.intervalIni);
    setEndValue(fee.intervalEnd);
    setPercentage(fee.percentageFee ? fee.percentageFee : 0);
    setAbsValue(fee.fee ? fee.fee : 0);

    setIndexToOverwrite(index);
  };

  // The user can save a template and this is stored in the localStorage browser for use
  // later to apply to other pairs
  const saveTemplate = () => {
    if (!templateName) {
      setWarningMessage('Please enter a template name');
      setShowWarningModal(true);
    }

    if (startValue === '' || endValue === '') {
      setWarningMessage('Please enter both start and end values');
      setShowWarningModal(true);
    }

    const currentTemplates = window.localStorage.getItem(TEMPLATES) || '{}';
    if (
      templateName &&
      startValue !== '' &&
      endValue !== '' &&
      percentage !== '' &&
      absValue !== ''
    ) {
      window.localStorage.setItem(
        TEMPLATES,
        JSON.stringify({
          ...JSON.parse(currentTemplates),
          [templateName]: {
            startValue,
            endValue,
            percentage,
            absValue,
          },
        })
      );
      setShowSavedMsg(true);
    }
  };

  const saveInputs = async () => {
    const sendingCurrencies = currenciesDataObj['Sending Currency'];
    const receivingCurrencies = currenciesDataObj['Receiving Currency'];

    if (!sendingCurrencies || !receivingCurrencies) {
      setWarningMessage('Please select both sending and receiving currencies');
      setShowWarningModal(true);

      return;
    }

    if (
      sendingCurrencies.length === 1 &&
      receivingCurrencies.length === 1 &&
      sendingCurrencies[0] === receivingCurrencies[0]
    ) {
      setWarningMessage('Sending and Receiving currencies are the same');
      setShowWarningModal(true);

      return;
    }

    let newRates = [];
    const saveObj = {
      intervalIni: startValue,
      intervalEnd: endValue,
      fee: absValue,
      percentageFee: percentage,
    };

    if (startValue === '' || endValue === '') {
      setWarningMessage('You must specify start and end values');
      setShowWarningModal(true);

      return;
    }

    if (endValue < startValue) {
      setWarningMessage('End value cannot be less than start value');
      setShowWarningModal(true);

      return;
    }

    if (percentage === '' || absValue === '') {
      setWarningMessage('Please enter both percentage and absolute values');
      setShowWarningModal(true);

      return;
    }

    if (
      (feeId === null && !currenciesDataObj['Sending Currency']) ||
      !currenciesDataObj['Receiving Currency']
    ) {
      setWarningMessage('Please choose Sending and Receiving Currencies');
      setShowWarningModal(true);

      return;
    }

    if (indexToOverwrite === null) {
      // No need to overwrite an existing rate but check if overlapping intervals
      if (feesData.length > 0) {
        for (const interval of feesData) {
          const overlap = checkOverlap(interval, saveObj);
          if (overlap) {
            setWarningMessage('The intervals must not overlap');
            setShowWarningModal(true);

            return;
          }
        }
      }

      newRates = [...feesData, saveObj];
    }

    if (indexToOverwrite !== null) {
      newRates = [...feesData];
      newRates[indexToOverwrite] = saveObj;
    }

    // If there is only one pair to update:
    if (feeId) {
      try {
        await saveEntity({
          entity: entitiesEnum.PAIR,
          id: feeId,
          hasOrder: false,
          values: { id: feeId, rates: newRates },
        });
        setShowSavedModal(true);
      } catch (err) {
        setWarningMessage(err);
        setShowWarningModal(true);
      }
    }

    // For updating multiple currencies, the feesData == [], so need to check overlap of intervals
    // before updating:
    if (feesData.length === 0 && !feeId)
      try {
        const sendingCurrencies = currenciesDataObj['Sending Currency'];
        const receivingCurrencies = currenciesDataObj['Receiving Currency'];

        for (const sendingCurrency of sendingCurrencies) {
          for (const receivingCurrency of receivingCurrencies) {
            if (sendingCurrency !== receivingCurrency) {
              const singlePair = data.filter(pair => {
                if (pair.from?.iso === sendingCurrency && pair.to?.iso === receivingCurrency)
                  return true;

                return false;
              })[0];

              if (singlePair.rates) {
                for (const interval of singlePair.rates) {
                  const overlap = checkOverlap(interval, saveObj);
                  if (overlap) {
                    setWarningMessage('The intervals must not overlap');
                    setShowWarningModal(true);

                    return;
                  }
                }
              }

              newRates = [...saveObj];
              await saveEntity({
                entity: entitiesEnum.PAIR,
                id: feeId,
                hasOrder: false,
                values: { id: feeId, rates: newRates },
              });
            }
          }
        }
        setShowSavedModal(true);
      } catch (err) {
        setWarningMessage(err);
        setShowWarningModal(true);
      }
  };

  const cancel = () => {
    setStartValue(0);
    setEndValue(0);
    setPercentage(0);
    setAbsValue(0);
    setIndexToOverwrite(null);
    setTemplateName('');
    const templateName = document.getElementById('template_name');
    templateName.value = '';
  };

  const closeSavedModal = () => {
    setShowSavedModal(false);
    handleRefreshData();
  };

  useEffect(() => {
    // saved msg will disappear after 5 secs
    const timeout = setTimeout(() => {
      setShowSavedMsg(false);
    }, 5000);

    return () => clearTimeout(timeout);
  }, [showSavedMsg]);

  return (
    <section className={classes.sectionContainer}>
      <header className={classes.headerContainer}>
        <SmallSideRectangle />
        <p>Conversion Fees</p>
      </header>
      <aside>
        <div
          className={
            isActive
              ? classes.subAsideContainer
              : `${classes.subAsideContainer} ${classes.disabledColor}`
          }
          onClick={() => {
            if (isActive) setIsNewFee(false);
          }}
        >
          <TemplateIcon active={isActive} />
          <p>All Templates</p>
          {!isNewFee && (
            <div
              className={
                isActive
                  ? classes.templatesUnderline
                  : `${classes.templatesUnderline} ${classes.underLineDisabled}`
              }
            />
          )}
        </div>
        <div
          className={
            isActive
              ? classes.subAsideContainer
              : `${classes.subAsideContainer} ${classes.disabledColor}`
          }
          onClick={() => {
            if (isActive) setIsNewFee(true);
          }}
        >
          <PlusIcon active={isActive} />
          <p>New Fee</p>
          {isNewFee && (
            <div
              className={
                isActive
                  ? classes.feesUnderLine
                  : `${classes.feesUnderLine} ${classes.underLineDisabled}`
              }
            />
          )}
        </div>
      </aside>
      <div className={classes.horizRule} />
      <main>
        {isNewFee && ( // New Fee Section
          <>
            <FeesInputs
              setStartValue={setStartValue}
              setEndValue={setEndValue}
              setPercentage={setPercentage}
              setAbsValue={setAbsValue}
              startValue={startValue}
              endValue={endValue}
              percentage={percentage}
              absValue={absValue}
              mode="newFee"
              saveInputs={saveInputs}
              cancel={cancel}
              isActive={isActive}
            />
            <div className={classes.saveAsTemplate}>
              <div
                className={
                  isActive
                    ? classes.saveTemplateContent
                    : `${classes.saveTemplateContent} ${classes.disabledTemplate}`
                }
              >
                <TemplateIcon active={isActive} />
                <input
                  id="template_name"
                  type="text"
                  placeholder="Template Name"
                  disabled={!isActive}
                  onChange={event => setTemplateName(event.target.value)}
                />
                <button
                  type="button"
                  className={!isActive ? classes.disabledText : undefined}
                  onClick={saveTemplate}
                  disabled={!isActive}
                >
                  Save as Template
                </button>
              </div>
            </div>
            {showSavedMsg && (
              <div className={classes.templateSavedMsg}>
                <p>{`"${templateName}" template saved`}</p>
              </div>
            )}
            <FeesTable
              editTemplateFee={editTemplateFee}
              mode="newFee"
              feesData={feesData}
              feeId={feeId}
              handleRefreshData={handleRefreshData}
              isActive={isActive}
            />
          </>
        )}

        {!isNewFee && (
          <AllTemplates
            feesData={feesData}
            pairsData={data}
            setWarningMessage={setWarningMessage}
            setShowWarningModal={setShowWarningModal}
            setShowSavedModal={setShowSavedModal}
            isActive={isActive}
          />
        )}
        <SavedModal open={showSavedModal} onClose={closeSavedModal} />
        <WarningModal
          open={showWarningModal}
          onClose={() => setShowWarningModal(false)}
          message={warningMessage}
        />
      </main>
    </section>
  );
};
