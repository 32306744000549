import API, { graphqlOperation } from '@aws-amplify/api';

const graphqlApiDecorator = async (queryType, queryTitle, options) => {
  try {
    const { data } = await API.graphql(graphqlOperation(queryType[queryTitle], options));

    return data[queryTitle];
  } catch (args) {
    // TODO: remove console.log and throw a custom error after resolving errors provided in https://jira.itransition.com/browse/KPAY-47
    // eslint-disable-next-line no-console
    const { data, errors } = args;

    console.log(`Error in the query: ${queryTitle}`, args);

    if (data && data[queryTitle]) {
      return data[queryTitle];
    }

    return { errors };
  }
};

export { graphqlApiDecorator };
