import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.default,
    paddingTop: '6.4rem',
    height: '100%',
  },
  content: {
    height: '100%',
  },
}));
