import React from 'react';

export const LinkedInIcon = ({ width = '24px', height = '24px' }) => (
  <svg
    data-name="Group 10181"
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 24 24"
  >
    <circle data-name="Ellipse 1857" cx="12" cy="12" r="12" style={{ fill: '#19365e' }} />
    <path
      data-name="Union 921"
      d="M8.626 10.95a.4.4 0 0 1-.4-.4V7.614a.667.667 0 1 0-1.335 0v2.935a.4.4 0 0 1-.4.4H4.355a.4.4 0 0 1-.4-.4V4.142a.4.4 0 0 1 .4-.4H6.49a.4.4 0 0 1 .4.4v.022a2.78 2.78 0 0 1 1.47-.422 2.813 2.813 0 0 1 2.8 2.8v4a.4.4 0 0 1-.4.4zm.4-3.335v2.535h1.335v-3.6A1.995 1.995 0 0 0 6.806 5.3a.4.4 0 0 1-.715-.249v-.509H4.756v5.608h1.335V7.614a1.469 1.469 0 1 1 2.938 0zM.51 10.95a.4.4 0 0 1-.4-.4V4.142a.4.4 0 0 1 .4-.4h2.136a.4.4 0 0 1 .4.4v6.408a.4.4 0 0 1-.4.4zm.4-.8h1.335V4.542H.91zm.652-6.962h-.1A1.536 1.536 0 0 1 0 1.592v-.1A1.524 1.524 0 0 1 1.593.005a.977.977 0 0 1 .178 0 1.565 1.565 0 0 1 1.388 1.7A1.541 1.541 0 0 1 1.667 3.19c-.033 0-.067 0-.104-.002zm0-.8h.025a.677.677 0 0 0 .147 0 .726.726 0 0 0 .505-.288.827.827 0 0 0 .168-.581A.766.766 0 0 0 1.6.8a.791.791 0 0 0-.146 0 .776.776 0 0 0-.693.841.759.759 0 0 0 .789.74z"
      transform="translate(6.695 6.699)"
      style={{ fill: '#1fe3ac' }}
    />
  </svg>
);
