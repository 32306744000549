import React from 'react';

export const CAD = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="45" height="45" viewBox="0 0 45 45">
    <g data-name="Group 6261">
      <path
        data-name="Path 9432"
        d="M2758.5 3693a22.5 22.5 0 1 0-22.5-22.5 22.5 22.5 0 0 0 22.5 22.5z"
        transform="translate(-2736 -3648)"
        style={{ fill: '#f0f0f0' }}
      />
      <path
        data-name="Path 9433"
        d="M3115.128 3693.823a22.9 22.9 0 0 0-11.828-20.423v40.847a22.9 22.9 0 0 0 11.828-20.424z"
        transform="translate(-3070.128 -3671.323)"
        style={{ fill: '#d80027' }}
      />
      <path
        data-name="Path 9434"
        d="M2736 3693.823a22.9 22.9 0 0 0 11.828 20.423V3673.4a22.9 22.9 0 0 0-11.828 20.423z"
        transform="translate(-2736 -3671.323)"
        style={{ fill: '#d80027' }}
      />
      <path
        data-name="Path 9435"
        d="m2913.877 3835.188 3.639-1.82-1.82-.91v-1.82l-3.639 1.82 1.82-3.639h-1.82l-1.82-2.729-1.82 2.729h-1.82l1.82 3.639-3.639-1.82v1.82l-1.82.91 3.639 1.82-.91 1.82h3.639v2.729h1.819v-2.729h3.639z"
        transform="translate(-2887.739 -3809.907)"
        style={{ fill: '#d80027' }}
      />
    </g>
  </svg>
);
