import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import React from 'react';

import { SavedIcon } from '../../icons/pairs';
import { useStyles } from './modal.hooks';

export const SuccessModal = ({ open, onClose, title, extraFntionality, children }) => {
  const classes = useStyles();

  return (
    <Modal open={open} onClose={onClose}>
      <Box>
        <div className={classes.modal}>
          <div className={classes.innerModal}>
            <div className={classes.topOfModal}>
              <div className={classes.iconPlaceholder}>
                <SavedIcon />
              </div>
            </div>
            <header>{title}</header>
            <div className={classes.modalBody}>{children}</div>
            <section>
              <p className={classes.successText}>Your Transaction was Successful!</p>
              <div className={classes.modalFooter}>
                <button className={classes.modalButton} onClick={extraFntionality}>
                  Repeat it
                </button>
                <button className={classes.modalButton} onClick={onClose}>
                  Ok
                </button>
              </div>
            </section>
          </div>
        </div>
      </Box>
    </Modal>
  );
};
