import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(theme => ({
  root: {
    '& .MuiPaper-root.MuiPaper-elevation': {
      maxWidth: 'unset',
      width: '50rem',
    },
    '& .MuiSvgIcon-root': {
      width: '2.4rem',
      height: '2.4rem',
      fill: theme.palette.primary.nav,
    },
  },
  content: {
    padding: '0 3rem 3rem',
  },
}));
