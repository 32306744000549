import React from 'react';

export const SmallAttachmentIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="13.993" height="16" viewBox="0 0 13.993 16">
    <path
      data-name="Path 22428"
      d="M296.6 413.933a.731.731 0 0 0-.582.292l-4.3 4.3-.013.013a3.578 3.578 0 1 1-5.062-5.06l.014-.013 6.412-6.41a2.159 2.159 0 0 1 3.053 3.053l-6.107 6.105A.719.719 0 1 1 289 415.2l4.953-4.953a.732.732 0 1 0-.926-1.108l-5.044 5.043a2.159 2.159 0 1 0 3.053 3.053l6.106-6.106a3.6 3.6 0 0 0-5.088-5.088l-6.411 6.411-.014.013a5.018 5.018 0 0 0 7.1 7.1l.013-.014 4.26-4.259a.733.733 0 0 0-.391-1.354z"
      transform="translate(-284.193 -404.993)"
      style={{ fill: '#19365e' }}
    />
  </svg>
);
