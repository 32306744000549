import {ClickAwayListener} from '@mui/base/ClickAwayListener';
import { makeStyles } from '@mui/styles';
import React, { useState } from 'react';

import { graphqlApiDecorator } from '../../../decorators';
import { mutations } from '../../../graphql';
import { GreenEdit, RedDeleteIcon, ThreeDots } from '../../icons';
import { WarningModal } from '../../modal/saved-warning-modal';
import { EditMemberModal } from './edit-new-member-modal';

export const SmallMenu = ({ data, handleUserDeleted, handleUserUpdated }) => {
  const [open, setOpen] = React.useState(false);
  const [openEditMemberModal, setOpenEditMemberModal] = useState(false);
  const [openErrorEditMemberModal, setOpenErrorEditMemberModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openNotDeletedModal, setOpenNotDeletedModal] = useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  const handleClickAway = () => {
    setOpen(false);
  };

  const handleDelete = async () => {
    setOpenDeleteModal(false);
    const result = await graphqlApiDecorator(mutations, 'removeUser', { userName: data.userName });

    if (!result.error) {
      handleUserDeleted(data);
    } else {
      setOpenNotDeletedModal(true);
    }
  };

  const handleEdit = async modifiedData => {
    setOpenEditMemberModal(false);
    try {
      const result = await graphqlApiDecorator(mutations, 'modifyUser', { input: modifiedData });
      if (!result.errors) {
        handleUserUpdated(modifiedData);
      } else {
        setOpenErrorEditMemberModal(true);
      }
    } catch (error) {
      setOpenErrorEditMemberModal(true);
    }
  };

  const createStyles = makeStyles(() => ({
    threeDots: {
      padding: '5px 20px 0px 20px',
      display: 'block',
    },
    innerMenu: {
      position: 'absolute',
      backgroundColor: '#FFFFFF',
      padding: '5px',
      borderRadius: '16px',
      marginLeft: '-105px',
      marginTop: '-40px',
      boxShadow: '0 0 6px 0 rgba(0, 0, 0, 0.08)',
      display: 'flex',
      flexDirection: 'column',
    },
    row: {
      display: 'flex',
      flexDirection: 'row',
      gap: '10px',
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: '#E9FFF9',
      },
      padding: '10px',
      borderRadius: '10px',
    },
    clickAway: {
      position: 'relative',
    },
  }));
  const styles = createStyles();

  return (
    <>
      <ClickAwayListener onClickAway={handleClickAway}>
        <div className={styles.clickAway}>
          <div className={styles.threeDots} onClick={handleClick}>
            <ThreeDots />
          </div>
          {open ? (
            <div className={styles.innerMenu}>
              <div className={styles.row} onClick={() => setOpenEditMemberModal(true)}>
                <GreenEdit />
                Edit
              </div>
              {/* <div className={styles.row}>
                <GreenDeactivate />
                Deactive
              </div> */}
              {/* <div className={styles.row}>
                <GreenReset />
                Reset Password
              </div> */}
              <div className={styles.row} onClick={() => setOpenDeleteModal(true)}>
                <RedDeleteIcon />
                Delete
              </div>
            </div>
          ) : null}
        </div>
      </ClickAwayListener>
      {openDeleteModal ? (
        <WarningModal
          onClose={() => setOpenDeleteModal(false)}
          confirm={handleDelete}
          message="Are you sure you want to delete the user?"
        />
      ) : null}
      {openNotDeletedModal ? (
        <WarningModal
          confirm={() => setOpenNotDeletedModal(false)}
          message="User was not deleted"
        />
      ) : null}
      {openEditMemberModal ? (
        <EditMemberModal
          data={data}
          onSubmit={handleEdit}
          onClose={() => setOpenEditMemberModal(false)}
        />
      ) : null}
      {openErrorEditMemberModal ? (
        <WarningModal
          confirm={() => setOpenErrorEditMemberModal(false)}
          message="User update failed"
        />
      ) : null}
    </>
  );
};
