import React from 'react';

export const NZD = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48">
    <g data-name="Group 8513">
      <path
        data-name="Path 9531"
        d="M5539 4584a24 24 0 1 1-48 0c0 .005 24-24 24-24a24 24 0 0 1 24 24z"
        transform="translate(-5491 -4559.999)"
        style={{ fill: '#0052b4' }}
      />
      <path
        data-name="Path 9532"
        d="M5746.32 4815.371h.06v-.062z"
        transform="translate(-5722.382 -4791.375)"
        style={{ fill: '#f0f0f0' }}
      />
      <path
        data-name="Path 9533"
        d="M5515.368 4572.819v-12.609a24.292 24.292 0 0 0-24.368 24.358h12.715v-7.218l7.159 7.218h4.43l.064-.065v-4.466l-7.159-7.218z"
        transform="translate(-5491 -4560.201)"
        style={{ fill: '#f0f0f0' }}
      />
      <path
        data-name="Path 9534"
        d="M5532.282 4593.269a26.571 26.571 0 0 0-7.892 8.663v10.184h5.927v-12.787h13.831v-6.059z"
        transform="translate(-5521.427 -4589.351)"
        style={{ fill: '#d80027' }}
      />
      <path
        data-name="Path 9535"
        d="m5635.428 4701.643-8.073-8.072h-2.794l10.867 10.866z"
        transform="translate(-5611.83 -4680.842)"
        style={{ fill: '#d80027' }}
      />
      <path
        data-name="Path 9536"
        d="m5817.76 4816 .42 1.294h1.36l-1.1.8.419 1.293-1.1-.8-1.1.8.421-1.293-1.1-.8h1.361z"
        transform="translate(-5785.375 -4791.9)"
        style={{ fill: '#f0f0f0' }}
      />
      <path
        data-name="Path 9537"
        d="m5769.545 4838.545 1.212.618.961-.962-.211 1.344 1.211.618-1.344.212-.212 1.344-.618-1.211-1.343.212.962-.961z"
        transform="translate(-5743.002 -4812.005)"
        style={{ fill: '#f0f0f0' }}
      />
      <path
        data-name="Path 9538"
        d="m5747 4886.761 1.293-.42v-1.36l.8 1.1 1.294-.422-.8 1.1.8 1.1-1.294-.419-.8 1.1v-1.361z"
        transform="translate(-5722.896 -4854.378)"
        style={{ fill: '#f0f0f0' }}
      />
      <path
        data-name="Path 9539"
        d="m5769.545 4935.433.617-1.212-.962-.962 1.343.213.618-1.212.212 1.344 1.344.212-1.211.618.211 1.344-.961-.962z"
        transform="translate(-5743.002 -4897.206)"
        style={{ fill: '#f0f0f0' }}
      />
      <path
        data-name="Path 9540"
        d="m5817.76 4959.317-.419-1.294h-1.361l1.1-.8-.421-1.293 1.1.8 1.1-.8-.419 1.293 1.1.8h-1.36z"
        transform="translate(-5785.375 -4918.654)"
        style={{ fill: '#f0f0f0' }}
      />
      <path
        data-name="Path 9541"
        d="m5866.424 4935.433-1.212-.617-.962.962.213-1.344-1.212-.618 1.345-.212.213-1.344.617 1.212 1.344-.213-.961.962z"
        transform="translate(-5828.195 -4897.206)"
        style={{ fill: '#f0f0f0' }}
      />
      <path
        data-name="Path 9542"
        d="m5890.316 4886.761-1.294.42v1.361l-.8-1.1-1.293.419.8-1.1-.8-1.1 1.293.422.8-1.1v1.36z"
        transform="translate(-5849.651 -4854.378)"
        style={{ fill: '#f0f0f0' }}
      />
      <path
        data-name="Path 9543"
        d="m5866.424 4838.545-.617 1.213.961.961-1.344-.212-.617 1.211-.213-1.344-1.345-.212 1.212-.618-.213-1.344.962.962z"
        transform="translate(-5828.195 -4812.005)"
        style={{ fill: '#f0f0f0' }}
      />
    </g>
  </svg>
);
