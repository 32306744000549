/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const setUserRisk = /* GraphQL */ `
  mutation SetUserRisk($input: SetUserRiskInput!) {
    setUserRisk(input: $input) {
      success
      data
      errorCode
    }
  }
`;
export const setUserLimitWaiver = /* GraphQL */ `
  mutation SetUserLimitWaiver($userId: ID!, $waiveUntil: AWSDateTime) {
    setUserLimitWaiver(userId: $userId, waiveUntil: $waiveUntil) {
      success
      data
      errorCode
    }
  }
`;
export const setUserLimits = /* GraphQL */ `
  mutation SetUserLimits($userId: ID, $userLimitsInput: UserLimitsInput) {
    setUserLimits(userId: $userId, userLimitsInput: $userLimitsInput) {
      success
      data
      errorCode
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser($input: UpdateUserProfileInput!) {
    updateUser(input: $input) {
      id
      accountType
      membership {
        id
        name
        price
        dailyLimit
        monthlyLimit
        createdAt
        updatedAt
      }
      name
      lastName
      birthdate
      country
      address
      city
      postalCode
      email
      emailVerified
      phoneCode
      phoneNumber
      phoneNumberVerified
      kyc {
        status
        result
        caseId
        applicantId
        reason
      }
      beneficiaries {
        id
        status
        userId
        accountType
        name
        lastName
        country {
          id
          iso
          iso2
          region {
            id
            name
            createdAt
            updatedAt
          }
          defaultCurrency {
            id
            iso
            active
            name
            walletBgColor
            walletTextColor
            order
            createdAt
            updatedAt
          }
          currency {
            id
            iso
            active
            name
            walletBgColor
            walletTextColor
            order
            createdAt
            updatedAt
          }
          active
          name
          phoneCode
          order
          companyRequirements
          personalRequirements
          createdAt
          updatedAt
        }
        address
        city
        postalCode
        email
        phoneCode
        phoneNumber
        currency {
          id
          iso
          active
          name
          walletBgColor
          walletTextColor
          order
          createdAt
          updatedAt
        }
        bankDetails {
          name
          value
        }
        userProfileId
        usageLevel
        skipInternalCheck
        currencyRequirementType
        beneficiaryCountryId
        beneficiaryCurrencyId
      }
      externalBankAccounts {
        name
        number
        iban
        swift
        sort
      }
      poundsAccount {
        id
        name
        label
        type
        currency
        iban
        bban
        balance
        lastTx
      }
      invitedBy {
        id
        accountType
        membership {
          id
          name
          price
          dailyLimit
          monthlyLimit
          createdAt
          updatedAt
        }
        name
        lastName
        birthdate
        country
        address
        city
        postalCode
        email
        emailVerified
        phoneCode
        phoneNumber
        phoneNumberVerified
        kyc {
          status
          result
          caseId
          applicantId
          reason
        }
        beneficiaries {
          id
          status
          userId
          accountType
          name
          lastName
          country {
            id
            iso
            iso2
            active
            name
            phoneCode
            order
            companyRequirements
            personalRequirements
            createdAt
            updatedAt
          }
          address
          city
          postalCode
          email
          phoneCode
          phoneNumber
          currency {
            id
            iso
            active
            name
            walletBgColor
            walletTextColor
            order
            createdAt
            updatedAt
          }
          bankDetails {
            name
            value
          }
          userProfileId
          usageLevel
          skipInternalCheck
          currencyRequirementType
          beneficiaryCountryId
          beneficiaryCurrencyId
        }
        externalBankAccounts {
          name
          number
          iban
          swift
          sort
        }
        poundsAccount {
          id
          name
          label
          type
          currency
          iban
          bban
          balance
          lastTx
        }
        invitedBy {
          id
          accountType
          membership {
            id
            name
            price
            dailyLimit
            monthlyLimit
            createdAt
            updatedAt
          }
          name
          lastName
          birthdate
          country
          address
          city
          postalCode
          email
          emailVerified
          phoneCode
          phoneNumber
          phoneNumberVerified
          kyc {
            status
            result
            caseId
            applicantId
            reason
          }
          beneficiaries {
            id
            status
            userId
            accountType
            name
            lastName
            address
            city
            postalCode
            email
            phoneCode
            phoneNumber
            userProfileId
            usageLevel
            skipInternalCheck
            currencyRequirementType
            beneficiaryCountryId
            beneficiaryCurrencyId
          }
          externalBankAccounts {
            name
            number
            iban
            swift
            sort
          }
          poundsAccount {
            id
            name
            label
            type
            currency
            iban
            bban
            balance
            lastTx
          }
          invitedBy {
            id
            accountType
            name
            lastName
            birthdate
            country
            address
            city
            postalCode
            email
            emailVerified
            phoneCode
            phoneNumber
            phoneNumberVerified
            readNotifications
            readOnboardings
            profileImage
            smsMarketingConsent
            pushMarketingConsent
            emailMarketingConsent
            smsAllowed
            pushAllowed
            emailAllowed
            createdAt
            updatedAt
            intercomProfileUrl
            accountStatus
            type
            passwordResetAt
            passwordResetBy
            customerId
            businessDetail
            shareholders
            SignUpAddressPersonalDetails
            SignUpFinancialDetails
            SignUpIndividualPersonalDetails
            transactionsInfo
            businessType
            author
          }
          readNotifications
          readOnboardings
          amountsSent {
            daily
            monthly
          }
          profileImage
          paymentMethods {
            last4
            scheme
            provider
            customerId
            expiryMonth
            id
            expiryYear
            type
          }
          companyInfo {
            tradingName
            primaryHolderName
            industryType
            registeredAddress
            businessDescription
            annualTurnover
            stablishmentDate
            tradingStartDate
            transactionVolumen
            internationalActivity
            kybCompanyCode
          }
          pushNotificationsConfig {
            token
            endpointArn
          }
          smsMarketingConsent
          pushMarketingConsent
          emailMarketingConsent
          smsAllowed
          pushAllowed
          emailAllowed
          forbiddenCurrency {
            id
            iso
            active
            name
            walletBgColor
            walletTextColor
            order
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          intercomProfileUrl
          accountStatus
          type
          passwordResetAt
          passwordResetBy
          customerId
          businessDetail
          shareholders
          SignUpAddressPersonalDetails
          SignUpFinancialDetails
          SignUpIndividualPersonalDetails
          transactionsInfo
          businessType
          author
        }
        readNotifications
        readOnboardings
        amountsSent {
          daily
          monthly
        }
        profileImage
        paymentMethods {
          last4
          scheme
          provider
          customerId
          expiryMonth
          id
          expiryYear
          type
        }
        companyInfo {
          tradingName
          primaryHolderName
          industryType
          registeredAddress
          businessDescription
          annualTurnover
          stablishmentDate
          tradingStartDate
          transactionVolumen
          shareholders {
            name
            lastName
            birthdate
            country
            address
            city
            postalCode
            email
            phoneCode
            phoneNumber
          }
          internationalActivity
          kybCompanyCode
        }
        pushNotificationsConfig {
          token
          endpointArn
        }
        smsMarketingConsent
        pushMarketingConsent
        emailMarketingConsent
        smsAllowed
        pushAllowed
        emailAllowed
        forbiddenCurrency {
          id
          iso
          active
          name
          walletBgColor
          walletTextColor
          order
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        intercomProfileUrl
        accountStatus
        type
        passwordResetAt
        passwordResetBy
        customerId
        businessDetail
        shareholders
        SignUpAddressPersonalDetails
        SignUpFinancialDetails
        SignUpIndividualPersonalDetails
        transactionsInfo
        businessType
        author
      }
      readNotifications
      readOnboardings
      amountsSent {
        daily
        monthly
      }
      profileImage
      paymentMethods {
        last4
        scheme
        provider
        customerId
        expiryMonth
        id
        expiryYear
        type
      }
      companyInfo {
        tradingName
        primaryHolderName
        industryType
        registeredAddress
        businessDescription
        annualTurnover
        stablishmentDate
        tradingStartDate
        transactionVolumen
        shareholders {
          name
          lastName
          birthdate
          country
          address
          city
          postalCode
          email
          phoneCode
          phoneNumber
        }
        internationalActivity
        kybCompanyCode
      }
      pushNotificationsConfig {
        token
        endpointArn
      }
      smsMarketingConsent
      pushMarketingConsent
      emailMarketingConsent
      smsAllowed
      pushAllowed
      emailAllowed
      forbiddenCurrency {
        id
        iso
        active
        name
        walletBgColor
        walletTextColor
        order
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      intercomProfileUrl
      accountStatus
      type
      passwordResetAt
      passwordResetBy
      customerId
      businessDetail
      shareholders
      SignUpAddressPersonalDetails
      SignUpFinancialDetails
      SignUpIndividualPersonalDetails
      transactionsInfo
      businessType
      author
    }
  }
`;
export const completeUserProfile = /* GraphQL */ `
  mutation CompleteUserProfile($input: CompleteUserProfileInput!) {
    completeUserProfile(input: $input) {
      id
      accountType
      membership {
        id
        name
        price
        dailyLimit
        monthlyLimit
        createdAt
        updatedAt
      }
      name
      lastName
      birthdate
      country
      address
      city
      postalCode
      email
      emailVerified
      phoneCode
      phoneNumber
      phoneNumberVerified
      kyc {
        status
        result
        caseId
        applicantId
        reason
      }
      beneficiaries {
        id
        status
        userId
        accountType
        name
        lastName
        country {
          id
          iso
          iso2
          region {
            id
            name
            createdAt
            updatedAt
          }
          defaultCurrency {
            id
            iso
            active
            name
            walletBgColor
            walletTextColor
            order
            createdAt
            updatedAt
          }
          currency {
            id
            iso
            active
            name
            walletBgColor
            walletTextColor
            order
            createdAt
            updatedAt
          }
          active
          name
          phoneCode
          order
          companyRequirements
          personalRequirements
          createdAt
          updatedAt
        }
        address
        city
        postalCode
        email
        phoneCode
        phoneNumber
        currency {
          id
          iso
          active
          name
          walletBgColor
          walletTextColor
          order
          createdAt
          updatedAt
        }
        bankDetails {
          name
          value
        }
        userProfileId
        usageLevel
        skipInternalCheck
        currencyRequirementType
        beneficiaryCountryId
        beneficiaryCurrencyId
      }
      externalBankAccounts {
        name
        number
        iban
        swift
        sort
      }
      poundsAccount {
        id
        name
        label
        type
        currency
        iban
        bban
        balance
        lastTx
      }
      invitedBy {
        id
        accountType
        membership {
          id
          name
          price
          dailyLimit
          monthlyLimit
          createdAt
          updatedAt
        }
        name
        lastName
        birthdate
        country
        address
        city
        postalCode
        email
        emailVerified
        phoneCode
        phoneNumber
        phoneNumberVerified
        kyc {
          status
          result
          caseId
          applicantId
          reason
        }
        beneficiaries {
          id
          status
          userId
          accountType
          name
          lastName
          country {
            id
            iso
            iso2
            active
            name
            phoneCode
            order
            companyRequirements
            personalRequirements
            createdAt
            updatedAt
          }
          address
          city
          postalCode
          email
          phoneCode
          phoneNumber
          currency {
            id
            iso
            active
            name
            walletBgColor
            walletTextColor
            order
            createdAt
            updatedAt
          }
          bankDetails {
            name
            value
          }
          userProfileId
          usageLevel
          skipInternalCheck
          currencyRequirementType
          beneficiaryCountryId
          beneficiaryCurrencyId
        }
        externalBankAccounts {
          name
          number
          iban
          swift
          sort
        }
        poundsAccount {
          id
          name
          label
          type
          currency
          iban
          bban
          balance
          lastTx
        }
        invitedBy {
          id
          accountType
          membership {
            id
            name
            price
            dailyLimit
            monthlyLimit
            createdAt
            updatedAt
          }
          name
          lastName
          birthdate
          country
          address
          city
          postalCode
          email
          emailVerified
          phoneCode
          phoneNumber
          phoneNumberVerified
          kyc {
            status
            result
            caseId
            applicantId
            reason
          }
          beneficiaries {
            id
            status
            userId
            accountType
            name
            lastName
            address
            city
            postalCode
            email
            phoneCode
            phoneNumber
            userProfileId
            usageLevel
            skipInternalCheck
            currencyRequirementType
            beneficiaryCountryId
            beneficiaryCurrencyId
          }
          externalBankAccounts {
            name
            number
            iban
            swift
            sort
          }
          poundsAccount {
            id
            name
            label
            type
            currency
            iban
            bban
            balance
            lastTx
          }
          invitedBy {
            id
            accountType
            name
            lastName
            birthdate
            country
            address
            city
            postalCode
            email
            emailVerified
            phoneCode
            phoneNumber
            phoneNumberVerified
            readNotifications
            readOnboardings
            profileImage
            smsMarketingConsent
            pushMarketingConsent
            emailMarketingConsent
            smsAllowed
            pushAllowed
            emailAllowed
            createdAt
            updatedAt
            intercomProfileUrl
            accountStatus
            type
            passwordResetAt
            passwordResetBy
            customerId
            businessDetail
            shareholders
            SignUpAddressPersonalDetails
            SignUpFinancialDetails
            SignUpIndividualPersonalDetails
            transactionsInfo
            businessType
            author
          }
          readNotifications
          readOnboardings
          amountsSent {
            daily
            monthly
          }
          profileImage
          paymentMethods {
            last4
            scheme
            provider
            customerId
            expiryMonth
            id
            expiryYear
            type
          }
          companyInfo {
            tradingName
            primaryHolderName
            industryType
            registeredAddress
            businessDescription
            annualTurnover
            stablishmentDate
            tradingStartDate
            transactionVolumen
            internationalActivity
            kybCompanyCode
          }
          pushNotificationsConfig {
            token
            endpointArn
          }
          smsMarketingConsent
          pushMarketingConsent
          emailMarketingConsent
          smsAllowed
          pushAllowed
          emailAllowed
          forbiddenCurrency {
            id
            iso
            active
            name
            walletBgColor
            walletTextColor
            order
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          intercomProfileUrl
          accountStatus
          type
          passwordResetAt
          passwordResetBy
          customerId
          businessDetail
          shareholders
          SignUpAddressPersonalDetails
          SignUpFinancialDetails
          SignUpIndividualPersonalDetails
          transactionsInfo
          businessType
          author
        }
        readNotifications
        readOnboardings
        amountsSent {
          daily
          monthly
        }
        profileImage
        paymentMethods {
          last4
          scheme
          provider
          customerId
          expiryMonth
          id
          expiryYear
          type
        }
        companyInfo {
          tradingName
          primaryHolderName
          industryType
          registeredAddress
          businessDescription
          annualTurnover
          stablishmentDate
          tradingStartDate
          transactionVolumen
          shareholders {
            name
            lastName
            birthdate
            country
            address
            city
            postalCode
            email
            phoneCode
            phoneNumber
          }
          internationalActivity
          kybCompanyCode
        }
        pushNotificationsConfig {
          token
          endpointArn
        }
        smsMarketingConsent
        pushMarketingConsent
        emailMarketingConsent
        smsAllowed
        pushAllowed
        emailAllowed
        forbiddenCurrency {
          id
          iso
          active
          name
          walletBgColor
          walletTextColor
          order
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        intercomProfileUrl
        accountStatus
        type
        passwordResetAt
        passwordResetBy
        customerId
        businessDetail
        shareholders
        SignUpAddressPersonalDetails
        SignUpFinancialDetails
        SignUpIndividualPersonalDetails
        transactionsInfo
        businessType
        author
      }
      readNotifications
      readOnboardings
      amountsSent {
        daily
        monthly
      }
      profileImage
      paymentMethods {
        last4
        scheme
        provider
        customerId
        expiryMonth
        id
        expiryYear
        type
      }
      companyInfo {
        tradingName
        primaryHolderName
        industryType
        registeredAddress
        businessDescription
        annualTurnover
        stablishmentDate
        tradingStartDate
        transactionVolumen
        shareholders {
          name
          lastName
          birthdate
          country
          address
          city
          postalCode
          email
          phoneCode
          phoneNumber
        }
        internationalActivity
        kybCompanyCode
      }
      pushNotificationsConfig {
        token
        endpointArn
      }
      smsMarketingConsent
      pushMarketingConsent
      emailMarketingConsent
      smsAllowed
      pushAllowed
      emailAllowed
      forbiddenCurrency {
        id
        iso
        active
        name
        walletBgColor
        walletTextColor
        order
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      intercomProfileUrl
      accountStatus
      type
      passwordResetAt
      passwordResetBy
      customerId
      businessDetail
      shareholders
      SignUpAddressPersonalDetails
      SignUpFinancialDetails
      SignUpIndividualPersonalDetails
      transactionsInfo
      businessType
      author
    }
  }
`;
export const updateUserBeneficiary = /* GraphQL */ `
  mutation UpdateUserBeneficiary($input: BeneficiaryInput) {
    updateUserBeneficiary(input: $input) {
      id
      status
      userId
      accountType
      name
      lastName
      country {
        id
        iso
        iso2
        region {
          id
          name
          createdAt
          updatedAt
        }
        defaultCurrency {
          id
          iso
          active
          name
          walletBgColor
          walletTextColor
          order
          createdAt
          updatedAt
        }
        currency {
          id
          iso
          active
          name
          walletBgColor
          walletTextColor
          order
          createdAt
          updatedAt
        }
        active
        name
        phoneCode
        order
        companyRequirements
        personalRequirements
        createdAt
        updatedAt
      }
      address
      city
      postalCode
      email
      phoneCode
      phoneNumber
      currency {
        id
        iso
        active
        name
        walletBgColor
        walletTextColor
        order
        createdAt
        updatedAt
      }
      bankDetails {
        name
        value
      }
      userProfileId
      usageLevel
      skipInternalCheck
      currencyRequirementType
      beneficiaryCountryId
      beneficiaryCurrencyId
    }
  }
`;
export const removeUserBeneficiary = /* GraphQL */ `
  mutation RemoveUserBeneficiary($input: DeleteBeneficiaryInput) {
    removeUserBeneficiary(input: $input) {
      id
      status
      userId
      accountType
      name
      lastName
      country {
        id
        iso
        iso2
        region {
          id
          name
          createdAt
          updatedAt
        }
        defaultCurrency {
          id
          iso
          active
          name
          walletBgColor
          walletTextColor
          order
          createdAt
          updatedAt
        }
        currency {
          id
          iso
          active
          name
          walletBgColor
          walletTextColor
          order
          createdAt
          updatedAt
        }
        active
        name
        phoneCode
        order
        companyRequirements
        personalRequirements
        createdAt
        updatedAt
      }
      address
      city
      postalCode
      email
      phoneCode
      phoneNumber
      currency {
        id
        iso
        active
        name
        walletBgColor
        walletTextColor
        order
        createdAt
        updatedAt
      }
      bankDetails {
        name
        value
      }
      userProfileId
      usageLevel
      skipInternalCheck
      currencyRequirementType
      beneficiaryCountryId
      beneficiaryCurrencyId
    }
  }
`;
export const initTransaction = /* GraphQL */ `
  mutation InitTransaction($input: TransactionInput) {
    initTransaction(input: $input) {
      id
      type
      userIdFrom
      userIdTo
      userFrom {
        id
        accountType
        membership {
          id
          name
          price
          dailyLimit
          monthlyLimit
          createdAt
          updatedAt
        }
        name
        lastName
        birthdate
        country
        address
        city
        postalCode
        email
        emailVerified
        phoneCode
        phoneNumber
        phoneNumberVerified
        kyc {
          status
          result
          caseId
          applicantId
          reason
        }
        beneficiaries {
          id
          status
          userId
          accountType
          name
          lastName
          country {
            id
            iso
            iso2
            active
            name
            phoneCode
            order
            companyRequirements
            personalRequirements
            createdAt
            updatedAt
          }
          address
          city
          postalCode
          email
          phoneCode
          phoneNumber
          currency {
            id
            iso
            active
            name
            walletBgColor
            walletTextColor
            order
            createdAt
            updatedAt
          }
          bankDetails {
            name
            value
          }
          userProfileId
          usageLevel
          skipInternalCheck
          currencyRequirementType
          beneficiaryCountryId
          beneficiaryCurrencyId
        }
        externalBankAccounts {
          name
          number
          iban
          swift
          sort
        }
        poundsAccount {
          id
          name
          label
          type
          currency
          iban
          bban
          balance
          lastTx
        }
        invitedBy {
          id
          accountType
          membership {
            id
            name
            price
            dailyLimit
            monthlyLimit
            createdAt
            updatedAt
          }
          name
          lastName
          birthdate
          country
          address
          city
          postalCode
          email
          emailVerified
          phoneCode
          phoneNumber
          phoneNumberVerified
          kyc {
            status
            result
            caseId
            applicantId
            reason
          }
          beneficiaries {
            id
            status
            userId
            accountType
            name
            lastName
            address
            city
            postalCode
            email
            phoneCode
            phoneNumber
            userProfileId
            usageLevel
            skipInternalCheck
            currencyRequirementType
            beneficiaryCountryId
            beneficiaryCurrencyId
          }
          externalBankAccounts {
            name
            number
            iban
            swift
            sort
          }
          poundsAccount {
            id
            name
            label
            type
            currency
            iban
            bban
            balance
            lastTx
          }
          invitedBy {
            id
            accountType
            name
            lastName
            birthdate
            country
            address
            city
            postalCode
            email
            emailVerified
            phoneCode
            phoneNumber
            phoneNumberVerified
            readNotifications
            readOnboardings
            profileImage
            smsMarketingConsent
            pushMarketingConsent
            emailMarketingConsent
            smsAllowed
            pushAllowed
            emailAllowed
            createdAt
            updatedAt
            intercomProfileUrl
            accountStatus
            type
            passwordResetAt
            passwordResetBy
            customerId
            businessDetail
            shareholders
            SignUpAddressPersonalDetails
            SignUpFinancialDetails
            SignUpIndividualPersonalDetails
            transactionsInfo
            businessType
            author
          }
          readNotifications
          readOnboardings
          amountsSent {
            daily
            monthly
          }
          profileImage
          paymentMethods {
            last4
            scheme
            provider
            customerId
            expiryMonth
            id
            expiryYear
            type
          }
          companyInfo {
            tradingName
            primaryHolderName
            industryType
            registeredAddress
            businessDescription
            annualTurnover
            stablishmentDate
            tradingStartDate
            transactionVolumen
            internationalActivity
            kybCompanyCode
          }
          pushNotificationsConfig {
            token
            endpointArn
          }
          smsMarketingConsent
          pushMarketingConsent
          emailMarketingConsent
          smsAllowed
          pushAllowed
          emailAllowed
          forbiddenCurrency {
            id
            iso
            active
            name
            walletBgColor
            walletTextColor
            order
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          intercomProfileUrl
          accountStatus
          type
          passwordResetAt
          passwordResetBy
          customerId
          businessDetail
          shareholders
          SignUpAddressPersonalDetails
          SignUpFinancialDetails
          SignUpIndividualPersonalDetails
          transactionsInfo
          businessType
          author
        }
        readNotifications
        readOnboardings
        amountsSent {
          daily
          monthly
        }
        profileImage
        paymentMethods {
          last4
          scheme
          provider
          customerId
          expiryMonth
          id
          expiryYear
          type
        }
        companyInfo {
          tradingName
          primaryHolderName
          industryType
          registeredAddress
          businessDescription
          annualTurnover
          stablishmentDate
          tradingStartDate
          transactionVolumen
          shareholders {
            name
            lastName
            birthdate
            country
            address
            city
            postalCode
            email
            phoneCode
            phoneNumber
          }
          internationalActivity
          kybCompanyCode
        }
        pushNotificationsConfig {
          token
          endpointArn
        }
        smsMarketingConsent
        pushMarketingConsent
        emailMarketingConsent
        smsAllowed
        pushAllowed
        emailAllowed
        forbiddenCurrency {
          id
          iso
          active
          name
          walletBgColor
          walletTextColor
          order
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        intercomProfileUrl
        accountStatus
        type
        passwordResetAt
        passwordResetBy
        customerId
        businessDetail
        shareholders
        SignUpAddressPersonalDetails
        SignUpFinancialDetails
        SignUpIndividualPersonalDetails
        transactionsInfo
        businessType
        author
      }
      externalUserFrom {
        name
        lastName
        iban
      }
      userTo {
        id
        accountType
        membership {
          id
          name
          price
          dailyLimit
          monthlyLimit
          createdAt
          updatedAt
        }
        name
        lastName
        birthdate
        country
        address
        city
        postalCode
        email
        emailVerified
        phoneCode
        phoneNumber
        phoneNumberVerified
        kyc {
          status
          result
          caseId
          applicantId
          reason
        }
        beneficiaries {
          id
          status
          userId
          accountType
          name
          lastName
          country {
            id
            iso
            iso2
            active
            name
            phoneCode
            order
            companyRequirements
            personalRequirements
            createdAt
            updatedAt
          }
          address
          city
          postalCode
          email
          phoneCode
          phoneNumber
          currency {
            id
            iso
            active
            name
            walletBgColor
            walletTextColor
            order
            createdAt
            updatedAt
          }
          bankDetails {
            name
            value
          }
          userProfileId
          usageLevel
          skipInternalCheck
          currencyRequirementType
          beneficiaryCountryId
          beneficiaryCurrencyId
        }
        externalBankAccounts {
          name
          number
          iban
          swift
          sort
        }
        poundsAccount {
          id
          name
          label
          type
          currency
          iban
          bban
          balance
          lastTx
        }
        invitedBy {
          id
          accountType
          membership {
            id
            name
            price
            dailyLimit
            monthlyLimit
            createdAt
            updatedAt
          }
          name
          lastName
          birthdate
          country
          address
          city
          postalCode
          email
          emailVerified
          phoneCode
          phoneNumber
          phoneNumberVerified
          kyc {
            status
            result
            caseId
            applicantId
            reason
          }
          beneficiaries {
            id
            status
            userId
            accountType
            name
            lastName
            address
            city
            postalCode
            email
            phoneCode
            phoneNumber
            userProfileId
            usageLevel
            skipInternalCheck
            currencyRequirementType
            beneficiaryCountryId
            beneficiaryCurrencyId
          }
          externalBankAccounts {
            name
            number
            iban
            swift
            sort
          }
          poundsAccount {
            id
            name
            label
            type
            currency
            iban
            bban
            balance
            lastTx
          }
          invitedBy {
            id
            accountType
            name
            lastName
            birthdate
            country
            address
            city
            postalCode
            email
            emailVerified
            phoneCode
            phoneNumber
            phoneNumberVerified
            readNotifications
            readOnboardings
            profileImage
            smsMarketingConsent
            pushMarketingConsent
            emailMarketingConsent
            smsAllowed
            pushAllowed
            emailAllowed
            createdAt
            updatedAt
            intercomProfileUrl
            accountStatus
            type
            passwordResetAt
            passwordResetBy
            customerId
            businessDetail
            shareholders
            SignUpAddressPersonalDetails
            SignUpFinancialDetails
            SignUpIndividualPersonalDetails
            transactionsInfo
            businessType
            author
          }
          readNotifications
          readOnboardings
          amountsSent {
            daily
            monthly
          }
          profileImage
          paymentMethods {
            last4
            scheme
            provider
            customerId
            expiryMonth
            id
            expiryYear
            type
          }
          companyInfo {
            tradingName
            primaryHolderName
            industryType
            registeredAddress
            businessDescription
            annualTurnover
            stablishmentDate
            tradingStartDate
            transactionVolumen
            internationalActivity
            kybCompanyCode
          }
          pushNotificationsConfig {
            token
            endpointArn
          }
          smsMarketingConsent
          pushMarketingConsent
          emailMarketingConsent
          smsAllowed
          pushAllowed
          emailAllowed
          forbiddenCurrency {
            id
            iso
            active
            name
            walletBgColor
            walletTextColor
            order
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          intercomProfileUrl
          accountStatus
          type
          passwordResetAt
          passwordResetBy
          customerId
          businessDetail
          shareholders
          SignUpAddressPersonalDetails
          SignUpFinancialDetails
          SignUpIndividualPersonalDetails
          transactionsInfo
          businessType
          author
        }
        readNotifications
        readOnboardings
        amountsSent {
          daily
          monthly
        }
        profileImage
        paymentMethods {
          last4
          scheme
          provider
          customerId
          expiryMonth
          id
          expiryYear
          type
        }
        companyInfo {
          tradingName
          primaryHolderName
          industryType
          registeredAddress
          businessDescription
          annualTurnover
          stablishmentDate
          tradingStartDate
          transactionVolumen
          shareholders {
            name
            lastName
            birthdate
            country
            address
            city
            postalCode
            email
            phoneCode
            phoneNumber
          }
          internationalActivity
          kybCompanyCode
        }
        pushNotificationsConfig {
          token
          endpointArn
        }
        smsMarketingConsent
        pushMarketingConsent
        emailMarketingConsent
        smsAllowed
        pushAllowed
        emailAllowed
        forbiddenCurrency {
          id
          iso
          active
          name
          walletBgColor
          walletTextColor
          order
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        intercomProfileUrl
        accountStatus
        type
        passwordResetAt
        passwordResetBy
        customerId
        businessDetail
        shareholders
        SignUpAddressPersonalDetails
        SignUpFinancialDetails
        SignUpIndividualPersonalDetails
        transactionsInfo
        businessType
        author
      }
      beneficiary {
        id
        status
        userId
        accountType
        name
        lastName
        country {
          id
          iso
          iso2
          region {
            id
            name
            createdAt
            updatedAt
          }
          defaultCurrency {
            id
            iso
            active
            name
            walletBgColor
            walletTextColor
            order
            createdAt
            updatedAt
          }
          currency {
            id
            iso
            active
            name
            walletBgColor
            walletTextColor
            order
            createdAt
            updatedAt
          }
          active
          name
          phoneCode
          order
          companyRequirements
          personalRequirements
          createdAt
          updatedAt
        }
        address
        city
        postalCode
        email
        phoneCode
        phoneNumber
        currency {
          id
          iso
          active
          name
          walletBgColor
          walletTextColor
          order
          createdAt
          updatedAt
        }
        bankDetails {
          name
          value
        }
        userProfileId
        usageLevel
        skipInternalCheck
        currencyRequirementType
        beneficiaryCountryId
        beneficiaryCurrencyId
      }
      fromCurrency
      toCurrency
      sendingAmount
      exchangeRate
      receivingAmount
      fee
      transferFee
      discount {
        id
        code
        type
        amount
        totalUses
        totalUniqueUses
        expire
        discountUses {
          userId
          date
        }
      }
      reference
      purpose
      status
      steps {
        timestamp
        description
        order
        status
      }
      errorCode
      method
      userFromNote
      userFromAttachment
      userToNote
      userToAttachment
      createdAt
      internalId
      failureReason
      adminAttachments
      debtorAccount {
        addressLine1
        addressLine2
        country
        postcode
        name
        ownerName
        iban
        bban
        descriptor
        institutionName
        transactionOwnerName
      }
      creditorAccount {
        addressLine1
        addressLine2
        country
        postcode
        name
        ownerName
        iban
        bban
        descriptor
        institutionName
        transactionOwnerName
      }
      endToEndId
      rateQuoteId
      runningBalanceFrom
      runningBalanceTo
    }
  }
`;
export const sendMoney = /* GraphQL */ `
  mutation SendMoney($input: TransactionInput) {
    sendMoney(input: $input)
  }
`;
export const inviteUser = /* GraphQL */ `
  mutation InviteUser($input: InviteBeneficiaryInput) {
    inviteUser(input: $input)
  }
`;
export const confirmTransaction = /* GraphQL */ `
  mutation ConfirmTransaction($input: ConfirmTransactionInput) {
    confirmTransaction(input: $input)
  }
`;
export const editTransactionFrom = /* GraphQL */ `
  mutation EditTransactionFrom($input: EditTransactionInput) {
    editTransactionFrom(input: $input) {
      id
      type
      userIdFrom
      userIdTo
      userFrom {
        id
        accountType
        membership {
          id
          name
          price
          dailyLimit
          monthlyLimit
          createdAt
          updatedAt
        }
        name
        lastName
        birthdate
        country
        address
        city
        postalCode
        email
        emailVerified
        phoneCode
        phoneNumber
        phoneNumberVerified
        kyc {
          status
          result
          caseId
          applicantId
          reason
        }
        beneficiaries {
          id
          status
          userId
          accountType
          name
          lastName
          country {
            id
            iso
            iso2
            active
            name
            phoneCode
            order
            companyRequirements
            personalRequirements
            createdAt
            updatedAt
          }
          address
          city
          postalCode
          email
          phoneCode
          phoneNumber
          currency {
            id
            iso
            active
            name
            walletBgColor
            walletTextColor
            order
            createdAt
            updatedAt
          }
          bankDetails {
            name
            value
          }
          userProfileId
          usageLevel
          skipInternalCheck
          currencyRequirementType
          beneficiaryCountryId
          beneficiaryCurrencyId
        }
        externalBankAccounts {
          name
          number
          iban
          swift
          sort
        }
        poundsAccount {
          id
          name
          label
          type
          currency
          iban
          bban
          balance
          lastTx
        }
        invitedBy {
          id
          accountType
          membership {
            id
            name
            price
            dailyLimit
            monthlyLimit
            createdAt
            updatedAt
          }
          name
          lastName
          birthdate
          country
          address
          city
          postalCode
          email
          emailVerified
          phoneCode
          phoneNumber
          phoneNumberVerified
          kyc {
            status
            result
            caseId
            applicantId
            reason
          }
          beneficiaries {
            id
            status
            userId
            accountType
            name
            lastName
            address
            city
            postalCode
            email
            phoneCode
            phoneNumber
            userProfileId
            usageLevel
            skipInternalCheck
            currencyRequirementType
            beneficiaryCountryId
            beneficiaryCurrencyId
          }
          externalBankAccounts {
            name
            number
            iban
            swift
            sort
          }
          poundsAccount {
            id
            name
            label
            type
            currency
            iban
            bban
            balance
            lastTx
          }
          invitedBy {
            id
            accountType
            name
            lastName
            birthdate
            country
            address
            city
            postalCode
            email
            emailVerified
            phoneCode
            phoneNumber
            phoneNumberVerified
            readNotifications
            readOnboardings
            profileImage
            smsMarketingConsent
            pushMarketingConsent
            emailMarketingConsent
            smsAllowed
            pushAllowed
            emailAllowed
            createdAt
            updatedAt
            intercomProfileUrl
            accountStatus
            type
            passwordResetAt
            passwordResetBy
            customerId
            businessDetail
            shareholders
            SignUpAddressPersonalDetails
            SignUpFinancialDetails
            SignUpIndividualPersonalDetails
            transactionsInfo
            businessType
            author
          }
          readNotifications
          readOnboardings
          amountsSent {
            daily
            monthly
          }
          profileImage
          paymentMethods {
            last4
            scheme
            provider
            customerId
            expiryMonth
            id
            expiryYear
            type
          }
          companyInfo {
            tradingName
            primaryHolderName
            industryType
            registeredAddress
            businessDescription
            annualTurnover
            stablishmentDate
            tradingStartDate
            transactionVolumen
            internationalActivity
            kybCompanyCode
          }
          pushNotificationsConfig {
            token
            endpointArn
          }
          smsMarketingConsent
          pushMarketingConsent
          emailMarketingConsent
          smsAllowed
          pushAllowed
          emailAllowed
          forbiddenCurrency {
            id
            iso
            active
            name
            walletBgColor
            walletTextColor
            order
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          intercomProfileUrl
          accountStatus
          type
          passwordResetAt
          passwordResetBy
          customerId
          businessDetail
          shareholders
          SignUpAddressPersonalDetails
          SignUpFinancialDetails
          SignUpIndividualPersonalDetails
          transactionsInfo
          businessType
          author
        }
        readNotifications
        readOnboardings
        amountsSent {
          daily
          monthly
        }
        profileImage
        paymentMethods {
          last4
          scheme
          provider
          customerId
          expiryMonth
          id
          expiryYear
          type
        }
        companyInfo {
          tradingName
          primaryHolderName
          industryType
          registeredAddress
          businessDescription
          annualTurnover
          stablishmentDate
          tradingStartDate
          transactionVolumen
          shareholders {
            name
            lastName
            birthdate
            country
            address
            city
            postalCode
            email
            phoneCode
            phoneNumber
          }
          internationalActivity
          kybCompanyCode
        }
        pushNotificationsConfig {
          token
          endpointArn
        }
        smsMarketingConsent
        pushMarketingConsent
        emailMarketingConsent
        smsAllowed
        pushAllowed
        emailAllowed
        forbiddenCurrency {
          id
          iso
          active
          name
          walletBgColor
          walletTextColor
          order
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        intercomProfileUrl
        accountStatus
        type
        passwordResetAt
        passwordResetBy
        customerId
        businessDetail
        shareholders
        SignUpAddressPersonalDetails
        SignUpFinancialDetails
        SignUpIndividualPersonalDetails
        transactionsInfo
        businessType
        author
      }
      externalUserFrom {
        name
        lastName
        iban
      }
      userTo {
        id
        accountType
        membership {
          id
          name
          price
          dailyLimit
          monthlyLimit
          createdAt
          updatedAt
        }
        name
        lastName
        birthdate
        country
        address
        city
        postalCode
        email
        emailVerified
        phoneCode
        phoneNumber
        phoneNumberVerified
        kyc {
          status
          result
          caseId
          applicantId
          reason
        }
        beneficiaries {
          id
          status
          userId
          accountType
          name
          lastName
          country {
            id
            iso
            iso2
            active
            name
            phoneCode
            order
            companyRequirements
            personalRequirements
            createdAt
            updatedAt
          }
          address
          city
          postalCode
          email
          phoneCode
          phoneNumber
          currency {
            id
            iso
            active
            name
            walletBgColor
            walletTextColor
            order
            createdAt
            updatedAt
          }
          bankDetails {
            name
            value
          }
          userProfileId
          usageLevel
          skipInternalCheck
          currencyRequirementType
          beneficiaryCountryId
          beneficiaryCurrencyId
        }
        externalBankAccounts {
          name
          number
          iban
          swift
          sort
        }
        poundsAccount {
          id
          name
          label
          type
          currency
          iban
          bban
          balance
          lastTx
        }
        invitedBy {
          id
          accountType
          membership {
            id
            name
            price
            dailyLimit
            monthlyLimit
            createdAt
            updatedAt
          }
          name
          lastName
          birthdate
          country
          address
          city
          postalCode
          email
          emailVerified
          phoneCode
          phoneNumber
          phoneNumberVerified
          kyc {
            status
            result
            caseId
            applicantId
            reason
          }
          beneficiaries {
            id
            status
            userId
            accountType
            name
            lastName
            address
            city
            postalCode
            email
            phoneCode
            phoneNumber
            userProfileId
            usageLevel
            skipInternalCheck
            currencyRequirementType
            beneficiaryCountryId
            beneficiaryCurrencyId
          }
          externalBankAccounts {
            name
            number
            iban
            swift
            sort
          }
          poundsAccount {
            id
            name
            label
            type
            currency
            iban
            bban
            balance
            lastTx
          }
          invitedBy {
            id
            accountType
            name
            lastName
            birthdate
            country
            address
            city
            postalCode
            email
            emailVerified
            phoneCode
            phoneNumber
            phoneNumberVerified
            readNotifications
            readOnboardings
            profileImage
            smsMarketingConsent
            pushMarketingConsent
            emailMarketingConsent
            smsAllowed
            pushAllowed
            emailAllowed
            createdAt
            updatedAt
            intercomProfileUrl
            accountStatus
            type
            passwordResetAt
            passwordResetBy
            customerId
            businessDetail
            shareholders
            SignUpAddressPersonalDetails
            SignUpFinancialDetails
            SignUpIndividualPersonalDetails
            transactionsInfo
            businessType
            author
          }
          readNotifications
          readOnboardings
          amountsSent {
            daily
            monthly
          }
          profileImage
          paymentMethods {
            last4
            scheme
            provider
            customerId
            expiryMonth
            id
            expiryYear
            type
          }
          companyInfo {
            tradingName
            primaryHolderName
            industryType
            registeredAddress
            businessDescription
            annualTurnover
            stablishmentDate
            tradingStartDate
            transactionVolumen
            internationalActivity
            kybCompanyCode
          }
          pushNotificationsConfig {
            token
            endpointArn
          }
          smsMarketingConsent
          pushMarketingConsent
          emailMarketingConsent
          smsAllowed
          pushAllowed
          emailAllowed
          forbiddenCurrency {
            id
            iso
            active
            name
            walletBgColor
            walletTextColor
            order
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          intercomProfileUrl
          accountStatus
          type
          passwordResetAt
          passwordResetBy
          customerId
          businessDetail
          shareholders
          SignUpAddressPersonalDetails
          SignUpFinancialDetails
          SignUpIndividualPersonalDetails
          transactionsInfo
          businessType
          author
        }
        readNotifications
        readOnboardings
        amountsSent {
          daily
          monthly
        }
        profileImage
        paymentMethods {
          last4
          scheme
          provider
          customerId
          expiryMonth
          id
          expiryYear
          type
        }
        companyInfo {
          tradingName
          primaryHolderName
          industryType
          registeredAddress
          businessDescription
          annualTurnover
          stablishmentDate
          tradingStartDate
          transactionVolumen
          shareholders {
            name
            lastName
            birthdate
            country
            address
            city
            postalCode
            email
            phoneCode
            phoneNumber
          }
          internationalActivity
          kybCompanyCode
        }
        pushNotificationsConfig {
          token
          endpointArn
        }
        smsMarketingConsent
        pushMarketingConsent
        emailMarketingConsent
        smsAllowed
        pushAllowed
        emailAllowed
        forbiddenCurrency {
          id
          iso
          active
          name
          walletBgColor
          walletTextColor
          order
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        intercomProfileUrl
        accountStatus
        type
        passwordResetAt
        passwordResetBy
        customerId
        businessDetail
        shareholders
        SignUpAddressPersonalDetails
        SignUpFinancialDetails
        SignUpIndividualPersonalDetails
        transactionsInfo
        businessType
        author
      }
      beneficiary {
        id
        status
        userId
        accountType
        name
        lastName
        country {
          id
          iso
          iso2
          region {
            id
            name
            createdAt
            updatedAt
          }
          defaultCurrency {
            id
            iso
            active
            name
            walletBgColor
            walletTextColor
            order
            createdAt
            updatedAt
          }
          currency {
            id
            iso
            active
            name
            walletBgColor
            walletTextColor
            order
            createdAt
            updatedAt
          }
          active
          name
          phoneCode
          order
          companyRequirements
          personalRequirements
          createdAt
          updatedAt
        }
        address
        city
        postalCode
        email
        phoneCode
        phoneNumber
        currency {
          id
          iso
          active
          name
          walletBgColor
          walletTextColor
          order
          createdAt
          updatedAt
        }
        bankDetails {
          name
          value
        }
        userProfileId
        usageLevel
        skipInternalCheck
        currencyRequirementType
        beneficiaryCountryId
        beneficiaryCurrencyId
      }
      fromCurrency
      toCurrency
      sendingAmount
      exchangeRate
      receivingAmount
      fee
      transferFee
      discount {
        id
        code
        type
        amount
        totalUses
        totalUniqueUses
        expire
        discountUses {
          userId
          date
        }
      }
      reference
      purpose
      status
      steps {
        timestamp
        description
        order
        status
      }
      errorCode
      method
      userFromNote
      userFromAttachment
      userToNote
      userToAttachment
      createdAt
      internalId
      failureReason
      adminAttachments
      debtorAccount {
        addressLine1
        addressLine2
        country
        postcode
        name
        ownerName
        iban
        bban
        descriptor
        institutionName
        transactionOwnerName
      }
      creditorAccount {
        addressLine1
        addressLine2
        country
        postcode
        name
        ownerName
        iban
        bban
        descriptor
        institutionName
        transactionOwnerName
      }
      endToEndId
      rateQuoteId
      runningBalanceFrom
      runningBalanceTo
    }
  }
`;
export const editTransactionTo = /* GraphQL */ `
  mutation EditTransactionTo($input: EditTransactionInput) {
    editTransactionTo(input: $input) {
      id
      type
      userIdFrom
      userIdTo
      userFrom {
        id
        accountType
        membership {
          id
          name
          price
          dailyLimit
          monthlyLimit
          createdAt
          updatedAt
        }
        name
        lastName
        birthdate
        country
        address
        city
        postalCode
        email
        emailVerified
        phoneCode
        phoneNumber
        phoneNumberVerified
        kyc {
          status
          result
          caseId
          applicantId
          reason
        }
        beneficiaries {
          id
          status
          userId
          accountType
          name
          lastName
          country {
            id
            iso
            iso2
            active
            name
            phoneCode
            order
            companyRequirements
            personalRequirements
            createdAt
            updatedAt
          }
          address
          city
          postalCode
          email
          phoneCode
          phoneNumber
          currency {
            id
            iso
            active
            name
            walletBgColor
            walletTextColor
            order
            createdAt
            updatedAt
          }
          bankDetails {
            name
            value
          }
          userProfileId
          usageLevel
          skipInternalCheck
          currencyRequirementType
          beneficiaryCountryId
          beneficiaryCurrencyId
        }
        externalBankAccounts {
          name
          number
          iban
          swift
          sort
        }
        poundsAccount {
          id
          name
          label
          type
          currency
          iban
          bban
          balance
          lastTx
        }
        invitedBy {
          id
          accountType
          membership {
            id
            name
            price
            dailyLimit
            monthlyLimit
            createdAt
            updatedAt
          }
          name
          lastName
          birthdate
          country
          address
          city
          postalCode
          email
          emailVerified
          phoneCode
          phoneNumber
          phoneNumberVerified
          kyc {
            status
            result
            caseId
            applicantId
            reason
          }
          beneficiaries {
            id
            status
            userId
            accountType
            name
            lastName
            address
            city
            postalCode
            email
            phoneCode
            phoneNumber
            userProfileId
            usageLevel
            skipInternalCheck
            currencyRequirementType
            beneficiaryCountryId
            beneficiaryCurrencyId
          }
          externalBankAccounts {
            name
            number
            iban
            swift
            sort
          }
          poundsAccount {
            id
            name
            label
            type
            currency
            iban
            bban
            balance
            lastTx
          }
          invitedBy {
            id
            accountType
            name
            lastName
            birthdate
            country
            address
            city
            postalCode
            email
            emailVerified
            phoneCode
            phoneNumber
            phoneNumberVerified
            readNotifications
            readOnboardings
            profileImage
            smsMarketingConsent
            pushMarketingConsent
            emailMarketingConsent
            smsAllowed
            pushAllowed
            emailAllowed
            createdAt
            updatedAt
            intercomProfileUrl
            accountStatus
            type
            passwordResetAt
            passwordResetBy
            customerId
            businessDetail
            shareholders
            SignUpAddressPersonalDetails
            SignUpFinancialDetails
            SignUpIndividualPersonalDetails
            transactionsInfo
            businessType
            author
          }
          readNotifications
          readOnboardings
          amountsSent {
            daily
            monthly
          }
          profileImage
          paymentMethods {
            last4
            scheme
            provider
            customerId
            expiryMonth
            id
            expiryYear
            type
          }
          companyInfo {
            tradingName
            primaryHolderName
            industryType
            registeredAddress
            businessDescription
            annualTurnover
            stablishmentDate
            tradingStartDate
            transactionVolumen
            internationalActivity
            kybCompanyCode
          }
          pushNotificationsConfig {
            token
            endpointArn
          }
          smsMarketingConsent
          pushMarketingConsent
          emailMarketingConsent
          smsAllowed
          pushAllowed
          emailAllowed
          forbiddenCurrency {
            id
            iso
            active
            name
            walletBgColor
            walletTextColor
            order
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          intercomProfileUrl
          accountStatus
          type
          passwordResetAt
          passwordResetBy
          customerId
          businessDetail
          shareholders
          SignUpAddressPersonalDetails
          SignUpFinancialDetails
          SignUpIndividualPersonalDetails
          transactionsInfo
          businessType
          author
        }
        readNotifications
        readOnboardings
        amountsSent {
          daily
          monthly
        }
        profileImage
        paymentMethods {
          last4
          scheme
          provider
          customerId
          expiryMonth
          id
          expiryYear
          type
        }
        companyInfo {
          tradingName
          primaryHolderName
          industryType
          registeredAddress
          businessDescription
          annualTurnover
          stablishmentDate
          tradingStartDate
          transactionVolumen
          shareholders {
            name
            lastName
            birthdate
            country
            address
            city
            postalCode
            email
            phoneCode
            phoneNumber
          }
          internationalActivity
          kybCompanyCode
        }
        pushNotificationsConfig {
          token
          endpointArn
        }
        smsMarketingConsent
        pushMarketingConsent
        emailMarketingConsent
        smsAllowed
        pushAllowed
        emailAllowed
        forbiddenCurrency {
          id
          iso
          active
          name
          walletBgColor
          walletTextColor
          order
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        intercomProfileUrl
        accountStatus
        type
        passwordResetAt
        passwordResetBy
        customerId
        businessDetail
        shareholders
        SignUpAddressPersonalDetails
        SignUpFinancialDetails
        SignUpIndividualPersonalDetails
        transactionsInfo
        businessType
        author
      }
      externalUserFrom {
        name
        lastName
        iban
      }
      userTo {
        id
        accountType
        membership {
          id
          name
          price
          dailyLimit
          monthlyLimit
          createdAt
          updatedAt
        }
        name
        lastName
        birthdate
        country
        address
        city
        postalCode
        email
        emailVerified
        phoneCode
        phoneNumber
        phoneNumberVerified
        kyc {
          status
          result
          caseId
          applicantId
          reason
        }
        beneficiaries {
          id
          status
          userId
          accountType
          name
          lastName
          country {
            id
            iso
            iso2
            active
            name
            phoneCode
            order
            companyRequirements
            personalRequirements
            createdAt
            updatedAt
          }
          address
          city
          postalCode
          email
          phoneCode
          phoneNumber
          currency {
            id
            iso
            active
            name
            walletBgColor
            walletTextColor
            order
            createdAt
            updatedAt
          }
          bankDetails {
            name
            value
          }
          userProfileId
          usageLevel
          skipInternalCheck
          currencyRequirementType
          beneficiaryCountryId
          beneficiaryCurrencyId
        }
        externalBankAccounts {
          name
          number
          iban
          swift
          sort
        }
        poundsAccount {
          id
          name
          label
          type
          currency
          iban
          bban
          balance
          lastTx
        }
        invitedBy {
          id
          accountType
          membership {
            id
            name
            price
            dailyLimit
            monthlyLimit
            createdAt
            updatedAt
          }
          name
          lastName
          birthdate
          country
          address
          city
          postalCode
          email
          emailVerified
          phoneCode
          phoneNumber
          phoneNumberVerified
          kyc {
            status
            result
            caseId
            applicantId
            reason
          }
          beneficiaries {
            id
            status
            userId
            accountType
            name
            lastName
            address
            city
            postalCode
            email
            phoneCode
            phoneNumber
            userProfileId
            usageLevel
            skipInternalCheck
            currencyRequirementType
            beneficiaryCountryId
            beneficiaryCurrencyId
          }
          externalBankAccounts {
            name
            number
            iban
            swift
            sort
          }
          poundsAccount {
            id
            name
            label
            type
            currency
            iban
            bban
            balance
            lastTx
          }
          invitedBy {
            id
            accountType
            name
            lastName
            birthdate
            country
            address
            city
            postalCode
            email
            emailVerified
            phoneCode
            phoneNumber
            phoneNumberVerified
            readNotifications
            readOnboardings
            profileImage
            smsMarketingConsent
            pushMarketingConsent
            emailMarketingConsent
            smsAllowed
            pushAllowed
            emailAllowed
            createdAt
            updatedAt
            intercomProfileUrl
            accountStatus
            type
            passwordResetAt
            passwordResetBy
            customerId
            businessDetail
            shareholders
            SignUpAddressPersonalDetails
            SignUpFinancialDetails
            SignUpIndividualPersonalDetails
            transactionsInfo
            businessType
            author
          }
          readNotifications
          readOnboardings
          amountsSent {
            daily
            monthly
          }
          profileImage
          paymentMethods {
            last4
            scheme
            provider
            customerId
            expiryMonth
            id
            expiryYear
            type
          }
          companyInfo {
            tradingName
            primaryHolderName
            industryType
            registeredAddress
            businessDescription
            annualTurnover
            stablishmentDate
            tradingStartDate
            transactionVolumen
            internationalActivity
            kybCompanyCode
          }
          pushNotificationsConfig {
            token
            endpointArn
          }
          smsMarketingConsent
          pushMarketingConsent
          emailMarketingConsent
          smsAllowed
          pushAllowed
          emailAllowed
          forbiddenCurrency {
            id
            iso
            active
            name
            walletBgColor
            walletTextColor
            order
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          intercomProfileUrl
          accountStatus
          type
          passwordResetAt
          passwordResetBy
          customerId
          businessDetail
          shareholders
          SignUpAddressPersonalDetails
          SignUpFinancialDetails
          SignUpIndividualPersonalDetails
          transactionsInfo
          businessType
          author
        }
        readNotifications
        readOnboardings
        amountsSent {
          daily
          monthly
        }
        profileImage
        paymentMethods {
          last4
          scheme
          provider
          customerId
          expiryMonth
          id
          expiryYear
          type
        }
        companyInfo {
          tradingName
          primaryHolderName
          industryType
          registeredAddress
          businessDescription
          annualTurnover
          stablishmentDate
          tradingStartDate
          transactionVolumen
          shareholders {
            name
            lastName
            birthdate
            country
            address
            city
            postalCode
            email
            phoneCode
            phoneNumber
          }
          internationalActivity
          kybCompanyCode
        }
        pushNotificationsConfig {
          token
          endpointArn
        }
        smsMarketingConsent
        pushMarketingConsent
        emailMarketingConsent
        smsAllowed
        pushAllowed
        emailAllowed
        forbiddenCurrency {
          id
          iso
          active
          name
          walletBgColor
          walletTextColor
          order
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        intercomProfileUrl
        accountStatus
        type
        passwordResetAt
        passwordResetBy
        customerId
        businessDetail
        shareholders
        SignUpAddressPersonalDetails
        SignUpFinancialDetails
        SignUpIndividualPersonalDetails
        transactionsInfo
        businessType
        author
      }
      beneficiary {
        id
        status
        userId
        accountType
        name
        lastName
        country {
          id
          iso
          iso2
          region {
            id
            name
            createdAt
            updatedAt
          }
          defaultCurrency {
            id
            iso
            active
            name
            walletBgColor
            walletTextColor
            order
            createdAt
            updatedAt
          }
          currency {
            id
            iso
            active
            name
            walletBgColor
            walletTextColor
            order
            createdAt
            updatedAt
          }
          active
          name
          phoneCode
          order
          companyRequirements
          personalRequirements
          createdAt
          updatedAt
        }
        address
        city
        postalCode
        email
        phoneCode
        phoneNumber
        currency {
          id
          iso
          active
          name
          walletBgColor
          walletTextColor
          order
          createdAt
          updatedAt
        }
        bankDetails {
          name
          value
        }
        userProfileId
        usageLevel
        skipInternalCheck
        currencyRequirementType
        beneficiaryCountryId
        beneficiaryCurrencyId
      }
      fromCurrency
      toCurrency
      sendingAmount
      exchangeRate
      receivingAmount
      fee
      transferFee
      discount {
        id
        code
        type
        amount
        totalUses
        totalUniqueUses
        expire
        discountUses {
          userId
          date
        }
      }
      reference
      purpose
      status
      steps {
        timestamp
        description
        order
        status
      }
      errorCode
      method
      userFromNote
      userFromAttachment
      userToNote
      userToAttachment
      createdAt
      internalId
      failureReason
      adminAttachments
      debtorAccount {
        addressLine1
        addressLine2
        country
        postcode
        name
        ownerName
        iban
        bban
        descriptor
        institutionName
        transactionOwnerName
      }
      creditorAccount {
        addressLine1
        addressLine2
        country
        postcode
        name
        ownerName
        iban
        bban
        descriptor
        institutionName
        transactionOwnerName
      }
      endToEndId
      rateQuoteId
      runningBalanceFrom
      runningBalanceTo
    }
  }
`;
export const editTransaction = /* GraphQL */ `
  mutation EditTransaction($input: EditTransactionInput) {
    editTransaction(input: $input) {
      id
      type
      userIdFrom
      userIdTo
      userFrom {
        id
        accountType
        membership {
          id
          name
          price
          dailyLimit
          monthlyLimit
          createdAt
          updatedAt
        }
        name
        lastName
        birthdate
        country
        address
        city
        postalCode
        email
        emailVerified
        phoneCode
        phoneNumber
        phoneNumberVerified
        kyc {
          status
          result
          caseId
          applicantId
          reason
        }
        beneficiaries {
          id
          status
          userId
          accountType
          name
          lastName
          country {
            id
            iso
            iso2
            active
            name
            phoneCode
            order
            companyRequirements
            personalRequirements
            createdAt
            updatedAt
          }
          address
          city
          postalCode
          email
          phoneCode
          phoneNumber
          currency {
            id
            iso
            active
            name
            walletBgColor
            walletTextColor
            order
            createdAt
            updatedAt
          }
          bankDetails {
            name
            value
          }
          userProfileId
          usageLevel
          skipInternalCheck
          currencyRequirementType
          beneficiaryCountryId
          beneficiaryCurrencyId
        }
        externalBankAccounts {
          name
          number
          iban
          swift
          sort
        }
        poundsAccount {
          id
          name
          label
          type
          currency
          iban
          bban
          balance
          lastTx
        }
        invitedBy {
          id
          accountType
          membership {
            id
            name
            price
            dailyLimit
            monthlyLimit
            createdAt
            updatedAt
          }
          name
          lastName
          birthdate
          country
          address
          city
          postalCode
          email
          emailVerified
          phoneCode
          phoneNumber
          phoneNumberVerified
          kyc {
            status
            result
            caseId
            applicantId
            reason
          }
          beneficiaries {
            id
            status
            userId
            accountType
            name
            lastName
            address
            city
            postalCode
            email
            phoneCode
            phoneNumber
            userProfileId
            usageLevel
            skipInternalCheck
            currencyRequirementType
            beneficiaryCountryId
            beneficiaryCurrencyId
          }
          externalBankAccounts {
            name
            number
            iban
            swift
            sort
          }
          poundsAccount {
            id
            name
            label
            type
            currency
            iban
            bban
            balance
            lastTx
          }
          invitedBy {
            id
            accountType
            name
            lastName
            birthdate
            country
            address
            city
            postalCode
            email
            emailVerified
            phoneCode
            phoneNumber
            phoneNumberVerified
            readNotifications
            readOnboardings
            profileImage
            smsMarketingConsent
            pushMarketingConsent
            emailMarketingConsent
            smsAllowed
            pushAllowed
            emailAllowed
            createdAt
            updatedAt
            intercomProfileUrl
            accountStatus
            type
            passwordResetAt
            passwordResetBy
            customerId
            businessDetail
            shareholders
            SignUpAddressPersonalDetails
            SignUpFinancialDetails
            SignUpIndividualPersonalDetails
            transactionsInfo
            businessType
            author
          }
          readNotifications
          readOnboardings
          amountsSent {
            daily
            monthly
          }
          profileImage
          paymentMethods {
            last4
            scheme
            provider
            customerId
            expiryMonth
            id
            expiryYear
            type
          }
          companyInfo {
            tradingName
            primaryHolderName
            industryType
            registeredAddress
            businessDescription
            annualTurnover
            stablishmentDate
            tradingStartDate
            transactionVolumen
            internationalActivity
            kybCompanyCode
          }
          pushNotificationsConfig {
            token
            endpointArn
          }
          smsMarketingConsent
          pushMarketingConsent
          emailMarketingConsent
          smsAllowed
          pushAllowed
          emailAllowed
          forbiddenCurrency {
            id
            iso
            active
            name
            walletBgColor
            walletTextColor
            order
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          intercomProfileUrl
          accountStatus
          type
          passwordResetAt
          passwordResetBy
          customerId
          businessDetail
          shareholders
          SignUpAddressPersonalDetails
          SignUpFinancialDetails
          SignUpIndividualPersonalDetails
          transactionsInfo
          businessType
          author
        }
        readNotifications
        readOnboardings
        amountsSent {
          daily
          monthly
        }
        profileImage
        paymentMethods {
          last4
          scheme
          provider
          customerId
          expiryMonth
          id
          expiryYear
          type
        }
        companyInfo {
          tradingName
          primaryHolderName
          industryType
          registeredAddress
          businessDescription
          annualTurnover
          stablishmentDate
          tradingStartDate
          transactionVolumen
          shareholders {
            name
            lastName
            birthdate
            country
            address
            city
            postalCode
            email
            phoneCode
            phoneNumber
          }
          internationalActivity
          kybCompanyCode
        }
        pushNotificationsConfig {
          token
          endpointArn
        }
        smsMarketingConsent
        pushMarketingConsent
        emailMarketingConsent
        smsAllowed
        pushAllowed
        emailAllowed
        forbiddenCurrency {
          id
          iso
          active
          name
          walletBgColor
          walletTextColor
          order
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        intercomProfileUrl
        accountStatus
        type
        passwordResetAt
        passwordResetBy
        customerId
        businessDetail
        shareholders
        SignUpAddressPersonalDetails
        SignUpFinancialDetails
        SignUpIndividualPersonalDetails
        transactionsInfo
        businessType
        author
      }
      externalUserFrom {
        name
        lastName
        iban
      }
      userTo {
        id
        accountType
        membership {
          id
          name
          price
          dailyLimit
          monthlyLimit
          createdAt
          updatedAt
        }
        name
        lastName
        birthdate
        country
        address
        city
        postalCode
        email
        emailVerified
        phoneCode
        phoneNumber
        phoneNumberVerified
        kyc {
          status
          result
          caseId
          applicantId
          reason
        }
        beneficiaries {
          id
          status
          userId
          accountType
          name
          lastName
          country {
            id
            iso
            iso2
            active
            name
            phoneCode
            order
            companyRequirements
            personalRequirements
            createdAt
            updatedAt
          }
          address
          city
          postalCode
          email
          phoneCode
          phoneNumber
          currency {
            id
            iso
            active
            name
            walletBgColor
            walletTextColor
            order
            createdAt
            updatedAt
          }
          bankDetails {
            name
            value
          }
          userProfileId
          usageLevel
          skipInternalCheck
          currencyRequirementType
          beneficiaryCountryId
          beneficiaryCurrencyId
        }
        externalBankAccounts {
          name
          number
          iban
          swift
          sort
        }
        poundsAccount {
          id
          name
          label
          type
          currency
          iban
          bban
          balance
          lastTx
        }
        invitedBy {
          id
          accountType
          membership {
            id
            name
            price
            dailyLimit
            monthlyLimit
            createdAt
            updatedAt
          }
          name
          lastName
          birthdate
          country
          address
          city
          postalCode
          email
          emailVerified
          phoneCode
          phoneNumber
          phoneNumberVerified
          kyc {
            status
            result
            caseId
            applicantId
            reason
          }
          beneficiaries {
            id
            status
            userId
            accountType
            name
            lastName
            address
            city
            postalCode
            email
            phoneCode
            phoneNumber
            userProfileId
            usageLevel
            skipInternalCheck
            currencyRequirementType
            beneficiaryCountryId
            beneficiaryCurrencyId
          }
          externalBankAccounts {
            name
            number
            iban
            swift
            sort
          }
          poundsAccount {
            id
            name
            label
            type
            currency
            iban
            bban
            balance
            lastTx
          }
          invitedBy {
            id
            accountType
            name
            lastName
            birthdate
            country
            address
            city
            postalCode
            email
            emailVerified
            phoneCode
            phoneNumber
            phoneNumberVerified
            readNotifications
            readOnboardings
            profileImage
            smsMarketingConsent
            pushMarketingConsent
            emailMarketingConsent
            smsAllowed
            pushAllowed
            emailAllowed
            createdAt
            updatedAt
            intercomProfileUrl
            accountStatus
            type
            passwordResetAt
            passwordResetBy
            customerId
            businessDetail
            shareholders
            SignUpAddressPersonalDetails
            SignUpFinancialDetails
            SignUpIndividualPersonalDetails
            transactionsInfo
            businessType
            author
          }
          readNotifications
          readOnboardings
          amountsSent {
            daily
            monthly
          }
          profileImage
          paymentMethods {
            last4
            scheme
            provider
            customerId
            expiryMonth
            id
            expiryYear
            type
          }
          companyInfo {
            tradingName
            primaryHolderName
            industryType
            registeredAddress
            businessDescription
            annualTurnover
            stablishmentDate
            tradingStartDate
            transactionVolumen
            internationalActivity
            kybCompanyCode
          }
          pushNotificationsConfig {
            token
            endpointArn
          }
          smsMarketingConsent
          pushMarketingConsent
          emailMarketingConsent
          smsAllowed
          pushAllowed
          emailAllowed
          forbiddenCurrency {
            id
            iso
            active
            name
            walletBgColor
            walletTextColor
            order
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          intercomProfileUrl
          accountStatus
          type
          passwordResetAt
          passwordResetBy
          customerId
          businessDetail
          shareholders
          SignUpAddressPersonalDetails
          SignUpFinancialDetails
          SignUpIndividualPersonalDetails
          transactionsInfo
          businessType
          author
        }
        readNotifications
        readOnboardings
        amountsSent {
          daily
          monthly
        }
        profileImage
        paymentMethods {
          last4
          scheme
          provider
          customerId
          expiryMonth
          id
          expiryYear
          type
        }
        companyInfo {
          tradingName
          primaryHolderName
          industryType
          registeredAddress
          businessDescription
          annualTurnover
          stablishmentDate
          tradingStartDate
          transactionVolumen
          shareholders {
            name
            lastName
            birthdate
            country
            address
            city
            postalCode
            email
            phoneCode
            phoneNumber
          }
          internationalActivity
          kybCompanyCode
        }
        pushNotificationsConfig {
          token
          endpointArn
        }
        smsMarketingConsent
        pushMarketingConsent
        emailMarketingConsent
        smsAllowed
        pushAllowed
        emailAllowed
        forbiddenCurrency {
          id
          iso
          active
          name
          walletBgColor
          walletTextColor
          order
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        intercomProfileUrl
        accountStatus
        type
        passwordResetAt
        passwordResetBy
        customerId
        businessDetail
        shareholders
        SignUpAddressPersonalDetails
        SignUpFinancialDetails
        SignUpIndividualPersonalDetails
        transactionsInfo
        businessType
        author
      }
      beneficiary {
        id
        status
        userId
        accountType
        name
        lastName
        country {
          id
          iso
          iso2
          region {
            id
            name
            createdAt
            updatedAt
          }
          defaultCurrency {
            id
            iso
            active
            name
            walletBgColor
            walletTextColor
            order
            createdAt
            updatedAt
          }
          currency {
            id
            iso
            active
            name
            walletBgColor
            walletTextColor
            order
            createdAt
            updatedAt
          }
          active
          name
          phoneCode
          order
          companyRequirements
          personalRequirements
          createdAt
          updatedAt
        }
        address
        city
        postalCode
        email
        phoneCode
        phoneNumber
        currency {
          id
          iso
          active
          name
          walletBgColor
          walletTextColor
          order
          createdAt
          updatedAt
        }
        bankDetails {
          name
          value
        }
        userProfileId
        usageLevel
        skipInternalCheck
        currencyRequirementType
        beneficiaryCountryId
        beneficiaryCurrencyId
      }
      fromCurrency
      toCurrency
      sendingAmount
      exchangeRate
      receivingAmount
      fee
      transferFee
      discount {
        id
        code
        type
        amount
        totalUses
        totalUniqueUses
        expire
        discountUses {
          userId
          date
        }
      }
      reference
      purpose
      status
      steps {
        timestamp
        description
        order
        status
      }
      errorCode
      method
      userFromNote
      userFromAttachment
      userToNote
      userToAttachment
      createdAt
      internalId
      failureReason
      adminAttachments
      debtorAccount {
        addressLine1
        addressLine2
        country
        postcode
        name
        ownerName
        iban
        bban
        descriptor
        institutionName
        transactionOwnerName
      }
      creditorAccount {
        addressLine1
        addressLine2
        country
        postcode
        name
        ownerName
        iban
        bban
        descriptor
        institutionName
        transactionOwnerName
      }
      endToEndId
      rateQuoteId
      runningBalanceFrom
      runningBalanceTo
    }
  }
`;
export const editTransferFee = /* GraphQL */ `
  mutation EditTransferFee($input: EditTransferFeeInput) {
    editTransferFee(input: $input) {
      paymentMethod
      fee
    }
  }
`;
export const createWallet = /* GraphQL */ `
  mutation CreateWallet($input: CreateWalletInput) {
    createWallet(input: $input) {
      id
      currency
      balance
      history
      routingNumber
      accountNumber
      bankAddress
      bankName
      status
      type
      iban
    }
  }
`;
export const removeWallet = /* GraphQL */ `
  mutation RemoveWallet($input: DeleteWalletInput) {
    removeWallet(input: $input) {
      id
      currency
      balance
      history
      routingNumber
      accountNumber
      bankAddress
      bankName
      status
      type
      iban
    }
  }
`;
export const setWalletStatus = /* GraphQL */ `
  mutation SetWalletStatus($input: SetWalletStatusInput) {
    setWalletStatus(input: $input) {
      id
      currency
      balance
      history
      routingNumber
      accountNumber
      bankAddress
      bankName
      status
      type
      iban
    }
  }
`;
export const confirmConversion = /* GraphQL */ `
  mutation ConfirmConversion($input: ConfirmTransactionInput) {
    confirmConversion(input: $input)
  }
`;
export const sendMessage = /* GraphQL */ `
  mutation SendMessage($input: CreateMessageInput) {
    sendMessage(input: $input) {
      conversationId
      type
      timestamp
      sender {
        id
        accountType
        membership {
          id
          name
          price
          dailyLimit
          monthlyLimit
          createdAt
          updatedAt
        }
        name
        lastName
        birthdate
        country
        address
        city
        postalCode
        email
        emailVerified
        phoneCode
        phoneNumber
        phoneNumberVerified
        kyc {
          status
          result
          caseId
          applicantId
          reason
        }
        beneficiaries {
          id
          status
          userId
          accountType
          name
          lastName
          country {
            id
            iso
            iso2
            active
            name
            phoneCode
            order
            companyRequirements
            personalRequirements
            createdAt
            updatedAt
          }
          address
          city
          postalCode
          email
          phoneCode
          phoneNumber
          currency {
            id
            iso
            active
            name
            walletBgColor
            walletTextColor
            order
            createdAt
            updatedAt
          }
          bankDetails {
            name
            value
          }
          userProfileId
          usageLevel
          skipInternalCheck
          currencyRequirementType
          beneficiaryCountryId
          beneficiaryCurrencyId
        }
        externalBankAccounts {
          name
          number
          iban
          swift
          sort
        }
        poundsAccount {
          id
          name
          label
          type
          currency
          iban
          bban
          balance
          lastTx
        }
        invitedBy {
          id
          accountType
          membership {
            id
            name
            price
            dailyLimit
            monthlyLimit
            createdAt
            updatedAt
          }
          name
          lastName
          birthdate
          country
          address
          city
          postalCode
          email
          emailVerified
          phoneCode
          phoneNumber
          phoneNumberVerified
          kyc {
            status
            result
            caseId
            applicantId
            reason
          }
          beneficiaries {
            id
            status
            userId
            accountType
            name
            lastName
            address
            city
            postalCode
            email
            phoneCode
            phoneNumber
            userProfileId
            usageLevel
            skipInternalCheck
            currencyRequirementType
            beneficiaryCountryId
            beneficiaryCurrencyId
          }
          externalBankAccounts {
            name
            number
            iban
            swift
            sort
          }
          poundsAccount {
            id
            name
            label
            type
            currency
            iban
            bban
            balance
            lastTx
          }
          invitedBy {
            id
            accountType
            name
            lastName
            birthdate
            country
            address
            city
            postalCode
            email
            emailVerified
            phoneCode
            phoneNumber
            phoneNumberVerified
            readNotifications
            readOnboardings
            profileImage
            smsMarketingConsent
            pushMarketingConsent
            emailMarketingConsent
            smsAllowed
            pushAllowed
            emailAllowed
            createdAt
            updatedAt
            intercomProfileUrl
            accountStatus
            type
            passwordResetAt
            passwordResetBy
            customerId
            businessDetail
            shareholders
            SignUpAddressPersonalDetails
            SignUpFinancialDetails
            SignUpIndividualPersonalDetails
            transactionsInfo
            businessType
            author
          }
          readNotifications
          readOnboardings
          amountsSent {
            daily
            monthly
          }
          profileImage
          paymentMethods {
            last4
            scheme
            provider
            customerId
            expiryMonth
            id
            expiryYear
            type
          }
          companyInfo {
            tradingName
            primaryHolderName
            industryType
            registeredAddress
            businessDescription
            annualTurnover
            stablishmentDate
            tradingStartDate
            transactionVolumen
            internationalActivity
            kybCompanyCode
          }
          pushNotificationsConfig {
            token
            endpointArn
          }
          smsMarketingConsent
          pushMarketingConsent
          emailMarketingConsent
          smsAllowed
          pushAllowed
          emailAllowed
          forbiddenCurrency {
            id
            iso
            active
            name
            walletBgColor
            walletTextColor
            order
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          intercomProfileUrl
          accountStatus
          type
          passwordResetAt
          passwordResetBy
          customerId
          businessDetail
          shareholders
          SignUpAddressPersonalDetails
          SignUpFinancialDetails
          SignUpIndividualPersonalDetails
          transactionsInfo
          businessType
          author
        }
        readNotifications
        readOnboardings
        amountsSent {
          daily
          monthly
        }
        profileImage
        paymentMethods {
          last4
          scheme
          provider
          customerId
          expiryMonth
          id
          expiryYear
          type
        }
        companyInfo {
          tradingName
          primaryHolderName
          industryType
          registeredAddress
          businessDescription
          annualTurnover
          stablishmentDate
          tradingStartDate
          transactionVolumen
          shareholders {
            name
            lastName
            birthdate
            country
            address
            city
            postalCode
            email
            phoneCode
            phoneNumber
          }
          internationalActivity
          kybCompanyCode
        }
        pushNotificationsConfig {
          token
          endpointArn
        }
        smsMarketingConsent
        pushMarketingConsent
        emailMarketingConsent
        smsAllowed
        pushAllowed
        emailAllowed
        forbiddenCurrency {
          id
          iso
          active
          name
          walletBgColor
          walletTextColor
          order
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        intercomProfileUrl
        accountStatus
        type
        passwordResetAt
        passwordResetBy
        customerId
        businessDetail
        shareholders
        SignUpAddressPersonalDetails
        SignUpFinancialDetails
        SignUpIndividualPersonalDetails
        transactionsInfo
        businessType
        author
      }
      content
      read
    }
  }
`;
export const readMessage = /* GraphQL */ `
  mutation ReadMessage($input: ReadMessageInput) {
    readMessage(input: $input) {
      conversationId
      type
      timestamp
      sender {
        id
        accountType
        membership {
          id
          name
          price
          dailyLimit
          monthlyLimit
          createdAt
          updatedAt
        }
        name
        lastName
        birthdate
        country
        address
        city
        postalCode
        email
        emailVerified
        phoneCode
        phoneNumber
        phoneNumberVerified
        kyc {
          status
          result
          caseId
          applicantId
          reason
        }
        beneficiaries {
          id
          status
          userId
          accountType
          name
          lastName
          country {
            id
            iso
            iso2
            active
            name
            phoneCode
            order
            companyRequirements
            personalRequirements
            createdAt
            updatedAt
          }
          address
          city
          postalCode
          email
          phoneCode
          phoneNumber
          currency {
            id
            iso
            active
            name
            walletBgColor
            walletTextColor
            order
            createdAt
            updatedAt
          }
          bankDetails {
            name
            value
          }
          userProfileId
          usageLevel
          skipInternalCheck
          currencyRequirementType
          beneficiaryCountryId
          beneficiaryCurrencyId
        }
        externalBankAccounts {
          name
          number
          iban
          swift
          sort
        }
        poundsAccount {
          id
          name
          label
          type
          currency
          iban
          bban
          balance
          lastTx
        }
        invitedBy {
          id
          accountType
          membership {
            id
            name
            price
            dailyLimit
            monthlyLimit
            createdAt
            updatedAt
          }
          name
          lastName
          birthdate
          country
          address
          city
          postalCode
          email
          emailVerified
          phoneCode
          phoneNumber
          phoneNumberVerified
          kyc {
            status
            result
            caseId
            applicantId
            reason
          }
          beneficiaries {
            id
            status
            userId
            accountType
            name
            lastName
            address
            city
            postalCode
            email
            phoneCode
            phoneNumber
            userProfileId
            usageLevel
            skipInternalCheck
            currencyRequirementType
            beneficiaryCountryId
            beneficiaryCurrencyId
          }
          externalBankAccounts {
            name
            number
            iban
            swift
            sort
          }
          poundsAccount {
            id
            name
            label
            type
            currency
            iban
            bban
            balance
            lastTx
          }
          invitedBy {
            id
            accountType
            name
            lastName
            birthdate
            country
            address
            city
            postalCode
            email
            emailVerified
            phoneCode
            phoneNumber
            phoneNumberVerified
            readNotifications
            readOnboardings
            profileImage
            smsMarketingConsent
            pushMarketingConsent
            emailMarketingConsent
            smsAllowed
            pushAllowed
            emailAllowed
            createdAt
            updatedAt
            intercomProfileUrl
            accountStatus
            type
            passwordResetAt
            passwordResetBy
            customerId
            businessDetail
            shareholders
            SignUpAddressPersonalDetails
            SignUpFinancialDetails
            SignUpIndividualPersonalDetails
            transactionsInfo
            businessType
            author
          }
          readNotifications
          readOnboardings
          amountsSent {
            daily
            monthly
          }
          profileImage
          paymentMethods {
            last4
            scheme
            provider
            customerId
            expiryMonth
            id
            expiryYear
            type
          }
          companyInfo {
            tradingName
            primaryHolderName
            industryType
            registeredAddress
            businessDescription
            annualTurnover
            stablishmentDate
            tradingStartDate
            transactionVolumen
            internationalActivity
            kybCompanyCode
          }
          pushNotificationsConfig {
            token
            endpointArn
          }
          smsMarketingConsent
          pushMarketingConsent
          emailMarketingConsent
          smsAllowed
          pushAllowed
          emailAllowed
          forbiddenCurrency {
            id
            iso
            active
            name
            walletBgColor
            walletTextColor
            order
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          intercomProfileUrl
          accountStatus
          type
          passwordResetAt
          passwordResetBy
          customerId
          businessDetail
          shareholders
          SignUpAddressPersonalDetails
          SignUpFinancialDetails
          SignUpIndividualPersonalDetails
          transactionsInfo
          businessType
          author
        }
        readNotifications
        readOnboardings
        amountsSent {
          daily
          monthly
        }
        profileImage
        paymentMethods {
          last4
          scheme
          provider
          customerId
          expiryMonth
          id
          expiryYear
          type
        }
        companyInfo {
          tradingName
          primaryHolderName
          industryType
          registeredAddress
          businessDescription
          annualTurnover
          stablishmentDate
          tradingStartDate
          transactionVolumen
          shareholders {
            name
            lastName
            birthdate
            country
            address
            city
            postalCode
            email
            phoneCode
            phoneNumber
          }
          internationalActivity
          kybCompanyCode
        }
        pushNotificationsConfig {
          token
          endpointArn
        }
        smsMarketingConsent
        pushMarketingConsent
        emailMarketingConsent
        smsAllowed
        pushAllowed
        emailAllowed
        forbiddenCurrency {
          id
          iso
          active
          name
          walletBgColor
          walletTextColor
          order
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        intercomProfileUrl
        accountStatus
        type
        passwordResetAt
        passwordResetBy
        customerId
        businessDetail
        shareholders
        SignUpAddressPersonalDetails
        SignUpFinancialDetails
        SignUpIndividualPersonalDetails
        transactionsInfo
        businessType
        author
      }
      content
      read
    }
  }
`;
export const registerMobileToken = /* GraphQL */ `
  mutation RegisterMobileToken($token: String!) {
    registerMobileToken(token: $token)
  }
`;
export const removeMobileToken = /* GraphQL */ `
  mutation RemoveMobileToken($token: String!) {
    removeMobileToken(token: $token)
  }
`;
export const paymentGatewayRequest = /* GraphQL */ `
  mutation PaymentGatewayRequest($input: RequestPaymentInput!) {
    paymentGatewayRequest(input: $input) {
      success
      errorCode
      errorMessage
      internalMessage
      redirectLink
    }
  }
`;
export const paymentGatewayRemoveMethod = /* GraphQL */ `
  mutation PaymentGatewayRemoveMethod($input: RequestPaymentInput!) {
    paymentGatewayRemoveMethod(input: $input) {
      success
      errorCode
      errorMessage
      internalMessage
      redirectLink
    }
  }
`;
export const cbCreateVirtualMCCYAccount = /* GraphQL */ `
  mutation CbCreateVirtualMCCYAccount {
    cbCreateVirtualMCCYAccount {
      success
      errorCode
      errorMessage
      internalMessage
    }
  }
`;
export const cbCreateVirtualAccount = /* GraphQL */ `
  mutation CbCreateVirtualAccount($input: CBCreateNewVirtualInput) {
    cbCreateVirtualAccount(input: $input) {
      success
      iban
      errorMessage
    }
  }
`;
export const kybCreateCase = /* GraphQL */ `
  mutation KybCreateCase($company: String, $country: String, $code: String) {
    kybCreateCase(company: $company, country: $country, code: $code) {
      success
      data
    }
  }
`;
export const kybCreateShareholder = /* GraphQL */ `
  mutation KybCreateShareholder($data: AWSJSON) {
    kybCreateShareholder(data: $data) {
      success
      data
    }
  }
`;
export const kybUploadDocument = /* GraphQL */ `
  mutation KybUploadDocument(
    $type: AccountType
    $name: String
    $path: String
    $commonCaseId: String
  ) {
    kybUploadDocument(
      type: $type
      name: $name
      path: $path
      commonCaseId: $commonCaseId
    ) {
      success
      data
    }
  }
`;
export const kybRequestDocument = /* GraphQL */ `
  mutation KybRequestDocument(
    $type: AccountType
    $documents: [String]
    $email: String
    $phone: String
    $caseCommonId: String
  ) {
    kybRequestDocument(
      type: $type
      documents: $documents
      email: $email
      phone: $phone
      caseCommonId: $caseCommonId
    ) {
      success
      data
    }
  }
`;
export const upgradeMembership = /* GraphQL */ `
  mutation UpgradeMembership($id: ID!) {
    upgradeMembership(id: $id) {
      success
      data
      errorCode
    }
  }
`;
export const cancelDirectDebitMandate = /* GraphQL */ `
  mutation CancelDirectDebitMandate($mandateId: ID!) {
    cancelDirectDebitMandate(mandateId: $mandateId) {
      success
      data
      errorCode
    }
  }
`;
export const createCountry = /* GraphQL */ `
  mutation CreateCountry($input: CreateCountryInput) {
    createCountry(input: $input) {
      id
      iso
      iso2
      region {
        id
        name
        createdAt
        updatedAt
      }
      defaultCurrency {
        id
        iso
        active
        name
        walletBgColor
        walletTextColor
        order
        createdAt
        updatedAt
      }
      currency {
        id
        iso
        active
        name
        walletBgColor
        walletTextColor
        order
        createdAt
        updatedAt
      }
      active
      name
      phoneCode
      order
      companyRequirements
      personalRequirements
      createdAt
      updatedAt
    }
  }
`;
export const updateCountry = /* GraphQL */ `
  mutation UpdateCountry($input: UpdateCountryInput) {
    updateCountry(input: $input) {
      id
      iso
      iso2
      region {
        id
        name
        createdAt
        updatedAt
      }
      defaultCurrency {
        id
        iso
        active
        name
        walletBgColor
        walletTextColor
        order
        createdAt
        updatedAt
      }
      currency {
        id
        iso
        active
        name
        walletBgColor
        walletTextColor
        order
        createdAt
        updatedAt
      }
      active
      name
      phoneCode
      order
      companyRequirements
      personalRequirements
      createdAt
      updatedAt
    }
  }
`;
export const deleteCountry = /* GraphQL */ `
  mutation DeleteCountry($input: DeleteCountryInput) {
    deleteCountry(input: $input) {
      id
      iso
      iso2
      region {
        id
        name
        createdAt
        updatedAt
      }
      defaultCurrency {
        id
        iso
        active
        name
        walletBgColor
        walletTextColor
        order
        createdAt
        updatedAt
      }
      currency {
        id
        iso
        active
        name
        walletBgColor
        walletTextColor
        order
        createdAt
        updatedAt
      }
      active
      name
      phoneCode
      order
      companyRequirements
      personalRequirements
      createdAt
      updatedAt
    }
  }
`;
export const createCurrency = /* GraphQL */ `
  mutation CreateCurrency($input: CreateCurrencyInput) {
    createCurrency(input: $input) {
      id
      iso
      active
      name
      walletBgColor
      walletTextColor
      order
      createdAt
      updatedAt
    }
  }
`;
export const updateCurrency = /* GraphQL */ `
  mutation UpdateCurrency($input: UpdateCurrencyInput) {
    updateCurrency(input: $input) {
      id
      iso
      active
      name
      walletBgColor
      walletTextColor
      order
      createdAt
      updatedAt
    }
  }
`;
export const deleteCurrency = /* GraphQL */ `
  mutation DeleteCurrency($input: DeleteCurrencyInput) {
    deleteCurrency(input: $input) {
      id
      iso
      active
      name
      walletBgColor
      walletTextColor
      order
      createdAt
      updatedAt
    }
  }
`;
export const putCurrencyRequirement = /* GraphQL */ `
  mutation PutCurrencyRequirement($input: PutCurrencyRequirementInput) {
    putCurrencyRequirement(input: $input) {
      currency
      type
      name
      requirements
      updatedAt
    }
  }
`;
export const editCurrencyRequirement = /* GraphQL */ `
  mutation EditCurrencyRequirement($input: PutCurrencyRequirementInput) {
    editCurrencyRequirement(input: $input) {
      currency
      type
      name
      requirements
      updatedAt
    }
  }
`;
export const deleteCurrencyRequirement = /* GraphQL */ `
  mutation DeleteCurrencyRequirement($input: DeleteCurrencyRequirementInput) {
    deleteCurrencyRequirement(input: $input) {
      currency
      type
      name
      requirements
      updatedAt
    }
  }
`;
export const createPair = /* GraphQL */ `
  mutation CreatePair($input: CreatePairInput) {
    createPair(input: $input) {
      id
      status
      from {
        id
        iso
        active
        name
        walletBgColor
        walletTextColor
        order
        createdAt
        updatedAt
      }
      to {
        id
        iso
        active
        name
        walletBgColor
        walletTextColor
        order
        createdAt
        updatedAt
      }
      regionDestination {
        id
        name
        createdAt
        updatedAt
      }
      destination {
        id
        iso
        iso2
        region {
          id
          name
          createdAt
          updatedAt
        }
        defaultCurrency {
          id
          iso
          active
          name
          walletBgColor
          walletTextColor
          order
          createdAt
          updatedAt
        }
        currency {
          id
          iso
          active
          name
          walletBgColor
          walletTextColor
          order
          createdAt
          updatedAt
        }
        active
        name
        phoneCode
        order
        companyRequirements
        personalRequirements
        createdAt
        updatedAt
      }
      rates {
        intervalIni
        intervalEnd
        rateMode
        rateFrom
        rateTo
        feeMode
        fee
        percentageFee
      }
      lastExchangeRateBuy
      lastExchangeRateSell
      createdAt
      updatedAt
      sellRateOverride
      profitMargin {
        type
        value
      }
    }
  }
`;
export const updatePair = /* GraphQL */ `
  mutation UpdatePair($input: UpdatePairInput) {
    updatePair(input: $input) {
      id
      status
      from {
        id
        iso
        active
        name
        walletBgColor
        walletTextColor
        order
        createdAt
        updatedAt
      }
      to {
        id
        iso
        active
        name
        walletBgColor
        walletTextColor
        order
        createdAt
        updatedAt
      }
      regionDestination {
        id
        name
        createdAt
        updatedAt
      }
      destination {
        id
        iso
        iso2
        region {
          id
          name
          createdAt
          updatedAt
        }
        defaultCurrency {
          id
          iso
          active
          name
          walletBgColor
          walletTextColor
          order
          createdAt
          updatedAt
        }
        currency {
          id
          iso
          active
          name
          walletBgColor
          walletTextColor
          order
          createdAt
          updatedAt
        }
        active
        name
        phoneCode
        order
        companyRequirements
        personalRequirements
        createdAt
        updatedAt
      }
      rates {
        intervalIni
        intervalEnd
        rateMode
        rateFrom
        rateTo
        feeMode
        fee
        percentageFee
      }
      lastExchangeRateBuy
      lastExchangeRateSell
      createdAt
      updatedAt
      sellRateOverride
      profitMargin {
        type
        value
      }
    }
  }
`;
export const deletePair = /* GraphQL */ `
  mutation DeletePair($input: DeletePairInput) {
    deletePair(input: $input) {
      id
      status
      from {
        id
        iso
        active
        name
        walletBgColor
        walletTextColor
        order
        createdAt
        updatedAt
      }
      to {
        id
        iso
        active
        name
        walletBgColor
        walletTextColor
        order
        createdAt
        updatedAt
      }
      regionDestination {
        id
        name
        createdAt
        updatedAt
      }
      destination {
        id
        iso
        iso2
        region {
          id
          name
          createdAt
          updatedAt
        }
        defaultCurrency {
          id
          iso
          active
          name
          walletBgColor
          walletTextColor
          order
          createdAt
          updatedAt
        }
        currency {
          id
          iso
          active
          name
          walletBgColor
          walletTextColor
          order
          createdAt
          updatedAt
        }
        active
        name
        phoneCode
        order
        companyRequirements
        personalRequirements
        createdAt
        updatedAt
      }
      rates {
        intervalIni
        intervalEnd
        rateMode
        rateFrom
        rateTo
        feeMode
        fee
        percentageFee
      }
      lastExchangeRateBuy
      lastExchangeRateSell
      createdAt
      updatedAt
      sellRateOverride
      profitMargin {
        type
        value
      }
    }
  }
`;
export const createTeam = /* GraphQL */ `
  mutation CreateTeam($input: CreateTeamInput) {
    createTeam(input: $input) {
      id
      name
      email
      active
      accountType
      permissions
      createdAt
      updatedAt
    }
  }
`;
export const updateTeam = /* GraphQL */ `
  mutation UpdateTeam($input: UpdateTeamInput) {
    updateTeam(input: $input) {
      id
      name
      email
      active
      accountType
      permissions
      createdAt
      updatedAt
    }
  }
`;
export const deleteTeam = /* GraphQL */ `
  mutation DeleteTeam($input: DeleteTeamInput) {
    deleteTeam(input: $input) {
      id
      name
      email
      active
      accountType
      permissions
      createdAt
      updatedAt
    }
  }
`;
export const createFee = /* GraphQL */ `
  mutation CreateFee($input: CreateFeeInput) {
    createFee(input: $input) {
      id
      currency {
        id
        iso
        active
        name
        walletBgColor
        walletTextColor
        order
        createdAt
        updatedAt
      }
      paymentType
      rates {
        intervalIni
        intervalEnd
        feePercent
        feeAbsolute
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateFee = /* GraphQL */ `
  mutation UpdateFee($input: UpdateFeeInput) {
    updateFee(input: $input) {
      id
      currency {
        id
        iso
        active
        name
        walletBgColor
        walletTextColor
        order
        createdAt
        updatedAt
      }
      paymentType
      rates {
        intervalIni
        intervalEnd
        feePercent
        feeAbsolute
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteFee = /* GraphQL */ `
  mutation DeleteFee($input: DeleteFeeInput) {
    deleteFee(input: $input) {
      id
      currency {
        id
        iso
        active
        name
        walletBgColor
        walletTextColor
        order
        createdAt
        updatedAt
      }
      paymentType
      rates {
        intervalIni
        intervalEnd
        feePercent
        feeAbsolute
      }
      createdAt
      updatedAt
    }
  }
`;
export const createLocalPair = /* GraphQL */ `
  mutation CreateLocalPair($input: CreateLocalPairInput) {
    createLocalPair(input: $input) {
      id
      currency {
        id
        iso
        active
        name
        walletBgColor
        walletTextColor
        order
        createdAt
        updatedAt
      }
      region {
        id
        name
        createdAt
        updatedAt
      }
      country {
        id
        iso
        iso2
        region {
          id
          name
          createdAt
          updatedAt
        }
        defaultCurrency {
          id
          iso
          active
          name
          walletBgColor
          walletTextColor
          order
          createdAt
          updatedAt
        }
        currency {
          id
          iso
          active
          name
          walletBgColor
          walletTextColor
          order
          createdAt
          updatedAt
        }
        active
        name
        phoneCode
        order
        companyRequirements
        personalRequirements
        createdAt
        updatedAt
      }
      rates {
        intervalIni
        intervalEnd
        feeMode
        fee
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateLocalPair = /* GraphQL */ `
  mutation UpdateLocalPair($input: UpdateLocalPairInput) {
    updateLocalPair(input: $input) {
      id
      currency {
        id
        iso
        active
        name
        walletBgColor
        walletTextColor
        order
        createdAt
        updatedAt
      }
      region {
        id
        name
        createdAt
        updatedAt
      }
      country {
        id
        iso
        iso2
        region {
          id
          name
          createdAt
          updatedAt
        }
        defaultCurrency {
          id
          iso
          active
          name
          walletBgColor
          walletTextColor
          order
          createdAt
          updatedAt
        }
        currency {
          id
          iso
          active
          name
          walletBgColor
          walletTextColor
          order
          createdAt
          updatedAt
        }
        active
        name
        phoneCode
        order
        companyRequirements
        personalRequirements
        createdAt
        updatedAt
      }
      rates {
        intervalIni
        intervalEnd
        feeMode
        fee
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteLocalPair = /* GraphQL */ `
  mutation DeleteLocalPair($input: DeleteLocalPairInput) {
    deleteLocalPair(input: $input) {
      id
      currency {
        id
        iso
        active
        name
        walletBgColor
        walletTextColor
        order
        createdAt
        updatedAt
      }
      region {
        id
        name
        createdAt
        updatedAt
      }
      country {
        id
        iso
        iso2
        region {
          id
          name
          createdAt
          updatedAt
        }
        defaultCurrency {
          id
          iso
          active
          name
          walletBgColor
          walletTextColor
          order
          createdAt
          updatedAt
        }
        currency {
          id
          iso
          active
          name
          walletBgColor
          walletTextColor
          order
          createdAt
          updatedAt
        }
        active
        name
        phoneCode
        order
        companyRequirements
        personalRequirements
        createdAt
        updatedAt
      }
      rates {
        intervalIni
        intervalEnd
        feeMode
        fee
      }
      createdAt
      updatedAt
    }
  }
`;
export const createConfig = /* GraphQL */ `
  mutation CreateConfig($input: CreateConfigInput) {
    createConfig(input: $input) {
      id
      key
      value
    }
  }
`;
export const updateConfig = /* GraphQL */ `
  mutation UpdateConfig($input: UpdateConfigInput) {
    updateConfig(input: $input) {
      id
      key
      value
    }
  }
`;
export const deleteConfig = /* GraphQL */ `
  mutation DeleteConfig($input: DeleteConfigInput) {
    deleteConfig(input: $input) {
      id
      key
      value
    }
  }
`;
export const createDiscount = /* GraphQL */ `
  mutation CreateDiscount($input: CreateDiscountInput) {
    createDiscount(input: $input) {
      id
      code
      type
      amount
      totalUses
      totalUniqueUses
      expire
      discountUses {
        userId
        date
      }
    }
  }
`;
export const updateDiscount = /* GraphQL */ `
  mutation UpdateDiscount($input: UpdateDiscountInput) {
    updateDiscount(input: $input) {
      id
      code
      type
      amount
      totalUses
      totalUniqueUses
      expire
      discountUses {
        userId
        date
      }
    }
  }
`;
export const deleteDiscount = /* GraphQL */ `
  mutation DeleteDiscount($input: DeleteDiscountInput) {
    deleteDiscount(input: $input) {
      id
      code
      type
      amount
      totalUses
      totalUniqueUses
      expire
      discountUses {
        userId
        date
      }
    }
  }
`;
export const createOnboarding = /* GraphQL */ `
  mutation CreateOnboarding($input: CreateOnboardingInput) {
    createOnboarding(input: $input) {
      id
      showTopSteps
      topText
      showBottomSteps
      bottomText
      type
      showLogo
      ctaText
      slideImage
      order
      createdAt
      updatedAt
    }
  }
`;
export const updateOnboarding = /* GraphQL */ `
  mutation UpdateOnboarding($input: UpdateOnboardingInput) {
    updateOnboarding(input: $input) {
      id
      showTopSteps
      topText
      showBottomSteps
      bottomText
      type
      showLogo
      ctaText
      slideImage
      order
      createdAt
      updatedAt
    }
  }
`;
export const deleteOnboarding = /* GraphQL */ `
  mutation DeleteOnboarding($input: DeleteOnboardingInput) {
    deleteOnboarding(input: $input) {
      id
      showTopSteps
      topText
      showBottomSteps
      bottomText
      type
      showLogo
      ctaText
      slideImage
      order
      createdAt
      updatedAt
    }
  }
`;
export const createNotification = /* GraphQL */ `
  mutation CreateNotification($input: CreateNotificationInput) {
    createNotification(input: $input) {
      id
      type
      title
      body
      url
      user {
        id
        accountType
        membership {
          id
          name
          price
          dailyLimit
          monthlyLimit
          createdAt
          updatedAt
        }
        name
        lastName
        birthdate
        country
        address
        city
        postalCode
        email
        emailVerified
        phoneCode
        phoneNumber
        phoneNumberVerified
        kyc {
          status
          result
          caseId
          applicantId
          reason
        }
        beneficiaries {
          id
          status
          userId
          accountType
          name
          lastName
          country {
            id
            iso
            iso2
            active
            name
            phoneCode
            order
            companyRequirements
            personalRequirements
            createdAt
            updatedAt
          }
          address
          city
          postalCode
          email
          phoneCode
          phoneNumber
          currency {
            id
            iso
            active
            name
            walletBgColor
            walletTextColor
            order
            createdAt
            updatedAt
          }
          bankDetails {
            name
            value
          }
          userProfileId
          usageLevel
          skipInternalCheck
          currencyRequirementType
          beneficiaryCountryId
          beneficiaryCurrencyId
        }
        externalBankAccounts {
          name
          number
          iban
          swift
          sort
        }
        poundsAccount {
          id
          name
          label
          type
          currency
          iban
          bban
          balance
          lastTx
        }
        invitedBy {
          id
          accountType
          membership {
            id
            name
            price
            dailyLimit
            monthlyLimit
            createdAt
            updatedAt
          }
          name
          lastName
          birthdate
          country
          address
          city
          postalCode
          email
          emailVerified
          phoneCode
          phoneNumber
          phoneNumberVerified
          kyc {
            status
            result
            caseId
            applicantId
            reason
          }
          beneficiaries {
            id
            status
            userId
            accountType
            name
            lastName
            address
            city
            postalCode
            email
            phoneCode
            phoneNumber
            userProfileId
            usageLevel
            skipInternalCheck
            currencyRequirementType
            beneficiaryCountryId
            beneficiaryCurrencyId
          }
          externalBankAccounts {
            name
            number
            iban
            swift
            sort
          }
          poundsAccount {
            id
            name
            label
            type
            currency
            iban
            bban
            balance
            lastTx
          }
          invitedBy {
            id
            accountType
            name
            lastName
            birthdate
            country
            address
            city
            postalCode
            email
            emailVerified
            phoneCode
            phoneNumber
            phoneNumberVerified
            readNotifications
            readOnboardings
            profileImage
            smsMarketingConsent
            pushMarketingConsent
            emailMarketingConsent
            smsAllowed
            pushAllowed
            emailAllowed
            createdAt
            updatedAt
            intercomProfileUrl
            accountStatus
            type
            passwordResetAt
            passwordResetBy
            customerId
            businessDetail
            shareholders
            SignUpAddressPersonalDetails
            SignUpFinancialDetails
            SignUpIndividualPersonalDetails
            transactionsInfo
            businessType
            author
          }
          readNotifications
          readOnboardings
          amountsSent {
            daily
            monthly
          }
          profileImage
          paymentMethods {
            last4
            scheme
            provider
            customerId
            expiryMonth
            id
            expiryYear
            type
          }
          companyInfo {
            tradingName
            primaryHolderName
            industryType
            registeredAddress
            businessDescription
            annualTurnover
            stablishmentDate
            tradingStartDate
            transactionVolumen
            internationalActivity
            kybCompanyCode
          }
          pushNotificationsConfig {
            token
            endpointArn
          }
          smsMarketingConsent
          pushMarketingConsent
          emailMarketingConsent
          smsAllowed
          pushAllowed
          emailAllowed
          forbiddenCurrency {
            id
            iso
            active
            name
            walletBgColor
            walletTextColor
            order
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          intercomProfileUrl
          accountStatus
          type
          passwordResetAt
          passwordResetBy
          customerId
          businessDetail
          shareholders
          SignUpAddressPersonalDetails
          SignUpFinancialDetails
          SignUpIndividualPersonalDetails
          transactionsInfo
          businessType
          author
        }
        readNotifications
        readOnboardings
        amountsSent {
          daily
          monthly
        }
        profileImage
        paymentMethods {
          last4
          scheme
          provider
          customerId
          expiryMonth
          id
          expiryYear
          type
        }
        companyInfo {
          tradingName
          primaryHolderName
          industryType
          registeredAddress
          businessDescription
          annualTurnover
          stablishmentDate
          tradingStartDate
          transactionVolumen
          shareholders {
            name
            lastName
            birthdate
            country
            address
            city
            postalCode
            email
            phoneCode
            phoneNumber
          }
          internationalActivity
          kybCompanyCode
        }
        pushNotificationsConfig {
          token
          endpointArn
        }
        smsMarketingConsent
        pushMarketingConsent
        emailMarketingConsent
        smsAllowed
        pushAllowed
        emailAllowed
        forbiddenCurrency {
          id
          iso
          active
          name
          walletBgColor
          walletTextColor
          order
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        intercomProfileUrl
        accountStatus
        type
        passwordResetAt
        passwordResetBy
        customerId
        businessDetail
        shareholders
        SignUpAddressPersonalDetails
        SignUpFinancialDetails
        SignUpIndividualPersonalDetails
        transactionsInfo
        businessType
        author
      }
      notificationUserId
      notifSendApproach
      meta
      createdAt
      updatedAt
    }
  }
`;
export const deleteNotification = /* GraphQL */ `
  mutation DeleteNotification($input: DeleteNotificationInput) {
    deleteNotification(input: $input) {
      id
      type
      title
      body
      url
      user {
        id
        accountType
        membership {
          id
          name
          price
          dailyLimit
          monthlyLimit
          createdAt
          updatedAt
        }
        name
        lastName
        birthdate
        country
        address
        city
        postalCode
        email
        emailVerified
        phoneCode
        phoneNumber
        phoneNumberVerified
        kyc {
          status
          result
          caseId
          applicantId
          reason
        }
        beneficiaries {
          id
          status
          userId
          accountType
          name
          lastName
          country {
            id
            iso
            iso2
            active
            name
            phoneCode
            order
            companyRequirements
            personalRequirements
            createdAt
            updatedAt
          }
          address
          city
          postalCode
          email
          phoneCode
          phoneNumber
          currency {
            id
            iso
            active
            name
            walletBgColor
            walletTextColor
            order
            createdAt
            updatedAt
          }
          bankDetails {
            name
            value
          }
          userProfileId
          usageLevel
          skipInternalCheck
          currencyRequirementType
          beneficiaryCountryId
          beneficiaryCurrencyId
        }
        externalBankAccounts {
          name
          number
          iban
          swift
          sort
        }
        poundsAccount {
          id
          name
          label
          type
          currency
          iban
          bban
          balance
          lastTx
        }
        invitedBy {
          id
          accountType
          membership {
            id
            name
            price
            dailyLimit
            monthlyLimit
            createdAt
            updatedAt
          }
          name
          lastName
          birthdate
          country
          address
          city
          postalCode
          email
          emailVerified
          phoneCode
          phoneNumber
          phoneNumberVerified
          kyc {
            status
            result
            caseId
            applicantId
            reason
          }
          beneficiaries {
            id
            status
            userId
            accountType
            name
            lastName
            address
            city
            postalCode
            email
            phoneCode
            phoneNumber
            userProfileId
            usageLevel
            skipInternalCheck
            currencyRequirementType
            beneficiaryCountryId
            beneficiaryCurrencyId
          }
          externalBankAccounts {
            name
            number
            iban
            swift
            sort
          }
          poundsAccount {
            id
            name
            label
            type
            currency
            iban
            bban
            balance
            lastTx
          }
          invitedBy {
            id
            accountType
            name
            lastName
            birthdate
            country
            address
            city
            postalCode
            email
            emailVerified
            phoneCode
            phoneNumber
            phoneNumberVerified
            readNotifications
            readOnboardings
            profileImage
            smsMarketingConsent
            pushMarketingConsent
            emailMarketingConsent
            smsAllowed
            pushAllowed
            emailAllowed
            createdAt
            updatedAt
            intercomProfileUrl
            accountStatus
            type
            passwordResetAt
            passwordResetBy
            customerId
            businessDetail
            shareholders
            SignUpAddressPersonalDetails
            SignUpFinancialDetails
            SignUpIndividualPersonalDetails
            transactionsInfo
            businessType
            author
          }
          readNotifications
          readOnboardings
          amountsSent {
            daily
            monthly
          }
          profileImage
          paymentMethods {
            last4
            scheme
            provider
            customerId
            expiryMonth
            id
            expiryYear
            type
          }
          companyInfo {
            tradingName
            primaryHolderName
            industryType
            registeredAddress
            businessDescription
            annualTurnover
            stablishmentDate
            tradingStartDate
            transactionVolumen
            internationalActivity
            kybCompanyCode
          }
          pushNotificationsConfig {
            token
            endpointArn
          }
          smsMarketingConsent
          pushMarketingConsent
          emailMarketingConsent
          smsAllowed
          pushAllowed
          emailAllowed
          forbiddenCurrency {
            id
            iso
            active
            name
            walletBgColor
            walletTextColor
            order
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          intercomProfileUrl
          accountStatus
          type
          passwordResetAt
          passwordResetBy
          customerId
          businessDetail
          shareholders
          SignUpAddressPersonalDetails
          SignUpFinancialDetails
          SignUpIndividualPersonalDetails
          transactionsInfo
          businessType
          author
        }
        readNotifications
        readOnboardings
        amountsSent {
          daily
          monthly
        }
        profileImage
        paymentMethods {
          last4
          scheme
          provider
          customerId
          expiryMonth
          id
          expiryYear
          type
        }
        companyInfo {
          tradingName
          primaryHolderName
          industryType
          registeredAddress
          businessDescription
          annualTurnover
          stablishmentDate
          tradingStartDate
          transactionVolumen
          shareholders {
            name
            lastName
            birthdate
            country
            address
            city
            postalCode
            email
            phoneCode
            phoneNumber
          }
          internationalActivity
          kybCompanyCode
        }
        pushNotificationsConfig {
          token
          endpointArn
        }
        smsMarketingConsent
        pushMarketingConsent
        emailMarketingConsent
        smsAllowed
        pushAllowed
        emailAllowed
        forbiddenCurrency {
          id
          iso
          active
          name
          walletBgColor
          walletTextColor
          order
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        intercomProfileUrl
        accountStatus
        type
        passwordResetAt
        passwordResetBy
        customerId
        businessDetail
        shareholders
        SignUpAddressPersonalDetails
        SignUpFinancialDetails
        SignUpIndividualPersonalDetails
        transactionsInfo
        businessType
        author
      }
      notificationUserId
      notifSendApproach
      meta
      createdAt
      updatedAt
    }
  }
`;
export const createMembership = /* GraphQL */ `
  mutation CreateMembership($input: CreateMembershipInput) {
    createMembership(input: $input) {
      id
      name
      price
      dailyLimit
      monthlyLimit
      createdAt
      updatedAt
    }
  }
`;
export const updateMembership = /* GraphQL */ `
  mutation UpdateMembership($input: UpdateMembershipInput) {
    updateMembership(input: $input) {
      id
      name
      price
      dailyLimit
      monthlyLimit
      createdAt
      updatedAt
    }
  }
`;
export const deleteMembership = /* GraphQL */ `
  mutation DeleteMembership($input: DeleteMembershipInput) {
    deleteMembership(input: $input) {
      id
      name
      price
      dailyLimit
      monthlyLimit
      createdAt
      updatedAt
    }
  }
`;
export const createRegion = /* GraphQL */ `
  mutation CreateRegion($input: CreateRegionInput) {
    createRegion(input: $input) {
      id
      name
      createdAt
      updatedAt
    }
  }
`;
export const updateRegion = /* GraphQL */ `
  mutation UpdateRegion($input: UpdateRegionInput) {
    updateRegion(input: $input) {
      id
      name
      createdAt
      updatedAt
    }
  }
`;
export const deleteRegion = /* GraphQL */ `
  mutation DeleteRegion($input: DeleteRegionInput) {
    deleteRegion(input: $input) {
      id
      name
      createdAt
      updatedAt
    }
  }
`;
export const createUserNote = /* GraphQL */ `
  mutation CreateUserNote($input: CreateUserNoteInput) {
    createUserNote(input: $input) {
      id
      userId
      adminId
      timestamp
      adminName
      note
      createdAt
      updatedAt
    }
  }
`;
export const updateUserNote = /* GraphQL */ `
  mutation UpdateUserNote($input: UpdateUserNoteInput) {
    updateUserNote(input: $input) {
      id
      userId
      adminId
      timestamp
      adminName
      note
      createdAt
      updatedAt
    }
  }
`;
export const deleteUserNote = /* GraphQL */ `
  mutation DeleteUserNote($input: DeleteUserNoteInput) {
    deleteUserNote(input: $input) {
      id
      userId
      adminId
      timestamp
      adminName
      note
      createdAt
      updatedAt
    }
  }
`;
export const createUserLog = /* GraphQL */ `
  mutation CreateUserLog($input: CreateUserLogInput) {
    createUserLog(input: $input) {
      id
      timestamp
      platform
      location
      ip
      createdAt
      updatedAt
    }
  }
`;
export const updateUserProfile = /* GraphQL */ `
  mutation UpdateUserProfile($input: BoUpdateUserProfileInput) {
    updateUserProfile(input: $input) {
      id
      accountType
      membership {
        id
        name
        price
        dailyLimit
        monthlyLimit
        createdAt
        updatedAt
      }
      name
      lastName
      birthdate
      country
      address
      city
      postalCode
      email
      emailVerified
      phoneCode
      phoneNumber
      phoneNumberVerified
      kyc {
        status
        result
        caseId
        applicantId
        reason
      }
      beneficiaries {
        id
        status
        userId
        accountType
        name
        lastName
        country {
          id
          iso
          iso2
          region {
            id
            name
            createdAt
            updatedAt
          }
          defaultCurrency {
            id
            iso
            active
            name
            walletBgColor
            walletTextColor
            order
            createdAt
            updatedAt
          }
          currency {
            id
            iso
            active
            name
            walletBgColor
            walletTextColor
            order
            createdAt
            updatedAt
          }
          active
          name
          phoneCode
          order
          companyRequirements
          personalRequirements
          createdAt
          updatedAt
        }
        address
        city
        postalCode
        email
        phoneCode
        phoneNumber
        currency {
          id
          iso
          active
          name
          walletBgColor
          walletTextColor
          order
          createdAt
          updatedAt
        }
        bankDetails {
          name
          value
        }
        userProfileId
        usageLevel
        skipInternalCheck
        currencyRequirementType
        beneficiaryCountryId
        beneficiaryCurrencyId
      }
      externalBankAccounts {
        name
        number
        iban
        swift
        sort
      }
      poundsAccount {
        id
        name
        label
        type
        currency
        iban
        bban
        balance
        lastTx
      }
      invitedBy {
        id
        accountType
        membership {
          id
          name
          price
          dailyLimit
          monthlyLimit
          createdAt
          updatedAt
        }
        name
        lastName
        birthdate
        country
        address
        city
        postalCode
        email
        emailVerified
        phoneCode
        phoneNumber
        phoneNumberVerified
        kyc {
          status
          result
          caseId
          applicantId
          reason
        }
        beneficiaries {
          id
          status
          userId
          accountType
          name
          lastName
          country {
            id
            iso
            iso2
            active
            name
            phoneCode
            order
            companyRequirements
            personalRequirements
            createdAt
            updatedAt
          }
          address
          city
          postalCode
          email
          phoneCode
          phoneNumber
          currency {
            id
            iso
            active
            name
            walletBgColor
            walletTextColor
            order
            createdAt
            updatedAt
          }
          bankDetails {
            name
            value
          }
          userProfileId
          usageLevel
          skipInternalCheck
          currencyRequirementType
          beneficiaryCountryId
          beneficiaryCurrencyId
        }
        externalBankAccounts {
          name
          number
          iban
          swift
          sort
        }
        poundsAccount {
          id
          name
          label
          type
          currency
          iban
          bban
          balance
          lastTx
        }
        invitedBy {
          id
          accountType
          membership {
            id
            name
            price
            dailyLimit
            monthlyLimit
            createdAt
            updatedAt
          }
          name
          lastName
          birthdate
          country
          address
          city
          postalCode
          email
          emailVerified
          phoneCode
          phoneNumber
          phoneNumberVerified
          kyc {
            status
            result
            caseId
            applicantId
            reason
          }
          beneficiaries {
            id
            status
            userId
            accountType
            name
            lastName
            address
            city
            postalCode
            email
            phoneCode
            phoneNumber
            userProfileId
            usageLevel
            skipInternalCheck
            currencyRequirementType
            beneficiaryCountryId
            beneficiaryCurrencyId
          }
          externalBankAccounts {
            name
            number
            iban
            swift
            sort
          }
          poundsAccount {
            id
            name
            label
            type
            currency
            iban
            bban
            balance
            lastTx
          }
          invitedBy {
            id
            accountType
            name
            lastName
            birthdate
            country
            address
            city
            postalCode
            email
            emailVerified
            phoneCode
            phoneNumber
            phoneNumberVerified
            readNotifications
            readOnboardings
            profileImage
            smsMarketingConsent
            pushMarketingConsent
            emailMarketingConsent
            smsAllowed
            pushAllowed
            emailAllowed
            createdAt
            updatedAt
            intercomProfileUrl
            accountStatus
            type
            passwordResetAt
            passwordResetBy
            customerId
            businessDetail
            shareholders
            SignUpAddressPersonalDetails
            SignUpFinancialDetails
            SignUpIndividualPersonalDetails
            transactionsInfo
            businessType
            author
          }
          readNotifications
          readOnboardings
          amountsSent {
            daily
            monthly
          }
          profileImage
          paymentMethods {
            last4
            scheme
            provider
            customerId
            expiryMonth
            id
            expiryYear
            type
          }
          companyInfo {
            tradingName
            primaryHolderName
            industryType
            registeredAddress
            businessDescription
            annualTurnover
            stablishmentDate
            tradingStartDate
            transactionVolumen
            internationalActivity
            kybCompanyCode
          }
          pushNotificationsConfig {
            token
            endpointArn
          }
          smsMarketingConsent
          pushMarketingConsent
          emailMarketingConsent
          smsAllowed
          pushAllowed
          emailAllowed
          forbiddenCurrency {
            id
            iso
            active
            name
            walletBgColor
            walletTextColor
            order
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          intercomProfileUrl
          accountStatus
          type
          passwordResetAt
          passwordResetBy
          customerId
          businessDetail
          shareholders
          SignUpAddressPersonalDetails
          SignUpFinancialDetails
          SignUpIndividualPersonalDetails
          transactionsInfo
          businessType
          author
        }
        readNotifications
        readOnboardings
        amountsSent {
          daily
          monthly
        }
        profileImage
        paymentMethods {
          last4
          scheme
          provider
          customerId
          expiryMonth
          id
          expiryYear
          type
        }
        companyInfo {
          tradingName
          primaryHolderName
          industryType
          registeredAddress
          businessDescription
          annualTurnover
          stablishmentDate
          tradingStartDate
          transactionVolumen
          shareholders {
            name
            lastName
            birthdate
            country
            address
            city
            postalCode
            email
            phoneCode
            phoneNumber
          }
          internationalActivity
          kybCompanyCode
        }
        pushNotificationsConfig {
          token
          endpointArn
        }
        smsMarketingConsent
        pushMarketingConsent
        emailMarketingConsent
        smsAllowed
        pushAllowed
        emailAllowed
        forbiddenCurrency {
          id
          iso
          active
          name
          walletBgColor
          walletTextColor
          order
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        intercomProfileUrl
        accountStatus
        type
        passwordResetAt
        passwordResetBy
        customerId
        businessDetail
        shareholders
        SignUpAddressPersonalDetails
        SignUpFinancialDetails
        SignUpIndividualPersonalDetails
        transactionsInfo
        businessType
        author
      }
      readNotifications
      readOnboardings
      amountsSent {
        daily
        monthly
      }
      profileImage
      paymentMethods {
        last4
        scheme
        provider
        customerId
        expiryMonth
        id
        expiryYear
        type
      }
      companyInfo {
        tradingName
        primaryHolderName
        industryType
        registeredAddress
        businessDescription
        annualTurnover
        stablishmentDate
        tradingStartDate
        transactionVolumen
        shareholders {
          name
          lastName
          birthdate
          country
          address
          city
          postalCode
          email
          phoneCode
          phoneNumber
        }
        internationalActivity
        kybCompanyCode
      }
      pushNotificationsConfig {
        token
        endpointArn
      }
      smsMarketingConsent
      pushMarketingConsent
      emailMarketingConsent
      smsAllowed
      pushAllowed
      emailAllowed
      forbiddenCurrency {
        id
        iso
        active
        name
        walletBgColor
        walletTextColor
        order
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      intercomProfileUrl
      accountStatus
      type
      passwordResetAt
      passwordResetBy
      customerId
      businessDetail
      shareholders
      SignUpAddressPersonalDetails
      SignUpFinancialDetails
      SignUpIndividualPersonalDetails
      transactionsInfo
      businessType
      author
    }
  }
`;
export const adminResetPassword = /* GraphQL */ `
  mutation AdminResetPassword($userId: String) {
    adminResetPassword(userId: $userId) {
      passwordResetAt
    }
  }
`;
export const sendSMSNotification = /* GraphQL */ `
  mutation SendSMSNotification($input: BackofficeSendSmsNotification) {
    sendSMSNotification(input: $input) {
      error
      messageId
      success
    }
  }
`;
export const setUserWalletStatus = /* GraphQL */ `
  mutation SetUserWalletStatus($input: SetUserWalletStatusInput) {
    setUserWalletStatus(input: $input) {
      id
      currency
      balance
      history
      routingNumber
      accountNumber
      bankAddress
      bankName
      status
      type
      iban
    }
  }
`;
export const createEmailTemplate = /* GraphQL */ `
  mutation CreateEmailTemplate($input: EmailTemplateInput) {
    createEmailTemplate(input: $input) {
      success
    }
  }
`;
export const editEmailTemplate = /* GraphQL */ `
  mutation EditEmailTemplate($input: EmailTemplateInput) {
    editEmailTemplate(input: $input) {
      success
    }
  }
`;
export const removeEmailTemplate = /* GraphQL */ `
  mutation RemoveEmailTemplate($templateName: String) {
    removeEmailTemplate(templateName: $templateName) {
      success
      error
    }
  }
`;
export const sendEmailTemplate = /* GraphQL */ `
  mutation SendEmailTemplate(
    $templateName: String
    $sendTo: String
    $htmlPart: String
  ) {
    sendEmailTemplate(
      templateName: $templateName
      sendTo: $sendTo
      htmlPart: $htmlPart
    ) {
      success
      error
    }
  }
`;
export const renderEmailTemplate = /* GraphQL */ `
  mutation RenderEmailTemplate($templateName: String, $sendTo: String) {
    renderEmailTemplate(templateName: $templateName, sendTo: $sendTo) {
      renderedTemplate
      success
    }
  }
`;
export const closeAccount = /* GraphQL */ `
  mutation CloseAccount($id: String!) {
    closeAccount(id: $id) {
      success
      error
    }
  }
`;
export const createUser = /* GraphQL */ `
  mutation CreateUser($input: CreateUserInput) {
    createUser(input: $input) {
      success
      error
    }
  }
`;
export const modifyUser = /* GraphQL */ `
  mutation ModifyUser($input: ModifyUserInput) {
    modifyUser(input: $input) {
      success
    }
  }
`;
export const removeUser = /* GraphQL */ `
  mutation RemoveUser($userName: String) {
    removeUser(userName: $userName) {
      success
    }
  }
`;
export const makeFXOrder = /* GraphQL */ `
  mutation MakeFXOrder($input: MakeFXOrderInput) {
    makeFXOrder(input: $input) {
      success
      error
    }
  }
`;
export const makeHVIPayment = /* GraphQL */ `
  mutation MakeHVIPayment($input: MakeHVIPayment) {
    makeHVIPayment(input: $input) {
      success
      error
    }
  }
`;
export const createPlansHeader = /* GraphQL */ `
  mutation CreatePlansHeader(
    $name: String!
    $description: String
    $price: String
    $parts: [InputPart]
  ) {
    createPlansHeader(
      name: $name
      description: $description
      price: $price
      parts: $parts
    ) {
      id
      name
      description
      price
      parts {
        name
        keyValues {
          key
          value
        }
      }
    }
  }
`;
export const updatePlansHeader = /* GraphQL */ `
  mutation UpdatePlansHeader(
    $id: ID!
    $name: String
    $description: String
    $price: String
    $parts: [InputPart]
  ) {
    updatePlansHeader(
      id: $id
      name: $name
      description: $description
      price: $price
      parts: $parts
    ) {
      id
      name
      description
      price
      parts {
        name
        keyValues {
          key
          value
        }
      }
    }
  }
`;
export const deletePlansHeader = /* GraphQL */ `
  mutation DeletePlansHeader($id: ID!) {
    deletePlansHeader(id: $id) {
      id
      name
      description
      price
      parts {
        name
        keyValues {
          key
          value
        }
      }
    }
  }
`;
export const newCloseAccount = /* GraphQL */ `
  mutation NewCloseAccount($input: CloseAccountInput) {
    newCloseAccount(input: $input) {
      message
      success
    }
  }
`;
export const kybGenerateIncorporationCompany = /* GraphQL */ `
  mutation KybGenerateIncorporationCompany(
    $companyId: String
    $countryCode: String
  ) {
    kybGenerateIncorporationCompany(
      companyId: $companyId
      countryCode: $countryCode
    ) {
      content
      filename
      contentType
      size
    }
  }
`;
