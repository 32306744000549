import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(theme => ({
  tableRow: {
    background: 'white',
    border: 'none',
    '&:hover': {
      background: theme.palette.primary.light,
    },
    color: theme.palette.icons.nav,
  },
}));
