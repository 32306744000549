import { createTheme } from '@mui/material';

import { overrides } from './overrides';
import { palette } from './palette';
import { typography } from './typography';

export const theme = createTheme({
  palette,
  typography,
  overrides,
  zIndex: {
    appBar: 1200,
    drawer: 1100,
  },
  borderRadius: '0 1.5rem 1.5rem 1.5rem',
  boxShadow: '0 0.3rem 0.6rem 0 rgba(0, 0, 0, 0.08)',
});
