import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(theme => ({
  label: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginRight: '16px',
    '& aside': {
      display: 'flex',
      flexDirection: 'row',
      gap: '8px',
      alignItems: 'center',
    },
    '& button': {
      border: 'none',
      backgroundColor: 'white',
      borderRadius: '16px',
      cursor: 'pointer',
    },
  },
  infoContainer: {
    fontSize: '12px',
    margin: '16px 16px 0px 16px',
    '& input[type="text"]': {
      height: '32px',
      border: '1px solid #f2f2f4',
      padding: '8px',
      borderRadius: '8px',
      color: theme.palette.icons.nav,
      fontWeight: 'bold',
    },
    '& input[type="text"]:focus, textarea': {
      outline: 'none !important',
    },
    '& > section': {
      display: 'flex',
      flexDirection: 'row',
      gap: '16px',
      alignItems: 'center',
    },
  },
  labelAndInput: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
    marginTop: '13px',
    width: '50%',
    '& input[type="date"]': {
      height: '32px',
      border: '1px solid #f2f2f4',
      padding: '8px',
      borderRadius: '8px',
      color: theme.palette.icons.nav,
      fontFamily: theme.typography.default,
      fontWeight: '500',
      cursor: 'pointer',
    },
  },
  countryAvatar: {
    position: 'absolute',
    top: '30px',
    right: '16px',
  },
  fullWidth: {
    width: '100%',
  },
  dashedLine: {
    borderBottom: '1px solid #a5a6ae',
    borderBottomStyle: 'dashed',
    marginTop: '40px',
    marginBottom: '33px',
  },
  resetBtn: {
    right: '16px',
    bottom: '16px',
    width: '128px',
    height: '32px',
    borderRadius: '16px',
    backgroundColor: '#c6ffef',
    padding: '8px',
    border: 'none',
  },
  resetPwInstructions: {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
    '& h3': {
      fontSize: '16px',
    },
    '& p': {
      fontSize: '14px',
    },
    marginBottom: '24px',
  },
  resetPwBtnContainer: {
    display: 'flex',
    flexDirection: 'row-reverse',
    '& button': {
      cursor: 'pointer',
    },
  },
}));
