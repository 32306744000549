import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
  container: {
    padding: '16px 0',
    backgroundColor: 'white',
    borderRadius: '0px 16px 16px 16px',
    minHeight: '600px',
  },
  headerRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    '& aside': {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
  },
  label: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  searchInput: {
    height: '32px',
    width: '50%',
    borderRadius: '16px',
    border: '1px solid #e6e6e6',
    padding: '4px 16px',
    marginLeft: '64px',
  },
  noOfMembersSelectedContainer: {
    marginRight: '36px',
  },
  adminListTableContainer: {
    padding: '16px 16px 54px 16px',
  },
  saveBtn: {
    height: '32px',
    width: '32px',
    backgroundColor: '#c6ffef',
    borderRadius: '32px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    marginLeft: '24px',
    border: 'none',
  },
}));
