import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(theme => ({
  backButtonText: {
    color: theme.palette.primary.dark,
  },
  backArrow: {
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.primary.dark,
    '& .MuiSvgIcon-root': {
      width: '2.4rem',
      height: '2.4rem',
    },
  },
  backButton: {
    minWidth: '10.2rem',
  },
}));
