import React from 'react';

export const DashedLine = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="369" height="1" viewBox="0 0 369 1">
    <path
      data-name="Path 19458"
      d="M-1087 1598h368"
      transform="translate(1087.5 -1597.5)"
      style={{ fill: 'none', stroke: '#a5a6ae', strokeLinecap: 'round', strokeDasharray: '1 4' }}
    />
  </svg>
);
