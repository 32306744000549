import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import { DottedLineIcon, FilterIcon } from '../../../../components/icons';
import { entitiesEnum } from '../../../../constants';
import { checkIfEveryPropIsFalsy, todayDate } from '../../../../utils';
import { useStyles } from './index';

export const TransactionFilterModal = ({
  onClose,
  searchTransactions,
  entity = entitiesEnum.TRANSACTION,
  isSearchMode,
}) => {
  const classes = useStyles();
  const { register, handleSubmit } = useForm();
  const [completedFilter, setCompletedFilter] = useState(false);
  const [failedFilter, setFailedFilter] = useState(false);
  const [processingFilter, setProcessingFilter] = useState(false);
  const [filterBtnActive, setFilterBtnActive] = useState(false);
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');
  const [incoming, setIncoming] = useState(false);
  const [outgoing, setOutgoing] = useState(false);
  const [exchange, setExchange] = useState(false);
  const [minAmount, setMinAmount] = useState('');
  const [maxAmount, setMaxAmount] = useState('');
  const [showRangeError, setShowRangeError] = useState(false);
  const dispatch = useDispatch();
  const transactionFilterObj = useSelector(state => state.transactionFilterObj);
  const userTransactionFilterObj = useSelector(state => state.userTransactionFilterObj);
  const isFiltered = obj => Object.keys(obj).length > 0;

  let filterObj = {};

  if (entity === entitiesEnum.TRANSACTION) filterObj = transactionFilterObj;
  if (entity === 'userTransactions') filterObj = userTransactionFilterObj;

  useEffect(() => {
    const handleFilterBtnActive = () => {
      const formValues = {
        completedFilter,
        failedFilter,
        processingFilter,
        fromDate,
        toDate,
        incoming,
        outgoing,
        exchange,
        minAmount,
        maxAmount,
      };

      setFilterBtnActive(!checkIfEveryPropIsFalsy(formValues));
      if (!isSearchMode && (!toDate || !fromDate)) setFilterBtnActive(false);
      if (showRangeError) setFilterBtnActive(false);
      if (entity === 'userTransactions') setFilterBtnActive(!checkIfEveryPropIsFalsy(formValues));
    };
    handleFilterBtnActive();
  }, [
    completedFilter,
    failedFilter,
    processingFilter,
    fromDate,
    toDate,
    incoming,
    outgoing,
    exchange,
    minAmount,
    maxAmount,
    showRangeError,
  ]);

  useEffect(() => {
    if (isFiltered(filterObj)) {
      setFromDate(filterObj.fromDate);
      setToDate(filterObj.toDate);
      setIncoming(filterObj.incoming);
      setOutgoing(filterObj.outgoing);
      setExchange(filterObj.exchange);
      setCompletedFilter(filterObj.statusFilters.completedFilter);
      setFailedFilter(filterObj.statusFilters.failedFilter);
      setProcessingFilter(filterObj.statusFilters.processingFilter);
      setMinAmount(filterObj.minAmount);
      setMaxAmount(filterObj.maxAmount);
    }
  }, []);

  const maxToDate = () => {
    if (toDate) return toDate;

    return todayDate();
  };

  useEffect(() => {
    const timeDifference = new Date(toDate) - new Date(fromDate);
    const ONEDAY = 86400000;
    if (timeDifference && timeDifference > 7 * ONEDAY && !isSearchMode) {
      setShowRangeError(true);
    } else {
      setShowRangeError(false);
    }
  }, [toDate, fromDate]);

  return (
    <div className={classes.transactionFilterModalContainer}>
      <div className={classes.modalTitleContainer}>Filter</div>
      <DottedLineIcon />
      <form
        onSubmit={handleSubmit(data => {
          const statusFilters = { completedFilter, failedFilter, processingFilter };
          const newData = { ...data, statusFilters };
          if (entity === entitiesEnum.TRANSACTION) {
            if (fromDate && !isSearchMode) searchTransactions(newData);
            dispatch({ type: 'SET_TRANSACTION_FILTER_OBJ', payload: newData });
          }
          if (entity === 'userTransactions') {
            dispatch({ type: 'SET_USER_TRANSACTION_FILTER_OBJ', payload: newData });
          }
          onClose();
        })}
      >
        <div className={classes.filterRowContainer}>
          <div>Date</div>
          <div className={classes.transactionInputContainer}>
            <p className={fromDate && classes.highlightColor}>From:</p>
            <input
              {...register('fromDate')}
              onChange={event => {
                setFromDate(event.target.value);
              }}
              className={classes.inputContainer}
              type="date"
              id="from_date"
              value={fromDate || filterObj.fromDate || ''}
              max={maxToDate()}
            />
          </div>
          <div className={classes.transactionInputContainer}>
            <p className={toDate && classes.highlightColor}>To:</p>
            <input
              {...register('toDate')}
              onChange={event => {
                setToDate(event.target.value);
              }}
              className={classes.inputContainer}
              type="date"
              id="to_date"
              value={toDate || filterObj.toDate || ''}
              min={fromDate || ''}
              max={todayDate()}
            />
          </div>
        </div>
        {showRangeError && (
          <p style={{ color: 'red', marginTop: '-10px' }}>
            Date range must have max range of 1 week in default mode
          </p>
        )}
        <DottedLineIcon />
        <div className={classes.filterRowContainer}>
          <div className={classes.transactionInputContainer}>
            <input
              {...register('incoming')}
              onChange={() => {
                setIncoming(!incoming);
              }}
              className={classes.checkbox}
              type="checkbox"
              id="incoming"
              checked={incoming}
            />
            <aside>Incoming</aside>
          </div>
          <div className={classes.transactionInputContainer}>
            <input
              {...register('outgoing')}
              onChange={() => {
                setOutgoing(!outgoing);
              }}
              className={classes.checkbox}
              type="checkbox"
              id="outgoing"
              checked={outgoing}
            />
            <aside>Outgoing</aside>
          </div>
          <div className={classes.transactionInputContainer}>
            <input
              {...register('exchange')}
              onChange={() => {
                setExchange(!exchange);
              }}
              className={classes.checkbox}
              type="checkbox"
              id="exchange"
              checked={exchange}
            />
            <aside>Exchange</aside>
          </div>
        </div>
        <DottedLineIcon />
        <div className={classes.filterRowContainer}>
          <div>Status</div>
          <div className={`${classes.transactionInputContainer} ${classes.statusGap}`}>
            <div
              className={
                completedFilter
                  ? `${classes.statusNotClicked} ${classes.statusCompletedSelected}`
                  : classes.statusNotClicked
              }
              onClick={() => {
                setCompletedFilter(!completedFilter);
              }}
            >
              Completed
            </div>
            <div
              className={
                failedFilter
                  ? `${classes.statusNotClicked} ${classes.statusFailedSelected}`
                  : classes.statusNotClicked
              }
              onClick={() => {
                setFailedFilter(!failedFilter);
              }}
            >
              Failed
            </div>
            <div
              className={
                processingFilter
                  ? `${classes.statusNotClicked} ${classes.statusProcessingSelected}`
                  : classes.statusNotClicked
              }
              onClick={() => {
                setProcessingFilter(!processingFilter);
              }}
            >
              Processing
            </div>
          </div>
        </div>
        <DottedLineIcon />
        <div className={classes.filterRowContainer}>
          <div>Amount</div>
          <div className={classes.transactionInputContainer}>
            <p className={minAmount && classes.highlightColor}>From:</p>
            <input
              {...register('minAmount')}
              className={classes.inputContainer}
              onChange={event => {
                setMinAmount(event.target.value);
              }}
              type="number"
              id="minAmount"
              value={minAmount && minAmount}
              placeholder={!minAmount ? 'Min Amount' : undefined}
            />
          </div>
          <div className={classes.transactionInputContainer}>
            <p className={maxAmount && classes.highlightColor}>To:</p>
            <input
              {...register('maxAmount')}
              className={classes.inputContainer}
              onChange={event => {
                setMaxAmount(event.target.value);
              }}
              type="number"
              id="maxAmount"
              value={maxAmount && maxAmount}
              placeholder={!maxAmount ? 'Max Amount' : undefined}
              min={minAmount || '0'}
            />
          </div>
        </div>
        <DottedLineIcon />
        <div />
        <button
          type="submit"
          disabled={!filterBtnActive}
          className={
            filterBtnActive
              ? classes.filterItBtn
              : `${classes.filterItBtn} ${classes.filterItBtnDisabled}`
          }
        >
          <div className={classes.filterIconSubmit}>
            <FilterIcon color="#ffffff" />
          </div>
          <p className={classes.filterItLabel}>Filter It</p>
          <div />
        </button>
      </form>
    </div>
  );
};
