import React from 'react';

export const TotalNumberOfRejects = ({ width = '26', height = '26', color = '#1fe3ac' }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40">
    <g data-name="Group 10341">
      <g data-name="Group 10306">
        <g data-name="Group 10296" transform="translate(-328 -504)">
          <rect
            data-name="Rectangle 16974"
            width="40"
            height="40"
            rx="8"
            transform="translate(328 504)"
            fill="#ff6d6d"
          />
        </g>
      </g>
      <g data-name="Group 10300">
        <path
          data-name="Union 975"
          d="m16.015 18.21-6.682-6.682-6.682 6.682a1.553 1.553 0 0 1-2.2-2.2l6.686-6.678-6.681-6.68a1.553 1.553 0 0 1 2.2-2.2l6.677 6.684L16.014.455a1.553 1.553 0 0 1 2.2 2.2l-6.68 6.68 6.682 6.682a1.553 1.553 0 1 1-2.2 2.2z"
          transform="translate(10.474 10.475)"
          fill="#fff"
        />
      </g>
    </g>
  </svg>
);
