import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
  buttonsWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    minWidth: '40%',
    width: '100%',
  },
  buttonDiv: {
    margin: '0 0.5rem',
    position: 'relative',
  },
}));
