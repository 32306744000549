import React from 'react';

import { ActiveTickIcon } from '../../../../components/icons';
import { useStyles } from './password-validation.hooks';

export const PasswordValidation = ({ password }) => {
  const classes = useStyles();

  return (
    <section className={classes.root}>
      <div>
        <ActiveTickIcon active={password.length > 7} />
        <p>At least 8 characters</p>
      </div>
      <div>
        <ActiveTickIcon active={!!password.match(/[A-Z]/g)} />
        <p>At least one uppercase letter</p>
      </div>
      <div>
        <ActiveTickIcon active={!!password.match(/[a-z]/g)} />
        <p>At least one lowercase letter</p>
      </div>
      <div>
        <ActiveTickIcon active={!!password.match(/[0-9]/g)} />
        <p>At least one number</p>
      </div>
      <div>
        <ActiveTickIcon active={!!password.match(/[^a-zA-Z\d\s:]/)} />
        <p>At least one symbol</p>
      </div>
      <div>
        <ActiveTickIcon active={!password.match(/\s/g)} />
        <p>No spaces</p>
      </div>
    </section>
  );
};
