import React from 'react';

export const ExclamationIcon = ({ active = true }) => {
  const fillColor = active ? '#1fe3ac' : '#a5a6ae';

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="66" height="66" viewBox="0 0 66 66">
      <g data-name="Group 9763" transform="translate(-190 -259.16)">
        <circle
          data-name="Ellipse 1675"
          cx="33"
          cy="33"
          r="33"
          transform="translate(190 259.16)"
          fill="#0cc5ff"
        />
        <path
          data-name="Path 19309"
          d="M20.29 2a18.29 18.29 0 1 0 18.29 18.29A18.29 18.29 0 0 0 20.29 2zm0 32.922A14.632 14.632 0 1 1 34.922 20.29 14.632 14.632 0 0 1 20.29 34.922zm0-15.547a1.829 1.829 0 0 0-1.829 1.825v5.487a1.829 1.829 0 1 0 3.658 0V21.2a1.829 1.829 0 0 0-1.829-1.824zm0-7.316a2.286 2.286 0 1 0 2.286 2.286 2.286 2.286 0 0 0-2.286-2.285z"
          transform="translate(203.366 272.396)"
          fill="#fff"
        />
      </g>
    </svg>
  );
};
