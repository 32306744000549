import React from 'react';

export const ArrowDownIcon = ({ width = '9.414', height = '5.207' }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 9.414 5.207">
    <path
      data-name="Path 19522"
      d="m1768.959 96.345 4 4 4-4"
      transform="translate(-1768.252 -95.637)"
      style={{ fill: 'none', stroke: '#19365e', strokeLinecap: 'round', strokeLinejoin: 'round' }}
    />
  </svg>
);
