import React from 'react';

export const MulticurrencyIcon = ({ className, color }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 32 32">
    <path
      fill={color}
      className={className}
      data-name="Union 959"
      d="M0 16a16 16 0 1 1 16 16A16 16 0 0 1 0 16zm1.832 0A14.168 14.168 0 1 0 16 1.832 14.169 14.169 0 0 0 1.832 16zM17 21.5a4.5 4.5 0 1 1 4.5 4.5 4.5 4.5 0 0 1-4.5-4.5zm1.7 0a2.8 2.8 0 1 0 2.8-2.8 2.8 2.8 0 0 0-2.8 2.8zM6 21.5a4.5 4.5 0 1 1 4.5 4.5A4.5 4.5 0 0 1 6 21.5zm1.7 0a2.8 2.8 0 1 0 2.8-2.8 2.8 2.8 0 0 0-2.8 2.8zm9.3-10a4.5 4.5 0 1 1 4.5 4.5 4.5 4.5 0 0 1-4.5-4.5zm1.7 0a2.8 2.8 0 1 0 2.8-2.8 2.8 2.8 0 0 0-2.8 2.8zM6 11.5a4.5 4.5 0 1 1 4.5 4.5A4.5 4.5 0 0 1 6 11.5zm1.7 0a2.8 2.8 0 1 0 2.8-2.8 2.8 2.8 0 0 0-2.8 2.8z"
    />
  </svg>
);
