import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(theme => ({
  card: {
    minHeight: '9rem',
    borderRadius: `${theme.borderRadius} !important`,
    padding: '2.5rem 1rem 2rem',
    boxShadow: theme.boxShadow,
    marginBottom: '2.1rem',
    position: 'relative',
    overflow: 'hidden',
    '&:before': {
      backgroundColor: theme.palette.white,
    },
    '& .Mui-expanded': {
      minHeight: 'unset',
    },
    '& .MuiAccordionSummary-content.Mui-expanded': {
      margin: '1.2rem 0',
    },
  },
  cardHeader: {
    minHeight: 'unset',
    '& .MuiSvgIcon-root': {
      width: '4rem',
      height: '4rem',
      fill: theme.palette.primary.nav,
    },
    '& p': {
      fontSize: '2.4rem',
    },
  },
  colorBox: {
    position: 'absolute',
    width: '2rem',
    height: '5rem',
    top: '-0.5rem',
    left: '-2.2rem',
    borderRadius: '1rem',
  },
  disabledCard: {
    position: 'absolute',
    padding: '0.5rem',
    right: '7rem',
    fontSize: '1rem',
    fontFamily: theme.typography.default,
    color: 'white',
    backgroundColor: theme.palette.error.light,
    borderRadius: '0.8rem',
  },
}));
