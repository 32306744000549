import { IconButton, Tooltip } from '@mui/material';
import React from 'react';

import { CompanyHouse } from './components';

export const CompanyHouseButton = ({
  profileUrl,
  title = `${profileUrl ? 'View on Companies house' : 'Companies house link not found'} `,
}) => {
  const handleOnClick = event => {
    event.stopPropagation();
    if (profileUrl) {
      window.open(profileUrl, '_blank');
    }
  };

  return (
    <Tooltip title={title} placement="top">
      <span>
        <IconButton onClick={handleOnClick} size="large">
          <CompanyHouse />
        </IconButton>
      </span>
    </Tooltip>
  );
};
