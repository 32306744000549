import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(theme => ({
  monitoringContainer: {
    backgroundColor: 'white',
    width: '100%',
    height: 'auto',
    maxHeight: "700px",
    overflowX:"scroll",
    borderRadius: '0 15px 15px 15px',
    fontFamily: theme.typography.default,
    color: theme.palette.icons.nav,
  },
  headerContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: '16px',
    paddingTop: '25px',
    fontSize: '24px',
    marginBottom: "8px",
    fontFamily: theme.typography.default,
    fontWeight: 'bold',
    color: theme.palette.icons.nav,
  },
  monitoringBody: {
    padding: "10px",
    fontSize: "16px"
  }
}));
