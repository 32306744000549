import React from 'react';
import copyIcon from '../../../../assets/icons/copyIcon.svg';

import { useStyles } from '../multicurrency.hooks';

export const LoadButton = ({ onClick }) => {
  const classes = useStyles();

  return (
    <button className={classes.loadButton} type="button" onClick={onClick}>
      Load
    </button>
  );
};
