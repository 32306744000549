import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
  header: {
    borderRadius: '0.4rem',
    padding: '0.5rem',
  },
  cardNoShadow: {
    boxShadow: 'none',
  },
  backButtonWrapper: {
    marginRight: '1.6rem',
    display: 'flex',
    justifyContent: 'center',
  },
}));
