import React from 'react';

import * as Countries from './index';

// Returns the country icon from 3 letter currency code
export const ReturnCountryIcon = ({ country }) => {
  const CountryIcon = Countries[country];
  if (!Countries[country]) return <div />;

  return <CountryIcon />;
};
