import React from 'react';

export const CopyToClipBoardIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="12.396"
    height="12.396"
    viewBox="0 0 12.396 12.396"
  >
    <g data-name="Icon feather-copy">
      <path
        data-name="Path 19528"
        d="M14.64 13.5h5.128a1.14 1.14 0 0 1 1.14 1.14v5.128a1.14 1.14 0 0 1-1.14 1.14H14.64a1.14 1.14 0 0 1-1.14-1.14V14.64a1.14 1.14 0 0 1 1.14-1.14z"
        transform="translate(-9.011 -9.011)"
        style={{ fill: 'none', stroke: '#19365e', strokeLinecap: 'round', strokeLinejoin: 'round' }}
      />
      <path
        data-name="Path 19529"
        d="M4.709 10.408H4.14A1.14 1.14 0 0 1 3 9.268V4.14A1.14 1.14 0 0 1 4.14 3h5.128a1.14 1.14 0 0 1 1.14 1.14v.57"
        style={{ fill: 'none', stroke: '#19365e', strokeLinecap: 'round', strokeLinejoin: 'round' }}
        transform="translate(-2.5 -2.5)"
      />
    </g>
  </svg>
);
