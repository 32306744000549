import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(theme => ({
  modalBox: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '480px',
    height: '544px',
    borderRadius: '16px',
    backgroundColor: 'white',
    border: 'none',
    padding: '24px',
    fontSize: '14px',
    fontFamily: theme.typography.default,
    color: theme.palette.icons.nav,
    '& header': {
      fontSize: '20px',
      fontWeight: 'bold',
      textAlign: 'center',
      marginBottom: '12px',
    },
    '& .rowContainer': {
      marginBottom: "20px"
    }
  },
  inputSection: {
    marginTop: '12px',
    '& div': {
      display: 'flex',
      flexDirection: 'column',
      marginBottom: '8px',
      '& p': {
        marginBottom: '4px',
      },
      '& input': {
        borderRadius: '8px',
        border: '1px solid #e6e6e6',
        height: '32px',
        fontSize: '16px',
        paddingLeft: '8px',
        color: theme.palette.icons.nav,
        outline: 'none !important',
      },
    },
  },
  rolesBtnContainer: {
    marginTop: '16px',
    display: 'flex',
    flexDirection: 'row',
    gap: '8px',
    flexWrap: 'wrap',
    '& button': {
      border: 'none',
      height: '32px',
      borderRadius: '16px',
      padding: '0 20px',
      cursor: 'pointer',
      fontWeight: '500',
      fontSize: '16px',
    },
  },
  roleBtn: {
    color: theme.palette.icons.nav,
  },
  roleBtnActive: {
    backgroundColor: '#c6ffef',
    color: '#23c3a4',
  },
  bottomBtnContainer: {
    marginTop: '32px',
    display: 'flex',
    flexDirection: 'row',
    gap: '32px',
    '& button': {
      fontSize: '18px',
      color: theme.palette.icons.nav,
      cursor: 'pointer',
    },
  },
  cancelBtn: {
    height: '40px',
    width: '128px',
    border: 'none',
    borderRadius: '8px',
    backgroundColor: '#ffccd4',
  },
  addBtn: {
    height: '40px',
    width: '280px',
    borderRadius: '8px',
    border: 'none',
    backgroundColor: '#c6ffef',
  },
}));
