import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(theme => ({
  root: {
    fontFamily: theme.typography.default,
    fontSize: '18px',
    color: theme.palette.icons.nav,
    overflowY: 'hidden',
    minWidth: '1250px',
  },
  content: {
    padding: '24px',
    display: 'flex',
    flexDirection: 'column',
    gap: '24px',
  },
  toolbar: {
    height: '112px',
    backgroundColor: 'white',
    borderRadius: '0 0 16px 16px',
    fontSize: '30px',
    fontWeight: 'bold',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  label: {
    padding: '35px 48px',
  },
  addNewMemberBtn: {
    backgroundColor: '#c6ffef',
    fontFamily: theme.typography.default,
    width: '200px',
    height: '40px',
    borderRadius: '8px',
    padding: '8px',
    border: 'none',
    cursor: 'pointer',
    fontSize: '18px',
    color: '#19365e',
    fontWeight: '500',
    display: 'flex',
    flexDirection: 'row',
    gap: '8px',
    alignItems: 'center',
    marginTop: '36px',
    marginRight: '24px',
  },
}));
