import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    paddingRight: '1rem',
  },
  menu: {
    paddingTop: '4.1rem',
    marginBottom: '4.1rem',
    borderBottom: `0.2rem solid ${theme.palette.newDesign.divider}`,
    borderTop: `0.1rem dashed ${theme.palette.newDesign.divider}`,
    '& .MuiTabs-root': {
      overflow: 'visible',
    },
    '& .MuiTabs-scroller': {
      overflow: 'visible !important',
    },
    '& .MuiTab-root': {
      fontSize: '1.8rem',
      minWidth: '25rem',
      color: theme.palette.primary.nav,
    },
    '& .MuiTabs-indicator': {
      height: '0.6rem',
      top: '4.6rem',
    },
  },
}));
