import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(theme => ({
  input: {
    display: 'none',
  },
  imgUploaded: {
    maxWidth: '98%',
  },
  secondary: {
    display: 'flex',
    height: '4rem',
    backgroundColor: theme.palette.secondary.dark,
    boxShadow: 'none',
    textTransform: 'capitalize',
    color: theme.palette.text.primary,
    fontWeight: 'bold',
    fontFamily: 'Gilroy, Roboto , Helvetica, Arial, sans-serif',
    borderRadius: '2rem',
    fontSize: '1.8rem',
    width: '20rem',
    padding: '0.8rem 2rem',
    alignItems: 'center',
    '&:hover': {
      backgroundColor: theme.palette.greyMid.mid,
    },
    '&:active': {
      backgroundColor: theme.palette.greyMid.dark,
    },
  },
  icon: {
    marginRight: '2rem',
    display: 'flex',
    '&:svg': {
      width: '10.5rem',
    },
  },
}));
