import React from 'react';

export const RefreshIcon = ({ active = true }) => {
  const fillColor = active ? '#19365e' : '#a5a6ae';

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <path
        data-name="Union 531"
        d="M10.351 23.556A11.127 11.127 0 0 1 5.575 20.7L1.62 16.938v5.082a.815.815 0 0 1-.81.819.815.815 0 0 1-.81-.818v-6.979a.825.825 0 0 1 .05-.285l.007-.019.006-.015v-.017a.818.818 0 0 1 .148-.225.8.8 0 0 1 .619-.258H7.7a.82.82 0 0 1 0 1.641H2.857l3.836 3.652a.125.125 0 0 1 .018.017 9.4 9.4 0 0 0 8.839 2.59 9.52 9.52 0 0 0 6.876-6.188.805.805 0 0 1 1.035-.5.821.821 0 0 1 .493 1.046 11.3 11.3 0 0 1-3.1 4.671A11.05 11.05 0 0 1 13.449 24a10.974 10.974 0 0 1-3.098-.444zM23.167 9.779H16.3a.82.82 0 0 1 0-1.64h4.847l-3.839-3.654-.018-.017a9.392 9.392 0 0 0-8.839-2.59A9.519 9.519 0 0 0 1.574 8.07a.809.809 0 0 1-1.035.5.825.825 0 0 1-.493-1.049 11.316 11.316 0 0 1 3.1-4.67A11.13 11.13 0 0 1 8.094.277 10.969 10.969 0 0 1 10.551 0a11.052 11.052 0 0 1 7.875 3.3l3.953 3.764V1.98a.81.81 0 1 1 1.62 0v6.954a.824.824 0 0 1-.22.586.8.8 0 0 1-.589.261z"
        style={{ fill: fillColor }}
      />
    </svg>
  );
};
