import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Menu, MenuItem } from '@mui/material';
import Button from '@mui/material/Button';
import React, { useEffect, useState } from 'react';

import { graphqlApiDecorator } from '../../../../decorators';
import { queries } from '../../../../graphql';
import {
  getCurrencySymbol,
  getFormattedDisplayDate,
  getFormattedDisplayTime,
} from '../../../../utils';
import { ArrowDownIcon, ArrowRightIcon, WalletIcon } from '../../../icons';
import { useStyles } from './balanceHistory.hooks';
import { SideRectangle } from './icons';

/**
 * Sub-component of the CustomerDetailPage parent component. This renders the activity related
 * to the customer. At time of writing this only renders the balance logs related to the customers wallets.
 * @param {object} data the user profile data of type userProfile
 */
export const BalanceHistory = ({ data }) => {
  const [userBalances, setUserBalances] = useState([]);
  const [error, setError] = useState('');
  const [paginationTokens, setPaginationTokens] = useState(['']);
  const [currency, setCurrency] = useState('GBP');
  const [anchorEl, setAnchorEl] = useState(null);
  const [arrowIconClicked, setArrowIconClicked] = useState(false);
  const [userWallets, setUserWallets] = useState(['GBP']);
  const open = Boolean(anchorEl);
  const [page, setPage] = useState(0);
  const classes = useStyles();
  const BALANCE_LOGS = 'getMonthlyStatement';

  const menuItemHoverStyle = { ':hover': { background: '#c6ffef' } };

  const fetchUserBalanceLogs = async (id, nextToken, currency) => {
    const input = {
      input: {
        userId: id,
        currency,
      },
    };
    try {
      const balanceLogs = await graphqlApiDecorator(queries, BALANCE_LOGS, input);

      if (balanceLogs.items) {
        const { items } = balanceLogs;
        setUserBalances(items);
        const currentPaginationTokens = [...paginationTokens];
        currentPaginationTokens[page + 1] = balanceLogs?.nextToken;
        setPaginationTokens(currentPaginationTokens);
      }
    } catch (error) {
      setError(error.message);
    }
  };

  const fetchUserWallets = async id => {
    const getUserWallets = 'getUserWallets';
    try {
      const result = await graphqlApiDecorator(queries, getUserWallets, { id });
      const newUserWallets = userWallets;

      result?.forEach(wallet => newUserWallets.push(wallet.currency));

      setUserWallets(newUserWallets);
    } catch (error) {
      setError(error.message);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
    setArrowIconClicked(false);
  };

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
    setArrowIconClicked(true);
  };
  useEffect(() => {
    fetchUserWallets(data.id);
  }, []);

  useEffect(() => {
    fetchUserBalanceLogs(data.id, paginationTokens[page], currency);
  }, [page]);

  useEffect(() => {
    fetchUserBalanceLogs(data.id, '', currency);
    setPage(0);
    setPaginationTokens(['']);
  }, [currency]);

  const getFormattedDateAndTime = timeStamp =>
    `${getFormattedDisplayDate(timeStamp)} | ${getFormattedDisplayTime(timeStamp)}`;

  const formatAmount = item =>
    `${getCurrencySymbol(item.currency)}${Number(item.balance).toLocaleString()}`;

  return (
    <div>
      <div className={classes.label}>
        <aside className={classes.sideLabel}>
          <SideRectangle />
          <header>Balance History</header>
        </aside>
        <Button
          id="currency-choice"
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            gap: '8px',
            justifyContent: 'space-evenly',
            width: '112px',
            height: '32px',
            borderRadius: '16px',
            border: '1px solid #1fe3ac',
            fontSize: '14px',
            color: '#19365e',
            textTransform: 'none',
            fontFamily: 'Gilroy, Roboto , Helvetica, Arial, sans-serif',
          }}
          aria-controls={open ? 'export-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
        >
          <WalletIcon />
          <p>{currency}</p>
          <div className={classes.arrowIcon}>
            {!arrowIconClicked ? <ArrowRightIcon /> : <ArrowDownIcon />}
          </div>
        </Button>
        <Menu
          id="currency-menu"
          sx={{
            '& .MuiPaper-root': {
              width: '112px',
              marginTop: '5px',
              border: '1px solid #1fe3ac',
              borderRadius: '16px',
              boxShadow: 'none',
            },
          }}
          open={open}
          anchorEl={anchorEl}
          getContentAnchorEl={null}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          transformOrigin={{ vertical: 'top', horizontal: 'center' }}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'export-button',
          }}
        >
          {userWallets.map(wallet => (
            <MenuItem
              key={wallet}
              sx={menuItemHoverStyle}
              onClick={() => {
                setCurrency(wallet);
                handleClose();
              }}
            >
              {wallet}
            </MenuItem>
          ))}
        </Menu>
      </div>
      <div className={classes.dateValueRow}>
        <p style={{ fontSize: '18px', fontWeight: '500' }}>Date</p>
        <aside className={classes.rhsRow}>
          <p style={{ fontSize: '18px', width: "100px" }}>Value</p>
        </aside>
      </div>
      <div className={classes.userLogsContainer}>
        {userBalances?.length &&
          userBalances.map((item, index) => (
            <div key={`${item.createdTimeStamp}-${index}`} className={classes.userLogRow}>
              <aside className={classes.lhsRow}>
                <p>{getFormattedDateAndTime(item.date)} | {item.month}</p>
              </aside>
              <aside className={classes.lhsRow} style={{ width: "100px" }}>
                <p>{formatAmount(item)}</p>
              </aside>
            </div>
          ))}
        {error && <p>{error}</p>}
      </div>
      {!!paginationTokens[1] && (
        <section className={classes.paginationContainer}>
          <Button
            type="button"
            className={classes.paginationBtn}
            disabled={page === 0}
            onClick={() => setPage(prev => prev - 1)}
          >
            <ArrowBackIcon sx={{ height: '24px', width: '24px' }} />
          </Button>
          <p style={{ fontSize: '14px', fontWeight: 'bold' }}>{`${page + 1}`}</p>
          <Button
            type="button"
            className={classes.paginationBtn}
            disabled={!paginationTokens[page + 1]}
            onClick={() => setPage(prev => prev + 1)}
          >
            <ArrowForwardIcon sx={{ height: '24px', width: '24px' }} />
          </Button>
        </section>
      )}
    </div>
  );
};
