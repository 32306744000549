import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(theme => ({
  boxContent: {
    background: theme.palette.white,
    borderRadius: '16px',
    padding: '16px 0',
    borderTopLeftRadius: '0px',
    boxShadow: '0 2px 16px 0 rgba(0, 0, 0, 0.04)',
    '&:not(:first-child)': {
      marginTop: '16px',
    },
  },
  leftContainer: {
    fontSize: '24px',
    fontWeight: 'bold',
    display: 'flex',
    flexDirection: 'row',
    gap: '16px',
    alignItems: 'center',
  },
  gridContent: {
    padding: '24px',
  },
  colorSpan:{
     display: "block",
     width:'16px',
     height:'16px',
     marginRight:'16px',
    borderRadius: '5px'
  }
}));
