import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { uid } from 'react-uid';

import { useStyles } from './page-menu.hooks';
import { MenuItem } from './page-menu-item';

export const PageMenu = ({ menu, onOpenSelectedCard }) => {
  const classes = useStyles();
  const [selected, setSelected] = useState(menu[0]);
  const [translateCount, setTranslateCount] = useState({ count: 0, literal: '' });
  const [menuWidth, setMenuWidth] = useState(0);
  const [contentMenuWidth, setContentMenuWidth] = useState(0);

  useEffect(() => {
    const menuDiv = document.getElementById('menu');
    const contentMenuDiv = document.getElementById('menuContent');

    setMenuWidth(menuDiv.clientWidth);
    setContentMenuWidth(contentMenuDiv.clientWidth);
  }, []);

  const onTranslateMenuRight = (counter, divWidth) => {
    if (counter > -divWidth) {
      const count = counter - 100;
      setTranslateCount({ count, literal: `translate(${count}px)` });
    }
  };

  const onTranslateMenuLeft = counter => {
    if (counter < 0) {
      const count = counter + 100;
      setTranslateCount({ count, literal: `translate(${count}px)` });
    }
  };

  const onGoSection = (section, card) => {
    setSelected(section);
    onOpenSelectedCard(card);
  };

  return (
    <div className={classes.menuWrapper}>
      {contentMenuWidth > menuWidth && translateCount.count > -contentMenuWidth && (
        <div className={clsx(classes.arrowDiv, classes.right)}>
          <ArrowForwardIosIcon
            onClick={() => onTranslateMenuRight(translateCount.count, contentMenuWidth)}
          />
        </div>
      )}
      {translateCount.count < 0 && (
        <div className={clsx(classes.arrowDiv, classes.left)}>
          <ArrowBackIosIcon onClick={() => onTranslateMenuLeft(translateCount.count)} />
        </div>
      )}
      <div className={classes.menu} id="menu">
        <div
          id="menuContent"
          className={classes.menuContent}
          style={{ transform: translateCount?.literal ?? 'none' }}
        >
          {menu.map(item => {
            const anchor = item.replace(/\s/g, '').toLowerCase();

            return (
              <a href={`#${anchor}`} key={uid(anchor)}>
                <MenuItem
                  title={item}
                  onClick={() => onGoSection(item, anchor)}
                  active={selected === item}
                  classes={classes}
                />
              </a>
            );
          })}
        </div>
      </div>
    </div>
  );
};
