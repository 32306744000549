import { makeStyles } from '@mui/styles';

import tickInputActive from '../../../../assets/icons/tick-input-active-icon.svg';
import tickInputInactive from '../../../../assets/icons/tick-input-icon.svg';

export const useStyles = makeStyles(theme => ({
  feeInputRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  feesInputContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '40px',
    fontSize: '18px',
    fontWeight: 'normal',
    marginTop: '24px',
    '& input': {
      flexGrow: '1',
      height: '48px',
      borderRadius: '8px',
      border: '1px solid #e6e6e6',
      marginTop: '11px',
    },
    '& input + $valueRhs': {
      backgroundImage: `url(${tickInputInactive})`,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
    },
    '& input:focus + $valueRhs': {
      border: '2px solid #085ccc',
      backgroundImage: `url(${tickInputActive})`,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
    },
    '& input:focus + $valueRhsPercentage': {
      border: '2px solid #085ccc',
    },
  },
  valueContainer: {
    position: 'relative',
    marginLeft: '24px',
    '& input::-webkit-outer-spin-button, input::-webkit-inner-spin-button': {
      WebkitAppearance: 'none',
      margin: 0,
    },
    '& input[type=number]': {
      MozAppearance: 'textfield',
      fontSize: '20px',
      fontFamily: theme.typography.default,
      color: theme.palette.icons.nav,
      paddingLeft: '8px',
    },
    '& > p': {
      fontWeight: 'bold',
    },
  },
  valueRhs: {
    position: 'absolute',
    height: '48px',
    width: '48px',
    borderRadius: '0px 8px 8px 0px',
    background: theme.palette.transactions.background,
    border: '1px solid #e6e6e6',
    textAlign: 'center',
    top: '32.5px',
    right: '0px',
    padding: '8px',
  },
  valueRhsPercentage: {
    position: 'absolute',
    height: '48px',
    width: '48px',
    borderRadius: '0px 8px 8px 0px',
    background: theme.palette.transactions.background,
    border: '1px solid #e6e6e6',
    textAlign: 'center',
    top: '32.5px',
    right: '0px',
    padding: '8px',
    fontSize: '24px',
  },
  defaultBtnStyle: {
    borderRadius: '8px',
    border: 'none',
    height: '48px',
    width: '136px',
    fontSize: '18px',
    color: theme.palette.icons.nav,
    fontFamily: theme.typography.default,
    fontWeight: '500',
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flext-start',
    gap: '12px',
    alignItems: 'center',
    paddingLeft: '12px',
  },
  btnContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: '20px',
    marginTop: '57px',
    paddingRight: "20px",
    marginRight: '24px',
    marginLeft: '24px',
  },
  btnCancel: {
    backgroundColor: '#e2ecff',
  },
  btnSave: {
    backgroundColor: '#c6ffef',
  },
  btnDisabled: {
    backgroundColor: '#f2f2f4',
    cursor: 'auto',
    color: '#a5a6ae',
  },
  disabledText: {
    color: '#a5a6ae',
    '& input[type="number"]': {
      color: '#a5a6ae',
    },
  },
}));
