import {
  getAllCountriesByCurrencyOrSymbol,
  getAllISOByCurrencyOrSymbol,
  getParamByISO,
} from 'iso-country-currency';

export const getCurrencySymbol = currencyText => {
  try {
    return getParamByISO(getAllISOByCurrencyOrSymbol('currency', currencyText)[0], 'symbol');
  } catch (error) {
    return '';
  }
};

export const getCountryFromCurrency = currencyText => {
  if (currencyText === 'EUR') return 'Europe';
  if (currencyText === 'USD') return 'United States';
  try {
    return getAllCountriesByCurrencyOrSymbol('currency', currencyText)[0];
  } catch (error) {
    return '';
  }
};
