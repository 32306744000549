import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(theme => ({
  root: {
    boxShadow: 'none',
  },
  flexGrow: {
    flexGrow: 1,
  },
  headerLogo: {
    maxHeight: '4rem',
  },
  headerText: {
    color: '#7e8494',
    fontWeight: 'bold',
    marginRight: '1.6rem',
  },
  headerBackground: {
    backgroundColor: 'white',
  },
  headerIcon: {
    height: '2.2rem',
    width: '2.4rem',
    color: '#A6ACB2',
  },
  topBox: {
    height: '6rem',
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    borderRight: '0.1rem solid #d7e1e8',
    marginRight: '1.6rem',
    paddingRight: '1.6rem',
  },
  menuButton: {
    backgroundColor: theme.palette.primary.main,
  },
}));
