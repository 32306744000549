export const getSellRateFromPercentage = (flatRate, percentage) => {
  if (flatRate && percentage) return flatRate - flatRate * (percentage / 100);

  return '';
};

export const getSellRateFromAbsolute = (flatRate, absolute) => {
  if (flatRate && absolute) return flatRate - absolute;

  return '';
};
