import React from 'react';

export const WarningIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="66" height="66" viewBox="0 0 66 66">
    <g data-name="Group 9765" transform="translate(-190 -694.004)">
      <circle
        data-name="Ellipse 1750"
        cx="33"
        cy="33"
        r="33"
        transform="translate(190 694.004)"
        style={{ fill: '#f8d753' }}
      />
      <path
        data-name="Path 19317"
        d="M18.421 24.183A1.582 1.582 0 1 0 20 25.764a1.582 1.582 0 0 0-1.579-1.581zM35.3 26.508 22.565 4.365a4.745 4.745 0 0 0-8.288 0L1.624 26.508a4.745 4.745 0 0 0 4.049 7.165h25.5a4.745 4.745 0 0 0 4.127-7.165zm-2.736 3.163a1.582 1.582 0 0 1-1.392.807H5.673a1.6 1.6 0 0 1-1.392-2.388L16.934 5.947a1.582 1.582 0 0 1 2.815 0l12.733 22.142a1.582 1.582 0 0 1 .079 1.613zM18.421 11.53a1.582 1.582 0 0 0-1.582 1.582v6.327a1.582 1.582 0 0 0 3.163 0v-6.328a1.582 1.582 0 0 0-1.581-1.581z"
        transform="translate(205.159 709.198)"
        style={{ fill: '#fff' }}
      />
    </g>
  </svg>
);
