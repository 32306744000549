import React, { useEffect, useState } from 'react';
import { uid } from 'react-uid';

import { graphqlApiDecorator } from '../../../../decorators';
import { mutations, queries } from '../../../../graphql';
import { getCurrentUser } from '../../../../services';
import { compareByDate, getFormattedDisplayDate, getFormattedDisplayTime } from '../../../../utils';
import { WarningModal } from '../../../modal';
import { MessageBlank } from './blank-templates';
import { SendButton, SideRectangle } from './icons';
import { useStyles } from './notes.hooks';

/**
 * Sub-component of the CustomerDetailPage parent component. This renders the private notes
 * that backoffice admin have made on the customer (non-customer visible);
 * Component feature allows admin to create and save a note about customer.
 * @param {object} data the user profile data of type userProfile
 */
export const Notes = ({ data }) => {
  const [userNotes, setUserNotes] = useState([]);
  const [openWarningModal, setOpenWarningModal] = useState(false);
  const [message, setMessage] = useState('');
  const [note, setNote] = useState('');
  const classes = useStyles();

  const fetchUserNotes = async id => {
    const queryTitle = 'listUserNotes';
    const filter = {
      userId: {
        eq: id,
      },
    };
    try {
      const result = await graphqlApiDecorator(queries, queryTitle, { filter });
      if (result.errors && !result.data) {
        setMessage(result.errors[0].message);
        setOpenWarningModal(true);

        return;
      }
      if (result) setUserNotes(result.items?.sort(compareByDate()));
    } catch (err) {
      setMessage(err.message);
      setOpenWarningModal(true);
    }
  };

  useEffect(() => {
    fetchUserNotes(data.id);
  }, [data]);

  const sendNote = async event => {
    event.preventDefault();

    if (note) {
      try {
        const mutationTitle = 'createUserNote';
        const currentUser = await getCurrentUser();
        const input = {
          id: uid(`${data.id}-${Date.now()}`),
          timestamp: Date.now(),
          adminName: currentUser?.data?.username,
          note,
          userId: data.id,
        };

        const result = await graphqlApiDecorator(mutations, mutationTitle, { input });
        if (result.errors && !result.data) {
          setMessage(result.errors[0].message);
          setOpenWarningModal(true);

          return;
        }

        await fetchUserNotes(data.id);
      } catch (err) {
        setMessage(err.message);
        setOpenWarningModal(true);
      }
    }
    setNote('');
  };

  return (
    <div className={classes.mainContainer}>
      <div className={classes.label}>
        <SideRectangle />
        <header>Notes</header>
      </div>
      <div className={classes.instruction}>
        <p>Leave a new note here:</p>
      </div>
      <div className={classes.notesContainer}>
        {userNotes?.length ? (
          userNotes.map((item, index) => (
            <div key={item.id}>
              <div className={classes.topOfNote}>
                <p style={{ fontWeight: '500' }}>{item.adminName}</p>
                <p style={{ fontSize: '10px' }}>{`${getFormattedDisplayDate(
                  item.createdAt
                )}, ${getFormattedDisplayTime(item.createdAt)}`}</p>
              </div>
              <div
                key={item.id}
                className={index % 2 === 0 ? classes.noteBubble : classes.alternateColor}
              >
                <p>{item.note}</p>
              </div>
            </div>
          ))
        ) : (
          <div className={classes.blankMessages}>
            <MessageBlank />
            <MessageBlank />
          </div>
        )}
      </div>
      <form className={classes.noteInputContainer} onSubmit={sendNote}>
        <input type="text" value={note} onChange={event => setNote(event.target.value)} />
        <button type="submit" className={classes.sendBtn} onClick={sendNote}>
          <SendButton />
        </button>
      </form>
      <WarningModal
        open={openWarningModal}
        onClose={() => setOpenWarningModal(false)}
        confirm={() => setOpenWarningModal(false)}
        message={message}
      />
    </div>
  );
};
