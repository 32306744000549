import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(theme => ({
  button: {
    width: '32.8rem',
    paddingTop: '1.4rem',
    paddingBottom: '1.4rem',
    backgroundColor: theme.palette.primary.nav,
    color: theme.palette.white,
    outline: 'none',
    border: 'none',
    fontSize: '2rem',
    borderRadius: '0.5rem',
    float: 'right',
    marginTop: '3.9rem',
    marginBottom: '6.4rem',
    '&:hover': {
      cursor: 'pointer',
    },
  },
}));
