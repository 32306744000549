/* eslint-disable no-restricted-syntax */
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { entitiesEnum, pairFeeTypeEnum } from '../../../../constants';
import { saveEntity } from '../../../../services';
import { getSellRateFromAbsolute, getSellRateFromPercentage } from '../../../../utils';
import { SmallSideRectangle } from '../../../icons';
import { PairsCancelIcon, PairsSaveIcon, PlusIcon, RefreshIcon } from '../../../icons/pairs';
import { LoadingModal, SavedModal, WarningModal } from './modal';
import { useStyles } from './rates.hooks';

/** Rates Section in the Pairs Page. Parent component is SetupPage.
 * This component displays the flat rate of the selected pair and the user
 * can set the profit margin
 * @param {Array} data array of pairs data to render of type Pair
 * @param {Array} rates array of {amount: float, from: string, to: string} the amount
 * represents the flat rate
 * @param {function} handleRefreshData this eventually calles the fetchEntity() in the useGraphList() custom hook
 * @param {function} refreshFlatRates this calls the getFlatRates() in the PairsTable parent
 * component
 */
export const Rates = ({ data, handleRefreshData, rates, refreshFlatRates }) => {
  const [profitMargin, setProfitMargin] = useState(0);
  const [showFlatRate, setShowFlatRate] = useState(false);
  const [showSellRate, setShowSellRate] = useState(true);
  const [flatRate, setFlatRate] = useState('');
  const [profitMarginType, setProfitMarginType] = useState('Percentage');
  const [sellRate, setSellRate] = useState(0);
  const [showSavedModal, setShowSavedModal] = useState(false);
  const [showWarningModal, setShowWarningModal] = useState(false);
  const [warningMessage, setWarningMessage] = useState('');
  const [originalProfitMargin, setOriginalProfitMargin] = useState(0);
  const [isActive, setIsActive] = useState(false);
  const [loading, setLoading] = useState(false);
  const classes = useStyles();
  const currenciesDataObj = useSelector(state => state.pairsCurrenciesObj);

  useEffect(() => {
    let oneSendingCurrencySelected = false;
    let oneReceivingCurrencySelected = false;

    if (currenciesDataObj['Sending Currency'] && currenciesDataObj['Sending Currency'].length === 1)
      oneSendingCurrencySelected = true;

    if (
      currenciesDataObj['Receiving Currency'] &&
      currenciesDataObj['Receiving Currency'].length === 1
    )
      oneReceivingCurrencySelected = true;

    if (!currenciesDataObj['Sending Currency'] || !currenciesDataObj['Sending Currency']) {
      setIsActive(false);
    }

    if (
      oneSendingCurrencySelected &&
      oneReceivingCurrencySelected &&
      currenciesDataObj['Sending Currency'][0] === currenciesDataObj['Receiving Currency'][0]
    ) {
      setIsActive(false);
    }

    if (
      oneSendingCurrencySelected &&
      oneReceivingCurrencySelected &&
      data &&
      currenciesDataObj['Sending Currency'][0] !== currenciesDataObj['Receiving Currency'][0]
    ) {
      setIsActive(true);
      setShowFlatRate(true);
      setShowSellRate(true);
      const pairData = data.filter(pair => {
        if (
          pair.from?.iso === currenciesDataObj['Sending Currency'][0] &&
          pair.to?.iso === currenciesDataObj['Receiving Currency'][0]
        )
          return true;

        return false;
      });
      setProfitMarginType(pairData[0].profitMargin?.type || 'Percentage');
      setProfitMargin(pairData[0].profitMargin?.value || '');
      setOriginalProfitMargin(pairData[0].profitMargin?.value || '');

      if (pairData[0].status && pairData[0].status === 'Active') {
        setIsActive(true);
      } else {
        setIsActive(false);
      }

      if (pairData[0].profitMargin?.type === pairFeeTypeEnum.Percentage && flatRate) {
        setSellRate(getSellRateFromPercentage(flatRate, profitMargin));
      }

      if (pairData[0].profitMargin?.type === pairFeeTypeEnum.Absolute && flatRate) {
        setSellRate(getSellRateFromAbsolute(flatRate, profitMargin));
      }
    }

    if (
      (currenciesDataObj['Sending Currency'] &&
        currenciesDataObj['Receiving Currency'] &&
        currenciesDataObj['Sending Currency']?.length > 1) ||
      currenciesDataObj['Receiving Currency']?.length > 1
    ) {
      // Multiple currencies selected so cannot show flat rate for one pair
      setIsActive(true);
      setShowFlatRate(false);
      setProfitMarginType('Percentage');
      setOriginalProfitMargin('');
      setProfitMargin('');
      setShowSellRate(false);
      setSellRate('');
    }
  }, [currenciesDataObj, data, flatRate]);

  useEffect(() => {
    const fetchPairsRate = (from, to) => {
      const { amount } = rates.filter(rate => rate.from === from && rate.to === to)[0];
      setFlatRate(amount);
    };

    if (
      showFlatRate &&
      currenciesDataObj['Sending Currency']?.length === 1 &&
      currenciesDataObj['Receiving Currency']?.length === 1 &&
      currenciesDataObj['Sending Currency'][0] !== currenciesDataObj['Receiving Currency'][0]
    )
      fetchPairsRate(
        currenciesDataObj['Sending Currency'][0],
        currenciesDataObj['Receiving Currency'][0]
      );
  }, [showFlatRate, currenciesDataObj]);

  const saveRate = async () => {
    const sendingCurrencies = currenciesDataObj['Sending Currency'];
    const receivingCurrencies = currenciesDataObj['Receiving Currency'];

    if (!sendingCurrencies || !receivingCurrencies) {
      setWarningMessage('Please select both sending and receiving currencies');
      setShowWarningModal(true);

      return;
    }

    if (profitMargin === '') {
      setWarningMessage('Please enter a profit margin');
      setShowWarningModal(true);

      return;
    }

    if (
      sendingCurrencies.length === 1 &&
      receivingCurrencies.length === 1 &&
      sendingCurrencies[0] === receivingCurrencies[0]
    ) {
      setWarningMessage('Sending and Receiving currencies are the same');
      setShowWarningModal(true);

      return;
    }

    if (sellRate < 0) {
      setWarningMessage('Sell rate cannot be negative');
      setShowWarningModal(true);

      return;
    }

    setLoading(true);

    try {
      for (const sendingCurrency of sendingCurrencies) {
        for (const receivingCurrency of receivingCurrencies) {
          if (sendingCurrency !== receivingCurrency) {
            const identifiedPairInData = data.find(pair => {
              if (pair.from?.iso === sendingCurrency && pair.to?.iso === receivingCurrency) {
                return true;
              }

              return false;
            });

            if (identifiedPairInData) {
              const { id } = identifiedPairInData;
              const values = {
                ...(profitMargin !== '' && {
                  profitMargin: { type: profitMarginType, value: profitMargin },
                }),
                ...(sellRate !== '' && { sellRateOverride: sellRate }),
                id,
              };
              const saveObj = {
                entity: entitiesEnum.PAIR,
                values,
                id,
                hasOrder: false,
              };

              await saveEntity(saveObj);
            }
          }
        }
      }
      setLoading(false);
      setShowSavedModal(true);
    } catch (err) {
      setLoading(false);
      setWarningMessage(err.message);
      setShowWarningModal(true);
    }
  };

  const closeSavedModal = async () => {
    setShowSavedModal(false);
    await handleRefreshData();
  };

  const cancelRate = () => {
    setProfitMargin('');
  };

  const preventMinus = event => {
    if (event.code === 'Minus') event.preventDefault();
  };

  return (
    <section className={classes.sectionContainer}>
      <header className={classes.headerContainer}>
        <SmallSideRectangle />
        <p>Rates</p>
      </header>
      <aside className={!isActive ? classes.disabledColor : undefined}>
        <PlusIcon active={isActive} />
        <p>New Rate</p>
        <div
          className={
            isActive
              ? classes.newRateUnderline
              : `${classes.newRateUnderline} ${classes.newRateDisabledUnderline}`
          }
        />
      </aside>
      <div className={classes.horizRule} />
      <main className={!isActive ? classes.disabledColor : undefined}>
        <aside className={classes.ratesInputsContainer}>
          {showFlatRate && (
            <div>
              <p>Flat Rate</p>
              <div className={classes.flatRatesContainer}>
                <p>{flatRate}</p>
                <aside>
                  <div
                    className={
                      isActive
                        ? classes.refreshIconContainer
                        : `${classes.refreshIconContainer} ${classes.btnDisabled}`
                    }
                    onClick={refreshFlatRates}
                  >
                    <RefreshIcon active={isActive} />
                  </div>
                  <div className={classes.flatRatesCurrencyContainer}>
                    <p>
                      {currenciesDataObj['Sending Currency']
                        ? currenciesDataObj['Sending Currency'][0]
                        : ''}
                    </p>
                  </div>
                </aside>
              </div>
            </div>
          )}
          <div className={classes.defaultMarginSellContainer}>
            <p>Profit Margin</p>
            <div
              className={
                isActive
                  ? classes.profitMarginSelectInputContainer
                  : `${classes.profitMarginSelectInputContainer} ${classes.disabledColor}`
              }
            >
              <select
                className={classes.profitMarginSelectBox}
                name="profitMarginType"
                value={profitMarginType}
                onChange={event => setProfitMarginType(event.target.value)}
                disabled={!isActive}
              >
                <option value="Percentage">Percentage</option>
                <option value="Absolute">Absolute</option>
              </select>
              <input
                type="number"
                disabled={!isActive}
                min="0"
                placeholder={originalProfitMargin}
                onKeyPress={preventMinus}
                onChange={event => {
                  setProfitMargin(event.target.value);
                  if (profitMarginType === pairFeeTypeEnum.Percentage && event.target.value) {
                    setSellRate(getSellRateFromPercentage(flatRate, event.target.value));
                  }
                  if (profitMarginType === pairFeeTypeEnum.Absolute && event.target.value) {
                    setSellRate(getSellRateFromAbsolute(flatRate, event.target.value));
                  }
                  if (profitMarginType === pairFeeTypeEnum.Percentage && !event.target.value) {
                    setSellRate(getSellRateFromPercentage(flatRate, originalProfitMargin));
                  }
                  if (profitMarginType === pairFeeTypeEnum.Absolute && !event.target.value) {
                    setSellRate(getSellRateFromAbsolute(flatRate, originalProfitMargin));
                  }
                }}
              />
              {profitMarginType === 'Percentage' && <div className={classes.rhsContainer}>%</div>}
            </div>
          </div>
          {showSellRate && (
            <div className={classes.defaultMarginSellContainer}>
              <p>Sell Rate</p>
              <input type="number" value={sellRate || ''} readOnly disabled />
              <div className={`${classes.rhsContainer} ${classes.sellRatesRHS}`}>
                {currenciesDataObj['Sending Currency']
                  ? currenciesDataObj['Sending Currency'][0]
                  : ''}
              </div>
            </div>
          )}
        </aside>
        <aside className={classes.ratesBtnContainer}>
          <button
            type="button"
            className={
              isActive
                ? `${classes.defaultBtnStyle} ${classes.btnSave}`
                : `${classes.defaultBtnStyle} ${classes.btnDisabled}`
            }
            onClick={saveRate}
            disabled={!isActive}
          >
            <PairsSaveIcon active={isActive} />
            <p>Save</p>
          </button>
          <button
            type="button"
            className={
              isActive
                ? `${classes.defaultBtnStyle} ${classes.btnCancel}`
                : `${classes.defaultBtnStyle} ${classes.btnDisabled}`
            }
            onClick={cancelRate}
            disabled={!isActive}
          >
            <PairsCancelIcon active={isActive} />
            Cancel
          </button>
        </aside>
        <SavedModal open={showSavedModal} onClose={closeSavedModal} />
        <WarningModal
          open={showWarningModal}
          onClose={() => setShowWarningModal(false)}
          message={warningMessage}
        />
        <LoadingModal open={loading} onClose={() => setLoading(false)} />
      </main>
    </section>
  );
};
