import React, { useEffect, useState } from 'react';

import { entitiesEnum, pairFeeTypeEnum } from '../../../../constants';
import { saveEntity } from '../../../../services';
import {
  ArrowDownIcon,
  ArrowUpIcon,
  BigSaveIcon,
  SearchIcon,
  SideRectangle,
  SmallSaveBtn,
} from '../../../icons';
import { currencyColors } from '../../../icons/countries/currency-colors';
import { ReturnCountryIcon } from '../../../icons/countries/ReturnCountryIcon';
import { SavedModal, WarningModal } from '../setup/modal';
import { useStyles } from './pairs-row.hooks';
import { PairsRowDetails } from './pairs-row-details';

/**
 * PairsRow component where the parent component is PairsTable. This represents a specific
 * country and use is able to click on drop down to expand the pairs related to the country.
 * @param {Array} rowData array of type Pair
 * @param {string} country the country in 3 letter ISO code format
 * @param {Array} rates array of {amount: float, from: string, to: string} the amount
 * represents the flat rate
 * @param {function} setupPage This will set the sending and receiving currency in redux and
 * change the tab to the setup of that pair
 * @param {function} handleRefreshData This will refresh the pairs data
 */
export const PairsRow = ({ rowData, country, rates, setupPage, handleRefreshData }) => {
  const [showSavedModal, setShowSavedModal] = useState(false);
  const [showWarningModal, setShowWarningModal] = useState(false);
  const [warningMessage, setWarningMessage] = useState('');
  // Sort toCountry in alphabetical order

  const sortRowDataCompare = (a, b) => {
    if (a.to?.iso > b.to?.iso) return 1;
    if (a.to?.iso < b.to?.iso) return -1;

    return 0;
  };

  const findExchangeRate = (from, to) => {
    if (rates.length > 0 && from && to) {
      const result = rates.find(rate => rate.from === from && rate.to === to);

      if (result) return result.amount;

      return '';
    }

    return '';
  };

  const [rowSelected, setIsRowSelected] = useState(false);
  const [pairsRowDataObj, setPairsRowDataObj] = useState({});
  const [copyRowData, setCopyRowData] = useState([]);
  const [negativeSellRate, setNegativeSellRate] = useState(false);
  const classes = useStyles();
  const currencyColor = currencyColors[country];

  useEffect(() => {
    setCopyRowData(
      [...rowData]
        .sort(sortRowDataCompare)
        .map(data => ({ ...data, exchangeRate: findExchangeRate(data.from?.iso, data.to?.iso) }))
    );
  }, [rowData]);

  const savePairsData = async () => {
    const pairs = Object.keys(pairsRowDataObj);

    if (!pairs.length) {
      setWarningMessage('No changes have been made');
      setShowWarningModal(true);

      return;
    }

    if (negativeSellRate) {
      setWarningMessage('You have negative Sell Rates');
      setShowWarningModal(true);

      return;
    }

    try {
      await Promise.all(
        pairs.map(async pair => {
          const saveObj = {
            entity: entitiesEnum.PAIR,
            values: pairsRowDataObj[pair],
            id: pairsRowDataObj[pair].id,
            hasOrder: false,
          };

          const resp = await saveEntity(saveObj);

          return resp;
        })
      );
      setShowSavedModal(true);
    } catch (err) {
      setWarningMessage(err.message);
      setShowWarningModal(true);
    }
  };

  const updatePairsRowDataObj = (event, field, id) => {
    const toCountryISO = rowData.find(obj => obj.id === id)?.to?.iso;
    const label = `${country}to${toCountryISO}`;

    setPairsRowDataObj(prev => {
      const newDataObj = { ...prev };

      // If the property in the dataObj already exists
      if (newDataObj[label]) {
        const newDataObjItem = { ...newDataObj[label] };

        if (field === pairFeeTypeEnum.Percentage) {
          newDataObjItem.profitMargin = {
            type: pairFeeTypeEnum.Percentage,
            value: event.target.value,
          };
        }

        if (field === pairFeeTypeEnum.Absolute) {
          newDataObjItem.profitMargin = {
            type: pairFeeTypeEnum.Absolute,
            value: event.target.value,
          };
        }

        if (field === pairFeeTypeEnum.Percentage || field === pairFeeTypeEnum.Absolute) {
          newDataObj[label] = newDataObjItem;

          return newDataObj;
        }
        newDataObjItem[field] = event.target?.value ? event.target.value : event;
        newDataObj[label] = newDataObjItem;

        return newDataObj;
      }

      // If creating property first time
      if (field === pairFeeTypeEnum.Percentage || field === pairFeeTypeEnum.Absolute) {
        const type =
          field === pairFeeTypeEnum.Percentage
            ? pairFeeTypeEnum.Percentage
            : pairFeeTypeEnum.Absolute;
        newDataObj[label] = { id, profitMargin: { type, value: event.target.value } };

        return newDataObj;
      }
      newDataObj[label] = { id, [field]: event.target?.value ? event.target.value : event };

      return newDataObj;
    });
  };

  const handleSearchChange = event => {
    const query = event.target.value;
    if (query) {
      const regex = new RegExp(query, 'gi');
      setCopyRowData(rowData.filter(pair => pair.to.iso.match(regex) || pair.to.name.match(regex)));
    }
    if (!query) setCopyRowData([...rowData].sort(sortRowDataCompare));
  };

  const closeSavedModal = async () => {
    setShowSavedModal(false);
    await handleRefreshData();
  };

  const sellRateNegative = isNegative => {
    if (isNegative) {
      setNegativeSellRate(true);
    }
    if (!isNegative) setNegativeSellRate(false);
  };

  return (
    <>
      <div className={classes.rowContainer}>
        <div className={classes.leftContainer}>
          <SideRectangle color={currencyColor} />
          <div className={classes.textContainer}>{country}</div>
          <ReturnCountryIcon country={country} />
        </div>
        <div>
          {rowSelected ? (
            <div className={classes.rightContainer}>
              <div className={classes.searchBarContainer}>
                <div className={classes.searchIconContainer}>
                  <SearchIcon width="24px" height="24px" />
                </div>
                <input
                  type="text"
                  id="search"
                  placeholder="Search"
                  autoComplete="off"
                  onChange={handleSearchChange}
                  className={classes.searchBar}
                />
              </div>
              <div className={classes.smallSaveBtn} onClick={savePairsData}>
                <SmallSaveBtn />
              </div>
              <div
                className={classes.arrowContainer}
                onClick={() => setIsRowSelected(!rowSelected)}
              >
                <div className={classes.arrowUp}>
                  <ArrowUpIcon />
                </div>
              </div>
            </div>
          ) : (
            <div className={classes.arrowContainer} onClick={() => setIsRowSelected(!rowSelected)}>
              <ArrowDownIcon width="22px" height="12px" />
            </div>
          )}
        </div>
      </div>
      {rowSelected && (
        <>
          <div className={classes.dropDownContainer}>
            <section className={classes.selectedColumns}>
              <p>Currencies</p>
              <p style={{ marginLeft: '-14px', flex: '1' }}>Flat Rates</p>
              <p style={{ marginLeft: '1px', flex: '1' }}>Profit Margin Sell</p>
              <p style={{ marginLeft: '39px', flex: '1' }}>Sell Rates</p>
            </section>
            <div className={classes.horizRule} />
          </div>

          {copyRowData &&
            copyRowData.map(data => (
              <PairsRowDetails
                key={data.id}
                data={data}
                rate={
                  rates &&
                  rates.filter(rate => rate.from === data.from.iso && rate.to === data.to.iso)[0]
                }
                pairsRowDataObj={pairsRowDataObj}
                updatePairsRowDataObj={updatePairsRowDataObj}
                setupPage={setupPage}
                sellRateNegative={sellRateNegative}
              />
            ))}

          <div className={classes.endRowContainer}>
            <div className={classes.bigSaveBtn} onClick={savePairsData}>
              <BigSaveIcon />
              <p>Save</p>
            </div>
          </div>
        </>
      )}
      <SavedModal open={showSavedModal} onClose={closeSavedModal} />
      <WarningModal
        open={showWarningModal}
        onClose={() => setShowWarningModal(false)}
        message={warningMessage}
      />
    </>
  );
};
