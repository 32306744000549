import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(theme => ({
  riskContainer: {
    backgroundColor: 'white',
    width: '900px',
    height: 'auto',
    maxHeight: '350px',
    overflow: 'hidden',
    borderRadius: '0 15px 15px 15px',
    fontFamily: theme.typography.default,
    color: theme.palette.icons.nav,
  },
  headerContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: '16px',
    paddingTop: '25px',
    fontSize: '24px',
    fontFamily: theme.typography.default,
    fontWeight: 'bold',
    color: theme.palette.icons.nav,
    backgroundColor: 'white',
    position: "sticky",
    top: "0"
  },
  amlContainer: {
    backgroundColor: 'white',
    width: '100%',
    height: '350px',
    maxHeight: '350px',
    overflow: "scroll",
    paddingRight: "10px",
    borderRadius: '0 15px 15px 15px',
    fontFamily: theme.typography.default,
    color: theme.palette.icons.nav,
  },
  firstTable: {
    marginBottom: '40px',
  },
  firstRow: {
    position: 'sticky',
    top: '57px',
  },
  tableRow: {
    background: theme.palette.white,
    paddingBottom: '19px',
    borderBottom: '1px solid #aeabab',
    color: theme.palette.icons.nav,
    whiteSpace: 'nowrap',
    padding: '1.2rem',
    fontSize: '16px',
    zIndex: 3,
  },
  tableCell: {
    backgroundColor: 'white',
    fontSize: '14px',
    color: theme.palette.icons.nav,
    width: '240px',
  },
}));
