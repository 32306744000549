/* eslint-disable react/jsx-no-useless-fragment */
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import React, { useEffect, useRef, useState } from 'react';

import { graphqlApiDecorator } from '../../../../decorators';
import { mutations, queries } from '../../../../graphql';
import { getCurrentUser } from '../../../../services';
import { getFormattedDisplayDate, getFormattedDisplayTime } from '../../../../utils';
import { CancelIcon, EditIcon } from '../../../icons';
import { NotificationBlank } from './blank-templates';
import { emailTemplateKixy } from './blank-templates/emailTemplateKixy';
import { EmailTemplateEdit } from './emailTemplateEdit';
import { ShowEmailDetail } from './showEmailDetail';
import {
  ChatIcon,
  EmailIcon,
  NotificationBell,
  NotificationTemp,
  SendButton,
  SideRectangle,
} from './icons';
import { DeleteModal, SavedModal, WarningModal } from './modals';
import { useStyles } from './notifications.hooks';
import { async } from 'validate.js';
/*
  Notifications section in the CustomerDetailsPage

  The admin can send either an app and/or SMS notification to the customer. The admin can
  select message templates for convenience to construct a message.

  The notifications data are saved in two different tables. This is why there are two
  queries performed (getUserNotificationsBo and getUserSMSNotificationsBo) and the data is
  concatenated and sorted by date for the result.
 */

/**
 * Sub-component of the CustomerDetailPage parent component. This renders the app and sms
 * notifications that have been sent to the customer. Features include sending app, sms and email
 * notifications to customer. Email notifications are sent from no-reply@kixy.com
 * When sending an sms or app notification, you can select a list of templates that construct a part of the message
 * you want to send.
 * For email notifications, at the time of writing there is only one template constructed from html
 * using a mailChimp template and has been tested for responsiveness for different devices and mail clients.
 * @param {object} data the user profile data of type userProfile
 */
export const Notifications = ({ data }) => {
  const [userNotifications, setUserNotifications] = useState([]);
  const [notificationBellSelected, setNotificationBellSelected] = useState(false);
  const [emailSelected, setEmailSelected] = useState(false);
  const [smsSelected, setsmsSelected] = useState(false);
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(true);
  const [showSavedModal, setShowSavedModal] = useState(false);
  const [showWarningModal, setShowWarningModal] = useState(false);
  const [header, setHeader] = useState('');
  const [content, setContent] = useState('');
  const [anchorTemplateEl, setAnchorTemplateEl] = useState(null);
  const openTemplateEl = Boolean(anchorTemplateEl);
  const [anchorEmailTemplateEl, setAnchorEmailTemplateEl] = useState(false);
  const openEmailTemplateEl = Boolean(anchorEmailTemplateEl);
  const [emailSubject, setEmailSubject] = useState('');
  const [emailTitle, setEmailTitle] = useState('');
  const [messageTo, setMessageTo] = useState('');
  const [closingMessage, setClosingMessage] = useState('');
  const [emailContent, setEmailContent] = useState('');
  const [from, setFrom] = useState('');
  const [emailDone, setEmailDone] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showEmailDetail, setShowEmailDetail] = useState(false);
  const [getEmailDetail, setgetEmailDetail] = useState({
    title: '',
    body: '',
    htmlPart: [],
    notifSendApproach: '',
  });

  const classes = useStyles();
  const inputRef = useRef(null);

  const LIST_EMAIL_TEMPLATES = 'listEmailTemplates';
  const CREATE_EMAIL_TEMPLATE = 'createEmailTemplate';
  const SEND_EMAIL_TEMPLATE = 'sendEmailTemplate';
  const EDIT_EMAIL_TEMPLATE = 'editEmailTemplate';
  const STANDARD_EMAIL_TEMPLATE = 'standardEmailTemplate';
  const TEMPLATE_1_MSG = `Dear ${data.name} ${data.lastName}, your transfer to NAME was successful. Amount: £AMOUNT`;
  const TEMPLATE_2_MSG = `Dear ${data.name} ${data.lastName}, you received a transfer from NAME. Amount: £AMOUNT`;
  const TEMPLATE_3_MSG = `Dear ${data.name} ${data.lastName}, your exchange from CURRENCY to CURRENCY was successful. Amount: £AMOUNT`;
  const handleTemplateElClick = () => {
    setAnchorTemplateEl(inputRef.current);
  };

  const handleCloseTemplateEl = () => {
    setAnchorTemplateEl(null);
  };

  const handleEmailTemplateElClick = () => {
    if (!notificationBellSelected && !smsSelected) {
      setAnchorEmailTemplateEl(inputRef.current);
      setEmailSelected(true);
    }
  };

  const handleCloseEmailTemplateEl = () => {
    setEmailSelected(false);
    setAnchorEmailTemplateEl(null);
  };

  const handleClickDone = () => {
    if (emailSubject && emailContent) {
      setAnchorEmailTemplateEl(null);
      setEmailDone(true);
      setEmailSelected(true);

      return;
    }
    setContent('Please fill subject and content');
    setShowWarningModal(true);
  };

  const handleDeleteEmailBtn = () => {
    setHeader('Delete Email');
    setContent('Are you sure you want to delete?');
    setShowDeleteModal(true);
  };

  const handleCancelEmail = () => {
    setEmailDone(false);
    setEmailSubject('');
    setEmailTitle('');
    setEmailContent('');
    setMessageTo('');
    setClosingMessage('');
    setFrom('');
    setEmailSelected(false);
    setShowDeleteModal(false);
  };

  const handleShowEmailNotification = async id => {
    setShowEmailDetail(true);
    const queryTitle = 'getNotificationById';
    try {
      const result = await graphqlApiDecorator(queries, queryTitle, { id });
      const htmlPart = JSON.parse(result.meta).htmlPart;
      console.log(result);
      setgetEmailDetail({
        title: result.title,
        body: result.body,
        htmlPart,
        notifSendApproach: result.notifSendApproach,
      });
    } catch (err) {
      setContent(err.message);
    }
  };

  const handleCloseEmailNotification = () => {
    setShowEmailDetail(false);
  };

  const fetchUserNotifications = async id => {
    const queryTitle = 'getUserNotificationsBo';
    const querySMSTitle = 'getUserSMSNotificationsBo';
    try {
      const result = await graphqlApiDecorator(queries, queryTitle, { id });
      const resultSMS = await graphqlApiDecorator(queries, querySMSTitle, { id });

      // The getUserSMSNotificationsBo has a createAt field, so need to modify it to createdAt to sort
      // Also the date is in a YYYY/MM/DDTHH:MM:SS format so need to change it to YYYY-MM-DDTHH:MM:SS for
      // the Date object to compare
      const resultSMSCreatedAt = resultSMS.map(item => {
        const resultItem = { ...item };
        resultItem.createdAt = item.createAt?.replace(/\//g, '-');

        delete resultItem.createAt;

        return resultItem;
      });
      if (result !== null && resultSMS !== null) {
        setUserNotifications(
          result
            .concat(resultSMSCreatedAt)
            .sort((a, b) => new Date(b?.createdAt) - new Date(a?.createdAt))
        );
      }

      setLoading(false);
    } catch (err) {
      setLoading(false);
      setContent(err.message);
      setShowWarningModal(true);
    }
  };

  useEffect(() => {
    fetchUserNotifications(data.id);
  }, [data]);

  const sendNotification = async event => {
    event.preventDefault();
    if (message && !notificationBellSelected && !smsSelected) {
      setContent('Please select notification bell and/or SMS icon for message');
      setShowWarningModal(true);

      return;
    }
    if (emailDone) {
      // for indentation
      const regex = /\n/g;
      const formattedEmailContent = emailContent.replace(regex, '<br>');
      const htmlPart = emailTemplateKixy({
        subject: emailSubject,
        ...(emailTitle && { mainTitle: emailTitle }),
        content: formattedEmailContent,
        ...(messageTo && { messageTo }),
        ...(closingMessage && { closingMessage }),
        ...(from && { from }),
      });

      // Would need to sanitize the htmlPart

      const EmailTemplateInput = {
        templateName: STANDARD_EMAIL_TEMPLATE,
        htmlPart,
        subjectPart: emailSubject,
        textPart: emailContent,
      };

      try {
        const emailTemplates = await graphqlApiDecorator(queries, LIST_EMAIL_TEMPLATES);
        if (
          emailTemplates.findIndex(
            template => template.TemplateName === STANDARD_EMAIL_TEMPLATE
          ) === -1
        ) {
          // Email template does not exist so create one
          await graphqlApiDecorator(mutations, CREATE_EMAIL_TEMPLATE, {
            input: EmailTemplateInput,
          });
        } else {
          // Email template exists so need to edit it before sending it
          await graphqlApiDecorator(mutations, EDIT_EMAIL_TEMPLATE, { input: EmailTemplateInput });
        }

        setLoading(true);
        await graphqlApiDecorator(mutations, SEND_EMAIL_TEMPLATE, {
          templateName: STANDARD_EMAIL_TEMPLATE,
          htmlPart,
          sendTo: data.id,
        });

        setHeader('Email sent');
        setContent(`Email sent to ${data.name} ${data.lastName}`);
        setLoading(false);
        setShowSavedModal(true);
      } catch (error) {
        setLoading(false);
        setContent(error.message);
        setShowWarningModal(true);
      }

      return;
    }

    if (message && (notificationBellSelected || smsSelected)) {
      // send message as info
      const modalMessage = [];

      if (notificationBellSelected) {
        try {
          const mutationTitle = 'createNotification';
          const input = {
            type: 'info',
            title: message,
            notificationUserId: data.id,
          };

          const result = await graphqlApiDecorator(mutations, mutationTitle, { input });
          if (result.success) modalMessage.push('App Notification Sent');
          if (!result.success) throw new Error('Error in sending app notification');
        } catch (error) {
          setContent(error.message);
          setShowWarningModal(true);

          return;
        }
      }

      if (smsSelected) {
        try {
          const mutationTitle = 'sendSMSNotification';
          const currentUser = await getCurrentUser();
          const inputT = {
            notificationUserId: data.id,
            body: message,
            type: 'info',
            sendApproach: 'SMS',
          };

          const result = await graphqlApiDecorator(mutations, mutationTitle, { input: inputT });
          if (result.success) modalMessage.push('SMS Notification Sent');
          if (!result.success) throw new Error('Error in sending SMS notification');
        } catch (error) {
          setContent(error.message);
          setShowWarningModal(true);

          return;
        }
      }

      setHeader('Notifications sent');
      setContent(modalMessage.join('\r\n'));
      setShowSavedModal(true);
    }
    setMessage('');
    await fetchUserNotifications(data.id);
  };

  return (
    <div>
      <div className={classes.label}>
        <SideRectangle />
        <header>Notifications</header>
      </div>
      <div className={classes.instructions}>
        <p>Enter a new message. The user will be notified:</p>
      </div>
      <div className={classes.notificationsContainer}>
        {loading ? (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: '5%',
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <>
            {userNotifications.length ? (
              userNotifications.map((notif, index) => (
                <div
                  key={notif.id}
                  className={
                    index % 2 === 0
                      ? classes.notificationBubble
                      : `${classes.notificationBubble} ${classes.alternativeBubble}`
                  }
                >
                  {notif.notifSendApproach == null && (
                    <div className={classes.bubbleSideIcon}>
                      <NotificationBell width="16px" height="16px" active />
                    </div>
                  )}
                  {notif.notifSendApproach == 'SMS' && (
                    <div className={classes.bubbleSideIcon}>
                      <ChatIcon width="16px" height="16px" active />
                    </div>
                  )}
                  {notif.notifSendApproach == 'EMAIL' && (
                    <div className={classes.bubbleSideIcon} aria-controls="email-template">
                      <EmailIcon width="16px" height="16px" active />
                    </div>
                  )}
                  <Menu
                    id="email-template"
                    // anchorEl={anchorEmailTemplateEl}
                    open={showEmailDetail}
                    sx={{
                      '& .MuiPaper-root': {
                        backgroundColor: 'white',
                        borderRadius: '15px 0 15px 15px',
                        width: '818px',
                      },
                    }}
                    anchorOrigin={{ vertical: 'center', horizontal: 'center' }}
                    transformOrigin={{ horizontal: 'center', vertical: 'center' }}
                    onClose={handleCloseEmailNotification}
                    MenuListProps={{
                      'aria-labelledby': 'email_btn',
                    }}
                  >
                    <ShowEmailDetail emailDetail={getEmailDetail} />
                  </Menu>
                  <p
                    style={{
                      width: '86%',
                      cursor:
                        notif.notifSendApproach == 'EMAIL' || notif.notifSendApproach == 'SMS'
                          ? 'pointer'
                          : '',
                    }}
                    onClick={
                      notif.notifSendApproach == 'EMAIL' || notif.notifSendApproach == 'SMS'
                        ? () => handleShowEmailNotification(notif.id)
                        : null
                    }
                  >
                    {notif.title || notif.text}
                  </p>
                  <div className={classes.timeStamp}>
                    {notif.createdAt && (
                      <p>{`${getFormattedDisplayDate(notif.createdAt)}, ${getFormattedDisplayTime(
                        notif.createdAt
                      )} `}</p>
                    )}
                  </div>
                </div>
              ))
            ) : (
              <div className={classes.notificationBlank}>
                {[...Array(5).keys()].map(item => (
                  <div key={`${item}`} className={classes.notifBlank}>
                    <NotificationBlank />
                  </div>
                ))}
              </div>
            )}
          </>
        )}
      </div>
      <form className={classes.messageInputContainer} onSubmit={sendNotification}>
        <Button
          id="email_btn"
          sx={{ maxHeight: '24px', maxWidth: '24px', borderRadius: '24px' }}
          aria-haspopup="true"
          aria-controls={openEmailTemplateEl ? 'basic-menu' : undefined}
          className={classes.emailIcon}
          aria-expanded={openEmailTemplateEl ? 'true' : undefined}
          onClick={handleEmailTemplateElClick}
        >
          <EmailIcon width="24" height="24" active={emailSelected} />
        </Button>
        <Menu
          id="email-template"
          anchorEl={anchorEmailTemplateEl}
          open={openEmailTemplateEl}
          sx={{
            '& .MuiPaper-root': {
              backgroundColor: 'white',
              borderRadius: '15px 0 15px 15px',
              width: '818px',
            },
          }}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          transformOrigin={{ horizontal: 'center', vertical: 'top' }}
          onClose={handleCloseEmailTemplateEl}
          MenuListProps={{
            'aria-labelledby': 'email_btn',
          }}
        >
          <EmailTemplateEdit
            emailSubject={emailSubject}
            setEmailSubject={setEmailSubject}
            emailContent={emailContent}
            setEmailContent={setEmailContent}
            emailTitle={emailTitle}
            setEmailTitle={setEmailTitle}
            handleClickDone={handleClickDone}
            messageTo={messageTo}
            setMessageTo={setMessageTo}
            closingMessage={closingMessage}
            setClosingMessage={setClosingMessage}
            from={from}
            setFrom={setFrom}
          />
        </Menu>
        <div
          className={classes.notificationBell}
          style={{ cursor: emailDone ? 'auto' : 'pointer' }}
          onClick={() => {
            if (!emailDone && !smsSelected) setNotificationBellSelected(!notificationBellSelected);
          }}
        >
          <NotificationBell width="24" height="24" active={notificationBellSelected} />
        </div>
        <div
          className={classes.chatIcon}
          style={{ cursor: emailDone ? 'auto' : 'pointer' }}
          onClick={() => {
            if (!emailDone && !notificationBellSelected) setsmsSelected(!smsSelected);
          }}
        >
          <ChatIcon width="24" height="24" active={smsSelected} />
        </div>
        <input
          type="text"
          ref={inputRef}
          value={message}
          onChange={event => setMessage(event.target.value)}
          disabled={emailDone}
        />
        {emailDone && (
          <div className={classes.savedEmailTab}>
            <p>{emailSubject}</p>
            <button
              type="button"
              className={classes.editEmailBtn}
              onClick={handleEmailTemplateElClick}
            >
              <EditIcon />
            </button>
            <button type="button" className={classes.cancelEmailBtn} onClick={handleDeleteEmailBtn}>
              <CancelIcon />
            </button>
          </div>
        )}
        <Button
          id="notif_temp_btn"
          aria-controls={openTemplateEl ? 'basic-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={openTemplateEl ? 'true' : undefined}
          onClick={handleTemplateElClick}
          className={classes.notifTempBtn}
          disabled={emailDone}
        >
          <NotificationTemp />
        </Button>
        <Menu
          id="basic-menu"
          anchorEl={anchorTemplateEl}
          open={openTemplateEl}
          sx={{
            '& .MuiPaper-root': {
              backgroundColor: '#ebfaff',
              borderRadius: '15px 0 15px 15px',
              width: '818px',
            },
          }}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          transformOrigin={{ horizontal: 'center', vertical: 'top' }}
          onClose={handleCloseTemplateEl}
          MenuListProps={{
            'aria-labelledby': 'notif_temp_btn',
          }}
        >
          {[TEMPLATE_1_MSG, TEMPLATE_2_MSG, TEMPLATE_3_MSG].map(item => (
            <MenuItem
              key={item}
              sx={{
                backgroundColor: 'white',
                marginLeft: '16px',
                marginBottom: '4px',
                marginRight: '16px',
                borderRadius: '0 16px 16px 16px',
                '&:hover': {
                  border: '1px solid #1fe3ac',
                  backgroundColor: 'white',
                },
              }}
              onClick={() => {
                setMessage(item);
                handleCloseTemplateEl();
              }}
            >
              <p>{item}</p>
            </MenuItem>
          ))}
        </Menu>
        <button type="submit" className={classes.sendBtn} onClick={sendNotification}>
          <SendButton />
        </button>
      </form>
      <SavedModal
        open={showSavedModal}
        onClose={() => setShowSavedModal(false)}
        header={header}
        content={content}
      />
      <WarningModal
        open={showWarningModal}
        confirm={() => setShowWarningModal(false)}
        message={content}
      />
      <DeleteModal
        open={showDeleteModal}
        cancel={() => setShowDeleteModal(false)}
        deleteIt={handleCancelEmail}
        onClose={() => setShowDeleteModal(false)}
        header={header}
        content={content}
      />
    </div>
  );
};
