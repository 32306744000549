import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
  label: {
    display: 'flex',
    flexDirection: 'row',
    gap: '8px',
  },
  instruction: {
    fontSize: '14px',
    paddingLeft: '16px',
    marginTop: '8px',
    marginBottom: '8px',
  },
  notesContainer: {
    margin: '16px',
    fontSize: '12px',
    width: 'auto',
    overflowY: 'auto',
    height: '147px',
  },
  topOfNote: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: '8px',
  },
  alternateColor: {
    backgroundColor: '#fafafb',
    padding: '9px',
    height: '32px',
    width: '100%',
    marginBottom: '8px',
    borderRadius: '0 16px 16px 16px',
  },
  noteBubble: {
    backgroundColor: '#ebfaff',
    padding: '9px',
    height: '32px',
    width: '100%',
    marginBottom: '8px',
    borderRadius: '0 16px 16px 16px',
  },
  noteInputContainer: {
    position: 'absolute',
    bottom: '16px',
    width: '100%',
    marginTop: '24px',
    padding: '0 16px',
    '& input[type="text"]': {
      height: '48px',
      width: '100%',
      borderRadius: '24px',
      border: '1px solid #f2f2f4',
      fontSize: '14px',
      padding: '8px 16px',
    },
    '& input[type="text"]:focus, textarea': {
      outline: 'none !important',
    },
  },
  sendBtn: {
    position: 'absolute',
    right: '24px',
    top: '8px',
    cursor: 'pointer',
    border: 'none',
    borderRadius: '16px',
    backgroundColor: 'white',
  },
  blankMessages: {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    width: '100%',
  },
}));
