import React from 'react';

export const PairsDropDownArrow = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="9" height="5" viewBox="0 0 9 5">
    <path
      data-name="Path 16694"
      d="M4 4.5a.5.5 0 0 1-.354-.146l-4-4a.5.5 0 0 1 0-.707.5.5 0 0 1 .707 0L4 3.293 7.646-.354a.5.5 0 0 1 .707 0 .5.5 0 0 1 0 .707l-4 4A.5.5 0 0 1 4 4.5z"
      transform="translate(.5 .5)"
      style={{ fill: '#19365e' }}
    />
  </svg>
);
