import React from 'react';

export const SEK = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48">
    <defs>
      <clipPath id="zkrhyz3gia">
        <path data-name="Rectangle 17810" d="M0 0h48v48H0z" style={{ fill: '#fff' }} />
      </clipPath>
    </defs>
    <g data-name="Group 8518" style={{ clipPath: 'url(#zkrhyz3gia)' }}>
      <path
        data-name="Path 19906"
        d="M15527.6 8256a24 24 0 1 0-24-24 24 24 0 0 0 24 24z"
        transform="translate(-15503.603 -8208)"
        style={{ fill: '#ffda44' }}
      />
      <path
        data-name="Path 19907"
        d="M15703.9 8228.87h29.014a24.013 24.013 0 0 0-29.014-20.3v20.3z"
        transform="translate(-15685.125 -8208)"
        style={{ fill: '#0052b4' }}
      />
      <path
        data-name="Path 19908"
        d="M15518.021 8257.082v-17.952a24.023 24.023 0 0 0-12.318 17.952z"
        transform="translate(-15505.505 -8236.212)"
        style={{ fill: '#0052b4' }}
      />
      <path
        data-name="Path 19909"
        d="M15518.021 8497.391h-12.321a24.021 24.021 0 0 0 12.318 17.951z"
        transform="translate(-15505.505 -8470.261)"
        style={{ fill: '#0052b4' }}
      />
      <path
        data-name="Path 19910"
        d="M15703.9 8497.391v20.3a24.009 24.009 0 0 0 29.014-20.3z"
        transform="translate(-15685.125 -8470.261)"
        style={{ fill: '#0052b4' }}
      />
    </g>
  </svg>
);
