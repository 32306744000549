export const awsConfig = {
  amplify: {
    Auth: {
      userPoolId: process.env.REACT_APP_USER_POOL_ID,
      userPoolWebClientId: process.env.REACT_APP_CLIENT_ID,
      region: process.env.REACT_APP_REGION,
      identityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID,
    },
    API: {
      aws_project_region: process.env.REACT_APP_REGION,
      aws_appsync_graphqlEndpoint: process.env.REACT_APP_GRAPHQL_ENDPOINT,
      aws_appsync_region: process.env.REACT_APP_REGION,
      aws_appsync_authenticationType: process.env.REACT_APP_AUTH_TYPE,
    },
    Storage: {
      bucket: process.env.REACT_APP_BUCKET,
      imagesCloudfront: '',
      region: process.env.REACT_APP_REGION,
      identityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID,
    },
  },
  executeApi: process.env.REACT_APP_AWS_EXECUTE_API,
};
