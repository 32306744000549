import React from 'react';

export const USD = ({ width = '45', height = '45' }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 48 48">
    <defs>
      <clipPath id="ddlz1tdowa">
        <path data-name="Rectangle 17811" style={{ fill: '#fff' }} d="M0 0h48v48H0z" />
      </clipPath>
    </defs>
    <g data-name="Group 8523" style={{ clipPath: 'url(#ddlz1tdowa)' }}>
      <path
        data-name="Path 19911"
        d="M9144.5 10991.831c13.53 0 24.5-10.706 24.5-23.916s-10.97-23.915-24.5-23.915-24.5 10.706-24.5 23.915 10.969 23.916 24.5 23.916z"
        transform="translate(-9120.6 -10943.534)"
        style={{ fill: '#f0f0f0' }}
      />
      <path
        data-name="Path 19912"
        d="M9364.872 11139.44h25.563a23.458 23.458 0 0 0-.844-6.241h-24.72z"
        transform="translate(-9342.04 -11115.059)"
        style={{ fill: '#d80027' }}
      />
      <path
        data-name="Path 19913"
        d="M9364.872 11005.932h21.968a24.316 24.316 0 0 0-5.653-6.23h-16.314z"
        transform="translate(-9342.04 -10994.031)"
        style={{ fill: '#d80027' }}
      />
      <path
        data-name="Path 19914"
        d="M9231.89 11405.5a24.736 24.736 0 0 0 15.251-5.2h-30.5a24.731 24.731 0 0 0 15.249 5.2z"
        transform="translate(-9207.994 -11357.208)"
        style={{ fill: '#d80027' }}
      />
      <path
        data-name="Path 19915"
        d="M9131.572 11273.041h41.807a23.632 23.632 0 0 0 2.753-6.241h-47.31a23.656 23.656 0 0 0 2.75 6.241z"
        transform="translate(-9128.578 -11236.178)"
        style={{ fill: '#d80027' }}
      />
      <path
        data-name="Path 19916"
        d="M9131.35 10947.737h2.231l-2.077 1.468.795 2.389-2.078-1.476-2.076 1.476.685-2.062a24.237 24.237 0 0 0-4.751 5.175h.717l-1.321.935a22.55 22.55 0 0 0-.595 1.019l.632 1.9-1.177-.832a21.993 21.993 0 0 0-.8 1.859l.694 2.083h2.569l-2.076 1.477.792 2.381-2.077-1.475-1.242.887a23.408 23.408 0 0 0-.191 2.979h24.5V10944a24.83 24.83 0 0 0-13.154 3.737zm.949 17.786-2.078-1.475-2.076 1.475.794-2.381-2.077-1.477h2.567l.792-2.382.793 2.382h2.567l-2.077 1.477zm-.795-9.35.795 2.382-2.078-1.467-2.076 1.467.794-2.382-2.077-1.468h2.567l.792-2.391.793 2.391h2.567zm9.582 9.35-2.076-1.475-2.079 1.475.8-2.381-2.078-1.477h2.568l.793-2.382.792 2.382h2.567l-2.078 1.477zm-.795-9.35.795 2.382-2.076-1.467-2.079 1.467.8-2.382-2.078-1.468h2.568l.793-2.391.792 2.391h2.567zm0-6.969.795 2.389-2.076-1.476-2.079 1.476.8-2.389-2.078-1.468h2.568l.793-2.383.792 2.383h2.567z"
        transform="translate(-9120.6 -10943.534)"
        style={{ fill: '#0052b4' }}
      />
    </g>
  </svg>
);
