import React from 'react';

import { useStyles } from '../multicurrency.hooks';

export const FXTradeButton = ({ onClick, title='Order FX Trade' , isDisabled}) => {
  const classes = useStyles();

  return (
    <button data-disabled={isDisabled} className={classes.FXTrade} type="button" onClick={onClick}>
        {title}
    </button>
  );
};
