import React from 'react';

export const CheckedIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
    <g data-name="Group 10166" transform="translate(-752 -168)">
      <rect
        data-name="Rectangle 18965"
        width="24"
        height="24"
        rx="4"
        transform="translate(752 168)"
        style={{ fill: '#1fe3ac' }}
      />
      <path
        data-name="Path 19571"
        d="M-73.138 1875.253a1.268 1.268 0 0 1-.93-.406l-3.557-3.839a1.268 1.268 0 0 1 .068-1.792 1.268 1.268 0 0 1 1.792.068l2.627 2.835 6.031-6.509a1.268 1.268 0 0 1 1.792-.068 1.268 1.268 0 0 1 .068 1.792l-6.961 7.512a1.268 1.268 0 0 1-.93.407z"
        transform="translate(834.962 -1690.205)"
        style={{ fill: '#fff' }}
      />
    </g>
  </svg>
);
