import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
  requirementItem: {
    backgroundColor: '#fbfbfb',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignItems: 'center',
    height: '64px',
    '& div': {
      flex: '1',
    },
    '&:hover': {
      backgroundColor: '#f3fffc',
      borderBottomStyle: 'dashed',
      borderBottom: '1px solid #1fe3ac',
    },
    borderBottom: '1px solid #e6e6e6',
    breakInside: 'avoid',
    webkitColumnBreakInside: 'avoid',
    pageBreakInside: 'avoid',
  },
  fieldName: {
    paddingLeft: '16px',
  },
  radioButtonsContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: '32px',
    '& div': {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      gap: '8px',
    },
    marginRight: '84.5px',
    '& input[type="radio"]': {
      width: '15px',
      height: '15px',
      border: '1px solid #a5a6ae',
      borderRadius: '50%',
      outline: 'none',
    },
    '& input[type="radio"]:before': {
      content: '""',
      display: 'block',
      width: '60%',
      height: '60%',
      margin: '20% auto',
      borderRadius: '50%',
    },
    '& input[type="radio"]:checked:before': {
      background: '#1fe3ac',
    },
    '& input[type="radio"]:checked': {
      background: '#1fe3ac',
    },
    '& input[type="radio"]:checked + label': {
      color: '#19365e',
    },
    '& label': {
      color: '#a5a6ae',
    },
  },
}));
