import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(theme => ({
  groupCard: {
    marginBottom: '3.2rem',
    borderRadius: '0.4rem',
    padding: '3rem',
    boxShadow: 'none',
  },
  terciaryButton: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.dark,
  },
  buttonGrid: {
    margin: '1rem',
  },
  headerContent: {
    height: '6.4rem',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    marginTop: '0.8rem',
    backgroundColor: theme.palette.white,
    padding: '0 2.8rem',
    borderRadius: '0.4rem',
    justifyContent: 'space-between',
  },
  backButtonWrapper: {
    marginRight: '1.6rem',
    display: 'flex',
    justifyContent: 'center',
  },
  saveButton: {
    '& button': {
      width: '100%',
      justifyContent: 'center',
    },
  },
}));
