import React from 'react';

export const NOK = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="48" height="47.996" viewBox="0 0 48 47.996">
    <g data-name="Group 8512">
      <path
        data-name="Path 10193"
        d="M10968 3695.995a24 24 0 1 0-24-24 24 24 0 0 0 24 24z"
        transform="translate(-10944.002 -3648)"
        style={{ fill: '#f0f0f0' }}
      />
      <path
        data-name="Path 10194"
        d="M10952.8 3970.78a23.565 23.565 0 0 0 8.614 12.542v-12.542z"
        transform="translate(-10951.969 -3940.192)"
        style={{ fill: '#d80027' }}
      />
      <path
        data-name="Path 10195"
        d="M11177.7 3988.1c.7.058 1.395.089 2.1.089a24.045 24.045 0 0 0 23.3-17.408h-25.4z"
        transform="translate(-11155.925 -3940.192)"
        style={{ fill: '#d80027' }}
      />
      <path
        data-name="Path 10196"
        d="M11203.1 3665.407a24.046 24.046 0 0 0-23.3-17.407c-.707 0-1.4.031-2.1.09v17.318z"
        transform="translate(-11155.925 -3648)"
        style={{ fill: '#d80027' }}
      />
      <path
        data-name="Path 10197"
        d="M10961.413 3700.9a23.565 23.565 0 0 0-8.614 12.541h8.614z"
        transform="translate(-10951.969 -3696.033)"
        style={{ fill: '#d80027' }}
      />
      <path
        data-name="Path 10198"
        d="M10991.8 3674.387h-29.017v-20.307a23.809 23.809 0 0 0-6.253 2.349v17.957h-12.32a24.123 24.123 0 0 0 0 6.263h12.32v17.958a23.841 23.841 0 0 0 6.253 2.349v-20.306h29.017a23.9 23.9 0 0 0 0-6.263z"
        transform="translate(-10944.002 -3653.521)"
        style={{ fill: '#0052b4' }}
      />
    </g>
  </svg>
);
