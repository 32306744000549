import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(theme => ({
  root: {
    fontFamily: theme.typography.default,
    color: theme.palette.icons.nav,
    display: 'inline-grid',
    gridTemplateRows: 'auto 1fr',
  },
  toolbar: {
    display: 'inline-block',
    top: '0px',
    backgroundColor: 'white',
    position: 'sticky',
    zIndex: '2',
    height: '112px',
    width: 'auto',
    overflowX: 'hidden',
    overflowY: 'hidden',
    borderRadius: '0 0 15px 15px',
    padding: '29px 24px 16px 48px',
    boxShadow: '0 2px 16px 0 rgba(0, 0, 0, 0.04)',
    '& header': {
      fontSize: '20px',
      fontWeight: 'bold',
    },
    '& aside': {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      fontSize: '14px',
      marginTop: '4px',
    },
    '& div': {
      cursor: 'pointer',
    },
    '& .limitationBorder': {
      borderLeft: '2px solid #093560',
      cursor: 'default',
      marginLeft: '20px',

      '& .limitation': {
        width: '160px',
        height: '32px',
        borderRadius: '16px',
        backgroundColor: '#c6ffef',
        fontWeight: '500',
        fontSize: '14px',
        textAlign: 'center',
        paddingTop: '8px',
        cursor: 'pointer',
        marginLeft: '30px',
      },
    },
  },
  inlineBlock: {
    display: 'inline-block',
  },
  gridContainer: {
    display: 'grid',
    gridTemplateColumns: '1fr 2fr 1fr',
    gridTemplateRows: 'auto 320px 1fr auto',
    gap: '24px',
    padding: '24px',
  },
  gridItem: {
    backgroundColor: 'white',
    borderRadius: '0px 16px 16px 16px',
    boxShadow: '0 2px 16px 0 rgba(0, 0, 0, 0.04)',
    padding: '16px 0px 16px 0',
    '& header': {
      fontSize: '24px',
      fontWeight: 'bold',
    },
  },
  accountContainer: {
    gridColumnStart: '1',
    gridColumnEnd: '2',
    gridRowStart: '1',
    gridRowEnd: '4',
  },
  walletsContainer: {
    gridColumnStart: '2',
    gridColumnEnd: '3',
    gridRowStart: '1',
    gridRowEnd: '2',
    minWidth: '850px',
  },
  infoContainer: {
    gridColumnStart: '3',
    gridColumnEnd: '4',
    gridRowStart: '1',
    gridRowEnd: '3',
  },
  noteContainer: {
    position: 'relative',
    gridColumnStart: '2',
    gridColumnEnd: '3',
    gridRowStart: '2',
    gridRowEnd: '3',
  },
  notificationsContainer: {
    gridColumnStart: '2',
    gridColumnEnd: '3',
    gridRowStart: '3',
    gridRowEnd: '4',
  },
  activityLogsContainer: {
    gridColumnStart: '2',
    gridColumnEnd: '3',
    gridRowStart: '4',
    gridRowEnd: '5',
  },
  managePaymentsContainer: {
    gridColumnStart: '3',
    gridColumnEnd: '4',
    gridRowStart: '3',
    gridRowEnd: '4',
  },
  transactionsContainer: {
    gridColumnStart: '1',
    gridColumnEnd: '4',
    gridRowStart: '5',
    gridRowEnd: '6',
  },
  beneficiaryContainer: {
    gridColumnStart: '3',
    gridColumnEnd: '4',
    gridRowStart: '3',
    gridRowEnd: '5',
  },
}));
