import { Button } from '@mui/material';
import clsx from 'clsx';
import React from 'react';

import { useStyles } from './main-button.hooks';

export const MainButton = ({ onClick, icon, text, maxWidth = false, type = 'button', design }) => {
  const classes = useStyles();

  return (
    <Button
      className={clsx(classes[design], classes.additional)}
      classes={{ root: maxWidth ? classes.maxWidth : classes.adaptWidth }}
      variant="contained"
      onClick={onClick}
      type={type}
    >
      {icon && <div className={classes.icon}>{icon}</div>}
      {text}
    </Button>
  );
};
