import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(theme => ({
  root: {
    borderRadius: '0 16px 16px 16px',
    padding: '0 2rem 1rem',
    boxShadow: 'none',
    overflowX: 'scroll',
    position: 'relative',
  },
  inner: {
    minWidth: '105rem',
  },
  pageNumber: {
    display: 'flex',
    alignItems: 'center',
    padding: '0.5rem 1.6rem',
  },
  content: {
    overflowY: 'scroll',
    height: 'inherit',
    padding: 0,
    paddingTop: '1rem',
    '&:last-child': {
      padding: 0,
    },
    '& .MuiGrid-container': {
      marginTop: 0,
    },
  },
  backgroundTransaction: {
    backgroundColor: theme.palette.transactions.background,
  },
  backgroundCurrencies: {
    marginTop: '-10px',
    backgroundColor: theme.palette.transactions.background,
  },
  backgroundCustomers: {
    marginTop: '-10px',
    borderRadius: '0 16px 16px 16px',
    backgroundColor: theme.palette.transactions.background,
    boxSizing: 'content-box',
  },
  tableRoot: {
    borderSpacing: '0 1rem',
    borderCollapse: 'separate',
  },
  tableRootCountryEntity: {
    borderSpacing: '0px 0px',
    borderCollapse: 'separate',
  },
  paginationButton: {
    fontSize: '1.4rem',
    padding: 0,
    borderRadius: '0.4rem',
    minWidth: '8.5rem',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      opacity: '0.85',
    },
  },
  moreContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    background: theme.palette.transactions.background,
    marginTop: '24px',
  },
  moreButton: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    background: '#c6ffef',
    width: '80px',
    height: '24px',
    borderRadius: '12px',
    color: theme.palette.icons.nav,
    fontSize: '14px',
    fontFamily: theme.typography.default,
    cursor: 'pointer',
  },
  paginationContainer: {
    display: 'flex',
    flexDirection: 'row',
    fontSize: '16px',
    fontFamily: theme.typography.default,
    gap: '8px',
    justifyContent: 'center',
    alignContent: 'center',
    paddingTop: '28px',
    paddingBottom: '10px',
    '& button': {
      border: 'none',
      backgroundColor: '#f2f2f4',
    },
  },
  btnLink: {
    height: '24px',
    width: '24px',
    borderRadius: '24px',
    '&:hover': {
      backgroundColor: '#f2f2f4',
    },
  },
  activeLink: {
    color: '#1fe3ac',
    cursor: 'pointer',
  },
  inactiveLink: {
    color: '#a5a6ae',
    cursor: 'auto',
  },
  pageNumbers: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '8px',
    listStyleType: 'none',
    color: theme.palette.icons.nav,
    '& li': {
      cursor: 'pointer',
    },
  },
  activePage: {
    color: '#1fe3ac',
  },
}));
