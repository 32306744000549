import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    background: '#e9fff9',
    fontFamily: theme.typography.default,
    color: theme.palette.icons.nav,
    height: '56px',
    borderRadius: '0 8px 8px 8px',
    position: 'relative',
    top: '-16px',
    boxShadow: '0 0 6px 0 rgba(0, 0, 0, 0.08)',
  },
  root: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingLeft: '15px',
    '& aside': {
      fontSize: '16px',
      fontWeight: 'bold',
    },
    '& p': {
      fontSize: '14px',
    },
  },
  fromToContainer: {
    marginLeft: '10px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  fromToLabel: {
    color: '#a5a6ae',
    marginRight: '5px',
    fontSize: '14px',
  },
  dateContainer: {
    textAlign: 'center',
    marginRight: '5px',
  },
  checkboxes: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: '5px',
    marginRight: '10px',
  },
  statusContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '5px',
    marginRight: '10px',
  },
  verticalLine: {
    width: '1px',
    height: '22px',
    background: '#1fe3ac',
    border: '1px solid #1fe3ac',
  },
  closeIcon: {
    marginRight: '15px',
    cursor: 'pointer',
  },
}));
