import React from 'react';

export const NumberPEPS = ({ className, color }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40">
    <g data-name="Group 10334" transform="translate(-392 -256)">
      <rect
        data-name="Rectangle 16974"
        width="40"
        height="40"
        rx="8"
        transform="translate(392 256)"
        fill="#ff6d6d"
      />
      <g data-name="Group 10335">
        <g data-name="Group 10332" transform="translate(-.233 -.286)">
          <circle
            data-name="Ellipse 1320"
            cx="4.061"
            cy="4.061"
            transform="translate(407.943 261.996)"
            fill="#fff"
            r="4.061"
          />
        </g>
        <g data-name="Group 10331" transform="translate(-.233 -1.241)">
          <circle
            data-name="Ellipse 1869"
            cx="4.061"
            cy="4.061"
            transform="translate(407.943 282.996)"
            fill="#fff"
            r="4.061"
          />
        </g>
        <g data-name="Group 10333">
          <path
            data-name="Path 8714"
            d="M-254.389-436.476a2.5 2.5 0 0 1 .2-.991 3.635 3.635 0 0 1 2.575-1.654 13.787 13.787 0 0 1 2.14-.337 20.494 20.494 0 0 1 4 0 13.944 13.944 0 0 1 2.14.337 3.5 3.5 0 0 1 2.575 1.654 2.568 2.568 0 0 1 0 1.992 3.462 3.462 0 0 1-2.575 1.645 12.965 12.965 0 0 1-2.14.346 21.081 21.081 0 0 1-3.26.056 3.319 3.319 0 0 1-.744-.056 12.742 12.742 0 0 1-2.132-.346 3.477 3.477 0 0 1-2.583-1.645 2.561 2.561 0 0 1-.196-1.001z"
            transform="translate(659.099 712.266)"
            fill="#fff"
          />
        </g>
      </g>
    </g>
  </svg>
);
