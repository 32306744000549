import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(theme => ({
  root: {
    boxShadow: 'none',
  },
  row: {
    height: '6.4rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: '0.8rem',
    marginBottom: '1.4rem',
    backgroundColor: theme.palette.white,
    padding: '0 2.8rem',
    borderRadius: '0.4rem',
  },
  content: {
    width: '100%',
  },
  tableRoot: {
    borderSpacing: '0 1rem',
    borderCollapse: 'separate',
  },
  tableCell: {
    borderTop: `0.1rem solid ${theme.palette.greyMid.main}`,
    borderBottom: `0.1rem solid ${theme.palette.greyMid.main}`,
    color: theme.palette.text.secondary,
    fontSize: '1.6rem',
    padding: '1.5em',
    width: '20%',

    '&:first-child': {
      borderTopLeftRadius: '0.4rem',
      borderBottomLeftRadius: '0.4rem',
      borderLeft: `0.1rem solid ${theme.palette.greyMid.main}`,
    },
    '&:last-child': {
      borderTopRightRadius: '0.4rem',
      borderBottomRightRadius: '0.4rem',
      borderRight: `0.1rem solid ${theme.palette.greyMid.main}`,
      textAlign: 'right',
    },
  },
  tableRow: {
    backgroundColor: theme.palette.background.default,
    '&:hover': {
      backgroundColor: '#55ddc0',
    },
  },
  showMore: {
    cursor: 'pointer',
    fontFamily: theme.typography.default,
    color: theme.palette.greyMid.dark,
    textDecoration: 'underline',
    fontSize: '1.4rem',
    marginLeft: 'auto',
    width: 'fit-content',
  },
}));
