import {makeStyles} from "@mui/styles";

export const useStyles = makeStyles(theme => ({
  sectionContainer: {
    background: theme.palette.white,
    borderRadius: '0 16px 16px 16px',
    color: theme.palette.icons.nav,
    fontSize: '18px',
    fontFamily: theme.typography.default,
    marginLeft: '16px',
    marginRight: '16px',
    overflow: 'auto',
    '& header': {
      fontWeight: 'bold',
      fontSize: '24px',
    },
    '& .BeneficiaryContent': {
      marginTop: "20px"
    }
  },
  utilityClass: {
    '& .Mui-focused': {
      background: '#f2f2f4',
    },
  },
  fxTradesSection: {
    padding: '24px',
  },
  fxTradesInputSection: {
    width: '50%',
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    margin: 'auto',
    height: '100%',
    '& .Mui-focused': {
      background: '#f2f2f4',
    },
  },
  transferFeeHeader: {
    display: 'flex',
    flexDirection: 'row',
    gap: '16px',
    paddingTop: '24px',
  },
  inputContainer: {
    height: '48px',
    borderRadius: '8px',
    border: 'solid 1px #e6e6e6',
    fontSize: '20px',
    fontWeight: 'normal',
    padding: '10px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: '10px',
    position: 'relative',
    cursor: 'pointer',
  },
  paymentMethodItem: {
    height: '64px',
    padding: '0 16px',
    backgroundColor: 'white',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontSize: '16px',
    borderBottom: '1px solid #e6e6e6',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#e9fff9',
    },
  },
  mainTransferFeesSection: {
    display: 'flex',
    flexDirection: 'row',
    margin: '42px 24px',
    paddingBottom: '24px',
  },
  transferFeesInputs: {
    display: 'flex',
    flexDirection: 'column',
    gap: '47px',
    width: '90%',
    fontWeight: '500',
  },
  transferFeeInputContainer: {
    '& input': {
      height: '48px',
      borderRadius: '8px',
      border: '1px solid #e6e6e6',
      paddingLeft: '16px',
      width: '100%',
      marginTop: '10px',
      fontSize: '20px',
      color: theme.palette.icons.nav,
    },
  },
  inputRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    gap: '40px',
  },
  asideButtonsContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    marginLeft: '40px',
    marginTop: '30px',
  },
  editBtn: {
    width: '56px',
    height: '32px',
    borderRadius: '16px',
    backgroundColor: '#c6ffef',
    textAlign: 'center',
    fontSize: '14px',
    paddingTop: '8px',
  },
  comboInputSelectContainer: {
    display: 'flex',
    flexDirection: 'row',
    position: 'relative',
    overflow: 'hidden',
    // border: '1px solid #c0c0c0',
    borderRadius: '8px',
    color: '#19365e',
    fontSize: '20px',
    height: '48px',
  },

  inputCombo: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '80%',
    zIndex: '5',
    height: '100%',
    borderRight: '1px solid #c0c0c0',
    paddingLeft: '8px',
  },
  abbrCurrency: {
    fontWeight: '800',
    marginRight: "-9px",
  },
  selectOption: {
    display: 'flex',
    alignItems: 'center',
    '& p': {
      marginLeft: '8px',
    },
    '& svg': {
      height: '32px',
      width: '32px',
    },
    '& .currencyName': {
      marginLeft: "20px"
    }
  },
  inputBase: {
    "font-size": "20px",
    "font-weight": "500",
    "font-stretch": 'normal',
    "font-style": 'normal',
    "line-height": 'normal',
    "letter-spacing": 'normal',
    "text-align": 'left',
    "color": '#19365e',
    border: '1px solid #c0c0c0',
    borderRadius: '8px',
    paddingInline: '8px',
    width: '100%',
    height: '48px',
  },
  IntPaymentContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
  },
  flag: {
    height: '24px',
    width: '24px',
    marginRight: '8px',
  },
  selectCombo: {
    "font-size": "20px",
    "font-weight": "500",
    "font-stretch": 'normal',
    "font-style": 'normal',
    "line-height": 'normal',
    "letter-spacing": 'normal',
    "text-align": 'left',
    "color": '#19365e',
    display: 'flex',
    '& .MuiSelect-select.MuiInputBase-input': {
      display: 'flex',
      direction: 'rtl',
      alignItems: 'center',
      '& p:last-child': {
        display: 'none',
      },
      '& svg': {
        height: '32px',
        width: '32px',
        marginLeft: '15px',
      },
    },
  },
  inputNumber: {
    "font-size": "20px",
    "font-weight": "500",
    "font-stretch": 'normal',
    "font-style": 'normal',
    "line-height": 'normal',
    "letter-spacing": 'normal',
    "text-align": 'left',
    "color": '#19365e',
    position: 'absolute',
    top: 0,
    left: 0,
    width: '80%',
    zIndex: '5',
    height: '100%',
    borderRight: '1px solid #c0c0c0',
    paddingLeft: '16px',
  },
  fontStyle:{
    "font-size": "20px",
    "font-weight": "500",
    "font-stretch": 'normal',
    "font-style": 'normal',
    "line-height": 'normal',
    "letter-spacing": 'normal',
    "text-align": 'left',
    "color": '#19365e',
  },
  feedback:{
    fontSize:'0.75 em',
    marginTop: '8px'
  }

}))
