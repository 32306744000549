import React from 'react';

export const DKK = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48">
    <defs>
      <clipPath id="0p94e2oiqa">
        <path
          data-name="Path 19882"
          d="M4584 5520a24 24 0 1 0-24-24 24 24 0 0 0 24 24z"
          transform="translate(-4208.001 -4501.001)"
          style={{ fill: '#f0f0f0' }}
        />
      </clipPath>
    </defs>
    <g data-name="Group 8541" transform="translate(-328 -928)">
      <circle
        data-name="Ellipse 1753"
        cx="24"
        cy="24"
        r="24"
        transform="translate(328 928)"
        style={{ fill: '#fff' }}
      />
      <g
        data-name="Mask Group 65"
        transform="translate(-24 -43)"
        style={{ clipPath: 'url(#0p94e2oiqa)' }}
      >
        <path
          data-name="Path 19878"
          d="M4760.523 5491h27a21.84 21.84 0 0 0-22-19 23.761 23.761 0 0 0-5 .568V5491z"
          transform="translate(-4387.523 -4501)"
          style={{ fill: '#d80027' }}
        />
        <path
          data-name="Path 19879"
          d="M4573.981 5519.416v-16c-6.128 3.344-11.06 8.782-12 16z"
          transform="translate(-4209.981 -4529.416)"
          style={{ fill: '#d80027' }}
        />
        <path
          data-name="Path 19880"
          d="M4573.981 5761.8h-12c.94 7.217 5.872 12.656 12 16z"
          transform="translate(-4209.981 -4761.801)"
          style={{ fill: '#d80027' }}
        />
        <path
          data-name="Path 19881"
          d="m4760.523 5761.8-.164 18.506a25.332 25.332 0 0 0 5.164.494 21.84 21.84 0 0 0 22-19z"
          transform="translate(-4387.523 -4761.801)"
          style={{ fill: '#d80027' }}
        />
      </g>
    </g>
  </svg>
);
