import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(theme => ({
  searchBarContainer: {
    position: 'relative',
    marginRight: '0.5rem',
    '& input[type="text"]:focus, textarea': {
      outline: 'none !important',
      border: '2px solid #1DE1A8',
    },
  },
  searchIconContainer: {
    position: 'absolute',
    top: '8px',
    left: '8px',
  },
  transactionSearchIconContainer: {
    position: 'absolute',
    top: '1px',
    right: '1px',
    cursor: 'pointer',
  },
  searchBar: {
    width: '561px',
    height: '32px',
    borderRadius: '16px',
    border: '1px solid #e6e6e6',
    paddingLeft: '30px',
    [theme.breakpoints.down('1200')]: {
      width: '400px',
    },
    [theme.breakpoints.down('1024')]: {
      width: '300px',
    },
  },
  transactionSearchBar: {
    width: '561px',
    height: '32px',
    borderRadius: '16px',
    border: '1px solid #e6e6e6',
    paddingLeft: '8px',
    [theme.breakpoints.down('1200')]: {
      width: '400px',
    },
    [theme.breakpoints.down('1024')]: {
      width: '300px',
    },
  },
  closeIconContainer: {
    cursor: 'pointer',
    position: 'absolute',
    top: '-3px',
    right: '2px',
  },
  transactionCloseIconContainer: {
    cursor: 'pointer',
    position: 'absolute',
    top: '-3px',
    right: '193px',
    [theme.breakpoints.down('1024')]: {
      right: '118px',
    },
  },
}));
