import { makeStyles } from '@mui/styles';

import { accountStatus } from '../../../../theme/styles';

export const useStyles = makeStyles(theme => ({
  tableCell: {
    borderTop: `0.1rem solid ${theme.palette.greyMid.main}`,
    borderBottom: `0.1rem solid ${theme.palette.greyMid.main}`,
    color: theme.palette.icons.nav,
    fontSize: '1.4rem',
    padding: '1rem',
    whiteSpace: 'nowrap',
    '&:first-child': {
      borderTopLeftRadius: '0.4rem',
      borderBottomLeftRadius: '0.4rem',
      borderLeft: `0.1rem solid ${theme.palette.greyMid.main}`,
    },
    '&:last-child': {
      borderTopRightRadius: '0.4rem',
      borderBottomRightRadius: '0.4rem',
      borderRight: `0.1rem solid ${theme.palette.greyMid.main}`,
    },
    '& .MuiSvgIcon-root': {
      color: theme.palette.greyMid.dark,
      height: '2.4rem',
      width: '2.4rem',
    },
  },
  tableRow: {
    background: theme.palette.background.default,
    '&:hover': {
      background: theme.palette.primary.light,
    },
  },
  transactionsTableRow: {
    '& .MuiTableCell-root': {
      background: 'white',
      padding: '10px 15px',
      borderRadius: '0 8px 8px 8px',
      boxShadow: 'rgba(0, 0, 0, 0.08)',
      '&:hover': {
        background: theme.palette.primary.light,
      },
    },
  },
  icon: {
    color: theme.palette.greyMid.dark,
  },
  checkbox: {
    color: theme.palette.greyMid.dark,
  },
  accountStatus,
  noDataTag: {
    fontSize: '14px',
    fontFamily: theme.typography.default,
    color: theme.palette.icons.nav,
  },
}));
