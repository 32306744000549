/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getUserRisks = /* GraphQL */ `
  query GetUserRisks($input: GetUserRisksInput!) {
    getUserRisks(input: $input) {
      items {
        type
        itemValue
        riskLevel
        updatedAt
        updaterEmail
        updaterComment
        riskId
        freezeUntil
      }
      from
      size
      total
    }
  }
`;
export const getLatestUserRisks = /* GraphQL */ `
  query GetLatestUserRisks($userId: ID!) {
    getLatestUserRisks(userId: $userId) {
      type
      itemValue
      riskLevel
      updatedAt
      updaterEmail
      updaterComment
      riskId
      freezeUntil
    }
  }
`;
export const getUserLimitWaiver = /* GraphQL */ `
  query GetUserLimitWaiver($userId: ID!) {
    getUserLimitWaiver(userId: $userId)
  }
`;
export const getUserLimits = /* GraphQL */ `
  query GetUserLimits($userId: ID!) {
    getUserLimits(userId: $userId) {
      monthlyLimit
      dailyLimit
      perTransactionLimit
    }
  }
`;
export const getMCCYTransaction = /* GraphQL */ `
  query GetMCCYTransaction($endToEndId: String!) {
    getMCCYTransaction(endToEndId: $endToEndId)
  }
`;
export const getApplicationsStatus = /* GraphQL */ `
  query GetApplicationsStatus {
    getApplicationsStatus {
      totalApplications
      totalNumberOfRejects
      totalNumberOfAccept
      totalNumberOfPending
      numberOfApplicationThisWeek
      numberOfAcceptsThisWeek
      numberOfRejectsThisWeek
      numberOfPendingThisWeek
      numberOfPersonalAccounts
      numberOfBusinessAccounts
      numberOfHighRiskAccount
      numberOfMidRiskAccount
      numberOfLowRiskAccount
      numberPEPS
      numberOfFraudulentApplications
    }
  }
`;
export const listTransferFees = /* GraphQL */ `
  query ListTransferFees {
    listTransferFees {
      items {
        paymentMethod
        fee
      }
    }
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser {
    getUser {
      id
      accountType
      membership {
        id
        name
        price
        dailyLimit
        monthlyLimit
        createdAt
        updatedAt
      }
      name
      lastName
      birthdate
      country
      address
      city
      postalCode
      email
      emailVerified
      phoneCode
      phoneNumber
      phoneNumberVerified
      kyc {
        status
        result
        caseId
        applicantId
        reason
      }
      beneficiaries {
        id
        status
        userId
        accountType
        name
        lastName
        country {
          id
          iso
          iso2
          region {
            id
            name
            createdAt
            updatedAt
          }
          defaultCurrency {
            id
            iso
            active
            name
            walletBgColor
            walletTextColor
            order
            createdAt
            updatedAt
          }
          currency {
            id
            iso
            active
            name
            walletBgColor
            walletTextColor
            order
            createdAt
            updatedAt
          }
          active
          name
          phoneCode
          order
          companyRequirements
          personalRequirements
          createdAt
          updatedAt
        }
        address
        city
        postalCode
        email
        phoneCode
        phoneNumber
        currency {
          id
          iso
          active
          name
          walletBgColor
          walletTextColor
          order
          createdAt
          updatedAt
        }
        bankDetails {
          name
          value
        }
        userProfileId
        usageLevel
        skipInternalCheck
        currencyRequirementType
        beneficiaryCountryId
        beneficiaryCurrencyId
      }
      externalBankAccounts {
        name
        number
        iban
        swift
        sort
      }
      poundsAccount {
        id
        name
        label
        type
        currency
        iban
        bban
        balance
        lastTx
      }
      invitedBy {
        id
        accountType
        membership {
          id
          name
          price
          dailyLimit
          monthlyLimit
          createdAt
          updatedAt
        }
        name
        lastName
        birthdate
        country
        address
        city
        postalCode
        email
        emailVerified
        phoneCode
        phoneNumber
        phoneNumberVerified
        kyc {
          status
          result
          caseId
          applicantId
          reason
        }
        beneficiaries {
          id
          status
          userId
          accountType
          name
          lastName
          country {
            id
            iso
            iso2
            active
            name
            phoneCode
            order
            companyRequirements
            personalRequirements
            createdAt
            updatedAt
          }
          address
          city
          postalCode
          email
          phoneCode
          phoneNumber
          currency {
            id
            iso
            active
            name
            walletBgColor
            walletTextColor
            order
            createdAt
            updatedAt
          }
          bankDetails {
            name
            value
          }
          userProfileId
          usageLevel
          skipInternalCheck
          currencyRequirementType
          beneficiaryCountryId
          beneficiaryCurrencyId
        }
        externalBankAccounts {
          name
          number
          iban
          swift
          sort
        }
        poundsAccount {
          id
          name
          label
          type
          currency
          iban
          bban
          balance
          lastTx
        }
        invitedBy {
          id
          accountType
          membership {
            id
            name
            price
            dailyLimit
            monthlyLimit
            createdAt
            updatedAt
          }
          name
          lastName
          birthdate
          country
          address
          city
          postalCode
          email
          emailVerified
          phoneCode
          phoneNumber
          phoneNumberVerified
          kyc {
            status
            result
            caseId
            applicantId
            reason
          }
          beneficiaries {
            id
            status
            userId
            accountType
            name
            lastName
            address
            city
            postalCode
            email
            phoneCode
            phoneNumber
            userProfileId
            usageLevel
            skipInternalCheck
            currencyRequirementType
            beneficiaryCountryId
            beneficiaryCurrencyId
          }
          externalBankAccounts {
            name
            number
            iban
            swift
            sort
          }
          poundsAccount {
            id
            name
            label
            type
            currency
            iban
            bban
            balance
            lastTx
          }
          invitedBy {
            id
            accountType
            name
            lastName
            birthdate
            country
            address
            city
            postalCode
            email
            emailVerified
            phoneCode
            phoneNumber
            phoneNumberVerified
            readNotifications
            readOnboardings
            profileImage
            smsMarketingConsent
            pushMarketingConsent
            emailMarketingConsent
            smsAllowed
            pushAllowed
            emailAllowed
            createdAt
            updatedAt
            intercomProfileUrl
            accountStatus
            type
            passwordResetAt
            passwordResetBy
            customerId
            businessDetail
            shareholders
            SignUpAddressPersonalDetails
            SignUpFinancialDetails
            SignUpIndividualPersonalDetails
            transactionsInfo
            businessType
            author
          }
          readNotifications
          readOnboardings
          amountsSent {
            daily
            monthly
          }
          profileImage
          paymentMethods {
            last4
            scheme
            provider
            customerId
            expiryMonth
            id
            expiryYear
            type
          }
          companyInfo {
            tradingName
            primaryHolderName
            industryType
            registeredAddress
            businessDescription
            annualTurnover
            stablishmentDate
            tradingStartDate
            transactionVolumen
            internationalActivity
            kybCompanyCode
          }
          pushNotificationsConfig {
            token
            endpointArn
          }
          smsMarketingConsent
          pushMarketingConsent
          emailMarketingConsent
          smsAllowed
          pushAllowed
          emailAllowed
          forbiddenCurrency {
            id
            iso
            active
            name
            walletBgColor
            walletTextColor
            order
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          intercomProfileUrl
          accountStatus
          type
          passwordResetAt
          passwordResetBy
          customerId
          businessDetail
          shareholders
          SignUpAddressPersonalDetails
          SignUpFinancialDetails
          SignUpIndividualPersonalDetails
          transactionsInfo
          businessType
          author
        }
        readNotifications
        readOnboardings
        amountsSent {
          daily
          monthly
        }
        profileImage
        paymentMethods {
          last4
          scheme
          provider
          customerId
          expiryMonth
          id
          expiryYear
          type
        }
        companyInfo {
          tradingName
          primaryHolderName
          industryType
          registeredAddress
          businessDescription
          annualTurnover
          stablishmentDate
          tradingStartDate
          transactionVolumen
          shareholders {
            name
            lastName
            birthdate
            country
            address
            city
            postalCode
            email
            phoneCode
            phoneNumber
          }
          internationalActivity
          kybCompanyCode
        }
        pushNotificationsConfig {
          token
          endpointArn
        }
        smsMarketingConsent
        pushMarketingConsent
        emailMarketingConsent
        smsAllowed
        pushAllowed
        emailAllowed
        forbiddenCurrency {
          id
          iso
          active
          name
          walletBgColor
          walletTextColor
          order
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        intercomProfileUrl
        accountStatus
        type
        passwordResetAt
        passwordResetBy
        customerId
        businessDetail
        shareholders
        SignUpAddressPersonalDetails
        SignUpFinancialDetails
        SignUpIndividualPersonalDetails
        transactionsInfo
        businessType
        author
      }
      readNotifications
      readOnboardings
      amountsSent {
        daily
        monthly
      }
      profileImage
      paymentMethods {
        last4
        scheme
        provider
        customerId
        expiryMonth
        id
        expiryYear
        type
      }
      companyInfo {
        tradingName
        primaryHolderName
        industryType
        registeredAddress
        businessDescription
        annualTurnover
        stablishmentDate
        tradingStartDate
        transactionVolumen
        shareholders {
          name
          lastName
          birthdate
          country
          address
          city
          postalCode
          email
          phoneCode
          phoneNumber
        }
        internationalActivity
        kybCompanyCode
      }
      pushNotificationsConfig {
        token
        endpointArn
      }
      smsMarketingConsent
      pushMarketingConsent
      emailMarketingConsent
      smsAllowed
      pushAllowed
      emailAllowed
      forbiddenCurrency {
        id
        iso
        active
        name
        walletBgColor
        walletTextColor
        order
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      intercomProfileUrl
      accountStatus
      type
      passwordResetAt
      passwordResetBy
      customerId
      businessDetail
      shareholders
      SignUpAddressPersonalDetails
      SignUpFinancialDetails
      SignUpIndividualPersonalDetails
      transactionsInfo
      businessType
      author
    }
  }
`;
export const getBeneficiary = /* GraphQL */ `
  query GetBeneficiary($id: ID!) {
    getBeneficiary(id: $id) {
      id
      status
      userId
      accountType
      name
      lastName
      country {
        id
        iso
        iso2
        region {
          id
          name
          createdAt
          updatedAt
        }
        defaultCurrency {
          id
          iso
          active
          name
          walletBgColor
          walletTextColor
          order
          createdAt
          updatedAt
        }
        currency {
          id
          iso
          active
          name
          walletBgColor
          walletTextColor
          order
          createdAt
          updatedAt
        }
        active
        name
        phoneCode
        order
        companyRequirements
        personalRequirements
        createdAt
        updatedAt
      }
      address
      city
      postalCode
      email
      phoneCode
      phoneNumber
      currency {
        id
        iso
        active
        name
        walletBgColor
        walletTextColor
        order
        createdAt
        updatedAt
      }
      bankDetails {
        name
        value
      }
      userProfileId
      usageLevel
      skipInternalCheck
      currencyRequirementType
      beneficiaryCountryId
      beneficiaryCurrencyId
    }
  }
`;
export const getUserBeneficiaries = /* GraphQL */ `
  query GetUserBeneficiaries {
    getUserBeneficiaries {
      items {
        id
        status
        userId
        accountType
        name
        lastName
        country {
          id
          iso
          iso2
          region {
            id
            name
            createdAt
            updatedAt
          }
          defaultCurrency {
            id
            iso
            active
            name
            walletBgColor
            walletTextColor
            order
            createdAt
            updatedAt
          }
          currency {
            id
            iso
            active
            name
            walletBgColor
            walletTextColor
            order
            createdAt
            updatedAt
          }
          active
          name
          phoneCode
          order
          companyRequirements
          personalRequirements
          createdAt
          updatedAt
        }
        address
        city
        postalCode
        email
        phoneCode
        phoneNumber
        currency {
          id
          iso
          active
          name
          walletBgColor
          walletTextColor
          order
          createdAt
          updatedAt
        }
        bankDetails {
          name
          value
        }
        userProfileId
        usageLevel
        skipInternalCheck
        currencyRequirementType
        beneficiaryCountryId
        beneficiaryCurrencyId
      }
      nextToken
    }
  }
`;
export const getUserActivity = /* GraphQL */ `
  query GetUserActivity {
    getUserActivity {
      id
      type
      userIdFrom
      userIdTo
      userFrom {
        id
        accountType
        membership {
          id
          name
          price
          dailyLimit
          monthlyLimit
          createdAt
          updatedAt
        }
        name
        lastName
        birthdate
        country
        address
        city
        postalCode
        email
        emailVerified
        phoneCode
        phoneNumber
        phoneNumberVerified
        kyc {
          status
          result
          caseId
          applicantId
          reason
        }
        beneficiaries {
          id
          status
          userId
          accountType
          name
          lastName
          country {
            id
            iso
            iso2
            active
            name
            phoneCode
            order
            companyRequirements
            personalRequirements
            createdAt
            updatedAt
          }
          address
          city
          postalCode
          email
          phoneCode
          phoneNumber
          currency {
            id
            iso
            active
            name
            walletBgColor
            walletTextColor
            order
            createdAt
            updatedAt
          }
          bankDetails {
            name
            value
          }
          userProfileId
          usageLevel
          skipInternalCheck
          currencyRequirementType
          beneficiaryCountryId
          beneficiaryCurrencyId
        }
        externalBankAccounts {
          name
          number
          iban
          swift
          sort
        }
        poundsAccount {
          id
          name
          label
          type
          currency
          iban
          bban
          balance
          lastTx
        }
        invitedBy {
          id
          accountType
          membership {
            id
            name
            price
            dailyLimit
            monthlyLimit
            createdAt
            updatedAt
          }
          name
          lastName
          birthdate
          country
          address
          city
          postalCode
          email
          emailVerified
          phoneCode
          phoneNumber
          phoneNumberVerified
          kyc {
            status
            result
            caseId
            applicantId
            reason
          }
          beneficiaries {
            id
            status
            userId
            accountType
            name
            lastName
            address
            city
            postalCode
            email
            phoneCode
            phoneNumber
            userProfileId
            usageLevel
            skipInternalCheck
            currencyRequirementType
            beneficiaryCountryId
            beneficiaryCurrencyId
          }
          externalBankAccounts {
            name
            number
            iban
            swift
            sort
          }
          poundsAccount {
            id
            name
            label
            type
            currency
            iban
            bban
            balance
            lastTx
          }
          invitedBy {
            id
            accountType
            name
            lastName
            birthdate
            country
            address
            city
            postalCode
            email
            emailVerified
            phoneCode
            phoneNumber
            phoneNumberVerified
            readNotifications
            readOnboardings
            profileImage
            smsMarketingConsent
            pushMarketingConsent
            emailMarketingConsent
            smsAllowed
            pushAllowed
            emailAllowed
            createdAt
            updatedAt
            intercomProfileUrl
            accountStatus
            type
            passwordResetAt
            passwordResetBy
            customerId
            businessDetail
            shareholders
            SignUpAddressPersonalDetails
            SignUpFinancialDetails
            SignUpIndividualPersonalDetails
            transactionsInfo
            businessType
            author
          }
          readNotifications
          readOnboardings
          amountsSent {
            daily
            monthly
          }
          profileImage
          paymentMethods {
            last4
            scheme
            provider
            customerId
            expiryMonth
            id
            expiryYear
            type
          }
          companyInfo {
            tradingName
            primaryHolderName
            industryType
            registeredAddress
            businessDescription
            annualTurnover
            stablishmentDate
            tradingStartDate
            transactionVolumen
            internationalActivity
            kybCompanyCode
          }
          pushNotificationsConfig {
            token
            endpointArn
          }
          smsMarketingConsent
          pushMarketingConsent
          emailMarketingConsent
          smsAllowed
          pushAllowed
          emailAllowed
          forbiddenCurrency {
            id
            iso
            active
            name
            walletBgColor
            walletTextColor
            order
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          intercomProfileUrl
          accountStatus
          type
          passwordResetAt
          passwordResetBy
          customerId
          businessDetail
          shareholders
          SignUpAddressPersonalDetails
          SignUpFinancialDetails
          SignUpIndividualPersonalDetails
          transactionsInfo
          businessType
          author
        }
        readNotifications
        readOnboardings
        amountsSent {
          daily
          monthly
        }
        profileImage
        paymentMethods {
          last4
          scheme
          provider
          customerId
          expiryMonth
          id
          expiryYear
          type
        }
        companyInfo {
          tradingName
          primaryHolderName
          industryType
          registeredAddress
          businessDescription
          annualTurnover
          stablishmentDate
          tradingStartDate
          transactionVolumen
          shareholders {
            name
            lastName
            birthdate
            country
            address
            city
            postalCode
            email
            phoneCode
            phoneNumber
          }
          internationalActivity
          kybCompanyCode
        }
        pushNotificationsConfig {
          token
          endpointArn
        }
        smsMarketingConsent
        pushMarketingConsent
        emailMarketingConsent
        smsAllowed
        pushAllowed
        emailAllowed
        forbiddenCurrency {
          id
          iso
          active
          name
          walletBgColor
          walletTextColor
          order
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        intercomProfileUrl
        accountStatus
        type
        passwordResetAt
        passwordResetBy
        customerId
        businessDetail
        shareholders
        SignUpAddressPersonalDetails
        SignUpFinancialDetails
        SignUpIndividualPersonalDetails
        transactionsInfo
        businessType
        author
      }
      externalUserFrom {
        name
        lastName
        iban
      }
      userTo {
        id
        accountType
        membership {
          id
          name
          price
          dailyLimit
          monthlyLimit
          createdAt
          updatedAt
        }
        name
        lastName
        birthdate
        country
        address
        city
        postalCode
        email
        emailVerified
        phoneCode
        phoneNumber
        phoneNumberVerified
        kyc {
          status
          result
          caseId
          applicantId
          reason
        }
        beneficiaries {
          id
          status
          userId
          accountType
          name
          lastName
          country {
            id
            iso
            iso2
            active
            name
            phoneCode
            order
            companyRequirements
            personalRequirements
            createdAt
            updatedAt
          }
          address
          city
          postalCode
          email
          phoneCode
          phoneNumber
          currency {
            id
            iso
            active
            name
            walletBgColor
            walletTextColor
            order
            createdAt
            updatedAt
          }
          bankDetails {
            name
            value
          }
          userProfileId
          usageLevel
          skipInternalCheck
          currencyRequirementType
          beneficiaryCountryId
          beneficiaryCurrencyId
        }
        externalBankAccounts {
          name
          number
          iban
          swift
          sort
        }
        poundsAccount {
          id
          name
          label
          type
          currency
          iban
          bban
          balance
          lastTx
        }
        invitedBy {
          id
          accountType
          membership {
            id
            name
            price
            dailyLimit
            monthlyLimit
            createdAt
            updatedAt
          }
          name
          lastName
          birthdate
          country
          address
          city
          postalCode
          email
          emailVerified
          phoneCode
          phoneNumber
          phoneNumberVerified
          kyc {
            status
            result
            caseId
            applicantId
            reason
          }
          beneficiaries {
            id
            status
            userId
            accountType
            name
            lastName
            address
            city
            postalCode
            email
            phoneCode
            phoneNumber
            userProfileId
            usageLevel
            skipInternalCheck
            currencyRequirementType
            beneficiaryCountryId
            beneficiaryCurrencyId
          }
          externalBankAccounts {
            name
            number
            iban
            swift
            sort
          }
          poundsAccount {
            id
            name
            label
            type
            currency
            iban
            bban
            balance
            lastTx
          }
          invitedBy {
            id
            accountType
            name
            lastName
            birthdate
            country
            address
            city
            postalCode
            email
            emailVerified
            phoneCode
            phoneNumber
            phoneNumberVerified
            readNotifications
            readOnboardings
            profileImage
            smsMarketingConsent
            pushMarketingConsent
            emailMarketingConsent
            smsAllowed
            pushAllowed
            emailAllowed
            createdAt
            updatedAt
            intercomProfileUrl
            accountStatus
            type
            passwordResetAt
            passwordResetBy
            customerId
            businessDetail
            shareholders
            SignUpAddressPersonalDetails
            SignUpFinancialDetails
            SignUpIndividualPersonalDetails
            transactionsInfo
            businessType
            author
          }
          readNotifications
          readOnboardings
          amountsSent {
            daily
            monthly
          }
          profileImage
          paymentMethods {
            last4
            scheme
            provider
            customerId
            expiryMonth
            id
            expiryYear
            type
          }
          companyInfo {
            tradingName
            primaryHolderName
            industryType
            registeredAddress
            businessDescription
            annualTurnover
            stablishmentDate
            tradingStartDate
            transactionVolumen
            internationalActivity
            kybCompanyCode
          }
          pushNotificationsConfig {
            token
            endpointArn
          }
          smsMarketingConsent
          pushMarketingConsent
          emailMarketingConsent
          smsAllowed
          pushAllowed
          emailAllowed
          forbiddenCurrency {
            id
            iso
            active
            name
            walletBgColor
            walletTextColor
            order
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          intercomProfileUrl
          accountStatus
          type
          passwordResetAt
          passwordResetBy
          customerId
          businessDetail
          shareholders
          SignUpAddressPersonalDetails
          SignUpFinancialDetails
          SignUpIndividualPersonalDetails
          transactionsInfo
          businessType
          author
        }
        readNotifications
        readOnboardings
        amountsSent {
          daily
          monthly
        }
        profileImage
        paymentMethods {
          last4
          scheme
          provider
          customerId
          expiryMonth
          id
          expiryYear
          type
        }
        companyInfo {
          tradingName
          primaryHolderName
          industryType
          registeredAddress
          businessDescription
          annualTurnover
          stablishmentDate
          tradingStartDate
          transactionVolumen
          shareholders {
            name
            lastName
            birthdate
            country
            address
            city
            postalCode
            email
            phoneCode
            phoneNumber
          }
          internationalActivity
          kybCompanyCode
        }
        pushNotificationsConfig {
          token
          endpointArn
        }
        smsMarketingConsent
        pushMarketingConsent
        emailMarketingConsent
        smsAllowed
        pushAllowed
        emailAllowed
        forbiddenCurrency {
          id
          iso
          active
          name
          walletBgColor
          walletTextColor
          order
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        intercomProfileUrl
        accountStatus
        type
        passwordResetAt
        passwordResetBy
        customerId
        businessDetail
        shareholders
        SignUpAddressPersonalDetails
        SignUpFinancialDetails
        SignUpIndividualPersonalDetails
        transactionsInfo
        businessType
        author
      }
      beneficiary {
        id
        status
        userId
        accountType
        name
        lastName
        country {
          id
          iso
          iso2
          region {
            id
            name
            createdAt
            updatedAt
          }
          defaultCurrency {
            id
            iso
            active
            name
            walletBgColor
            walletTextColor
            order
            createdAt
            updatedAt
          }
          currency {
            id
            iso
            active
            name
            walletBgColor
            walletTextColor
            order
            createdAt
            updatedAt
          }
          active
          name
          phoneCode
          order
          companyRequirements
          personalRequirements
          createdAt
          updatedAt
        }
        address
        city
        postalCode
        email
        phoneCode
        phoneNumber
        currency {
          id
          iso
          active
          name
          walletBgColor
          walletTextColor
          order
          createdAt
          updatedAt
        }
        bankDetails {
          name
          value
        }
        userProfileId
        usageLevel
        skipInternalCheck
        currencyRequirementType
        beneficiaryCountryId
        beneficiaryCurrencyId
      }
      fromCurrency
      toCurrency
      sendingAmount
      exchangeRate
      receivingAmount
      fee
      transferFee
      discount {
        id
        code
        type
        amount
        totalUses
        totalUniqueUses
        expire
        discountUses {
          userId
          date
        }
      }
      reference
      purpose
      status
      steps {
        timestamp
        description
        order
        status
      }
      errorCode
      method
      userFromNote
      userFromAttachment
      userToNote
      userToAttachment
      createdAt
      internalId
      failureReason
      adminAttachments
      debtorAccount {
        addressLine1
        addressLine2
        country
        postcode
        name
        ownerName
        iban
        bban
        descriptor
        institutionName
        transactionOwnerName
      }
      creditorAccount {
        addressLine1
        addressLine2
        country
        postcode
        name
        ownerName
        iban
        bban
        descriptor
        institutionName
        transactionOwnerName
      }
      endToEndId
      rateQuoteId
      runningBalanceFrom
      runningBalanceTo
    }
  }
`;
export const getUserBalances = /* GraphQL */ `
  query GetUserBalances {
    getUserBalances {
      total
      balances {
        amount
        gbpBalance
        currency
        flag
        symbol
        color
        abbreviation
        history {
          range {
            from
            to
          }
          data {
            date
            amount
          }
        }
        routingNumber
        accountNumber
        bankAddress
        bankName
        owner
        iban
        status
      }
    }
  }
`;
export const getUserByEmail = /* GraphQL */ `
  query GetUserByEmail($email: String!) {
    getUserByEmail(email: $email) {
      id
      accountType
      membership {
        id
        name
        price
        dailyLimit
        monthlyLimit
        createdAt
        updatedAt
      }
      name
      lastName
      birthdate
      country
      address
      city
      postalCode
      email
      emailVerified
      phoneCode
      phoneNumber
      phoneNumberVerified
      kyc {
        status
        result
        caseId
        applicantId
        reason
      }
      beneficiaries {
        id
        status
        userId
        accountType
        name
        lastName
        country {
          id
          iso
          iso2
          region {
            id
            name
            createdAt
            updatedAt
          }
          defaultCurrency {
            id
            iso
            active
            name
            walletBgColor
            walletTextColor
            order
            createdAt
            updatedAt
          }
          currency {
            id
            iso
            active
            name
            walletBgColor
            walletTextColor
            order
            createdAt
            updatedAt
          }
          active
          name
          phoneCode
          order
          companyRequirements
          personalRequirements
          createdAt
          updatedAt
        }
        address
        city
        postalCode
        email
        phoneCode
        phoneNumber
        currency {
          id
          iso
          active
          name
          walletBgColor
          walletTextColor
          order
          createdAt
          updatedAt
        }
        bankDetails {
          name
          value
        }
        userProfileId
        usageLevel
        skipInternalCheck
        currencyRequirementType
        beneficiaryCountryId
        beneficiaryCurrencyId
      }
      externalBankAccounts {
        name
        number
        iban
        swift
        sort
      }
      poundsAccount {
        id
        name
        label
        type
        currency
        iban
        bban
        balance
        lastTx
      }
      invitedBy {
        id
        accountType
        membership {
          id
          name
          price
          dailyLimit
          monthlyLimit
          createdAt
          updatedAt
        }
        name
        lastName
        birthdate
        country
        address
        city
        postalCode
        email
        emailVerified
        phoneCode
        phoneNumber
        phoneNumberVerified
        kyc {
          status
          result
          caseId
          applicantId
          reason
        }
        beneficiaries {
          id
          status
          userId
          accountType
          name
          lastName
          country {
            id
            iso
            iso2
            active
            name
            phoneCode
            order
            companyRequirements
            personalRequirements
            createdAt
            updatedAt
          }
          address
          city
          postalCode
          email
          phoneCode
          phoneNumber
          currency {
            id
            iso
            active
            name
            walletBgColor
            walletTextColor
            order
            createdAt
            updatedAt
          }
          bankDetails {
            name
            value
          }
          userProfileId
          usageLevel
          skipInternalCheck
          currencyRequirementType
          beneficiaryCountryId
          beneficiaryCurrencyId
        }
        externalBankAccounts {
          name
          number
          iban
          swift
          sort
        }
        poundsAccount {
          id
          name
          label
          type
          currency
          iban
          bban
          balance
          lastTx
        }
        invitedBy {
          id
          accountType
          membership {
            id
            name
            price
            dailyLimit
            monthlyLimit
            createdAt
            updatedAt
          }
          name
          lastName
          birthdate
          country
          address
          city
          postalCode
          email
          emailVerified
          phoneCode
          phoneNumber
          phoneNumberVerified
          kyc {
            status
            result
            caseId
            applicantId
            reason
          }
          beneficiaries {
            id
            status
            userId
            accountType
            name
            lastName
            address
            city
            postalCode
            email
            phoneCode
            phoneNumber
            userProfileId
            usageLevel
            skipInternalCheck
            currencyRequirementType
            beneficiaryCountryId
            beneficiaryCurrencyId
          }
          externalBankAccounts {
            name
            number
            iban
            swift
            sort
          }
          poundsAccount {
            id
            name
            label
            type
            currency
            iban
            bban
            balance
            lastTx
          }
          invitedBy {
            id
            accountType
            name
            lastName
            birthdate
            country
            address
            city
            postalCode
            email
            emailVerified
            phoneCode
            phoneNumber
            phoneNumberVerified
            readNotifications
            readOnboardings
            profileImage
            smsMarketingConsent
            pushMarketingConsent
            emailMarketingConsent
            smsAllowed
            pushAllowed
            emailAllowed
            createdAt
            updatedAt
            intercomProfileUrl
            accountStatus
            type
            passwordResetAt
            passwordResetBy
            customerId
            businessDetail
            shareholders
            SignUpAddressPersonalDetails
            SignUpFinancialDetails
            SignUpIndividualPersonalDetails
            transactionsInfo
            businessType
            author
          }
          readNotifications
          readOnboardings
          amountsSent {
            daily
            monthly
          }
          profileImage
          paymentMethods {
            last4
            scheme
            provider
            customerId
            expiryMonth
            id
            expiryYear
            type
          }
          companyInfo {
            tradingName
            primaryHolderName
            industryType
            registeredAddress
            businessDescription
            annualTurnover
            stablishmentDate
            tradingStartDate
            transactionVolumen
            internationalActivity
            kybCompanyCode
          }
          pushNotificationsConfig {
            token
            endpointArn
          }
          smsMarketingConsent
          pushMarketingConsent
          emailMarketingConsent
          smsAllowed
          pushAllowed
          emailAllowed
          forbiddenCurrency {
            id
            iso
            active
            name
            walletBgColor
            walletTextColor
            order
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          intercomProfileUrl
          accountStatus
          type
          passwordResetAt
          passwordResetBy
          customerId
          businessDetail
          shareholders
          SignUpAddressPersonalDetails
          SignUpFinancialDetails
          SignUpIndividualPersonalDetails
          transactionsInfo
          businessType
          author
        }
        readNotifications
        readOnboardings
        amountsSent {
          daily
          monthly
        }
        profileImage
        paymentMethods {
          last4
          scheme
          provider
          customerId
          expiryMonth
          id
          expiryYear
          type
        }
        companyInfo {
          tradingName
          primaryHolderName
          industryType
          registeredAddress
          businessDescription
          annualTurnover
          stablishmentDate
          tradingStartDate
          transactionVolumen
          shareholders {
            name
            lastName
            birthdate
            country
            address
            city
            postalCode
            email
            phoneCode
            phoneNumber
          }
          internationalActivity
          kybCompanyCode
        }
        pushNotificationsConfig {
          token
          endpointArn
        }
        smsMarketingConsent
        pushMarketingConsent
        emailMarketingConsent
        smsAllowed
        pushAllowed
        emailAllowed
        forbiddenCurrency {
          id
          iso
          active
          name
          walletBgColor
          walletTextColor
          order
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        intercomProfileUrl
        accountStatus
        type
        passwordResetAt
        passwordResetBy
        customerId
        businessDetail
        shareholders
        SignUpAddressPersonalDetails
        SignUpFinancialDetails
        SignUpIndividualPersonalDetails
        transactionsInfo
        businessType
        author
      }
      readNotifications
      readOnboardings
      amountsSent {
        daily
        monthly
      }
      profileImage
      paymentMethods {
        last4
        scheme
        provider
        customerId
        expiryMonth
        id
        expiryYear
        type
      }
      companyInfo {
        tradingName
        primaryHolderName
        industryType
        registeredAddress
        businessDescription
        annualTurnover
        stablishmentDate
        tradingStartDate
        transactionVolumen
        shareholders {
          name
          lastName
          birthdate
          country
          address
          city
          postalCode
          email
          phoneCode
          phoneNumber
        }
        internationalActivity
        kybCompanyCode
      }
      pushNotificationsConfig {
        token
        endpointArn
      }
      smsMarketingConsent
      pushMarketingConsent
      emailMarketingConsent
      smsAllowed
      pushAllowed
      emailAllowed
      forbiddenCurrency {
        id
        iso
        active
        name
        walletBgColor
        walletTextColor
        order
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      intercomProfileUrl
      accountStatus
      type
      passwordResetAt
      passwordResetBy
      customerId
      businessDetail
      shareholders
      SignUpAddressPersonalDetails
      SignUpFinancialDetails
      SignUpIndividualPersonalDetails
      transactionsInfo
      businessType
      author
    }
  }
`;
export const getMessages = /* GraphQL */ `
  query GetMessages($conversationId: String!) {
    getMessages(conversationId: $conversationId) {
      conversationId
      type
      timestamp
      sender {
        id
        accountType
        membership {
          id
          name
          price
          dailyLimit
          monthlyLimit
          createdAt
          updatedAt
        }
        name
        lastName
        birthdate
        country
        address
        city
        postalCode
        email
        emailVerified
        phoneCode
        phoneNumber
        phoneNumberVerified
        kyc {
          status
          result
          caseId
          applicantId
          reason
        }
        beneficiaries {
          id
          status
          userId
          accountType
          name
          lastName
          country {
            id
            iso
            iso2
            active
            name
            phoneCode
            order
            companyRequirements
            personalRequirements
            createdAt
            updatedAt
          }
          address
          city
          postalCode
          email
          phoneCode
          phoneNumber
          currency {
            id
            iso
            active
            name
            walletBgColor
            walletTextColor
            order
            createdAt
            updatedAt
          }
          bankDetails {
            name
            value
          }
          userProfileId
          usageLevel
          skipInternalCheck
          currencyRequirementType
          beneficiaryCountryId
          beneficiaryCurrencyId
        }
        externalBankAccounts {
          name
          number
          iban
          swift
          sort
        }
        poundsAccount {
          id
          name
          label
          type
          currency
          iban
          bban
          balance
          lastTx
        }
        invitedBy {
          id
          accountType
          membership {
            id
            name
            price
            dailyLimit
            monthlyLimit
            createdAt
            updatedAt
          }
          name
          lastName
          birthdate
          country
          address
          city
          postalCode
          email
          emailVerified
          phoneCode
          phoneNumber
          phoneNumberVerified
          kyc {
            status
            result
            caseId
            applicantId
            reason
          }
          beneficiaries {
            id
            status
            userId
            accountType
            name
            lastName
            address
            city
            postalCode
            email
            phoneCode
            phoneNumber
            userProfileId
            usageLevel
            skipInternalCheck
            currencyRequirementType
            beneficiaryCountryId
            beneficiaryCurrencyId
          }
          externalBankAccounts {
            name
            number
            iban
            swift
            sort
          }
          poundsAccount {
            id
            name
            label
            type
            currency
            iban
            bban
            balance
            lastTx
          }
          invitedBy {
            id
            accountType
            name
            lastName
            birthdate
            country
            address
            city
            postalCode
            email
            emailVerified
            phoneCode
            phoneNumber
            phoneNumberVerified
            readNotifications
            readOnboardings
            profileImage
            smsMarketingConsent
            pushMarketingConsent
            emailMarketingConsent
            smsAllowed
            pushAllowed
            emailAllowed
            createdAt
            updatedAt
            intercomProfileUrl
            accountStatus
            type
            passwordResetAt
            passwordResetBy
            customerId
            businessDetail
            shareholders
            SignUpAddressPersonalDetails
            SignUpFinancialDetails
            SignUpIndividualPersonalDetails
            transactionsInfo
            businessType
            author
          }
          readNotifications
          readOnboardings
          amountsSent {
            daily
            monthly
          }
          profileImage
          paymentMethods {
            last4
            scheme
            provider
            customerId
            expiryMonth
            id
            expiryYear
            type
          }
          companyInfo {
            tradingName
            primaryHolderName
            industryType
            registeredAddress
            businessDescription
            annualTurnover
            stablishmentDate
            tradingStartDate
            transactionVolumen
            internationalActivity
            kybCompanyCode
          }
          pushNotificationsConfig {
            token
            endpointArn
          }
          smsMarketingConsent
          pushMarketingConsent
          emailMarketingConsent
          smsAllowed
          pushAllowed
          emailAllowed
          forbiddenCurrency {
            id
            iso
            active
            name
            walletBgColor
            walletTextColor
            order
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          intercomProfileUrl
          accountStatus
          type
          passwordResetAt
          passwordResetBy
          customerId
          businessDetail
          shareholders
          SignUpAddressPersonalDetails
          SignUpFinancialDetails
          SignUpIndividualPersonalDetails
          transactionsInfo
          businessType
          author
        }
        readNotifications
        readOnboardings
        amountsSent {
          daily
          monthly
        }
        profileImage
        paymentMethods {
          last4
          scheme
          provider
          customerId
          expiryMonth
          id
          expiryYear
          type
        }
        companyInfo {
          tradingName
          primaryHolderName
          industryType
          registeredAddress
          businessDescription
          annualTurnover
          stablishmentDate
          tradingStartDate
          transactionVolumen
          shareholders {
            name
            lastName
            birthdate
            country
            address
            city
            postalCode
            email
            phoneCode
            phoneNumber
          }
          internationalActivity
          kybCompanyCode
        }
        pushNotificationsConfig {
          token
          endpointArn
        }
        smsMarketingConsent
        pushMarketingConsent
        emailMarketingConsent
        smsAllowed
        pushAllowed
        emailAllowed
        forbiddenCurrency {
          id
          iso
          active
          name
          walletBgColor
          walletTextColor
          order
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        intercomProfileUrl
        accountStatus
        type
        passwordResetAt
        passwordResetBy
        customerId
        businessDetail
        shareholders
        SignUpAddressPersonalDetails
        SignUpFinancialDetails
        SignUpIndividualPersonalDetails
        transactionsInfo
        businessType
        author
      }
      content
      read
    }
  }
`;
export const getUserNotifications = /* GraphQL */ `
  query GetUserNotifications($fromDate: AWSDateTime) {
    getUserNotifications(fromDate: $fromDate) {
      id
      type
      title
      body
      url
      user {
        id
        accountType
        membership {
          id
          name
          price
          dailyLimit
          monthlyLimit
          createdAt
          updatedAt
        }
        name
        lastName
        birthdate
        country
        address
        city
        postalCode
        email
        emailVerified
        phoneCode
        phoneNumber
        phoneNumberVerified
        kyc {
          status
          result
          caseId
          applicantId
          reason
        }
        beneficiaries {
          id
          status
          userId
          accountType
          name
          lastName
          country {
            id
            iso
            iso2
            active
            name
            phoneCode
            order
            companyRequirements
            personalRequirements
            createdAt
            updatedAt
          }
          address
          city
          postalCode
          email
          phoneCode
          phoneNumber
          currency {
            id
            iso
            active
            name
            walletBgColor
            walletTextColor
            order
            createdAt
            updatedAt
          }
          bankDetails {
            name
            value
          }
          userProfileId
          usageLevel
          skipInternalCheck
          currencyRequirementType
          beneficiaryCountryId
          beneficiaryCurrencyId
        }
        externalBankAccounts {
          name
          number
          iban
          swift
          sort
        }
        poundsAccount {
          id
          name
          label
          type
          currency
          iban
          bban
          balance
          lastTx
        }
        invitedBy {
          id
          accountType
          membership {
            id
            name
            price
            dailyLimit
            monthlyLimit
            createdAt
            updatedAt
          }
          name
          lastName
          birthdate
          country
          address
          city
          postalCode
          email
          emailVerified
          phoneCode
          phoneNumber
          phoneNumberVerified
          kyc {
            status
            result
            caseId
            applicantId
            reason
          }
          beneficiaries {
            id
            status
            userId
            accountType
            name
            lastName
            address
            city
            postalCode
            email
            phoneCode
            phoneNumber
            userProfileId
            usageLevel
            skipInternalCheck
            currencyRequirementType
            beneficiaryCountryId
            beneficiaryCurrencyId
          }
          externalBankAccounts {
            name
            number
            iban
            swift
            sort
          }
          poundsAccount {
            id
            name
            label
            type
            currency
            iban
            bban
            balance
            lastTx
          }
          invitedBy {
            id
            accountType
            name
            lastName
            birthdate
            country
            address
            city
            postalCode
            email
            emailVerified
            phoneCode
            phoneNumber
            phoneNumberVerified
            readNotifications
            readOnboardings
            profileImage
            smsMarketingConsent
            pushMarketingConsent
            emailMarketingConsent
            smsAllowed
            pushAllowed
            emailAllowed
            createdAt
            updatedAt
            intercomProfileUrl
            accountStatus
            type
            passwordResetAt
            passwordResetBy
            customerId
            businessDetail
            shareholders
            SignUpAddressPersonalDetails
            SignUpFinancialDetails
            SignUpIndividualPersonalDetails
            transactionsInfo
            businessType
            author
          }
          readNotifications
          readOnboardings
          amountsSent {
            daily
            monthly
          }
          profileImage
          paymentMethods {
            last4
            scheme
            provider
            customerId
            expiryMonth
            id
            expiryYear
            type
          }
          companyInfo {
            tradingName
            primaryHolderName
            industryType
            registeredAddress
            businessDescription
            annualTurnover
            stablishmentDate
            tradingStartDate
            transactionVolumen
            internationalActivity
            kybCompanyCode
          }
          pushNotificationsConfig {
            token
            endpointArn
          }
          smsMarketingConsent
          pushMarketingConsent
          emailMarketingConsent
          smsAllowed
          pushAllowed
          emailAllowed
          forbiddenCurrency {
            id
            iso
            active
            name
            walletBgColor
            walletTextColor
            order
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          intercomProfileUrl
          accountStatus
          type
          passwordResetAt
          passwordResetBy
          customerId
          businessDetail
          shareholders
          SignUpAddressPersonalDetails
          SignUpFinancialDetails
          SignUpIndividualPersonalDetails
          transactionsInfo
          businessType
          author
        }
        readNotifications
        readOnboardings
        amountsSent {
          daily
          monthly
        }
        profileImage
        paymentMethods {
          last4
          scheme
          provider
          customerId
          expiryMonth
          id
          expiryYear
          type
        }
        companyInfo {
          tradingName
          primaryHolderName
          industryType
          registeredAddress
          businessDescription
          annualTurnover
          stablishmentDate
          tradingStartDate
          transactionVolumen
          shareholders {
            name
            lastName
            birthdate
            country
            address
            city
            postalCode
            email
            phoneCode
            phoneNumber
          }
          internationalActivity
          kybCompanyCode
        }
        pushNotificationsConfig {
          token
          endpointArn
        }
        smsMarketingConsent
        pushMarketingConsent
        emailMarketingConsent
        smsAllowed
        pushAllowed
        emailAllowed
        forbiddenCurrency {
          id
          iso
          active
          name
          walletBgColor
          walletTextColor
          order
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        intercomProfileUrl
        accountStatus
        type
        passwordResetAt
        passwordResetBy
        customerId
        businessDetail
        shareholders
        SignUpAddressPersonalDetails
        SignUpFinancialDetails
        SignUpIndividualPersonalDetails
        transactionsInfo
        businessType
        author
      }
      notificationUserId
      notifSendApproach
      meta
      createdAt
      updatedAt
    }
  }
`;
export const getDiscountByCode = /* GraphQL */ `
  query GetDiscountByCode($code: String) {
    getDiscountByCode(code: $code) {
      id
      code
      type
      amount
      totalUses
      totalUniqueUses
      expire
      discountUses {
        userId
        date
      }
    }
  }
`;
export const kybSearchCompany = /* GraphQL */ `
  query KybSearchCompany($company: String, $country: String) {
    kybSearchCompany(company: $company, country: $country) {
      success
      data
    }
  }
`;
export const kybGetCaseDetails = /* GraphQL */ `
  query KybGetCaseDetails {
    kybGetCaseDetails {
      success
      data
    }
  }
`;
export const getRatesComparison = /* GraphQL */ `
  query GetRatesComparison(
    $fromCurrency: String
    $toCurrency: String
    $amount: String
  ) {
    getRatesComparison(
      fromCurrency: $fromCurrency
      toCurrency: $toCurrency
      amount: $amount
    ) {
      success
      data
      errorCode
    }
  }
`;
export const verifyLast4DigitsOfPhone = /* GraphQL */ `
  query VerifyLast4DigitsOfPhone($last4Digits: String) {
    verifyLast4DigitsOfPhone(last4Digits: $last4Digits) {
      oneTimePassword
    }
  }
`;
export const listDirectDebitMandates = /* GraphQL */ `
  query ListDirectDebitMandates {
    listDirectDebitMandates {
      items {
        userId
        mandateId
        originatorInformation {
          accountName
          sortCode
          accountNumber
        }
        payerInformation {
          accountName
          sortCode
          accountNumber
        }
        reference
        status
        createdTimestamp
        lastPaymentDate
        lastPaymentAmount
      }
      nextToken
    }
  }
`;
export const getExchangeRate = /* GraphQL */ `
  query GetExchangeRate(
    $fromCurrency: String
    $toCurrency: String
    $toAmount: Float
    $fromAmount: Float
  ) {
    getExchangeRate(
      fromCurrency: $fromCurrency
      toCurrency: $toCurrency
      toAmount: $toAmount
      fromAmount: $fromAmount
    ) {
      fromCurrency
      toCurrency
      fromAmount
      toAmount
      exchangeRate
      fee
      error
    }
  }
`;
export const getFlatRates = /* GraphQL */ `
  query GetFlatRates {
    getFlatRates {
      from
      to
      amount
    }
  }
`;
export const getCountry = /* GraphQL */ `
  query GetCountry($id: ID!) {
    getCountry(id: $id) {
      id
      iso
      iso2
      region {
        id
        name
        createdAt
        updatedAt
      }
      defaultCurrency {
        id
        iso
        active
        name
        walletBgColor
        walletTextColor
        order
        createdAt
        updatedAt
      }
      currency {
        id
        iso
        active
        name
        walletBgColor
        walletTextColor
        order
        createdAt
        updatedAt
      }
      active
      name
      phoneCode
      order
      companyRequirements
      personalRequirements
      createdAt
      updatedAt
    }
  }
`;
export const listCountrys = /* GraphQL */ `
  query ListCountrys(
    $filter: ModelCountryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCountrys(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        iso
        iso2
        region {
          id
          name
          createdAt
          updatedAt
        }
        defaultCurrency {
          id
          iso
          active
          name
          walletBgColor
          walletTextColor
          order
          createdAt
          updatedAt
        }
        currency {
          id
          iso
          active
          name
          walletBgColor
          walletTextColor
          order
          createdAt
          updatedAt
        }
        active
        name
        phoneCode
        order
        companyRequirements
        personalRequirements
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCurrency = /* GraphQL */ `
  query GetCurrency($id: ID!) {
    getCurrency(id: $id) {
      id
      iso
      active
      name
      walletBgColor
      walletTextColor
      order
      createdAt
      updatedAt
    }
  }
`;
export const listCurrencys = /* GraphQL */ `
  query ListCurrencys(
    $filter: ModelCurrencyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCurrencys(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        iso
        active
        name
        walletBgColor
        walletTextColor
        order
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listCurrencyRequirements = /* GraphQL */ `
  query ListCurrencyRequirements($filter: ListCurrencyRequirementsInput) {
    listCurrencyRequirements(filter: $filter) {
      items {
        currency
        type
        name
        requirements
        updatedAt
      }
    }
  }
`;
export const getPair = /* GraphQL */ `
  query GetPair($id: ID!) {
    getPair(id: $id) {
      id
      status
      from {
        id
        iso
        active
        name
        walletBgColor
        walletTextColor
        order
        createdAt
        updatedAt
      }
      to {
        id
        iso
        active
        name
        walletBgColor
        walletTextColor
        order
        createdAt
        updatedAt
      }
      regionDestination {
        id
        name
        createdAt
        updatedAt
      }
      destination {
        id
        iso
        iso2
        region {
          id
          name
          createdAt
          updatedAt
        }
        defaultCurrency {
          id
          iso
          active
          name
          walletBgColor
          walletTextColor
          order
          createdAt
          updatedAt
        }
        currency {
          id
          iso
          active
          name
          walletBgColor
          walletTextColor
          order
          createdAt
          updatedAt
        }
        active
        name
        phoneCode
        order
        companyRequirements
        personalRequirements
        createdAt
        updatedAt
      }
      rates {
        intervalIni
        intervalEnd
        rateMode
        rateFrom
        rateTo
        feeMode
        fee
        percentageFee
      }
      lastExchangeRateBuy
      lastExchangeRateSell
      createdAt
      updatedAt
      sellRateOverride
      profitMargin {
        type
        value
      }
    }
  }
`;
export const getPairWithRate = /* GraphQL */ `
  query GetPairWithRate($input: PairWithRateInput) {
    getPairWithRate(input: $input) {
      id
      status
      from {
        id
        iso
        active
        name
        walletBgColor
        walletTextColor
        order
        createdAt
        updatedAt
      }
      to {
        id
        iso
        active
        name
        walletBgColor
        walletTextColor
        order
        createdAt
        updatedAt
      }
      regionDestination {
        id
        name
        createdAt
        updatedAt
      }
      destination {
        id
        iso
        iso2
        region {
          id
          name
          createdAt
          updatedAt
        }
        defaultCurrency {
          id
          iso
          active
          name
          walletBgColor
          walletTextColor
          order
          createdAt
          updatedAt
        }
        currency {
          id
          iso
          active
          name
          walletBgColor
          walletTextColor
          order
          createdAt
          updatedAt
        }
        active
        name
        phoneCode
        order
        companyRequirements
        personalRequirements
        createdAt
        updatedAt
      }
      rates {
        intervalIni
        intervalEnd
        rateMode
        rateFrom
        rateTo
        feeMode
        fee
        percentageFee
      }
      lastExchangeRateBuy
      lastExchangeRateSell
      createdAt
      updatedAt
      sellRateOverride
      profitMargin {
        type
        value
      }
    }
  }
`;
export const listPairs = /* GraphQL */ `
  query ListPairs(
    $filter: ModelPairFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPairs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        status
        from {
          id
          iso
          active
          name
          walletBgColor
          walletTextColor
          order
          createdAt
          updatedAt
        }
        to {
          id
          iso
          active
          name
          walletBgColor
          walletTextColor
          order
          createdAt
          updatedAt
        }
        regionDestination {
          id
          name
          createdAt
          updatedAt
        }
        destination {
          id
          iso
          iso2
          region {
            id
            name
            createdAt
            updatedAt
          }
          defaultCurrency {
            id
            iso
            active
            name
            walletBgColor
            walletTextColor
            order
            createdAt
            updatedAt
          }
          currency {
            id
            iso
            active
            name
            walletBgColor
            walletTextColor
            order
            createdAt
            updatedAt
          }
          active
          name
          phoneCode
          order
          companyRequirements
          personalRequirements
          createdAt
          updatedAt
        }
        rates {
          intervalIni
          intervalEnd
          rateMode
          rateFrom
          rateTo
          feeMode
          fee
          percentageFee
        }
        lastExchangeRateBuy
        lastExchangeRateSell
        createdAt
        updatedAt
        sellRateOverride
        profitMargin {
          type
          value
        }
      }
      nextToken
    }
  }
`;
export const getTeam = /* GraphQL */ `
  query GetTeam($id: ID!) {
    getTeam(id: $id) {
      id
      name
      email
      active
      accountType
      permissions
      createdAt
      updatedAt
    }
  }
`;
export const listTeams = /* GraphQL */ `
  query ListTeams(
    $filter: ModelTeamFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTeams(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        email
        active
        accountType
        permissions
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getFee = /* GraphQL */ `
  query GetFee($id: ID!) {
    getFee(id: $id) {
      id
      currency {
        id
        iso
        active
        name
        walletBgColor
        walletTextColor
        order
        createdAt
        updatedAt
      }
      paymentType
      rates {
        intervalIni
        intervalEnd
        feePercent
        feeAbsolute
      }
      createdAt
      updatedAt
    }
  }
`;
export const listFees = /* GraphQL */ `
  query ListFees(
    $filter: ModelFeeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFees(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        currency {
          id
          iso
          active
          name
          walletBgColor
          walletTextColor
          order
          createdAt
          updatedAt
        }
        paymentType
        rates {
          intervalIni
          intervalEnd
          feePercent
          feeAbsolute
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getLocalPair = /* GraphQL */ `
  query GetLocalPair($id: ID!) {
    getLocalPair(id: $id) {
      id
      currency {
        id
        iso
        active
        name
        walletBgColor
        walletTextColor
        order
        createdAt
        updatedAt
      }
      region {
        id
        name
        createdAt
        updatedAt
      }
      country {
        id
        iso
        iso2
        region {
          id
          name
          createdAt
          updatedAt
        }
        defaultCurrency {
          id
          iso
          active
          name
          walletBgColor
          walletTextColor
          order
          createdAt
          updatedAt
        }
        currency {
          id
          iso
          active
          name
          walletBgColor
          walletTextColor
          order
          createdAt
          updatedAt
        }
        active
        name
        phoneCode
        order
        companyRequirements
        personalRequirements
        createdAt
        updatedAt
      }
      rates {
        intervalIni
        intervalEnd
        feeMode
        fee
      }
      createdAt
      updatedAt
    }
  }
`;
export const listLocalPairs = /* GraphQL */ `
  query ListLocalPairs(
    $filter: ModelLocalPairFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLocalPairs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        currency {
          id
          iso
          active
          name
          walletBgColor
          walletTextColor
          order
          createdAt
          updatedAt
        }
        region {
          id
          name
          createdAt
          updatedAt
        }
        country {
          id
          iso
          iso2
          region {
            id
            name
            createdAt
            updatedAt
          }
          defaultCurrency {
            id
            iso
            active
            name
            walletBgColor
            walletTextColor
            order
            createdAt
            updatedAt
          }
          currency {
            id
            iso
            active
            name
            walletBgColor
            walletTextColor
            order
            createdAt
            updatedAt
          }
          active
          name
          phoneCode
          order
          companyRequirements
          personalRequirements
          createdAt
          updatedAt
        }
        rates {
          intervalIni
          intervalEnd
          feeMode
          fee
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getUserProfile = /* GraphQL */ `
  query GetUserProfile($id: ID!) {
    getUserProfile(id: $id) {
      id
      accountType
      membership {
        id
        name
        price
        dailyLimit
        monthlyLimit
        createdAt
        updatedAt
      }
      name
      lastName
      birthdate
      country
      address
      city
      postalCode
      email
      emailVerified
      phoneCode
      phoneNumber
      phoneNumberVerified
      kyc {
        status
        result
        caseId
        applicantId
        reason
      }
      beneficiaries {
        id
        status
        userId
        accountType
        name
        lastName
        country {
          id
          iso
          iso2
          region {
            id
            name
            createdAt
            updatedAt
          }
          defaultCurrency {
            id
            iso
            active
            name
            walletBgColor
            walletTextColor
            order
            createdAt
            updatedAt
          }
          currency {
            id
            iso
            active
            name
            walletBgColor
            walletTextColor
            order
            createdAt
            updatedAt
          }
          active
          name
          phoneCode
          order
          companyRequirements
          personalRequirements
          createdAt
          updatedAt
        }
        address
        city
        postalCode
        email
        phoneCode
        phoneNumber
        currency {
          id
          iso
          active
          name
          walletBgColor
          walletTextColor
          order
          createdAt
          updatedAt
        }
        bankDetails {
          name
          value
        }
        userProfileId
        usageLevel
        skipInternalCheck
        currencyRequirementType
        beneficiaryCountryId
        beneficiaryCurrencyId
      }
      externalBankAccounts {
        name
        number
        iban
        swift
        sort
      }
      poundsAccount {
        id
        name
        label
        type
        currency
        iban
        bban
        balance
        lastTx
      }
      invitedBy {
        id
        accountType
        membership {
          id
          name
          price
          dailyLimit
          monthlyLimit
          createdAt
          updatedAt
        }
        name
        lastName
        birthdate
        country
        address
        city
        postalCode
        email
        emailVerified
        phoneCode
        phoneNumber
        phoneNumberVerified
        kyc {
          status
          result
          caseId
          applicantId
          reason
        }
        beneficiaries {
          id
          status
          userId
          accountType
          name
          lastName
          country {
            id
            iso
            iso2
            active
            name
            phoneCode
            order
            companyRequirements
            personalRequirements
            createdAt
            updatedAt
          }
          address
          city
          postalCode
          email
          phoneCode
          phoneNumber
          currency {
            id
            iso
            active
            name
            walletBgColor
            walletTextColor
            order
            createdAt
            updatedAt
          }
          bankDetails {
            name
            value
          }
          userProfileId
          usageLevel
          skipInternalCheck
          currencyRequirementType
          beneficiaryCountryId
          beneficiaryCurrencyId
        }
        externalBankAccounts {
          name
          number
          iban
          swift
          sort
        }
        poundsAccount {
          id
          name
          label
          type
          currency
          iban
          bban
          balance
          lastTx
        }
        invitedBy {
          id
          accountType
          membership {
            id
            name
            price
            dailyLimit
            monthlyLimit
            createdAt
            updatedAt
          }
          name
          lastName
          birthdate
          country
          address
          city
          postalCode
          email
          emailVerified
          phoneCode
          phoneNumber
          phoneNumberVerified
          kyc {
            status
            result
            caseId
            applicantId
            reason
          }
          beneficiaries {
            id
            status
            userId
            accountType
            name
            lastName
            address
            city
            postalCode
            email
            phoneCode
            phoneNumber
            userProfileId
            usageLevel
            skipInternalCheck
            currencyRequirementType
            beneficiaryCountryId
            beneficiaryCurrencyId
          }
          externalBankAccounts {
            name
            number
            iban
            swift
            sort
          }
          poundsAccount {
            id
            name
            label
            type
            currency
            iban
            bban
            balance
            lastTx
          }
          invitedBy {
            id
            accountType
            name
            lastName
            birthdate
            country
            address
            city
            postalCode
            email
            emailVerified
            phoneCode
            phoneNumber
            phoneNumberVerified
            readNotifications
            readOnboardings
            profileImage
            smsMarketingConsent
            pushMarketingConsent
            emailMarketingConsent
            smsAllowed
            pushAllowed
            emailAllowed
            createdAt
            updatedAt
            intercomProfileUrl
            accountStatus
            type
            passwordResetAt
            passwordResetBy
            customerId
            businessDetail
            shareholders
            SignUpAddressPersonalDetails
            SignUpFinancialDetails
            SignUpIndividualPersonalDetails
            transactionsInfo
            businessType
            author
          }
          readNotifications
          readOnboardings
          amountsSent {
            daily
            monthly
          }
          profileImage
          paymentMethods {
            last4
            scheme
            provider
            customerId
            expiryMonth
            id
            expiryYear
            type
          }
          companyInfo {
            tradingName
            primaryHolderName
            industryType
            registeredAddress
            businessDescription
            annualTurnover
            stablishmentDate
            tradingStartDate
            transactionVolumen
            internationalActivity
            kybCompanyCode
          }
          pushNotificationsConfig {
            token
            endpointArn
          }
          smsMarketingConsent
          pushMarketingConsent
          emailMarketingConsent
          smsAllowed
          pushAllowed
          emailAllowed
          forbiddenCurrency {
            id
            iso
            active
            name
            walletBgColor
            walletTextColor
            order
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          intercomProfileUrl
          accountStatus
          type
          passwordResetAt
          passwordResetBy
          customerId
          businessDetail
          shareholders
          SignUpAddressPersonalDetails
          SignUpFinancialDetails
          SignUpIndividualPersonalDetails
          transactionsInfo
          businessType
          author
        }
        readNotifications
        readOnboardings
        amountsSent {
          daily
          monthly
        }
        profileImage
        paymentMethods {
          last4
          scheme
          provider
          customerId
          expiryMonth
          id
          expiryYear
          type
        }
        companyInfo {
          tradingName
          primaryHolderName
          industryType
          registeredAddress
          businessDescription
          annualTurnover
          stablishmentDate
          tradingStartDate
          transactionVolumen
          shareholders {
            name
            lastName
            birthdate
            country
            address
            city
            postalCode
            email
            phoneCode
            phoneNumber
          }
          internationalActivity
          kybCompanyCode
        }
        pushNotificationsConfig {
          token
          endpointArn
        }
        smsMarketingConsent
        pushMarketingConsent
        emailMarketingConsent
        smsAllowed
        pushAllowed
        emailAllowed
        forbiddenCurrency {
          id
          iso
          active
          name
          walletBgColor
          walletTextColor
          order
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        intercomProfileUrl
        accountStatus
        type
        passwordResetAt
        passwordResetBy
        customerId
        businessDetail
        shareholders
        SignUpAddressPersonalDetails
        SignUpFinancialDetails
        SignUpIndividualPersonalDetails
        transactionsInfo
        businessType
        author
      }
      readNotifications
      readOnboardings
      amountsSent {
        daily
        monthly
      }
      profileImage
      paymentMethods {
        last4
        scheme
        provider
        customerId
        expiryMonth
        id
        expiryYear
        type
      }
      companyInfo {
        tradingName
        primaryHolderName
        industryType
        registeredAddress
        businessDescription
        annualTurnover
        stablishmentDate
        tradingStartDate
        transactionVolumen
        shareholders {
          name
          lastName
          birthdate
          country
          address
          city
          postalCode
          email
          phoneCode
          phoneNumber
        }
        internationalActivity
        kybCompanyCode
      }
      pushNotificationsConfig {
        token
        endpointArn
      }
      smsMarketingConsent
      pushMarketingConsent
      emailMarketingConsent
      smsAllowed
      pushAllowed
      emailAllowed
      forbiddenCurrency {
        id
        iso
        active
        name
        walletBgColor
        walletTextColor
        order
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      intercomProfileUrl
      accountStatus
      type
      passwordResetAt
      passwordResetBy
      customerId
      businessDetail
      shareholders
      SignUpAddressPersonalDetails
      SignUpFinancialDetails
      SignUpIndividualPersonalDetails
      transactionsInfo
      businessType
      author
    }
  }
`;
export const listUserProfiles = /* GraphQL */ `
  query ListUserProfiles(
    $filter: ModelUserProfileFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserProfiles(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        accountType
        membership {
          id
          name
          price
          dailyLimit
          monthlyLimit
          createdAt
          updatedAt
        }
        name
        lastName
        birthdate
        country
        address
        city
        postalCode
        email
        emailVerified
        phoneCode
        phoneNumber
        phoneNumberVerified
        kyc {
          status
          result
          caseId
          applicantId
          reason
        }
        beneficiaries {
          id
          status
          userId
          accountType
          name
          lastName
          country {
            id
            iso
            iso2
            active
            name
            phoneCode
            order
            companyRequirements
            personalRequirements
            createdAt
            updatedAt
          }
          address
          city
          postalCode
          email
          phoneCode
          phoneNumber
          currency {
            id
            iso
            active
            name
            walletBgColor
            walletTextColor
            order
            createdAt
            updatedAt
          }
          bankDetails {
            name
            value
          }
          userProfileId
          usageLevel
          skipInternalCheck
          currencyRequirementType
          beneficiaryCountryId
          beneficiaryCurrencyId
        }
        externalBankAccounts {
          name
          number
          iban
          swift
          sort
        }
        poundsAccount {
          id
          name
          label
          type
          currency
          iban
          bban
          balance
          lastTx
        }
        invitedBy {
          id
          accountType
          membership {
            id
            name
            price
            dailyLimit
            monthlyLimit
            createdAt
            updatedAt
          }
          name
          lastName
          birthdate
          country
          address
          city
          postalCode
          email
          emailVerified
          phoneCode
          phoneNumber
          phoneNumberVerified
          kyc {
            status
            result
            caseId
            applicantId
            reason
          }
          beneficiaries {
            id
            status
            userId
            accountType
            name
            lastName
            address
            city
            postalCode
            email
            phoneCode
            phoneNumber
            userProfileId
            usageLevel
            skipInternalCheck
            currencyRequirementType
            beneficiaryCountryId
            beneficiaryCurrencyId
          }
          externalBankAccounts {
            name
            number
            iban
            swift
            sort
          }
          poundsAccount {
            id
            name
            label
            type
            currency
            iban
            bban
            balance
            lastTx
          }
          invitedBy {
            id
            accountType
            name
            lastName
            birthdate
            country
            address
            city
            postalCode
            email
            emailVerified
            phoneCode
            phoneNumber
            phoneNumberVerified
            readNotifications
            readOnboardings
            profileImage
            smsMarketingConsent
            pushMarketingConsent
            emailMarketingConsent
            smsAllowed
            pushAllowed
            emailAllowed
            createdAt
            updatedAt
            intercomProfileUrl
            accountStatus
            type
            passwordResetAt
            passwordResetBy
            customerId
            businessDetail
            shareholders
            SignUpAddressPersonalDetails
            SignUpFinancialDetails
            SignUpIndividualPersonalDetails
            transactionsInfo
            businessType
            author
          }
          readNotifications
          readOnboardings
          amountsSent {
            daily
            monthly
          }
          profileImage
          paymentMethods {
            last4
            scheme
            provider
            customerId
            expiryMonth
            id
            expiryYear
            type
          }
          companyInfo {
            tradingName
            primaryHolderName
            industryType
            registeredAddress
            businessDescription
            annualTurnover
            stablishmentDate
            tradingStartDate
            transactionVolumen
            internationalActivity
            kybCompanyCode
          }
          pushNotificationsConfig {
            token
            endpointArn
          }
          smsMarketingConsent
          pushMarketingConsent
          emailMarketingConsent
          smsAllowed
          pushAllowed
          emailAllowed
          forbiddenCurrency {
            id
            iso
            active
            name
            walletBgColor
            walletTextColor
            order
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          intercomProfileUrl
          accountStatus
          type
          passwordResetAt
          passwordResetBy
          customerId
          businessDetail
          shareholders
          SignUpAddressPersonalDetails
          SignUpFinancialDetails
          SignUpIndividualPersonalDetails
          transactionsInfo
          businessType
          author
        }
        readNotifications
        readOnboardings
        amountsSent {
          daily
          monthly
        }
        profileImage
        paymentMethods {
          last4
          scheme
          provider
          customerId
          expiryMonth
          id
          expiryYear
          type
        }
        companyInfo {
          tradingName
          primaryHolderName
          industryType
          registeredAddress
          businessDescription
          annualTurnover
          stablishmentDate
          tradingStartDate
          transactionVolumen
          shareholders {
            name
            lastName
            birthdate
            country
            address
            city
            postalCode
            email
            phoneCode
            phoneNumber
          }
          internationalActivity
          kybCompanyCode
        }
        pushNotificationsConfig {
          token
          endpointArn
        }
        smsMarketingConsent
        pushMarketingConsent
        emailMarketingConsent
        smsAllowed
        pushAllowed
        emailAllowed
        forbiddenCurrency {
          id
          iso
          active
          name
          walletBgColor
          walletTextColor
          order
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        intercomProfileUrl
        accountStatus
        type
        passwordResetAt
        passwordResetBy
        customerId
        businessDetail
        shareholders
        SignUpAddressPersonalDetails
        SignUpFinancialDetails
        SignUpIndividualPersonalDetails
        transactionsInfo
        businessType
        author
      }
      nextToken
    }
  }
`;
export const getTransaction = /* GraphQL */ `
  query GetTransaction($id: ID!) {
    getTransaction(id: $id) {
      id
      type
      userIdFrom
      userIdTo
      userFrom {
        id
        accountType
        membership {
          id
          name
          price
          dailyLimit
          monthlyLimit
          createdAt
          updatedAt
        }
        name
        lastName
        birthdate
        country
        address
        city
        postalCode
        email
        emailVerified
        phoneCode
        phoneNumber
        phoneNumberVerified
        kyc {
          status
          result
          caseId
          applicantId
          reason
        }
        beneficiaries {
          id
          status
          userId
          accountType
          name
          lastName
          country {
            id
            iso
            iso2
            active
            name
            phoneCode
            order
            companyRequirements
            personalRequirements
            createdAt
            updatedAt
          }
          address
          city
          postalCode
          email
          phoneCode
          phoneNumber
          currency {
            id
            iso
            active
            name
            walletBgColor
            walletTextColor
            order
            createdAt
            updatedAt
          }
          bankDetails {
            name
            value
          }
          userProfileId
          usageLevel
          skipInternalCheck
          currencyRequirementType
          beneficiaryCountryId
          beneficiaryCurrencyId
        }
        externalBankAccounts {
          name
          number
          iban
          swift
          sort
        }
        poundsAccount {
          id
          name
          label
          type
          currency
          iban
          bban
          balance
          lastTx
        }
        invitedBy {
          id
          accountType
          membership {
            id
            name
            price
            dailyLimit
            monthlyLimit
            createdAt
            updatedAt
          }
          name
          lastName
          birthdate
          country
          address
          city
          postalCode
          email
          emailVerified
          phoneCode
          phoneNumber
          phoneNumberVerified
          kyc {
            status
            result
            caseId
            applicantId
            reason
          }
          beneficiaries {
            id
            status
            userId
            accountType
            name
            lastName
            address
            city
            postalCode
            email
            phoneCode
            phoneNumber
            userProfileId
            usageLevel
            skipInternalCheck
            currencyRequirementType
            beneficiaryCountryId
            beneficiaryCurrencyId
          }
          externalBankAccounts {
            name
            number
            iban
            swift
            sort
          }
          poundsAccount {
            id
            name
            label
            type
            currency
            iban
            bban
            balance
            lastTx
          }
          invitedBy {
            id
            accountType
            name
            lastName
            birthdate
            country
            address
            city
            postalCode
            email
            emailVerified
            phoneCode
            phoneNumber
            phoneNumberVerified
            readNotifications
            readOnboardings
            profileImage
            smsMarketingConsent
            pushMarketingConsent
            emailMarketingConsent
            smsAllowed
            pushAllowed
            emailAllowed
            createdAt
            updatedAt
            intercomProfileUrl
            accountStatus
            type
            passwordResetAt
            passwordResetBy
            customerId
            businessDetail
            shareholders
            SignUpAddressPersonalDetails
            SignUpFinancialDetails
            SignUpIndividualPersonalDetails
            transactionsInfo
            businessType
            author
          }
          readNotifications
          readOnboardings
          amountsSent {
            daily
            monthly
          }
          profileImage
          paymentMethods {
            last4
            scheme
            provider
            customerId
            expiryMonth
            id
            expiryYear
            type
          }
          companyInfo {
            tradingName
            primaryHolderName
            industryType
            registeredAddress
            businessDescription
            annualTurnover
            stablishmentDate
            tradingStartDate
            transactionVolumen
            internationalActivity
            kybCompanyCode
          }
          pushNotificationsConfig {
            token
            endpointArn
          }
          smsMarketingConsent
          pushMarketingConsent
          emailMarketingConsent
          smsAllowed
          pushAllowed
          emailAllowed
          forbiddenCurrency {
            id
            iso
            active
            name
            walletBgColor
            walletTextColor
            order
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          intercomProfileUrl
          accountStatus
          type
          passwordResetAt
          passwordResetBy
          customerId
          businessDetail
          shareholders
          SignUpAddressPersonalDetails
          SignUpFinancialDetails
          SignUpIndividualPersonalDetails
          transactionsInfo
          businessType
          author
        }
        readNotifications
        readOnboardings
        amountsSent {
          daily
          monthly
        }
        profileImage
        paymentMethods {
          last4
          scheme
          provider
          customerId
          expiryMonth
          id
          expiryYear
          type
        }
        companyInfo {
          tradingName
          primaryHolderName
          industryType
          registeredAddress
          businessDescription
          annualTurnover
          stablishmentDate
          tradingStartDate
          transactionVolumen
          shareholders {
            name
            lastName
            birthdate
            country
            address
            city
            postalCode
            email
            phoneCode
            phoneNumber
          }
          internationalActivity
          kybCompanyCode
        }
        pushNotificationsConfig {
          token
          endpointArn
        }
        smsMarketingConsent
        pushMarketingConsent
        emailMarketingConsent
        smsAllowed
        pushAllowed
        emailAllowed
        forbiddenCurrency {
          id
          iso
          active
          name
          walletBgColor
          walletTextColor
          order
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        intercomProfileUrl
        accountStatus
        type
        passwordResetAt
        passwordResetBy
        customerId
        businessDetail
        shareholders
        SignUpAddressPersonalDetails
        SignUpFinancialDetails
        SignUpIndividualPersonalDetails
        transactionsInfo
        businessType
        author
      }
      externalUserFrom {
        name
        lastName
        iban
      }
      userTo {
        id
        accountType
        membership {
          id
          name
          price
          dailyLimit
          monthlyLimit
          createdAt
          updatedAt
        }
        name
        lastName
        birthdate
        country
        address
        city
        postalCode
        email
        emailVerified
        phoneCode
        phoneNumber
        phoneNumberVerified
        kyc {
          status
          result
          caseId
          applicantId
          reason
        }
        beneficiaries {
          id
          status
          userId
          accountType
          name
          lastName
          country {
            id
            iso
            iso2
            active
            name
            phoneCode
            order
            companyRequirements
            personalRequirements
            createdAt
            updatedAt
          }
          address
          city
          postalCode
          email
          phoneCode
          phoneNumber
          currency {
            id
            iso
            active
            name
            walletBgColor
            walletTextColor
            order
            createdAt
            updatedAt
          }
          bankDetails {
            name
            value
          }
          userProfileId
          usageLevel
          skipInternalCheck
          currencyRequirementType
          beneficiaryCountryId
          beneficiaryCurrencyId
        }
        externalBankAccounts {
          name
          number
          iban
          swift
          sort
        }
        poundsAccount {
          id
          name
          label
          type
          currency
          iban
          bban
          balance
          lastTx
        }
        invitedBy {
          id
          accountType
          membership {
            id
            name
            price
            dailyLimit
            monthlyLimit
            createdAt
            updatedAt
          }
          name
          lastName
          birthdate
          country
          address
          city
          postalCode
          email
          emailVerified
          phoneCode
          phoneNumber
          phoneNumberVerified
          kyc {
            status
            result
            caseId
            applicantId
            reason
          }
          beneficiaries {
            id
            status
            userId
            accountType
            name
            lastName
            address
            city
            postalCode
            email
            phoneCode
            phoneNumber
            userProfileId
            usageLevel
            skipInternalCheck
            currencyRequirementType
            beneficiaryCountryId
            beneficiaryCurrencyId
          }
          externalBankAccounts {
            name
            number
            iban
            swift
            sort
          }
          poundsAccount {
            id
            name
            label
            type
            currency
            iban
            bban
            balance
            lastTx
          }
          invitedBy {
            id
            accountType
            name
            lastName
            birthdate
            country
            address
            city
            postalCode
            email
            emailVerified
            phoneCode
            phoneNumber
            phoneNumberVerified
            readNotifications
            readOnboardings
            profileImage
            smsMarketingConsent
            pushMarketingConsent
            emailMarketingConsent
            smsAllowed
            pushAllowed
            emailAllowed
            createdAt
            updatedAt
            intercomProfileUrl
            accountStatus
            type
            passwordResetAt
            passwordResetBy
            customerId
            businessDetail
            shareholders
            SignUpAddressPersonalDetails
            SignUpFinancialDetails
            SignUpIndividualPersonalDetails
            transactionsInfo
            businessType
            author
          }
          readNotifications
          readOnboardings
          amountsSent {
            daily
            monthly
          }
          profileImage
          paymentMethods {
            last4
            scheme
            provider
            customerId
            expiryMonth
            id
            expiryYear
            type
          }
          companyInfo {
            tradingName
            primaryHolderName
            industryType
            registeredAddress
            businessDescription
            annualTurnover
            stablishmentDate
            tradingStartDate
            transactionVolumen
            internationalActivity
            kybCompanyCode
          }
          pushNotificationsConfig {
            token
            endpointArn
          }
          smsMarketingConsent
          pushMarketingConsent
          emailMarketingConsent
          smsAllowed
          pushAllowed
          emailAllowed
          forbiddenCurrency {
            id
            iso
            active
            name
            walletBgColor
            walletTextColor
            order
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          intercomProfileUrl
          accountStatus
          type
          passwordResetAt
          passwordResetBy
          customerId
          businessDetail
          shareholders
          SignUpAddressPersonalDetails
          SignUpFinancialDetails
          SignUpIndividualPersonalDetails
          transactionsInfo
          businessType
          author
        }
        readNotifications
        readOnboardings
        amountsSent {
          daily
          monthly
        }
        profileImage
        paymentMethods {
          last4
          scheme
          provider
          customerId
          expiryMonth
          id
          expiryYear
          type
        }
        companyInfo {
          tradingName
          primaryHolderName
          industryType
          registeredAddress
          businessDescription
          annualTurnover
          stablishmentDate
          tradingStartDate
          transactionVolumen
          shareholders {
            name
            lastName
            birthdate
            country
            address
            city
            postalCode
            email
            phoneCode
            phoneNumber
          }
          internationalActivity
          kybCompanyCode
        }
        pushNotificationsConfig {
          token
          endpointArn
        }
        smsMarketingConsent
        pushMarketingConsent
        emailMarketingConsent
        smsAllowed
        pushAllowed
        emailAllowed
        forbiddenCurrency {
          id
          iso
          active
          name
          walletBgColor
          walletTextColor
          order
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        intercomProfileUrl
        accountStatus
        type
        passwordResetAt
        passwordResetBy
        customerId
        businessDetail
        shareholders
        SignUpAddressPersonalDetails
        SignUpFinancialDetails
        SignUpIndividualPersonalDetails
        transactionsInfo
        businessType
        author
      }
      beneficiary {
        id
        status
        userId
        accountType
        name
        lastName
        country {
          id
          iso
          iso2
          region {
            id
            name
            createdAt
            updatedAt
          }
          defaultCurrency {
            id
            iso
            active
            name
            walletBgColor
            walletTextColor
            order
            createdAt
            updatedAt
          }
          currency {
            id
            iso
            active
            name
            walletBgColor
            walletTextColor
            order
            createdAt
            updatedAt
          }
          active
          name
          phoneCode
          order
          companyRequirements
          personalRequirements
          createdAt
          updatedAt
        }
        address
        city
        postalCode
        email
        phoneCode
        phoneNumber
        currency {
          id
          iso
          active
          name
          walletBgColor
          walletTextColor
          order
          createdAt
          updatedAt
        }
        bankDetails {
          name
          value
        }
        userProfileId
        usageLevel
        skipInternalCheck
        currencyRequirementType
        beneficiaryCountryId
        beneficiaryCurrencyId
      }
      fromCurrency
      toCurrency
      sendingAmount
      exchangeRate
      receivingAmount
      fee
      transferFee
      discount {
        id
        code
        type
        amount
        totalUses
        totalUniqueUses
        expire
        discountUses {
          userId
          date
        }
      }
      reference
      purpose
      status
      steps {
        timestamp
        description
        order
        status
      }
      errorCode
      method
      userFromNote
      userFromAttachment
      userToNote
      userToAttachment
      createdAt
      internalId
      failureReason
      adminAttachments
      debtorAccount {
        addressLine1
        addressLine2
        country
        postcode
        name
        ownerName
        iban
        bban
        descriptor
        institutionName
        transactionOwnerName
      }
      creditorAccount {
        addressLine1
        addressLine2
        country
        postcode
        name
        ownerName
        iban
        bban
        descriptor
        institutionName
        transactionOwnerName
      }
      endToEndId
      rateQuoteId
      runningBalanceFrom
      runningBalanceTo
    }
  }
`;
export const getTransactionV2 = /* GraphQL */ `
  query GetTransactionV2($id: ID!) {
    getTransactionV2(id: $id) {
      userId
      id
      type
      action
      country
      lng
      city
      region
      lat
      ipAddress
      metadataId
      reference
      internalId
      amount
      debtorSource
      debtorName
      debtorUserId
      debtorCardNumber
      debtorIban
      debtorAccountNumber
      debtorSortCode
      exchangeRate
      fee
      transferFee
      scope
      creditorAddress
      iban
      accountType
      creditorSource
      creditorName
      creditorUserId
      creditorIban
      creditorAccountNumber
      creditorSortCode
      swift
      currency
      endToEndId
      status
      direction
      expireAt
      destinationCurrency
      destinationAmount
      timestamp
      subType
      attachment
      note
      createdAt
      updatedAt
    }
  }
`;
export const searchTransactions = /* GraphQL */ `
  query SearchTransactions(
    $filter: ModelTransactionSearchInput
    $limit: Int
    $nextToken: String
    $value: String
    $key: TransactionInputQueryType
  ) {
    searchTransactions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      value: $value
      key: $key
    ) {
      items {
        id
        type
        userIdFrom
        userIdTo
        userFrom {
          id
          accountType
          membership {
            id
            name
            price
            dailyLimit
            monthlyLimit
            createdAt
            updatedAt
          }
          name
          lastName
          birthdate
          country
          address
          city
          postalCode
          email
          emailVerified
          phoneCode
          phoneNumber
          phoneNumberVerified
          kyc {
            status
            result
            caseId
            applicantId
            reason
          }
          beneficiaries {
            id
            status
            userId
            accountType
            name
            lastName
            address
            city
            postalCode
            email
            phoneCode
            phoneNumber
            userProfileId
            usageLevel
            skipInternalCheck
            currencyRequirementType
            beneficiaryCountryId
            beneficiaryCurrencyId
          }
          externalBankAccounts {
            name
            number
            iban
            swift
            sort
          }
          poundsAccount {
            id
            name
            label
            type
            currency
            iban
            bban
            balance
            lastTx
          }
          invitedBy {
            id
            accountType
            name
            lastName
            birthdate
            country
            address
            city
            postalCode
            email
            emailVerified
            phoneCode
            phoneNumber
            phoneNumberVerified
            readNotifications
            readOnboardings
            profileImage
            smsMarketingConsent
            pushMarketingConsent
            emailMarketingConsent
            smsAllowed
            pushAllowed
            emailAllowed
            createdAt
            updatedAt
            intercomProfileUrl
            accountStatus
            type
            passwordResetAt
            passwordResetBy
            customerId
            businessDetail
            shareholders
            SignUpAddressPersonalDetails
            SignUpFinancialDetails
            SignUpIndividualPersonalDetails
            transactionsInfo
            businessType
            author
          }
          readNotifications
          readOnboardings
          amountsSent {
            daily
            monthly
          }
          profileImage
          paymentMethods {
            last4
            scheme
            provider
            customerId
            expiryMonth
            id
            expiryYear
            type
          }
          companyInfo {
            tradingName
            primaryHolderName
            industryType
            registeredAddress
            businessDescription
            annualTurnover
            stablishmentDate
            tradingStartDate
            transactionVolumen
            internationalActivity
            kybCompanyCode
          }
          pushNotificationsConfig {
            token
            endpointArn
          }
          smsMarketingConsent
          pushMarketingConsent
          emailMarketingConsent
          smsAllowed
          pushAllowed
          emailAllowed
          forbiddenCurrency {
            id
            iso
            active
            name
            walletBgColor
            walletTextColor
            order
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          intercomProfileUrl
          accountStatus
          type
          passwordResetAt
          passwordResetBy
          customerId
          businessDetail
          shareholders
          SignUpAddressPersonalDetails
          SignUpFinancialDetails
          SignUpIndividualPersonalDetails
          transactionsInfo
          businessType
          author
        }
        externalUserFrom {
          name
          lastName
          iban
        }
        userTo {
          id
          accountType
          membership {
            id
            name
            price
            dailyLimit
            monthlyLimit
            createdAt
            updatedAt
          }
          name
          lastName
          birthdate
          country
          address
          city
          postalCode
          email
          emailVerified
          phoneCode
          phoneNumber
          phoneNumberVerified
          kyc {
            status
            result
            caseId
            applicantId
            reason
          }
          beneficiaries {
            id
            status
            userId
            accountType
            name
            lastName
            address
            city
            postalCode
            email
            phoneCode
            phoneNumber
            userProfileId
            usageLevel
            skipInternalCheck
            currencyRequirementType
            beneficiaryCountryId
            beneficiaryCurrencyId
          }
          externalBankAccounts {
            name
            number
            iban
            swift
            sort
          }
          poundsAccount {
            id
            name
            label
            type
            currency
            iban
            bban
            balance
            lastTx
          }
          invitedBy {
            id
            accountType
            name
            lastName
            birthdate
            country
            address
            city
            postalCode
            email
            emailVerified
            phoneCode
            phoneNumber
            phoneNumberVerified
            readNotifications
            readOnboardings
            profileImage
            smsMarketingConsent
            pushMarketingConsent
            emailMarketingConsent
            smsAllowed
            pushAllowed
            emailAllowed
            createdAt
            updatedAt
            intercomProfileUrl
            accountStatus
            type
            passwordResetAt
            passwordResetBy
            customerId
            businessDetail
            shareholders
            SignUpAddressPersonalDetails
            SignUpFinancialDetails
            SignUpIndividualPersonalDetails
            transactionsInfo
            businessType
            author
          }
          readNotifications
          readOnboardings
          amountsSent {
            daily
            monthly
          }
          profileImage
          paymentMethods {
            last4
            scheme
            provider
            customerId
            expiryMonth
            id
            expiryYear
            type
          }
          companyInfo {
            tradingName
            primaryHolderName
            industryType
            registeredAddress
            businessDescription
            annualTurnover
            stablishmentDate
            tradingStartDate
            transactionVolumen
            internationalActivity
            kybCompanyCode
          }
          pushNotificationsConfig {
            token
            endpointArn
          }
          smsMarketingConsent
          pushMarketingConsent
          emailMarketingConsent
          smsAllowed
          pushAllowed
          emailAllowed
          forbiddenCurrency {
            id
            iso
            active
            name
            walletBgColor
            walletTextColor
            order
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          intercomProfileUrl
          accountStatus
          type
          passwordResetAt
          passwordResetBy
          customerId
          businessDetail
          shareholders
          SignUpAddressPersonalDetails
          SignUpFinancialDetails
          SignUpIndividualPersonalDetails
          transactionsInfo
          businessType
          author
        }
        beneficiary {
          id
          status
          userId
          accountType
          name
          lastName
          country {
            id
            iso
            iso2
            active
            name
            phoneCode
            order
            companyRequirements
            personalRequirements
            createdAt
            updatedAt
          }
          address
          city
          postalCode
          email
          phoneCode
          phoneNumber
          currency {
            id
            iso
            active
            name
            walletBgColor
            walletTextColor
            order
            createdAt
            updatedAt
          }
          bankDetails {
            name
            value
          }
          userProfileId
          usageLevel
          skipInternalCheck
          currencyRequirementType
          beneficiaryCountryId
          beneficiaryCurrencyId
        }
        fromCurrency
        toCurrency
        sendingAmount
        exchangeRate
        receivingAmount
        fee
        transferFee
        discount {
          id
          code
          type
          amount
          totalUses
          totalUniqueUses
          expire
          discountUses {
            userId
            date
          }
        }
        reference
        purpose
        status
        steps {
          timestamp
          description
          order
          status
        }
        errorCode
        method
        userFromNote
        userFromAttachment
        userToNote
        userToAttachment
        createdAt
        internalId
        failureReason
        adminAttachments
        debtorAccount {
          addressLine1
          addressLine2
          country
          postcode
          name
          ownerName
          iban
          bban
          descriptor
          institutionName
          transactionOwnerName
        }
        creditorAccount {
          addressLine1
          addressLine2
          country
          postcode
          name
          ownerName
          iban
          bban
          descriptor
          institutionName
          transactionOwnerName
        }
        endToEndId
        rateQuoteId
        runningBalanceFrom
        runningBalanceTo
      }
      nextToken
    }
  }
`;
export const searchCustomers = /* GraphQL */ `
  query SearchCustomers(
    $filter: ModelCustomerSearchInput
    $limit: Int
    $nextToken: String
    $value: String
    $key: CustomerInputQueryType
  ) {
    searchCustomers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      value: $value
      key: $key
    ) {
      items {
        id
        accountType
        membership {
          id
          name
          price
          dailyLimit
          monthlyLimit
          createdAt
          updatedAt
        }
        name
        lastName
        birthdate
        country
        address
        city
        postalCode
        email
        emailVerified
        phoneCode
        phoneNumber
        phoneNumberVerified
        kyc {
          status
          result
          caseId
          applicantId
          reason
        }
        beneficiaries {
          id
          status
          userId
          accountType
          name
          lastName
          country {
            id
            iso
            iso2
            active
            name
            phoneCode
            order
            companyRequirements
            personalRequirements
            createdAt
            updatedAt
          }
          address
          city
          postalCode
          email
          phoneCode
          phoneNumber
          currency {
            id
            iso
            active
            name
            walletBgColor
            walletTextColor
            order
            createdAt
            updatedAt
          }
          bankDetails {
            name
            value
          }
          userProfileId
          usageLevel
          skipInternalCheck
          currencyRequirementType
          beneficiaryCountryId
          beneficiaryCurrencyId
        }
        externalBankAccounts {
          name
          number
          iban
          swift
          sort
        }
        poundsAccount {
          id
          name
          label
          type
          currency
          iban
          bban
          balance
          lastTx
        }
        invitedBy {
          id
          accountType
          membership {
            id
            name
            price
            dailyLimit
            monthlyLimit
            createdAt
            updatedAt
          }
          name
          lastName
          birthdate
          country
          address
          city
          postalCode
          email
          emailVerified
          phoneCode
          phoneNumber
          phoneNumberVerified
          kyc {
            status
            result
            caseId
            applicantId
            reason
          }
          beneficiaries {
            id
            status
            userId
            accountType
            name
            lastName
            address
            city
            postalCode
            email
            phoneCode
            phoneNumber
            userProfileId
            usageLevel
            skipInternalCheck
            currencyRequirementType
            beneficiaryCountryId
            beneficiaryCurrencyId
          }
          externalBankAccounts {
            name
            number
            iban
            swift
            sort
          }
          poundsAccount {
            id
            name
            label
            type
            currency
            iban
            bban
            balance
            lastTx
          }
          invitedBy {
            id
            accountType
            name
            lastName
            birthdate
            country
            address
            city
            postalCode
            email
            emailVerified
            phoneCode
            phoneNumber
            phoneNumberVerified
            readNotifications
            readOnboardings
            profileImage
            smsMarketingConsent
            pushMarketingConsent
            emailMarketingConsent
            smsAllowed
            pushAllowed
            emailAllowed
            createdAt
            updatedAt
            intercomProfileUrl
            accountStatus
            type
            passwordResetAt
            passwordResetBy
            customerId
            businessDetail
            shareholders
            SignUpAddressPersonalDetails
            SignUpFinancialDetails
            SignUpIndividualPersonalDetails
            transactionsInfo
            businessType
            author
          }
          readNotifications
          readOnboardings
          amountsSent {
            daily
            monthly
          }
          profileImage
          paymentMethods {
            last4
            scheme
            provider
            customerId
            expiryMonth
            id
            expiryYear
            type
          }
          companyInfo {
            tradingName
            primaryHolderName
            industryType
            registeredAddress
            businessDescription
            annualTurnover
            stablishmentDate
            tradingStartDate
            transactionVolumen
            internationalActivity
            kybCompanyCode
          }
          pushNotificationsConfig {
            token
            endpointArn
          }
          smsMarketingConsent
          pushMarketingConsent
          emailMarketingConsent
          smsAllowed
          pushAllowed
          emailAllowed
          forbiddenCurrency {
            id
            iso
            active
            name
            walletBgColor
            walletTextColor
            order
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          intercomProfileUrl
          accountStatus
          type
          passwordResetAt
          passwordResetBy
          customerId
          businessDetail
          shareholders
          SignUpAddressPersonalDetails
          SignUpFinancialDetails
          SignUpIndividualPersonalDetails
          transactionsInfo
          businessType
          author
        }
        readNotifications
        readOnboardings
        amountsSent {
          daily
          monthly
        }
        profileImage
        paymentMethods {
          last4
          scheme
          provider
          customerId
          expiryMonth
          id
          expiryYear
          type
        }
        companyInfo {
          tradingName
          primaryHolderName
          industryType
          registeredAddress
          businessDescription
          annualTurnover
          stablishmentDate
          tradingStartDate
          transactionVolumen
          shareholders {
            name
            lastName
            birthdate
            country
            address
            city
            postalCode
            email
            phoneCode
            phoneNumber
          }
          internationalActivity
          kybCompanyCode
        }
        pushNotificationsConfig {
          token
          endpointArn
        }
        smsMarketingConsent
        pushMarketingConsent
        emailMarketingConsent
        smsAllowed
        pushAllowed
        emailAllowed
        forbiddenCurrency {
          id
          iso
          active
          name
          walletBgColor
          walletTextColor
          order
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        intercomProfileUrl
        accountStatus
        type
        passwordResetAt
        passwordResetBy
        customerId
        businessDetail
        shareholders
        SignUpAddressPersonalDetails
        SignUpFinancialDetails
        SignUpIndividualPersonalDetails
        transactionsInfo
        businessType
        author
      }
      nextToken
    }
  }
`;
export const listTransactions = /* GraphQL */ `
  query ListTransactions(
    $filter: ModelTransactionFilterInput
    $limit: Int
    $nextToken: String
    $createdDayHour: String
  ) {
    listTransactions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      createdDayHour: $createdDayHour
    ) {
      items {
        id
        type
        userIdFrom
        userIdTo
        userFrom {
          id
          accountType
          membership {
            id
            name
            price
            dailyLimit
            monthlyLimit
            createdAt
            updatedAt
          }
          name
          lastName
          birthdate
          country
          address
          city
          postalCode
          email
          emailVerified
          phoneCode
          phoneNumber
          phoneNumberVerified
          kyc {
            status
            result
            caseId
            applicantId
            reason
          }
          beneficiaries {
            id
            status
            userId
            accountType
            name
            lastName
            address
            city
            postalCode
            email
            phoneCode
            phoneNumber
            userProfileId
            usageLevel
            skipInternalCheck
            currencyRequirementType
            beneficiaryCountryId
            beneficiaryCurrencyId
          }
          externalBankAccounts {
            name
            number
            iban
            swift
            sort
          }
          poundsAccount {
            id
            name
            label
            type
            currency
            iban
            bban
            balance
            lastTx
          }
          invitedBy {
            id
            accountType
            name
            lastName
            birthdate
            country
            address
            city
            postalCode
            email
            emailVerified
            phoneCode
            phoneNumber
            phoneNumberVerified
            readNotifications
            readOnboardings
            profileImage
            smsMarketingConsent
            pushMarketingConsent
            emailMarketingConsent
            smsAllowed
            pushAllowed
            emailAllowed
            createdAt
            updatedAt
            intercomProfileUrl
            accountStatus
            type
            passwordResetAt
            passwordResetBy
            customerId
            businessDetail
            shareholders
            SignUpAddressPersonalDetails
            SignUpFinancialDetails
            SignUpIndividualPersonalDetails
            transactionsInfo
            businessType
            author
          }
          readNotifications
          readOnboardings
          amountsSent {
            daily
            monthly
          }
          profileImage
          paymentMethods {
            last4
            scheme
            provider
            customerId
            expiryMonth
            id
            expiryYear
            type
          }
          companyInfo {
            tradingName
            primaryHolderName
            industryType
            registeredAddress
            businessDescription
            annualTurnover
            stablishmentDate
            tradingStartDate
            transactionVolumen
            internationalActivity
            kybCompanyCode
          }
          pushNotificationsConfig {
            token
            endpointArn
          }
          smsMarketingConsent
          pushMarketingConsent
          emailMarketingConsent
          smsAllowed
          pushAllowed
          emailAllowed
          forbiddenCurrency {
            id
            iso
            active
            name
            walletBgColor
            walletTextColor
            order
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          intercomProfileUrl
          accountStatus
          type
          passwordResetAt
          passwordResetBy
          customerId
          businessDetail
          shareholders
          SignUpAddressPersonalDetails
          SignUpFinancialDetails
          SignUpIndividualPersonalDetails
          transactionsInfo
          businessType
          author
        }
        externalUserFrom {
          name
          lastName
          iban
        }
        userTo {
          id
          accountType
          membership {
            id
            name
            price
            dailyLimit
            monthlyLimit
            createdAt
            updatedAt
          }
          name
          lastName
          birthdate
          country
          address
          city
          postalCode
          email
          emailVerified
          phoneCode
          phoneNumber
          phoneNumberVerified
          kyc {
            status
            result
            caseId
            applicantId
            reason
          }
          beneficiaries {
            id
            status
            userId
            accountType
            name
            lastName
            address
            city
            postalCode
            email
            phoneCode
            phoneNumber
            userProfileId
            usageLevel
            skipInternalCheck
            currencyRequirementType
            beneficiaryCountryId
            beneficiaryCurrencyId
          }
          externalBankAccounts {
            name
            number
            iban
            swift
            sort
          }
          poundsAccount {
            id
            name
            label
            type
            currency
            iban
            bban
            balance
            lastTx
          }
          invitedBy {
            id
            accountType
            name
            lastName
            birthdate
            country
            address
            city
            postalCode
            email
            emailVerified
            phoneCode
            phoneNumber
            phoneNumberVerified
            readNotifications
            readOnboardings
            profileImage
            smsMarketingConsent
            pushMarketingConsent
            emailMarketingConsent
            smsAllowed
            pushAllowed
            emailAllowed
            createdAt
            updatedAt
            intercomProfileUrl
            accountStatus
            type
            passwordResetAt
            passwordResetBy
            customerId
            businessDetail
            shareholders
            SignUpAddressPersonalDetails
            SignUpFinancialDetails
            SignUpIndividualPersonalDetails
            transactionsInfo
            businessType
            author
          }
          readNotifications
          readOnboardings
          amountsSent {
            daily
            monthly
          }
          profileImage
          paymentMethods {
            last4
            scheme
            provider
            customerId
            expiryMonth
            id
            expiryYear
            type
          }
          companyInfo {
            tradingName
            primaryHolderName
            industryType
            registeredAddress
            businessDescription
            annualTurnover
            stablishmentDate
            tradingStartDate
            transactionVolumen
            internationalActivity
            kybCompanyCode
          }
          pushNotificationsConfig {
            token
            endpointArn
          }
          smsMarketingConsent
          pushMarketingConsent
          emailMarketingConsent
          smsAllowed
          pushAllowed
          emailAllowed
          forbiddenCurrency {
            id
            iso
            active
            name
            walletBgColor
            walletTextColor
            order
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          intercomProfileUrl
          accountStatus
          type
          passwordResetAt
          passwordResetBy
          customerId
          businessDetail
          shareholders
          SignUpAddressPersonalDetails
          SignUpFinancialDetails
          SignUpIndividualPersonalDetails
          transactionsInfo
          businessType
          author
        }
        beneficiary {
          id
          status
          userId
          accountType
          name
          lastName
          country {
            id
            iso
            iso2
            active
            name
            phoneCode
            order
            companyRequirements
            personalRequirements
            createdAt
            updatedAt
          }
          address
          city
          postalCode
          email
          phoneCode
          phoneNumber
          currency {
            id
            iso
            active
            name
            walletBgColor
            walletTextColor
            order
            createdAt
            updatedAt
          }
          bankDetails {
            name
            value
          }
          userProfileId
          usageLevel
          skipInternalCheck
          currencyRequirementType
          beneficiaryCountryId
          beneficiaryCurrencyId
        }
        fromCurrency
        toCurrency
        sendingAmount
        exchangeRate
        receivingAmount
        fee
        transferFee
        discount {
          id
          code
          type
          amount
          totalUses
          totalUniqueUses
          expire
          discountUses {
            userId
            date
          }
        }
        reference
        purpose
        status
        steps {
          timestamp
          description
          order
          status
        }
        errorCode
        method
        userFromNote
        userFromAttachment
        userToNote
        userToAttachment
        createdAt
        internalId
        failureReason
        adminAttachments
        debtorAccount {
          addressLine1
          addressLine2
          country
          postcode
          name
          ownerName
          iban
          bban
          descriptor
          institutionName
          transactionOwnerName
        }
        creditorAccount {
          addressLine1
          addressLine2
          country
          postcode
          name
          ownerName
          iban
          bban
          descriptor
          institutionName
          transactionOwnerName
        }
        endToEndId
        rateQuoteId
        runningBalanceFrom
        runningBalanceTo
      }
      nextToken
    }
  }
`;
export const getConfig = /* GraphQL */ `
  query GetConfig($id: ID!) {
    getConfig(id: $id) {
      id
      key
      value
    }
  }
`;
export const listConfigs = /* GraphQL */ `
  query ListConfigs(
    $filter: ModelConfigFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listConfigs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        key
        value
      }
      nextToken
    }
  }
`;
export const getDiscount = /* GraphQL */ `
  query GetDiscount($id: ID!) {
    getDiscount(id: $id) {
      id
      code
      type
      amount
      totalUses
      totalUniqueUses
      expire
      discountUses {
        userId
        date
      }
    }
  }
`;
export const listDiscounts = /* GraphQL */ `
  query ListDiscounts(
    $filter: ModelDiscountFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDiscounts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        code
        type
        amount
        totalUses
        totalUniqueUses
        expire
        discountUses {
          userId
          date
        }
      }
      nextToken
    }
  }
`;
export const getOnboarding = /* GraphQL */ `
  query GetOnboarding($id: ID!) {
    getOnboarding(id: $id) {
      id
      showTopSteps
      topText
      showBottomSteps
      bottomText
      type
      showLogo
      ctaText
      slideImage
      order
      createdAt
      updatedAt
    }
  }
`;
export const listOnboardings = /* GraphQL */ `
  query ListOnboardings(
    $filter: ModelOnboardingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOnboardings(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        showTopSteps
        topText
        showBottomSteps
        bottomText
        type
        showLogo
        ctaText
        slideImage
        order
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getNotification = /* GraphQL */ `
  query GetNotification($id: ID!) {
    getNotification(id: $id) {
      id
      type
      title
      body
      url
      user {
        id
        accountType
        membership {
          id
          name
          price
          dailyLimit
          monthlyLimit
          createdAt
          updatedAt
        }
        name
        lastName
        birthdate
        country
        address
        city
        postalCode
        email
        emailVerified
        phoneCode
        phoneNumber
        phoneNumberVerified
        kyc {
          status
          result
          caseId
          applicantId
          reason
        }
        beneficiaries {
          id
          status
          userId
          accountType
          name
          lastName
          country {
            id
            iso
            iso2
            active
            name
            phoneCode
            order
            companyRequirements
            personalRequirements
            createdAt
            updatedAt
          }
          address
          city
          postalCode
          email
          phoneCode
          phoneNumber
          currency {
            id
            iso
            active
            name
            walletBgColor
            walletTextColor
            order
            createdAt
            updatedAt
          }
          bankDetails {
            name
            value
          }
          userProfileId
          usageLevel
          skipInternalCheck
          currencyRequirementType
          beneficiaryCountryId
          beneficiaryCurrencyId
        }
        externalBankAccounts {
          name
          number
          iban
          swift
          sort
        }
        poundsAccount {
          id
          name
          label
          type
          currency
          iban
          bban
          balance
          lastTx
        }
        invitedBy {
          id
          accountType
          membership {
            id
            name
            price
            dailyLimit
            monthlyLimit
            createdAt
            updatedAt
          }
          name
          lastName
          birthdate
          country
          address
          city
          postalCode
          email
          emailVerified
          phoneCode
          phoneNumber
          phoneNumberVerified
          kyc {
            status
            result
            caseId
            applicantId
            reason
          }
          beneficiaries {
            id
            status
            userId
            accountType
            name
            lastName
            address
            city
            postalCode
            email
            phoneCode
            phoneNumber
            userProfileId
            usageLevel
            skipInternalCheck
            currencyRequirementType
            beneficiaryCountryId
            beneficiaryCurrencyId
          }
          externalBankAccounts {
            name
            number
            iban
            swift
            sort
          }
          poundsAccount {
            id
            name
            label
            type
            currency
            iban
            bban
            balance
            lastTx
          }
          invitedBy {
            id
            accountType
            name
            lastName
            birthdate
            country
            address
            city
            postalCode
            email
            emailVerified
            phoneCode
            phoneNumber
            phoneNumberVerified
            readNotifications
            readOnboardings
            profileImage
            smsMarketingConsent
            pushMarketingConsent
            emailMarketingConsent
            smsAllowed
            pushAllowed
            emailAllowed
            createdAt
            updatedAt
            intercomProfileUrl
            accountStatus
            type
            passwordResetAt
            passwordResetBy
            customerId
            businessDetail
            shareholders
            SignUpAddressPersonalDetails
            SignUpFinancialDetails
            SignUpIndividualPersonalDetails
            transactionsInfo
            businessType
            author
          }
          readNotifications
          readOnboardings
          amountsSent {
            daily
            monthly
          }
          profileImage
          paymentMethods {
            last4
            scheme
            provider
            customerId
            expiryMonth
            id
            expiryYear
            type
          }
          companyInfo {
            tradingName
            primaryHolderName
            industryType
            registeredAddress
            businessDescription
            annualTurnover
            stablishmentDate
            tradingStartDate
            transactionVolumen
            internationalActivity
            kybCompanyCode
          }
          pushNotificationsConfig {
            token
            endpointArn
          }
          smsMarketingConsent
          pushMarketingConsent
          emailMarketingConsent
          smsAllowed
          pushAllowed
          emailAllowed
          forbiddenCurrency {
            id
            iso
            active
            name
            walletBgColor
            walletTextColor
            order
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          intercomProfileUrl
          accountStatus
          type
          passwordResetAt
          passwordResetBy
          customerId
          businessDetail
          shareholders
          SignUpAddressPersonalDetails
          SignUpFinancialDetails
          SignUpIndividualPersonalDetails
          transactionsInfo
          businessType
          author
        }
        readNotifications
        readOnboardings
        amountsSent {
          daily
          monthly
        }
        profileImage
        paymentMethods {
          last4
          scheme
          provider
          customerId
          expiryMonth
          id
          expiryYear
          type
        }
        companyInfo {
          tradingName
          primaryHolderName
          industryType
          registeredAddress
          businessDescription
          annualTurnover
          stablishmentDate
          tradingStartDate
          transactionVolumen
          shareholders {
            name
            lastName
            birthdate
            country
            address
            city
            postalCode
            email
            phoneCode
            phoneNumber
          }
          internationalActivity
          kybCompanyCode
        }
        pushNotificationsConfig {
          token
          endpointArn
        }
        smsMarketingConsent
        pushMarketingConsent
        emailMarketingConsent
        smsAllowed
        pushAllowed
        emailAllowed
        forbiddenCurrency {
          id
          iso
          active
          name
          walletBgColor
          walletTextColor
          order
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        intercomProfileUrl
        accountStatus
        type
        passwordResetAt
        passwordResetBy
        customerId
        businessDetail
        shareholders
        SignUpAddressPersonalDetails
        SignUpFinancialDetails
        SignUpIndividualPersonalDetails
        transactionsInfo
        businessType
        author
      }
      notificationUserId
      notifSendApproach
      meta
      createdAt
      updatedAt
    }
  }
`;
export const listNotifications = /* GraphQL */ `
  query ListNotifications(
    $filter: ModelNotificationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listNotifications(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        type
        title
        body
        url
        user {
          id
          accountType
          membership {
            id
            name
            price
            dailyLimit
            monthlyLimit
            createdAt
            updatedAt
          }
          name
          lastName
          birthdate
          country
          address
          city
          postalCode
          email
          emailVerified
          phoneCode
          phoneNumber
          phoneNumberVerified
          kyc {
            status
            result
            caseId
            applicantId
            reason
          }
          beneficiaries {
            id
            status
            userId
            accountType
            name
            lastName
            address
            city
            postalCode
            email
            phoneCode
            phoneNumber
            userProfileId
            usageLevel
            skipInternalCheck
            currencyRequirementType
            beneficiaryCountryId
            beneficiaryCurrencyId
          }
          externalBankAccounts {
            name
            number
            iban
            swift
            sort
          }
          poundsAccount {
            id
            name
            label
            type
            currency
            iban
            bban
            balance
            lastTx
          }
          invitedBy {
            id
            accountType
            name
            lastName
            birthdate
            country
            address
            city
            postalCode
            email
            emailVerified
            phoneCode
            phoneNumber
            phoneNumberVerified
            readNotifications
            readOnboardings
            profileImage
            smsMarketingConsent
            pushMarketingConsent
            emailMarketingConsent
            smsAllowed
            pushAllowed
            emailAllowed
            createdAt
            updatedAt
            intercomProfileUrl
            accountStatus
            type
            passwordResetAt
            passwordResetBy
            customerId
            businessDetail
            shareholders
            SignUpAddressPersonalDetails
            SignUpFinancialDetails
            SignUpIndividualPersonalDetails
            transactionsInfo
            businessType
            author
          }
          readNotifications
          readOnboardings
          amountsSent {
            daily
            monthly
          }
          profileImage
          paymentMethods {
            last4
            scheme
            provider
            customerId
            expiryMonth
            id
            expiryYear
            type
          }
          companyInfo {
            tradingName
            primaryHolderName
            industryType
            registeredAddress
            businessDescription
            annualTurnover
            stablishmentDate
            tradingStartDate
            transactionVolumen
            internationalActivity
            kybCompanyCode
          }
          pushNotificationsConfig {
            token
            endpointArn
          }
          smsMarketingConsent
          pushMarketingConsent
          emailMarketingConsent
          smsAllowed
          pushAllowed
          emailAllowed
          forbiddenCurrency {
            id
            iso
            active
            name
            walletBgColor
            walletTextColor
            order
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          intercomProfileUrl
          accountStatus
          type
          passwordResetAt
          passwordResetBy
          customerId
          businessDetail
          shareholders
          SignUpAddressPersonalDetails
          SignUpFinancialDetails
          SignUpIndividualPersonalDetails
          transactionsInfo
          businessType
          author
        }
        notificationUserId
        notifSendApproach
        meta
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getMembership = /* GraphQL */ `
  query GetMembership($id: ID!) {
    getMembership(id: $id) {
      id
      name
      price
      dailyLimit
      monthlyLimit
      createdAt
      updatedAt
    }
  }
`;
export const listMemberships = /* GraphQL */ `
  query ListMemberships(
    $filter: ModelMembershipFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMemberships(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        price
        dailyLimit
        monthlyLimit
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getRegion = /* GraphQL */ `
  query GetRegion($id: ID!) {
    getRegion(id: $id) {
      id
      name
      createdAt
      updatedAt
    }
  }
`;
export const listRegions = /* GraphQL */ `
  query ListRegions(
    $filter: ModelRegionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRegions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getUserNote = /* GraphQL */ `
  query GetUserNote($id: ID!) {
    getUserNote(id: $id) {
      id
      userId
      adminId
      timestamp
      adminName
      note
      createdAt
      updatedAt
    }
  }
`;
export const listUserNotes = /* GraphQL */ `
  query ListUserNotes(
    $filter: ModelUserNoteFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserNotes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userId
        adminId
        timestamp
        adminName
        note
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listUserLogs = /* GraphQL */ `
  query ListUserLogs(
    $filter: ModelUserLogFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserLogs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        timestamp
        platform
        location
        ip
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listBalanceLogs = /* GraphQL */ `
  query ListBalanceLogs(
    $userId: String
    $transactionId: String
    $limit: Int
    $nextToken: String
  ) {
    listBalanceLogs(
      userId: $userId
      transactionId: $transactionId
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        range
        amount
        createdTimestamp
        currency
        endToEndId
        source
        transactionId
      }
      nextToken
    }
  }
`;
export const listUserBalances = /* GraphQL */ `
  query ListUserBalances(
    $userId: String
    $currency: String
    $limit: Int
    $nextToken: String
  ) {
    listUserBalances(
      userId: $userId
      currency: $currency
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        balance
        createdTimestamp
        currency
      }
      nextToken
    }
  }
`;
export const getCurrencyIBAN = /* GraphQL */ `
  query GetCurrencyIBAN($id: ID!, $multiCurrency: Boolean) {
    getCurrencyIBAN(id: $id, multiCurrency: $multiCurrency) {
      id
      AccountId
      BatchId
      iban
      Identifiers {
        Identifier
        Kind
      }
      owner
      status
      timestamp
      TimestampCreated
      VirtualAccountId
      balance
      currency
      name
      type
      history {
        date
        amount
      }
    }
  }
`;
export const getUserWallets = /* GraphQL */ `
  query GetUserWallets($id: ID!) {
    getUserWallets(id: $id) {
      id
      currency
      balance
      history
      routingNumber
      accountNumber
      bankAddress
      bankName
      status
      type
      iban
    }
  }
`;
export const getUserTransactions = /* GraphQL */ `
  query GetUserTransactions($id: ID!) {
    getUserTransactions(id: $id) {
      id
      type
      userIdFrom
      userIdTo
      userFrom {
        id
        accountType
        membership {
          id
          name
          price
          dailyLimit
          monthlyLimit
          createdAt
          updatedAt
        }
        name
        lastName
        birthdate
        country
        address
        city
        postalCode
        email
        emailVerified
        phoneCode
        phoneNumber
        phoneNumberVerified
        kyc {
          status
          result
          caseId
          applicantId
          reason
        }
        beneficiaries {
          id
          status
          userId
          accountType
          name
          lastName
          country {
            id
            iso
            iso2
            active
            name
            phoneCode
            order
            companyRequirements
            personalRequirements
            createdAt
            updatedAt
          }
          address
          city
          postalCode
          email
          phoneCode
          phoneNumber
          currency {
            id
            iso
            active
            name
            walletBgColor
            walletTextColor
            order
            createdAt
            updatedAt
          }
          bankDetails {
            name
            value
          }
          userProfileId
          usageLevel
          skipInternalCheck
          currencyRequirementType
          beneficiaryCountryId
          beneficiaryCurrencyId
        }
        externalBankAccounts {
          name
          number
          iban
          swift
          sort
        }
        poundsAccount {
          id
          name
          label
          type
          currency
          iban
          bban
          balance
          lastTx
        }
        invitedBy {
          id
          accountType
          membership {
            id
            name
            price
            dailyLimit
            monthlyLimit
            createdAt
            updatedAt
          }
          name
          lastName
          birthdate
          country
          address
          city
          postalCode
          email
          emailVerified
          phoneCode
          phoneNumber
          phoneNumberVerified
          kyc {
            status
            result
            caseId
            applicantId
            reason
          }
          beneficiaries {
            id
            status
            userId
            accountType
            name
            lastName
            address
            city
            postalCode
            email
            phoneCode
            phoneNumber
            userProfileId
            usageLevel
            skipInternalCheck
            currencyRequirementType
            beneficiaryCountryId
            beneficiaryCurrencyId
          }
          externalBankAccounts {
            name
            number
            iban
            swift
            sort
          }
          poundsAccount {
            id
            name
            label
            type
            currency
            iban
            bban
            balance
            lastTx
          }
          invitedBy {
            id
            accountType
            name
            lastName
            birthdate
            country
            address
            city
            postalCode
            email
            emailVerified
            phoneCode
            phoneNumber
            phoneNumberVerified
            readNotifications
            readOnboardings
            profileImage
            smsMarketingConsent
            pushMarketingConsent
            emailMarketingConsent
            smsAllowed
            pushAllowed
            emailAllowed
            createdAt
            updatedAt
            intercomProfileUrl
            accountStatus
            type
            passwordResetAt
            passwordResetBy
            customerId
            businessDetail
            shareholders
            SignUpAddressPersonalDetails
            SignUpFinancialDetails
            SignUpIndividualPersonalDetails
            transactionsInfo
            businessType
            author
          }
          readNotifications
          readOnboardings
          amountsSent {
            daily
            monthly
          }
          profileImage
          paymentMethods {
            last4
            scheme
            provider
            customerId
            expiryMonth
            id
            expiryYear
            type
          }
          companyInfo {
            tradingName
            primaryHolderName
            industryType
            registeredAddress
            businessDescription
            annualTurnover
            stablishmentDate
            tradingStartDate
            transactionVolumen
            internationalActivity
            kybCompanyCode
          }
          pushNotificationsConfig {
            token
            endpointArn
          }
          smsMarketingConsent
          pushMarketingConsent
          emailMarketingConsent
          smsAllowed
          pushAllowed
          emailAllowed
          forbiddenCurrency {
            id
            iso
            active
            name
            walletBgColor
            walletTextColor
            order
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          intercomProfileUrl
          accountStatus
          type
          passwordResetAt
          passwordResetBy
          customerId
          businessDetail
          shareholders
          SignUpAddressPersonalDetails
          SignUpFinancialDetails
          SignUpIndividualPersonalDetails
          transactionsInfo
          businessType
          author
        }
        readNotifications
        readOnboardings
        amountsSent {
          daily
          monthly
        }
        profileImage
        paymentMethods {
          last4
          scheme
          provider
          customerId
          expiryMonth
          id
          expiryYear
          type
        }
        companyInfo {
          tradingName
          primaryHolderName
          industryType
          registeredAddress
          businessDescription
          annualTurnover
          stablishmentDate
          tradingStartDate
          transactionVolumen
          shareholders {
            name
            lastName
            birthdate
            country
            address
            city
            postalCode
            email
            phoneCode
            phoneNumber
          }
          internationalActivity
          kybCompanyCode
        }
        pushNotificationsConfig {
          token
          endpointArn
        }
        smsMarketingConsent
        pushMarketingConsent
        emailMarketingConsent
        smsAllowed
        pushAllowed
        emailAllowed
        forbiddenCurrency {
          id
          iso
          active
          name
          walletBgColor
          walletTextColor
          order
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        intercomProfileUrl
        accountStatus
        type
        passwordResetAt
        passwordResetBy
        customerId
        businessDetail
        shareholders
        SignUpAddressPersonalDetails
        SignUpFinancialDetails
        SignUpIndividualPersonalDetails
        transactionsInfo
        businessType
        author
      }
      externalUserFrom {
        name
        lastName
        iban
      }
      userTo {
        id
        accountType
        membership {
          id
          name
          price
          dailyLimit
          monthlyLimit
          createdAt
          updatedAt
        }
        name
        lastName
        birthdate
        country
        address
        city
        postalCode
        email
        emailVerified
        phoneCode
        phoneNumber
        phoneNumberVerified
        kyc {
          status
          result
          caseId
          applicantId
          reason
        }
        beneficiaries {
          id
          status
          userId
          accountType
          name
          lastName
          country {
            id
            iso
            iso2
            active
            name
            phoneCode
            order
            companyRequirements
            personalRequirements
            createdAt
            updatedAt
          }
          address
          city
          postalCode
          email
          phoneCode
          phoneNumber
          currency {
            id
            iso
            active
            name
            walletBgColor
            walletTextColor
            order
            createdAt
            updatedAt
          }
          bankDetails {
            name
            value
          }
          userProfileId
          usageLevel
          skipInternalCheck
          currencyRequirementType
          beneficiaryCountryId
          beneficiaryCurrencyId
        }
        externalBankAccounts {
          name
          number
          iban
          swift
          sort
        }
        poundsAccount {
          id
          name
          label
          type
          currency
          iban
          bban
          balance
          lastTx
        }
        invitedBy {
          id
          accountType
          membership {
            id
            name
            price
            dailyLimit
            monthlyLimit
            createdAt
            updatedAt
          }
          name
          lastName
          birthdate
          country
          address
          city
          postalCode
          email
          emailVerified
          phoneCode
          phoneNumber
          phoneNumberVerified
          kyc {
            status
            result
            caseId
            applicantId
            reason
          }
          beneficiaries {
            id
            status
            userId
            accountType
            name
            lastName
            address
            city
            postalCode
            email
            phoneCode
            phoneNumber
            userProfileId
            usageLevel
            skipInternalCheck
            currencyRequirementType
            beneficiaryCountryId
            beneficiaryCurrencyId
          }
          externalBankAccounts {
            name
            number
            iban
            swift
            sort
          }
          poundsAccount {
            id
            name
            label
            type
            currency
            iban
            bban
            balance
            lastTx
          }
          invitedBy {
            id
            accountType
            name
            lastName
            birthdate
            country
            address
            city
            postalCode
            email
            emailVerified
            phoneCode
            phoneNumber
            phoneNumberVerified
            readNotifications
            readOnboardings
            profileImage
            smsMarketingConsent
            pushMarketingConsent
            emailMarketingConsent
            smsAllowed
            pushAllowed
            emailAllowed
            createdAt
            updatedAt
            intercomProfileUrl
            accountStatus
            type
            passwordResetAt
            passwordResetBy
            customerId
            businessDetail
            shareholders
            SignUpAddressPersonalDetails
            SignUpFinancialDetails
            SignUpIndividualPersonalDetails
            transactionsInfo
            businessType
            author
          }
          readNotifications
          readOnboardings
          amountsSent {
            daily
            monthly
          }
          profileImage
          paymentMethods {
            last4
            scheme
            provider
            customerId
            expiryMonth
            id
            expiryYear
            type
          }
          companyInfo {
            tradingName
            primaryHolderName
            industryType
            registeredAddress
            businessDescription
            annualTurnover
            stablishmentDate
            tradingStartDate
            transactionVolumen
            internationalActivity
            kybCompanyCode
          }
          pushNotificationsConfig {
            token
            endpointArn
          }
          smsMarketingConsent
          pushMarketingConsent
          emailMarketingConsent
          smsAllowed
          pushAllowed
          emailAllowed
          forbiddenCurrency {
            id
            iso
            active
            name
            walletBgColor
            walletTextColor
            order
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          intercomProfileUrl
          accountStatus
          type
          passwordResetAt
          passwordResetBy
          customerId
          businessDetail
          shareholders
          SignUpAddressPersonalDetails
          SignUpFinancialDetails
          SignUpIndividualPersonalDetails
          transactionsInfo
          businessType
          author
        }
        readNotifications
        readOnboardings
        amountsSent {
          daily
          monthly
        }
        profileImage
        paymentMethods {
          last4
          scheme
          provider
          customerId
          expiryMonth
          id
          expiryYear
          type
        }
        companyInfo {
          tradingName
          primaryHolderName
          industryType
          registeredAddress
          businessDescription
          annualTurnover
          stablishmentDate
          tradingStartDate
          transactionVolumen
          shareholders {
            name
            lastName
            birthdate
            country
            address
            city
            postalCode
            email
            phoneCode
            phoneNumber
          }
          internationalActivity
          kybCompanyCode
        }
        pushNotificationsConfig {
          token
          endpointArn
        }
        smsMarketingConsent
        pushMarketingConsent
        emailMarketingConsent
        smsAllowed
        pushAllowed
        emailAllowed
        forbiddenCurrency {
          id
          iso
          active
          name
          walletBgColor
          walletTextColor
          order
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        intercomProfileUrl
        accountStatus
        type
        passwordResetAt
        passwordResetBy
        customerId
        businessDetail
        shareholders
        SignUpAddressPersonalDetails
        SignUpFinancialDetails
        SignUpIndividualPersonalDetails
        transactionsInfo
        businessType
        author
      }
      beneficiary {
        id
        status
        userId
        accountType
        name
        lastName
        country {
          id
          iso
          iso2
          region {
            id
            name
            createdAt
            updatedAt
          }
          defaultCurrency {
            id
            iso
            active
            name
            walletBgColor
            walletTextColor
            order
            createdAt
            updatedAt
          }
          currency {
            id
            iso
            active
            name
            walletBgColor
            walletTextColor
            order
            createdAt
            updatedAt
          }
          active
          name
          phoneCode
          order
          companyRequirements
          personalRequirements
          createdAt
          updatedAt
        }
        address
        city
        postalCode
        email
        phoneCode
        phoneNumber
        currency {
          id
          iso
          active
          name
          walletBgColor
          walletTextColor
          order
          createdAt
          updatedAt
        }
        bankDetails {
          name
          value
        }
        userProfileId
        usageLevel
        skipInternalCheck
        currencyRequirementType
        beneficiaryCountryId
        beneficiaryCurrencyId
      }
      fromCurrency
      toCurrency
      sendingAmount
      exchangeRate
      receivingAmount
      fee
      transferFee
      discount {
        id
        code
        type
        amount
        totalUses
        totalUniqueUses
        expire
        discountUses {
          userId
          date
        }
      }
      reference
      purpose
      status
      steps {
        timestamp
        description
        order
        status
      }
      errorCode
      method
      userFromNote
      userFromAttachment
      userToNote
      userToAttachment
      createdAt
      internalId
      failureReason
      adminAttachments
      debtorAccount {
        addressLine1
        addressLine2
        country
        postcode
        name
        ownerName
        iban
        bban
        descriptor
        institutionName
        transactionOwnerName
      }
      creditorAccount {
        addressLine1
        addressLine2
        country
        postcode
        name
        ownerName
        iban
        bban
        descriptor
        institutionName
        transactionOwnerName
      }
      endToEndId
      rateQuoteId
      runningBalanceFrom
      runningBalanceTo
    }
  }
`;
export const getUserNotificationsBo = /* GraphQL */ `
  query GetUserNotificationsBo($id: ID!) {
    getUserNotificationsBo(id: $id) {
      id
      type
      title
      body
      url
      user {
        id
        accountType
        membership {
          id
          name
          price
          dailyLimit
          monthlyLimit
          createdAt
          updatedAt
        }
        name
        lastName
        birthdate
        country
        address
        city
        postalCode
        email
        emailVerified
        phoneCode
        phoneNumber
        phoneNumberVerified
        kyc {
          status
          result
          caseId
          applicantId
          reason
        }
        beneficiaries {
          id
          status
          userId
          accountType
          name
          lastName
          country {
            id
            iso
            iso2
            active
            name
            phoneCode
            order
            companyRequirements
            personalRequirements
            createdAt
            updatedAt
          }
          address
          city
          postalCode
          email
          phoneCode
          phoneNumber
          currency {
            id
            iso
            active
            name
            walletBgColor
            walletTextColor
            order
            createdAt
            updatedAt
          }
          bankDetails {
            name
            value
          }
          userProfileId
          usageLevel
          skipInternalCheck
          currencyRequirementType
          beneficiaryCountryId
          beneficiaryCurrencyId
        }
        externalBankAccounts {
          name
          number
          iban
          swift
          sort
        }
        poundsAccount {
          id
          name
          label
          type
          currency
          iban
          bban
          balance
          lastTx
        }
        invitedBy {
          id
          accountType
          membership {
            id
            name
            price
            dailyLimit
            monthlyLimit
            createdAt
            updatedAt
          }
          name
          lastName
          birthdate
          country
          address
          city
          postalCode
          email
          emailVerified
          phoneCode
          phoneNumber
          phoneNumberVerified
          kyc {
            status
            result
            caseId
            applicantId
            reason
          }
          beneficiaries {
            id
            status
            userId
            accountType
            name
            lastName
            address
            city
            postalCode
            email
            phoneCode
            phoneNumber
            userProfileId
            usageLevel
            skipInternalCheck
            currencyRequirementType
            beneficiaryCountryId
            beneficiaryCurrencyId
          }
          externalBankAccounts {
            name
            number
            iban
            swift
            sort
          }
          poundsAccount {
            id
            name
            label
            type
            currency
            iban
            bban
            balance
            lastTx
          }
          invitedBy {
            id
            accountType
            name
            lastName
            birthdate
            country
            address
            city
            postalCode
            email
            emailVerified
            phoneCode
            phoneNumber
            phoneNumberVerified
            readNotifications
            readOnboardings
            profileImage
            smsMarketingConsent
            pushMarketingConsent
            emailMarketingConsent
            smsAllowed
            pushAllowed
            emailAllowed
            createdAt
            updatedAt
            intercomProfileUrl
            accountStatus
            type
            passwordResetAt
            passwordResetBy
            customerId
            businessDetail
            shareholders
            SignUpAddressPersonalDetails
            SignUpFinancialDetails
            SignUpIndividualPersonalDetails
            transactionsInfo
            businessType
            author
          }
          readNotifications
          readOnboardings
          amountsSent {
            daily
            monthly
          }
          profileImage
          paymentMethods {
            last4
            scheme
            provider
            customerId
            expiryMonth
            id
            expiryYear
            type
          }
          companyInfo {
            tradingName
            primaryHolderName
            industryType
            registeredAddress
            businessDescription
            annualTurnover
            stablishmentDate
            tradingStartDate
            transactionVolumen
            internationalActivity
            kybCompanyCode
          }
          pushNotificationsConfig {
            token
            endpointArn
          }
          smsMarketingConsent
          pushMarketingConsent
          emailMarketingConsent
          smsAllowed
          pushAllowed
          emailAllowed
          forbiddenCurrency {
            id
            iso
            active
            name
            walletBgColor
            walletTextColor
            order
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          intercomProfileUrl
          accountStatus
          type
          passwordResetAt
          passwordResetBy
          customerId
          businessDetail
          shareholders
          SignUpAddressPersonalDetails
          SignUpFinancialDetails
          SignUpIndividualPersonalDetails
          transactionsInfo
          businessType
          author
        }
        readNotifications
        readOnboardings
        amountsSent {
          daily
          monthly
        }
        profileImage
        paymentMethods {
          last4
          scheme
          provider
          customerId
          expiryMonth
          id
          expiryYear
          type
        }
        companyInfo {
          tradingName
          primaryHolderName
          industryType
          registeredAddress
          businessDescription
          annualTurnover
          stablishmentDate
          tradingStartDate
          transactionVolumen
          shareholders {
            name
            lastName
            birthdate
            country
            address
            city
            postalCode
            email
            phoneCode
            phoneNumber
          }
          internationalActivity
          kybCompanyCode
        }
        pushNotificationsConfig {
          token
          endpointArn
        }
        smsMarketingConsent
        pushMarketingConsent
        emailMarketingConsent
        smsAllowed
        pushAllowed
        emailAllowed
        forbiddenCurrency {
          id
          iso
          active
          name
          walletBgColor
          walletTextColor
          order
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        intercomProfileUrl
        accountStatus
        type
        passwordResetAt
        passwordResetBy
        customerId
        businessDetail
        shareholders
        SignUpAddressPersonalDetails
        SignUpFinancialDetails
        SignUpIndividualPersonalDetails
        transactionsInfo
        businessType
        author
      }
      notificationUserId
      notifSendApproach
      meta
      createdAt
      updatedAt
    }
  }
`;
export const getNotificationById = /* GraphQL */ `
  query GetNotificationById($id: ID!) {
    getNotificationById(id: $id) {
      id
      type
      title
      body
      url
      user {
        id
        accountType
        membership {
          id
          name
          price
          dailyLimit
          monthlyLimit
          createdAt
          updatedAt
        }
        name
        lastName
        birthdate
        country
        address
        city
        postalCode
        email
        emailVerified
        phoneCode
        phoneNumber
        phoneNumberVerified
        kyc {
          status
          result
          caseId
          applicantId
          reason
        }
        beneficiaries {
          id
          status
          userId
          accountType
          name
          lastName
          country {
            id
            iso
            iso2
            active
            name
            phoneCode
            order
            companyRequirements
            personalRequirements
            createdAt
            updatedAt
          }
          address
          city
          postalCode
          email
          phoneCode
          phoneNumber
          currency {
            id
            iso
            active
            name
            walletBgColor
            walletTextColor
            order
            createdAt
            updatedAt
          }
          bankDetails {
            name
            value
          }
          userProfileId
          usageLevel
          skipInternalCheck
          currencyRequirementType
          beneficiaryCountryId
          beneficiaryCurrencyId
        }
        externalBankAccounts {
          name
          number
          iban
          swift
          sort
        }
        poundsAccount {
          id
          name
          label
          type
          currency
          iban
          bban
          balance
          lastTx
        }
        invitedBy {
          id
          accountType
          membership {
            id
            name
            price
            dailyLimit
            monthlyLimit
            createdAt
            updatedAt
          }
          name
          lastName
          birthdate
          country
          address
          city
          postalCode
          email
          emailVerified
          phoneCode
          phoneNumber
          phoneNumberVerified
          kyc {
            status
            result
            caseId
            applicantId
            reason
          }
          beneficiaries {
            id
            status
            userId
            accountType
            name
            lastName
            address
            city
            postalCode
            email
            phoneCode
            phoneNumber
            userProfileId
            usageLevel
            skipInternalCheck
            currencyRequirementType
            beneficiaryCountryId
            beneficiaryCurrencyId
          }
          externalBankAccounts {
            name
            number
            iban
            swift
            sort
          }
          poundsAccount {
            id
            name
            label
            type
            currency
            iban
            bban
            balance
            lastTx
          }
          invitedBy {
            id
            accountType
            name
            lastName
            birthdate
            country
            address
            city
            postalCode
            email
            emailVerified
            phoneCode
            phoneNumber
            phoneNumberVerified
            readNotifications
            readOnboardings
            profileImage
            smsMarketingConsent
            pushMarketingConsent
            emailMarketingConsent
            smsAllowed
            pushAllowed
            emailAllowed
            createdAt
            updatedAt
            intercomProfileUrl
            accountStatus
            type
            passwordResetAt
            passwordResetBy
            customerId
            businessDetail
            shareholders
            SignUpAddressPersonalDetails
            SignUpFinancialDetails
            SignUpIndividualPersonalDetails
            transactionsInfo
            businessType
            author
          }
          readNotifications
          readOnboardings
          amountsSent {
            daily
            monthly
          }
          profileImage
          paymentMethods {
            last4
            scheme
            provider
            customerId
            expiryMonth
            id
            expiryYear
            type
          }
          companyInfo {
            tradingName
            primaryHolderName
            industryType
            registeredAddress
            businessDescription
            annualTurnover
            stablishmentDate
            tradingStartDate
            transactionVolumen
            internationalActivity
            kybCompanyCode
          }
          pushNotificationsConfig {
            token
            endpointArn
          }
          smsMarketingConsent
          pushMarketingConsent
          emailMarketingConsent
          smsAllowed
          pushAllowed
          emailAllowed
          forbiddenCurrency {
            id
            iso
            active
            name
            walletBgColor
            walletTextColor
            order
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          intercomProfileUrl
          accountStatus
          type
          passwordResetAt
          passwordResetBy
          customerId
          businessDetail
          shareholders
          SignUpAddressPersonalDetails
          SignUpFinancialDetails
          SignUpIndividualPersonalDetails
          transactionsInfo
          businessType
          author
        }
        readNotifications
        readOnboardings
        amountsSent {
          daily
          monthly
        }
        profileImage
        paymentMethods {
          last4
          scheme
          provider
          customerId
          expiryMonth
          id
          expiryYear
          type
        }
        companyInfo {
          tradingName
          primaryHolderName
          industryType
          registeredAddress
          businessDescription
          annualTurnover
          stablishmentDate
          tradingStartDate
          transactionVolumen
          shareholders {
            name
            lastName
            birthdate
            country
            address
            city
            postalCode
            email
            phoneCode
            phoneNumber
          }
          internationalActivity
          kybCompanyCode
        }
        pushNotificationsConfig {
          token
          endpointArn
        }
        smsMarketingConsent
        pushMarketingConsent
        emailMarketingConsent
        smsAllowed
        pushAllowed
        emailAllowed
        forbiddenCurrency {
          id
          iso
          active
          name
          walletBgColor
          walletTextColor
          order
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        intercomProfileUrl
        accountStatus
        type
        passwordResetAt
        passwordResetBy
        customerId
        businessDetail
        shareholders
        SignUpAddressPersonalDetails
        SignUpFinancialDetails
        SignUpIndividualPersonalDetails
        transactionsInfo
        businessType
        author
      }
      notificationUserId
      notifSendApproach
      meta
      createdAt
      updatedAt
    }
  }
`;
export const getUserSMSNotificationsBo = /* GraphQL */ `
  query GetUserSMSNotificationsBo($id: ID!) {
    getUserSMSNotificationsBo(id: $id) {
      id
      text
      user {
        id
        accountType
        membership {
          id
          name
          price
          dailyLimit
          monthlyLimit
          createdAt
          updatedAt
        }
        name
        lastName
        birthdate
        country
        address
        city
        postalCode
        email
        emailVerified
        phoneCode
        phoneNumber
        phoneNumberVerified
        kyc {
          status
          result
          caseId
          applicantId
          reason
        }
        beneficiaries {
          id
          status
          userId
          accountType
          name
          lastName
          country {
            id
            iso
            iso2
            active
            name
            phoneCode
            order
            companyRequirements
            personalRequirements
            createdAt
            updatedAt
          }
          address
          city
          postalCode
          email
          phoneCode
          phoneNumber
          currency {
            id
            iso
            active
            name
            walletBgColor
            walletTextColor
            order
            createdAt
            updatedAt
          }
          bankDetails {
            name
            value
          }
          userProfileId
          usageLevel
          skipInternalCheck
          currencyRequirementType
          beneficiaryCountryId
          beneficiaryCurrencyId
        }
        externalBankAccounts {
          name
          number
          iban
          swift
          sort
        }
        poundsAccount {
          id
          name
          label
          type
          currency
          iban
          bban
          balance
          lastTx
        }
        invitedBy {
          id
          accountType
          membership {
            id
            name
            price
            dailyLimit
            monthlyLimit
            createdAt
            updatedAt
          }
          name
          lastName
          birthdate
          country
          address
          city
          postalCode
          email
          emailVerified
          phoneCode
          phoneNumber
          phoneNumberVerified
          kyc {
            status
            result
            caseId
            applicantId
            reason
          }
          beneficiaries {
            id
            status
            userId
            accountType
            name
            lastName
            address
            city
            postalCode
            email
            phoneCode
            phoneNumber
            userProfileId
            usageLevel
            skipInternalCheck
            currencyRequirementType
            beneficiaryCountryId
            beneficiaryCurrencyId
          }
          externalBankAccounts {
            name
            number
            iban
            swift
            sort
          }
          poundsAccount {
            id
            name
            label
            type
            currency
            iban
            bban
            balance
            lastTx
          }
          invitedBy {
            id
            accountType
            name
            lastName
            birthdate
            country
            address
            city
            postalCode
            email
            emailVerified
            phoneCode
            phoneNumber
            phoneNumberVerified
            readNotifications
            readOnboardings
            profileImage
            smsMarketingConsent
            pushMarketingConsent
            emailMarketingConsent
            smsAllowed
            pushAllowed
            emailAllowed
            createdAt
            updatedAt
            intercomProfileUrl
            accountStatus
            type
            passwordResetAt
            passwordResetBy
            customerId
            businessDetail
            shareholders
            SignUpAddressPersonalDetails
            SignUpFinancialDetails
            SignUpIndividualPersonalDetails
            transactionsInfo
            businessType
            author
          }
          readNotifications
          readOnboardings
          amountsSent {
            daily
            monthly
          }
          profileImage
          paymentMethods {
            last4
            scheme
            provider
            customerId
            expiryMonth
            id
            expiryYear
            type
          }
          companyInfo {
            tradingName
            primaryHolderName
            industryType
            registeredAddress
            businessDescription
            annualTurnover
            stablishmentDate
            tradingStartDate
            transactionVolumen
            internationalActivity
            kybCompanyCode
          }
          pushNotificationsConfig {
            token
            endpointArn
          }
          smsMarketingConsent
          pushMarketingConsent
          emailMarketingConsent
          smsAllowed
          pushAllowed
          emailAllowed
          forbiddenCurrency {
            id
            iso
            active
            name
            walletBgColor
            walletTextColor
            order
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          intercomProfileUrl
          accountStatus
          type
          passwordResetAt
          passwordResetBy
          customerId
          businessDetail
          shareholders
          SignUpAddressPersonalDetails
          SignUpFinancialDetails
          SignUpIndividualPersonalDetails
          transactionsInfo
          businessType
          author
        }
        readNotifications
        readOnboardings
        amountsSent {
          daily
          monthly
        }
        profileImage
        paymentMethods {
          last4
          scheme
          provider
          customerId
          expiryMonth
          id
          expiryYear
          type
        }
        companyInfo {
          tradingName
          primaryHolderName
          industryType
          registeredAddress
          businessDescription
          annualTurnover
          stablishmentDate
          tradingStartDate
          transactionVolumen
          shareholders {
            name
            lastName
            birthdate
            country
            address
            city
            postalCode
            email
            phoneCode
            phoneNumber
          }
          internationalActivity
          kybCompanyCode
        }
        pushNotificationsConfig {
          token
          endpointArn
        }
        smsMarketingConsent
        pushMarketingConsent
        emailMarketingConsent
        smsAllowed
        pushAllowed
        emailAllowed
        forbiddenCurrency {
          id
          iso
          active
          name
          walletBgColor
          walletTextColor
          order
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        intercomProfileUrl
        accountStatus
        type
        passwordResetAt
        passwordResetBy
        customerId
        businessDetail
        shareholders
        SignUpAddressPersonalDetails
        SignUpFinancialDetails
        SignUpIndividualPersonalDetails
        transactionsInfo
        businessType
        author
      }
      adminId
      adminName
      createAt
      success
      error
    }
  }
`;
export const listEmailTemplates = /* GraphQL */ `
  query ListEmailTemplates {
    listEmailTemplates {
      TemplateName
      HtmlPart
      SubjectPart
      TextPart
    }
  }
`;
export const newSearchTransactions = /* GraphQL */ `
  query NewSearchTransactions(
    $key: NewTransactionKey
    $value: String
    $sort: TransactionSort
    $filter: NewTransactionFilterInput
    $limit: Int
    $offset: Int
  ) {
    newSearchTransactions(
      key: $key
      value: $value
      sort: $sort
      filter: $filter
      limit: $limit
      offset: $offset
    ) {
      items {
        id
        type
        userIdFrom
        userIdTo
        userFrom {
          id
          accountType
          membership {
            id
            name
            price
            dailyLimit
            monthlyLimit
            createdAt
            updatedAt
          }
          name
          lastName
          birthdate
          country
          address
          city
          postalCode
          email
          emailVerified
          phoneCode
          phoneNumber
          phoneNumberVerified
          kyc {
            status
            result
            caseId
            applicantId
            reason
          }
          beneficiaries {
            id
            status
            userId
            accountType
            name
            lastName
            address
            city
            postalCode
            email
            phoneCode
            phoneNumber
            userProfileId
            usageLevel
            skipInternalCheck
            currencyRequirementType
            beneficiaryCountryId
            beneficiaryCurrencyId
          }
          externalBankAccounts {
            name
            number
            iban
            swift
            sort
          }
          poundsAccount {
            id
            name
            label
            type
            currency
            iban
            bban
            balance
            lastTx
          }
          invitedBy {
            id
            accountType
            name
            lastName
            birthdate
            country
            address
            city
            postalCode
            email
            emailVerified
            phoneCode
            phoneNumber
            phoneNumberVerified
            readNotifications
            readOnboardings
            profileImage
            smsMarketingConsent
            pushMarketingConsent
            emailMarketingConsent
            smsAllowed
            pushAllowed
            emailAllowed
            createdAt
            updatedAt
            intercomProfileUrl
            accountStatus
            type
            passwordResetAt
            passwordResetBy
            customerId
            businessDetail
            shareholders
            SignUpAddressPersonalDetails
            SignUpFinancialDetails
            SignUpIndividualPersonalDetails
            transactionsInfo
            businessType
            author
          }
          readNotifications
          readOnboardings
          amountsSent {
            daily
            monthly
          }
          profileImage
          paymentMethods {
            last4
            scheme
            provider
            customerId
            expiryMonth
            id
            expiryYear
            type
          }
          companyInfo {
            tradingName
            primaryHolderName
            industryType
            registeredAddress
            businessDescription
            annualTurnover
            stablishmentDate
            tradingStartDate
            transactionVolumen
            internationalActivity
            kybCompanyCode
          }
          pushNotificationsConfig {
            token
            endpointArn
          }
          smsMarketingConsent
          pushMarketingConsent
          emailMarketingConsent
          smsAllowed
          pushAllowed
          emailAllowed
          forbiddenCurrency {
            id
            iso
            active
            name
            walletBgColor
            walletTextColor
            order
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          intercomProfileUrl
          accountStatus
          type
          passwordResetAt
          passwordResetBy
          customerId
          businessDetail
          shareholders
          SignUpAddressPersonalDetails
          SignUpFinancialDetails
          SignUpIndividualPersonalDetails
          transactionsInfo
          businessType
          author
        }
        externalUserFrom {
          name
          lastName
          iban
        }
        userTo {
          id
          accountType
          membership {
            id
            name
            price
            dailyLimit
            monthlyLimit
            createdAt
            updatedAt
          }
          name
          lastName
          birthdate
          country
          address
          city
          postalCode
          email
          emailVerified
          phoneCode
          phoneNumber
          phoneNumberVerified
          kyc {
            status
            result
            caseId
            applicantId
            reason
          }
          beneficiaries {
            id
            status
            userId
            accountType
            name
            lastName
            address
            city
            postalCode
            email
            phoneCode
            phoneNumber
            userProfileId
            usageLevel
            skipInternalCheck
            currencyRequirementType
            beneficiaryCountryId
            beneficiaryCurrencyId
          }
          externalBankAccounts {
            name
            number
            iban
            swift
            sort
          }
          poundsAccount {
            id
            name
            label
            type
            currency
            iban
            bban
            balance
            lastTx
          }
          invitedBy {
            id
            accountType
            name
            lastName
            birthdate
            country
            address
            city
            postalCode
            email
            emailVerified
            phoneCode
            phoneNumber
            phoneNumberVerified
            readNotifications
            readOnboardings
            profileImage
            smsMarketingConsent
            pushMarketingConsent
            emailMarketingConsent
            smsAllowed
            pushAllowed
            emailAllowed
            createdAt
            updatedAt
            intercomProfileUrl
            accountStatus
            type
            passwordResetAt
            passwordResetBy
            customerId
            businessDetail
            shareholders
            SignUpAddressPersonalDetails
            SignUpFinancialDetails
            SignUpIndividualPersonalDetails
            transactionsInfo
            businessType
            author
          }
          readNotifications
          readOnboardings
          amountsSent {
            daily
            monthly
          }
          profileImage
          paymentMethods {
            last4
            scheme
            provider
            customerId
            expiryMonth
            id
            expiryYear
            type
          }
          companyInfo {
            tradingName
            primaryHolderName
            industryType
            registeredAddress
            businessDescription
            annualTurnover
            stablishmentDate
            tradingStartDate
            transactionVolumen
            internationalActivity
            kybCompanyCode
          }
          pushNotificationsConfig {
            token
            endpointArn
          }
          smsMarketingConsent
          pushMarketingConsent
          emailMarketingConsent
          smsAllowed
          pushAllowed
          emailAllowed
          forbiddenCurrency {
            id
            iso
            active
            name
            walletBgColor
            walletTextColor
            order
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          intercomProfileUrl
          accountStatus
          type
          passwordResetAt
          passwordResetBy
          customerId
          businessDetail
          shareholders
          SignUpAddressPersonalDetails
          SignUpFinancialDetails
          SignUpIndividualPersonalDetails
          transactionsInfo
          businessType
          author
        }
        beneficiary {
          id
          status
          userId
          accountType
          name
          lastName
          country {
            id
            iso
            iso2
            active
            name
            phoneCode
            order
            companyRequirements
            personalRequirements
            createdAt
            updatedAt
          }
          address
          city
          postalCode
          email
          phoneCode
          phoneNumber
          currency {
            id
            iso
            active
            name
            walletBgColor
            walletTextColor
            order
            createdAt
            updatedAt
          }
          bankDetails {
            name
            value
          }
          userProfileId
          usageLevel
          skipInternalCheck
          currencyRequirementType
          beneficiaryCountryId
          beneficiaryCurrencyId
        }
        fromCurrency
        toCurrency
        sendingAmount
        exchangeRate
        receivingAmount
        fee
        transferFee
        discount {
          id
          code
          type
          amount
          totalUses
          totalUniqueUses
          expire
          discountUses {
            userId
            date
          }
        }
        reference
        purpose
        status
        steps {
          timestamp
          description
          order
          status
        }
        errorCode
        method
        userFromNote
        userFromAttachment
        userToNote
        userToAttachment
        createdAt
        internalId
        failureReason
        adminAttachments
        debtorAccount {
          addressLine1
          addressLine2
          country
          postcode
          name
          ownerName
          iban
          bban
          descriptor
          institutionName
          transactionOwnerName
        }
        creditorAccount {
          addressLine1
          addressLine2
          country
          postcode
          name
          ownerName
          iban
          bban
          descriptor
          institutionName
          transactionOwnerName
        }
        endToEndId
        rateQuoteId
        runningBalanceFrom
        runningBalanceTo
      }
      total
    }
  }
`;
export const boMCCYSearchTransactions = /* GraphQL */ `
  query BoMCCYSearchTransactions(
    $key: BOMCCYTransactionKey
    $value: String
    $sort: TransactionSort
    $filter: BOMCCYTransactionFilterInput
    $limit: Int
    $offset: Int
  ) {
    boMCCYSearchTransactions(
      key: $key
      value: $value
      sort: $sort
      filter: $filter
      limit: $limit
      offset: $offset
    ) {
      items {
        id
        userId
        username
        currency
        amount
        reference
        status
        steps {
          timestamp
          description
          order
        }
        createdAt
        failureReason
        endToEndId
        beneficiaryName
        beneficiaryAddress
        beneficiaryCity
        beneficiaryPostcode
        beneficiaryCountry
        beneficiarySwift
        beneficiaryIBAN
        beneficiaryCountryCode
        beneficiaryAccountNumber
      }
      total
    }
  }
`;
export const listAllTransactions = /* GraphQL */ `
  query ListAllTransactions(
    $currency: String
    $startDate: String
    $endDate: String
    $limit: Int
    $offset: Int
    $type: AllTransactionType
  ) {
    listAllTransactions(
      currency: $currency
      startDate: $startDate
      endDate: $endDate
      limit: $limit
      offset: $offset
      type: $type
    ) {
      items {
        endToEndId
        status
        amount
        createdAt
        beneficiaryName
      }
      total
    }
  }
`;
export const newSearchCustomers = /* GraphQL */ `
  query NewSearchCustomers(
    $key: NewCustomerKey
    $value: String
    $sort: CustomerSort
    $filter: NewCustomerFilterInput
    $limit: Int
    $offset: Int
  ) {
    newSearchCustomers(
      key: $key
      value: $value
      sort: $sort
      filter: $filter
      limit: $limit
      offset: $offset
    ) {
      items {
        id
        accountType
        membership {
          id
          name
          price
          dailyLimit
          monthlyLimit
          createdAt
          updatedAt
        }
        name
        lastName
        birthdate
        country
        address
        city
        postalCode
        email
        emailVerified
        phoneCode
        phoneNumber
        phoneNumberVerified
        kyc {
          status
          result
          caseId
          applicantId
          reason
        }
        beneficiaries {
          id
          status
          userId
          accountType
          name
          lastName
          country {
            id
            iso
            iso2
            active
            name
            phoneCode
            order
            companyRequirements
            personalRequirements
            createdAt
            updatedAt
          }
          address
          city
          postalCode
          email
          phoneCode
          phoneNumber
          currency {
            id
            iso
            active
            name
            walletBgColor
            walletTextColor
            order
            createdAt
            updatedAt
          }
          bankDetails {
            name
            value
          }
          userProfileId
          usageLevel
          skipInternalCheck
          currencyRequirementType
          beneficiaryCountryId
          beneficiaryCurrencyId
        }
        externalBankAccounts {
          name
          number
          iban
          swift
          sort
        }
        poundsAccount {
          id
          name
          label
          type
          currency
          iban
          bban
          balance
          lastTx
        }
        invitedBy {
          id
          accountType
          membership {
            id
            name
            price
            dailyLimit
            monthlyLimit
            createdAt
            updatedAt
          }
          name
          lastName
          birthdate
          country
          address
          city
          postalCode
          email
          emailVerified
          phoneCode
          phoneNumber
          phoneNumberVerified
          kyc {
            status
            result
            caseId
            applicantId
            reason
          }
          beneficiaries {
            id
            status
            userId
            accountType
            name
            lastName
            address
            city
            postalCode
            email
            phoneCode
            phoneNumber
            userProfileId
            usageLevel
            skipInternalCheck
            currencyRequirementType
            beneficiaryCountryId
            beneficiaryCurrencyId
          }
          externalBankAccounts {
            name
            number
            iban
            swift
            sort
          }
          poundsAccount {
            id
            name
            label
            type
            currency
            iban
            bban
            balance
            lastTx
          }
          invitedBy {
            id
            accountType
            name
            lastName
            birthdate
            country
            address
            city
            postalCode
            email
            emailVerified
            phoneCode
            phoneNumber
            phoneNumberVerified
            readNotifications
            readOnboardings
            profileImage
            smsMarketingConsent
            pushMarketingConsent
            emailMarketingConsent
            smsAllowed
            pushAllowed
            emailAllowed
            createdAt
            updatedAt
            intercomProfileUrl
            accountStatus
            type
            passwordResetAt
            passwordResetBy
            customerId
            businessDetail
            shareholders
            SignUpAddressPersonalDetails
            SignUpFinancialDetails
            SignUpIndividualPersonalDetails
            transactionsInfo
            businessType
            author
          }
          readNotifications
          readOnboardings
          amountsSent {
            daily
            monthly
          }
          profileImage
          paymentMethods {
            last4
            scheme
            provider
            customerId
            expiryMonth
            id
            expiryYear
            type
          }
          companyInfo {
            tradingName
            primaryHolderName
            industryType
            registeredAddress
            businessDescription
            annualTurnover
            stablishmentDate
            tradingStartDate
            transactionVolumen
            internationalActivity
            kybCompanyCode
          }
          pushNotificationsConfig {
            token
            endpointArn
          }
          smsMarketingConsent
          pushMarketingConsent
          emailMarketingConsent
          smsAllowed
          pushAllowed
          emailAllowed
          forbiddenCurrency {
            id
            iso
            active
            name
            walletBgColor
            walletTextColor
            order
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          intercomProfileUrl
          accountStatus
          type
          passwordResetAt
          passwordResetBy
          customerId
          businessDetail
          shareholders
          SignUpAddressPersonalDetails
          SignUpFinancialDetails
          SignUpIndividualPersonalDetails
          transactionsInfo
          businessType
          author
        }
        readNotifications
        readOnboardings
        amountsSent {
          daily
          monthly
        }
        profileImage
        paymentMethods {
          last4
          scheme
          provider
          customerId
          expiryMonth
          id
          expiryYear
          type
        }
        companyInfo {
          tradingName
          primaryHolderName
          industryType
          registeredAddress
          businessDescription
          annualTurnover
          stablishmentDate
          tradingStartDate
          transactionVolumen
          shareholders {
            name
            lastName
            birthdate
            country
            address
            city
            postalCode
            email
            phoneCode
            phoneNumber
          }
          internationalActivity
          kybCompanyCode
        }
        pushNotificationsConfig {
          token
          endpointArn
        }
        smsMarketingConsent
        pushMarketingConsent
        emailMarketingConsent
        smsAllowed
        pushAllowed
        emailAllowed
        forbiddenCurrency {
          id
          iso
          active
          name
          walletBgColor
          walletTextColor
          order
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        intercomProfileUrl
        accountStatus
        type
        passwordResetAt
        passwordResetBy
        customerId
        businessDetail
        shareholders
        SignUpAddressPersonalDetails
        SignUpFinancialDetails
        SignUpIndividualPersonalDetails
        transactionsInfo
        businessType
        author
      }
      total
    }
  }
`;
export const listRoles = /* GraphQL */ `
  query ListRoles {
    listRoles {
      creationDate
      description
      roleName
      lastModifiedDate
      precedence
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers {
    listUsers {
      userName
      attributes {
        name
        value
      }
      userCreateDate
      userLastModifiedDate
      enabled
      userStatus
      roles
    }
  }
`;
export const listMCCYTransactions = /* GraphQL */ `
  query ListMCCYTransactions(
    $currency: String
    $startDate: String
    $endDate: String
  ) {
    listMCCYTransactions(
      currency: $currency
      startDate: $startDate
      endDate: $endDate
    )
  }
`;
export const listMCCYBalances = /* GraphQL */ `
  query ListMCCYBalances {
    listMCCYBalances {
      currency
      available
      actual
    }
  }
`;
export const listFXTrades = /* GraphQL */ `
  query ListFXTrades($createdDay: String) {
    listFXTrades(createdDay: $createdDay)
  }
`;
export const rDSTest = /* GraphQL */ `
  query RDSTest {
    RDSTest
  }
`;
export const listTermsCondition = /* GraphQL */ `
  query ListTermsCondition($limit: Int, $offset: Int) {
    listTermsCondition(limit: $limit, offset: $offset) {
      items {
        id
        platform
        version
        content
        link
        createdAt
        updatedAt
      }
      total
    }
  }
`;
export const getMonthlyStatement = /* GraphQL */ `
  query GetMonthlyStatement($input: GetMonthlyStatementInput) {
    getMonthlyStatement(input: $input) {
      currency
      currentRang
      items {
        currency
        incoming
        outgoing
        balance
        date
        month
      }
    }
  }
`;
export const fetchRelatedShareHolders = /* GraphQL */ `
  query FetchRelatedShareHolders($id: ID!) {
    fetchRelatedShareHolders(id: $id) {
      id
      name
      lastName
      phoneNumber
      boardStatus
      accountType
      accountStatus
      email
      applicantId
    }
  }
`;
export const kybCompanyFilings = /* GraphQL */ `
  query KybCompanyFilings($companyId: String, $countryCode: String) {
    kybCompanyFilings(companyId: $companyId, countryCode: $countryCode) {
      companyId
      countryCode
      filings {
        category
        code
        date
        description
        fileName
        fileType
      }
      pagination {
        limit
        offset
        total
      }
      requestId
      status
    }
  }
`;
export const kybCompanyKycSearch = /* GraphQL */ `
  query KybCompanyKycSearch($companyId: String) {
    kybCompanyKycSearch(companyId: $companyId) {
      created
      searchHits {
        searchHit {
          aka {
            name
          }
          amlTypes
          associates {
            association
            name
          }
          entityType
          lastUpdated
          media {
            date
            snippet
            title
            url
          }
          name
        }
      }
      searchId
      totalHits
      totalMatches
      updated
    }
  }
`;
