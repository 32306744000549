import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(theme => ({
  togglerContainer: {
    display: 'flex',
    flexDirection: 'row',
    background: theme.palette.transactions.background,
    height: '56px',
    borderRadius: '28px',
    margin: '0 20px',
    fontSize: '24px',
    fontFamily: theme.typography.default,
    justifyContent: 'space-evenly',
    alignItems: 'center',
    border: 'solid 1px #d9e1e7',
  },
  contentSection: {
    padding: '0 20px',
    display: 'flex',
    gap: '30px',
  },
  itemContainer: {
    textAlign: 'center',
    flexGrow: '1',
    paddingTop: '5px',
    cursor: 'pointer',
    color: '#a5a6ae',
  },
  selectedContainer: {
    background: theme.palette.white,
    borderRadius: '20px',
    paddingInline: '10px',
    fontWeight: 'bold',
    height: '40px',
    margin: '8px',
    color: theme.palette.icons.nav,
  },
  notSelected: {
    paddingTop: '0px',
  },
  sectionContainer: {
    height: 'calc(100vh - 256px)',
    marginTop: '16px',
    marginBottom: '16px',
    overflowY: 'scroll',
  },
  tab: {
    background: 'theme.palette.white',
    marginTop: '25px',
    paddingBottom: '10px',
  },
  sectionContainer: {
    background: theme.palette.white,
    borderRadius: '0px 16px 16px 16px',
    margin: '0 20px 16px 20px',
    height: 'auto',
  },
  headerContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: '16px',
    paddingTop: '25px',
    fontSize: '24px',
    fontFamily: theme.typography.default,
    fontWeight: 'bold',
    color: theme.palette.icons.nav,
  },
  contentContainer: {
    marginTop: '20px',
    display: 'flex',
    padding: '0 25px',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  infoContainer: {
    display: 'flex',
    justifyContent: 'center',
    gap: '15px',
  },
  info: {
    fontSize: '16px',
    fontFamily: theme.typography.default,
    color: theme.palette.icons.nav,
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
  },
  profile: {
    width: '50px',
    height: '50px',
    background: 'gray',
    borderRadius: '100%',
  },
  authorInfo: {
    color: '#2BE4B0',
    display: 'flex',
    gap: '5px',
    cursor: 'pointer',
    '& b:first-child': {
      color: '#264167',
      width: 'auto',
      cursor: 'default',
    },
  },
  downloadBtn: {
    color: '#2BE4B0',
    display: 'flex',
    gap: '5px',
    cursor: 'pointer',
    '& b:first-child': {
      color: '#264167',
      cursor: 'default',
    },
  },
  downloadBtnDisable: {
    color: 'gray',
    display: 'flex',
    gap: '5px',
    cursor: 'not-allowed',
    '& b:first-child': {
      color: 'gray',
      cursor: 'default',
    },
  },
}));
