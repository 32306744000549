import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { FormControl, MenuItem, Select } from '@mui/material';
import React from 'react';
import { Controller } from 'react-hook-form';
import { uid } from 'react-uid';

import { toStringOrEmptyString } from '../../../utils';
import { useStyles } from './form-select-field.hooks';

export const FormSelectField = ({ name, options, type = 'text', control, rules, defaultValue }) => {
  const classes = useStyles();
  const Icon = () => <ArrowDropDownIcon className={classes.icon} />;

  return (
    <FormControl className={classes.formControl}>
      <Controller
        render={({ field, formState }) => (
          <Select IconComponent={Icon} variant="filled" {...field} {...formState} fullWidth>
            {options.map(option => (
              <MenuItem key={uid(option)} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        )}
        control={control}
        name={name}
        rules={rules}
        type={type}
        defaultValue={toStringOrEmptyString(defaultValue)}
      />
    </FormControl>
  );
};
