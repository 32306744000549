import { TableCell } from '@mui/material';
import React from 'react';

import { FieldValue } from '../../field-value';
import { useStyles } from '../generic-table-body.hooks';

export const CommonTableCell = ({ tableField, entity, data }) => {
  const classes = useStyles();

  const fieldValueProps = {
    field: tableField,
    value: data[tableField.name],
    entity,
  };

  return (
    <TableCell className={classes.tableCell}>
      <FieldValue {...fieldValueProps} />
    </TableCell>
  );
};
