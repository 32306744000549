import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(theme => ({
  root: {
    padding: '2.4rem',
    boxShadow: 'none',
  },
  row: {
    height: '6.4rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: '0.8rem',
    marginBottom: '1em',
    backgroundColor: theme.palette.white,
    padding: '0 2.8rem',
    borderRadius: '0.4rem',
  },
  content: {
    marginTop: '1.6rem',
  },
  tableRoot: {
    borderSpacing: '0 1rem',
    borderCollapse: 'separate',
  },
  inner: {
    paddingTop: '2rem',
    minWidth: '105rem',
  },
  tableHeadCell: {
    maxWidth: '9rem',
    backgroundColor: theme.palette.white,
    border: 'none',
    color: theme.palette.text.secondary,
  },
  tableCell: {
    borderTop: `0.1rem solid ${theme.palette.greyMid.main}`,
    borderBottom: `0.1rem solid ${theme.palette.greyMid.main}`,
    color: theme.palette.text.secondary,
    fontSize: '1.4rem',
    padding: '1rem',

    '&:first-child': {
      borderTopLeftRadius: '0.4rem',
      borderBottomLeftRadius: '0.4rem',
      borderLeft: `0.1rem solid ${theme.palette.greyMid.main}`,
    },
    '&:last-child': {
      borderTopRightRadius: '0.4rem',
      borderBottomRightRadius: '0.4rem',
      borderRight: `0.1rem solid ${theme.palette.greyMid.main}`,
      width: '35%',
    },
  },
  tableHeadRow: {
    border: 'none',
  },
  tableRow: {
    backgroundColor: theme.palette.background.default,
    '&:hover': {
      backgroundColor: '#55ddc0',
    },
  },
}));
