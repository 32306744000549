/* eslint-disable*/
import InputBase from '@mui/material/InputBase';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import React, { useState } from 'react';
import { useStyles } from '../multicurrency.hooks';
import { FXTradeButton } from './FXTradeButton';
import { graphqlApiDecorator } from '../../../../decorators';
import { mutations, queries } from '../../../../graphql';
import { useGraphList } from '../../../../hooks';
import { entitiesEnum } from '../../../../constants';
import { ReturnCountryIcon } from '../../../icons/countries/ReturnCountryIcon';
import { SuccessModal } from '../../setup/success-modal';
import { ModalBodyContent } from '../../setup/modal-body-content';
import { ConfirmationModal } from '../../setup/confirmation-modal';
import { FailModal } from '../../setup/fail-modal';
import { SelectArrowDownIcon } from '../../../icons';
import {areAllValuesDefined, numberWithCommas, todayDate} from "../../../../utils";
import {Snackbar} from "@mui/material";
import {Alert} from "@mui/lab";

function getDate(date) {
  let year = date.getFullYear();
  let month = date.getMonth() + 1;
  let day = date.getDate();

  // Add leading zeros to month and day if necessary
  if (month < 10) {
    month = '0' + month;
  }
  if (day < 10) {
    day = '0' + day;
  }

  return year + '-' + month + '-' + day;
}
export const FXTradesPage = () => {
  const currentDate = new Date();
  // Calculate the dates for tomorrow and day after tomorrow
  const tomorrowDate = new Date();
  tomorrowDate.setDate(currentDate.getDate() + 1);
  const dayAfterTomorrowDate = new Date();
  dayAfterTomorrowDate.setDate(currentDate.getDate() + 2);
  const [sellAmount, setSellAmount] = useState('');
  const [buyAmount, setBuyAmount] = useState('');
  const [fromCurrency, setFromCurrency] = useState('GBP');
  const [state, setState] = useState('');
  const [toCurrency, setToCurrency] = useState('EUR');
  const [btnTitle, setBtnTitle] = useState('Sell');
  const [error, setError] = useState('');
  const [selectedDate, setSelectedDate] = useState(currentDate.toDateString());
  const [errorMessageRequest, setErrorMessageRequest] = useState('');
  const classes = useStyles();
      const [isLoading, setIsLoading] = useState(false);
  const { data: currencies } = useGraphList({
    entity: entitiesEnum.CURRENCY,
  });

  const handleCreateFxOrder = async userData => {
    setIsLoading(true)
    if (!buyAmount && !sellAmount){
      setError({message: 'both buy amount and sell amount are empty'});
      setState('')
      return;
    }
    const amount = buyAmount ? buyAmount: sellAmount;
    const res = await graphqlApiDecorator(mutations, 'makeFXOrder', {
      input: {
        amount,
        fixedSide: buyAmount ? 'BUY': 'SELL',
        fromCurrency,
        toCurrency,
        valueDate: getDate(new Date(selectedDate)),
      },
    }).then(res=>{
      if (res.error){
        setState('fail')
        setErrorMessageRequest(res.error)
      }else{
        setState('success')
        setSellAmount('')
        setBuyAmount('')
      }

      return res;
    }).catch(e=>{
      setState('fail')
    }).finally(()=>{
      setIsLoading(false)
    });

  };

  let input = {
    'Sell currency': sellAmount + ' ' + fromCurrency,
    'Buy Currency': buyAmount + ' ' + toCurrency,
    'Value Date': selectedDate === currentDate.toDateString()? 'Today': selectedDate === tomorrowDate.toDateString() ?'Tomorrow': 'The day after tomorrow',
  };
  const modals = {
    confirmation: (
      <ConfirmationModal
        open={true}
        isDisabled={isLoading}
        onClose={() => setState('')}
        title={'FX Trade'}
        extraFntionality={handleCreateFxOrder}
      >
        <ModalBodyContent obj={input}  />
      </ConfirmationModal>
    ),
    success: (
      <SuccessModal
        open={state}
        onClose={() => setState('')}
        title={'FX Trade'}
        extraFntionality={() => setState('fail')}
      >
        <ModalBodyContent obj={input} />
      </SuccessModal>
    ),
    fail: (
      <FailModal
        open={state}
        errorMessage={errorMessageRequest}
        onClose={() => setState('')}
        title={'FX Trade'}
        extraFntionality={handleCreateFxOrder}
        isDisabled={isLoading}
      >
        <ModalBodyContent obj={input} />
      </FailModal>
    ),
  }[state];

  const setAmount = async (value, type,currency) => {
    // let variables = {};
    if (type === 'sell') {
      setSellAmount(value);
      setBtnTitle('Sell')
      setBuyAmount('');
    } else {
      setBuyAmount(value);
      setBtnTitle('Buy')
      setSellAmount('');
    }
    //   if (type === 'sell') {
    //   setSellAmount(value);
    //   variables = {
    //     fromCurrency: currency ?? fromCurrency,
    //     toCurrency: toCurrency,
    //     fromAmount: parseFloat(value),
    //   };
    // } else {
    //   setBuyAmount(value);
    //   variables = {
    //     fromCurrency: fromCurrency,
    //     toCurrency: currency ?? toCurrency,
    //     toAmount: parseFloat(value),
    //   };
    // }
    // if (areAllValuesDefined(variables)){
    //   const dataResult = await graphqlApiDecorator(queries, 'getExchangeRate', variables);
    //   if (dataResult) {
    //     if (type === 'sell') {
    //       setBtnTitle('Sell')
    //       // setBuyAmountDisabled(dataResult['toAmount']);
    //       setBuyAmountDisabled('');
    //     } else {
    //       setBtnTitle('Buy')
    //       // setSellAmount(dataResult['fromAmount']);
    //       setSellAmount('');
    //     }
    //   }
    // }

  };

  return (
    <div className={classes.sectionContainer}>
      <section className={classes.fxTradesSection}>
        <div className={classes.fxTradesInputSection}>
          <div className={classes.inputItem}>
            <p style={{ marginBottom: '10px' }}>Sell Currency</p>
            <div className={classes.comboInputSelectContainer}>
              <InputBase
                inputProps={{"maxLength": 20}}
                onChange={e => setAmount(parseFloat((e.target.value).replace(/,/g, '')), 'sell', fromCurrency)}
                value={sellAmount ? numberWithCommas(sellAmount): ''}
                className={classes.inputCombo}
                id="demo-customized-textbox"
              />
              <Select
                id="sell_currency"
                value={fromCurrency}
                className={classes.selectCombo}
                IconComponent={SelectArrowDownIcon}
                sx={{
                  borderWidth: '0',
                  width: '100%',
                  borderRadius: '8px',
                  textAlign: 'right',
                  maxHeight: '250px',
                }}
                onChange={e => {
                  setFromCurrency(e.target.value);
                  setAmount(sellAmount,'sell', e.target.value)
                }}
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: 250,
                    },
                  },
                }}
              >
                {Object.values(currencies).map(currency => (
                  <MenuItem className={classes.selectOption} value={currency.iso}>
                    {/* eslint-disable-next-line jsx-a11y/alt-text */}
                    <ReturnCountryIcon country={currency?.iso} />
                    <p className={classes.abbrCurrency}>{currency?.iso}</p>
                    <p>{currency.name}</p>
                  </MenuItem>
                ))}
              </Select>
            </div>
          </div>
          <div className={classes.inputItem}>
            <p style={{ marginBottom: '10px' }}>Buy Currency</p>
            <div className={classes.comboInputSelectContainer}>
              <InputBase
                onChange={e => setAmount(parseFloat((e.target.value).replace(/,/g, '')), 'buy',toCurrency)}
                value={buyAmount ? numberWithCommas(buyAmount): ''}
                inputProps={{"maxLength": 20}}
                className={classes.inputCombo}
                id="demo-customized-textbox"
              />
              <Select
                id="Buy_currency"
                value={toCurrency}
                className={classes.selectCombo}
                IconComponent={SelectArrowDownIcon}
                sx={{
                  borderWidth: '0',
                  width: '100%',
                  borderRadius: '8px',
                  textAlign: 'right',
                }}
                onChange={e => {
                  setToCurrency(e.target.value);
                  setAmount(buyAmount,'buy', e.target.value)

                }}
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: 250,
                    },
                  },
                }}
              >
                {Object.values(currencies).map(currency => (
                  <MenuItem className={classes.selectOption} value={currency.iso}>
                    {/* eslint-disable-next-line jsx-a11y/alt-text */}
                    <ReturnCountryIcon country={currency?.iso} />
                    <p className={classes.abbrCurrency}>{currency?.iso}</p>
                    <p>{currency.name}</p>
                  </MenuItem>
                ))}
              </Select>
            </div>
          </div>
          <div className={classes.utilityClass}>
            <p style={{ marginBottom: '10px' }}>Value date</p>
            <Select
              id="value_date"
              value={selectedDate}
              IconComponent={SelectArrowDownIcon}
              sx={{
                height: '48px',
                width: '100%',
                borderRadius: '8px',
                color: '#19365e',
                fontSize: '20px',
              }}
              onChange={e => setSelectedDate(e.target.value)}
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: 400,
                  },
                },
              }}
            >
              <MenuItem className={classes.selectCombo} value={currentDate.toDateString()}>
                <p>Today</p>
              </MenuItem>
              <MenuItem className={classes.selectCombo} value={tomorrowDate.toDateString()}>
                <p>Tomorrow</p>
              </MenuItem>
              <MenuItem className={classes.selectCombo} value={dayAfterTomorrowDate.toDateString()}>
                <p>The day after tomorrow</p>
              </MenuItem>
            </Select>
          </div>
          <FXTradeButton title={btnTitle} onClick={() => setState('confirmation')} />
        </div>
      </section>
      {modals}

      <Snackbar open={!!error} autoHideDuration={6000} onClose={() => setError('')}>
        <Alert onClose={() => setError('')} severity="error" sx={{ width: '100%' }}>
          {error?.message}
        </Alert>
      </Snackbar>
    </div>
  );
};
