import React from 'react';

export const AUD = ({ width = '45', height = '45' }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 45 47">
    <defs>
      <clipPath id="mvmq7bh57a">
        <path
          data-name="Rectangle 16822"
          transform="translate(.261 .492)"
          style={{ fill: '#fff' }}
          d="M0 0h45v47H0z"
        />
      </clipPath>
    </defs>
    <g
      data-name="Group 6263"
      transform="translate(-.261 -.492)"
      style={{ clipPath: 'url(#mvmq7bh57a)' }}
    >
      <path
        data-name="Path 10803"
        d="M8252.84 6406.967c0 12.684-10.037 22.967-22.42 22.967s-22.42-10.283-22.42-22.967c0 .005 22.421-22.965 22.421-22.967 12.379 0 22.419 10.283 22.419 22.967z"
        transform="translate(-8207.805 -6383.875)"
        style={{ fill: '#0052b4' }}
      />
      <path
        data-name="Path 10804"
        d="M8463.309 6639.38h.06v-.062l-.06.062z"
        transform="translate(-8440.754 -6616.288)"
        style={{ fill: '#f0f0f0' }}
      />
      <path
        data-name="Path 10805"
        d="M8230.42 6395.983V6384c-12.382 0-22.418 10.284-22.418 22.967h11.7v-6.748l6.587 6.748h4.076l.059-.061v-4.175l-6.587-6.748z"
        transform="translate(-8207.805 -6383.875)"
        style={{ fill: '#f0f0f0' }}
      />
      <path
        data-name="Path 10806"
        d="M8249.808 6417.39a22.815 22.815 0 0 0-8.418 8.625v11.347h5.849v-13.98h13.646v-5.991z"
        transform="translate(-8238.271 -6414.27)"
        style={{ fill: '#d80027' }}
      />
      <path
        data-name="Path 10807"
        d="m8352.291 6525.729-7.965-8.16h-2.756l10.721 10.984z"
        transform="translate(-8329.678 -6505.46)"
        style={{ fill: '#d80027' }}
      />
      <path
        data-name="Path 10808"
        d="M8508.112 6684.521v1h-7.8v-1h-2.924v3.993h1.948v1h9.75v-1h1.948v-3.993z"
        transform="translate(-8471.851 -6657.435)"
        style={{ fill: '#ffda44' }}
      />
      <path
        data-name="Path 10809"
        d="M8497.39 6517.568v10.984c0 5.351 6.822 6.99 6.822 6.99s6.824-1.64 6.824-6.99v-10.984z"
        transform="translate(-8471.851 -6505.46)"
        style={{ fill: '#338af3' }}
      />
      <path
        data-name="Path 10810"
        d="M8504.212 6608.89c-1.706 0-1.706 1.6-3.411 1.6s-1.705-1.6-3.411-1.6v2.8c1.706 0 1.706 1.6 3.411 1.6s1.705-1.6 3.411-1.6 1.707 1.6 3.413 1.6 1.705-1.6 3.411-1.6v-2.8c-1.706 0-1.706 1.6-3.411 1.6s-1.706-1.6-3.413-1.6z"
        transform="translate(-8471.851 -6588.589)"
        style={{ fill: '#f3f3f3' }}
      />
      <path
        data-name="Path 10811"
        d="M8504.212 6546.5c-1.706 0-1.706 1.6-3.411 1.6s-1.705-1.6-3.411-1.6v2.8c1.706 0 1.706 1.6 3.411 1.6s1.705-1.6 3.411-1.6 1.707 1.6 3.413 1.6 1.705-1.6 3.411-1.6v-2.8c-1.706 0-1.706 1.6-3.411 1.6s-1.706-1.6-3.413-1.6z"
        transform="translate(-8471.851 -6531.796)"
        style={{ fill: '#f3f3f3' }}
      />
    </g>
  </svg>
);
