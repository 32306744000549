import { Slider, Typography } from '@mui/material';
import React from 'react';

import { splitAndCapitalizeString } from '../../../utils';
import { sliderConfig } from './requirement-field.constants';
import { useStyles } from './requirement-field.hooks';
import {
  getFieldRequirement,
  getFieldRequirementWithUpdatedStatus,
  getRequirementFieldSliderValue,
} from './requirement-field.utils';

export const RequirementField = ({
  requirements,
  currency,
  country,
  requirementFieldName,
  setRequirements,
  setValue,
  fieldName,
}) => {
  const classes = useStyles();
  const { marks, min, max, step } = sliderConfig;
  const requirementField = requirements[currency].find(
    requirement =>
      requirement.field === requirementFieldName || requirement.value === requirementFieldName
  );
  const value = getRequirementFieldSliderValue(requirementField);

  const handleRequirementChange = (event, requirementFieldName) => {
    const { value } = event.target;
    const { fieldRequirement, fieldIndex } = getFieldRequirement({
      country,
      requirements,
      currency,
      requirementFieldName,
    });
    const newRequirements = getFieldRequirementWithUpdatedStatus({
      fieldRequirement,
      requirements,
      currency,
      fieldIndex,
      value,
    });

    setRequirements(newRequirements);
    setValue(fieldName, JSON.stringify(newRequirements));
  };

  return (
    <div className={classes.field}>
      <div className={classes.fieldLabel}>
        <Typography>{splitAndCapitalizeString({ str: requirementFieldName })}</Typography>
      </div>
      <div className={classes.fieldValue}>
        <Slider
          value={value}
          step={step}
          min={min}
          max={max}
          marks={marks}
          onChange={event => handleRequirementChange(event, requirementFieldName)}
        />
      </div>
    </div>
  );
};
