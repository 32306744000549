import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import React from 'react';

import { SearchIcon } from '../../../components/icons';
import { useStyles } from './search-bar.hooks';

/**
 * Generic search bar that is used to search for a query in the handleSubmit() function
 * Similar to the Transaction Search Bar but without the dropdown list
 *
 * @param {string} searchQuery - the query the user entered
 * @param {function} changeQuery - setting the query in the useState hook
 * @param {function} handleSubmit - the query will eventually call to fetch data from the query
 * @param {function} clearQuery - set the searchQuery to empty string
 *
 * @author [Silky Ng](https://github.com/silkster87)
 */

export const SearchBar = ({ searchQuery, changeQuery, handleSubmit, clearQuery }) => {
  const classes = useStyles();

  return (
    <form onSubmit={handleSubmit}>
      <div className={classes.searchBarContainer}>
        <div className={classes.searchIconContainer}>
          <SearchIcon />
        </div>
        <input
          type="text"
          id="search"
          placeholder="Search"
          autoComplete="off"
          value={searchQuery}
          onChange={changeQuery}
          className={classes.searchBar}
        />
        {searchQuery && (
          <div className={classes.closeIconContainer} onClick={clearQuery}>
            <IconButton>
              <CloseIcon fontSize="large" color="info" />
            </IconButton>
          </div>
        )}
      </div>
    </form>
  );
};
