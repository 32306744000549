import { graphqlApiDecorator } from 'decorators';
import { queries } from '../../graphql';
import { Loader } from 'components/loader';
import { useEffect, useState } from 'react';
import { TableContainer, Paper } from '@mui/material';
import { useStyles } from './monitoring.hook';
import ReactJson from 'react-json-view';
import { SmallSideRectangle } from 'components/icons';

export const Monitoring = ({ userId }) => {
  const classes = useStyles();
  const [data, setData] = useState();
  const [loading, setLoading] = useState(true);

  const fetchData = async () => {
    const queryTitle = 'kybCompanyKycSearch';
    setLoading(true);
    try {
      const result = await graphqlApiDecorator(queries, queryTitle, { companyId: userId });
      setData(result);
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <TableContainer className={classes.monitoringContainer} component={Paper}>
          <header className={classes.headerContainer}>
            <SmallSideRectangle />
            <p>Monitoring</p>
          </header>
          {data && (
            <div className={classes.monitoringBody}>
              <ReactJson src={data} displayDataTypes={false} collapsed={2} />
            </div>
          )}
        </TableContainer>
      )}
    </>
  );
};
