import { Button, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import validator from 'validator';

import { KixySmileLogo } from '../../../../components/icons';
import { WarningModal } from '../../../../components/modal/saved-warning-modal';
import { sendConfirmationCodeToUser } from '../../../../services';
import { EnterFourDigitSection } from './enterFourDigitSection';
import { useStyles } from './forgot-password.hooks';

export const ForgotPasswordReset = () => {
  const [email, setEmail] = useState('');
  const [btnDisabled, setBtnDisabled] = useState(true);
  const [show4DigitCodePage, setShow4DigitCodePage] = useState(false);
  const [openWarningModal, setOpenWarningModal] = useState(false);
  const [message, setMessage] = useState('');
  const classes = useStyles();
  const textFieldStyle = { fontSize: '18px', color: '#19365e', borderRadius: '8px' };

  useEffect(() => {
    setBtnDisabled(!validator.isEmail(email));
  }, [email]);

  const handleContinue = async event => {
    event.preventDefault();
    if (!validator.isEmail(email)) return;

    try {
      await sendConfirmationCodeToUser(email);
      setShow4DigitCodePage(true);
    } catch (error) {
      setMessage(error.message);
      setOpenWarningModal(true);
    }
  };

  const closeModal = () => setOpenWarningModal(false);

  return (
    <>
      {show4DigitCodePage ? (
        <EnterFourDigitSection email={email} />
      ) : (
        <form className={classes.root} onSubmit={handleContinue}>
          <header>Forgot your Password?</header>
          <div className={classes.subHeader}>
            <p>No worries. We&apos;ve got your back</p>
            <KixySmileLogo />
          </div>
          <p>Enter your email address you used to join Kixy:</p>
          <section className={classes.emailContainer}>
            <p>Email</p>
            <TextField
              type="text"
              value={email}
              onChange={e => setEmail(e.target.value)}
              fullWidth
              InputProps={{
                style: textFieldStyle,
              }}
            />
          </section>
          <Button
            className={classes.signInButton}
            color="primary"
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            disabled={btnDisabled}
            sx={{
              '&.Mui-disabled': {
                backgroundColor: '#d9e1e7',
                color: 'white',
              },
            }}
          >
            Continue
          </Button>
        </form>
      )}
      <WarningModal open={openWarningModal} confirm={closeModal} message={message} title="Error" />
    </>
  );
};
