const permissionOptions = [
  'all',
  'transactions',
  'fees',
  'pairs',
  'customers',
  'currencies',
  'countries',
];

export { permissionOptions };
