import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from '@mui/material';
import { useStyles } from './risk.hook';
import GaugeChart from 'components/gauge-chart/gauge-chart';
import { queries } from '../../graphql';
import { SmallSideRectangle } from 'components/icons';
import { useEffect, useState } from 'react';
import { graphqlApiDecorator } from 'decorators';

export const Risk = ({ id, AML }) => {
  const classes = useStyles();
  const [currentRiskLevel, setCurrentRiskLevel] = useState();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchCurrentRisk();
  }, []);

  const fetchCurrentRisk = async () => {
    const queryTitle = 'getLatestUserRisks';
    const userId = id;

    try {
      setLoading(true);
      const result = await graphqlApiDecorator(queries, queryTitle, { userId: userId });
      if (result) {
        const currentRiskRate = result.filter(item => item.type === 'OVERALL');

        if (currentRiskRate.length > 0) {
          setCurrentRiskLevel(currentRiskRate[0].riskLevel);
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const formatString = str => {
    return str
      .replace(/([a-z])([A-Z])/g, '$1 $2')
      .replace(/^\w|\s\w/g, match => match.toUpperCase());
  };

  return (
    <>
      <TableContainer className={classes.riskContainer} component={Paper}>
        <header className={classes.headerContainer}>
          <SmallSideRectangle />
          <p>Risk Rating</p>
        </header>
        <GaugeChart riskRate={currentRiskLevel} type={'kyb'} loading={loading} />
      </TableContainer>
      <TableContainer className={classes.amlContainer} component={Paper}>
        <header className={classes.headerContainer}>
          <SmallSideRectangle />
          <p>AML List</p>
        </header>
        <Table>
          <TableHead>
            <TableRow className={classes.firstRow}>
              <TableCell className={classes.tableCell}>Question</TableCell>
              <TableCell className={classes.tableCell}>Answer</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {AML ? (
              Object.keys(AML).map((key, index) => (
                <TableRow key={index} className={classes.tableRow}>
                  <TableCell className={classes.tableCell}>{formatString(key)}</TableCell>
                  <TableCell className={classes.tableCell}>{AML[key]}</TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={7} className={classes.tableCell}>
                  No data available
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};
