import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(theme => ({
  emailSection: {
    fontFamily: theme.typography.default,
    color: theme.palette.icons.nav,
    fontSize: '14px',
    border: '1px solid #1fe3ac',
    overFlowY: 'scroll',
    borderRadius: '8px',
    '& input::placeholder': {
      fontWeight: 'bold',
      color: theme.palette.icons.nav,
    },
    '& input': {
      border: 'none',
      fontWeight: 'bold',
      color: theme.palette.icons.nav,
    },
    '& textarea': {
      fontFamily: theme.typography.default,
      color: theme.palette.icons.nav,
      border: 'none',
    },
  },
  emailHeader: {
    paddingLeft: '16px',
    paddingRight: '16px',
    height: '80px',
    borderRadius: '8px 8px 20px 20px',
    backgroundColor: '#e9fff9',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  emailContent: {
    marginTop: '16px',
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
    padding: '8px',
  },
  emailFooter: {
    borderRadius: '8px',
    backgroundColor: '#e9fff9',
    height: '104px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '8px',
    marginTop: '16px',
  },
  footerIcons: {
    display: 'flex',
    flexDirection: 'row',
    gap: '8px',
  },
  doneBtn: {
    marginTop: '16px',
    textTransform: 'none',
    color: theme.palette.icons.nav,
    fontFamily: theme.typography.default,
    height: '32px',
    width: '128px',
    borderRadius: '16px',
    backgroundColor: '#c6ffef',
    '&:hover': {
      backgroundColor: '#1fe3ac',
    },
  },
  btnContainer: {
    width: '128px',
    margin: 'auto',
  },
  emailSubjectInput: {
    fontSize: '14px',
  },
  emailTitleInput: {
    fontSize: '16px',
  },
  td: {
    height: '458px',
    overflow: 'hidden',
  },
}));
