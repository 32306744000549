import React from 'react';

export const DirectDebitIcon = ({ width = '18px', height = '21px' }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 18 21">
    <path
      data-name="Path 23228"
      d="M14.761 0H3.239A3.237 3.237 0 0 0 0 3.228v8.231a3.237 3.237 0 0 0 3.239 3.227h5.019v5.6a.743.743 0 0 0 1.484 0v-5.6h5.019A3.237 3.237 0 0 0 18 11.459V3.228A3.237 3.237 0 0 0 14.761 0m1.869 11.459a1.868 1.868 0 0 1-1.869 1.863H9.742V6.471L12.4 9.457a.741.741 0 1 0 1.109-.983L9.541 4.019a.74.74 0 0 0-1.109 0L4.485 8.477a.74.74 0 0 0 .556 1.229.742.742 0 0 0 .558-.25l2.659-3.005v6.871H3.239a1.868 1.868 0 0 1-1.869-1.863V3.228a1.868 1.868 0 0 1 1.869-1.862h11.522a1.868 1.868 0 0 1 1.869 1.862z"
      style={{ fill: '#fc3333' }}
    />
  </svg>
);
