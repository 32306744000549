import React from 'react';

export const CircularSavedIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="66" height="66" viewBox="0 0 66 66">
    <g data-name="Group 9764" transform="translate(-190 -481)">
      <circle
        data-name="Ellipse 1748"
        cx="33"
        cy="33"
        r="33"
        transform="translate(190 481)"
        style={{ fill: '#23c3a4' }}
      />
      <path
        data-name="Path 19313"
        d="m24.578 14.052-7.615 7.633-2.929-2.929a1.775 1.775 0 1 0-2.5 2.5l4.166 4.192a1.778 1.778 0 0 0 2.5 0l8.875-8.875a1.776 1.776 0 1 0-2.5-2.52zM19.75 2A17.75 17.75 0 1 0 37.5 19.75 17.75 17.75 0 0 0 19.75 2zm0 31.95a14.2 14.2 0 1 1 14.2-14.2 14.2 14.2 0 0 1-14.2 14.2z"
        transform="translate(203.86 494.86)"
        style={{ fill: '#fff' }}
      />
    </g>
  </svg>
);
