import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardControlKeyIcon from '@mui/icons-material/KeyboardControlKey';
import { Card, IconButton, Typography } from '@mui/material';
import clsx from 'clsx';
import React from 'react';

import { useStyles } from './closing-cart.hooks';

export const ClosingCard = ({ children, title, active, setActiveCard, card, className }) => {
  const classes = useStyles();

  const onOpenCard = targetCard => {
    setActiveCard(targetCard);
  };

  const onCloseCard = () => {
    setActiveCard('');
  };

  return (
    <Card
      className={clsx(className, {
        [classes.active]: active,
        [classes.closed]: !active,
      })}
      id={card}
    >
      <div className={classes.cardHeader}>
        <Typography variant="h4" style={{ marginLeft: '1em' }}>
          {title}
        </Typography>
        {active ? (
          <IconButton onClick={onCloseCard} size="large">
            <KeyboardControlKeyIcon />
          </IconButton>
        ) : (
          <IconButton onClick={() => onOpenCard(card)} size="large">
            <KeyboardArrowDownIcon />
          </IconButton>
        )}
      </div>
      {children}
    </Card>
  );
};
