import { combineReducers } from 'redux';

const userTransactionSearchQuery = (state = '', action) => {
  switch (action.type) {
    case 'SET_USER_TRANSACTION_SEARCH_QUERY':
      return action.payload;
    default:
      return state;
  }
};

const searchCurrencyQuery = (state = '', action) => {
  switch (action.type) {
    case 'SET_SEARCH_CURRENCY_QUERY':
      return action.payload;
    default:
      return state;
  }
};

const tableDataToExport = (state = [], action) => {
  switch (action.type) {
    case 'SET_TABLE_DATA_TO_EXPORT':
      return action.payload;
    default:
      return state;
  }
};

const userTransactionsTableDataToExport = (state = [], action) => {
  switch (action.type) {
    case 'SET_USER_TRANSACTIONS_DATA_TO_EXPORT':
      return action.payload;
    default:
      return state;
  }
};

const customersTableDataToExport = (state = [], action) => {
  switch (action.type) {
    case 'SET_CUSTOMER_TABLE_DATA_TO_EXPORT':
      return action.payload;
    default:
      return state;
  }
};

const currenciesTableDataToExport = (state = [], action) => {
  switch (action.type) {
    case 'SET_CURRENCIES_TABLE_DATA_TO_EXPORT':
      return action.payload;
    default:
      return state;
  }
};

const transactionFilterObj = (state = {}, action) => {
  switch (action.type) {
    case 'SET_TRANSACTION_FILTER_OBJ':
      return action.payload;
    default:
      return state;
  }
};

const userTransactionFilterObj = (state = {}, action) => {
  switch (action.type) {
    case 'SET_USER_TRANSACTION_FILTER_OBJ':
      return action.payload;
    default:
      return state;
  }
};

const customerFilterObj = (state = {}, action) => {
  switch (action.type) {
    case 'SET_CUSTOMER_FILTER_OBJ':
      return action.payload;
    default:
      return state;
  }
};

const customerFilterByAccountType = (state = '', action) => {
  switch (action.type) {
    case 'SET_CUSTOMER_FILTER_BY_ACCOUNT_TYPE':
      return action.payload;
    default:
      return state;
  }
};

const pairsCurrenciesObj = (state = {}, action) => {
  switch (action.type) {
    case 'SET_PAIRS_CURRENCIES_OBJ':
      return { ...state, ...action.payload };
    case 'CLEAR_PAIRS_CURRENCIES_OBJ':
      return {};
    default:
      return state;
  }
};

const pairsSearchQuery = (state = '', action) => {
  switch (action.type) {
    case 'SET_PAIRS_SEARCH_QUERY':
      return action.payload;
    default:
      return state;
  }
};

const transactionSort = (state = '', action) => {
  switch (action.type) {
    case 'SET_TRANSACTION_SORT':
      return action.payload;
    default:
      return state;
  }
};

const userTransactionSort = (state = '', action) => {
  switch (action.type) {
    case 'SET_USER_TRANSACTION_SORT':
      return action.payload;
    default:
      return state;
  }
};

const customerSort = (state = '', action) => {
  switch (action.type) {
    case 'SET_CUSTOMER_SORT':
      return action.payload;
    default:
      return state;
  }
};

export const reducers = combineReducers({
  userTransactionSearchQuery,
  userTransactionsTableDataToExport,
  searchCurrencyQuery,
  tableDataToExport,
  customersTableDataToExport,
  currenciesTableDataToExport,
  transactionFilterObj,
  customerFilterByAccountType,
  userTransactionFilterObj,
  customerFilterObj,
  pairsCurrenciesObj,
  pairsSearchQuery,
  transactionSort,
  userTransactionSort,
  customerSort,
});
