import { palette } from '../palette';
import { typography } from '../typography';

export const MuiTableCell = {
  root: {
    ...typography.body1,
    borderBottom: `0.1rem solid ${palette.divider}`,
    padding: '0rem 1rem 0rem 1rem',
  },
};
