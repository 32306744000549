import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import React from 'react';

import { CircularSavedIcon } from '../../icons';
import { useStyles } from './modal.hooks';

export const SavedModal = ({ onClose, header, content, open }) => {
  const classes = useStyles();

  let openVal = true;

  if (![null, undefined].includes(open)) {
    openVal = open;
  }

  return (
    <Modal open={openVal} onClose={onClose}>
      <Box>
        <div className={classes.modalBox}>
          <div className={classes.iconContainer}>
            <CircularSavedIcon />
          </div>
          <header>{header}</header>
          <p>{content}</p>
          <section className={classes.cancelDelSection}>
            <div className={classes.cancel} onClick={onClose}>
              OK
            </div>
          </section>
        </div>
      </Box>
    </Modal>
  );
};
