import React from 'react';
import { Controller } from 'react-hook-form';

import { StyledTextField } from './styled-text-field';

export const FormTextField = ({
  errors,
  field,
  type = 'text',
  control,
  rules,
  disabled,
  largeText,
}) => {
  const { label, name } = field;
  const multiline = field.name.slice(0, 8) === 'richText';
  const defaultValue = type === 'number' ? 0 : '';

  return (
    <Controller
      render={({ field, formState }) => {
        if (!field.value) {
          field.value = defaultValue;
        }

        return (
          <StyledTextField
            error={!!errors}
            {...field}
            {...formState}
            label={label || name}
            fullWidth
            multiline={largeText ? true : multiline}
            rows={8}
            disabled={disabled}
            name={name}
            InputLabelProps={{ shrink: true }}
          />
        );
      }}
      control={control}
      name={name}
      focused
      rules={rules}
      type={type}
      variant="outlined"
    />
  );
};
