import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(theme => ({
  togglerContainer: {
    display: 'flex',
    flexDirection: 'row',
    background: theme.palette.transactions.background,
    height: '56px',
    borderRadius: '28px',
    margin: '0 20px',
    fontSize: '24px',
    fontFamily: theme.typography.default,
    justifyContent: 'space-evenly',
    alignItems: 'center',
    border: 'solid 1px #d9e1e7',
  },
  itemContainer: {
    textAlign: 'center',
    flexGrow: '1',
    paddingTop: '5px',
    cursor: 'pointer',
    color: '#a5a6ae',
  },
  selectedContainer: {
    background: theme.palette.white,
    borderRadius: '20px',
    paddingInline: '10px',
    fontWeight: 'bold',
    height: '40px',
    margin: '8px',
    color: theme.palette.icons.nav,
  },
  notSelected: {
    paddingTop: '0px',
  },
  sectionContainer: {
    height: 'calc(100vh - 256px)',
    marginTop: '16px',
    marginBottom: '16px',
    overflowY: 'scroll',
  },
  boxContent: {
    padding: '24px',
    paddingTop: '0',
  },
  tab: {
    background: theme.palette.white,
    padding: '0 24px 24px 24px',
    boxShadow: '0 -5px 0px 3px white',
    borderBottomLeftRadius: '15px',
    borderBottomRightRadius: '15px',
  },
}));
