import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  detailsRow: {
    display: 'flex',
    flexDirection: 'row',
    gap: '10px',
    '& p:first-child': {
      fontWeight: 'bold',
      flexBasis: '200px',
    },
  },
});
