import { KycResults, KycStatuses } from '../constants';

const getKycStatuses = status => {
  const isUnstarted = status === KycStatuses.UNSTARTED;
  const isPending = status === KycStatuses.PENDING;
  const isCompleted = status === KycStatuses.COMPLETED;

  return { isUnstarted, isPending, isCompleted };
};

const getKycResults = result => {
  const isResultSuccess = result === KycResults.GREEN;
  const isResultFail = result === KycResults.RED;

  return { isResultSuccess, isResultFail };
};

const getKycInfo = kyc => {
  if (!kyc) {
    return {
      applicantId: null,
      kycLink: null,
      status: null,
    };
  }

  const { applicantId, status } = kyc;

  const kycLink = `https://cockpit.sumsub.com/checkus/#/applicant/${applicantId}/basicInfo?clientId=kiyanpay`;

  return {
    applicantId,
    kycLink,
    status,
  };
};

export { getKycInfo, getKycResults, getKycStatuses };
