import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { graphqlApiDecorator } from '../../../../decorators';
import { queries } from '../../../../graphql';
import { groupPairsByFromCountry } from '../../../../utils';
import { Loader } from '../../../loader';
import { SetupPage } from '../setup';
import { WarningModal } from '../setup/modal';
import { TransferFeesPage } from '../transferFees/transfer-fees-page';
import { PairsRow } from './pairs-row';
import { useStyles } from './pairs-table.hooks';

/**
 * PairsTable component where the parent component is EntityTable. This is displayed if the entity
 * is pairs as it is a completely different UI design than the GenericTable for other entities
 * @param {Array} data array of pairs data to render
 * @param {boolean} loading true if loading, false if not
 * @param {function} handleRefreshData this eventually calles the fetchEntity() in the useGraphList() custom hook
 */

export const PairsTable = ({ data, loading, handleRefreshData }) => {
  const RATES = 'rates';
  const SETUP = 'setup';
  const TRANSFER_FEES = 'transferFees';
  const [tabSelected, setTabSelected] = useState(RATES);
  const [currencyRates, setCurrencyRates] = useState([]);
  const [showWarningModal, setShowWarningModal] = useState(false);
  const [warningMessage, setWarningMessage] = useState('');
  const classes = useStyles();
  const dispatch = useDispatch();

  const pairsSearchQuery = useSelector(state => state.pairsSearchQuery);
  // Only pairs from and to properties should be shown
  let copyOfRowData = [...data].filter(pair => !!pair.from && !!pair.to);

  if (pairsSearchQuery !== '') {
    const regex = new RegExp(pairsSearchQuery, 'gi');
    copyOfRowData = copyOfRowData.filter(
      pair => pair.from.iso.match(regex) || pair.from.name.match(regex)
    );
  }

  const groupedData = groupPairsByFromCountry(copyOfRowData);
  const fromCountries = Object.keys(groupedData);

  const getFlatRates = async () => {
    try {
      const result = await graphqlApiDecorator(queries, 'getFlatRates', { limit: 756 });
      setCurrencyRates(result);
    } catch (err) {
      setWarningMessage(err.message);
      setShowWarningModal(true);
    }
  };

  useEffect(() => {
    // Clear previous data
    dispatch({ type: 'SET_PAIRS_SEARCH_QUERY', payload: '' });
    dispatch({ type: 'SET_PAIRS_CURRENCIES_OBJ', payload: {} });
    getFlatRates();
  }, []);

  fromCountries?.sort();

  // User has clicked on a certain pair -> take details and navigate to setup page for pair
  const setupPage = pairData => {
    const payloadData = {
      'Sending Currency': [`${pairData.from.iso}`],
      'Receiving Currency': [`${pairData.to.iso}`],
    };
    dispatch({ type: 'SET_PAIRS_CURRENCIES_OBJ', payload: payloadData });
    setTabSelected(SETUP);
  };

  const refreshFlatRates = () => {
    getFlatRates();
  };

  return (
    <div>
      {loading ? (
        <Loader />
      ) : (
        <>
          <div className={classes.tab}>
            <div className={classes.togglerContainer}>
              <div
                className={
                  tabSelected === RATES
                    ? `${classes.selectedContainer} ${classes.itemContainer}`
                    : `${classes.itemContainer} ${classes.notSelected}`
                }
                onClick={() => setTabSelected(RATES)}
              >
                Rates
              </div>
              <div
                className={
                  tabSelected === SETUP
                    ? `${classes.selectedContainer} ${classes.itemContainer}`
                    : `${classes.itemContainer} ${classes.notSelected}`
                }
                onClick={() => setTabSelected(SETUP)}
              >
                Setup
              </div>
              <div
                className={
                  tabSelected === TRANSFER_FEES
                    ? `${classes.selectedContainer} ${classes.itemContainer}`
                    : `${classes.itemContainer} ${classes.notSelected}`
                }
                onClick={() => setTabSelected(TRANSFER_FEES)}
              >
                Transfer Fees
              </div>
            </div>
          </div>

          <div className={classes.boxContent}>
            {tabSelected === RATES && (
              <section className={classes.sectionContainer}>
                {fromCountries.map(country => (
                  <PairsRow
                    key={country}
                    rates={currencyRates && currencyRates.filter(rate => rate.from === country)}
                    rowData={groupedData[country]}
                    country={country}
                    setupPage={setupPage}
                    handleRefreshData={handleRefreshData}
                  />
                ))}
              </section>
            )}
            {tabSelected === SETUP && (
              <section className={classes.sectionContainer}>
                <SetupPage
                  data={data}
                  handleRefreshData={handleRefreshData}
                  rates={currencyRates}
                  refreshFlatRates={refreshFlatRates}
                />
              </section>
            )}
            {tabSelected === TRANSFER_FEES && (
              <section className={classes.sectionContainer}>
                <TransferFeesPage />
              </section>
            )}
          </div>
        </>
      )}
      <WarningModal
        open={showWarningModal}
        onClose={() => setShowWarningModal(false)}
        message={warningMessage}
      />
    </div>
  );
};
