import React from 'react';

export const PremiumIcon = ({ active = true }) => {
  if (active)
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 40 40">
        <g data-name="Group 8382" transform="translate(-424 -608)">
          <circle
            data-name="Ellipse 1544"
            cx="20"
            cy="20"
            r="20"
            transform="translate(424 608)"
            style={{ fill: '#0cc5ff' }}
          />
          <path
            data-name="Icon ionic-md-star"
            d="m15.8 23.793 7.413 4.707-1.962-8.879 6.546-5.97-8.63-.78L15.8 4.5l-3.371 8.371-8.629.78 6.546 5.97L8.381 28.5z"
            transform="translate(428.203 611.5)"
            style={{ fill: '#fff' }}
          />
        </g>
      </svg>
    );

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 40 40">
      <g data-name="Group 8314" transform="translate(-424 -608)">
        <circle
          data-name="Ellipse 1544"
          cx="20"
          cy="20"
          r="20"
          transform="translate(424 608)"
          style={{ fill: '#f2f2f4' }}
        />
        <path
          data-name="Icon ionic-md-star"
          d="m15.8 23.793 7.413 4.707-1.962-8.879 6.546-5.97-8.63-.78L15.8 4.5l-3.371 8.371-8.629.78 6.546 5.97L8.381 28.5z"
          transform="translate(428.203 611.5)"
          style={{ fill: '#a5a6ae' }}
        />
      </g>
    </svg>
  );
};
