import { palette } from './palette';

export const typography = {
  default: 'Roboto , Helvetica, Arial, sans-serif',
  h1: {
    color: palette.text.primary,
    fontWeight: 500,
    fontSize: '3.5rem',
    letterSpacing: '-0.024rem',
    lineHeight: '4rem',
  },
  h2: {
    color: palette.text.primary,
    fontWeight: 'bold',
    fontSize: '2.8rem',
    letterSpacing: '-0.024rem',
    lineHeight: 'normal',
    fontFamily: 'Gilroy, Roboto , Helvetica, Arial, sans-serif',
  },
  h3: {
    color: palette.text.secondary,
    fontWeight: 500,
    fontSize: '2.4rem',
    letterSpacing: '-0.006rem',
    lineHeight: '2.8rem',
    fontFamily: 'Gilroy, Roboto , Helvetica, Arial, sans-serif',
  },
  h4: {
    color: palette.text.secondary,
    fontWeight: 500,
    fontSize: '2rem',
    letterSpacing: '-0.006rem',
    lineHeight: '2.4rem',
    fontFamily: 'Gilroy, Roboto , Helvetica, Arial, sans-serif',
  },
  h5: {
    color: palette.text.primary,
    fontWeight: 500,
    fontSize: '1.6rem',
    letterSpacing: '-0.005rem',
    lineHeight: '2rem',
    fontFamily: 'Gilroy, Roboto , Helvetica, Arial, sans-serif',
  },
  h6: {
    color: palette.text.primary,
    fontWeight: 500,
    fontSize: '1.4rem',
    letterSpacing: '-0.005rem',
    lineHeight: '2rem',
  },
  subtitle1: {
    color: palette.text.tertiary,
    fontSize: '1.6rem',
    letterSpacing: '-0.005rem',
    lineHeight: '2.5rem',
  },
  subtitle2: {
    color: palette.text.secondary,
    fontWeight: 400,
    fontSize: '1.4rem',
    letterSpacing: '-0.005rem',
    lineHeight: '2.1rem',
  },
  body1: {
    color: palette.text.primary,
    fontSize: '1.4rem',
    letterSpacing: '-0.005rem',
    lineHeight: '2.1rem',
  },
  body2: {
    color: palette.text.secondary,
    fontSize: '1.2rem',
    letterSpacing: '-0.004rem',
    lineHeight: '1.8rem',
  },
  button: {
    color: palette.text.primary,
    fontSize: '1.4rem',
  },
  caption: {
    color: palette.text.secondary,
    fontSize: '1.1rem',
    letterSpacing: '0.033rem',
    lineHeight: '1.3rem',
  },
  overline: {
    color: palette.text.secondary,
    fontSize: '1.1rem',
    fontWeight: 500,
    letterSpacing: '0.033rem',
    lineHeight: '1.3rem',
    textTransform: 'uppercase',
  },
};
