import React, { useEffect, useState } from 'react';
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';

import { AdminActivity, AdminTeamPage, RouteElement, TeamPage } from './components';
import { CustomerDetailsPage } from './components/generic-table/customers-table/customer-detail-page';
import { entitiesEnum } from './constants';
import { isFeatureEnabled } from './utils';
import {
  CountryEdit,
  CurrencyRequirementsEdit,
  GenericEdit,
  GenericList,
  PairsView,
  SignIn,
  TransactionDetailsPage,
  Dashboard,
} from './views';
import { getCurrentUser } from 'services';

export const RoutesList = ({ userRoles }) => {
  const [dynamicRoutes, setDynamicRoutes] = useState([]);
  const [routesLoaded, setRoutesLoaded] = useState(false);
  const navigate = useNavigate();

  const fetchRoutes = async () => {
    const { accessToken } = await getCurrentUser();
    if (accessToken) {
      try {
        const response = await fetch('https://api.test.kixy.com/user/admin-menu', {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const data = await response.json();
        const existingTitles = new Set([
          'userprofile',
          'metrics',
          'table',
          'kyb',
          'form',
          'transaction',
          'pair-new',
          'pair',
          'currency',
          'country',
        ]);

        const newRoutes = data.resources
          .filter(item => !existingTitles.has(item.title.toLowerCase()))
          .map(item => ({
            path: `/${item.title.toLowerCase()}`,
            element: (
              <RouteElement
                component={GenericList}
                entity={entitiesEnum[item.title.toUpperCase()]}
              />
            ),
          }));

        setDynamicRoutes(newRoutes);
      } catch (error) {
        console.error('Failed to fetch and update routes:', error);
      }
    }
  };

  useEffect(() => {
    const callFetchRoutesWithDelay = async () => {
      await fetchRoutes();

      setTimeout(async () => {
        await fetchRoutes();
      }, 1000);
    };

    callFetchRoutesWithDelay();
  }, [navigate]);

  useEffect(() => {
    const currentPath = window.location.pathname;

    if (userRoles?.includes('admin') || userRoles?.includes('developers')) {
      if (currentPath === '/' || currentPath === '/sign-in') {
        navigate('/userprofile');
      }
    } else if (userRoles?.includes('mccy')) {
      if (currentPath === '/' || currentPath === '/sign-in') {
        navigate('/multicurrency');
      }
    } else if (
      currentPath === '/' ||
      currentPath === '/userprofile' ||
      currentPath === '/multicurrency'
    ) {
      navigate('/sign-in', { replace: true });
    }
  }, [routesLoaded, userRoles, navigate]);

  return (
    <Routes>
      {(userRoles?.includes('admin') ||
        userRoles?.includes('developers') ||
        userRoles?.includes('compliance')) && (
        <>
          <Route path="/" element={<Navigate to={'/userprofile'} replace />} />
          <Route
            path="/userprofile"
            element={<RouteElement component={GenericList} entity={entitiesEnum.USERPROFILE} />}
          />
          <Route
            path="/metrics"
            element={<RouteElement component={Dashboard} entity={entitiesEnum.METRIC} />}
          />
          <Route
            path="/table"
            element={<RouteElement component={GenericList} entity={entitiesEnum.TABLE} />}
          />
          <Route
            path="/kyb"
            element={<RouteElement component={GenericList} entity={entitiesEnum.KYB} />}
          />
          <Route
            path="/form"
            element={<RouteElement component={GenericList} entity={entitiesEnum.FORM} />}
          />
          <Route
            path="/userprofile/:id"
            element={
              <RouteElement component={CustomerDetailsPage} entity={entitiesEnum.USERPROFILE} />
            }
          />
          <Route
            path="/transaction"
            element={<RouteElement component={GenericList} entity={entitiesEnum.TRANSACTION} />}
          />
          <Route
            path="/transaction/:id"
            element={
              <RouteElement
                component={TransactionDetailsPage}
                entity={entitiesEnum.TRANSACTIONV2}
              />
            }
          />
          <Route
            path="/pair-new"
            element={<RouteElement component={PairsView} entity="PairsNew" />}
          />
          <Route
            path="/pair"
            element={<RouteElement component={GenericList} entity={entitiesEnum.PAIR} />}
          />
          <Route
            path="/pair/:id"
            element={<RouteElement component={GenericEdit} entity={entitiesEnum.PAIR} />}
          />
          <Route
            path="/currency"
            element={<RouteElement component={GenericList} entity={entitiesEnum.CURRENCY} />}
          />
          <Route
            path="/currency/:id"
            element={
              <RouteElement component={CurrencyRequirementsEdit} entity={entitiesEnum.CURRENCY} />
            }
          />
          <Route
            path="/country"
            element={<RouteElement component={GenericList} entity={entitiesEnum.COUNTRY} />}
          />
          <Route
            path="/country/:id"
            exact
            element={<RouteElement component={CountryEdit} entity={entitiesEnum.COUNTRY} />}
          />
          {dynamicRoutes.map((route, index) => (
            <Route key={index} path={route.path} element={route.element} />
          ))}
        </>
      )}
      {userRoles?.includes('admin') && (
        <>
          <Route
            path="/multicurrency"
            exact
            element={<RouteElement component={GenericList} entity={entitiesEnum.MULTICURRENCY} />}
          />
          <Route
            path="/adminTeam"
            element={<RouteElement component={AdminTeamPage} entity={entitiesEnum.TEAM} />}
          />
        </>
      )}
      {userRoles?.includes('mccy') && (
        <>
          <Route
            path="/multicurrency"
            exact
            element={<RouteElement component={GenericList} entity={entitiesEnum.MULTICURRENCY} />}
          />
        </>
      )}
      <Route path="/sign-in" element={<RouteElement component={SignIn} />} />
      {isFeatureEnabled('hiddenRoutes') && (
        <>
          <Route
            path="/membership"
            element={<RouteElement component={GenericList} entity={entitiesEnum.MEMBERSHIP} />}
          />
          <Route
            path="/membership/:id"
            element={<RouteElement component={GenericEdit} entity={entitiesEnum.MEMBERSHIP} />}
          />
          <Route
            path="/notification"
            element={<RouteElement component={GenericList} entity={entitiesEnum.NOTIFICATION} />}
          />
          <Route
            path="/notification/:id"
            element={<RouteElement component={GenericEdit} entity={entitiesEnum.NOTIFICATION} />}
          />
          <Route
            path="/region"
            element={<RouteElement component={GenericList} entity={entitiesEnum.REGION} />}
          />
          <Route
            path="/region/:id"
            element={<RouteElement component={GenericEdit} entity={entitiesEnum.REGION} />}
          />
          <Route
            path="/team"
            element={<RouteElement component={TeamPage} entity={entitiesEnum.TEAM} />}
          />
          <Route
            path="/team/:id"
            element={<RouteElement component={AdminActivity} entity={entitiesEnum.TEAM} />}
          />
          <Route
            path="/fee"
            element={<RouteElement component={GenericList} entity={entitiesEnum.FEE} />}
          />
          <Route
            path="/fee/:id"
            element={<RouteElement component={GenericEdit} entity={entitiesEnum.FEE} />}
          />
          <Route
            path="/localpair"
            element={<RouteElement component={GenericList} entity={entitiesEnum.LOCALPAIR} />}
          />
          <Route
            path="/localpair/:id"
            element={<RouteElement component={GenericEdit} entity={entitiesEnum.LOCALPAIR} />}
          />
          <Route
            path="/config"
            element={<RouteElement component={GenericList} entity={entitiesEnum.CONFIG} />}
          />
          <Route
            path="/config/:id"
            element={<RouteElement component={GenericEdit} entity={entitiesEnum.CONFIG} />}
          />
          <Route
            path="/discount"
            element={<RouteElement component={GenericList} entity={entitiesEnum.DISCOUNT} />}
          />
          <Route
            path="/discount/:id"
            element={<RouteElement component={GenericEdit} entity={entitiesEnum.DISCOUNT} />}
          />
          <Route
            path="/onboarding"
            element={<RouteElement component={GenericList} entity={entitiesEnum.ONBOARDING} />}
          />
          <Route
            path="/onboarding/:id"
            element={<RouteElement component={GenericEdit} entity={entitiesEnum.ONBOARDING} />}
          />
        </>
      )}
    </Routes>
  );
};
