import { Grid, Modal, Typography } from '@mui/material';
import React from 'react';

import { MainButton } from '../../../buttons';
import { HSpacer } from '../../../h-spacer';
import { useStyles } from './action-modal.hooks';

export const ActionModal = ({
  onClose,
  onShow,
  actionKind,
  confirmRemove,
  confirmDisable,
  disablingAccount,
}) => {
  const classes = useStyles();

  const onConfirm = action => {
    if (action === 'remove') confirmRemove();
    if (action === 'disable') confirmDisable();
  };

  const { active } = disablingAccount;

  return (
    <Modal
      aria-describedby="simple-modal-description"
      aria-labelledby="simple-modal-title"
      className={classes.modal}
      onClose={onClose}
      open={onShow}
    >
      <div className={classes.modalPaper}>
        {actionKind === 'close' && (
          <Typography>Do you want to close and suspend this account?</Typography>
        )}
        {actionKind === 'remove' && <Typography>Do you want to remove this item?</Typography>}
        {actionKind === 'disable' && (
          <Typography>Do you want to {active ? 'disable' : 'enable'} this account?</Typography>
        )}
        <HSpacer />
        <Grid container item justifyContent="flex-end" spacing={2} xs={12}>
          <Grid item xs={6}>
            <MainButton onClick={onClose} text="Cancel" design="secondary" />
          </Grid>
          <Grid item xs={6}>
            <MainButton onClick={() => onConfirm(actionKind)} text="Confirm" design="primary" />
          </Grid>
        </Grid>
      </div>
    </Modal>
  );
};
