import React from 'react';

import { useStyles } from './line-with-arrow.hooks';

export const LineWithArrow = () => {
  const classes = useStyles();

  return (
    <div className={classes.wrapper}>
      <div className={classes.line} />
      <div className={classes.triangle} />
      <div className={classes.line} />
    </div>
  );
};
