import React from 'react';

export const DateIcon = ({ width = '16px', height = '16px' }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 16 16">
    <path
      data-name="Path 23298"
      d="M15.6 3.6H14v-.8a.8.8 0 1 0-1.6 0v.8H7.6v-.8a.8.8 0 0 0-1.6 0v.8H4.4A2.4 2.4 0 0 0 2 6v9.6A2.4 2.4 0 0 0 4.4 18h11.2a2.4 2.4 0 0 0 2.4-2.4V6a2.4 2.4 0 0 0-2.4-2.4zm.8 12a.8.8 0 0 1-.8.8H4.4a.8.8 0 0 1-.8-.8V10h12.8zm0-7.2H3.6V6a.8.8 0 0 1 .8-.8H6V6a.8.8 0 0 0 1.6 0v-.8h4.8V6A.8.8 0 1 0 14 6v-.8h1.6a.8.8 0 0 1 .8.8z"
      transform="translate(-2 -2)"
      fill="#19365e"
    />
  </svg>
);
