import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(theme => ({
  root: {
    paddingLeft: '8rem',
    paddingRight: '8rem',
    paddingBottom: '12.5rem',
    flexBasis: '70rem',
    fontFamily: theme.typography.default,
    color: theme.palette.icons.nav,
    fontSize: '18px',
    '& header': {
      fontSize: '24px',
      fontWeight: '500',
    },
  },
  subHeader: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '8px',
    fontWeight: '500',
    fontSize: '18px',
    marginTop: '8px',
    marginBottom: '24px',
  },
  emailContainer: {
    marginTop: '24px',
    '& > p': {
      fontWeight: '500',
      marginBottom: '8px',
    },
  },
  signInButton: {
    margin: '1.6rem 0',
    fontSize: '18px',
    backgroundColor: '#c6ffef',
    color: theme.palette.icons.nav,
    borderRadius: '8px',
    boxShadow: 'none',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      opacity: '0.85',
    },
  },
}));
