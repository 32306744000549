import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(theme => ({
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginRight: '16px',
  },
  label: {
    display: 'flex',
    flexDirection: 'row',
    gap: '8px',
  },
  mainContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  statusContainer: {
    height: '300px',
    width: '396px',
    position: 'relative',
  },
  dashedLine: {
    position: 'absolute',
    top: '151px',
    left: '19px',
  },
  dotContainer: {
    position: 'absolute',
    top: '155px',
    left: '97px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '90px',
  },
  targetStatusContainer: {
    position: 'absolute',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '60px',
    top: '100px',
    left: '81px',
  },
  textContainer: {
    position: 'absolute',
    display: 'flex',
    flexDirection: 'row',
    gap: '38px',
    alignItems: 'center',
    top: '178px',
    left: '67px',
    fontSize: '16px',
    color: '#a5a6ae',
  },
  highlightText: {
    fontWeight: 'bold',
    color: theme.palette.icons.nav,
  },
}));
