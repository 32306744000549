import React from 'react';

export const BigSaveIcon = ({ width = '32', height = '32' }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 32 32.001">
    <path
      data-name="Path 20061"
      d="M23.429.354A1.227 1.227 0 0 0 22.565 0H4.5A4.528 4.528 0 0 0 0 4.5v23A4.521 4.521 0 0 0 4.5 32h23a4.523 4.523 0 0 0 4.5-4.5V9.431a1.217 1.217 0 0 0-.356-.861zm-.438 29.209H9.006v-10.7H23zm6.571-2.063a2.078 2.078 0 0 1-2.07 2.068h-2.068V17.645a1.22 1.22 0 0 0-1.218-1.218H7.782a1.222 1.222 0 0 0-1.211 1.218v11.922H4.5A2.078 2.078 0 0 1 2.434 27.5v-23A2.077 2.077 0 0 1 4.5 2.433h2.071V8.7a1.223 1.223 0 0 0 1.213 1.216h13.138a1.218 1.218 0 0 0-.005-2.435H9.006V2.435h13.062l.049.05 7.451 7.45z"
      transform="translate(-.001)"
      style={{ fill: '#19365e' }}
    />
  </svg>
);
