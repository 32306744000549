import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(theme => ({
  root: {
    flexBasis: '752px',
    color: theme.palette.icons.nav,
    fontFamily: theme.typography.default,
    fontSize: '18px',
  },
  titleRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  resendCodeBtn: {
    width: '144px',
    height: '32px',
    border: 'none',
    borderRadius: '16px',
    backgroundColor: '#c6ffef',
    cursor: 'pointer',
    fontSize: '16px',
    color: theme.palette.icons.nav,
    fontWeight: '500',
    fontFamily: theme.typography.default,
    textTransform: 'none',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      opacity: '0.85',
    },
  },
  otpContainer: {
    marginTop: '8px',
  },
  inputStyle: {
    width: '64px !important',
    height: '48px',
    border: '1px solid #e6e6e6',
    fontSize: '20px',
    fontWeight: '500',
    borderRadius: '8px',
    marginRight: '32px',
  },
  resetPwBtn: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '52px',
    fontSize: '18px',
    backgroundColor: '#c6ffef',
    color: theme.palette.icons.nav,
    borderRadius: '8px',
    boxShadow: 'none',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      opacity: '0.85',
    },
  },
}));
