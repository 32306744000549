import Auth from '@aws-amplify/auth';
import AWS from 'aws-sdk';

import { awsConfig } from '../config/aws.config';
import { graphqlApiDecorator, requestDecorator } from '../decorators';
import { mutations } from '../graphql';
import { response } from '.';

const getUserWithGroup = user => {
  const group =
    user?.signInUserSession?.accessToken?.payload &&
    user?.signInUserSession?.accessToken?.payload['cognito:groups']
      ? user?.signInUserSession?.accessToken?.payload['cognito:groups']
      : '';

  return { ...user, group };
};

const signIn = async (username, password) => {
  const user = await requestDecorator(Auth.signIn(username, password), { shouldReturnData: true });

  const accessToken = user?.signInUserSession?.accessToken?.jwtToken;

  if (!user?.success && user?.message === 'NotAuthorizedException')
    return response(false, 'NotAuthorizedException', user.data, accessToken);

  if (!user?.success && user?.message === 'UserNotFoundException')
    return response(false, 'UserNotFoundException', user.data, accessToken);

  return response(
    true,
    '',
    user.challengeName === 'NEW_PASSWORD_REQUIRED' ? user : getUserWithGroup(user),
    accessToken
  );
};

const setNewPassword = async (user, password) =>
  requestDecorator(Auth.completeNewPassword(user, password));

const getCurrentUser = async () => {
  const user = await requestDecorator(Auth.currentAuthenticatedUser(), { shouldReturnData: true });

  const accessToken = user?.signInUserSession?.accessToken?.jwtToken;

  return response(true, '', getUserWithGroup(user), accessToken);
};

const signOut = async () => requestDecorator(Auth.signOut());

const getCredentials = async () => {
  const session = await requestDecorator(Auth.currentSession(), { shouldReturnData: true });
  const { amplify } = awsConfig;
  const loginPool = `cognito-idp.${amplify.Auth.region}.amazonaws.com/${amplify.Auth.userPoolId}`;

  return new AWS.CognitoIdentityCredentials(
    {
      IdentityPoolId: amplify.Auth.identityPoolId,
      Logins: {
        [loginPool]: session.getIdToken().getJwtToken(),
      },
    },
    { region: amplify.Auth.region }
  );
};

const resetUserPassword = async userId =>
  graphqlApiDecorator(mutations, 'adminResetPassword', { userId });

const sendConfirmationCodeToUser = async email => Auth.forgotPassword(email);

const confirmCodeWithPw = async (email, code, password) =>
  Auth.forgotPasswordSubmit(email, code, password);

export {
  confirmCodeWithPw,
  getCredentials,
  getCurrentUser,
  resetUserPassword,
  sendConfirmationCodeToUser,
  setNewPassword,
  signIn,
  signOut,
};
