import React from 'react';

import { PairsItem } from './pairs-item';

export const PairsTabPanel = ({ pairsList, index, value, control, errors, watch }) => (
  <div hidden={value !== index}>
    {pairsList.map(pair => (
      <PairsItem pair={pair} key={pair.id} control={control} errors={errors} watch={watch} />
    ))}
  </div>
);
