import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(theme => ({
  transactionFilterModalContainer: {
    backgroundColor: 'white',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '480px',
    height: 'auto',
    borderRadius: '15px',
    zIndex: 5,
    fontFamily: theme.typography.default,
    color: theme.palette.icons.nav,
    padding: '21px',
  },
  modalTitleContainer: {
    textAlign: 'center',
    fontSize: '20px',
    fontFamily: theme.typography.default,
    fontWeight: 'bold',
    marginBottom: '1.5em',
  },
  filterRowContainer: {
    fontSize: '13px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    gap: '1em',
    alignItems: 'center',
    margin: '15px 0',
    '& p': {
      color: '#a5a6ae',
    },
    '& div': {
      fontWeight: 'bold',
    },
    '& .transactionTilte': {
      width: '130px',
    },
  },
  filterRowDetailContainer: {
    fontSize: '13px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    gap: '1em',
    alignItems: 'center',
    margin: '15px 0',
    '& p': {
      color: '#a5a6ae',
    },
    '& div': {
      fontWeight: 'bold',
    },
  },

  resetBtn: {
    width: '120px',
    height: '30px',
    borderRadius: '16px',
    backgroundColor: '#c6ffef',
    fontWeight: '500',
    fontSize: '13px',
    textAlign: 'center',
    paddingTop: '8px',
    cursor: 'pointer',
    marginLeft: '30px',
  },

  disableResetBtn: {
    width: '120px',
    height: '30px',
    borderRadius: '16px',
    backgroundColor: 'lightgray',
    fontWeight: '500',
    fontSize: '13px',
    textAlign: 'center',
    paddingTop: '8px',
    cursor: 'no-drop',
    marginLeft: '30px',
  },

  transactionInputContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '10px',
    '& aside': {
      fontWeight: '500',
      fontSize: '14px',
    },
    '& p': {
      fontWeight: 'bold',
      fontSize: "15px"
    },
  },
  statusGap: {
    gap: '30px',
  },
  highlightColor: {
    color: `${theme.palette.icons.nav} !important`,
  },
  inputContainer: {
    border: `1px solid ${theme.palette.transactions.background}`,
    borderRadius: '14px',
    width: '120px',
    height: '28px',
    fontSize: '14px',
    padding: '5px',
    color: theme.palette.icons.nav,
    fontFamily: theme.typography.default,
    textAlign: 'center',
  },
  checkbox: {
    accentColor: '#1fe3ac',
    height: '24px',
    width: '24px',
    borderRadius: '4px',
  },
  filterItBtn: {
    backgroundColor: theme.palette.icons.nav,
    width: '432px',
    height: '40px',
    borderRadius: '8px',
    color: 'white',
    border: 'none',
    position: 'relative',
    cursor: 'pointer',
    marginTop: '20px',
  },
  cancelBtn: {
    backgroundColor: theme.palette.fail.color,
    width: '432px',
    height: '40px',
    borderRadius: '8px',
    color: 'white',
    border: 'none',
    position: 'relative',
    cursor: 'pointer',
    marginTop: '20px',
  },
  btnBox: {
    display: 'flex',
    gap: '20px',
    marginTop: '20px',
  },
  filterItBtnDisabled: {
    backgroundColor: '#d9e1e7',
    cursor: 'auto',
  },
  filterIconSubmit: {
    position: 'absolute',
    left: '16px',
    top: '10px',
  },
  filterItLabel: {
    width: '50%',
    margin: 'auto',
  },
  statusNotClicked: {
    height: '24px',
    width: '88px',
    borderRadius: '0 12px 12px 12px',
    background: theme.palette.transactions.background,
    color: '#a5a6ae',
    fontSize: '12px',
    textAlign: 'center',
    padding: '5px',
    cursor: 'pointer',
  },
  statusCompletedSelected: {
    background: '#c6ffef',
    color: '#23c3a4',
  },
  statusFailedSelected: {
    background: '#ffccd4',
    color: '#fc3333',
  },
  statusProcessingSelected: {
    background: '#e5f0ff',
    color: '#0069f8',
  },
}));
