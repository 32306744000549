import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(theme => ({
  tableCell: {
    width: '21rem',
    height: '4rem',
    border: 'none',
    padding: '1rem',
    backgroundColor: theme.palette.white,
  },
  input: {
    border: `0.1rem solid ${theme.palette.secondary.dark}`,
    borderRadius: '0.4rem',
    padding: '0.5rem',
    width: '100%',
  },
}));
