import React from 'react';

export const PersonalIcon = ({ active = true }) => {
  if (active)
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40">
        <g data-name="Group 9003">
          <g
            data-name="Rectangle 16974"
            style={{ fill: '#9ef3db', stroke: '#19365e', strokeWidth: '2px' }}
          >
            <rect width="40" height="40" rx="8" style={{ stroke: 'none' }} />
            <rect x="1" y="1" width="38" height="38" rx="7" style={{ fill: 'none' }} />
          </g>
          <g data-name="Group 7126">
            <g data-name="Group 6098" transform="translate(11.429 9.286)">
              <ellipse
                data-name="Ellipse 1320"
                cx="4.697"
                cy="4.696"
                rx="4.697"
                ry="4.696"
                transform="translate(4.13 .821)"
                style={{ fill: '#fff' }}
              />
              <path
                data-name="Ellipse 1320 - Outline"
                d="M4.368-1.15A5.518 5.518 0 1 1-1.15 4.368 5.524 5.524 0 0 1 4.368-1.15zm0 9.393A3.875 3.875 0 1 0 .493 4.368a3.879 3.879 0 0 0 3.875 3.875z"
                transform="translate(4.459 1.15)"
                style={{ fill: '#19365e' }}
              />
              <path
                data-name="Path 8714"
                d="M-254.389-435.994a2.887 2.887 0 0 1 .232-1.146 4.2 4.2 0 0 1 2.978-1.913 15.941 15.941 0 0 1 2.475-.389 23.7 23.7 0 0 1 4.63 0 16.126 16.126 0 0 1 2.475.389c1.131.26 2.494.778 2.978 1.913a2.97 2.97 0 0 1 0 2.3c-.483 1.135-1.847 1.654-2.978 1.9a14.993 14.993 0 0 1-2.475.4 24.376 24.376 0 0 1-3.77.065 3.838 3.838 0 0 1-.86-.065 14.734 14.734 0 0 1-2.465-.4 4.022 4.022 0 0 1-2.987-1.9 2.962 2.962 0 0 1-.233-1.154z"
                transform="translate(255.21 453.246)"
                style={{ fill: '#fff' }}
              />
              <path
                data-name="Path 8714 - Outline"
                d="M-246.718-440.706c.794 0 1.6.039 2.387.116a17.008 17.008 0 0 1 2.589.408c1.86.427 3.053 1.232 3.547 2.391a3.784 3.784 0 0 1 0 2.947c-.5 1.17-1.692 1.971-3.546 2.381a15.869 15.869 0 0 1-2.593.418 25.49 25.49 0 0 1-3.865.068 4.683 4.683 0 0 1-.953-.072 15.611 15.611 0 0 1-2.535-.415c-1.849-.405-3.045-1.2-3.553-2.375v-.007a3.757 3.757 0 0 1-.3-1.478 3.731 3.731 0 0 1 .3-1.47v-.011c.678-1.515 2.507-2.14 3.54-2.378a16.821 16.821 0 0 1 2.591-.408 25.207 25.207 0 0 1 2.391-.115zm.01 7.131c.74 0 1.489-.035 2.228-.105h.015a14.222 14.222 0 0 0 2.34-.378l.022-.005c.9-.2 2.05-.606 2.4-1.423a2.161 2.161 0 0 0 0-1.66c-.344-.808-1.5-1.226-2.405-1.434h-.006a15.359 15.359 0 0 0-2.349-.37h-.016a22.859 22.859 0 0 0-4.47 0h-.017a15.17 15.17 0 0 0-2.348.369h-.007c-.59.135-2 .547-2.409 1.443a2.075 2.075 0 0 0-.163.814 2.153 2.153 0 0 0 .166.832 3.354 3.354 0 0 0 2.407 1.424l.025.006a13.958 13.958 0 0 0 2.328.378.824.824 0 0 1 .118.018 3.18 3.18 0 0 0 .68.045h.05c.465.032.941.046 1.411.046z"
                transform="translate(255.539 453.575)"
                style={{ fill: '#19365e' }}
              />
            </g>
          </g>
        </g>
      </svg>
    );

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 80 80">
      <g data-name="Group 8410" transform="translate(-504 -440)">
        <rect
          data-name="Rectangle 16974"
          width="80"
          height="80"
          rx="16"
          transform="translate(504 440)"
          style={{ fill: '#f2f2f4' }}
        />
        <g data-name="Group 7126">
          <g data-name="Group 6098" transform="translate(533 466)">
            <circle
              data-name="Ellipse 1320"
              cx="6.575"
              cy="6.575"
              transform="translate(4.632)"
              style={{
                fill: '#fff',
                stroke: '#a5a6ae',
                strokeLinecap: 'round',
                strokeLinejoin: 'round',
                strokeWidth: '2.3px',
              }}
              r="6.575"
            />
            <path
              data-name="Path 8714"
              d="M-254.389-434.569a4.042 4.042 0 0 1 .325-1.6c.677-1.514 2.585-2.315 4.169-2.678a22.318 22.318 0 0 1 3.465-.545 33.18 33.18 0 0 1 6.482 0 22.577 22.577 0 0 1 3.465.545c1.583.364 3.492 1.09 4.169 2.678a4.158 4.158 0 0 1 0 3.225c-.677 1.589-2.585 2.316-4.169 2.664a20.99 20.99 0 0 1-3.465.56 34.127 34.127 0 0 1-5.278.091 5.372 5.372 0 0 1-1.2-.091 20.629 20.629 0 0 1-3.451-.56c-1.6-.348-3.492-1.075-4.182-2.664a4.146 4.146 0 0 1-.33-1.625z"
              transform="translate(254.389 457.573)"
              style={{
                fill: '#fff',
                stroke: '#a5a6ae',
                strokeLinecap: 'round',
                strokeLinejoin: 'round',
                strokeWidth: '2.3px',
              }}
            />
          </g>
        </g>
      </g>
    </svg>
  );
};
