const getCompanyHouseInfo = registerNumber => {
  if (!registerNumber) {
    return {
      registerNumber: null,
    };
  }

  const companyLink = `https://find-and-update.company-information.service.gov.uk/company/${registerNumber}`;

  return {
    companyLink,
  };
};

export { getCompanyHouseInfo };
