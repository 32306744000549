import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
  active: {
    borderRadius: '0.4rem',
    padding: '1rem',
    boxShadow: 'none',
    margin: '1em 0',
  },
  closed: {
    borderRadius: '0.4rem',
    padding: '1rem',
    boxShadow: 'none',
    margin: '1rem 0',
    height: '6.4rem',
  },
  cardHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& .MuiSvgIcon-root': {
      width: '2.4rem',
      height: '2.4rem',
    },
  },
}));
