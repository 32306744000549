import { Typography } from '@mui/material';
import React from 'react';

import { useStyles } from './block-title-value.hooks';

export const BlockTitleValue = ({ title, value }) => {
  const classes = useStyles();

  return (
    <div className={classes.blockContent}>
      <Typography className={classes.blockTitle}>{title}</Typography>
      <div className={classes.blockValue}>{value}</div>
    </div>
  );
};
