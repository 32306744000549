import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(theme => ({
  tableContainer: {
    backgroundColor: theme.palette.white,
    fontSize: '18px',
    fontFamily: theme.typography.default,
    color: theme.palette.icons.nav,
    borderRadius: '0 16px 16px 16px',
    boxShadow: '0 2px 16px 0 rgba(0, 0, 0, 0.04)',
    width: '100%',
    borderCollapse: 'collapse',
    padding: '0 16px',
    '& td': {
      padding: '16px 16px',
    },
  },
  tableHead: {
    borderBottom: '1px solid #aeabab',
    paddingTop: '16px',
  },
  tableFields: {
    '& th': {
      textAlign: 'left',
      padding: '16px 16px',
    },
    '& input[type="checkbox"]': {
      width: '24px',
      height: '24px',
      cursor: 'pointer',
    },
  },
  idHeader: {
    display: 'flex',
    flexDirection: 'row',
    gap: '16px',
  },
  tableRow: {
    height: '96px',
    fontSize: '14px',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#e9fff9',
    },
  },
  firstCol: {
    '& > div': {
      display: 'flex',
      flexDirection: 'row',
      gap: '16px',
      alignItems: 'center',
      marginBottom: '8px',
    },
    '& input[type="checkbox"]': {
      width: '24px',
      height: '24px',
      borderRadius: '16px',
      cursor: 'pointer',
      border: '1px solid #b5b5b5',
    },
  },
  currencyId: {
    fontSize: '16px',
    fontWeight: '500',
  },
  statusData: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '96px',
  },
  rightArrow: {
    cursor: 'pointer',
  },
}));
