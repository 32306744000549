import { getBackofficeConfig } from '../config/backoffice.config';
import * as queries from '../graphql/queries';
import { capitalizeString, compareByValue, getEntityFromSchema } from '../utils';

const mappers = {
  fieldType: {
    ENUM: 'enumSelect',
    OBJECT: 'entitySelect',
    ID: 'enumSelect',
    Int: 'int',
    AWSDate: 'date',
    AWSDateTime: 'timestamp',
    LIST_FREE: 'listFree',
    LIST_IMG: 'imgUploadList',
  },
  fieldName: {
    name: 'Name',
    rates: 'Rates',
    createdAt: 'Created',
    updatedAt: 'Updated',
    richTextDesc: 'Description',
    type: 'Type',
    country: 'Country',
    includes: 'Includes',
    notIncludes: 'Not includes',
    iva: 'IVA (%)',
  },
};

const isVideoField = fieldName => fieldName.slice(-5) === 'Video';

const isImageField = fieldName => fieldName.slice(-5) === 'Image';

const isFileField = fieldName => fieldName.slice(-4) === 'File';

const getType = ({ type: fieldType, name: fieldName, relatedEntity }) => {
  if (fieldType === 'String' && isVideoField(fieldName)) {
    return 'videoUpload';
  }

  if (fieldType === 'String' && isImageField(fieldName)) {
    return 'imageUpload';
  }

  if (fieldType === 'String' && isFileField(fieldName)) {
    return 'fileUpload';
  }

  if (fieldType === 'LIST') {
    return queries[`get${relatedEntity}`] ? 'entitySelectMultiple' : 'subObjectList';
  }

  return mappers.fieldType[fieldType] || fieldType.toLowerCase();
};

const getFieldType = (fieldType, fieldName) => {
  if (fieldType.kind === 'NON_NULL') {
    return getFieldType(fieldType.ofType);
  }

  if (fieldType.kind === 'SCALAR') {
    return fieldType.name;
  }

  if (fieldType.kind === 'LIST' && isImageField(fieldName)) {
    return 'LIST_IMG';
  }

  if (fieldType.kind === 'LIST' && fieldType.ofType.name === 'String') {
    return 'LIST_FREE';
  }

  return fieldType.kind;
};

const getFieldName = (fieldType, name) => {
  if (fieldType.kind === 'NON_NULL' && fieldType.ofType.name.indexOf('AWS') !== 0) {
    return getFieldName(fieldType.ofType, name);
  }

  if (fieldType.kind === 'SCALAR' && fieldType.name === 'ID') {
    return 'id';
  }

  return name;
};

const getEnumOptions = optionsName => {
  const optionsField = getEntityFromSchema(optionsName);

  return optionsField.enumValues
    .map(({ name: value }) => {
      const label =
        getBackofficeConfig().labelsOverride[value] ||
        value.split('_').map(capitalizeString).join(' ');

      return { value, label };
    })
    .sort(compareByValue('label'));
};

const getOptionsName = fieldType => {
  if (fieldType.kind === 'NON_NULL') return getOptionsName(fieldType.ofType);

  return fieldType.name;
};

const getRelatedEntity = (fieldType, field) => {
  if (field.type.kind === 'NON_NULL') {
    return getRelatedEntity(fieldType, { type: field.type.ofType });
  }

  if (fieldType === 'OBJECT') {
    return field.type.name;
  }

  if (fieldType === 'LIST') {
    return field.type.ofType.name;
  }

  return null;
};

const getFieldLabel = fieldName => {
  const fieldNameCleaned = fieldName.replace(/(Lang|File|Img|URL|Image)$/, '');

  return (
    getBackofficeConfig().labelsOverride[fieldName] ||
    mappers.fieldName[fieldNameCleaned] ||
    fieldNameCleaned
  );
};

const fieldsMapper = field => {
  const fieldType = getFieldType(field.type, field.name);
  const fieldName = getFieldName(field.type, field.name);
  const options = fieldType === 'ENUM' ? getEnumOptions(getOptionsName(field.type)) : [];
  const relatedEntity = getRelatedEntity(fieldType, field);
  const type = getType({ type: fieldType, name: fieldName, relatedEntity });
  const label = getFieldLabel(fieldName);

  return {
    type,
    label,
    options,
    relatedEntity,
    name: fieldName,
    required: fieldType === 'NON_NULL',
  };
};

export { fieldsMapper };
