import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import React from 'react';

import { WarningIcon } from '../icons';
import { useStyles } from './modal.hooks';

export const WarningModal = ({ open, onClose, message }) => {
  const classes = useStyles();

  return (
    <Modal open={open} onClose={onClose}>
      <Box>
        <div className={classes.modalBox}>
          <div className={classes.iconContainer}>
            <WarningIcon />
          </div>
          <header>Warning</header>
          <p>{message}</p>
          <section className={classes.cancelDelSection}>
            <div className={classes.cancel} onClick={onClose}>
              OK
            </div>
          </section>
        </div>
      </Box>
    </Modal>
  );
};
