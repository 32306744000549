import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(theme => ({
  root: {
    '& .MuiGrid-item': {
      marginBottom: '1.5rem',
      padding: '0 1rem',
    },
  },
  title: {
    fontSize: '2rem',
    color: theme.palette.primary.nav,
    textAlign: 'center',
    marginBottom: '1rem',
  },
  subTitleWrapper: {
    display: 'flex',
  },
  subTitle: {
    fontSize: '1.5rem',
    flexBasis: '50%',
    textAlign: 'center',
  },
}));
