import React from 'react';

export const PairsIcon = ({ className, color }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 33 33">
    {/* prettier-ignore */}
    <g data-name="Group 7282">
      <path data-name="Union 102"
        d="M0 16a16 16 0 1 1 16 16A16 16 0 0 1 0 16zm1.74 0A14.26 14.26 0 1 0 16 1.74 14.274 14.274 0 0 0 1.74 16zm11.36 8.774A1827.24 1827.24 0 0 0 7.854 20.4c-.03-.006-.048.006-.048.022v1.764a.8.8 0 0 1-.843.849h-.037a.875.875 0 0 1-.872-.815v-3.788a.9.9 0 0 1 .9-.826H10.86a.847.847 0 0 1 .893.787.889.889 0 0 1-.884.835h-1.6l-.029.03 1.593 1.34c1.695 1.411 3.488 2.906 3.67 3.061a2.345 2.345 0 0 0 1.6.606 3.354 3.354 0 0 0 1.739-.824l6.7-5.639a.871.871 0 0 1 .53-.2.874.874 0 0 1 .874.872.853.853 0 0 1-.185.52.817.817 0 0 1-.094.119s-.085.076-6.465 5.454a4.845 4.845 0 0 1-3.012 1.367h-.168a4.238 4.238 0 0 1-2.922-1.16zm11.876-10.387h-3.834a.856.856 0 0 1-.893-.786.892.892 0 0 1 .906-.841h1.58l.03-.031-1.593-1.335L17.5 8.333a2.27 2.27 0 0 0-1.548-.609H15.9a3.355 3.355 0 0 0-1.739.825l-6.7 5.639a.849.849 0 0 1-.528.194A.852.852 0 0 1 6.241 13a1.076 1.076 0 0 1 .094-.119c.013-.013 3.523-2.974 6.464-5.454a4.859 4.859 0 0 1 3.012-1.367A4.234 4.234 0 0 1 18.9 7.223c.224.19 3.4 2.842 5.246 4.377l.048-.021V9.812a.8.8 0 0 1 .84-.849h.038a.874.874 0 0 1 .874.815v3.79a.909.909 0 0 1-.9.824zm.063-5.29V9.09z"
        transform="translate(.5 .5)"
        fill={color}
        className={className}
        />
      <path data-name="Rectangle 17105"
        transform="translate(.5 .5)"
        style={{ fill: 'none'}}
        d="M0 0h32v32H0z"/>
    </g>
  </svg>
);
