import { Grid, Typography } from '@mui/material';
import React, { useState } from 'react';

import { fieldNamesEnum, fieldValuesEnum, profitOptions } from '../../../constants';
import { FormSelectField, FormTextField } from '../../form-element';
import { FlagIcon } from '../../icons';
import { useStyles } from './pairs-item.hook';
import { calculateRate, roundNumber, validationRule } from './pairs-item.utils';

export const PairsItem = ({ pair, control, errors, watch }) => {
  const defaultProfitValue = pair.profitMargin.percentageValue
    ? fieldValuesEnum.PERCENTAGE
    : fieldValuesEnum.ABSOLUTE;

  const defaultMargin = pair.profitMargin.percentageValue || pair.profitMargin.absoluteValue;

  const [profitType, setProfitType] = useState(defaultProfitValue);
  const [margin, setMargin] = useState(defaultMargin);
  const classes = useStyles();

  const sellRate = calculateRate(pair.flatRate, margin, profitType);

  const fieldProps = {
    control,
    errors,
    rules: validationRule,
  };

  const profitTypeWatch = watch(`${pair.id}-${fieldNamesEnum.PROFITMARGINTYPE}`);
  const marginWatch = watch(`${pair.id}-${fieldNamesEnum.PROFITMARGINVALUE}`);

  React.useEffect(() => {
    setProfitType(profitTypeWatch);
  }, [profitTypeWatch]);

  React.useEffect(() => {
    setMargin(marginWatch || defaultMargin);
  }, [marginWatch]);

  return (
    <Grid container spacing={2} key={pair.id} className={classes.line}>
      <Grid item xs={1.5} className={classes.titleWrapper}>
        <Typography className={classes.title}>{pair.pairToId}</Typography>
        <FlagIcon name={pair.pairToId} size="4.5rem" />
      </Grid>
      <Grid item xs={2}>
        <Typography className={classes.text}>{roundNumber(pair.flatRate)}</Typography>
      </Grid>
      <Grid item xs={3.5} className={classes.profit}>
        <FormSelectField
          name={`${pair.id}-${fieldNamesEnum.PROFITMARGINTYPE}`}
          options={profitOptions}
          control={control}
          defaultValue={defaultProfitValue}
        />
        <FormTextField
          name={`${pair.id}-${fieldNamesEnum.PROFITMARGINVALUE}`}
          {...fieldProps}
          defaultValue={margin}
        />
      </Grid>
      <Grid item xs={2}>
        <Typography className={classes.text}>{roundNumber(sellRate) || 0}</Typography>
      </Grid>
      <Grid item xs={3} className={classes.fee}>
        <FormTextField
          name={`${pair.id}-${fieldNamesEnum.FEEPERCENTAGE}`}
          {...fieldProps}
          defaultValue={pair.fee.percentage}
        />
        <FormTextField
          name={`${pair.id}-${fieldNamesEnum.FEEABSOLUTE}`}
          {...fieldProps}
          defaultValue={pair.fee.absolute}
        />
      </Grid>
    </Grid>
  );
};
