import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import React, { useState } from 'react';

import { ExclamationIcon } from '../../icons/multi-currency';
import { useStyles } from './modal.hooks';

export const ConfirmationModal = ({ open, onClose, title, extraFntionality, children, isDisabled }) => {
  const classes = useStyles();

  return (
    <Modal open={open} onClose={onClose}>
      <Box>
        <div className={classes.modal}>
          <div className={classes.innerModal}>
            <div className={classes.topOfModal}>
              <div className={classes.iconPlaceholder}>
                <ExclamationIcon />
              </div>
            </div>
            <header>{title}</header>
            <div className={classes.modalBody}>{children}</div>
            <section>
              <p>Do you really want to send this amount?</p>
              <div className={classes.modalFooter}>
                <button className={classes.modalButton} onClick={onClose}>
                  Cancel
                </button>
                <button data-disabled={isDisabled} className={classes.modalButton} onClick={isDisabled? void 0 : extraFntionality}>
                  Confirm it
                </button>
              </div>
            </section>
          </div>
        </div>
      </Box>
    </Modal>
  );
};
