export const emailTemplateKixy = ({
  subject = '',
  mainTitle = '{{date}}',
  content = '',
  messageTo = 'Dear {{firstName}} {{lastName}}',
  closingMessage = 'Kind Regards',
  from = 'Kixy Team',
}) => `<section style="font-family:Helvetica;font-size:12px;font-style:normal;font-variant-caps:normal;font-weight:400;letter-spacing:normal;text-indent:0px;text-transform:none;white-space:normal;word-spacing:0px;text-decoration:none">
<table align="center" border="0" cellpadding="0" cellspacing="0" height="100%" width="100%" id="m_1500603058918672271bodyTable" style="border-collapse:collapse;height:855px;margin:0px;padding:0px">
<tbody>
<tr>
<td align="center" valign="top" id="m_1500603058918672271bodyCell" style="height:855px;margin:0px;padding:0px;width:949px">
<table border="0" cellpadding="0" cellspacing="0" width="100%" style="border-collapse:collapse">
<tbody>
<tr>
<td align="center" valign="top" id="m_1500603058918672271templateHeader" style="background-color:rgb(233,255,249); border-radius: 0px 0px 20px 20px; background-image:none;background-size:cover;border-top-width:0px;border-bottom-width:0px;padding-top:19px;padding-bottom:19px;background-position:center center;background-repeat:no-repeat no-repeat">
<table border="0" cellpadding="0" cellspacing="0" width="100%" style="border-collapse:collapse">
<tbody>
<tr>
<td valign="top" style="background-color:transparent;background-image:none;background-size:cover;border-top-width:0px;border-bottom-width:0px;padding-top:0px;padding-bottom:0px;background-position:center center;background-repeat:no-repeat no-repeat">
<table border="0" cellpadding="0" cellspacing="0" width="100%" style="border-collapse:collapse;min-width:100%">
<tbody>
<tr>
<td valign="top" style="padding:9px">
<table align="left" width="100%" border="0" cellpadding="0" cellspacing="0" style="border-collapse:collapse;min-width:100%">
<tbody>
<tr>
<td valign="top" style="padding:0px 9px;text-align:center">
<img align="center" alt="Kixy Logo" src="https://ci6.googleusercontent.com/proxy/9nHe836RpYLXbdXQHxSKaMl1auqbljQPzWa2SJsKE8QbMJCVFhyy4JK5NAHpqUgObuEUPXOGx_8TfCFoSWq8VENlK85CUpFRck8JxT44CVunRPrRfXQEZB6axG080AkmcrNVIzqOTMDC4jDfqiosxMozq8xmHy2I8w1jaC7PXcUduepN973-0xvpcsmPPPgos5q-79FqIKDtVRXwkmiGjiIo1rx166vrt9ObT2eupEkluecVCjt-7sXmSOUc-uQLTOUWN4M5jOEEf0k1LnnTQkaIHff5BKrKC7m2hIp5GRVt-iy7JW1qWlcCc_5shdSQ4S6MHApTb6AT31oS5i1vKspAN136YbNwtnLA_m6DoqaQpg2DQBKGooJHfm7fk10Xp1pzY46kGsjc8h8=s0-d-e1-ft#https://linkprotect.cudasvc.com/url?a=https%3a%2f%2fmcusercontent.com%2f8b04ba1ff1e04c028636623c7%2fimages%2f8821c1db-d037-6fc6-9336-83c158e48392.png&amp;c=E,1,OHCzwqEKvsFoUUk3hqDYC9ALe5DKfbUZhV65sfBNsjYs_JpaS87VDeQpoDi4HI3Hqi32TQwc9mucadeSUYM6yTp6mNqAR_UkXxWyoDe6QA6juNzGgA,,&amp;typo=1" style="border:0px;height:40px;width:80px;outline:none;text-decoration:none;vertical-align:bottom;max-width:162px;padding-bottom:0px;display:inline!important" class="CToWUd" data-bit="iit"></td>
</tr>
</tbody>
</table>
</td>
</tr>
</tbody>
</table>
</td>
</tr>
</tbody>
</table>
</td>
</tr>
<tr>
<td align="center" valign="top" id="m_1500603058918672271templateBody" style="background-color:rgb(255,255,255);background-image:none;background-size:cover;border-top-width:0px;border-bottom-width:0px;padding-top:36px;padding-bottom:45px;background-position:center center;background-repeat:no-repeat no-repeat">
<table align="center" border="0" cellpadding="0" cellspacing="0" width="100%" style="border-collapse:collapse;">
<tbody>
<tr>
<td valign="top" style="background-color:transparent;background-image:none;background-size:cover;border-top-width:0px;border-bottom-width:0px;padding-top:0px;padding-bottom:0px;background-position:center center;background-repeat:no-repeat no-repeat">
<table border="0" cellpadding="0" cellspacing="0" width="100%" style="border-collapse:collapse;min-width:100%">
<tbody>
<tr>
<td valign="top" style="padding-top:9px">
<table align="left" border="0" cellpadding="0" cellspacing="0" width="100%" style="border-collapse:collapse;max-width:100%;min-width:100%">
<tbody>
<tr>
<td valign="top" style="word-break:break-word;color:rgb(117,117,117);font-family:Helvetica;font-size:16px;line-height:24px;text-align:left;padding:0px 18px 9px">
<p style="color: #19365e;font-family: Arial">${mainTitle}</p>
<h2 style="color: #19365e;font-family: Arial">${subject}</h2>
<p style="color: #19365e;font-family: Arial;font-size: 14px">${messageTo}</p>
<p style="color: #19365e;font-family: Arial;font-size: 14px">${content}</p>
<p style="color: #19365e;font-family: Arial;font-size: 14px; margin-top: 24px">${closingMessage}</p>
<strong style="color: #19365e;font-family: Arial;font-size: 14px">${from}</strong>

</tr>
</tbody>
</table>
</td>
</tr>
</tbody>
</table>
</td>
</tr>
</tbody>
</table>
</td>
</tr>
<tr>
<td align="center" valign="top" id="m_1500603058918672271templateFooter" style="background-color:rgb(233,255,249); border-radius: 20px 20px 0px 0px; background-image:none;background-size:cover;border-top-width:0px;border-bottom-width:0px;padding-top:5px;padding-bottom:5px;background-position:center center;background-repeat:no-repeat no-repeat">
<table align="center" border="0" cellpadding="0" cellspacing="0" width="100%" style="border-collapse:collapse;max-width:600px!important;width:600px!important">
<tbody>
<tr>
<td valign="top" style="background-color:transparent;background-image:none;background-size:cover;border-top-width:0px;border-bottom-width:0px;padding-top:0px;padding-bottom:0px;background-position:center center;background-repeat:no-repeat no-repeat">
<table border="0" cellpadding="0" cellspacing="0" width="100%" style="border-collapse:collapse;min-width:100%">
<tbody>
<tr>
<td valign="top" style="padding-top:9px">
<table align="left" border="0" cellpadding="0" cellspacing="0" width="100%" style="border-collapse:collapse;max-width:100%;min-width:100%">
<tbody>
<tr>
<td valign="top" style="word-break:break-word;color:rgb(52,58,97);font-family:Helvetica;font-size:11px;line-height:16.5px;text-align:center;padding:0px 18px 9px">
<span style="font-family:arial,&quot;helvetica neue&quot;,helvetica,sans-serif">
<a href="https://us21.mailchimp.com/mctx/clicks?url=https%3A%2F%2Finstagram.com%2Fkixy.official%3Figshid%3DYmMyMTA2M2Y%3D&amp;xid=5d426e0ac3&amp;uid=186287446&amp;iid=d29f4e2666&amp;pool=cts&amp;v=2&amp;c=1674138234&amp;h=db97d58bfced3ed61997add7d492616c39f99032928791023dea5d8b96069461" style="color:rgb(255,255,255);font-weight:normal;text-decoration:none" target="_blank" data-saferedirecturl="https://www.google.com/url?q=https://us21.mailchimp.com/mctx/clicks?url%3Dhttps%253A%252F%252Finstagram.com%252Fkixy.official%253Figshid%253DYmMyMTA2M2Y%253D%26xid%3D5d426e0ac3%26uid%3D186287446%26iid%3Dd29f4e2666%26pool%3Dcts%26v%3D2%26c%3D1674138234%26h%3Ddb97d58bfced3ed61997add7d492616c39f99032928791023dea5d8b96069461&amp;source=gmail&amp;ust=1674224688974000&amp;usg=AOvVaw2bMH_H1sieV9Qxz4o1H-qU">
<img alt="Kixy Instagram" height="24" src="https://ci4.googleusercontent.com/proxy/EWcu-1UyeW7C8_B9AVwzEOFXhVMcIHsQusq2NHZFcKZM9pTxfOjPVyBEY2pgoWpQ-a-x04oIpdIGugS3t1xIikmgGYuU_pNE4ug5zC2QHMS-2tDqo_Jlhd2IbO2F6nfCy0Lf8wtdS1cDLf2gcv5UuscNMbm5KCYq3qfeo1EBZ_84D8ViLqiYgztn0OJbFWPHrwX_wGQ1tAapV7XkfApN_OJ0HeZ78iCL4yN2gFolwqqv9JmdEkwgdGlqoaiJvqThVqRsXr8cAAb6YbPp2dycWDvl_8ney64PVR0q4N7zRVYOCyVQFYw4lJqmKBnfT33a7d-4Uxl22v9B6lZyzvBUZvp4yf3sOVf8xT1m4oaY5WzJ3bSPZ3LOf9UE7_VBG1hFovnDCTp4x9XHsgU=s0-d-e1-ft#https://linkprotect.cudasvc.com/url?a=https%3a%2f%2fmcusercontent.com%2f8b04ba1ff1e04c028636623c7%2fimages%2f9108d8da-5e8c-c550-7d5b-0a122e5f01bc.png&amp;c=E,1,Vit-IOmiqhKEKlYov1vVJwmyDZN21uaMgoxA3CP5k3_-TZTimveHTSPw813JVGdHZF39iHHk67vVvkJ7NjOlP0ki-zu0HUuhPgGGk8teOyGA56uUEA,,&amp;typo=1" width="24" style="border:0px;height:24px;outline:none;text-decoration:none;width:24px;margin:0px" class="CToWUd" data-bit="iit"></a>
&nbsp;&nbsp;<a href="https://us21.mailchimp.com/mctx/clicks?url=http%3A%2F%2Fwww.twitter.com&amp;xid=5d426e0ac3&amp;uid=186287446&amp;iid=d29f4e2666&amp;pool=cts&amp;v=2&amp;c=1674138234&amp;h=411448e630a8d675bed576099ad2ba3100fabbf321000631b62b40d9c0be23c9" style="color:rgb(255,255,255);font-weight:normal;text-decoration:none" target="_blank" data-saferedirecturl="https://www.google.com/url?q=https://us21.mailchimp.com/mctx/clicks?url%3Dhttp%253A%252F%252Fwww.twitter.com%26xid%3D5d426e0ac3%26uid%3D186287446%26iid%3Dd29f4e2666%26pool%3Dcts%26v%3D2%26c%3D1674138234%26h%3D411448e630a8d675bed576099ad2ba3100fabbf321000631b62b40d9c0be23c9&amp;source=gmail&amp;ust=1674224688974000&amp;usg=AOvVaw3tOQWUvZRMxgOwIATHZ1qF">
<img alt="Twitter" height="24" src="https://ci5.googleusercontent.com/proxy/pkhQ79w0fQWaxuMLyoc7dEKryZNbys0EG6wrHjZYojNU9U9opXmlXMljPJWgASEdL-LQPCGebrW_GU5TiI8F8ikDWkvcsa_AfNCw0WFD-QFIAZtWCb0QtYE63i8Lfa3_4DY9CvfUCDMZdHMv2cMgozDy3AQslmknIEdaPpBfRseFzodAhEr0FPD--fEOoSMDjtU2nlm3f31nEHPscTOfajDwujjBH6QPnEtkw1hHl2ADnFB2t_WyKMDdp-iyset4DbNxbRXiSVmt5CZvIn2b6ruk5upd9F6wqEgqq4kckTvJF1peVKtUIwVarrj6L3_Hy8Z5xkAnYCrxD4Hb57OB6UOsa80iGO518vR4yGjRaSfsepmoVLCC20p1XTJ5Pqy4fxBDDfHdCniaLTA7V0AaST7K0A=s0-d-e1-ft#https://linkprotect.cudasvc.com/url?a=https%3a%2f%2fmcusercontent.com%2f8b04ba1ff1e04c028636623c7%2fimages%2f803e7574-2d7b-8fbc-c05b-a111f4c99426.png&amp;c=E,1,HxDgRI-vf1Oj5b34T_2kcrtCP2MQeLNKuGpizSRURNcAITHasvQVboWzx1NC1LqSAnJKaRLQMw3lYOJCAAQV1xyOpBkrZiwA9b7QxPEwESybhQm_NhEWij86Pg,,&amp;typo=1" width="24" style="border:0px;height:24px;outline:none;text-decoration:none;width:24px;margin:0px" class="CToWUd" data-bit="iit"></a>
&nbsp;&nbsp;<a href="https://us21.mailchimp.com/mctx/clicks?url=https%3A%2F%2Fwww.linkedin.com%2Fcompany%2Fkixyofficial%2Fmycompany%2F&amp;xid=5d426e0ac3&amp;uid=186287446&amp;iid=d29f4e2666&amp;pool=cts&amp;v=2&amp;c=1674138234&amp;h=b4fbb5a0ddf5f6ab17e6c308dc13078adcd13d3ca1715010b30b2d65c566b353" style="color:rgb(255,255,255);font-weight:normal;text-decoration:none" target="_blank" data-saferedirecturl="https://www.google.com/url?q=https://us21.mailchimp.com/mctx/clicks?url%3Dhttps%253A%252F%252Fwww.linkedin.com%252Fcompany%252Fkixyofficial%252Fmycompany%252F%26xid%3D5d426e0ac3%26uid%3D186287446%26iid%3Dd29f4e2666%26pool%3Dcts%26v%3D2%26c%3D1674138234%26h%3Db4fbb5a0ddf5f6ab17e6c308dc13078adcd13d3ca1715010b30b2d65c566b353&amp;source=gmail&amp;ust=1674224688975000&amp;usg=AOvVaw2IExXIBR50cYnyfMkdnzr2"><img height="24" src="https://ci4.googleusercontent.com/proxy/ti--W6eSOLM1XNGVtER2oxBxW-rIgiQhx3Hk-K9wvok_doPD9ePKS60nyV2pa3i70KV6DxsiCNpSkH4HCrA7_WessMS7ZzzJR35mX1qZpXlFAQyndc0tYSIP7BlXQrBRspxQaq3adleiHEUkp1BbgNJsLrhHeXUAwaaU_IqS-hBS5mojA_cU5InDuZ_mG4X0g_QUWa0ZIq27644qBWUcQcCvlXofEyL4IzfxrtuRywxQ6pQsD7vucyyj_RKWvjhWuWKraB7Anux88Ztfwb4Pyk_m9vie6DI039420sLht2l42P5po8FibMBdvsAR-F2zzcAOYGacuXNDpPmbKklcGkbmVijiH6DMuXDXw6TBvI2T8rPGFA6BpR8MHkpzgw7u-L1mWZUY9w=s0-d-e1-ft#https://linkprotect.cudasvc.com/url?a=https%3a%2f%2fmcusercontent.com%2f8b04ba1ff1e04c028636623c7%2fimages%2f90a9e754-f191-65b7-89f3-0cf8351918b0.png&amp;c=E,1,3BZxJzT8UKJfUuX34HsAFRLs9SIeDhXGbasxOL-eC3hP-qJt2tM6WvuyvSs0YWca95lin8U9522V6SlFefXoGvKVJWbAsy2b5CWI4oYmpEHgzQg,&amp;typo=1" width="24" style="border:0px;height:24px;outline:none;text-decoration:none;width:24px;margin:0px" class="CToWUd" data-bit="iit"></a><br>
&copy; 2023 Kixy Ltd</span><br>
<a alt="Kixy Linkedin" href="https://linkprotect.cudasvc.com/url?a=https%3a%2f%2fwww.kixy.com&amp;c=E,1,R1kEcKMVQ6KGHGcKEmvXHZ3xqoxNhgToTciqbwCgbeqz_Et6GczAPZVucMyKN1N2Wie3JI0uXAwAKQxXKS9-xvjuVSxjCHSbePl-Xl2EzWtuZhXRZP-asFCMEHo,&amp;typo=1&amp;ancr_add=1" style="color:#19365e;font-weight:normal;text-decoration:none" target="_blank" data-saferedirecturl="https://www.google.com/url?q=https://linkprotect.cudasvc.com/url?a%3Dhttps%253a%252f%252fwww.kixy.com%26c%3DE,1,R1kEcKMVQ6KGHGcKEmvXHZ3xqoxNhgToTciqbwCgbeqz_Et6GczAPZVucMyKN1N2Wie3JI0uXAwAKQxXKS9-xvjuVSxjCHSbePl-Xl2EzWtuZhXRZP-asFCMEHo,%26typo%3D1%26ancr_add%3D1&amp;source=gmail&amp;ust=1674224688975000&amp;usg=AOvVaw3eulUsLIeeWXRJ5sVaQbOh">www.kixy.com</a></td>
</tr>
</tbody>
</table>
</td>
</tr>
</tbody>
</table>
</td>
</tr>
</tbody>
</table>
</td>
</tr>
</tbody>
</table>
</td>
</tr>
</tbody>
</table>
</section>`;
