const transactionFields = [
  'internalId',
  'type',
  'userFrom',
  'beneficiary',
  'purpose',
  'createdAt',
  'status',
  'fromCurrency',
  'toCurrency',
  'sendingAmount',
  'exchangeRate',
  'receivingAmount',
  'fee',
  'reference',
];

const userProfileFields = [
  'name',
  'lastName',
  'country',
  'email',
  'phoneCode',
  'phoneNumber',
  'accountStatus',
];

const pairsFields = [
  'from',
  'to',
  'regionDestination',
  'destination',
  'lastExchangeRateBuy',
  'lastExchangeRateSell',
];

const currenciesFields = ['id', 'iso', 'active', 'name', 'walletBgColor', 'walletTextColor'];

const teamFields = ['active', 'name', 'email', 'accountType'];

const countryFields = [
  'id',
  'iso',
  'iso2',
  'region',
  'name',
  'phoneCode',
  'defaultCurrency',
  'active',
];

export {
  countryFields,
  currenciesFields,
  pairsFields,
  teamFields,
  transactionFields,
  userProfileFields,
};
