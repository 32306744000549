import React from 'react';

export const ExchangedMoneyIcon = () => {
  const pathStyle = {
    fill: 'none',
    stroke: '#0069f8',
    strokeLinecap: 'round',
    strokeLinejoin: 'round',
    strokeWidth: '1.5px',
  };

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15.851"
      height="21.678"
      viewBox="0 0 15.851 21.678"
    >
      <g data-name="Group 8284">
        <g data-name="Group 8282">
          <path
            data-name="Path 9337"
            d="m0 0 6.108 7.146L.468 13.7"
            transform="rotate(90 -.03 14.789)"
            style={pathStyle}
          />
          <path
            data-name="Path 19499"
            d="m0 5.079 6 .759V0"
            transform="rotate(-90 14.806 5.87)"
            style={pathStyle}
          />
        </g>
        <g data-name="Group 8283">
          <path
            data-name="Path 9337"
            d="m0 0 6.108 7.146L.468 13.7"
            transform="rotate(-90 3.976 2.883)"
            style={pathStyle}
          />
          <path
            data-name="Path 19499"
            d="m0 5.132 5.656.706V0"
            transform="rotate(90 2.784 4.13)"
            style={pathStyle}
          />
        </g>
      </g>
    </svg>
  );
};
