import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(theme => ({
  toolbar: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    height: '112px',
    backgroundColor: 'white',
    borderRadius: '0 0 15px 15px',
    padding: '32px 32px 12px 48px',
    fontSize: '30px',
    color: theme.palette.icons.nav,
    fontFamily: theme.typography.default,
    '& header': {
      fontSize: '30px',
      fontWeight: 'bold',
    },
  },
  navigateBack: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    cursor: 'pointer',
    marginTop: '5px',
    gap: '11px',
    color: '#1fe3ac',
    fontWeight: '500',
    '& p': {
      fontSize: '18px',
    },
  },
  saveBtn: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '16px',
    paddingLeft: '16px',
    border: 'none',
    width: '168px',
    height: '56px',
    borderRadius: '8px',
    backgroundColor: '#c6ffef',
    color: theme.palette.icons.nav,
    fontSize: '24px',
    cursor: 'pointer',
  },
  editContainer: {
    backgroundColor: 'white',
    margin: '24px',
    paddingTop: '16px',
    borderRadius: '0px 16px 16px 16px',
    minHeight: '280px',
    fontFamily: theme.typography.default,
    color: theme.palette.icons.nav,
  },
  containerLabel: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '8px',
    marginBottom: '24px',
    fontSize: '24px',
    fontWeight: 'bold',
  },
  content: {
    fontSize: '18px',
    padding: '0 24px 24px 24px',
  },
  radioButtons: {
    display: 'flex',
    flexDirection: 'row',
    gap: '72px',
    marginTop: '19px',
    '& div': {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      gap: '8px',
    },
    '& input[type="radio"]': {
      width: '15px',
      height: '15px',
      border: '1px solid #a5a6ae',
      borderRadius: '50%',
      outline: 'none',
    },
    '& input[type="radio"]:before': {
      content: '""',
      display: 'block',
      width: '60%',
      height: '60%',
      margin: '20% auto',
      borderRadius: '50%',
    },
    '& input[type="radio"]:checked:before': {
      background: '#1fe3ac',
    },
    '& input[type="radio"]:checked': {
      background: '#1fe3ac',
    },
    '& input[type="radio"]:checked + label': {
      color: '#19365e',
    },
    '& label': {
      color: '#a5a6ae',
    },
  },
  nameCurrencyRow: {
    marginTop: '42px',
    display: 'flex',
    flexDirection: 'row',
    gap: '40px',
    '& > div': {
      width: '50%',
      display: 'flex',
      flexDirection: 'column',
      gap: '11px',
      '& > p': {
        fontWeight: '500',
      },
    },
  },
}));
