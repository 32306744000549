import React from 'react';

export const TotalNumberOfApplications = ({ width = '26', height = '26', color = '#1fe3ac' }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40">
    <g data-name="Group 10330">
      <g data-name="Group 10323">
        <g data-name="Group 10292" transform="translate(-328 -504)">
          <rect
            data-name="Rectangle 16974"
            width="40"
            height="40"
            rx="8"
            transform="translate(328 504)"
            fill="#85e2ff"
          />
        </g>
      </g>
      <g data-name="Group 10329">
        <g data-name="Group 10316">
          <path
            data-name="Path 23420"
            d="M4 5a4 4 0 1 1-4 4 4 4 0 0 1 4-4"
            transform="translate(5)"
            fill="#fff"
          />
        </g>
        <g data-name="Group 10325">
          <path
            data-name="Path 23420"
            d="M4 5a4 4 0 1 1-4 4 4 4 0 0 1 4-4"
            transform="translate(5 11)"
            fill="#fff"
          />
        </g>
        <g data-name="Group 10326">
          <path
            data-name="Path 23420"
            d="M4 5a4 4 0 1 1-4 4 4 4 0 0 1 4-4"
            transform="translate(16 11)"
            fill="#fff"
          />
        </g>
        <g data-name="Group 10327">
          <path
            data-name="Path 23420"
            d="M4 5a4 4 0 1 1-4 4 4 4 0 0 1 4-4"
            transform="translate(27 11)"
            fill="#fff"
          />
        </g>
        <g data-name="Group 10328">
          <path
            data-name="Path 23420"
            d="M4 5a4 4 0 1 1-4 4 4 4 0 0 1 4-4"
            transform="translate(27 22)"
            fill="#fff"
          />
        </g>
      </g>
    </g>
  </svg>
);
