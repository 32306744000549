import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(theme => ({
  modalContentContainer: {
    backgroundColor: 'white',
    position: 'absolute',
    top: '25%',
    left: '35%',
    width: '480px',
    height: '440px',
    borderRadius: '15px',
    zIndex: 5,
    fontFamily: theme.typography.default,
    padding: '21px',
  },
  modalTitleContainer: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    fontSize: '20px',
    fontFamily: theme.typography.default,
    fontWeight: 'bold',
    color: theme.palette.icons.nav,
    marginBottom: '1.5em',
  },
  modalIcon: {},
  modalTextRowContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    fontSize: '14px',
    marginTop: '0.5em',
    marginBottom: '0.6em',
  },
  modalLHSText: {
    color: '#a5a6ae',
  },
  modalRHSText: {
    fontWeight: 'bold',
    color: theme.palette.icons.nav,
  },
}));
