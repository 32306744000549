export const FailIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="66" height="66" viewBox="0 0 66 66">
      <g data-name="Group 10289" transform="translate(-923 -614)">
        <circle
          data-name="Ellipse 1748"
          cx="33"
          cy="33"
          r="33"
          transform="translate(923 614)"
          fill="#fc3333"
        />
        <path
          data-name="Union 971"
          d="M-10029 5727.75a17.75 17.75 0 0 1 17.752-17.75 17.751 17.751 0 0 1 17.75 17.75 17.751 17.751 0 0 1-17.75 17.75 17.751 17.751 0 0 1-17.752-17.75zm3.552 0a14.2 14.2 0 0 0 14.2 14.2 14.2 14.2 0 0 0 14.2-14.2 14.2 14.2 0 0 0-14.2-14.2 14.2 14.2 0 0 0-14.201 14.2zm19.6 7.762-5.147-5.15-5.149 5.15a1.657 1.657 0 0 1-1.181.488 1.66 1.66 0 0 1-1.181-.488 1.67 1.67 0 0 1 0-2.361l5.147-5.15-5.147-5.15a1.673 1.673 0 0 1 0-2.363 1.675 1.675 0 0 1 2.361 0l5.149 5.15 5.147-5.15a1.677 1.677 0 0 1 2.363 0 1.668 1.668 0 0 1 0 2.363l-5.149 5.15 5.149 5.15a1.665 1.665 0 0 1 0 2.361 1.665 1.665 0 0 1-1.183.488 1.66 1.66 0 0 1-1.184-.488z"
          transform="translate(10968 -5080)"
          fill="#fff"
        />
      </g>
    </svg>
  );
};
