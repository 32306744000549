import 'jspdf-autotable';

import { jsPDF as JSPDF } from 'jspdf';

import {
  formatAccountNo,
  formatDOB,
} from '../components/generic-table/customers-table/utils/customers-table.utils';
import { entitiesEnum, transactionsFieldHeaders } from '../constants';
import { getFormattedDisplayDate, getFormattedDisplayTime } from '../utils';

const XLSX = require('xlsx');

export const formatTableData = tableData => {
  const rows = [];

  tableData.forEach(row => {
    rows.push({
      InternalId: row.internalId,
      Sender: `${row.userFrom?.name} ${row.userFrom?.lastName}`,
      Type: row.type,
      Beneficiary: `${row.beneficiary?.name} ${row.beneficiary?.lastName}`,
      ExternalUserFrom: `${row.externalUserFrom?.name} ${row.externalUserFrom?.lastName}`,
      Status: row.status,
      'Origin Currency': row.fromCurrency,
      'Dest Currency': row.toCurrency,
      'Send Amount': row.sendingAmount,
      'Receiving Amount': row.receivingAmount,
      'Exchange Rate': row.exchangeRate,
      Fee: row.fee,
      Purpose: row.purpose,
      Ref: row.reference,
    });
  });

  return rows;
};

export const formatCustomerTableData = tableData => {
  const rows = [];

  tableData.forEach(record => {
    rows.push({
      Name: `${record.name} ${record.lastName}`,
      Status: record.accountStatus,
      Created: `${getFormattedDisplayDate(record.createdAt)} ${getFormattedDisplayTime(
        record.createdAt
      )}`,
      'Account Number': formatAccountNo(record.poundsAccount?.iban) || '',
      'Phone Number': `${record.phoneCode} ${record.phoneNumber}`,
      'Date of Birth': formatDOB(record.birthdate),
      Email: record.email,
    });
  });

  return rows;
};

export const exportTableDataToExcel = (tableData, entity, name = null) => {
  if (entity === entitiesEnum.TRANSACTION || entity === 'userTransactions') {
    const rows = formatTableData(tableData);
    const worksheet = XLSX.utils.json_to_sheet(rows);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Transactions');

    if (entity === entitiesEnum.TRANSACTION) XLSX.writeFile(workbook, 'Transactions.xlsx');
    if (entity === 'userTransactions') XLSX.writeFile(workbook, `${name}-Transactions.xlsx`);
  }

  if (entity === entitiesEnum.USERPROFILE) {
    const rows = formatCustomerTableData(tableData);
    const worksheet = XLSX.utils.json_to_sheet(rows);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Customers');

    XLSX.writeFile(workbook, 'Customers.xlsx');
  }
};

export const exportTableDataToPdf = (tableData, title) => {
  const rows = formatTableData(tableData);
  const unit = 'pt';
  const size = 'A4';
  const orientation = 'landscape';

  const marginLeft = 40;
  const doc = new JSPDF(orientation, unit, size);
  doc.setFontSize(15);
  const headers = [transactionsFieldHeaders.map(header => header.key)];
  const data = rows.map(record => Object.values(record));

  const content = {
    startY: 50,
    head: headers,
    body: data,
    theme: 'grid',
    columnStyles: { columnWidth: 'auto' },
    bodyStyles: { fontSize: 9 },
  };

  doc.text(title, marginLeft, 40);
  doc.autoTable(content);
  doc.save(`${title}.pdf`);
};

export const exportCustomersTableDataToPdf = (tableData, title) => {
  const rows = formatCustomerTableData(tableData);
  const unit = 'pt';
  const size = 'A4';
  const orientation = 'landscape';

  const marginLeft = 40;
  const doc = new JSPDF(orientation, unit, size);
  doc.setFontSize(15);
  const headers = [
    ['Name', 'Status', 'Created', 'Account Number', 'Phone Number', 'Date of Birth', 'Email'],
  ];
  const data = rows.map(record => Object.values(record));

  const content = {
    startY: 50,
    head: headers,
    body: data,
    theme: 'grid',
    columnStyles: { columnWidth: 'auto' },
    bodyStyles: { fontSize: 9 },
  };

  doc.text(title, marginLeft, 40);
  doc.autoTable(content);
  doc.save(`${title}.pdf`);
};

export const formatCurrencyTableData = tableData => {
  const rows = [];

  tableData.forEach(record => {
    rows.push({
      ID: `${record.id}`,
      ISO: record.iso,
      Status: record.active ? 'Enabled' : 'Disabled',
      Name: record.name,
      WalletBgColor: record.walletBgColor,
      WalletTextColor: record.walletTextColor,
      Order: record.order,
      Created: record.createdAt,
      Updated: record.updatedAt,
    });
  });

  return rows;
};

export const exportCurrenciesTableDataToPdf = (tableData, title) => {
  const rows = formatCurrencyTableData(tableData);
  const unit = 'pt';
  const size = 'A4';
  const orientation = 'landscape';

  const marginLeft = 40;
  const doc = new JSPDF(orientation, unit, size);
  doc.setFontSize(15);

  const headers = [
    ['ID', 'ISO', 'Status', 'Name', 'WalletBgColor', 'WalletTextColor', 'Created', 'Updated'],
  ];

  const data = rows.map(record => Object.values(record));

  const content = {
    startY: 50,
    head: headers,
    body: data,
    theme: 'grid',
    columnStyles: { columnWidth: 'auto' },
    bodyStyles: { fontSize: 9 },
  };

  doc.text(title, marginLeft, 40);
  doc.autoTable(content);
  doc.save(`${title}.pdf`);
};
