import React from 'react';

import { useStyles } from '../multicurrency.hooks';

export const IntPaymentButton = ({ onClick }) => {
  const classes = useStyles();

  return (
    <button onClick={onClick} className={classes.PaymentButton} type="button">
      Make payment
    </button>
  );
};
