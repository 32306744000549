import React from 'react';

export const DoubleArrowIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18.414"
    height="18.828"
    viewBox="0 0 18.414 18.828"
  >
    <g data-name="Group 8880">
      <path
        data-name="Path 20068"
        d="m0 0 8 8 8-8"
        transform="rotate(-90 13.414 4)"
        style={{
          fill: 'none',
          stroke: '#19365e',
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
          strokeWidth: '2px',
        }}
      />
      <path
        data-name="Path 20157"
        d="m0 0 8 8 8-8"
        transform="rotate(-90 9.414 8)"
        style={{
          fill: 'none',
          stroke: '#19365e',
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
          strokeWidth: '2px',
        }}
      />
    </g>
  </svg>
);
