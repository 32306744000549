import { makeStyles } from '@mui/styles';

// This makes a custom checkbox. More details in
// https://www.w3schools.com/howto/tryit.asp?filename=tryhow_css_custom_checkbox
export const useStyles = makeStyles(theme => ({
  modalContent: {
    fontFamily: 'Roboto',
    color: theme.palette.icons.nav,
    fontWeight: '500',
    '& > header': {
      fontSize: '24px',
      fontWeight: 'bold',
      textAlign: 'center',
      marginTop: '17px',
      marginBottom: '26px',
    },
    '& > main': {
      columnCount: 2,
      columnGap: '48px',
      width: '95%',
      marginLeft: '40px',
      height: 'auto',
    },
    '& > label': {
      width: '50%',
      margin: 'auto auto 24px 240px',
      fontSize: '20px',
      fontWeight: '600',
    },
  },
  container: {
    display: 'inline-flex',
    width: '100%',
    position: 'relative',
    paddingLeft: '40px',
    paddingTop: '4px',
    marginBottom: '16px',
    cursor: 'pointer',
    fontSize: '16px',
    webkitUserSelect: 'none',
    mozUserSelect: 'none',
    msUserSelect: 'none',
    userSelect: 'none',
    '& input': {
      position: 'absolute',
      opacity: '0',
      cursor: 'pointer',
      height: '0',
      width: '0',
    },
    '& input:checked ~ $checkmark': {
      backgroundColor: '#1fe3ac',
      border: 'none',
    },
    '& input:checked ~ $checkmark:after': {
      display: 'block',
    },
    '& $checkmark:after': {
      left: '9px',
      top: '5px',
      width: '5px',
      height: '10px',
      border: 'solid white',
      borderWidth: '0 3px 3px 0',
      webkitTransform: 'rotate(45deg)',
      msTransform: 'rotate(45deg)',
      transform: 'rotate(45deg)',
    },
  },
  checkmark: {
    position: 'absolute',
    top: '0',
    left: '0',
    height: '25px',
    width: '25px',
    backgroundColor: 'white',
    border: '1px solid #a5a6ae',
    borderRadius: '5px',
    '&:after': {
      content: '""',
      position: 'absolute',
      display: 'none',
    },
  },
  btnContainer: {
    width: '144px',
    margin: '48px auto',
    '& button': {
      width: '144px',
      height: '40px',
      border: 'none',
      backgroundColor: '#c6ffef',
      borderRadius: '8px',
      textAlign: 'center',
      fontSize: '18px',
      color: theme.palette.icons.nav,
      fontWeight: 'bold',
      margin: 'auto',
      cursor: 'pointer',
    },
    '& button:disabled': {
      backgroundColor: '#d9e1e7',
      color: 'white',
      cursor: 'auto',
    },
  },
}));
