import React from 'react';

export const StandardIcon = ({ active = true }) => {
  if (active)
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 40 40">
        <g data-name="Group 8383" transform="translate(-352 -608)">
          <circle
            data-name="Ellipse 1528"
            cx="20"
            cy="20"
            r="20"
            transform="translate(352 608)"
            style={{ fill: '#0cc5ff' }}
          />
          <circle
            data-name="Ellipse 1546"
            cx="8"
            cy="8"
            r="8"
            transform="translate(364 620)"
            style={{ fill: '#fff' }}
          />
        </g>
      </svg>
    );

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 40 40">
      <g data-name="Group 8313" transform="translate(-352 -608)">
        <circle
          data-name="Ellipse 1528"
          cx="20"
          cy="20"
          r="20"
          transform="translate(352 608)"
          style={{ fill: '#f2f2f4' }}
        />
        <circle
          data-name="Ellipse 1546"
          cx="8"
          cy="8"
          r="8"
          transform="translate(364 620)"
          style={{ fill: '#a5a6ae' }}
        />
      </g>
    </svg>
  );
};
