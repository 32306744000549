import { makeStyles } from '@mui/styles';

import { accountStatus } from '../../../../theme/styles';

export const useStyles = makeStyles(theme => ({
  cardNoShadow: {
    boxShadow: 'none',
  },
  selectBlock: {
    backgroundColor: theme.palette.background.default,
    borderRadius: '0.4rem',
    marginBottom: '1rem',
    '&:last-child': {
      marginBottom: 0,
    },
  },
  accountStatus,
}));
