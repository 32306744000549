import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(theme => ({
  root: {
    fontSize: '16px',
    color: theme.palette.icons.nav,
    fontFamily: theme.typography.default,
    '& div': {
      display: 'flex',
      flexDirection: 'row',
      gap: '16px',
      alignItems: 'center',
      marginBottom: '4px',
    },
  },
}));
