import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(theme => ({
  showMore: {
    cursor: 'pointer',
    fontFamily: theme.typography.default,
    color: theme.palette.greyMid.dark,
    textDecoration: 'underline',
    fontSize: '1.4rem',
    marginLeft: 'auto',
    width: 'fit-content',
  },
}));
