import Box from '@mui/material/Box';
import React from 'react';

import { InstagramIcon, KixySmileLogo, KixyTitle, LinkedInIcon, TwitterIcon } from '../../../icons';
import { useStyles } from './emailTemplateEdit.hooks';

export const ShowEmailDetail = ({ emailDetail }) => {
  const classes = useStyles();

  return (
    <Box sx={{ padding: '16px', overFlowY: 'scrol' }}>
      <section className={classes.emailSection}>
        {emailDetail.notifSendApproach == 'SMS' && (
          <>
            <div className={classes.emailHeader}>
              <div style={{ width: '24px' }} />
              <KixyTitle />
              <KixySmileLogo width="25px" height="32px" />
            </div>
            <section className={classes.emailContent}>
              <p>Title : {emailDetail.title}</p>
              <p>Body : {emailDetail.body}</p>
            </section>
            <footer className={classes.emailFooter}>
              <section className={classes.footerIcons}>
                <InstagramIcon />
                <TwitterIcon />
                <LinkedInIcon />
              </section>
              <p style={{ fontWeight: 500 }}>&copy; 2023 Kixy LTD</p>
              <p style={{ fontWeight: 500 }}>www.kixy.com</p>
            </footer>
          </>
        )}
        {emailDetail.notifSendApproach == 'EMAIL' && (
          <section
            className={classes.td}
            dangerouslySetInnerHTML={{ __html: emailDetail.htmlPart }}
          />
        )}
      </section>
    </Box>
  );
};
