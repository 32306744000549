export const currencyColors = {
  AED: '#E6778B',
  AUD: '#75B2FB',
  BHD: '#DDC6FF',
  CAD: '#4FE3E3',
  CHF: '#ADE186',
  CNY: '#98D2D6',
  CZK: '#C4DFFF',
  DKK: '#FFC9D3',
  EUR: '#00C2FF',
  GBP: '#1FE3AC',
  HKD: '#C9D0FF',
  HUF: '#87C85A',
  JPY: '#FFB188',
  KWD: '#DBDBDB',
  MAD: '#EED15C',
  MXN: '#FF9811',
  NOK: '#0FF3F3',
  NZD: '#7BA1E2',
  OMR: '#61E203',
  PLN: '#FF7A92',
  QAR: '#E5B5CC',
  SAR: '#9DC580',
  SEK: '#88F7FF',
  SGD: '#D2B9BE',
  THB: '#B1C3D8',
  TRY: '#EED0A5',
  USD: '#D29FFF',
  ZAR: '#FFDA44',
};
