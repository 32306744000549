import React, {useState} from 'react';
import {DemoContainer} from '@mui/x-date-pickers/internals/demo';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {DatePicker} from '@mui/x-date-pickers/DatePicker';
import {useStyles} from '../multicurrency.hooks';
import {Loader} from '../../../loader';
import {DateIcon} from '../../../icons';
import {LoadButton} from './LoadButton';
import {graphqlBatchApiDecorator} from "../../../../decorators/batch-decorator-api";
import {CopyButton} from "./CopyButton";
import {copyTextToClipboard} from "../../../../utils";
import {Alert} from "@mui/lab";
import {Snackbar} from "@mui/material";
import {statusMap} from "../status";


function getDates(startDate, endDate) {
    const dateArray = [];
    let currentDate = new Date(startDate);
    let lastDate = new Date(endDate);
    currentDate.setHours(0, 0, 0, 0);
    lastDate.setHours(23, 59, 59, 999);
    while (currentDate <= lastDate) {
        const year = currentDate.getFullYear();
        const month = String(currentDate.getMonth() + 1).padStart(2, '0');
        const day = String(currentDate.getDate()).padStart(2, '0');
        const dateString = `${year}-${month}-${day}`;
        dateArray.push(dateString);
        currentDate.setDate(currentDate.getDate() + 1);
    }
    return dateArray;
}

function mergeDuplicateItems(arr, key, dateField) {
    // Sort the array based on the date field
    arr.sort((a, b) => new Date((b.status == 'Executed' )?b['executedTime']:b[dateField]) - new Date((a.status == 'Executed' )?a['executedTime']:a[dateField]));

    const result = [];
    const map = {};

    for (let i = 0; i < arr.length; i++) {
      const item = arr[i];
      const itemKey = item[key];

      if (!map[itemKey]) {
        // Add the item to the map if it doesn't exist
        map[itemKey] = item;
        result.push(item);
      } else {
        // Merge the item with the existing item in the map if it's newer
        const existingItem = map[itemKey];
        const newItemDate = new Date(item[dateField]);
        const existingItemDate = new Date(existingItem[dateField]);

        if (newItemDate > existingItemDate) {
          map[itemKey] = Object.assign({}, item, existingItem);
          result[result.indexOf(existingItem)] = map[itemKey];
        }
      }
    }

    // return result.sort((a, b) => new Date(a[dateField]) - new Date(b[dateField]));
    return result
  }

export const FXTradeHistory = () => {
    const [periods, setPeriods] = useState([
        new Date().toLocaleDateString('en-GB'),
        new Date().toLocaleDateString('en-GB'),
    ]);
    const [loading, setLoading] = useState(false);
    const classes = useStyles();
    const [data, setData] = useState([]);
    const [copied, setCopied] = useState(false);

    const columns = [
        'endToEndID',
        'status',
        'cancellationReason',
        'createdAt',
        'sellCurrencyAndAmount',
        'buyCurrencyAndAmount',
        'copyInfo',
    ];

    const copy = txt =>
        setTimeout(() => {
            copyTextToClipboard(txt).then(res=> setCopied(true));
        }, 1000);

    const LoadData = async () => {
        const list = [];
        setLoading(true);
        const dateArray = getDates(...periods)
        const res = await graphqlBatchApiDecorator(
            dateArray.map(date =>
                ({
                    title: 'listFXTrades',
                    options: {
                        createdDay: date
                    }
                })));

        const results = res.map(queryResponse => JSON.parse(queryResponse['listFXTrades']))
        const ArrayOfResult = mergeDuplicateItems([].concat(...results),'endToEndId','createdTimestamp')
        // const ArrayOfResult = [].concat(...results)
        ArrayOfResult.map(item => {
            list.push({
                key: item['endToEndId'],
                endToEndID: (
                    <CopyButton textClass={classes.fontStyle} onClick={() => copy(item['endToEndId'])} text={item['endToEndId']}/>
                ),
                status: item['status'],
                cancellationReason: item['cancellationReason'],
                createdAt: item['createdDay'],
                sellCurrencyAndAmount:
                    item['sellCurrency'] + ' - ' + (item['debitAmount'] ? item['debitAmount'] : ''),
                buyCurrencyAndAmount: item['buyCurrency'] + ' - ' + item['creditAmount'],
                copyInfo: <CopyButton onClick={() => copy(item['_info'])}/>,
            });
        });
        setData(list);
        setLoading(false);
    }

    const colorFinder = status => {
        switch (true) {
            case statusMap[status.toLowerCase()]==='Cancelled':
                return '#fc3333';
            case statusMap[status.toLowerCase()]==='Settled':
                return '#23c3a4';
            case statusMap[status.toLowerCase()]==='In Progress':
                return '#0069f8';
        }
    };

    return (
        <>
        <div className={classes.sectionContainer}>
            <div className={classes.container}>
                <div style={{display: 'flex', padding: '16px', gap: '16px'}}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer components={['DatePicker']}>
                            <DatePicker
                                format={'DD/MM/YYYY'}
                                className={classes.dateField}
                                onChange={e => setPeriods([e, periods[0]])}
                                slots={{
                                    openPickerIcon: DateIcon,
                                }}
                                label={'From Date'}
                            />
                            <DatePicker
                                format={'DD/MM/YYYY'}
                                className={classes.dateField}
                                onChange={e => setPeriods([periods[0], e])}
                                slots={{
                                    openPickerIcon: DateIcon,
                                }}
                                label={'To Date'}

                            />
                        </DemoContainer>
                    </LocalizationProvider>
                    <LoadButton onClick={LoadData}/>
                </div>
                {loading ? (
                    <Loader/>
                ) : (
                    <table className={classes.tableContainer}>
                        <thead className={classes.tableHead}>
                        <tr className={classes.tableFields}>
                            <th className={classes.endToEndColumn}>End To End ID</th>
                            <th>Status</th>
                            <th>Cancellation Reason</th>
                            <th>Created At</th>
                            <th>Sell Currency & Amount</th>
                            <th>Buy Currency & Amount</th>
                            <th>Copy Info</th>
                        </tr>
                        </thead>
                        <tbody>
                        {data.map(row => (
                            <tr className={classes.tableRow} key={row.endToEndId}>
                                {columns.map(head => (
                                    <td
                                        key={head}
                                        className={classes.tableCell}
                                        style={{...head === 'status' ? {color: colorFinder((row[head]).toLocaleString())} : {}}}
                                        id={head}
                                    >
                                        {head === 'status' ? statusMap[(row[head]).toLowerCase()] : row[head]}
                                    </td>
                                ))}
                            </tr>
                        ))}
                        </tbody>
                    </table>
                )}
            </div>
        </div>
        <Snackbar open={copied} autoHideDuration={2000} onClose={() => setCopied(false)}>
            <Alert onClose={() => setCopied(false)} severity="info" sx={{width: '100%'}}>
                copied
            </Alert>
        </Snackbar>
        </>

    );
};
