import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(theme => ({
  mainContainer: {
    display: 'flex',
    flexDirection: 'column',
    background: theme.palette.white,
    margin: '0px 24px',
    '& :hover': {
      backgroundColor: '#e9fff9',
    },
    '& input:hover': {
      backgroundColor: 'white',
    },
    '& select:hover': {
      backgroundColor: 'white',
    },
    '& input:focus': {
      outline: 'none',
      border: '1px solid #1fe3ac',
    },
    '& select:focus': {
      outline: 'none',
      border: '1px solid #1fe3ac',
    },
  },
  rowDataContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    height: '80px',
    fontSize: '20px',
    paddingRight: '40px',
    fontFamily: theme.typography.default,
    alignItems: 'center',
    color: theme.palette.icons.nav,
    '& p': {
      fontWeight: 'bold',
    },
  },
  currenciesContainer: {
    display: 'flex',
    flexDirection: 'row',
    paddingLeft: '40px',
    gap: '16px',
    alignItems: 'center',
    flex: '0.8',
  },
  flatRateContainer: {
    paddingLeft: '40px',
    flex: '0.8',
    '& input::-webkit-outer-spin-button, input::-webkit-inner-spin-button': {
      WebkitAppearance: 'none',
      margin: 0,
    },
    '& input[type=number]': {
      MozAppearance: 'textfield',
    },
    '& input': {
      borderRadius: '8px',
      height: '48px',
      border: '1px solid #e6e6e6',
      padding: '8px',
      fontSize: '20px',
      fontFamily: theme.typography.default,
      color: theme.palette.icons.nav,
    },
  },
  profitMarginSellContainer: {
    paddingLeft: '40px',
    display: 'flex',
    flexDirection: 'row',
    gap: '24px',
    flex: '1',
  },
  profitMarginSellSelectBox: {
    height: '48px',
    borderRadius: '8px',
    border: '1px solid #e6e6e6',
    padding: '5px',
    fontSize: '20px',
    fontFamily: theme.typography.default,
    color: theme.palette.icons.nav,
  },
  defaultInputContainer: {
    '& input::-webkit-outer-spin-button, input::-webkit-inner-spin-button': {
      WebkitAppearance: 'none',
      margin: 0,
    },
    '& input[type=number]': {
      MozAppearance: 'textfield',
      fontSize: '20px',
      fontFamily: theme.typography.default,
      color: theme.palette.icons.nav,
      paddingLeft: '8px',
    },
  },
  sellRates: {
    flex: '1',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& > p': {
      paddingLeft: '50px',
      fontWeight: 'normal',
    },
  },
  feeContainer: {
    flex: '1',
    display: 'flex',
    flexDirection: 'row',
    gap: '24px',
  },
  relContainer: {
    position: 'relative',
    '& $defaultInputBox:focus + $percentageIcon': {
      background: '#c6ffef',
      border: '1px solid #1fe3ac',
    },
    '& $percentageIcon:hover': {
      background: '#f2f2f4',
    },
  },
  defaultInputBox: {
    width: '128px',
    height: '48px',
    borderRadius: '8px',
    border: '1px solid #e6e6e6',
  },
  percentageIcon: {
    position: 'absolute',
    height: '48px',
    width: '48px',
    borderRadius: '0px 8px 8px 0px',
    background: theme.palette.transactions.background,
    border: '1px solid #e6e6e6',
    textAlign: 'center',
    paddingTop: '12px',
    top: '0px',
    right: '0px',
  },
  sellRatesInputBox: {
    width: '200px',
  },
  horizRule: {
    borderBottom: '1px solid #e6e6e6',
    margin: '0px 24px',
  },
  smallRightArrow: {
    cursor: 'pointer',
  },
}));
