export const currencies = {
  AED: 'United Arab Emirates Dirham',
  AUD: 'Australian Dollar',
  BHD: 'Bahrain Dinar',
  CAD: 'Canadian Dollar',
  CHF: 'Swiss Franc',
  CNY: 'Chinese Yuan',
  CZK: 'Czech Krouna',
  DKK: 'Danish Krone',
  EUR: 'Euro',
  GBP: 'British pound sterling',
  HKD: 'Hong Kong Dollar',
  HUF: 'Hungarian Forint',
  JPY: 'Japanese Yen',
  KWD: 'Kuwait Dinar',
  MAD: 'Moroccan Dirham',
  MXN: 'Mexican Peso',
  NOK: 'Norwegian Krone',
  NZD: 'New Zealand Dollar',
  OMR: 'Omani Rial',
  PLN: 'Poland zloty',
  QAR: 'Qatari Rial',
  SAR: 'Saudi Riyal',
  SEK: 'Swedish Krona',
  SGD: 'Singapore Dollar',
  THB: 'Thai Baht',
  TRY: 'Turkish lira',
  USD: 'United States Dollar',
  ZAR: 'South African Rand',
};
