import React from 'react';

export const SmallDeleteIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="15.999" viewBox="0 0 16 15.999">
    <path
      data-name="Union 369"
      d="M4.883 16a2.43 2.43 0 0 1-2.5-2.083c-.1-.808-.126-1.626-.18-2.441q-.258-3.9-.508-7.8c-.008-.132-.056-.16-.184-.158-.314.008-.628.01-.943 0A.588.588 0 0 1 0 2.985a.581.581 0 0 1 .46-.612 1.521 1.521 0 0 1 .286-.026q2.011-.006 4.019 0c.122 0 .166-.026.158-.148-.01-.2 0-.4 0-.6A1.593 1.593 0 0 1 6.556.01C7.516 0 8.478 0 9.441.01a1.605 1.605 0 0 1 1.612 1.3 4.379 4.379 0 0 1 .024.728c0 .3 0 .3.3.3 1.321 0 2.641 0 3.961.008a.6.6 0 0 1 .662.595.605.605 0 0 1-.656.574h-.884c-.1 0-.146.022-.152.128-.018.378-.049.754-.074 1.13l-.284 4.155c-.108 1.574-.232 3.147-.328 4.721A2.467 2.467 0 0 1 11.037 16H4.883zM2.921 3.735q.133 1.914.256 3.829l.246 3.773c.052.8.1 1.594.16 2.389a1.254 1.254 0 0 0 1.344 1.1q3.076.006 6.153 0a2.054 2.054 0 0 0 .268-.02 1.221 1.221 0 0 0 1.072-1.1q.181-2.863.354-5.726c.089-1.422.174-2.845.27-4.267.01-.162-.04-.186-.194-.186-1.628.006-8.128.032-9.751.028h-.01c-.147-.002-.178.045-.168.18zm6.77-1.395c.126 0 .166-.028.158-.148-.01-.188 0-.378 0-.566 0-.278-.142-.408-.444-.408H6.599c-.309 0-.441.134-.443.428 0 .188.007.378 0 .566-.006.1.03.13.138.13.57-.006 1.141 0 1.711 0h.967c.237-.005.478-.004.719-.002zm-2.305 9.933v-6.21a.564.564 0 0 1 .114-.342.638.638 0 0 1 .678-.23.588.588 0 0 1 .44.576V12.252a.614.614 0 0 1-1.228.016z"
      style={{ fill: '#fc3333' }}
    />
  </svg>
);
