import React from 'react';

export const TwitterIcon = ({ height = '24px', width = '24px' }) => (
  <svg
    data-name="Group 10180"
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 24 24"
  >
    <circle data-name="Ellipse 1856" cx="12" cy="12" r="12" style={{ fill: '#19365e' }} />
    <path
      data-name="Path 16296"
      d="M13.572.2a.562.562 0 0 0-.287.079 4.992 4.992 0 0 1-1.239.527.091.091 0 0 1-.082-.019A3.264 3.264 0 0 0 9.838 0h-.011a3.3 3.3 0 0 0-3.309 3.207.094.094 0 0 1-.033.067.086.086 0 0 1-.071.019A7.257 7.257 0 0 1 1.921.708.552.552 0 0 0 1.439.5.56.56 0 0 0 1 .783a3.357 3.357 0 0 0-.154 3.022.089.089 0 0 1-.035.112.585.585 0 0 0-.279.5 1.867 1.867 0 0 0 .016.274 3.287 3.287 0 0 0 .994 2.1.088.088 0 0 1 .017.105.56.56 0 0 0-.037.433 3.3 3.3 0 0 0 1.5 1.851.089.089 0 0 1 .044.084.086.086 0 0 1-.057.076 4.969 4.969 0 0 1-2.378.3.514.514 0 0 0-.066 0 .562.562 0 0 0-.3 1.033A8.319 8.319 0 0 0 4.769 12h.091a8.242 8.242 0 0 0 7.932-6.026 8.257 8.257 0 0 0 .344-2.349v-.13a.093.093 0 0 1 .024-.061 3.683 3.683 0 0 0 .974-2.653.084.084 0 0 1 0-.019.561.561 0 0 0-.562-.562m-1.434 2.71a.551.551 0 0 0-.13.392c.006.1.006.2.006.3v.026a7.119 7.119 0 0 1-.3 2.03 7.024 7.024 0 0 1-6.8 5.224 7.741 7.741 0 0 1-1.828-.2.089.089 0 0 1-.068-.084.091.091 0 0 1 .062-.089 6.042 6.042 0 0 0 1.887-1 .561.561 0 0 0-.338-1 2.167 2.167 0 0 1-1.5-.634.089.089 0 0 1 .047-.152 2.7 2.7 0 0 0 .277-.069.561.561 0 0 0-.044-1.09A2.184 2.184 0 0 1 1.88 5.388a.091.091 0 0 1 .008-.088.086.086 0 0 1 .084-.037 3.429 3.429 0 0 0 .349.029h.009a.571.571 0 0 0 .543-.392.562.562 0 0 0-.225-.636 2.161 2.161 0 0 1-.966-1.813v-.136a.09.09 0 0 1 .15-.059A8.39 8.39 0 0 0 7.12 4.493a.546.546 0 0 0 .46-.2.563.563 0 0 0 .114-.493 2.119 2.119 0 0 1-.059-.494 2.194 2.194 0 0 1 2.189-2.184h.005a2.171 2.171 0 0 1 1.586.692.541.541 0 0 0 .515.167 5.992 5.992 0 0 0 .777-.206.089.089 0 0 1 .109.124 4.446 4.446 0 0 1-.679 1.012"
      transform="translate(4.999 6)"
      style={{ fill: '#1fe3ac' }}
    />
  </svg>
);
