export const formatDOB = dob => {
  if (dob) return `${dob.slice(8)}/${dob.slice(5, 7)}/${dob.slice(0, 4)}`;

  return '';
};

export const formatAccountNo = IBAN => {
  if (IBAN) return IBAN.slice(-8);

  return '';
};
