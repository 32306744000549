import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { Button } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import { useStyles } from './back-button.hooks';

export const BackButton = () => {
  const classes = useStyles();
  const navigate = useNavigate();

  const handleBack = () => navigate(-1);

  return (
    <Button onClick={handleBack} className={classes.backButton}>
      <div className={classes.backArrow}>
        <ChevronLeftIcon />
      </div>
      <span className={classes.backButtonText}>Go back</span>
    </Button>
  );
};
