import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Card, CardContent, CardHeader, Divider, Grid, Typography } from '@mui/material';
import React, { useEffect, useMemo, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { uid } from 'react-uid';

import { useGraphGet } from '../../hooks';
import { saveEntity } from '../../services';
import { getExtraComponents, getMappedEntity } from '../../utils';
import { BackButton, MainButton } from '../buttons';
import { ErrorBoundary } from '../error-boundary';
import { FormField } from '../form-field-generic';
import { FormSubObjectListField } from '../form-field-generic/components';
import { useStyles } from './generic-edit-form.hooks';

export const GenericEditForm = ({ entity }) => {
  const formRef = useRef();
  const navigate = useNavigate();
  const { fields, hasOrder } = useMemo(() => getMappedEntity(entity), [entity]);
  const { id } = useParams();
  const { loading, error, data } = useGraphGet(entity, id, fields);
  const classes = useStyles();

  const {
    handleSubmit,
    formState: { errors },
    setValue,
    control,
    register,
    getValues,
    watch,
  } = useForm();

  useEffect(() => {
    if (data) {
      data.forEach(obj => {
        const [[name, value]] = Object.entries(obj);

        setValue(name, value);
      });
    }
  }, [setValue, data]);

  const handleFormSubmit = async (values, event) => {
    if (formRef.current !== event.target) {
      return false;
    }

    delete values?.regionDestination;

    await saveEntity({ entity, values, id, hasOrder });
    navigate(0);

    return true;
  };

  const extraComponents = getExtraComponents(entity);

  return (
    <>
      <form onSubmit={handleSubmit(handleFormSubmit)} ref={formRef}>
        <Grid container spacing={4}>
          <Grid container item spacing={2} xs={12} style={{ margin: 'auto' }}>
            <div className={classes.headerContent}>
              <Grid xs={9} container item>
                <Typography variant="h3">{data ? 'Edit' : 'Create'}</Typography>
                <p>&nbsp;</p>
                <Typography variant="h3">{entity}</Typography>
              </Grid>
              <Grid item xs={1} className={classes.backButtonWrapper}>
                <BackButton />
              </Grid>
              <Grid item xs={2} className={classes.saveButton}>
                <MainButton icon={<CheckCircleIcon />} type="submit" text="Save" design="primary" />
              </Grid>
            </div>
          </Grid>
          {extraComponents.length > 0 && (
            <Grid container item justifyContent="flex-end" spacing={2} xs={12}>
              {extraComponents.map(Component => (
                <Component key={uid(Component)} watch={watch} />
              ))}
            </Grid>
          )}
          <Grid item xs={12}>
            {(loading || error) && (
              <Card>
                <CardHeader title="Edit" />
                <Divider />
                <CardContent>
                  <Grid container>
                    <Grid item xs={8}>
                      {loading && <Typography>Loading</Typography>}
                      {error && <Typography>{error}</Typography>}
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            )}
            <Card className={classes.groupCard}>
              <CardContent>
                <Grid container item spacing={4} xs={12}>
                  {fields.map(field => {
                    const defaultProps = {
                      control,
                      entity,
                      field,
                      getValues,
                      register,
                      setValue,
                      errors: errors[field.name],
                      rules: { required: field.required },
                      watch,
                    };

                    return (
                      <ErrorBoundary key={uid(field)}>
                        <FormField id={id} {...defaultProps} />
                      </ErrorBoundary>
                    );
                  })}
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </form>
      {fields
        .filter(({ type }) => type === 'subObjectList')
        .map(field => {
          const defaultProps = {
            entity,
            field,
            id,
            register,
            watch,
            setValue,
            rules: { required: false },
          };

          return (
            <Card className={classes.groupCard} key={uid(field)}>
              <CardContent>
                <Grid container item spacing={4} xs={12}>
                  <ErrorBoundary>
                    <FormSubObjectListField {...defaultProps} />
                  </ErrorBoundary>
                </Grid>
              </CardContent>
            </Card>
          );
        })}
    </>
  );
};
