import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { FilterIcon } from '../../../../components/icons';
import { useStyles } from './customer-filter-modal.hooks';

export const CustomerFilterModal = ({ onClose }) => {
  const customerFilterObj = useSelector(state => state.customerFilterObj);
  const [btnDisabled, setBtnDisabled] = useState(true);
  const [dataObj, setDataObj] = useState({
    status: customerFilterObj.status || {
      created: false,
      rejected: false,
      closed: false,
      active: false,
      suspended: false,
    },
    dateFrom: customerFilterObj.dateFrom || '',
    dateTo: customerFilterObj.dateTo || '',
  });
  const dispatch = useDispatch();
  const classes = useStyles();

  useEffect(() => {
    if (
      Object.values(dataObj.status).every(status => !status) &&
      Object.values(dataObj).every(value => {
        if (typeof value !== 'object') return !value;

        return true;
      })
    ) {
      // every value is falsy so search button disabled
      setBtnDisabled(true);
    } else {
      setBtnDisabled(false);
    }
  }, [dataObj]);
  const handleSubmit = event => {
    event.preventDefault();
    dispatch({ type: 'SET_CUSTOMER_FILTER_OBJ', payload: dataObj });
    onClose();
  };

  const updateDataObj = (event, prop) => {
    setDataObj(prev => ({ ...prev, [prop]: event.target.value }));
  };

  const updateDataObjStatus = prop => {
    setDataObj(prev => {
      const status = { ...prev.status };
      status[prop] = !prev.status[prop];

      return { ...prev, status };
    });
  };

  return (
    <div className={classes.customerFilterModalContainer}>
      <header>Filter</header>
      <form onSubmit={handleSubmit}>
        <div className={classes.statusRow}>
          <p>Status</p>
          <div
            className={
              dataObj.status.created
                ? `${classes.createdActive} ${classes.defaultStatus}`
                : classes.defaultStatus
            }
            onClick={() => updateDataObjStatus('created')}
          >
            Created
          </div>
          <div
            className={
              dataObj.status.rejected
                ? `${classes.rejectedActive} ${classes.defaultStatus}`
                : classes.defaultStatus
            }
            onClick={() => updateDataObjStatus('rejected')}
          >
            Rejected
          </div>
          <div
            className={
              dataObj.status.closed
                ? `${classes.closedActive} ${classes.defaultStatus}`
                : classes.defaultStatus
            }
            onClick={() => updateDataObjStatus('closed')}
          >
            Closed
          </div>
          <div
            className={
              dataObj.status.active
                ? `${classes.activeActive} ${classes.defaultStatus}`
                : classes.defaultStatus
            }
            onClick={() => updateDataObjStatus('active')}
          >
            Active
          </div>
          <div
            className={
              dataObj.status.suspended
                ? `${classes.suspendedActive} ${classes.defaultStatus}`
                : classes.defaultStatus
            }
            onClick={() => updateDataObjStatus('suspended')}
          >
            Suspended
          </div>
        </div>
        <div className={classes.dateRow}>
          <section>
            <label htmlFor="dateFrom">From:</label>
            <input
              type="date"
              id="dateFrom"
              value={dataObj.dateFrom}
              onChange={e => updateDataObj(e, 'dateFrom')}
            />
          </section>
          <section>
            <label htmlFor="dateTo">To:</label>
            <input
              type="date"
              id="dateTo"
              value={dataObj.dateTo}
              onChange={e => updateDataObj(e, 'dateTo')}
            />
          </section>
        </div>
        <button
          className={
            btnDisabled ? classes.searchBtn : `${classes.enabledSearch} ${classes.searchBtn}`
          }
          type="submit"
          disabled={btnDisabled}
          onClick={handleSubmit}
        >
          <FilterIcon color="white" />
          <p>Filter</p>
          <div />
        </button>
      </form>
    </div>
  );
};
