/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { ArrowDownIcon, ArrowRightIcon, LeftArrowIcon } from '../../components/icons';
import { currencies } from '../../constants';
import { graphqlApiDecorator } from '../../decorators';
import { mutations } from '../../graphql';
import { listEntity } from '../../services';
import { useStyles } from './currency-requirements-edit.hooks';
import { BigSaveIcon, SmallSaveicon } from './icons';
import { SavedModal, WarningModal } from './modal';
import { RequirementsColumns } from './requirements-columns';

export const CurrencyRequirementsEdit = () => {
  const LOCAL = 'LOCAL';
  const INTER = 'INTERNATIONAL';
  const classes = useStyles();
  const navigate = useNavigate();
  const { id } = useParams();
  const [currencyReqData, setCurrencyReqData] = useState([]);
  const [localRequirementCurrencies, setLocalRequirementCurrencies] = useState([]);
  const [internationalRequirementCurrencies, setInternationalRequirementCurrencies] = useState([]);
  const [currencyTabsClicked, setCurrencyTabsClicked] = useState({ [id]: true });
  const [currencyLocalInterTabClicked, setCurrencyLocalInterTabClicked] = useState({
    [id]: 'LOCAL',
  });
  const [openModal, setOpenModal] = useState(false);
  const [showWarningModal, setShowWarningModal] = useState(false);
  const [warningMessage, setWarningMessage] = useState('');
  const ENTITY = 'CurrencyRequirement';

  const getCurrencyRequirements = async () => {
    try {
      const { data } = await listEntity({
        entity: ENTITY,
        getAll: false,
        paginationToken: null,
        limit: 100,
        filter: { currency: id },
      });
      setCurrencyReqData(data);
    } catch (error) {
      setWarningMessage(error.message);
      setShowWarningModal(true);
    }
  };

  useEffect(() => {
    getCurrencyRequirements();
    document.title = `Edit Currency: ${currencies[id]}`;
  }, []);

  useEffect(() => {
    if (currencyReqData?.length) {
      const localRequirements = currencyReqData.filter(
        item => item.currency === id && item.type === 'Local'
      );

      if (localRequirements?.length)
        setLocalRequirementCurrencies(JSON.parse(localRequirements[0]?.requirements));

      const internationalRequirements = currencyReqData.filter(
        item => item.currency === id && item.type === 'International'
      );

      if (internationalRequirements?.length)
        setInternationalRequirementCurrencies(
          JSON.parse(internationalRequirements[0]?.requirements)
        );
    }
  }, [currencyReqData]);

  const currencyTabClicked = currency => {
    setCurrencyTabsClicked(prev => {
      const newTabs = { ...prev, [currency]: !prev[currency] };

      return newTabs;
    });
  };

  const localInterClicked = currency => {
    setCurrencyLocalInterTabClicked(prev => {
      const newTabs = { ...prev, [currency]: prev[currency] === LOCAL ? INTER : LOCAL };

      return newTabs;
    });
  };

  const saveChanges = async () => {
    const mutationName = 'putCurrencyRequirement';

    try {
      if (internationalRequirementCurrencies.length) {
        const international = 'International';
        const values = {
          currency: id,
          type: international,
          name: `${id} ${international}`,
          requirements: JSON.stringify([...internationalRequirementCurrencies]),
        };
        await graphqlApiDecorator(mutations, mutationName, { input: values });
      }

      if (localRequirementCurrencies.length) {
        const local = 'Local';
        const values = {
          currency: id,
          type: local,
          name: `${id} ${local}`,
          requirements: JSON.stringify([...localRequirementCurrencies]),
        };
        await graphqlApiDecorator(mutations, mutationName, { input: values });
      }
      setOpenModal(true);
    } catch (error) {
      setWarningMessage(error.message);
      setShowWarningModal(true);
    }
  };

  const localButtonChange = (value, field) => {
    setLocalRequirementCurrencies(prev => {
      const newData = [...prev];
      const indexToChange = newData.findIndex(obj => obj.field === field);
      newData[indexToChange].status = value;

      return newData;
    });
  };

  const internButtonChange = (value, field) => {
    setInternationalRequirementCurrencies(prev => {
      const newData = [...prev];
      const indexToChange = newData.findIndex(obj => obj.field === field);
      newData[indexToChange].status = value;

      return newData;
    });
  };

  return (
    <>
      <SavedModal open={openModal} onClose={() => setOpenModal(false)} />
      <WarningModal
        open={showWarningModal}
        onClose={() => setShowWarningModal(false)}
        message={warningMessage}
      />
      <div className={classes.toolbar}>
        <div>
          <header>Edit Currency Requirements</header>
          <div className={classes.navigateBack} onClick={() => navigate(-1)}>
            <LeftArrowIcon />
            <p>Go Back</p>
          </div>
        </div>

        <div className={classes.bigSaveButton} onClick={saveChanges}>
          <BigSaveIcon />
          <p>Save</p>
        </div>
      </div>
      <div className={classes.root}>
        <div
          className={
            !currencyTabsClicked[id]
              ? `${classes.currencyTab} ${classes.lastTab}`
              : classes.currencyTab
          }
        >
          <p>{id}</p>
          {!currencyTabsClicked[id] && (
            <div className={classes.arrowIcon} onClick={() => currencyTabClicked(id)}>
              <ArrowRightIcon width="8px" height="16px" />
            </div>
          )}
          {currencyTabsClicked[id] && (
            <>
              <div className={classes.localInternationalContainer}>
                <div
                  className={
                    currencyLocalInterTabClicked[id] === LOCAL
                      ? classes.highlightedTab
                      : classes.defaultTab
                  }
                  onClick={() => localInterClicked(id)}
                >
                  Local
                </div>
                <div
                  className={
                    currencyLocalInterTabClicked[id] === INTER
                      ? classes.highlightedTab
                      : classes.defaultTab
                  }
                  onClick={() => localInterClicked(id)}
                >
                  International
                </div>
              </div>
              <div className={classes.saveAnddownArrowContainer}>
                <div onClick={() => saveChanges()} className={classes.arrowIcon}>
                  <SmallSaveicon />
                </div>
                <div onClick={() => currencyTabClicked(id)} className={classes.downArrow}>
                  <ArrowDownIcon width="16px" height="8px" />
                </div>
              </div>
            </>
          )}
        </div>
        {currencyLocalInterTabClicked[id] === LOCAL && currencyTabsClicked[id] && (
          <div className={classes.requirementsContainer}>
            {localRequirementCurrencies?.length ? (
              <RequirementsColumns
                reqList={localRequirementCurrencies}
                buttonChange={localButtonChange}
                currency={id}
                type="Local"
              />
            ) : (
              <p>No data to display</p>
            )}
          </div>
        )}
        {currencyLocalInterTabClicked[id] === INTER && currencyTabsClicked[id] && (
          <div className={classes.requirementsContainer}>
            {internationalRequirementCurrencies?.length ? (
              <RequirementsColumns
                reqList={internationalRequirementCurrencies}
                buttonChange={internButtonChange}
                currency={id}
                type="International"
              />
            ) : (
              <p>No data to display</p>
            )}
          </div>
        )}
      </div>
    </>
  );
};
