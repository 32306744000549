import { getBackofficeConfig } from '../config/backoffice.config';
import { palette } from '../theme/palette';
import { isManualIdFieldShown } from './entities.utils';

const isFieldExcluded = name => getBackofficeConfig().createExcludeFields.includes(name);

const isReadOnlyField = (entity, field) =>
  getBackofficeConfig().fieldsReadOnly?.[entity]?.includes(field);

const isDependentFieldHidden = (values, fieldName, entity, subObject) => {
  const entityDependentFields = getBackofficeConfig().dependentFields[entity];

  if (!entityDependentFields || !entityDependentFields[fieldName]) {
    return false;
  }

  if (subObject && entityDependentFields?.[subObject]?.[fieldName]) {
    const subObjectRules = Object.keys(entityDependentFields[subObject][fieldName]);

    return !subObjectRules.every(rule =>
      entityDependentFields[subObject][fieldName][rule].includes(values[rule])
    );
  }

  const fieldRules = Object.keys(entityDependentFields[fieldName]);

  return !fieldRules.every(rule => {
    if (!Array.isArray(entityDependentFields[fieldName][rule])) {
      return true;
    }

    return entityDependentFields[fieldName][rule].includes(values[rule]);
  });
};

const isHiddenField = (values, fieldName, entity, subObject) =>
  !!(isFieldExcluded(fieldName) || isDependentFieldHidden(values, fieldName, entity, subObject));

const fieldBreaksLineBefore = (entity, field) =>
  getBackofficeConfig().fieldsBreakLineBefore?.[entity]?.includes(field);

const fieldBreaksLineAfter = (entity, field) =>
  getBackofficeConfig().fieldsBreakLineAfter?.[entity]?.includes(field);

const getFieldBackground = (entity, field) =>
  getBackofficeConfig().fieldsBackground?.[entity]?.[field] ?? palette.white;

const getSubObjectValidations = (entity, field) =>
  getBackofficeConfig().subObjectValidations?.[entity]?.[field];

const getFilteredTableFields = (tableFields, entity) => {
  const filteredTableFields = tableFields.filter(f => {
    if (f.name === 'id' && !isManualIdFieldShown(entity)) {
      return false;
    }

    return getBackofficeConfig().tableFields[entity]?.includes(f.name);
  });

  return filteredTableFields;
};

const addCreatedAtField = tableFields => {
  if (!tableFields.some(field => field.name === 'createdAt')) {
    const Time = {
      label: 'createdAt',
      name: 'createdAt',
      options: [],
      relatedEntity: null,
      required: false,
      type: 'date',
    };
    tableFields.push(Time);
  }
};

export {
  addCreatedAtField,
  fieldBreaksLineAfter,
  fieldBreaksLineBefore,
  getFieldBackground,
  getFilteredTableFields,
  getSubObjectValidations,
  isDependentFieldHidden,
  isFieldExcluded,
  isHiddenField,
  isReadOnlyField,
};
