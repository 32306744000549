import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(theme => ({
  sectionContainer: {
    background: theme.palette.white,
    borderRadius: '0px 16px 16px 16px',
    margin: '16px 20px',
    height: '328px',
  },
  headerContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: '16px',
    paddingTop: '25px',
    fontSize: '24px',
    fontFamily: theme.typography.default,
    fontWeight: 'bold',
    color: theme.palette.icons.nav,
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  sectionInputContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: '24px',
    width: '80%',
  },
  rowInputContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    gap: '40px',
    marginTop: '42px',
  },
  emptyDiv: {
    flexGrow: '1',
  },
  statusRadioButtonContainer: {
    fontSize: '18px',
    fontFamily: theme.typography.default,
    color: theme.palette.icons.nav,
    flexGrow: '1',
    '& > p ': {
      marginBottom: '24px',
    },
  },
  radioButtons: {
    display: 'flex',
    flexDirection: 'row',
    gap: '72px',
    '& > div': {
      display: 'flex',
      flexDirection: 'row',
      gap: '8px',
      '& input[type="radio"]:checked+label': {
        fontWeight: 'bold',
      },
      '& input[type="radio"]:checked': {
        accentColor: theme.palette.icons.nav,
      },
      '& input[type="radio"]:not(:checked)+label': {
        color: theme.palette.icons.nav,
      },
    },
  },
  buttonsContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    marginTop: '72px',
    marginRight: '24px',
  },
  defaultBtnStyle: {
    borderRadius: '8px',
    border: 'none',
    height: '48px',
    width: '136px',
    fontSize: '18px',
    color: theme.palette.icons.nav,
    fontFamily: theme.typography.default,
    fontWeight: '500',
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flext-start',
    gap: '12px',
    alignItems: 'center',
    paddingLeft: '12px',
  },
  btnDelete: {
    backgroundColor: '#ffccd4',
  },
  btnCancel: {
    backgroundColor: '#e2ecff',
  },
  btnSave: {
    backgroundColor: '#c6ffef',
  },
}));
