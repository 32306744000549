import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import React, { useState } from 'react';

import { ArrowDownIcon, SearchIcon } from '../../../components/icons';
import { customerSearchOptions, entitiesEnum, transactionSearchOptions } from '../../../constants';
import { useStyles } from './search-bar.hooks';

export const SearchBarDropDown = ({
  searchQuery,
  changeQuery,
  handleSubmit,
  clearQuery,
  itemSelected,
  setItemSelected,
  entity,
}) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  let options = [];
  if (entity === entitiesEnum.TRANSACTION) options = Object.values(transactionSearchOptions);
  if (entity === entitiesEnum.USERPROFILE) options = Object.values(customerSearchOptions);

  const menuItemHoverStyle = { ':hover': { background: '#c6ffef' } };

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className={classes.searchBarContainer}>
        <div className={classes.transactionSearchIconContainer} onClick={handleSubmit}>
          <IconButton>
            <SearchIcon />
          </IconButton>
        </div>
        <input
          type="text"
          id="search"
          placeholder="Search"
          autoComplete="off"
          value={searchQuery}
          onChange={changeQuery}
          className={classes.transactionSearchBar}
        />
        {searchQuery && (
          <div className={classes.transactionCloseIconContainer} onClick={clearQuery}>
            <IconButton>
              <CloseIcon fontSize="large" color="info" />
            </IconButton>
          </div>
        )}

        <Button
          id="basic-button"
          sx={{
            position: 'absolute',
            top: '4px',
            right: '34px',
            display: 'flex',
            flexDirection: 'row',
            gap: '16px',
            textTransform: 'none',
            borderRadius: '12px',
            width: 'auto',
            height: '24px',
            fontSize: '16px',
            color: '#0069f8',
            backgroundColor: '#e5f0ff',
            '&:hover': {
              backgroundColor: '#e5f0ff',
            },
          }}
          aria-controls={open ? 'basic-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
        >
          <p>{itemSelected}</p>
          <ArrowDownIcon />
        </Button>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          transformOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
        >
          {options.map(item => (
            <MenuItem
              key={item}
              sx={menuItemHoverStyle}
              onClick={() => {
                setItemSelected(item);
                handleClose();
              }}
            >
              {item}
            </MenuItem>
          ))}
        </Menu>
      </div>
    </form>
  );
};
