import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(theme => ({
  transactionRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    color: '#a5a6ae',
    gap: '0.5rem',
  },
  transactionRowDateTimeContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginRight: '1rem',
    cursor: 'pointer',
  },
  transactionRowIconContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: 'white',
    height: '32px',
    width: '32px',
    borderRadius: '4px',
    border: theme.palette.transactions.border,
  },
  transactionRowReferenceContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: '0.5rem',
    width: '250px',
    cursor: 'pointer',
  },
  transactionRowReferenceContainerUser: {
    display: 'flex',
    flexDirection: 'row',
    gap: '0.5rem',
    width: '200px',
    cursor: 'pointer',
  },
  textFormat: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    maxWidth: '30ch',
    whiteSpace: 'nowrap',
  },
  transactionRowVerticalLine: {
    borderLeft: `1px solid #a5a6ae`,
    height: '32px',
  },
  transactionRowPurposeNameContainer: {
    display: 'flex',
    flexDirection: 'column',
    minWidth: '250px',
    cursor: 'pointer',
  },
  transactionRowPurposeNameContainerReverse: {
    display: 'flex',
    flexDirection: 'column-reverse',
    minWidth: '250px',
    cursor: 'pointer',
  },
  transactionRowExchangeContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: '0.5rem',
    alignItems: 'center',
  },
  transactionRowClipBoardContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    background: 'white',
    padding: '0.4rem',
    paddingLeft: '1rem',
    borderRadius: '3rem',
    gap: '1rem',
    width: '375px',
    marginRight: '0.5rem',
    border: theme.palette.transactions.border,
  },
  userTransactionRowClipBoardContainer: {
    marginLeft: '5vw',
  },
  transactionRowClipBoardTextContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  transactionRowClipBoardIconContainer: {
    width: '24px',
    height: '24px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '50%',
    background: '#f8f8f9',
    '&:hover': {
      background: '#c6ffef',
      cursor: 'pointer',
    },
  },
  transactionRowFontColor: {
    color: theme.palette.icons.nav,
    fontWeight: 'bold',
  },
  transactionRowStatusContainer: {
    borderRadius: '0 16px 16px 16px',
    fontSize: '14px',
    padding: '0.1em 1em',
    width: '96px',
    height: '24px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
  },
  transactionRowStatusCompleted: {
    background: '#c6ffef',
    color: '#23c3a4',
  },
  transactionRowStatusFailed: {
    background: '#ffccd4',
    color: '#fc3333',
  },
  transactionRowStatusProcessing: {
    background: '#e5f0ff',
    color: '#0069f8',
  },
  attachmentIcon: {
    marginTop: '4px',
    marginLeft: '32px',
    cursor: 'pointer',
  },
  currencySendMoney: {
    marginLeft: 'auto',
    fontWeight: 'bold',
    textAlign: 'right',
    fontSize: '14px',
    color: theme.palette.icons.nav,
    cursor: 'pointer',
  },
  currencyConvertMoney: {
    marginLeft: 'auto',
    textAlign: 'right',
    cursor: 'pointer',
  },
  currencyConvertMoneyTop: {
    fontSize: '14px',
    fontWeight: 'bold',
    color: theme.palette.icons.nav,
  },
  currencyConvertMoneyBottom: {
    color: '#a5a6ae',
  },
  currencyAddMoney: {
    marginLeft: 'auto',
    fontWeight: 'bold',
    textAlign: 'right',
    fontSize: '14px',
    color: theme.palette.icons.nav,
    cursor: 'pointer',
  },
  failedStatusMsg: {
    color: '#fc3333',
    fontSize: '14px',
    marginLeft: '32px',
  },
  totalBalanceContainer: {
    marginLeft: '8px',
  },
}));
