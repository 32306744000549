import { IconButton, Tooltip } from '@mui/material';
import React from 'react';

import { palette } from '../../theme/palette';
import { SumsubIcon } from './components';

export const SumsubButton = ({ profileUrl, title = 'View on Sumsub', disabled }) => {
  const handleOnClick = event => {
    event.stopPropagation();
    if (disabled) return;
    window.open(profileUrl, '_blank');
  };

  const { sumsub } = palette.icons;
  const bodyColor = disabled ? sumsub.disabled : sumsub.body;
  const headColor = disabled ? sumsub.disabled : sumsub.head;

  return (
    <Tooltip title={title} placement="top">
      <span>
        <IconButton onClick={handleOnClick} disabled={disabled} size="large">
          <SumsubIcon bodyColor={bodyColor} headColor={headColor} />
        </IconButton>
      </span>
    </Tooltip>
  );
};
