import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
  search: {
    position: 'relative',
  },
  searchIcon: {
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: '0 0 0 2.5rem',
    fontWeight: 500,
    '&::placeholder': {
      fontWeight: 500,
      opacity: 1,
    },
  },
}));
