import { customerSortEnum } from '../constants';

export const sortCustomerData = (tableBodyData, customerSort) => {
  if (customerSort === customerSortEnum.CREATED_NEW) {
    const sortedData = [...tableBodyData];
    sortedData.sort((a, b) => +new Date(b.createdAt) - +new Date(a.createdAt));

    return sortedData;
  }

  if (customerSort === customerSortEnum.CREATED_OLD) {
    const sortedData = [...tableBodyData];
    sortedData.sort((a, b) => +new Date(a.createdAt) - +new Date(b.createdAt));

    return sortedData;
  }

  if (customerSort === customerSortEnum.NAME_AZ) {
    const sortedData = [...tableBodyData];
    sortedData.sort((a, b) => `${a.name} ${a.lastName}`.localeCompare(`${b.name} ${b.lastName}`));

    return sortedData;
  }

  if (customerSort === customerSortEnum.NAME_ZA) {
    const sortedData = [...tableBodyData];
    sortedData.sort((a, b) => `${b.name} ${b.lastName}`.localeCompare(`${a.name} ${a.lastName}`));

    return sortedData;
  }

  return tableBodyData;
};
