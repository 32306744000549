import React from 'react';

export const CHF = ({ width = '45', height = '45' }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 48 48">
    <defs>
      <clipPath id="axgksysdba">
        <path data-name="Rectangle 16633" style={{ fill: '#fff' }} d="M0 0h48v48H0z" />
      </clipPath>
    </defs>
    <g data-name="Group 8503">
      <g data-name="Group 96" style={{ clipPath: 'url(#axgksysdba)' }}>
        <path
          data-name="Path 10522"
          d="M16439.6 8256a24 24 0 1 0-24-24 24 24 0 0 0 24 24z"
          transform="translate(-16415.602 -8208)"
          style={{ fill: '#d80027' }}
        />
        <path
          data-name="Path 10523"
          d="M16563.043 8338.788h-8.346v-8.347h-8.342v8.347H16538v8.347h8.354v8.351h8.342v-8.351h8.346z"
          transform="translate(-16526.527 -8318.962)"
          style={{ fill: '#f0f0f0' }}
        />
      </g>
    </g>
  </svg>
);
