async function copyTextToClipboard(textToCopy) {
  if (window.isSecureContext && navigator.clipboard) {
    try {
      await navigator.clipboard.writeText(textToCopy);
      console.log('copied to clipboard');
    } catch (error) {
      console.log('failed to copy to clipboard. error=' + error);
    }
  } else {
      const textArea = document.createElement('textarea');
      textArea.value = textToCopy;
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();
      document.execCommand('copy');
      document.body.removeChild(textArea);
  }
}

function areAllValuesDefined(obj) {
  for (let key in obj) {
    if (obj[key] === undefined) {
      return false;
    }
  }
  return true;
}
export { copyTextToClipboard, areAllValuesDefined };
