import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(theme => ({
  subEntityForm: {
    width: '100%',
  },
  errorMessage: {
    color: 'red',
    marginRight: '2rem',
  },
  scrollContainer: {
    maxHeight: '70vh',
    overflow: 'scroll',
    paddingBottom: '15rem',
  },
  button: {
    fontSize: '1.6rem',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      opacity: '0.85',
    },
  },
}));
