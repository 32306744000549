import React from 'react';

export const BeneficiaryBlank = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="248" height="44" viewBox="0 0 248 44">
    <g data-name="Group 10056" transform="translate(-1424 -1152)">
      <circle
        data-name="Ellipse 1836"
        cx="20"
        cy="20"
        r="20"
        transform="translate(1424 1152)"
        style={{ fill: '#f2f2f4' }}
      />
      <g
        data-name="Ellipse 1837"
        transform="translate(1455 1174)"
        style={{ stroke: '#fff', fill: '#f2f2f4' }}
      >
        <circle cx="11" cy="11" r="11" style={{ stroke: 'none' }} />
        <circle cx="11" cy="11" r="10.5" style={{ fill: 'none' }} />
      </g>
      <rect
        data-name="Rectangle 18924"
        width="80"
        height="16"
        rx="8"
        transform="translate(1488 1152)"
        style={{ fill: '#f2f2f4' }}
      />
      <rect
        data-name="Rectangle 18925"
        width="184"
        height="16"
        rx="8"
        transform="translate(1488 1176)"
        style={{ fill: '#f2f2f4' }}
      />
    </g>
  </svg>
);
