import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import React from 'react';

import { toStringOrEmptyString } from '../../../../utils';
import { useStyles } from './filter-select-enum.hooks';

export const FilterSelectEnum = ({ field, filters, setFilters }) => {
  const {
    label,
    filter,
    filter: { schemaField },
  } = field;
  const ALL_OPTIONS = { value: 'all', label };

  const classes = useStyles();

  const value = filters[schemaField] || 'all';
  const selectList = [ALL_OPTIONS, ...filter.enum];

  const onChange = e => {
    const newFilters = { ...filters, [schemaField]: e.target.value };
    setFilters(newFilters);
  };

  return (
    <TextField
      className={classes.root}
      id="name"
      select
      value={toStringOrEmptyString(value)}
      onChange={onChange}
      InputProps={{ className: classes.label, disableUnderline: true }}
    >
      {selectList.map(option => (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      ))}
    </TextField>
  );
};
