import { Grid } from '@mui/material';
import React from 'react';

import {
  fieldBreaksLineAfter,
  fieldBreaksLineBefore,
  getCustomComponent,
  getFieldBackground,
  getRelatedEntityFieldName,
  isFieldExcluded,
  isHiddenField,
  isManualIdFieldShown,
  isReadOnlyField,
} from '../../utils';
import {
  FormBooleanField,
  FormEntitySelectField,
  FormEnumSelectField,
  FormFreeStringList,
  FormImageUploadField,
  FormImageUploadList,
  FormTextField,
  FormVideoUploadField,
} from './components';
import { getFieldType, getFieldWidth } from './form-field.utils';

export const FormField = ({ field, id, entity, watch, subObject, getValues, ...defaultProps }) => {
  const showIdField = isManualIdFieldShown(entity);
  const isExcluded = isFieldExcluded(field.name);

  if (!showIdField && isExcluded && id === '0') {
    return null;
  }

  const disabled =
    field.name === 'createdAt' ||
    field.name === 'updatedAt' ||
    field.name === 'order' ||
    (field.name === 'id' && id !== '0');

  const values = getValues();
  const isHidden = isHiddenField(values, field.name, entity, subObject);
  const breaksLineBefore = fieldBreaksLineBefore(entity, field.name);
  const isReadOnly = isReadOnlyField(entity, field.name);
  const breaksLineAfter = fieldBreaksLineAfter(entity, field.name);
  const fieldWidth = defaultProps.fullWidth ? 12 : getFieldWidth(field.type);
  const fieldType = getFieldType(field.type);
  const fieldBackground = getFieldBackground(entity, field.name);

  const isMultiple = field.type === 'entitySelectMultiple';

  const fieldStyles = (order, margin) => ({
    display: isHidden ? 'none' : 'block',
    backgroundColor: fieldBackground,
    borderRadius: '0.4rem',
    order: order || '',
    margin: margin || '',
  });

  const fieldProps = {
    id,
    entity,
    field,
    disabled,
    watch,
    isReadOnly,
    type: fieldType,
    ...defaultProps,
  };

  const CustomComponent = getCustomComponent(entity, field.name);

  if (CustomComponent) {
    return (
      <>
        {breaksLineBefore && <Grid item xs={12} />}
        <Grid item md={12} style={fieldStyles(1)} xs={12}>
          <CustomComponent {...fieldProps} />
        </Grid>
        {breaksLineAfter && <Grid item xs={12} />}
      </>
    );
  }

  if (field.name === 'id') {
    return showIdField ? (
      <>
        {breaksLineBefore && <Grid item xs={12} />}
        <Grid item md={fieldWidth} xs={12}>
          <FormTextField {...fieldProps} />
        </Grid>
        {breaksLineAfter && <Grid item xs={12} />}
      </>
    ) : (
      <div style={{ display: 'none' }}>
        <FormTextField {...fieldProps} />
      </div>
    );
  }

  if (field.type === 'string') {
    return (
      <>
        {breaksLineBefore && <Grid item xs={12} />}
        <Grid item md={fieldWidth} style={fieldStyles(2)} xs={12}>
          <FormTextField {...fieldProps} />
        </Grid>
        {breaksLineAfter && <Grid item xs={12} />}
      </>
    );
  }

  if (field.type === 'boolean') {
    return (
      <>
        {breaksLineBefore && <Grid item xs={12} />}
        <Grid item md={fieldWidth} style={fieldStyles(3)} xs={12}>
          <FormBooleanField {...fieldProps} />
        </Grid>
        {breaksLineAfter && <Grid item xs={12} />}
      </>
    );
  }

  if (field.type === 'float' || field.type === 'int') {
    return (
      <>
        {breaksLineBefore && <Grid item xs={12} />}
        <Grid item md={fieldWidth} style={fieldStyles(2)} xs={12}>
          <FormTextField {...fieldProps} />
        </Grid>
        {breaksLineAfter && <Grid item xs={12} />}
      </>
    );
  }

  if (field.type === 'date') {
    return (
      <>
        {breaksLineBefore && <Grid item xs={12} />}
        <Grid item md={fieldWidth} style={fieldStyles(2)} xs={12}>
          <FormTextField {...fieldProps} />
        </Grid>
        {breaksLineAfter && <Grid item xs={12} />}
      </>
    );
  }

  if (field.type === 'timestamp') {
    return (
      <>
        {breaksLineBefore && <Grid item xs={12} />}
        <Grid item md={fieldWidth} style={fieldStyles(2)} xs={12}>
          <FormTextField {...fieldProps} />
        </Grid>
        {breaksLineAfter && <Grid item xs={12} />}
      </>
    );
  }

  if (field.type === 'enumSelect') {
    return (
      <>
        {breaksLineBefore && <Grid item xs={12} />}
        <Grid item md={fieldWidth} style={fieldStyles(1, '1rem')} xs={12}>
          <FormEnumSelectField {...fieldProps} options={field.options} />
        </Grid>
        {breaksLineAfter && <Grid item xs={12} />}
      </>
    );
  }

  if (field.type === 'entitySelect' || isMultiple) {
    const entityFieldName = getRelatedEntityFieldName(entity, field.name);

    return (
      <>
        {breaksLineBefore && <Grid item xs={12} />}
        <Grid item md={fieldWidth} style={fieldStyles(isMultiple ? 3 : 1)} xs={12}>
          <FormEntitySelectField
            {...fieldProps}
            entityFieldName={entityFieldName}
            multiple={isMultiple}
          />
        </Grid>
        {breaksLineAfter && <Grid item xs={12} />}
      </>
    );
  }

  if (field.type === 'listFree') {
    return (
      <>
        {breaksLineBefore && <Grid item xs={12} />}
        <Grid item md={fieldWidth} style={fieldStyles(2)} xs={12}>
          <FormFreeStringList {...fieldProps} entityFieldName={field.name} />
        </Grid>
        {breaksLineAfter && <Grid item xs={12} />}
      </>
    );
  }

  if (field.type === 'videoUpload') {
    return (
      <>
        <Grid item md={fieldWidth} style={fieldStyles(4)} xs={12}>
          <FormVideoUploadField {...fieldProps} />
        </Grid>
        {!isHidden && breaksLineAfter && <Grid item xs={12} />}
      </>
    );
  }

  if (field.type === 'imageUpload') {
    return (
      <>
        {breaksLineBefore && <Grid item xs={12} />}
        <Grid item md={fieldWidth} style={fieldStyles(4)} xs={12}>
          <FormImageUploadField {...fieldProps} />
        </Grid>
        {breaksLineAfter && <Grid item xs={12} />}
      </>
    );
  }

  if (field.type === 'imgUploadList') {
    return (
      <>
        {breaksLineBefore && <Grid item xs={12} />}
        <Grid item md={12} style={fieldStyles(4)} xs={12}>
          <FormImageUploadList {...fieldProps} />
        </Grid>
        {breaksLineAfter && <Grid item xs={12} />}
      </>
    );
  }

  return null;
};
