import { makeStyles } from '@mui/styles';

import { accountStatus } from '../../theme/styles';

export const useStyles = makeStyles(() => ({
  root: {
    padding: '3.2rem',
  },
  groupCard: {
    borderRadius: '0.4rem',
    padding: '2rem',
  },
  stickyHeader: {
    position: 'sticky',
    top: '6.4rem',
    zIndex: 2,
    borderRadius: '0.4rem',
    paddingBottom: '3.2rem',
  },
  accountStatus,
}));
