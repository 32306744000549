import { Chip, FormControl, TextField } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import React, { useEffect, useState } from 'react';
import { uid } from 'react-uid';

import { useStyles } from './form-free-string-list.hooks';

const DEFAULT_OPTIONS = [];
const DEFAULT_VALUE = [];

export const FormFreeStringList = ({
  errors,
  entityFieldName,
  field,
  register,
  rules,
  setValue,
  watch,
}) => {
  const [open, setOpen] = useState(false);
  const { label, name } = field;
  const labelId = `label-select-${name}`;
  const classes = useStyles();

  useEffect(() => {
    register(entityFieldName, rules);
  }, []);

  const handleAutocompleteChange = (event, value) => {
    const valuesDeduplicated =
      value && value.length ? value.filter((val, i) => value.indexOf(val) === i) : [];

    return setValue(entityFieldName, valuesDeduplicated);
  };
  const value = watch(entityFieldName, DEFAULT_VALUE);
  const notNullValue = value === null ? DEFAULT_VALUE : value;

  return (
    <FormControl variant="outlined" className={classes.formControl}>
      <Autocomplete
        freeSolo
        id={labelId}
        multiple
        onChange={handleAutocompleteChange}
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
        open={open}
        options={DEFAULT_OPTIONS}
        value={notNullValue}
        renderInput={params => (
          <TextField
            {...params}
            error={!!errors}
            fullWidth
            label={label}
            variant="outlined"
            InputProps={params.InputProps}
          />
        )}
        renderTags={(value, getTagProps) =>
          value.map((option, index) => (
            <Chip key={uid(option)} variant="outlined" label={option} {...getTagProps({ index })} />
          ))
        }
      />
    </FormControl>
  );
};
