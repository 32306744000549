import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { PairsDropDownArrow } from '../../../icons/pairs';
import { useStyles } from './currencies-input-field.hooks';
import { InputModal } from './modal/input-modal';

// When user clicks on an input field, a popup modal appears allowing user to select options
export const CurrenciesInputField = ({ field, size = 'normal', currencies }) => {
  const [open, setOpen] = useState(false);
  const classes = useStyles();
  const pairsCurrenciesObj = useSelector(state => state.pairsCurrenciesObj);
  const dispatch = useDispatch();
  let inputText = '';

  if (pairsCurrenciesObj[field]) {
    if (pairsCurrenciesObj[field].length === Object.keys(currencies).length) {
      inputText = 'All';
    } else {
      pairsCurrenciesObj[field].forEach(item => {
        inputText += `${item}, `;
      });
      inputText = inputText.slice(0, inputText.length - 2);
    }
  }

  if (inputText.length > 30) inputText = `${inputText.slice(0, 30)}...`;

  const closeModal = () => {
    setOpen(false);
  };

  const inputOrSelectChange = event => {
    dispatch({ type: 'SET_PAIRS_CURRENCIES_OBJ', payload: { [field]: [event.target.value] } });
  };

  const isPopupField =
    field === 'Sending Currency' || field === 'Country' || field === 'Receiving Currency';

  return (
    <>
      {isPopupField ? (
        <div
          className={`${classes.mainContainer} ${size === 'big' && classes.bigSize}`}
          onClick={() => setOpen(true)}
        >
          <p>{field}</p>
          <div className={classes.inputContainer}>
            <p>{inputText}</p>
            <PairsDropDownArrow />
          </div>
        </div>
      ) : (
        <div className={classes.mainContainer}>
          <p>{field}</p>
          {field === 'Status' && (
            <select
              onChange={inputOrSelectChange}
              value={pairsCurrenciesObj[field] ? pairsCurrenciesObj[field][0] : ''}
            >
              <option disabled value="" />
              <option value="inactive">Inactive</option>
              <option value="active">Active</option>
            </select>
          )}
          {field === 'Sort' && (
            <input
              type="number"
              onChange={inputOrSelectChange}
              value={pairsCurrenciesObj[field] ? pairsCurrenciesObj[field][0] : ''}
            />
          )}
        </div>
      )}
      <Modal open={open} onClose={() => setOpen(false)}>
        <Box>
          <div className={classes.modalStyle}>
            <InputModal field={field} closeModal={closeModal} currencies={currencies} />
          </div>
        </Box>
      </Modal>
    </>
  );
};
