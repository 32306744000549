import React from 'react';

export const CancelIcon = ({ width = '24px', height = '24px' }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 24 24">
    <g data-name="Group 10183" transform="translate(-741 -996)">
      <circle
        data-name="Ellipse 1859"
        cx="12"
        cy="12"
        r="12"
        transform="translate(741 996)"
        style={{ fill: '#ffccd4' }}
      />
      <path
        data-name="Union 922"
        d="m-937.134-5641.9 3.844-3.875a.649.649 0 0 0 0-.9l-.021-.021a.613.613 0 0 0-.867.018l-3.848 3.878-3.87-3.895-.021-.023a.615.615 0 0 0-.42-.169h-.021a.612.612 0 0 0-.431.19.649.649 0 0 0 0 .9l3.863 3.9-3.958 3.924a.652.652 0 0 0 0 .9l.087.088.006-.012a.634.634 0 0 0 .354.112.625.625 0 0 0 .44-.187l3.966-3.931 3.9 3.926.04.037a.616.616 0 0 0 .4.152h.023a.611.611 0 0 0 .425-.191.652.652 0 0 0 0-.9z"
        transform="translate(1691.064 6649.888)"
        style={{ fill: '#fc3333' }}
      />
    </g>
  </svg>
);
