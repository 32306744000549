import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(theme => ({
  accountContainer: {
    fontFamily: theme.typography.default,
    color: theme.palette.icons.nav,
    overflow: "hidden"
  },
  accountHeader: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginRight: '16px',
  },
  accountLabel: {
    display: 'flex',
    flexDirection: 'row',
    gap: '8px',
  },
  profileRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '16px',
    margin: '26px 16px 8px 16px',
  },
  lightBlueContainer: {
    backgroundColor: '#e2ecff',
    borderRadius: '0 12px 12px 12px',
    width: '296px',
    height: '56px',
    display: 'flex',
    flexDirection: 'row',
    gap: '8px',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    padding: '5px 29px 4px 16px',
  },
  accountDetailsText: {
    '& p:first-child': {
      fontSize: '14px',
    },
    '& p:last-child': {
      fontSize: '16px',
      fontWeight: '500',
      marginTop: '4px',
    },
  },
  ibanDetails: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    minWidth: '368px',
    gap: '8px',
    padding: '12px',
    fontSize: '14px',
    backgroundColor: '#ebfaff',
    borderRadius: '0 12px 12px 12px',
    margin: '0 16px 16px 16px',
  },
  ibanAccountDetailsLHS: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '8px',
    width: '10%',
  },
  verticalLine: {
    width: '1px',
    height: '60px',
    margin: '0 4px',
    borderRight: '1px dashed #19365e',
  },
  ibanAccountDetailsRHS: {
    width: '80%',
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
    '& > div': {
      display: 'flex',
      flexDirection: 'row',
      '& p:first-child': {
        width: '30%',
        minWidth: '89px',
      },
      '& p:nth-child(2)': {
        width: '65%',
        minWidth: '194px',
      },
    },
  },
  clipBoardIcon: {
    cursor: 'pointer',
  },
  membershipDetails: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    gap: '16px',
    fontSize: '12px',
  },
  membershipPlan: {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    fontWeight: 'bold',
    '& div': {
      display: 'flex',
      flexDirection: 'row',
      gap: '8px',
      alignItems: 'center',
    },
  },
  deviceDetails: {
    backgroundColor: '#f1f1f3',
    borderRadius: '0 12px 12px 12px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    width: '176px',
    height: '128px',
    '& div': {
      display: 'flex',
      flexDirection: 'row',
      gap: '8px',
      alignItems: 'center',
      paddingLeft: '32px',
    },
  },
  saveBtn: {
    cursor: 'pointer',
  },
  accountType: {
    fontSize: '14px',
    fontWeight: 'bold',
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
    alignItems: 'center',
  },
  dashedLine: {
    borderTop: '1px solid #a5a6ae',
    borderTopStyle: 'dashed',
    marginTop: '39.1px',
    marginLeft: '16px',
    marginRight: '16px',
  },
  accountStatus: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: '40px',
    marginBottom: '-16px',
  },
  closedTarget: {
    position: 'absolute',
    top: '0px',
    left: '86px',
    cursor: 'pointer',
  },
  activeTarget: {
    position: 'absolute',
    top: '61px',
    right: '0px',
    cursor: 'pointer',
  },
  suspendedTarget: {
    position: 'absolute',
    bottom: '0px',
    right: '33px',
    cursor: 'pointer',
  },
  rejectedTarget: {
    position: 'absolute',
    bottom: '0px',
    left: '32px',
    cursor: 'pointer',
  },
  createdTarget: {
    position: 'absolute',
    top: '61px',
    left: '0px',
    cursor: 'pointer',
  },
  statusTreeContainer: {
    position: 'relative',
    fontSize: '16px',
  },
  active: {
    position: 'absolute',
    top: '61px',
    right: '0px',
  },
  suspended: {
    position: 'absolute',
    bottom: '0px',
    right: '33px',
  },
  closed: {
    position: 'absolute',
    top: '0px',
    left: '86px',
  },
  created: {
    position: 'absolute',
    top: '61px',
    left: '0px',
  },
  account_verification_failed: {
    position: 'absolute',
    bottom: '0px',
    left: '32px',
  },
  hard_failed: {
    position: 'absolute',
    bottom: '0px',
    left: '32px',
  },
  soft_failed: {
    position: 'absolute',
    bottom: '0px',
    left: '32px',
  },
  rejectedText: {
    position: 'absolute',
    bottom: '15px',
    left: '-39px',
  },
  activeText: {
    position: 'absolute',
    right: '-55px',
    top: '72px',
  },
  createdText: {
    position: 'absolute',
    top: '72px',
    left: '-67px',
  },
  closedText: {
    position: 'absolute',
    top: '-26px',
    left: '82px',
  },
  suspendedText: {
    position: 'absolute',
    bottom: '15px',
    right: '-56px',
  },
  riskRating: {
    paddingTop: '20px',
    height: '248px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '& p': {
      fontSize: '16px',
      fontWeight: '500',
    },
    overflow: "hidden"
  },
  riskRatingTitle: {
    alignSelf: 'flex-start',
    zIndex: "1"
  },
  lowText: {
    position: 'absolute',
    top: '75px',
    left: '66px',
    fontSize: '16px',
    fontWeight: '500',
    transform: 'rotate(-45deg)',
    WebkitTransform: 'rotate(-45deg)',
    msTransform: 'rotate(-45deg)',
  },
  mediumText: {
    position: 'absolute',
    fontSize: '16px',
    fontWeight: '500',
    top: '52px',
    left: '127px',
  },
  highText: {
    position: 'absolute',
    fontSize: '16px',
    fontWeight: '500',
    top: '80px',
    right: '57px',
    transform: 'rotate(45deg)',
  },
  medium: {
    position: 'absolute',
    bottom: '-33px',
    left: '147px',
  },
  low: {
    position: 'absolute',
    bottom: '-33px',
    left: '118px',
    transform: 'rotate(-45deg)',
  },
  high: {
    position: 'absolute',
    bottom: '-33px',
    left: '168px',
    transform: 'rotate(52deg)',
  },
  detailBtnContainer: {
    margin: "10px 50px",
  },
  detailBtn: {
    fontFamily: 'Gilroy, Roboto , Helvetica, Arial, sans-serif',
    width: '100%',
    height: '32px',
    borderRadius: '16px',
    border: '1px solid #2BE4B0',
    fontWeight: '500',
    fontSize: '13px',
    textAlign: 'center',
    paddingTop: '5px',
    cursor: 'pointer',
    color: '#19365e',
    overflow:"hidden",
  },
}));
