import React from 'react';

export const NumberOfHighRiskAccount = ({ className, color }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40">
    <g data-name="Group 10321">
      <g data-name="Group 10303">
        <g data-name="Group 10296" transform="translate(-328 -504)">
          <rect
            data-name="Rectangle 16974"
            width="40"
            height="40"
            rx="8"
            transform="translate(328 504)"
            fill="#ff6d6d"
          />
        </g>
      </g>
      <g data-name="Group 10309">
        <g data-name="Group 10316">
          <path
            data-name="Path 23420"
            d="M3 5a3 3 0 1 1-3 3 3 3 0 0 1 3-3"
            transform="translate(8 11)"
            fill="#fff"
          />
        </g>
        <g data-name="Group 10315">
          <path
            data-name="Path 23422"
            d="M10 11a3 3 0 1 1-3 3 3 3 0 0 1 3-3"
            transform="translate(8 11)"
            fill="#fff"
          />
        </g>
        <g data-name="Group 10314">
          <path
            data-name="Path 23424"
            d="M15.312 5.535a3 3 0 1 1-3 3 3 3 0 0 1 3-3"
            transform="translate(8 11)"
            fill="#fff"
          />
        </g>
        <g data-name="Group 10313">
          <path
            data-name="Path 23426"
            d="M21 0a3 3 0 1 1-3 3 3 3 0 0 1 3-3"
            transform="translate(8 11)"
            fill="#fff"
          />
        </g>
      </g>
    </g>
  </svg>
);
