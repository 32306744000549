import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(theme => ({
  root: {
    marginLeft: 0,
    padding: '1.4rem 1.5rem',
    width: '100%',
    backgroundColor: theme.palette.background.default,
    borderRadius: '0.7rem',
  },
  label: {
    marginRight: 'auto',
    fontWeight: 'bold',
  },
}));
