import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(theme => ({
  exportButton: {
    fontSize: '14px',
    color: theme.palette.icons.nav,
    textTransform: 'none',
    border: '1px solid #1fe3ac',
    borderRadius: '16px',
    width: '152px',
    height: '32px',
    display: 'flex',
    flexDirection: 'row',
  },
  exportIcon: {
    marginTop: '8px',
    marginLeft: '-19px',
    cursor: 'pointer',
  },
  exportLabel: {
    fontSize: '14px',
    fontFamily: theme.typography.default,
    [theme.breakpoints.down('1200')]: {
      fontSize: '12px',
    },
  },
  arrowIcon: {
    position: 'relative',
    left: '8px',
  },
  exportButtonLink: {
    fontSize: '1.6rem',
    color: '#fff',
  },
  exportMainButton: {
    marginRight: '0.8rem',
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      opacity: '0.85',
    },
    '&:active': {
      backgroundColor: theme.palette.primary.main,
      opacity: '0.85',
    },
  },
  exportCSV: {
    color: theme.palette.text.primary,
  },
}));
