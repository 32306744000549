import React from 'react';

export const NumberOfPendingThisWeek = ({ className, color }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40">
    <g data-name="Group 10352">
      <g data-name="Group 10292" transform="translate(-328 -504)">
        <rect
          data-name="Rectangle 16974"
          width="40"
          height="40"
          rx="8"
          transform="translate(328 504)"
          fill="#a5a6ae"
        />
      </g>
      <g data-name="Group 10298">
        <path
          data-name="Path 23420"
          d="M-17674.479-5649.852a1.686 1.686 0 0 1-1.189-.493l-6.811-6.81a1.682 1.682 0 0 1 0-2.381 1.685 1.685 0 0 1 2.383 0l5.617 5.618 10.451-10.452a1.685 1.685 0 0 1 2.381 0 1.683 1.683 0 0 1 0 2.382l-11.641 11.643a1.687 1.687 0 0 1-1.191.493z"
          transform="translate(17692.06 5676.953)"
          fill="#fff"
        />
        <path
          data-name="Path 23420 - Outline"
          d="M-17674.588-5649.961a1.684 1.684 0 0 0 1.191-.493l11.641-11.643a1.683 1.683 0 0 0 0-2.382 1.684 1.684 0 0 0-1.191-.493 1.679 1.679 0 0 0-1.189.493l-10.451 10.452-5.617-5.618a1.685 1.685 0 0 0-2.383 0 1.682 1.682 0 0 0 0 2.381l6.811 6.81a1.686 1.686 0 0 0 1.189.493m0 1.091a2.788 2.788 0 0 1-1.963-.814l-6.809-6.808a2.781 2.781 0 0 1 0-3.924 2.759 2.759 0 0 1 1.963-.812 2.761 2.761 0 0 1 1.963.812l4.846 4.847 9.682-9.681a2.753 2.753 0 0 1 1.959-.812 2.767 2.767 0 0 1 1.963.812 2.756 2.756 0 0 1 .814 1.963 2.753 2.753 0 0 1-.814 1.962l-11.641 11.642a2.759 2.759 0 0 1-1.964.813z"
          transform="translate(17692.17 5677.062)"
          fill="none"
        />
      </g>
      <g data-name="Group 10348">
        <g data-name="Group 10316">
          <path
            data-name="Path 23420"
            d="M4 5a4 4 0 1 1-4 4 4 4 0 0 1 4-4"
            transform="translate(13 3)"
            fill="#fff"
          />
        </g>
      </g>
    </g>
  </svg>
);
