import React from 'react';

export const AccountStatus = ({ status }) => {
  let colorFill = '';

  switch (status) {
    case 'active':
      colorFill = '#1fe3ac';
      break;
    case 'suspended':
      colorFill = '#a5a6ae';
      break;
    case 'closed':
      colorFill = '#fc3333';
      break;
    case 'created':
      colorFill = '#0cc5ff';
      break;
    default:
      colorFill = '#f8d753';
  }

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40">
      <g data-name="Group 8222">
        <g data-name="Ellipse 1455" style={{ stroke: '#19365e', strokeWidth: '2px', fill: '#fff' }}>
          <circle cx="20" cy="20" r="20" style={{ stroke: 'none' }} />
          <circle cx="20" cy="20" r="19" style={{ fill: 'none' }} />
        </g>
        <g
          data-name="Ellipse 1456"
          transform="translate(10 10)"
          style={{ fill: colorFill, stroke: '#19365e', strokeWidth: '2px' }}
        >
          <circle cx="10" cy="10" r="10" style={{ stroke: 'none' }} />
          <circle cx="10" cy="10" r="9" style={{ fill: 'none' }} />
        </g>
      </g>
    </svg>
  );
};
