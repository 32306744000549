import React from 'react';

import { entitiesEnum } from '../../constants';
import { useGraphList } from '../../hooks';
import { AccordionCard } from '../accordion';
import { PairsDetails } from './components';

export const CurrencyPairsList = () => {
  const listData = useGraphList({ entity: entitiesEnum.CURRENCY });

  const { data } = listData;

  if (!data) {
    return null;
  }

  return data.map(({ id, name, walletBgColor, active }) => {
    const labelConfig = !active && {
      class: 'disabledCard',
      title: 'DISABLED',
    };

    return (
      <AccordionCard
        key={id}
        id={id}
        title={`${id} (${name})`}
        colorIndicator={walletBgColor}
        labelConfig={labelConfig}
      >
        <PairsDetails />
      </AccordionCard>
    );
  });
};
