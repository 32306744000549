import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { DottedLineIcon } from '../../../../components/icons';
import { entitiesEnum, transactionSortEnum } from '../../../../constants';
import { useStyles } from './sort-modal.hooks';

export const TransactionSortModal = ({ entity, onClose }) => {
  const [sortType, setSortType] = useState('');
  const classes = useStyles();
  const dispatch = useDispatch();
  const transactionEntitySort = useSelector(state => state.transactionSort);
  const userTransactionSort = useSelector(state => state.userTransactionSort);

  let transactionSort = transactionEntitySort;
  if (entity === entitiesEnum.USERPROFILE) transactionSort = userTransactionSort;
  if (entity === entitiesEnum.TRANSACTION) transactionSort = transactionEntitySort;

  const handleSubmit = event => {
    event.preventDefault();
    if (entity === entitiesEnum.TRANSACTION) {
      dispatch({ type: 'SET_TRANSACTION_SORT', payload: sortType });
    }

    if (entity === entitiesEnum.USERPROFILE) {
      dispatch({ type: 'SET_USER_TRANSACTION_SORT', payload: sortType });
    }
    onClose();
  };

  useEffect(() => {
    setSortType(transactionSort);
  }, []);

  return (
    <div className={classes.modalContainer}>
      <div className={classes.modalTitleContainer}>Sort</div>
      <DottedLineIcon />
      <form className={classes.form} onSubmit={handleSubmit}>
        <div>
          <input
            type="radio"
            id="dateNewest"
            name="sortAnswer"
            checked={sortType === transactionSortEnum.DATENEWEST}
            onChange={() => setSortType(transactionSortEnum.DATENEWEST)}
          />
          <label htmlFor="dateNewest">Date (newest first)</label>
        </div>
        <div>
          <input
            type="radio"
            id="dateOldest"
            name="sortAnswer"
            checked={sortType === transactionSortEnum.DATEOLDEST}
            onChange={() => setSortType(transactionSortEnum.DATEOLDEST)}
          />
          <label htmlFor="dateOldest">Date (oldest first)</label>
        </div>
        <div>
          <input
            type="radio"
            id="amountLargest"
            name="sortAnswer"
            checked={sortType === transactionSortEnum.AMOUNTLARGEST}
            onChange={() => setSortType(transactionSortEnum.AMOUNTLARGEST)}
          />
          <label htmlFor="amountLargest">Amount (largest first)</label>
        </div>
        <div>
          <input
            type="radio"
            id="amountSmallest"
            name="sortAnswer"
            checked={sortType === transactionSortEnum.AMOUNTSMALLEST}
            onChange={() => setSortType(transactionSortEnum.AMOUNTSMALLEST)}
          />
          <label htmlFor="amountSmallest">Amount (smallest first)</label>
        </div>
        <DottedLineIcon />
        <button type="submit" className={classes.sortButton}>
          Sort It
        </button>
      </form>
    </div>
  );
};
