import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(theme => ({
  templatesRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& aside': {
      display: 'flex',
      flexDirection: 'row',
      gap: '24px',
      marginTop: '56px',
      marginRight: '24px',
    },
  },
  templatesContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: '48px',
    marginTop: '64px',
    marginLeft: '24px',
  },
  singleTemplateBtnContainer: {
    position: 'relative',
  },
  templateBtnDefault: {
    width: '128px',
    height: '40px',
    border: 'none',
    background: '#f1f1f3',
    borderRadius: '20px',
    fontSize: '18px',
    color: '#a5a6ae',
    textAlign: 'center',
    fontFamily: theme.typography.default,
    cursor: 'pointer',
  },
  templateBtnActive: {
    background: '#e5f0ff',
    border: '1px solid #0069f8',
    color: theme.palette.icons.nav,
    fontWeight: '500',
  },
  templateDeleteIcon: {
    position: 'absolute',
    top: '-12px',
    right: '-3px',
    cursor: 'pointer',
  },
  applyTemplateBtn: {
    width: '160px',
    height: '48px',
    borderRadius: '8px',
    background: '#9fe3ff',
    border: 'none',
    fontSize: '18px',
    fontWeight: '500',
    color: theme.palette.icons.nav,
    fontFamily: theme.typography.default,
    cursor: 'pointer',
  },
  templateBigEdit: {
    cursor: 'pointer',
  },
}));
