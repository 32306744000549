const intervalHandler = (start, end) => {
  let newStart = start;
  let newEnd = end;

  if (start >= 100000) {
    newStart = `${start / 1000}K`;
  }

  if (start >= 1000000) {
    newStart = `${start / 1000000}M`;
  }

  if (end >= 100000) {
    newEnd = `${end / 1000}K`;
  }

  if (end >= 1000000) {
    newEnd = `${end / 1000000}M`;
  }

  if (end === null) {
    return `OVER ${newStart}`;
  }

  return `${newStart} - ${newEnd}`;
};

export { intervalHandler };
