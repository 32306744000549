import React from 'react';

export const HKD = ({ width = '45', height = '45' }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 48 47.999">
    <g data-name="Group 8507">
      <path
        data-name="Path 9831"
        d="M3672 9168a24 24 0 1 0-24-24 24 24 0 0 0 24 24z"
        transform="translate(-3648 -9120.001)"
        style={{ fill: '#d80027' }}
      />
      <path
        data-name="Path 9832"
        d="M3872.1 9250.581c-.506 2.1-1.4 1.7-1.845 3.536a4.837 4.837 0 0 1 2.261-9.405c-.886 3.668.031 3.988-.416 5.869z"
        transform="translate(-3845.824 -9232.666)"
        style={{ fill: '#f0f0f0' }}
      />
      <path
        data-name="Path 9833"
        d="M3788.089 9308.39c1.843 1.13 1.184 1.861 2.792 2.847a4.836 4.836 0 0 1-8.245-5.056c3.215 1.971 3.803 1.197 5.453 2.209z"
        transform="translate(-3769.135 -9288.473)"
        style={{ fill: '#f0f0f0' }}
      />
      <path
        data-name="Path 9834"
        d="M3806.2 9392.227c1.645-1.4 2.136-.553 3.571-1.775a4.835 4.835 0 1 1-7.357 6.277c2.866-2.448 2.311-3.247 3.786-4.502z"
        transform="translate(-3787.683 -9364.692)"
        style={{ fill: '#f0f0f0' }}
      />
      <path
        data-name="Path 9835"
        d="M3913.1 9396.641c-.827-2 .136-2.2-.585-3.944a4.835 4.835 0 1 1 3.7 8.936c-1.448-3.485-2.379-3.204-3.115-4.992z"
        transform="translate(-3887.392 -9366.305)"
        style={{ fill: '#f0f0f0' }}
      />
      <path
        data-name="Path 9836"
        d="M3929.965 9320.135c-2.156.168-2.052-.811-3.933-.662a4.836 4.836 0 0 1 9.643-.758c-3.76.295-3.781 1.267-5.71 1.42z"
        transform="translate(-3899.425 -9295.844)"
        style={{ fill: '#f0f0f0' }}
      />
    </g>
  </svg>
);
