import { graphqlApiDecorator } from 'decorators';
import React, { useEffect, useState } from 'react';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { useStyles } from './related-shareholders.hook';
import { queries } from '../../graphql';
import { Loader } from 'components/loader';
import { SmallSideRectangle } from 'components/icons';
import { getKycInfo } from 'utils';

export const RelatedShareholders = ({ userId }) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const classes = useStyles();

  const fetchShareholders = async () => {
    const queryTitle = 'fetchRelatedShareHolders';
    setLoading(true);
    try {
      const result = await graphqlApiDecorator(queries, queryTitle, {
        id: userId,
      });
      setData(result || []);
      setLoading(false);

      if (result) {
      }
    } catch (error) {}
  };

  const handleRowClick = shareholder => {
    const input = { applicantId: shareholder.applicantId };
    const { kycLink } = getKycInfo(input);
    if (shareholder.applicantId) {
      window.open(kycLink, '_blank');
    }
  };

  useEffect(() => {
    fetchShareholders();
  }, []);
  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <TableContainer component={Paper} className={classes.shareholdersContainer}>
          <header className={classes.headerContainer}>
            <SmallSideRectangle />
            <p>Shareholders</p>
          </header>
          <Table>
            <TableHead>
              <TableRow className={classes.firstRow}>
                <TableCell className={classes.tableCell}>KYC Status</TableCell>
                <TableCell className={classes.tableCell}>Account Type</TableCell>
                <TableCell className={classes.tableCell}>Email</TableCell>
                <TableCell className={classes.tableCell}>Last Name</TableCell>
                <TableCell className={classes.tableCell}>Name</TableCell>
                <TableCell className={classes.tableCell}>Phone Number</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.length > 0 ? (
                data.map(item => (
                  <TableRow
                    key={item.id}
                    className={classes.tableRow}
                    onClick={() => handleRowClick(item)}
                    style={{ cursor: item.applicantId ? 'pointer' : 'not-allowed' }}
                  >
                    <TableCell className={classes.tableCell}>{item.accountStatus}</TableCell>
                    <TableCell className={classes.tableCell}>{item.accountType}</TableCell>
                    <TableCell className={classes.tableCell}>{item.email}</TableCell>
                    <TableCell className={classes.tableCell}>{item.lastName}</TableCell>
                    <TableCell className={classes.tableCell}>{item.name}</TableCell>
                    <TableCell className={classes.tableCell}>{item.phoneNumber}</TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={7} className={classes.tableCell}>
                    No data available
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </>
  );
};
