import { TextField } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledTextField = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    '& input': {
      fontSize: '1.8rem',
      paddingTop: '1.2rem',
      paddingBottom: '1.2rem',
      height: '4.5rem',
      boxSizing: 'border-box',
    },
    '& fieldset': {
      border: `0.1rem ${theme.palette.newDesign.border} solid`,
    },
  },
}));
