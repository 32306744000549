import { useCallback, useEffect, useState } from 'react';

import {
  customerSortEnum,
  entitiesEnum,
  entitiesTitles,
  transactionSearchFieldsEnum,
} from '../constants';
import { graphqlApiDecorator } from '../decorators';
import { queries } from '../graphql';
import { listEntity } from '../services';
import { compareByValue, formatDOBString, getMappedEntity } from '../utils';

const defaultLimit = 100;
const TRANSACTION_DAY_LIMIT = 1000;
const TRANSACTION_SEARCH_LIMIT = 20;
const CUSTOMER_SEARCH_LIMIT = 20;

const getEntityRequestLimit = entity => {
  const isCountryEntity = entity === entitiesEnum.COUNTRY;
  const fullCountryListCount = 250;
  const isPairEntity = entity === entitiesEnum.PAIR;
  const isUserProfileEntity = entity === entitiesEnum.USERPROFILE;

  // All the pairs in one request for 28 currencies
  if (isPairEntity) return 756;

  if (isUserProfileEntity) return 25;

  return isCountryEntity ? fullCountryListCount : defaultLimit;
};

/*
 * The useGraphList() is a custom hook in the GenericList component that is fetches data
 * related to the entity. There is separate logic for transaction data as that entity is handled
 * differently.
 */
export const useGraphList = ({ entity, sorted = false, customerEntitySort }) => {
  const [loading, setLoading] = useState(true);
  const [canOrder, setCanOrder] = useState(true);
  const [error, setError] = useState('');
  const [data, setData] = useState([]);
  const [tableFields, setTableFields] = useState([]);
  const [fields, setFields] = useState([]);
  const [transactionSearchResultsPage, setTransactionSearchResultsPage] = useState(0);
  const [customerSearchResultsPage, setCustomerSearchResultsPage] = useState(0);
  const [totalTransactionResults, setTotalTransactionResults] = useState(0);
  const [totalCustomerResults, setTotalCustomerResults] = useState(0);
  const [customerQuery, setCustomerQuery] = useState('');
  const [transactionQuery, setTransactionQuery] = useState('');
  const [transactionItem, setTransactionItem] = useState('name');
  const [transactionDay, setTransactionDay] = useState(1); // 1 day of transactions
  const [transactionData, setTransactionData] = useState([]);
  const [update, setUpdate] = useState('');
  const [accountSelected, setAccountSelected] = useState(sessionStorage.getItem('account') || '');
  const [isSearchMode, setIsSearchMode] = useState(false);
  const [noOfResultsCustomers, setNoOfResultsCustomers] = useState(CUSTOMER_SEARCH_LIMIT);
  const [noOfResultsTransactions, setNoOfResultsTransactions] = useState(TRANSACTION_SEARCH_LIMIT);

  const limit = getEntityRequestLimit(entity);
  const ONEDAYMILLISECONDS = 60 * 60 * 24 * 1000;

  let customerSearchField = 'name';

  const fetchEntity = useCallback(async () => {
    setLoading(true);
    const {
      success,
      msg,
      data: listEntityData,
    } = await listEntity({ entity, getAll: false, limit });
    const { tableFields, fields, hasOrder } = getMappedEntity(entity);

    if (success) {
      setData(sorted ? listEntityData.sort(compareByValue('name')) : listEntityData);
      setTableFields(tableFields);
      setFields(fields);
    } else {
      setError(msg);
    }

    setCanOrder(hasOrder);
    setLoading(false);
  }, [entity, setData, setError, setLoading, update]);

  const getTransactionDataFromCreatedDay = async (daysArr, isMinResult = true) => {
    let transactionDataLength = 0;

    try {
      await Promise.all(
        daysArr.map(async createdDay => {
          const {
            success,
            msg,
            data: listEntityData,
          } = await listEntity({
            entity,
            getAll: false,
            paginationToken: null,
            limit: TRANSACTION_DAY_LIMIT,
            filter: null,
            key: 'createdDay',
            value: createdDay,
          });
          const { tableFields, fields, hasOrder } = getMappedEntity(entity);
          if (success) {
            const dataResult = sorted
              ? listEntityData.sort(compareByValue('name'))
              : listEntityData;
            // Only transaction records have internalId property

            setTransactionData(prev => {
              const prevData = prev.filter(record => !!record.internalId);
              transactionDataLength = prevData.concat(dataResult).length;

              return prevData.concat(dataResult);
            });

            setTableFields(tableFields);
            setFields(fields);
          } else {
            setError(msg);
          }
          setCanOrder(hasOrder);
        })
      );
    } catch (err) {
      setError(err.message);
    }

    if (isMinResult && transactionDataLength < 20) {
      // If there are very few transactions then load more
      setTransactionDay(prev => prev + 1);
    }
  };

  const searchTransactions = async searchObj => {
    setTransactionData([]);

    if (searchObj.fromDate && searchObj.toDate) {
      const createdDaysArray = [];
      const createdDay = new Date(searchObj.fromDate);
      const toDate = new Date(searchObj.toDate);
      while (createdDay.getTime() <= toDate.getTime()) {
        createdDaysArray.push(createdDay.toISOString().replace(/T.*/, ''));
        createdDay.setTime(createdDay.getTime() + ONEDAYMILLISECONDS);
      }

      setLoading(true);
      getTransactionDataFromCreatedDay(createdDaysArray, false);
      setLoading(false);
    }

    if (searchObj.fromDate && !searchObj.toDate) {
      const createdDaysArray = [];
      const createdDay = new Date(searchObj.fromDate);
      const toDate = new Date();
      while (createdDay.getTime() <= toDate.getTime()) {
        createdDaysArray.push(createdDay.toISOString().replace(/T.*/, ''));
        createdDay.setTime(createdDay.getTime() + ONEDAYMILLISECONDS);
      }

      setLoading(true);
      getTransactionDataFromCreatedDay(createdDaysArray, false);
      setLoading(false);
    }
  };

  const fetchTransactionResultsFromQuery = async ({ query, page = 0, key }) => {
    setLoading(true);
    if (query) setIsSearchMode(true);
    const graphQLQuery = 'newSearchTransactions';
    setTransactionSearchResultsPage(page);
    setTransactionQuery(query);
    setTransactionItem(key);
    try {
      const variables = {
        ...(key ? { key } : null),
        ...(query ? { value: query } : null),
        limit: noOfResultsTransactions,
        offset: (page + 1) * noOfResultsTransactions - noOfResultsTransactions,
        sort: 'dateDESC',
      };
      const dataResult = await graphqlApiDecorator(queries, graphQLQuery, variables);
      if (!dataResult) {
        setError(error.message);
        setTransactionData([]);
        setLoading(false);

        return;
      }
      setTransactionData(dataResult?.items);
      setTotalTransactionResults(dataResult?.total);
      setLoading(false);
    } catch (error) {
      setError(error.message);
      setTransactionData([]);
      setLoading(false);
    }
  };

  const fetchTransactionsEntity = () => {
    fetchTransactionResultsFromQuery({ query: '', key: '' });
  };

  const fetchTransactionDataFromQuery = async ({ itemSelected, query }) => {
    let key = '';
    key = Object.keys(transactionSearchFieldsEnum).find(
      key => transactionSearchFieldsEnum[key] === itemSelected
    );

    await fetchTransactionResultsFromQuery({ query, key });
  };

  const fetchCustomerDataFromQuery = async ({ query, page = 0, key, account }) => {
    setLoading(true);
    sessionStorage.setItem('account', account || "");
    setAccountSelected(sessionStorage.getItem('account'));

    // Search mode if there is a query. If empty string then default data.
    if (query) setIsSearchMode(true);
    const graphqlQuery = 'newSearchCustomers';
    setCustomerSearchResultsPage(page);
    setCustomerQuery(query);
    customerSearchField = key;

    let customerSort = '';

    if (customerEntitySort === customerSortEnum.CREATED_NEW) customerSort = 'dateDESC';
    if (customerEntitySort === customerSortEnum.CREATED_OLD) customerSort = 'dateASC';
    if (customerEntitySort === customerSortEnum.NAME_AZ) customerSort = 'nameASC';
    if (customerEntitySort === customerSortEnum.NAME_ZA) customerSort = 'nameDESC';

    try {
      const variables = {
        ...(key ? { key } : null),
        value: key === 'dateOfBirth' ? formatDOBString(query) : query,
        limit: noOfResultsCustomers,
        offset: (page + 1) * noOfResultsCustomers - noOfResultsCustomers,
        ...(customerSort ? { sort: customerSort } : null),
        filter: account ? { accountType: account } : {},
      };
      const dataResult = await graphqlApiDecorator(queries, graphqlQuery, variables);
      if (dataResult) {
        setData(dataResult?.items);
        setTotalCustomerResults(dataResult?.total);
        setTableFields(tableFields);
        setFields(fields);
      } else {
        setData([]);
        setTotalCustomerResults(0);
      }
      setLoading(false);
    } catch (error) {
      setError(error.message);
      setData([]);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (entity === entitiesEnum.TRANSACTION) fetchTransactionsEntity();
    if (entity !== entitiesEnum.TRANSACTION && entity !== entitiesEnum.USERPROFILE) {
      // Clear previous transaction data when navigating away only if not in search mode
      setTransactionData([]);
      setIsSearchMode(false);
      fetchEntity();
    }
    if (entity === entitiesEnum.USERPROFILE) fetchCustomerDataFromQuery({ query: '', key: 'name' });
    document.title = entitiesTitles[entity];
  }, [entity, update, transactionDay]);

  useEffect(() => {
    if (loading) return;

    if (entity === entitiesEnum.TRANSACTION)
      fetchTransactionResultsFromQuery({
        query: transactionQuery,
        page: transactionSearchResultsPage,
        key: transactionItem,
        account: accountSelected ?? undefined,
      });
  }, [transactionSearchResultsPage]);

  useEffect(() => {
    if (loading) return;

    if (entity === entitiesEnum.USERPROFILE)
      fetchCustomerDataFromQuery({
        query: customerQuery,
        page: customerSearchResultsPage,
        key: customerSearchField,
        sort: customerEntitySort,
        account: accountSelected ?? undefined,
      });
  }, [customerSearchResultsPage, customerEntitySort]);

  useEffect(() => {
    if (loading) return;

    if (entity === entitiesEnum.TRANSACTION)
      fetchTransactionResultsFromQuery({ query: transactionQuery, key: transactionItem });

    if (entity === entitiesEnum.USERPROFILE)
      fetchCustomerDataFromQuery({
        query: customerQuery,
        key: customerSearchField,
        sort: customerEntitySort,
        account: accountSelected ?? undefined,
      });
  }, [noOfResultsCustomers, noOfResultsTransactions]);

  if (entity === entitiesEnum.TRANSACTION) {
    return {
      loading,
      setLoading,
      error,
      data: transactionData,
      handleRefreshData: fetchTransactionsEntity,
      tableFields,
      fields,
      canOrder,
      setUpdate,
      limit,
      searchTransactions,
      fetchTransactionDataFromQuery,
      isSearchMode,
      setIsSearchMode,
      transactionSearchResultsPage,
      hasNextPageTransactionSearchByName:
        transactionSearchResultsPage + 1 <
        Math.ceil(totalTransactionResults / noOfResultsTransactions),
      noOfPagesTransactionSearchResults: Math.ceil(
        totalTransactionResults / noOfResultsTransactions
      ),
      setTransactionSearchResultsPage,
      setNoOfResultsTransactions,
      noOfResultsTransactions,
    };
  }

  return {
    loading,
    setLoading,
    error,
    data,
    handleRefreshData: fetchEntity,
    tableFields,
    fields,
    canOrder,
    setUpdate,
    limit,
    setIsSearchMode,
    isSearchMode,
    fetchCustomerDataFromQuery,
    customerSearchResultsPage,
    hasNextPageCustomerSearch:
      customerSearchResultsPage + 1 < Math.ceil(totalCustomerResults / noOfResultsCustomers),
    noOfPagesCustomerSearchResults: Math.ceil(totalCustomerResults / noOfResultsCustomers),
    setCustomerSearchResultsPage,
    setNoOfResultsCustomers,
    noOfResultsCustomers,
  };
};
