import { Button } from '@mui/material';
import set from 'lodash.set';
import React, { useRef } from 'react';

import { saveEntity } from '../../services';
import { ArrowRightIcon, ImportIcon } from '../icons';
import { useStyles } from './table-import.hooks';

// eslint-disable-next-line no-promise-executor-return
const sleep = n => new Promise(resolve => setTimeout(resolve, n));

export const TableImport = ({ entity }) => {
  const classes = useStyles();
  const inputRef = useRef();

  const handleFileUploadImport = event => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();

      reader.onload = async e => {
        const rows = e.target.result.split('\n');
        const header = rows[0].split(';');
        const objsImport = rows.slice(1).map(row => {
          const values = row.split(';');

          return header.reduce((objAcc, field, i) => {
            const fieldValueString = values[i] ? values[i].replace(/"/g, '').trim() : '';
            const fieldValue =
              fieldValueString === 'TRUE' || fieldValueString === 'FALSE'
                ? fieldValueString === 'TRUE'
                : fieldValueString;
            const fieldName = field ? field.replace(/"/g, '').trim() : '';
            set(objAcc, fieldName, fieldValue);

            return { ...objAcc };
          }, {});
        });

        for (let i = 0; i < objsImport.length; i += 1) {
          const obj = objsImport[i];
          saveEntity({ entity, values: obj, id: obj.id, forceCreate: true });
          await sleep(20);
        }
      };
      reader.readAsText(file);
    }
  };

  return (
    <Button className={classes.importButton} onClick={() => inputRef.current.click()}>
      <div className={classes.importIcon}>
        <ImportIcon />
      </div>
      <input
        ref={inputRef}
        accept=".csv"
        style={{ display: 'none' }}
        id="import-csv-upload"
        type="file"
        onChange={handleFileUploadImport}
      />
      <label htmlFor="import-csv-upload">Import</label>
      <ArrowRightIcon />
    </Button>
  );
};
