import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { entitiesEnum } from '../../../../../constants';
import { getFormattedLongDisplayDate } from '../../../../../utils';
import { CloseIcon } from '../../../../icons';
import { useStyles } from './transaction-filter-summary.hooks';

export const TransactionFilterSummary = ({ entity = entitiesEnum.TRANSACTION, isSearchMode }) => {
  const classes = useStyles();
  const transactionFilterObj = useSelector(state => state.transactionFilterObj);
  const userTransactionFilterObj = useSelector(state => state.userTransactionFilterObj);
  const dispatch = useDispatch();

  let filterObj = {};

  if (entity === entitiesEnum.TRANSACTION) filterObj = transactionFilterObj;
  if (entity === 'userTransactions') filterObj = userTransactionFilterObj;

  const cancelFilter = () => {
    if (entity === entitiesEnum.TRANSACTION) {
      dispatch({ type: 'SET_TRANSACTION_FILTER_OBJ', payload: {} });
      if (!isSearchMode) window.location.reload();
    }

    if (entity === 'userTransactions')
      dispatch({ type: 'SET_USER_TRANSACTION_FILTER_OBJ', payload: {} });
  };

  if (Object.keys(filterObj).length !== 0) {
    return (
      <div className={classes.container}>
        <div className={classes.root}>
          {(filterObj.fromDate || filterObj.toDate) && (
            <>
              <aside>Date:</aside>
              <div className={classes.fromToContainer}>
                {filterObj.fromDate && (
                  <>
                    <p className={classes.fromToLabel}>From:</p>
                    <p className={classes.dateContainer}>
                      {getFormattedLongDisplayDate(filterObj.fromDate)}
                    </p>
                  </>
                )}
                {filterObj.toDate && (
                  <>
                    <p className={classes.fromToLabel}>To:</p>
                    <p className={classes.dateContainer}>
                      {getFormattedLongDisplayDate(filterObj.toDate)}
                    </p>
                  </>
                )}
              </div>
              <div className={classes.verticalLine} />
            </>
          )}
          {(filterObj.incoming || filterObj.outgoing || filterObj.exchange) && (
            <div className={classes.checkboxes}>
              <aside style={{ marginLeft: '10px' }}>Type:</aside>
              {filterObj.incoming && <p>Incoming</p>}
              {filterObj.outgoing && <p>Outgoing</p>}
              {filterObj.exchange && <p>Exchange</p>}
              <div className={classes.verticalLine} />
            </div>
          )}
          {(filterObj.statusFilters?.completedFilter ||
            filterObj.statusFilters?.failedFilter ||
            filterObj.statusFilters?.processingFilter) && (
            <div className={classes.statusContainer}>
              <aside style={{ marginLeft: '10px' }}>Status:</aside>
              {filterObj.statusFilters?.completedFilter && (
                <>
                  <p>Completed</p>
                  <div className={classes.verticalLine} />
                </>
              )}
              {filterObj.statusFilters?.failedFilter && (
                <>
                  <p>Failed</p>
                  <div className={classes.verticalLine} />
                </>
              )}
              {filterObj.statusFilters?.processingFilter && (
                <>
                  <p>Processing</p>
                  <div className={classes.verticalLine} />
                </>
              )}
            </div>
          )}
          {(filterObj.minAmount || filterObj.maxAmount) && (
            <>
              <aside style={{ marginLeft: '10px' }}>Amount:</aside>
              <div className={classes.fromToContainer}>
                {filterObj.minAmount && (
                  <>
                    <p className={classes.fromToLabel}>From:</p>
                    <p className={classes.dateContainer}>{`£${filterObj.minAmount}`}</p>
                  </>
                )}
                {filterObj.maxAmount && (
                  <>
                    <p className={classes.fromToLabel}>To:</p>
                    <p className={classes.dateContainer}>{`£${filterObj.maxAmount}`}</p>
                  </>
                )}
              </div>
            </>
          )}
        </div>
        <div className={classes.closeIcon} onClick={cancelFilter}>
          <CloseIcon />
        </div>
      </div>
    );
  }

  return null;
};
