import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.primary.nav,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    width: '90px',
    paddingTop: '2.8rem',
  },
  logo: {
    marginLeft: 'auto',
    marginRight: 'auto',
  },
}));
