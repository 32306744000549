import { fieldValuesEnum } from '../../../constants';

const calculateRate = (flatRate, margin, profitType) => {
  if (profitType === fieldValuesEnum.PERCENTAGE) {
    return flatRate + (flatRate * margin) / 100;
  }

  return flatRate + margin * 0.0001;
};

const validationRule = {
  pattern: /^[0-9]*(\.[0-9]{1,5})?$/,
  validate: {
    positive: value => parseFloat(value, 10) > 0,
  },
  required: true,
};

const roundNumber = num => Math.round(num * 100000) / 100000;

export { calculateRate, roundNumber, validationRule };
