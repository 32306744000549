import React from 'react';

export const CNY = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48">
    <g data-name="Group 8537">
      <path
        data-name="Path 9502"
        d="M936 4608a24 24 0 1 0-24-24 24 24 0 0 0 24 24z"
        transform="translate(-912 -4560)"
        style={{ fill: '#d80027' }}
      />
      <path
        data-name="Path 9503"
        d="m966.151 4715.8 1.806 5.559h5.843l-4.725 3.441 1.807 5.558-4.733-3.433-4.733 3.433 1.815-5.558-4.733-3.441h5.845z"
        transform="translate(-954.699 -4700.195)"
        style={{ fill: '#ffda44' }}
      />
      <path
        data-name="Path 9504"
        d="m1174.821 4892.13-1.381-1.7-2.043.793 1.185-1.84-1.381-1.708 2.117.564 1.193-1.839.115 2.191 2.125.564-2.052.785z"
        transform="translate(-1146.424 -4855.177)"
        style={{ fill: '#ffda44' }}
      />
      <path
        data-name="Path 9505"
        d="m1236.336 4833.885.654-2.092-1.79-1.267 2.191-.033.646-2.093.711 2.076 2.191-.024-1.758 1.308.7 2.076-1.79-1.267z"
        transform="translate(-1204.283 -4802.751)"
        style={{ fill: '#ffda44' }}
      />
      <path
        data-name="Path 9506"
        d="m1237.376 4742.207-.964 1.97 1.569 1.529-2.166-.311-.965 1.962-.376-2.158-2.174-.311 1.945-1.021-.376-2.167 1.569 1.529z"
        transform="translate(-1201.668 -4724.325)"
        style={{ fill: '#ffda44' }}
      />
      <path
        data-name="Path 9507"
        d="m1174.6 4674.9-.163 2.183 2.035.826-2.134.523-.155 2.19-1.153-1.863-2.133.523 1.414-1.676-1.161-1.855 2.035.826z"
        transform="translate(-1146.151 -4663.91)"
        style={{ fill: '#ffda44' }}
      />
    </g>
  </svg>
);
