import { Grid } from '@mui/material';
import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { UserContext } from '../../contexts/user-role';
import { LoginForm, SetInitialPassword } from './components';
import { useStyles } from './sign-in.hooks';

export const SignIn = () => {
  const { setUserName, setUserRoles } = useContext(UserContext);
  const [loginUser, setLoginUser] = useState(null);
  const [newPassword, setNewPassword] = useState(false);
  const classes = useStyles();
  const navigate = useNavigate();

  const continueLogin = async ({ username, attributes }) => {
    setUserName(username);
    if (attributes?.['custom:groups']) {
      setUserRoles(attributes?.['custom:groups'].split(','));
    }
    navigate('/');
  };

  document.title = 'Kixy Backoffice';

  const handleSignIn = async user => {
    setLoginUser(user);
    if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
      setNewPassword(true);
    } else {
      continueLogin(user);
    }
  };

  return (
    <div className={classes.root}>
      <Grid className={classes.grid} container>
        <Grid className={classes.content} item lg={12} xs={12}>
          <div className={classes.content}>
            <div className={classes.contentBody}>
              {newPassword ? (
                <SetInitialPassword onChangePasswordSuccess={continueLogin} user={loginUser} />
              ) : (
                <LoginForm onLoginSuccess={handleSignIn} />
              )}
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};
