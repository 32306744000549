import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(theme => ({
  modalPaper: {
    backgroundColor: theme.palette.white,
    boxShadow: theme.shadows[5],
    padding: '4.8rem',
    outline: 'none',
    borderRadius: '0.4rem',
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));
