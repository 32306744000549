import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
  groupCard: {
    borderRadius: '0.4rem',
    padding: '2rem',
  },
  cardNoShadow: {
    boxShadow: 'none',
  },
}));
