import React from 'react';

export const KixyLogoCircle = ({ width = '24', height = '24' }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 24 24">
    <g data-name="Group 8979">
      <g
        data-name="Ellipse 1372"
        transform="translate(1 1)"
        style={{ fill: '#19365e', stroke: '#fff' }}
      >
        <circle cx="11" cy="11" r="11" style={{ stroke: 'none' }} />
        <circle cx="11" cy="11" r="11.5" style={{ fill: 'none' }} />
      </g>
      <g data-name="Group 6260" transform="translate(7.442 6)">
        <path
          data-name="Path 8463"
          d="M167.592 83.568a.979.979 0 0 0-.98.979v3.314a.98.98 0 1 0 1.959 0v-3.314a.979.979 0 0 0-.979-.979z"
          transform="translate(-166.612 -76.961)"
          style={{ fill: '#1fe3ac' }}
        />
        <circle
          data-name="Ellipse 1282"
          cx="1.058"
          cy="1.058"
          r="1.058"
          transform="translate(.021)"
          style={{ fill: '#1fe3ac' }}
        />
        <path
          data-name="Path 8464"
          d="M182.839 60.064a.979.979 0 0 0-.9 1.741 4.677 4.677 0 0 1 0 8.314.979.979 0 1 0 .9 1.741 6.635 6.635 0 0 0 0-11.8z"
          transform="translate(-177.319 -59.877)"
          style={{ fill: '#1fe3ac' }}
        />
      </g>
    </g>
  </svg>
);
