const routingFields = [
  'sort_code',
  'aba',
  'bsb_code',
  'institution_no',
  'bank_code',
  'branch_code',
  'clabe',
  'cnaps',
  'ifsc',
];

const allFields = [
  'acct_number',
  'bic_swift',
  'iban',
  'bank_address',
  'bank_name',
  'bank_account_type',
  ...routingFields,
  'on_behalf_of',
  'beneficiary_first_name',
  'beneficiary_last_name',
  'beneficiary_entity_type',
  'beneficiary_company_name',
  'beneficiary_city',
  'beneficiary_postcode',
  'beneficiary_state_or_province',
  'beneficiary_address',
  'beneficiary_date_of_birth',
  'beneficiary_identification_type',
  'beneficiary_external_reference',
];

export { allFields, routingFields };
