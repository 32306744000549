import React from 'react';

export const SentAndExchangedMoneyIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21">
    <defs>
      <clipPath id="jp8peiymea">
        <path data-name="Rectangle 17450" style={{ fill: 'none' }} d="M0 0h21v21H0z" />
      </clipPath>
    </defs>
    <g data-name="Group 8421">
      <g data-name="Group 8420" style={{ clipPath: 'url(#jp8peiymea)' }}>
        <path
          data-name="Path 19592"
          d="M6.388 6.089a.757.757 0 0 0 .57-.257l2.717-3.083v14.195a.759.759 0 0 0 1.517 0V2.771l2.721 3.063a.758.758 0 1 0 1.134-1.008L10.987.255A.758.758 0 0 0 10.421 0a.761.761 0 0 0-.568.257L5.819 4.828a.758.758 0 0 0 .568 1.26"
          transform="translate(.065)"
          style={{ fill: '#fc3333' }}
        />
        <path
          data-name="Path 19593"
          d="M20.962 9.649H15.59c-.013.232-.033.514-.07.857-.087.823-.121 1.28-.121 1.28-.318 3.529-1.8 6.455-3.707 7.345l-.046.021-.025.011a4.985 4.985 0 0 1-.2.074c-.059.021-.115.047-.175.064a4.449 4.449 0 0 1-.737.087 2.687 2.687 0 0 1-1.291-.462c-1.718-1.048-3.057-3.794-3.382-7.021a.263.263 0 0 1 0-.026c-.01-.123-.055-.713-.057-.756 0 .048.007.093.01.14 0-.043-.006-.093-.009-.14-.03-.455-.064-1.02-.073-1.477H.037c-.017.246-.037.49-.037.739a10.512 10.512 0 0 0 10.5 10.5H10.712l.177-.011v-.005A10.569 10.569 0 0 0 21 10.388c0-.25-.02-.494-.038-.739M1.516 11.126h2.775a13.461 13.461 0 0 0 2.28 7.374 9.066 9.066 0 0 1-5.055-7.375m13.23 7.218a13.61 13.61 0 0 0 2.176-7.218h2.559a8.983 8.983 0 0 1-4.735 7.218"
          transform="translate(0 .112)"
          style={{ fill: '#fc3333' }}
        />
      </g>
    </g>
  </svg>
);
