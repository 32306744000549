import { Button, ListItem } from '@mui/material';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useStyles } from './sidebar-nav.hooks';
import { SidebarNavItemPopup } from './sidebar-nav-item-popup';

export const SidebarNavItem = ({ page, active }) => {
  const classes = useStyles();
  const [isHovering, setIsHovering] = useState(false);
  const handleMouseOver = () => setIsHovering(true);
  const handleMouseOut = () => setIsHovering(false);
  const navigate = useNavigate();
  const Icon = page.icon;

  return (
    <div className={classes.containerListItem}>
      {isHovering && <SidebarNavItemPopup title={page.title} />}

      <ListItem className={classes.item}>
        <div
          onMouseEnter={handleMouseOver}
          onMouseLeave={handleMouseOut}
          onFocus={handleMouseOver}
          onBlur={handleMouseOut}
        >
          <Button
            classes={{ root: `${classes.button} ${active ? 'active' : ''}`, label: classes.label }}
            onClick={() => navigate(`${page.href}`)}
          >
            <Icon className={classes.icon} color="white" />
          </Button>
        </div>
      </ListItem>
    </div>
  );
};
