import React from 'react';

export const HUF = ({ width = '45', height = '45' }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 48 48">
    <defs>
      <clipPath id="yle3fdwtta">
        <path
          data-name="Path 19900"
          d="M12792 48a24 24 0 1 0-24-24 24 24 0 0 0 24 24z"
          transform="translate(-12416 1244)"
          style={{ fill: '#f0f0f0' }}
        />
      </clipPath>
    </defs>
    <g
      data-name="Mask Group 67"
      transform="translate(-352 -1244)"
      style={{ clipPath: 'url(#yle3fdwtta)' }}
    >
      <path
        data-name="Path 19897"
        d="M12792 48a24 24 0 1 0-24-24 24 24 0 0 0 24 24z"
        transform="translate(-12416 1244)"
        style={{ fill: '#f0f0f0' }}
      />
      <path
        data-name="Path 19898"
        d="M12806.514 0c-10.369 0-19.205 6.057-22.614 14.557h45.228c-3.411-8.5-12.243-14.557-22.614-14.557z"
        transform="translate(-12430.513 1244)"
        style={{ fill: '#d80027' }}
      />
      <path
        data-name="Path 19899"
        d="M12806.514 359.6c10.371 0 19.2-6.057 22.613-14.557h-45.227c3.409 8.5 12.245 14.557 22.614 14.557z"
        transform="translate(-12430.513 932.4)"
        style={{ fill: '#6ea746' }}
      />
    </g>
  </svg>
);
