import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(theme => ({
  line: {
    alignItems: 'center',
    marginBottom: '2.1rem',
    marginLeft: '0',
    marginTop: '0',
    width: '100%',
    '& .MuiGrid-item': {
      padding: '0 1rem',
    },
  },
  titleWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  title: {
    fontSize: '2rem',
    color: theme.palette.primary.nav,
    display: 'inline-block',
    width: '55%',
  },
  flag: {
    display: 'inline-block',
    height: '4.5rem',
    width: '4.5rem',
    backgroundColor: theme.palette.primary.nav,
    borderRadius: '50%',
  },
  text: {
    fontSize: '1.8rem',
    backgroundColor: theme.palette.newDesign.bg,
    padding: '1.2rem 1.6rem',
    color: theme.palette.primary.nav,
  },
  subTitle: {
    flexBasis: '50%',
    textAlign: 'center',
  },
  fee: {
    display: 'flex',
    justifyContent: 'space-between',
    '& .MuiOutlinedInput-root': {
      width: '94%',
    },
    '& > div:last-child div': {
      right: '-6%',
    },
  },
  profit: {
    display: 'flex',
    justifyContent: 'space-between',
    '& .MuiOutlinedInput-root': {
      width: '94%',
    },
    '& .MuiSelect-select': {
      width: '94%',
    },
    '& .MuiFilledInput-root.MuiFilledInput-underline.MuiInputBase-root:hover': {
      backgroundColor: theme.palette.white,
    },
    '& .MuiFilledInput-root.MuiFilledInput-underline.MuiInputBase-root:active': {
      backgroundColor: theme.palette.white,
    },
    '& > div:last-child div': {
      right: '-6%',
    },
  },
}));
