import React from 'react';
import { Controller } from 'react-hook-form';

import { StyledTextField } from './styled-text-field';

export const FormTextField = ({
  name,
  errors,
  type = 'text',
  control,
  rules,
  disabled,
  defaultValue,
}) => {
  const isErrorsExist =
    errors &&
    Object.keys(errors).length > 0 &&
    Object.keys(errors).includes(name) &&
    Object.getPrototypeOf(errors) === Object.prototype;

  return (
    <Controller
      render={({ field, formState }) => (
        <StyledTextField
          error={isErrorsExist}
          {...field}
          {...formState}
          fullWidth
          rows={8}
          disabled={disabled}
        />
      )}
      control={control}
      name={name}
      focused
      defaultValue={defaultValue}
      rules={rules}
      type={type}
      variant="outlined"
    />
  );
};
