import React from 'react';

export const GenericProfilePic = ({ width = '40', height = '40' }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 40 40">
    <g data-name="Group 8983" transform="translate(-24 -411)">
      <circle
        data-name="Ellipse 1370"
        cx="20"
        cy="20"
        r="20"
        transform="translate(24 411)"
        style={{ fill: '#a5a6ae' }}
      />
      <g data-name="Group 6273">
        <g data-name="Group 6098" transform="translate(36 420)">
          <circle
            data-name="Ellipse 1320"
            cx="5.309"
            cy="5.309"
            r="5.309"
            transform="translate(3.113)"
            style={{
              fill: 'none',
              stroke: '#fff',
              strokeLinecap: 'round',
              strokeLinejoin: 'round',
              strokeWidth: '1.7px',
            }}
          />
          <path
            data-name="Path 8714"
            d="M-254.389-436.2a2.47 2.47 0 0 1 .244-1.078c.509-1.017 1.943-1.556 3.133-1.8a18.607 18.607 0 0 1 2.6-.366 27.869 27.869 0 0 1 4.872 0 18.821 18.821 0 0 1 2.6.366c1.19.244 2.624.732 3.133 1.8a2.523 2.523 0 0 1 0 2.167c-.509 1.068-1.943 1.556-3.133 1.79a17.5 17.5 0 0 1-2.6.376 28.674 28.674 0 0 1-3.967.061 4.506 4.506 0 0 1-.9-.061 17.2 17.2 0 0 1-2.594-.376c-1.2-.234-2.624-.722-3.143-1.79a2.533 2.533 0 0 1-.245-1.089z"
            transform="translate(254.389 454.208)"
            style={{
              fill: 'none',
              stroke: '#fff',
              strokeLinecap: 'round',
              strokeLinejoin: 'round',
              strokeWidth: '1.7px',
            }}
          />
        </g>
      </g>
    </g>
  </svg>
);
