import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { DottedLineIcon } from '../../../../components/icons';
import { customerSortEnum } from '../../../../constants';
import { useStyles } from './sort-modal.hooks';

export const CustomerSortModal = ({ onClose }) => {
  const classes = useStyles();
  const [sortType, setSortType] = useState('');
  const customerEntitySort = useSelector(state => state.customerSort);
  const dispatch = useDispatch();
  const sortAnswer = 'sortAnswer';

  const handleSubmit = event => {
    event.preventDefault();
    dispatch({ type: 'SET_CUSTOMER_SORT', payload: sortType });
    onClose();
  };

  useEffect(() => {
    setSortType(customerEntitySort);
  }, []);

  return (
    <div className={classes.modalContainer}>
      <div className={classes.modalTitleContainer}>Sort</div>
      <DottedLineIcon />
      <form className={classes.form} onSubmit={handleSubmit}>
        {Object.values(customerSortEnum).map(item => (
          <div key={item}>
            <input
              type="radio"
              id={item}
              name={sortAnswer}
              checked={sortType === item}
              onChange={() => setSortType(item)}
            />
            <label htmlFor={item}>{item}</label>
          </div>
        ))}
        <DottedLineIcon />
        <button type="submit" className={classes.sortButton}>
          Sort It
        </button>
      </form>
    </div>
  );
};
