import { CardContent, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import React, { useEffect, useState } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { uid } from 'react-uid';

import { useGraphList } from '../../hooks';
import { compareByDate } from '../../utils';
import { GenericToolbar } from '../../views';
import { ClosingCard } from '../closing-card';
import { GenericTable } from '../generic-table';
import { movements, teamActivityFields } from './team-page.constants';
import { useStyles } from './team-page.hooks';

export const TeamPage = ({ entity }) => {
  const [filters, setFilters] = useState({});
  const [tableData, setTableData] = useState([]);
  const [showCreateAdminModal, setShowCreateAdminModal] = useState(false);
  const [activeCards, setActiveCards] = useState(['teamlist']);

  const {
    loading,
    error,
    data,
    handleRefreshData,
    tableFields,
    canOrder,
    setLoading,
    page,
    hasNextPage,
    handleNextPage,
    handlePrevPage,
    setUpdate,
  } = useGraphList({ entity });

  useEffect(() => {
    const orderedData = data.sort(compareByDate({ canOrder }));
    setTableData(orderedData);
  }, [data, canOrder]);

  const classes = useStyles();

  const onCreateAdmin = () => {
    setShowCreateAdminModal(true);
  };

  return (
    <div className={classes.root}>
      <GenericToolbar
        entity={entity}
        onCreateAdmin={onCreateAdmin}
        setActiveCards={setActiveCards}
        activeCards={activeCards}
      />
      <ClosingCard
        active={activeCards.indexOf('teamlist') !== -1}
        setActiveCards={setActiveCards}
        card="teamlist"
        title="Team list"
        activeCards={activeCards}
      >
        <div className={classes.content}>
          <GenericTable
            canOrder={canOrder}
            data={tableData}
            entity={entity}
            error={error}
            hasNextPage={hasNextPage}
            loading={loading}
            onNextPage={handleNextPage}
            onPrevPage={handlePrevPage}
            onRefreshData={handleRefreshData}
            page={page}
            setLoading={setLoading}
            tableFields={tableFields}
            filters={filters}
            setFilters={setFilters}
            setUpdate={setUpdate}
            setShowCreateAdminModal={setShowCreateAdminModal}
            showCreateAdminModal={showCreateAdminModal}
          />
        </div>
      </ClosingCard>
      <ClosingCard
        active={activeCards.indexOf('activity') !== -1}
        setActiveCards={setActiveCards}
        card="activity"
        title="Team activity"
        activeCards={activeCards}
      >
        <div className={classes.content} id="activity">
          <CardContent className={classes.content}>
            <PerfectScrollbar>
              <div className={classes.inner}>
                <Table className={classes.tableRoot}>
                  <TableHead>
                    <TableRow className={classes.tableHeadRow}>
                      {teamActivityFields.map(field => (
                        <TableCell className={classes.tableHeadCell} key={uid(field.name)}>
                          {field.label}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody className={classes.tableRoot}>
                    {movements.map(movement => (
                      <TableRow className={classes.tableRow} hover key={uid(movement.id)}>
                        <TableCell
                          className={classes.tableCell}
                          style={{ textDecoration: 'underline' }}
                        >
                          {movement.teamMember}
                        </TableCell>
                        <TableCell className={classes.tableCell}>{movement.movement}</TableCell>
                        <TableCell className={classes.tableCell}>{movement.customerName}</TableCell>
                        <TableCell className={classes.tableCell}>{movement.id}</TableCell>
                        <TableCell className={classes.tableCell}>{movement.note}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </div>
            </PerfectScrollbar>
          </CardContent>
        </div>
      </ClosingCard>
    </div>
  );
};
