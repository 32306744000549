import React, { useState } from 'react';

import { entitiesEnum } from '../../../../constants';
import { saveEntity } from '../../../../services';
import { DeleteFeesIcon, EditFeesIcon } from '../../../icons/pairs';
import { useStyles } from './feesTable.hooks';
import { DeleteModal } from './modal/delete-modal';

export const FeesTable = ({
  editTemplateFee,
  selectedTemplate,
  deleteTemplateFee,
  handleRefreshData,
  feesData,
  feeId,
  mode,
  isActive,
}) => {
  const [openModal, setOpenModal] = useState(false);
  const [deleteIndex, setDeleteIndex] = useState(0);
  const isNewFee = mode === 'newFee';
  const classes = useStyles();
  const TEMPLATES = 'feesTemplates';

  let tableData = feesData;

  const deleteIconClicked = indexToDelete => {
    setDeleteIndex(indexToDelete);
    setOpenModal(true);
  };

  const deleteFee = async (id = null) => {
    const newRates = [...feesData].filter((rate, index) => index !== deleteIndex);

    if (id) {
      await saveEntity({
        entity: entitiesEnum.PAIR,
        values: { id, rates: newRates },
        id,
        hasOrder: false,
      });
    }
    setOpenModal(false);
    handleRefreshData();
  };

  if (selectedTemplate && selectedTemplate !== 'templates_blank') {
    const currentTemplate =
      JSON.parse(window.localStorage.getItem(TEMPLATES))[selectedTemplate] || {};
    tableData = [{ ...currentTemplate, templateName: selectedTemplate }];
  }

  if (selectedTemplate === 'templates_blank') tableData = null;

  return (
    <section className={classes.tableContainer}>
      <table className={isActive ? classes.table : `${classes.table} ${classes.disabled}`}>
        <tbody>
          <tr>
            <th>Start Value</th>
            <th>End Value</th>
            <th>Percentage</th>
            <th>Absolute Value</th>
            <th>Edit / Delete</th>
          </tr>
          {tableData && !isNewFee
            ? tableData.map(item => (
                <tr key={item.templateName}>
                  <td>{item.startValue}</td>
                  <td>{item.endValue}</td>
                  <td>{`${item.percentage} %`}</td>
                  <td>{item.absValue}</td>
                  <td className={classes.deleteEditCell}>
                    <div
                      className={classes.deleteEditContainer}
                      onClick={() => editTemplateFee(selectedTemplate)}
                    >
                      <EditFeesIcon />
                    </div>
                    <div className={classes.deleteEditContainer} onClick={() => setOpenModal(true)}>
                      <DeleteFeesIcon />
                    </div>
                  </td>
                  <DeleteModal
                    cancel={() => setOpenModal(false)}
                    deleteIt={() => deleteTemplateFee(selectedTemplate)}
                    onClose={() => setOpenModal(false)}
                    open={openModal}
                  />
                </tr>
              ))
            : null}
          {tableData && isNewFee
            ? tableData.map((item, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <tr key={index}>
                  <td>{item.intervalIni}</td>
                  <td>{item.intervalEnd}</td>
                  <td>{item.percentageFee ? `${item.percentageFee} %` : ''}</td>
                  <td>{item.fee}</td>
                  <td className={classes.deleteEditCell}>
                    <div
                      className={
                        isActive
                          ? classes.deleteEditContainer
                          : `${classes.deleteEditContainer} ${classes.disabled}`
                      }
                      onClick={() => {
                        if (isActive) editTemplateFee(tableData[index], index);
                      }}
                    >
                      <EditFeesIcon active={isActive} />
                    </div>
                    <div
                      className={
                        isActive
                          ? classes.deleteEditContainer
                          : `${classes.deleteEditContainer} ${classes.disabled}`
                      }
                      onClick={() => {
                        if (isActive) deleteIconClicked(index);
                      }}
                    >
                      <DeleteFeesIcon active={isActive} />
                    </div>
                  </td>
                  <DeleteModal
                    cancel={() => setOpenModal(false)}
                    deleteIt={() => deleteFee(feeId)}
                    onClose={() => setOpenModal(false)}
                    open={openModal}
                  />
                </tr>
              ))
            : null}
        </tbody>
      </table>
      {!tableData && (
        <div
          className={isActive ? classes.noTemplates : `${classes.noTemplates} ${classes.disabled}`}
        >
          {isNewFee ? `No fees to display` : `No templates to display`}
        </div>
      )}
    </section>
  );
};
