import React from 'react';

export const DottedDivider = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1" height="41" viewBox="0 0 1 41">
    <path
      data-name="Path 19459"
      d="M0 0v40"
      transform="translate(.5 .5)"
      style={{ fill: 'none', stroke: '#19365e', strokeLinecap: 'round', strokeDasharray: '1 4' }}
    />
  </svg>
);
