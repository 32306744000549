import {
  Card,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import React from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { uid } from 'react-uid';

import { BackButton } from '../buttons';
import { movements, teamActivityFields, TeamMember } from './admin-activity.constants';
import { useStyles } from './admin-activity.hooks';

export const AdminActivity = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.row}>
        <Typography variant="h3">{`${TeamMember} 's activity`}</Typography>
        <BackButton />
      </div>
      <Card className={classes.root} id="activity">
        <CardContent className={classes.content}>
          <PerfectScrollbar>
            <div className={classes.inner}>
              <Table className={classes.tableRoot}>
                <TableHead>
                  <TableRow className={classes.tableHeadRow}>
                    {teamActivityFields.map(field => (
                      <TableCell className={classes.tableHeadCell} key={uid(field.name)}>
                        {field.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody className={classes.tableRoot}>
                  {movements.map(movement => (
                    <TableRow className={classes.tableRow} hover key={uid(movement.id)}>
                      <TableCell className={classes.tableCell}>{movement.movement}</TableCell>
                      <TableCell className={classes.tableCell}>{movement.customerName}</TableCell>
                      <TableCell className={classes.tableCell}>{movement.id}</TableCell>
                      <TableCell className={classes.tableCell}>{movement.note}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </div>
          </PerfectScrollbar>
        </CardContent>
      </Card>
    </div>
  );
};
