import React from 'react';

export const RON = ({ width = '48', height = '48' }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 24 24">
    <defs>
      <clipPath id="gcdg25mfwa">
        <path
          data-name="Path 22692"
          d="M12780 24a12 12 0 1 0-12-12 12 12 0 0 0 12 12z"
          transform="translate(-12416 1244)"
          style={{ fill: '#f0f0f0' }}
        />
      </clipPath>
    </defs>
    <g
      data-name="Mask Group 162"
      transform="rotate(-90 -434 810)"
      style={{ clipPath: 'url(#gcdg25mfwa)' }}
    >
      <path
        data-name="Path 22689"
        d="M12780 24a12 12 0 1 0-12-12 12 12 0 0 0 12 12z"
        transform="translate(-12416 1244)"
        style={{ fill: '#fcd116' }}
      />
      <path
        data-name="Path 22690"
        d="M12795.206 0a12.058 12.058 0 0 0-11.307 7.279h22.614A12.058 12.058 0 0 0 12795.206 0z"
        transform="translate(-12431.206 1244)"
        style={{ fill: '#002b7f' }}
      />
      <path
        data-name="Path 22691"
        d="M12795.206 352.322a12.058 12.058 0 0 0 11.308-7.279h-22.614a12.058 12.058 0 0 0 11.306 7.279z"
        transform="translate(-12431.206 915.679)"
        style={{ fill: '#ce1126' }}
      />
    </g>
  </svg>
);
