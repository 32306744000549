import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
  tableContainer: {
    '& table': {
      marginTop: '57px',
      width: '95%',
      margin: 'auto',
      fontSize: '18px',
      borderCollapse: 'separate',
      borderSpacing: '0',
    },
    '& th': {
      border: '2px solid #e6e6e6',
      background: '#f2f2f4',
      borderRight: 'none',
      padding: '11px',
    },
    '& table td': {
      textAlign: 'center',
      width: '20%',
    },
    '& th:last-child': {
      borderRight: '2px solid #e6e6e6',
    },
    '& tr td': {
      borderLeft: '2px solid #e6e6e6',
      borderBottom: '2px solid #e6e6e6',
      padding: '8px',
    },
    '& tr td:last-child': {
      borderRight: '2px solid #e6e6e6',
    },
    '& table tr th:first-child table tr td: first-child': {
      borderLeft: '2px solid #e6e6e6',
    },
    '& table tr:first-child th:first-child': {
      borderTopLeftRadius: '8px',
    },
    '& table tr:first-child th:last-child': {
      borderTopRightRadius: '8px',
    },
    '& table tr:last-child td:first-child': {
      borderBottomLeftRadius: '8px',
    },
    '& table tr:last-child td:last-child': {
      borderBottomRightRadius: '8px',
    },
  },
  deleteEditCell: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    gap: '40px',
    width: 'auto !important',
  },
  deleteEditContainer: {
    border: '1px solid #e6e6e6',
    borderRadius: '4px',
    height: '32px',
    width: '32px',
    paddingTop: '2px',
    cursor: 'pointer',
  },
  noTemplates: {
    textAlign: 'center',
    fontSize: '18px',
    marginTop: '24px',
    marginLeft: '72px',
  },
  disabled: {
    color: '#a5a6ae',
    cursor: 'auto',
  },
}));
