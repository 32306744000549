import React from 'react';

export const JPY = ({ width = '45', height = '45' }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 48 48">
    <g data-name="Group 8573">
      <path
        data-name="Path 9884"
        d="M6408 10080a24 24 0 1 0-24-24 24 24 0 0 0 24 24z"
        transform="translate(-6384 -10032.001)"
        style={{ fill: '#f0f0f0' }}
      />
      <path
        data-name="Path 9885"
        d="M6539.174 10197.649a10.474 10.474 0 1 0-10.474-10.473 10.473 10.473 0 0 0 10.474 10.473z"
        transform="translate(-6515.174 -10163.176)"
        style={{ fill: '#d80027' }}
      />
    </g>
  </svg>
);
