import React, { useEffect, useState } from 'react';
import { Paper, Button, Box, Container, Typography, Grid } from '@material-ui/core';
import { useLocation } from 'react-router-dom';
import { Loader } from 'components/loader';
import { getCurrentUser } from 'services';
import { useStyles } from './Dashboard-content.hook';
import validator from 'validator';
import Form from '@rjsf/material-ui';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';

const theme = createTheme({
  typography: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    h4: {
      fontWeight: 600,
    },
    body1: {
      fontSize: '16px',
    },
  },
});

const FetchAndRenderForm = ({ userId, url, tagParam, bussinessName }) => {
  const [schema, setSchema] = useState(null);
  const [loading, setLoading] = useState(true);
  const [formData, setFormData] = useState({});
  const [accessToken, setAccessToken] = useState();
  const classes = useStyles();
  const [formattedSchema, setFormattedSchema] = useState(null);

  const fetchAccessToken = async () => {
    try {
      const data = await getCurrentUser();
      setAccessToken(data.accessToken);

      if (data) {
        fetchSchema(data.accessToken);
      }
    } catch (error) {
      console.error('Error fetching access token:', error);
    }
  };

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get('id');
  const baseUrl = queryParams.get('baseUrl');
  const tag = queryParams.get('tag');

  const formatString = str => {
    return str
      .replace(/([a-z])([A-Z])/g, '$1 $2')
      .replace(/^\w|\s\w/g, match => match.toUpperCase());
  };

  const formatSchemaLabels = schema => {
    const formattedSchema = { ...schema };
    if (formattedSchema.properties) {
      formattedSchema.properties = Object.keys(schema.properties).reduce((acc, key) => {
        const formattedKey = formatString(key);
        acc[key] = { ...schema.properties[key], title: formattedKey };
        return acc;
      }, {});
    }
    return formattedSchema;
  };

  const fetchSchema = async token => {
    try {
      let apiUrl;
      if (tag === 'CREATE') {
        apiUrl = `https://api.test.kixy.com/user${baseUrl}`;
      } else {
        apiUrl = `https://api.test.kixy.com/user${baseUrl || url}/${id || userId}`;
      }

      const response = await fetch(apiUrl, {
        headers: {
          Accept: 'application/schema+json',
          Authorization: `Bearer ${token}`,
        },
      });

      const data = await response.json();
      setSchema(data);
    } catch (error) {
      console.error('Failed to fetch schema:', error);
    } finally {
      setLoading(false);
    }
  };

  const fetchData = async () => {
    setLoading(true);
    try {
      const dataResponse = await fetch(`https://api.test.kixy.com/user${baseUrl}/${id}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      const data = await dataResponse.json();
      setFormData(data);
      bussinessName(data.businessName);
    } catch (error) {
      console.error('Failed to fetch Data:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAccessToken();
  }, []);

  useEffect(() => {
    if (schema && tag !== 'CREATE') {
      fetchData();
    }
    const updatedSchema = formatSchemaLabels(schema);
    setFormattedSchema(updatedSchema);
  }, [schema]);

  const handleSubmit = async ({ formData }) => {
    setLoading(true);
    try {
      const response = await fetch(`https://api.test.kixy.com/user/v1/admin/user/company/${id}`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify(formData),
      });
      const result = await response.json();
    } catch (error) {
      console.error('Failed to submit form:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <ThemeProvider theme={theme}>
      {loading ? (
        <Loader />
      ) : (
        <Container maxWidth="md">
          <Paper className={classes.container} elevation={3} style={{ padding: '2em' }}>
            {schema && (
              <Form
                schema={formattedSchema}
                formData={formData}
                onSubmit={handleSubmit}
                validator={validator}
                disabled={tag !== 'EDIT' && tag !== 'CREATE'}
                onChange={({ formData }) => setFormData(formData)}
                showErrorList={false}
                noHtml5Validate
              >
                {tag === 'EDIT' || tag === 'CREATE' ? (
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <Button type="submit" variant="contained" color="primary" fullWidth>
                        Submit
                      </Button>
                    </Grid>
                  </Grid>
                ) : (
                  <></>
                )}
              </Form>
            )}
          </Paper>
        </Container>
      )}
    </ThemeProvider>
  );
};

export default FetchAndRenderForm;
