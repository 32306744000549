import { Button } from '@mui/material';
import { SmallSideRectangle } from '../../../components/icons';
import React, { useEffect, useState } from 'react';
import { useStyles } from './Dashboard-content.hook';
import { Loader } from '../../../components/loader';
import { useLocation, useNavigate } from 'react-router-dom';
import { RelatedShareholders, Risk } from '../../../components';
import { graphqlApiDecorator } from 'decorators';
import { mutations, queries } from '../../../graphql';
import { SavedModal, WarningModal } from 'components/modal';
import { Monitoring } from 'components/monitoring';
import FetchAndRenderForm from 'views/fetch-and-render-form/components/FetchAndRenderForm';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import axios from 'axios';

export const DashboardContent = () => {
  const classes = useStyles();
  const DetailsSection = 'Details';
  const RiskSection = 'Risk';
  const SharholdersSection = 'Sharholders';
  const monitoring = 'Monitoring';
  const [tabSelected, setTabSelected] = useState(DetailsSection);
  const [loading, setLoading] = useState(false);
  const [bussinesName, setBussinesName] = useState('');
  const [openSavedModal, setOpenSavedModal] = useState(false);
  const [openWarningModal, setOpenWarningModal] = useState(false);
  const [modalContent, setModalContent] = useState('');
  const [savedHeader, setSavedHeader] = useState('');
  const [accountStatus, setAccountStatus] = useState('');
  const [amlData, setAmlData] = useState();
  const [authorName, setAuthorName] = useState([]);
  const [authorLastName, setAuthorLastName] = useState([]);
  const [authorId, setAuthorId] = useState();
  const [companyFullsircleId, setCompanyFullsircleId] = useState('');
  const [pdfLoading, setPdfLoading] = useState(false);

  const navigate = useNavigate();

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get('id');
  const baseUrl = queryParams.get('baseUrl');
  const tag = queryParams.get('tag');

  const saveUserProfile = async accountStatus => {
    const BoUpdateUserProfileInput = {
      id,
      accountStatus,
    };

    const queryTitle = 'updateUserProfile';
    setLoading(true);
    try {
      const result = await graphqlApiDecorator(mutations, queryTitle, {
        input: BoUpdateUserProfileInput,
      });

      if (result) {
        setSavedHeader('Saved');
        setModalContent('Account status updated');
        setOpenSavedModal(true);
      }
      getUserProfile();
      setLoading(false);
    } catch (error) {
      setModalContent(error.message);
      setOpenWarningModal(true);
    } finally {
      setLoading(false);
    }
  };

  const getUserProfile = async () => {
    const queryTitle = 'getUserProfile';
    setLoading(true);
    try {
      const result = await graphqlApiDecorator(queries, queryTitle, {
        id,
      });

      if (result) {
        setAccountStatus(result.accountStatus);
        authorDetails(result.author);
        setAuthorId(result.author);

        const transactionsInfo = JSON.parse(result.transactionsInfo);
        const businessType = JSON.parse(result.businessType);
        const companyId = JSON.parse(result.businessDetail);

        setCompanyFullsircleId(companyId.registrationNumber);

        const mergedData = businessType.reduce((acc, item) => {
          return { ...acc, ...item };
        }, transactionsInfo);

        setAmlData(mergedData);
      }
      setLoading(false);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const downloadPDF = async () => {
    setPdfLoading(true);

    try {
      setPdfLoading(true);
      const response = await axios.post(
        'https://ej8dyuet89.execute-api.eu-west-1.amazonaws.com/incorporation-generate',
        {
          companyId: companyFullsircleId,
          countryCode: 'gb',
        }
      );

      const fileUrl = response.data.url;

      setPdfLoading(false);
      openPdfInNewTab(fileUrl);
    } catch (error) {
      setPdfLoading(false);
      console.log(error);
    } finally {
      setPdfLoading(false);
    }
  };

  const openPdfInNewTab = pdfUrl => {
    setPdfLoading(false);
    window.open(pdfUrl, '_blank');
  };

  const handleClick = () => {
    if (authorId) {
      navigate(`/userprofile/${authorId}`);
    }
  };

  const authorDetails = async authorId => {
    const queryTitle = 'getUserProfile';
    setLoading(true);
    try {
      const result = await graphqlApiDecorator(queries, queryTitle, {
        id: authorId,
      });

      if (result) {
        setAuthorName(result.name);
        setAuthorLastName(result.lastName);
      }
      setLoading(false);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getUserProfile();
  }, []);

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          <section className={classes.sectionContainer}>
            <header className={classes.headerContainer}>
              <SmallSideRectangle />
              <p>Information</p>
            </header>
            <main className={classes.contentContainer}>
              <div className={classes.infoContainer}>
                <div className={classes.profile}></div>
                <div className={classes.info}>
                  <p>
                    <b>Name:</b> {bussinesName || '__'}
                  </p>
                  <p>
                    <b>Account Status:</b>
                    {accountStatus === 'account_verification_failed'
                      ? 'Reject'
                      : accountStatus ?? '__'}
                  </p>
                  <p onClick={handleClick} className={classes.authorInfo}>
                    <b>Author:</b> <span>{authorName + authorLastName || '---'}</span>
                    {authorName && authorLastName && (
                      <ArrowForwardIcon fontSize="large" color="#2BE4B0" />
                    )}
                  </p>
                  <p className={pdfLoading ? classes.downloadBtnDisable : classes.downloadBtn}>
                    <b>Certificate Of Incorporation:</b>
                    <span onClick={() => downloadPDF()}>Download</span>
                  </p>
                </div>
              </div>
              <div>
                <div>
                  <Button
                    sx={{
                      width: '100%',
                      height: '32px',
                      border: '1px solid #2BE4B0',
                      color: '#093560',
                      marginBottom: '20px',
                      borderRadius: '16px',
                      textTransform: 'none',
                      padding: '0 40px',
                    }}
                    onClick={() => saveUserProfile('active')}
                  >
                    <p>Accept</p>
                  </Button>
                </div>
                <div>
                  <Button
                    sx={{
                      width: '100%',
                      height: '32px',
                      border: '1px solid red',
                      color: '#093560',
                      marginBottom: '20px',
                      borderRadius: '16px',
                      textTransform: 'none',
                      padding: '0 40px',
                    }}
                    onClick={() => saveUserProfile('account_verification_failed')}
                  >
                    <p>Reject</p>
                  </Button>
                </div>
              </div>
            </main>
            <div className={classes.tab}>
              <div className={classes.togglerContainer}>
                <div
                  className={
                    tabSelected === DetailsSection
                      ? `${classes.selectedContainer} ${classes.itemContainer}`
                      : `${classes.itemContainer} ${classes.notSelected}`
                  }
                  onClick={() => setTabSelected(DetailsSection)}
                >
                  Details
                </div>
                <div
                  className={
                    tabSelected === RiskSection
                      ? `${classes.selectedContainer} ${classes.itemContainer}`
                      : `${classes.itemContainer} ${classes.notSelected}`
                  }
                  onClick={() => setTabSelected(RiskSection)}
                >
                  Risk and AML
                </div>
                <div
                  className={
                    tabSelected === SharholdersSection
                      ? `${classes.selectedContainer} ${classes.itemContainer}`
                      : `${classes.itemContainer} ${classes.notSelected}`
                  }
                  onClick={() => setTabSelected(SharholdersSection)}
                >
                  Shareholders
                </div>
                <div
                  className={
                    tabSelected === monitoring
                      ? `${classes.selectedContainer} ${classes.itemContainer}`
                      : `${classes.itemContainer} ${classes.notSelected}`
                  }
                  onClick={() => setTabSelected(monitoring)}
                >
                  Monitoring
                </div>
              </div>
            </div>
          </section>
          <section className={classes.contentSection}>
            {tabSelected === 'Details' && (
              <FetchAndRenderForm
                userId={id}
                url={baseUrl}
                tagParam={tag}
                bussinessName={name => setBussinesName(name)}
              />
            )}
            {tabSelected === 'Sharholders' && <RelatedShareholders userId={id} />}
            {tabSelected === 'Risk' && <Risk id={id} AML={amlData} />}
            {tabSelected === 'Monitoring' && <Monitoring userId={id} />}
          </section>

          <SavedModal
            open={openSavedModal}
            onClose={() => setOpenSavedModal(false)}
            header={savedHeader}
            content={modalContent}
          />
          <WarningModal
            open={openWarningModal}
            onClose={() => setOpenWarningModal(false)}
            confirm={() => setOpenWarningModal(false)}
            message={modalContent}
          />
        </>
      )}
    </>
  );
};
