// @mui
import PropTypes from 'prop-types';
import { alpha, styled } from '@mui/material/styles';
import { Card, Typography } from '@mui/material';

// ----------------------------------------------------------------------

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}
const StyledIcon = styled('div')(({ theme }) => ({
  margin: 'auto',
  display: 'flex',
  borderRadius: '50%',
  alignItems: 'center',
  width: 32,
  height: 32,
  justifyContent: 'center',
  marginBottom: 12,
}));

// ----------------------------------------------------------------------

AppWidgetSummary.propTypes = {
  color: PropTypes.string,
  icon: PropTypes.element,
  title: PropTypes.string.isRequired,
  total: PropTypes.number.isRequired,
  sx: PropTypes.object,
};

export default function AppWidgetSummary({ title, total, icon, color = 'primary', sx, ...other }) {
  return (
    <Card
      sx={{
        py: 3,
        px: 4,
        boxShadow: '0 0 8px 0 rgba(0, 0, 0, 0.16)',
        textAlign: 'center',
        borderRadius: '12px',
        borderTopLeftRadius: '0',
        background: !title && '#f2f2f4',
        color: theme => 'white',
        ...sx,
      }}
      {...other}
    >
      <StyledIcon
        sx={{
          color: theme => color,
          backgroundImage: theme =>
            `linear-gradient(135deg, ${alpha(color, 0.3)} 0%, ${alpha(color, 0.24)} 100%)`,
        }}
      >
        {icon}
      </StyledIcon>

      <Typography fontWeight={700} variant="h3">
        {total || ''}
      </Typography>

      <Typography height={40} variant="h6" sx={{ opacity: 0.72 }}>
        {capitalizeFirstLetter(title || '')}
      </Typography>
    </Card>
  );
}
