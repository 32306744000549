import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import React, { useState } from 'react';

import { adminRoles } from '../../../constants';
import { DottedLineIcon } from '../../icons';
import { useStyles } from '../styles/add-new-member-modal.hooks';

export const AddNewMemberModal = ({ onClose, onSubmit }) => {
  const [userData, setUserData] = useState({
    userName: '',
    name: '',
    password: '',
    roles: [],
  });

  const classes = useStyles();

  // TODO: Need to do form validation on inputs and ensure submit button is greyed out until all parts filled in.

  const subTitleStyle = { fontWeight: 'bold', fontSize: '16px', marginTop: '17px' };

  const addRole = role => {
    if (Array.isArray(userData.roles)) {
      // eslint-disable-next-line no-unused-expressions
      !userData.roles.includes(role)
        ? userData.roles.push(role)
        : userData.roles.splice(userData.roles.indexOf(role), 1);
    } else {
      userData.roles = [role];
    }

    setUserData({ ...userData, roles: userData.roles });
  };

  const handleChange = event => {
    setUserData({ ...userData, [event.target.name]: event.target.value });
  };

  return (
    <Modal open onClose={onClose}>
      <Box>
        <form onSubmit={onSubmit}>
          <div className={classes.modalBox}>
            <header>Add New Member</header>
            <DottedLineIcon />
            <p style={subTitleStyle}>Personal Info</p>
            <section className={classes.inputSection}>
              <div className='rowContainer'>
                <p>Full Name</p>
                <input type="text" name="name" value={userData.name} onChange={handleChange} />
              </div>
              <div className='rowContainer'>
                <p>Email</p>
                <input
                  type="text"
                  name="userName"
                  value={userData.userName}
                  onChange={handleChange}
                />
              </div>
              <div>
                <p>Password</p>
                <input
                  type="password"
                  name="password"
                  value={userData.password}
                  onChange={handleChange}
                />
              </div>
            </section>
            <DottedLineIcon />
            <p style={subTitleStyle}>Apply Role</p>
            <section className={classes.rolesBtnContainer}>
              {adminRoles.map(role => (
                <button
                  key={role}
                  type="button"
                  className={
                    userData.roles.includes(role) ? classes.roleBtnActive : classes.roleBtn
                  }
                  onClick={() => addRole(role)}
                >
                  {role}
                </button>
              ))}
            </section>
            <section className={classes.bottomBtnContainer}>
              <button type="button" className={classes.cancelBtn} onClick={onClose}>
                Cancel
              </button>
              <button type="button" className={classes.addBtn} onClick={() => onSubmit(userData)}>
                Add New Member
              </button>
            </section>
          </div>
        </form>
      </Box>
    </Modal>
  );
};
