import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import React, { useEffect, useRef, useState } from 'react';

import { graphqlApiDecorator } from '../../../../decorators';
import { mutations } from '../../../../graphql';
import { listEntity } from '../../../../services';
import { SmallSideRectangle } from '../../../icons';
import { CancelButton, EditButton, SaveButton } from '../buttons';
import { SavedModal } from '../setup/modal/saved-modal';
import { useStyles } from './transfer-fees.hooks';

export const TransferFeesPage = () => {
  const [transferFeesData, setTransferFeesData] = useState([]);
  const [methodSelected, setMethodSelected] = useState('');
  const [transferFeeInput, setTransferFeeInput] = useState(0);
  const [showSavedModal, setShowSavedModal] = useState(false);
  const [isEditable, setIsEditable] = useState(false);
  const [originalFee, setOriginalFee] = useState(0);
  const classes = useStyles();
  const dropDownRef = useRef(null);

  const getTransferFees = async () => {
    const { data } = await listEntity({ entity: 'TransferFee' });
    setTransferFeesData(data);
  };

  useEffect(() => {
    if (transferFeesData.length && !methodSelected)
      setMethodSelected(transferFeesData[0]?.paymentMethod);
  }, [transferFeesData]);

  useEffect(() => {
    getTransferFees();
  }, []);

  useEffect(() => {
    if (transferFeesData.length) {
      const transferFee = transferFeesData.find(
        item => item?.paymentMethod === methodSelected
      )?.fee;
      setTransferFeeInput(transferFee);
      setOriginalFee(transferFee);
    }
  }, [methodSelected, transferFeesData]);

  const save = async () => {
    const saveObj = { paymentMethod: methodSelected, fee: transferFeeInput };
    const result = await graphqlApiDecorator(mutations, 'editTransferFee', { input: saveObj });

    if (result) setShowSavedModal(true);
    setIsEditable(false);
  };

  const edit = () => {};

  const closeSavedModal = () => {
    setShowSavedModal(false);
    getTransferFees();
  };

  const preventMinus = event => {
    if (event.code === 'Minus') event.preventDefault();
  };

  const handlePaymentMethodSelectChange = event => {
    setMethodSelected(event.target.value);
  };

  const handleFeesInputSubmit = event => {
    event.preventDefault();
    if (isEditable) save();
  };

  return (
    <div className={classes.sectionContainer}>
      <div className={classes.transferFeeHeader}>
        <SmallSideRectangle />
        <header>Transfer fees</header>
      </div>
      <section className={classes.mainTransferFeesSection}>
        <aside className={classes.transferFeesInputs}>
          <div className={classes.inputRow}>
            <div className={classes.inputItem} ref={dropDownRef}>
              <p style={{ marginBottom: '10px' }}>Payment Method</p>
              <Select
                id="payment_method"
                value={methodSelected}
                sx={{
                  height: '48px',
                  width: '100%',
                  borderRadius: '8px',
                  color: '#19365e',
                  fontSize: '20px',
                }}
                onChange={handlePaymentMethodSelectChange}
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: 400,
                    },
                  },
                }}
              >
                {transferFeesData &&
                  transferFeesData.map(item =>
                    item ? (
                      <MenuItem
                        key={item?.paymentMethod}
                        className={classes.paymentMethodItem}
                        value={item?.paymentMethod}
                      >
                        <p>{item?.paymentMethod}</p>
                      </MenuItem>
                    ) : null
                  )}
              </Select>
            </div>
            <div className={classes.inputItem}>
              <p>Transfer Fee</p>
              <div className={classes.transferFeeInputContainer}>
                <form onSubmit={handleFeesInputSubmit}>
                  <input
                    type="number"
                    step="any"
                    min="0"
                    value={transferFeeInput}
                    disabled={!isEditable}
                    onChange={event => setTransferFeeInput(event.target.value)}
                    onKeyDown={preventMinus}
                  />
                </form>
              </div>
            </div>
          </div>
        </aside>
        <aside className={classes.asideButtonsContainer}>
          {isEditable && <SaveButton save={save} />}
          {!isEditable && (
            <div onClick={() => setIsEditable(true)}>
              <EditButton edit={edit} />
            </div>
          )}
          {isEditable && (
            <div
              onClick={() => {
                setIsEditable(false);
                setTransferFeeInput(originalFee);
              }}
            >
              <CancelButton />
            </div>
          )}
        </aside>
        <SavedModal open={showSavedModal} onClose={closeSavedModal} />
      </section>
    </div>
  );
};
