import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(theme => ({
  modalBox: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '479px',
    height: '576px',
    borderRadius: '15px',
    border: 'none',
    boxShadow: 24,
    fontSize: '14px',
    fontFamily: theme.typography.default,
    color: theme.palette.icons.nav,
    backgroundColor: 'white',
    padding: '29px 24px 24px 23px',
    '& header': {
      fontSize: '20px',
      fontWeight: 'bold',
      textAlign: 'center',
      marginBottom: '22px',
    },
    '& p': {
      fontSize: '14px',
    },
  },
  inputBox: {
    width: '432px',
    height: '32px',
    borderRadius: '8px',
    fontWeight: '500',
    marginTop: '4px',
    fontSize: '16px',
  },
  btnContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    marginTop: '32px',
  },
  confirmBtn: {
    width: '168px',
    height: '40px',
    borderRadius: '8px',
    backgroundColor: '#c6ffef',
    fontSize: '18px',
    fontWeight: '500',
    color: theme.palette.icons.nav,
    border: 'none',
    cursor: 'pointer',
  },
}));
