const white = '#FFFFFF';
const black = '#000000';

export const palette = {
  black,
  white,
  primary: {
    contrastText: white,
    dark: '#00886D',
    main: '#1ce2a9',
    pillUnstarted: '#64b5f6',
    pending: '#fff9c4',
    mid: '#2b9f88',
    midLight: '#25c3a3',
    light: '#e9fffb',
    hover: '#55ddc0',
    nav: '#093560',
  },
  secondary: {
    contrastText: white,
    dark: '#e8e8e8',
    main: '#f5f5f7',
    light: '#F1F1F1',
  },
  blueModalText: '#0069f8',
  successText: {
    color: '#23c3a4',
  },
  success: {
    contrastText: white,
    dark: '#48ba6c',
    main: '#48ba6c',
    light: '#48ba6c',
  },
  info: {
    contrastText: white,
    dark: '#757575',
    main: '#757575',
    light: '#757575',
  },
  warning: {
    contrastText: white,
    dark: '#ffb514',
    main: '#fbb827',
    light: '#fdc54b',
  },
  error: {
    contrastText: white,
    dark: '#f21c54',
    main: '#f21c54',
    light: '#f05454',
  },
  text: {
    primary: '#454846',
    secondary: '#3f4759',
    tertiary: '#686868',
    link: '#888888',
  },
  greyMid: {
    main: '#f5f5f7',
    mid: '#d7e1e8',
    dark: '#a6acb2',
    extradark: '#979797',
  },
  background: {
    default: '#f5f7fc',
    hoverDefault: '#e8ecf3',
    paper: white,
  },
  fail: {
    color: '#fc3333',
  },
  icons: {
    nav: '#19365e',
    sidebar: '#ffffff',
    intercomIcon: {
      default: '#1f8ded',
      disabled: '#a6acb2',
    },
    sumsub: {
      body: '#002366',
      head: '#2bd4ab',
      disabled: '#a6acb2',
    },
    style: {
      display: 'flex',
      width: '1.8rem',
      height: '3rem',
    },
  },
  newDesign: {
    border: '#e6e6e6',
    divider: '#d6d6d6',
    bg: '#f9f9f9',
  },
  divider: '#10293d',
  transactions: {
    background: '#f2f2f4',
    border: '1px solid #f2f2f4',
  },
};
