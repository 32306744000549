import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import React from 'react';

import { FailIcon } from '../../icons/multi-currency';
import { useStyles } from './modal.hooks';

export const FailModal = ({ open, onClose, title, extraFntionality, children, isDisabled,errorMessage }) => {
  const classes = useStyles();

  return (
    <Modal open={open} onClose={onClose}>
      <Box>
        <div className={classes.modal}>
          <div className={classes.innerModal}>
            <div className={classes.topOfModal}>
              <div className={classes.iconPlaceholder}>
                <FailIcon />
              </div>
            </div>
            <header>{title}</header>
            <div className={classes.modalBody}>{children}</div>
            <section className={classes.maxWidth100}>
              <p className={classes.failText}>{errorMessage ?? "Your Transaction Failed!"}</p>
              <div className={classes.modalFooter}>
                <button className={classes.modalButton} onClick={onClose}>
                  Cancel
                </button>
                <button data-disabled={isDisabled} className={classes.modalButton} onClick={isDisabled ? void 0 :extraFntionality}>
                  Repeat it
                </button>
              </div>
            </section>
          </div>
        </div>
      </Box>
    </Modal>
  );
};
