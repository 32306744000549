import { capitalizeString } from '../../../utils';

const HIDDEN_VALUE = 0;
const VISIBLE_VALUE = 10;
const MANDATORY_VALUE = 20;

const SLIDER_MIN_VALUE = 0;
const SLIDER_MAX_VALUE = 20;
const SLIDER_STEP_VALUE = 10;

const requirementStatusesEnum = {
  HIDDEN: 'hidden',
  VISIBLE: 'visible',
  MANDATORY: 'mandatory',
};

const requirementStatuses = {
  [HIDDEN_VALUE]: requirementStatusesEnum.HIDDEN,
  [VISIBLE_VALUE]: requirementStatusesEnum.VISIBLE,
  [MANDATORY_VALUE]: requirementStatusesEnum.MANDATORY,
};

const sliderMarks = [
  {
    value: HIDDEN_VALUE,
    label: capitalizeString(requirementStatusesEnum.HIDDEN),
  },
  {
    value: VISIBLE_VALUE,
    label: capitalizeString(requirementStatusesEnum.VISIBLE),
  },
  {
    value: MANDATORY_VALUE,
    label: capitalizeString(requirementStatusesEnum.MANDATORY),
  },
];

const sliderConfig = {
  marks: sliderMarks,
  min: SLIDER_MIN_VALUE,
  max: SLIDER_MAX_VALUE,
  step: SLIDER_STEP_VALUE,
};

export {
  HIDDEN_VALUE,
  MANDATORY_VALUE,
  requirementStatuses,
  requirementStatusesEnum,
  sliderConfig,
  VISIBLE_VALUE,
};
