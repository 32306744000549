import ClearIcon from '@mui/icons-material/Clear';
import { Modal, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';

import { permissionOptions } from '../../../constants';
import { setDefaultValues } from '../../../utils';
import { ModalButtons } from '../modal-buttons';
import { ModalDataForm } from '../modal-data-form';
import { ModalPermissions } from '../modal-permissions';
import { useStyles } from './create-admin-modal.hooks';

export const CreateAdminModal = ({ onClose, onShow, onCreate, styles, entity, onRefreshData }) => {
  const classes = useStyles();

  const [permissions, setPermissions] = useState(setDefaultValues(permissionOptions));
  const [adminData, setAdminData] = useState({});

  const onChange = e => {
    const { value, name } = e.target;
    setTimeout(setAdminData({ ...adminData, [name]: value }), 2000);
  };

  const onCreateAdmin = async (_entity, _values) => {
    await onCreate({
      entity,
      values: {
        name: _values.name,
        email: _values.email,
        permissions: JSON.stringify(_values.permissions),
      },
    });
    onClose();
    onRefreshData();
  };

  useEffect(() => {
    setAdminData({ ...adminData, permissions });
  }, [permissions]);

  if (!onShow) {
    return null;
  }

  return (
    <Modal
      aria-describedby="simple-modal-description"
      aria-labelledby="simple-modal-title"
      className={classes.modal}
      onClose={onClose}
      open={onShow}
    >
      <div className={classes.modalPaper}>
        <div className={classes.header}>
          <Typography variant="h4">Create admin</Typography>
          <div className={classes.x} onClick={onClose}>
            <ClearIcon />
          </div>
        </div>
        <ModalDataForm onChange={onChange} styles={styles} />
        <ModalPermissions
          styles={styles}
          permissions={permissions}
          setPermissions={setPermissions}
          permissionOptions={permissionOptions}
        />
        <ModalButtons
          buttonText1="Cancel"
          buttonText2="Create"
          onClick1={onClose}
          onClick2={() => onCreateAdmin(entity, adminData)}
        />
      </div>
    </Modal>
  );
};
