/* eslint-disable no-restricted-syntax */
import { ONEDAYMILLISECONDS } from './date.utils';

export const filterCustomerData = (tableBodyData, filterObj) => {
  let data = [...tableBodyData];
  // Filter by creation date from
  if (filterObj.dateFrom) {
    data = data.filter(record => new Date(record.createdAt) > new Date(filterObj.dateFrom));
  }

  if (filterObj.dateTo) {
    const toDate = new Date(filterObj.dateTo);
    toDate.setTime(toDate.getTime() + ONEDAYMILLISECONDS);
    data = data.filter(record => new Date(record.createdAt) < toDate);
  }

  // Filter by status
  if (Object.values(filterObj.status).some(status => !!status)) {
    const arrayOfStatusToFilter = [];

    for (const [key, value] of Object.entries(filterObj.status)) {
      if (value) {
        if (key === 'rejected') {
          arrayOfStatusToFilter.push('account_verification_failed');
          arrayOfStatusToFilter.push('soft_failed');
          arrayOfStatusToFilter.push('hard_failed');
        } else {
          arrayOfStatusToFilter.push(key);
        }
      }
    }
    data = data.filter(record => arrayOfStatusToFilter.includes(record.accountStatus));
  }

  return data;
};
