import React, { useRef, useState } from 'react';
import { useForm } from 'react-hook-form';

import { useStyles } from './pairs-details.hooks';
// import { getDataToSend } from './pairs-details.utils';
import { PairsHeader } from './pairs-header';
import { PairsIntervals } from './pairs-intervals';
import { PairsTabPanel } from './pairs-tab-panel';

const intervalsMock = [
  {
    id: '123',
    title: 'first',
    start: 0,
    end: 100000,
  },
  {
    id: '456',
    title: 'second',
    start: 100000,
    end: 1000000,
  },
  {
    id: '789',
    title: 'last',
    start: 1000000,
    end: null,
  },
];

const pairsMock = [
  {
    id: '1',
    flatRate: 38.6891962406,
    pairFromId: 'AED',
    pairToId: 'AED',
    rateId: '123',
    countryIso: 'ARE',
    profitMargin: {
      type: 'percentage',
      percentageValue: '1',
      absoluteValue: 'null',
    },
    fee: {
      percentage: '1',
      absolute: '1.00231',
    },
  },
  {
    id: '2',
    flatRate: 36.6891962406,
    pairFromId: 'AUD',
    pairToId: 'USD',
    rateId: '456',
    countryIso: 'USA',
    profitMargin: {
      type: 'percentage',
      percentageValue: '1',
      absoluteValue: 'null',
    },
    fee: {
      percentage: '1',
      absolute: '1.00231',
    },
  },
  {
    id: '3',
    flatRate: 30.6891962406,
    pairFromId: 'AUD',
    pairToId: 'ZAR',
    rateId: '789',
    countryIso: 'ZAF',
    profitMargin: {
      type: 'percentage',
      percentageValue: '1',
      absoluteValue: 'null',
    },
    fee: {
      percentage: '1',
      absolute: '1.00231',
    },
  },
  {
    id: '4',
    flatRate: 48.6891962406,
    pairFromId: 'AUD',
    pairToId: 'AUD',
    rateId: '123',
    countryIso: 'AUS',
    profitMargin: {
      type: 'absolute',
      percentageValue: null,
      absoluteValue: '0.05',
    },
    fee: {
      percentage: '1',
      absolute: '1.00231',
    },
  },
  {
    id: '5',
    flatRate: 46.6891962406,
    pairFromId: 'AUD',
    pairToId: 'SGD',
    rateId: '456',
    countryIso: 'SGP',
    profitMargin: {
      type: 'percentage',
      percentageValue: '1',
      absoluteValue: 'null',
    },
    fee: {
      percentage: '1',
      absolute: '1.00231',
    },
  },
  {
    id: '6',
    flatRate: 40.6891962406,
    pairFromId: 'AUD',
    pairToId: 'AUD',
    rateId: '789',
    countryIso: 'AUS',
    profitMargin: {
      type: 'percentage',
      percentageValue: '1',
      absoluteValue: 'null',
    },
    fee: {
      percentage: '1',
      absolute: '1.00231',
    },
  },
  {
    id: '7',
    flatRate: 58.6891962406,
    pairFromId: 'AUD',
    pairToId: 'BHD',
    rateId: '123',
    countryIso: 'BHR',
    profitMargin: {
      type: 'percentage',
      percentageValue: '1',
      absoluteValue: 'null',
    },
    fee: {
      percentage: '1',
      absolute: '1.00231',
    },
  },
  {
    id: '8',
    flatRate: 56.6891962406,
    pairFromId: 'AUD',
    pairToId: 'DKK',
    rateId: '456',
    countryIso: 'DNK',
    profitMargin: {
      type: 'percentage',
      percentageValue: '1',
      absoluteValue: 'null',
    },
    fee: {
      percentage: '1',
      absolute: '1.00231',
    },
  },
  {
    id: '10',
    flatRate: 50.6891962406,
    pairFromId: 'AUD',
    pairToId: 'CAD',
    rateId: '123',
    countryIso: 'CAN',
    profitMargin: {
      type: 'percentage',
      percentageValue: '1',
      absoluteValue: 'null',
    },
    fee: {
      percentage: '1',
      absolute: '1.00231',
    },
  },
  {
    id: '11',
    flatRate: 50.6891962406,
    pairFromId: 'AUD',
    pairToId: 'CHF',
    rateId: '123',
    countryIso: 'CHE',
    profitMargin: {
      type: 'percentage',
      percentageValue: '1',
      absoluteValue: 'null',
    },
    fee: {
      percentage: '1',
      absolute: '1.00231',
    },
  },
  {
    id: '12',
    flatRate: 50.6891962406,
    pairFromId: 'AUD',
    pairToId: 'CNY',
    rateId: '123',
    countryIso: 'CHN',
    profitMargin: {
      type: 'percentage',
      percentageValue: '1',
      absoluteValue: 'null',
    },
    fee: {
      percentage: '1',
      absolute: '1.00231',
    },
  },
  {
    id: '13',
    flatRate: 50.6891962406,
    pairFromId: 'AUD',
    pairToId: 'CZK',
    rateId: '123',
    countryIso: 'CZE',
    profitMargin: {
      type: 'percentage',
      percentageValue: '1',
      absoluteValue: 'null',
    },
    fee: {
      percentage: '1',
      absolute: '1.00231',
    },
  },
  {
    id: '14',
    flatRate: 50.6891962406,
    pairFromId: 'AUD',
    pairToId: 'DKK',
    rateId: '123',
    countryIso: 'DNK',
    profitMargin: {
      type: 'percentage',
      percentageValue: '1',
      absoluteValue: 'null',
    },
    fee: {
      percentage: '1',
      absolute: '1.00231',
    },
  },
  {
    id: '15',
    flatRate: 50.6891962406,
    pairFromId: 'AUD',
    pairToId: 'EUR',
    rateId: '123',
    countryIso: 'EUR',
    profitMargin: {
      type: 'percentage',
      percentageValue: '1',
      absoluteValue: 'null',
    },
    fee: {
      percentage: '1',
      absolute: '1.00231',
    },
  },
  {
    id: '16',
    flatRate: 50.6891962406,
    pairFromId: 'AUD',
    pairToId: 'GBP',
    rateId: '123',
    countryIso: 'GBR',
    profitMargin: {
      type: 'percentage',
      percentageValue: '1',
      absoluteValue: 'null',
    },
    fee: {
      percentage: '1',
      absolute: '1.00231',
    },
  },
  {
    id: '17',
    flatRate: 50.6891962406,
    pairFromId: 'AUD',
    pairToId: 'HKD',
    rateId: '123',
    countryIso: 'HKG',
    profitMargin: {
      type: 'percentage',
      percentageValue: '1',
      absoluteValue: 'null',
    },
    fee: {
      percentage: '1',
      absolute: '1.00231',
    },
  },
  {
    id: '18',
    flatRate: 50.6891962406,
    pairFromId: 'AUD',
    pairToId: 'HUF',
    rateId: '123',
    countryIso: 'HUN',
    profitMargin: {
      type: 'percentage',
      percentageValue: '1',
      absoluteValue: 'null',
    },
    fee: {
      percentage: '1',
      absolute: '1.00231',
    },
  },
  {
    id: '19',
    flatRate: 50.6891962406,
    pairFromId: 'AUD',
    pairToId: 'JPY',
    rateId: '123',
    countryIso: 'JPN',
    profitMargin: {
      type: 'percentage',
      percentageValue: '1',
      absoluteValue: 'null',
    },
    fee: {
      percentage: '1',
      absolute: '1.00231',
    },
  },
  {
    id: '20',
    flatRate: 50.6891962406,
    pairFromId: 'AUD',
    pairToId: 'KWD',
    rateId: '123',
    countryIso: 'KWT',
    profitMargin: {
      type: 'percentage',
      percentageValue: '1',
      absoluteValue: 'null',
    },
    fee: {
      percentage: '1',
      absolute: '1.00231',
    },
  },
  {
    id: '21',
    flatRate: 50.6891962406,
    pairFromId: 'AUD',
    pairToId: 'MAD',
    rateId: '123',
    countryIso: 'MAR',
    profitMargin: {
      type: 'percentage',
      percentageValue: '1',
      absoluteValue: 'null',
    },
    fee: {
      percentage: '1',
      absolute: '1.00231',
    },
  },
  {
    id: '22',
    flatRate: 50.6891962406,
    pairFromId: 'AUD',
    pairToId: 'MXN',
    rateId: '123',
    countryIso: 'MEX',
    profitMargin: {
      type: 'percentage',
      percentageValue: '1',
      absoluteValue: 'null',
    },
    fee: {
      percentage: '1',
      absolute: '1.00231',
    },
  },
  {
    id: '23',
    flatRate: 50.6891962406,
    pairFromId: 'AUD',
    pairToId: 'NOK',
    rateId: '123',
    countryIso: 'NOR',
    profitMargin: {
      type: 'percentage',
      percentageValue: '1',
      absoluteValue: 'null',
    },
    fee: {
      percentage: '1',
      absolute: '1.00231',
    },
  },
  {
    id: '24',
    flatRate: 50.6891962406,
    pairFromId: 'AUD',
    pairToId: 'NZD',
    rateId: '123',
    countryIso: 'NZL',
    profitMargin: {
      type: 'percentage',
      percentageValue: '1',
      absoluteValue: 'null',
    },
    fee: {
      percentage: '1',
      absolute: '1.00231',
    },
  },
  {
    id: '25',
    flatRate: 50.6891962406,
    pairFromId: 'AUD',
    pairToId: 'OMR',
    rateId: '123',
    countryIso: 'OMN',
    profitMargin: {
      type: 'percentage',
      percentageValue: '1',
      absoluteValue: 'null',
    },
    fee: {
      percentage: '1',
      absolute: '1.00231',
    },
  },
  {
    id: '26',
    flatRate: 50.6891962406,
    pairFromId: 'AUD',
    pairToId: 'PLN',
    rateId: '123',
    countryIso: 'POL',
    profitMargin: {
      type: 'percentage',
      percentageValue: '1',
      absoluteValue: 'null',
    },
    fee: {
      percentage: '1',
      absolute: '1.00231',
    },
  },
  {
    id: '27',
    flatRate: 50.6891962406,
    pairFromId: 'AUD',
    pairToId: 'QAR',
    rateId: '123',
    countryIso: 'QAT',
    profitMargin: {
      type: 'percentage',
      percentageValue: '1',
      absoluteValue: 'null',
    },
    fee: {
      percentage: '1',
      absolute: '1.00231',
    },
  },
  {
    id: '28',
    flatRate: 50.6891962406,
    pairFromId: 'AUD',
    pairToId: 'SAR',
    rateId: '123',
    countryIso: 'SAU',
    profitMargin: {
      type: 'percentage',
      percentageValue: '1',
      absoluteValue: 'null',
    },
    fee: {
      percentage: '1',
      absolute: '1.00231',
    },
  },
  {
    id: '29',
    flatRate: 50.6891962406,
    pairFromId: 'AUD',
    pairToId: 'SEK',
    rateId: '123',
    countryIso: 'SWE',
    profitMargin: {
      type: 'percentage',
      percentageValue: '1',
      absoluteValue: 'null',
    },
    fee: {
      percentage: '1',
      absolute: '1.00231',
    },
  },
  {
    id: '30',
    flatRate: 50.6891962406,
    pairFromId: 'AUD',
    pairToId: 'SGD',
    rateId: '123',
    countryIso: 'SGP',
    profitMargin: {
      type: 'percentage',
      percentageValue: '1',
      absoluteValue: 'null',
    },
    fee: {
      percentage: '1',
      absolute: '1.00231',
    },
  },
  {
    id: '31',
    flatRate: 50.6891962406,
    pairFromId: 'AUD',
    pairToId: 'THB',
    rateId: '123',
    countryIso: 'THA',
    profitMargin: {
      type: 'percentage',
      percentageValue: '1',
      absoluteValue: 'null',
    },
    fee: {
      percentage: '1',
      absolute: '1.00231',
    },
  },
  {
    id: '32',
    flatRate: 50.6891962406,
    pairFromId: 'AUD',
    pairToId: 'TRY',
    rateId: '123',
    countryIso: 'TUR',
    profitMargin: {
      type: 'percentage',
      percentageValue: '1',
      absoluteValue: 'null',
    },
    fee: {
      percentage: '1',
      absolute: '1.00231',
    },
  },
  {
    id: '33',
    flatRate: 50.6891962406,
    pairFromId: 'AUD',
    pairToId: 'USD',
    rateId: '123',
    countryIso: 'USA',
    profitMargin: {
      type: 'percentage',
      percentageValue: '1',
      absoluteValue: 'null',
    },
    fee: {
      percentage: '1',
      absolute: '1.00231',
    },
  },
  {
    id: '34',
    flatRate: 50.6891962406,
    pairFromId: 'AUD',
    pairToId: 'ZAR',
    rateId: '123',
    countryIso: 'ZAF',
    profitMargin: {
      type: 'percentage',
      percentageValue: '1',
      absoluteValue: 'null',
    },
    fee: {
      percentage: '1',
      absolute: '1.00231',
    },
  },
];

export const PairsDetails = props => {
  const [intervalIndex, setIntervalIndex] = useState(0);
  const formRef = useRef();

  const classes = useStyles();
  const {
    handleSubmit,
    control,
    formState: { errors }, // dirtyFields
    watch,
  } = useForm();

  const handleFormSubmit = async (values, event) => {
    if (formRef.current !== event.target) {
      return false;
    }

    // const dataToSend = getDataToSend(Object.keys(dirtyFields), values);

    // console.log('dataToSend', dataToSend);

    // await saveEntity({ entity, values, id, hasOrder });

    props.onClose(false);

    return true;
  };

  return (
    <>
      <PairsIntervals {...{ intervalIndex, setIntervalIndex, intervals: intervalsMock }} />
      <PairsHeader />
      <form onSubmit={handleSubmit(handleFormSubmit)} ref={formRef}>
        {intervalsMock.map((interval, index) => {
          const filteredPairs = pairsMock.filter(pair => pair.rateId === interval.id);

          return (
            <PairsTabPanel
              pairsList={filteredPairs}
              key={interval.id}
              index={index}
              value={intervalIndex}
              control={control}
              errors={errors}
              watch={watch}
            />
          );
        })}
        <button type="submit" className={classes.button}>
          Save
        </button>
      </form>
    </>
  );
};
