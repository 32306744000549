import React from 'react';

import { PairsCancelIcon } from '../../../icons/pairs';
import { useStyles } from './button.hooks';

export const CancelButton = ({ cancel }) => {
  const classes = useStyles();

  return (
    <button
      className={`${classes.defaultBtnStyle} ${classes.btnCancel}`}
      type="button"
      onClick={cancel}
    >
      <PairsCancelIcon />
      Cancel
    </button>
  );
};
