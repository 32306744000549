import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
  input: {
    display: 'none',
  },
  imgUploaded: {
    maxWidth: '98%',
  },
}));
