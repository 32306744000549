import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
  formControl: {
    width: '100%',
    '& .MuiOutlinedInput-root': {
      padding: '0.7rem',
      minHeight: '5.3rem',
    },
    '& span': {
      fontSize: '1.4rem',
    },
    '& .MuiSvgIcon-root': {
      height: '2.4rem',
      width: '2.4rem',
    },
  },
}));
