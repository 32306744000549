import React from 'react';

export const RedDeleteIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="66" height="66" viewBox="0 0 66 66">
    <g data-name="Group 8879" transform="translate(-189.997 -356)">
      <circle
        data-name="Ellipse 1675"
        cx="33"
        cy="33"
        r="33"
        transform="translate(189.997 356)"
        style={{ fill: '#fc3333' }}
      />
      <path
        data-name="Union 369"
        d="M10.325 33.822a5.138 5.138 0 0 1-5.291-4.4c-.212-1.709-.267-3.439-.381-5.16q-.545-8.249-1.075-16.5c-.017-.279-.118-.338-.388-.334a34.85 34.85 0 0 1-1.993 0A1.243 1.243 0 0 1 0 6.31a1.229 1.229 0 0 1 .977-1.294 3.216 3.216 0 0 1 .606-.055q4.251-.013 8.5-.009c.257 0 .351-.055.334-.313-.022-.423-.01-.85-.006-1.273a3.368 3.368 0 0 1 3.45-3.345c2.03-.03 4.065-.025 6.1 0a3.394 3.394 0 0 1 3.409 2.758 9.259 9.259 0 0 1 .051 1.54c.008.626 0 .626.643.63 2.793 0 5.584 0 8.376.017a1.273 1.273 0 0 1 1.39 1.252 1.279 1.279 0 0 1-1.387 1.214c-.622.008-1.247.008-1.869 0-.216 0-.308.046-.322.27-.037.8-.1 1.595-.156 2.39l-.6 8.786c-.228 3.329-.49 6.654-.694 9.982a5.216 5.216 0 0 1-5.464 4.962h-6.464c-1.455 0-2.91.006-4.365.006q-1.093-.001-2.184-.006zM6.175 7.9q.281 4.048.542 8.1l.52 7.978c.11 1.683.2 3.371.339 5.05a2.652 2.652 0 0 0 2.842 2.326q6.5.012 13.01 0A4.343 4.343 0 0 0 24 31.3a2.581 2.581 0 0 0 2.267-2.331q.382-6.053.749-12.106c.187-3.007.369-6.015.571-9.022.022-.342-.084-.393-.41-.393-3.442.013-17.186.068-20.616.059h-.025c-.317.005-.381.1-.361.393zM20.49 4.948c.266 0 .351-.059.333-.313-.021-.4 0-.8 0-1.2-.008-.588-.3-.863-.939-.863-1.163-.009-2.33 0-3.493 0h-2.44c-.652.008-.932.283-.936.9 0 .4.014.8 0 1.2-.012.22.063.275.292.275 1.206-.013 2.411 0 3.617 0h2.044a77.253 77.253 0 0 1 1.522.001zm-4.874 21q-.006-6.567 0-13.129a1.193 1.193 0 0 1 .237-.723 1.349 1.349 0 0 1 1.434-.487 1.244 1.244 0 0 1 .93 1.218v6.522q.006 3.287 0 6.565a1.3 1.3 0 0 1-2.6.034z"
        transform="translate(206.558 372.56)"
        style={{ fill: '#fff' }}
      />
    </g>
  </svg>
);
