import { TableRow } from '@mui/material';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { CountriesTableCell } from './components';
import { useStyles } from './countries-table-body.hooks';

/**
 * Renders the countries and their respective currencies for configuration. Data
 * is from the GenericTable component.
 * @param {Object[]} data - the countries data entity of type Country
 * @param {Array} tableColumns - the table columns associated with the Country entity
 */
export const CountriesTableBody = ({ data, tableColumns }) => {
  const [isHovering, setIsHovering] = useState('');
  const navigate = useNavigate();
  const classes = useStyles();

  if (data && data.every(item => !!item.currency))
    return (
      <>
        {data.map(rowData => (
          <TableRow
            key={rowData.id}
            onMouseEnter={() => setIsHovering(rowData.id)}
            onMouseLeave={() => setIsHovering('')}
            sx={{
              cursor: 'pointer',
              '& .MuiTableCell-root': {
                borderBottom: '1px solid #e6e6e6',
              },
              '&:hover .MuiTableCell-root:first-of-type': {
                borderRadius: '8px 0 0 8px',
              },
              '&:hover .MuiTableCell-root:last-of-type': {
                borderRadius: '0 8px 8px 0',
              },
            }}
            className={classes.tableRow}
            onClick={() => navigate(`/country/${rowData.id}`)}
          >
            {tableColumns.map(tableField => (
              <CountriesTableCell
                key={tableField.label}
                tableField={tableField}
                data={rowData}
                isHovering={isHovering === rowData.id}
              />
            ))}
          </TableRow>
        ))}
      </>
    );

  return null;
};
