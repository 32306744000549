import { getCurrencySymbol, getFormattedDisplayDate, getFormattedDisplayTime } from '../../utils';

export const transactionDetailsObj = transactionData => {
  let transactionType;
  let debitOrCredit;

  if (transactionData.subType === 'TRANSFER') {
    transactionType = 'Outgoing';
    debitOrCredit = 'Debit';
  }
  if (transactionData.subType === 'TOPUP') {
    transactionType = 'Incoming';
    debitOrCredit = 'Credit';
  }
  if (transactionData.subType === 'CONVERT') {
    transactionType = 'Exchange';
    debitOrCredit = 'Credit';
  }
  if (transactionData.subType === 'DIRECT_DEBIT') {
    transactionType = 'Outgoing';
    debitOrCredit = 'Debit';
  }

  const cancellationCode = 'N/A';

  const amount = `${getCurrencySymbol(transactionData.currency)}${
    transactionData.amount?.toLocaleString() || transactionData.amount
  }`;

  const cancellationReason = 'N/A';

  let beneficiaryAccountName = 'N/A';
  let beneficiaryAccountNo = 'N/A';
  let beneficiaryIBAN = 'N/A';
  let beneficiarySortCode = 'N/A';
  let beneficiaryInstitutionBIC = 'N/A';

  let remittingAccountName = 'N/A';
  let remittingAccountNumber = 'N/A';
  let remittingIBAN = 'N/A';
  let remittingSortCode = 'N/A';

  if (transactionData?.subType === 'TOPUP') {
    if (transactionData?.debtorName && transactionData?.debtorName !== 'undefined undefined') {
      remittingAccountName = transactionData?.debtorName;
    }

    // if (
    //   debtorAccount?.ownerName &&
    //   debtorAccount?.ownerName !== 'Not Provided' &&
    //   debtorAccount?.ownerName !== 'undefined undefined'
    // ) {
    //   remittingAccountName = debtorAccount.ownerName;
    // }

    if (transactionData?.debtorIban) {
      remittingAccountNumber = transactionData?.debtorAccountNumber;
      remittingIBAN = transactionData?.debtorIban;
      remittingSortCode = transactionData?.debtorSortCode;
    }

    // if (debtorAccount?.iban && validator.isIBAN(debtorAccount?.iban)) {
    //   remittingAccountNumber = getAccountNoFromIban(debtorAccount.iban);
    //   remittingIBAN = debtorAccount.iban;
    //   remittingSortCode = getSortCodeFromIban(debtorAccount.iban);
    // }

    if (transactionData?.creditorName && transactionData?.creditorName !== 'undefined undefined') {
      beneficiaryAccountName = transactionData?.creditorName;
    }

    // if (
    //   creditorAccount?.ownerName &&
    //   creditorAccount?.ownerName !== 'Not Provided' &&
    //   creditorAccount?.ownerName !== 'undefined undefined'
    // ) {
    //   beneficiaryAccountName = creditorAccount.ownerName;
    // }

    if (transactionData?.creditorIban) {
      beneficiaryAccountNo = transactionData?.creditorAccountNumber;
      beneficiaryIBAN = transactionData?.creditorIban;
      beneficiarySortCode = transactionData?.creditorSortCode;
    }

    // if (creditorAccount?.iban && validator.isIBAN(creditorAccount?.iban)) {
    //   beneficiaryAccountNo = getAccountNoFromIban(creditorAccount.iban);
    //   beneficiaryIBAN = creditorAccount?.iban;
    //   beneficiarySortCode = getSortCodeFromIban(creditorAccount.iban);
    // }

    // if (userTo) {
    //   beneficiaryAccountName = `${userTo.name} ${userTo.lastName}`;
    //   beneficiaryAccountNo = getAccountNoFromIban(userTo.poundsAccount?.iban);
    //   beneficiaryIBAN = userTo.poundsAccount?.iban;
    //   beneficiarySortCode = getSortCodeFromIban(userTo.poundsAccount?.iban);
    // }

    // Temp solution for creditorAccount IBAN for remittingAccountNumber
    // if (creditorAccount?.descriptor && validator.isIBAN(creditorAccount?.descriptor)) {
    //   remittingAccountNumber = getAccountNoFromIban(creditorAccount.descriptor);
    //   remittingIBAN = creditorAccount.descriptor;
    //   remittingSortCode = getSortCodeFromIban(creditorAccount.descriptor);
    // }
  }

  // Other way round for this transaction type
  if (transactionData?.subType === 'TRANSFER' || transactionData?.subType === 'DIRECT_DEBIT') {
    beneficiaryAccountName = transactionData?.creditorName ?? 'N/A';
    beneficiaryAccountNo = transactionData?.creditorAccountNumber ?? 'N/A';
    beneficiaryIBAN = transactionData?.creditorIban ?? 'N/A';
    beneficiarySortCode = transactionData?.creditorSortCode ?? 'N/A';

    remittingAccountName = transactionData?.debtorName ?? 'N/A';
    remittingAccountNumber = transactionData?.debtorAccountNumber ?? 'N/A';
    remittingIBAN = transactionData?.debtorIban ?? 'N/A';
    remittingSortCode = transactionData?.debtorSortCode ?? 'N/A';

    beneficiaryInstitutionBIC = transactionData.swift || 'N/A';
  }

  if (transactionData?.subType === 'CONVERT') {
    beneficiaryAccountName = transactionData?.creditorName ?? 'N/A';
    beneficiaryAccountNo = transactionData?.creditorAccountNumber ?? 'N/A';
    beneficiaryIBAN = transactionData?.creditorIban ?? 'N/A';
    beneficiarySortCode = transactionData?.creditorSortCode ?? 'N/A';

    remittingAccountName = transactionData?.debtorName ?? 'N/A';
    remittingAccountNumber = transactionData?.debtorAccountNumber ?? 'N/A';
    remittingIBAN = transactionData?.debtorIban ?? 'N/A';
    remittingSortCode = transactionData?.debtorSortCode ?? 'N/A';
  }

  const transactionDetailsLHS = {
    'Transaction Time:': `${getFormattedDisplayDate(
      transactionData.createdAt
    )}, ${getFormattedDisplayTime(transactionData.createdAt)}`,
    'Transaction Type:': transactionType,
    'Debit/Credit:': debitOrCredit,
    'Cancellation Code:': cancellationCode,
  };

  if (transactionData?.debtorUserId && transactionData?.creditorUserId) {
    transactionDetailsLHS['Payment Method:'] = 'Internal';
  } else {
    transactionDetailsLHS['Payment Method:'] = 'External';
  }

  const transactionDetailsRHS = {
    'Amount:': amount,
    'Customer Reference:': transactionData.reference || 'N/A',
    'Identifier Field Ref:': transactionData.internalId,
    'End to End ID:': transactionData.endToEndId,
    'Cancellation Reason:': cancellationReason,
  };

  const remittingAccountDetails = {
    'Account Name:': remittingAccountName,
    'Account Number:': remittingAccountNumber,
    'IBAN:': remittingIBAN,
    'Sort Code:': remittingSortCode,
  };

  const beneficiaryAccountDetails = {
    'Transaction Owner Name:': beneficiaryAccountName,
    'Account Number:': beneficiaryAccountNo,
    'IBAN:': beneficiaryIBAN,
    'Sort Code:': beneficiarySortCode,
  };

  let remittingInstitutionDetails = {
    'Account Name:': transactionData.debtorName ?? 'N/A',
    'Account Number:': transactionData.debtorAccountNumber || 'N/A',
  };

  if (transactionData.subType === 'TRANSFER' || transactionData.subType === 'TOPUP') {
    remittingInstitutionDetails = {
      'Account Name:': transactionData.debtorName ?? 'N/A',
      'Account Number:': transactionData.debtorAccountNumber || 'N/A',
    };

    if (transactionData.debtorUserId) {
      remittingInstitutionDetails = {
        'Account Name:': transactionData.debtorName,
        'Account Number:': transactionData.debtorAccountNumber || 'N/A',
      };
    }
  }

  const beneficiaryInstitutionDetails = {
    'BIC:': beneficiaryInstitutionBIC,
  };

  return {
    transactionDetailsLHS,
    transactionDetailsRHS,
    remittingAccountDetails,
    beneficiaryAccountDetails,
    remittingInstitutionDetails,
    beneficiaryInstitutionDetails,
  };
};

export const modifyRemittingAccountDetails = obj => {
  const modifiedObj = { ...obj };
  modifiedObj['Remitting Account Name'] = modifiedObj['Account Name:'];
  delete modifiedObj['Account Name:'];
  modifiedObj['Remitting Account Number'] = modifiedObj['Account Number:'];
  delete modifiedObj['Account Number:'];
  modifiedObj['Remitting IBAN'] = modifiedObj['IBAN:'];
  delete modifiedObj['IBAN:'];
  modifiedObj['Remitting Sort Code'] = modifiedObj['Sort Code:'];
  delete modifiedObj['Sort Code:'];

  return modifiedObj;
};

export const modifyBeneficiaryAccountDetails = obj => {
  const modifiedObj = { ...obj };
  modifiedObj['Beneficiary Account Number'] = modifiedObj['Account Number:'];
  delete modifiedObj['Account Number:'];
  modifiedObj['Beneficiary IBAN'] = modifiedObj['IBAN:'];
  delete modifiedObj['IBAN:'];
  modifiedObj['Beneficiary Sort Code'] = modifiedObj['Sort Code:'];
  delete modifiedObj['Sort Code:'];

  return modifiedObj;
};

export const modifyRemittingInstitutionDetails = obj => {
  const modifiedObj = { ...obj };
  modifiedObj['Remitting Institution Account Name'] = modifiedObj['Account Name:'];
  delete modifiedObj['Account Name:'];
  modifiedObj['Remitting Institution Account Number'] = modifiedObj['Account Number:'];
  delete modifiedObj['Account Number:'];

  return modifiedObj;
};
