import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(theme => ({
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  line: {
    display: 'inline-block',
    borderTop: `0.2rem solid ${theme.palette.primary.main}`,
    width: '48%',
  },
  triangle: {
    display: 'inline-block',
    height: '0.8rem',
    width: '0.8rem',
    transform: 'rotate(45deg)',
    transformOrigin: 'center center',
    borderTop: `0.2rem solid ${theme.palette.primary.main}`,
    borderLeft: `0.2rem solid ${theme.palette.primary.main}`,
    margin: '0 0.1rem 0.1rem',
  },
}));
