import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(theme => ({
  drawer: {
    border: 'none',
    position: 'absolute',
    width: '90px',
    overflowY: 'clip',
    [theme.breakpoints.up('lg')]: {
      height: '100%',
    },
  },
  root: {
    backgroundColor: theme.palette.primary.nav,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    width: '90px',
    paddingTop: '2.8rem',
  },
  divider: {
    margin: '1.6rem 0',
  },
  nav: {},
  logo: {
    alignSelf: 'center',
    marginBottom: '8px',
  },
  logout: {
    alignSelf: 'center',
    position: 'relative',
    bottom: '15vh',
    cursor: 'pointer',
    marginTop: "80px",
    padding: "20px"
  },
}));
