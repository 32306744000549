import React from 'react';

export const NextIcon = ({ className, style, onClick }) => (
  <div
    className={className}
    style={{
      ...style,
      backgroundColor: 'grey',
      borderRadius: '16px',
    }}
    onClick={onClick}
  >
    <div style={{ position: 'relative', top: '-22px' }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
        <g data-name="Group 8211" transform="translate(-1256 -264)">
          <circle
            data-name="Ellipse 1491"
            cx="12"
            cy="12"
            r="12"
            transform="translate(1256 264)"
            style={{ fill: '#f2f2f4' }}
          />
          <path
            data-name="Path 19359"
            d="M1773.1 100.981a.5.5 0 0 1-.354-.146l-4.136-4.136a.5.5 0 1 1 .707-.707l3.782 3.783 3.783-3.783a.5.5 0 0 1 .707.707l-4.136 4.136a.5.5 0 0 1-.353.146z"
            transform="rotate(-90 1609.16 439.572)"
            style={{ fill: '#19365e' }}
          />
        </g>
      </svg>
    </div>
  </div>
);
