import React from 'react';

export const EmailIcon = ({ width = '24', height = '24', active = false }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 24 24">
    <g data-name="Group 8361">
      <g data-name="Ellipse 1530" style={{ fill: active ? '#093560' : '#fff', stroke: '#f2f2f4' }}>
        <circle cx="12" cy="12" r="12" style={{ stroke: 'none' }} />
        <circle cx="12" cy="12" r="11.5" style={{ fill: 'none' }} />
      </g>
      <path
        data-name="Icon zocial-email"
        d="M.072 11.359V4.777q0-.011.035-.217L4.03 7.874.118 11.588a.958.958 0 0 1-.046-.229zM.593 4.1a.5.5 0 0 1 .2-.034h10.562a.663.663 0 0 1 .208.034L7.628 7.428l-.521.411-1.03.834-1.03-.834-.521-.411zM.6 12.034 4.55 8.3l1.528 1.219L7.605 8.3l3.946 3.737a.562.562 0 0 1-.2.034H.789a.53.53 0 0 1-.189-.037zm7.522-4.16 3.911-3.314a.674.674 0 0 1 .035.217v6.583a.866.866 0 0 1-.035.229z"
        transform="translate(5.928 3.932)"
        style={{ fill: active ? '#fff' : '#b5b5b5' }}
      />
    </g>
  </svg>
);
