import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import React, { useState } from 'react';

import { toStringOrEmptyString } from '../../../../utils';
import { useStyles } from './filter-range.hooks';

export const FilterRange = ({ field, filters, setFilters }) => {
  const {
    label,
    filter: { schemaField },
  } = field;
  const classes = useStyles();
  const ranges = filters[schemaField] || { rangeIni: null, rangeEnd: null };

  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);

  const onClick = e => {
    setAnchorEl(e.currentTarget);
    setOpen(true);
  };

  const onChange = value => {
    const newFilters = { ...filters, [schemaField]: value };
    setFilters(newFilters);
  };

  return (
    <div>
      <div onClick={onClick} className={classes.button}>
        <div>{label}</div>
        <div>{open ? <ExpandLess /> : <ExpandMore />}</div>
      </div>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={() => setOpen(false)}
      >
        <MenuItem>
          <TextField
            fullWidth
            label="From"
            margin="none"
            size="small"
            variant="outlined"
            name="rangeIni"
            onChange={e => onChange({ ...ranges, rangeIni: e.target.value })}
            type="number"
            value={toStringOrEmptyString(ranges.rangeIni)}
          />
        </MenuItem>
        <MenuItem>
          <TextField
            fullWidth
            label="To"
            margin="none"
            size="small"
            name="rangeEnd"
            variant="outlined"
            onChange={e => onChange({ ...ranges, rangeEnd: e.target.value })}
            type="number"
            value={toStringOrEmptyString(ranges.rangeEnd)}
          />
        </MenuItem>
        <MenuItem onClick={() => setOpen(false)}>Close</MenuItem>
      </Menu>
      {(ranges.rangeIni || ranges.rangeEnd) && (
        <span>
          {ranges.rangeIni > 0 ? `${ranges.rangeIni}` : 0}
          {ranges.rangeEnd > 0 && ` - ${ranges.rangeEnd}`}
        </span>
      )}
    </div>
  );
};
