import React from 'react';

export const NotificationBell = ({ width = '24', height = '24', active = false }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 24 24">
    <g data-name="Group 8362">
      <g data-name="Ellipse 1531" style={{ fill: active ? '#1fe3ac' : '#fff', stroke: '#f2f2f4' }}>
        <circle cx="12" cy="12" r="12" style={{ stroke: 'none' }} />
        <circle cx="12" cy="12" r="11.5" style={{ fill: 'none' }} />
      </g>
      <g data-name="Icon ionic-ios-notifications">
        <path
          data-name="Path 19530"
          d="M16.051 29.927a1.415 1.415 0 0 0 1.449-1.591h-2.9a1.416 1.416 0 0 0 1.451 1.591z"
          transform="translate(-4.057 -11.927)"
          style={{ fill: active ? '#fff' : '#b5b5b5' }}
        />
        <path
          data-name="Path 19531"
          d="M16.234 12.815c-.462-.609-1.371-.966-1.371-3.691 0-2.8-1.236-3.922-2.387-4.192-.108-.027-.186-.063-.186-.177v-.087a.732.732 0 1 0-1.464 0v.087c0 .111-.078.15-.186.177-1.155.268-2.387 1.394-2.387 4.192 0 2.726-.909 3.079-1.371 3.691a.6.6 0 0 0 .477.954h8.4a.6.6 0 0 0 .475-.954z"
          style={{ fill: active ? '#fff' : '#b5b5b5' }}
          transform="translate(.442 2.07)"
        />
      </g>
    </g>
  </svg>
);
