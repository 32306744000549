import { palette } from '../palette';

export const accountStatus = {
  fontWeight: 700,
  '&.active': {
    color: palette.primary.main,
  },
  '&.suspended, &.account_verification_failed': {
    color: palette.error.main,
  },
  '&.closed': {
    color: palette.info.main,
  },
  '&.created': {
    color: palette.warning.main,
  },
};
