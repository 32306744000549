import React from 'react';

export const PairsCancelIcon = ({ active = true }) => {
  const fillColor = active ? '#19365e' : '#a5a6ae';

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="23.999"
      height="23.999"
      viewBox="0 0 23.999 23.999"
    >
      <path
        data-name="Union 511"
        d="M-948.146-5632a4.868 4.868 0 0 1-4.855-4.853v-14.291a4.87 4.87 0 0 1 4.855-4.855h14.291a4.869 4.869 0 0 1 4.853 4.855v14.291a4.865 4.865 0 0 1-4.853 4.853zm-3.259-19.147v14.291a3.269 3.269 0 0 0 3.26 3.262h14.291a3.271 3.271 0 0 0 3.262-3.262v-14.291a3.272 3.272 0 0 0-3.262-3.262h-14.291a3.269 3.269 0 0 0-3.255 3.261zm3.667 13.752-.009.017-.123-.123a.9.9 0 0 1 0-1.25l5.567-5.449-5.432-5.411a.893.893 0 0 1 0-1.25.866.866 0 0 1 .606-.265h.028a.877.877 0 0 1 .592.234l.03.033 5.444 5.408 5.411-5.385a.871.871 0 0 1 1.219-.024l.03.03a.892.892 0 0 1 0 1.248l-5.407 5.381 5.471 5.448a.9.9 0 0 1 0 1.248.857.857 0 0 1-.6.264h-.033a.873.873 0 0 1-.568-.21l-.055-.051-5.479-5.454-5.578 5.459a.882.882 0 0 1-.619.26.9.9 0 0 1-.495-.163z"
        transform="translate(953 5656)"
        style={{ fill: fillColor }}
      />
    </svg>
  );
};
