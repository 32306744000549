import React from 'react';

export const RedDeleteIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
    <path
      data-name="Union 915"
      d="M9.979 24H7.326a3.642 3.642 0 0 1-3.754-3.125c-.151-1.214-.19-2.437-.271-3.662q-.388-5.849-.763-11.7c-.011-.2-.083-.241-.274-.234a37.03 37.03 0 0 1-1.414 0A.886.886 0 0 1 0 4.476a.871.871 0 0 1 .7-.918 2.1 2.1 0 0 1 .429-.036q3.013-.011 6.028-.007c.181 0 .249-.04.238-.223-.018-.3-.007-.6-.007-.9A2.391 2.391 0 0 1 9.835.015c1.44-.021 2.884-.018 4.323 0a2.406 2.406 0 0 1 2.419 1.955 5.939 5.939 0 0 1 .036 1.094c.007.443 0 .443.457.443q2.971.005 5.942.014a.9.9 0 0 1 .988.89.9.9 0 0 1-.983.861 41.74 41.74 0 0 1-1.324 0c-.155 0-.223.032-.229.194-.029.566-.073 1.131-.111 1.7q-.211 3.116-.426 6.232c-.165 2.362-.35 4.72-.493 7.082A3.7 3.7 0 0 1 16.556 24q-2.3.005-4.586 0H9.979zM4.382 5.6q.2 2.873.386 5.742l.367 5.663c.079 1.2.144 2.391.241 3.583a1.883 1.883 0 0 0 2.015 1.653q4.617.005 9.229 0a3.075 3.075 0 0 0 .4-.032 1.832 1.832 0 0 0 1.609-1.652q.27-4.293.529-8.591c.133-2.135.263-4.27.406-6.4.018-.241-.061-.281-.291-.277-2.441.007-12.192.047-14.626.04h-.013c-.219 0-.266.071-.252.271zm10.157-2.089c.184 0 .245-.043.234-.223-.018-.281 0-.565 0-.85 0-.414-.212-.612-.665-.612q-1.236-.005-2.48 0H9.9c-.465 0-.662.2-.666.641 0 .281.011.565 0 .85-.007.155.047.195.209.195.853-.007 1.71 0 2.566 0h1.45q.541-.012 1.08-.001zm-3.458 14.9q-.006-4.66 0-9.314a.858.858 0 0 1 .169-.515.955.955 0 0 1 1.016-.349.892.892 0 0 1 .662.867v9.285a.906.906 0 0 1-.926.922.9.9 0 0 1-.921-.895z"
      style={{ fill: 'fill:#fc3333' }}
    />
  </svg>
);
