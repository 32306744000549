import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
  textField: {
    marginBottom: '3.2rem',
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderRadius: '8px',
        border: '1px solid #e6e6e6',
      },
    },
  },
}));
