import React, { useEffect, useState } from 'react';
import { useStyles } from './edit-risk-rate-modal.hooks';
import { graphqlApiDecorator } from 'decorators';
import { mutations, queries } from '../../../../../graphql';
import { Loader } from 'components/loader';
import {
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
  Table,
  Button,
  TextareaAutosize,
} from '@mui/material';

export const EditRiskRateModal = ({ onClose, userId }) => {
  const classes = useStyles();
  const [data, setData] = useState();
  const [loading, setLoading] = useState(true);
  const [comment, setComment] = useState();
  const [riskType, setRiskType] = useState();
  const [riskRate, setRiskRate] = useState();
  const [showRiskRates, setShowRiskRates] = useState([]);
  const [selectedRowIndex, setSelectedRowIndex] = useState(null);
  const [freezDates, setFreezDates] = useState([]);
  const [freezed, setFreezed] = useState('');

  const fetchRisk = async () => {
    const queryTitle = 'getLatestUserRisks';
    setLoading(true);
    try {
      const result = await graphqlApiDecorator(queries, queryTitle, { userId });
      if (result) {
        setData(result);
        setShowRiskRates(result.map(risk => handleShowRiskRate(risk.riskLevel)));
      }
      setLoading(false);
    } catch (error) {
      setError(error);
      setLoading(false);
    }
  };

  const setUserRisk = async () => {
    const queryTitle = 'setUserRisk';
    const input = {
      userId,
      updaterComment: comment,
      riskType: riskType,
      riskLevel: riskRate,
      freezeUntil: freezed || null,
    };
    setLoading(true);
    try {
      const result = await graphqlApiDecorator(mutations, queryTitle, { input });
      if (result) {
        onClose();
      }
      setLoading(false);
    } catch (error) {
      setError(error);
      setLoading(false);
    }
  };

  const handleRiskRate = riskRate => {
    switch (riskRate) {
      case 'Low':
        return 0;
      case 'Medium':
        return 1;
      case 'High':
        return 2;
      case 'Very High':
        return 3;
      default:
        return 'Unknown';
    }
  };

  const handleShowRiskRate = risk => {
    switch (risk) {
      case 0:
        return 'Low';
      case 1:
        return 'Medium';
      case 2:
        return 'High';
      default:
        return undefined;
    }
  };

  const handleChange = e => {
    setComment(e.target.value);
  };

  const handleChangeOption = (e, item, index) => {
    const newRiskRate = handleRiskRate(e.target.value);
    const updatedShowRiskRates = [...showRiskRates];
    updatedShowRiskRates[index] = e.target.value;
    setShowRiskRates(updatedShowRiskRates);
    setRiskType(item.type);
    setRiskRate(newRiskRate);
    if (newRiskRate === item.riskLevel) {
      setSelectedRowIndex(null);
    } else {
      setSelectedRowIndex(index);
    }
  };

  const handleDateChange = (index, newDate) => {
    const updatedDates = [...freezDates];
    updatedDates[index] = newDate;
    setFreezDates(updatedDates);
    if (newDate === '') {
      setFreezDates([]);
    }
    handleFreezedDate(newDate);
  };

  const handleFreezedDate = inputDate => {
    if (!inputDate) {
      setFreezed('');
      return;
    }
    const [day, month, year] = inputDate.split('/');
    const dateObject = new Date(`${year}-${month}-${day}`);

    const isoString = dateObject.toISOString();
    setFreezed(isoString);
  };

  const isSubmitDisabled = () => {
    if (selectedRowIndex === null && freezDates.length === 0) {
      return true;
    }
    return !comment;
  };

  useEffect(() => {
    fetchRisk();
  }, []);

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div className={classes.editRiskModalContainer}>
          <Table>
            <TableHead>
              <TableRow className={classes.tableRow}>
                <TableCell className={classes.headFirstTableCell}>Criteria</TableCell>
                <TableCell className={classes.bodySecondTableCell}>Value</TableCell>
                <TableCell className={classes.tableCell}>Risk rate</TableCell>
                <TableCell className={classes.tableCell}>Freezed Until</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data &&
                data !== undefined &&
                data.map((item, index) =>
                  item.type === 'OVERALL' ? null : (
                    <TableRow key={index} className={classes.tableCell}>
                      <TableCell className={classes.tableCell}>{item.type || '--'}</TableCell>
                      <TableCell className={classes.tableCell}>{item.itemValue || '--'}</TableCell>
                      <TableCell className={classes.tableCell}>
                        <select
                          name="riskRate"
                          style={{
                            color:
                              showRiskRates[index] === 'Low'
                                ? '#1ce2a9'
                                : showRiskRates[index] === 'Medium'
                                ? 'orange'
                                : showRiskRates[index] === 'High'
                                ? 'red'
                                : 'inherit',
                          }}
                          id={`riskRate-${index}`}
                          className={classes.optionContainer}
                          onChange={e => handleChangeOption(e, item, index)}
                          value={showRiskRates[index]}
                          disabled={
                            (selectedRowIndex !== null && selectedRowIndex !== index) ||
                            (freezDates.length !== 0 && !freezDates[index])
                          }
                        >
                          <option value="Low">Low</option>
                          <option value="Medium">Medium</option>
                          <option value="High">High</option>
                        </select>
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        <input
                          name="freezDate"
                          id={`freezDate-${index}`}
                          className={classes.inputContainer}
                          onChange={event => handleDateChange(index, event.target.value)}
                          type="date"
                          disabled={
                            (selectedRowIndex !== null && selectedRowIndex !== index) ||
                            (freezDates.length !== 0 && !freezDates[index])
                          }
                          value={freezDates[index]}
                        />
                      </TableCell>
                    </TableRow>
                  )
                )}
            </TableBody>

            <TableFooter>
              <TableRow>
                <TableCell className={classes.commentTableCell}>Comment</TableCell>
                <TableCell className={classes.commentTableCell} colSpan={3}>
                  <TextareaAutosize
                    className={classes.textArea}
                    name="comment"
                    id="comment"
                    rowsMin={2}
                    style={{ width: '100%' }}
                    onChange={handleChange}
                  />
                </TableCell>
              </TableRow>
            </TableFooter>
          </Table>
          <div className={classes.riskRateBtnContainer}>
            <Button
              className={classes.riskRateBtn}
              disabled={isSubmitDisabled()}
              type="button"
              onClick={setUserRisk}
            >
              Submit
            </Button>
          </div>
        </div>
      )}
    </>
  );
};
