import { useStyles } from './dashboard.hooks';
import { DashboardContent } from './components/Dashboard-content';

export const TableDashboard = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <DashboardContent />
      </div>
    </div>
  );
};
