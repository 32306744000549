import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ClearIcon from '@mui/icons-material/Clear';
import { IconButton, Modal, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';

import EditIcon from '../../../assets/icons/icons-edit.svg';
import DeleteIcon from '../../../assets/icons/icons-trash-full.svg';
import { permissionOptions } from '../../../constants';
import { setDefaultValues } from '../../../utils';
import { ModalButtons } from '../modal-buttons';
import { ModalDataForm } from '../modal-data-form';
import { ModalPermissions } from '../modal-permissions';
import { useStyles } from './edit-admin-modal.hooks';

export const EditAdminModal = ({
  onClose,
  onShow,
  onSave,
  styles,
  entity,
  data,
  onRefreshData,
  onDelete,
  index,
}) => {
  const classes = useStyles();

  const [permissions, setPermissions] = useState(setDefaultValues(permissionOptions));
  const [adminData, setAdminData] = useState({});
  const [active, setActive] = useState(false);

  const onChange = e => {
    const { value, name } = e.target;
    setTimeout(setAdminData({ ...adminData, [name]: value }), 2000);
  };

  const onCloseAdmin = () => {
    setActive(false);
    onClose();
  };

  const onSaveAdmin = async (_entity, _values, id) => {
    await onSave({
      entity,
      values: {
        name: _values.name,
        email: _values.email,
        permissions: JSON.stringify(_values.permissions),
        id,
      },
      id,
      active: true,
    });
    onRefreshData();
    onCloseAdmin();
  };

  const onDeleteAdmin = (id, i) => {
    onDelete(id, i);
    onClose();
  };

  useEffect(() => {
    setAdminData({ ...adminData, permissions });
  }, [permissions]);

  return (
    <Modal
      aria-describedby="simple-modal-description"
      aria-labelledby="simple-modal-title"
      className={classes.modal}
      onClose={onCloseAdmin}
      open={onShow}
    >
      <div className={classes.modalPaper}>
        <div className={classes.header}>
          <Typography variant="h3" style={{ marginRight: '1em' }}>
            {data.name}
          </Typography>
          <IconButton onClick={() => setActive(true)} size="large">
            <img alt="edit" src={EditIcon} />
          </IconButton>
          <IconButton onClick={() => onDeleteAdmin(data.id, index)} size="large">
            <img alt="delete" src={DeleteIcon} />
          </IconButton>
          <div className={classes.x} onClick={onClose}>
            <ClearIcon />
          </div>
        </div>
        <ModalDataForm onChange={onChange} styles={styles} data={data} active={active} />
        <ModalPermissions
          styles={styles}
          permissions={permissions}
          setPermissions={setPermissions}
          permissionOptions={permissionOptions}
        />
        <ModalButtons
          buttonText1="Cancel"
          buttonText2="Save"
          icon={<CheckCircleIcon />}
          onClick1={onClose}
          onClick2={() => onSaveAdmin(entity, adminData, data.id)}
        />
      </div>
    </Modal>
  );
};
