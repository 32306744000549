import React from 'react';

export const GBP = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="47.998"
    height="48.001"
    viewBox="0 0 47.998 48.001"
  >
    <defs>
      <clipPath id="hk89i6co7a">
        <path
          data-name="Path 19896"
          d="M16440 10080a24 24 0 1 0-24-24 24 24 0 0 0 24 24z"
          transform="translate(-16064.002 -8923)"
          style={{ fill: '#f0f0f0' }}
        />
      </clipPath>
    </defs>
    <g
      data-name="Mask Group 66"
      transform="translate(-352 -1109)"
      style={{ clipPath: 'url(#hk89i6co7a)' }}
    >
      <path
        data-name="Path 19883"
        d="M16428.646 10132.1a22.254 22.254 0 0 0-3.846 7.77h11.615z"
        transform="translate(-16072.034 -9013.575)"
        style={{ fill: '#0052b4' }}
      />
      <path
        data-name="Path 19884"
        d="M16797.611 10139.868a22.275 22.275 0 0 0-3.84-7.77l-7.771 7.77z"
        transform="translate(-16398.379 -9013.575)"
        style={{ fill: '#0052b4' }}
      />
      <path
        data-name="Path 19885"
        d="M16424.8 10354.8a22.216 22.216 0 0 0 3.846 7.769l7.77-7.769z"
        transform="translate(-16072.034 -9215.089)"
        style={{ fill: '#0052b4' }}
      />
      <path
        data-name="Path 19886"
        d="M16746.572 10044.644a22.337 22.337 0 0 0-7.771-3.845v11.614z"
        transform="translate(-16356.092 -8931.032)"
        style={{ fill: '#0052b4' }}
      />
      <path
        data-name="Path 19887"
        d="M16516.1 10409.771a22.29 22.29 0 0 0 7.77 3.843V10402z"
        transform="translate(-16154.576 -9257.38)"
        style={{ fill: '#0052b4' }}
      />
      <path
        data-name="Path 19888"
        d="M16523.869 10040.8a22.328 22.328 0 0 0-7.77 3.845l7.77 7.77z"
        transform="translate(-16154.576 -8931.032)"
        style={{ fill: '#0052b4' }}
      />
      <path
        data-name="Path 19889"
        d="M16738.8 10413.613a22.3 22.3 0 0 0 7.771-3.843l-7.771-7.771z"
        transform="translate(-16356.092 -9257.38)"
        style={{ fill: '#0052b4' }}
      />
      <path
        data-name="Path 19890"
        d="m16786 10354.8 7.771 7.769a22.238 22.238 0 0 0 3.84-7.769z"
        transform="translate(-16398.379 -9215.089)"
        style={{ fill: '#0052b4' }}
      />
      <path
        data-name="Path 19891"
        d="M16463.795 10052.87h-20.664v-20.664a23.889 23.889 0 0 0-6.262 0v20.664h-20.664a24.23 24.23 0 0 0 0 6.262h20.664v20.664a24 24 0 0 0 6.262 0v-20.664h20.664a24 24 0 0 0 0-6.262z"
        transform="translate(-16064.002 -8923)"
        style={{ fill: '#d80027' }}
      />
      <path
        data-name="Path 19892"
        d="m16738.8 10354.8 9.959 9.958c.463-.455.9-.933 1.316-1.431l-8.527-8.527z"
        transform="translate(-16355.296 -9214.294)"
        style={{ fill: '#d80027' }}
      />
      <path
        data-name="Path 19893"
        d="m16500.957 10354.8-9.957 9.958c.455.463.934.9 1.43 1.317l8.527-8.527z"
        transform="translate(-16132.46 -9214.294)"
        style={{ fill: '#d80027' }}
      />
      <path
        data-name="Path 19894"
        d="m16487.172 10116.957-9.957-9.957c-.459.454-.9.934-1.316 1.429l8.529 8.528z"
        transform="translate(-16118.675 -8991.46)"
        style={{ fill: '#d80027' }}
      />
      <path
        data-name="Path 19895"
        d="m16738.8 10103.175 9.959-9.958c-.453-.462-.934-.9-1.43-1.316l-8.529 8.528z"
        transform="translate(-16355.296 -8977.677)"
        style={{ fill: '#d80027' }}
      />
    </g>
  </svg>
);
