import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(theme => ({
  root: {
    height: '100vh',
  },
  item: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0,
    justifyContent: 'center',
  },
  button: {
    position: 'relative',
    borderRadius: '0.8rem',
    color: theme.palette.white,
    padding: '9px',
    textTransform: 'none',
    letterSpacing: 0,
    marginBottom: '16px',
    minWidth: '50px',
    height: '50px',
    fontWeight: theme.typography.fontWeightMedium,
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.nav,
      '& $icon': {
        fill: theme.palette.primary.nav,
      },
      transition: '1000ms',
    },
    '&.active': {
      backgroundColor: theme.palette.primary.main,
      border: `0.2rem solid ${theme.palette.primary.nav}`,
      borderLeft: 'none',
      color: theme.palette.primary.nav,
      fontWeight: theme.typography.fontWeightMedium,
      '& $icon': {
        fill: theme.palette.primary.nav,
      },
    },
  },
  icon: {
    color: theme.palette.white,
    width: '2.4rem',
    height: '2.4rem',
    display: 'flex',
    alignItems: 'center',
    marginRight: '0.8rem',
    '& .MuiSvgIcon-root': {
      width: '2.4rem',
      height: '2.4rem',
    },
  },
  containerListItem: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
  },
  popup: {
    position: 'absolute',
    zIndex: 3,
    top: '15px',
    left: '97px',
    padding: '5px',
    borderRadius: '0.8rem',
    color: theme.palette.white,
    backgroundColor: theme.palette.primary.nav,
    fontFamily: theme.typography.default,
    fontSize: theme.typography.h6.fontSize,
    '&:after': {
      content: '" "',
      position: 'absolute',
      top: '10px',
      left: '-0.3rem',
      width: '0.3rem',
      height: '0.3rem',
      transform: 'rotate(45deg)',
      backgroundColor: theme.palette.primary.nav,
      borderTop: `0.2rem solid ${theme.palette.primary.nav}`,
      borderRight: `0.2rem solid ${theme.palette.primary.nav}`,
      zIndex: -1,
    },
  },
}));
