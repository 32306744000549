import React from 'react';

export const TemplateDeleteIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26">
    <defs>
      <clipPath id="mf82lykpaa">
        <path data-name="Rectangle 18228" style={{ fill: 'none' }} d="M0 0h10.958v10.959H0z" />
      </clipPath>
    </defs>
    <g data-name="Group 8852">
      <g
        data-name="Ellipse 1438"
        transform="rotate(90 12 13)"
        style={{ fill: '#fff', stroke: '#0069f8' }}
      >
        <circle cx="12" cy="12" r="12" style={{ stroke: 'none' }} />
        <circle cx="12" cy="12" r="12.5" style={{ fill: 'none' }} />
      </g>
      <g data-name="Group 8850">
        <g
          data-name="Group 8849"
          style={{ clipPath: 'url(#mf82lykpaa)' }}
          transform="translate(7.521 7.521)"
        >
          <path
            data-name="Path 20128"
            d="m6.621 5.487 4.074-4.074.048-.048a.819.819 0 0 0 .215-.586.815.815 0 0 0-1.406-.524L5.471 4.337 1.382.248a.823.823 0 0 0-1.1 0 .82.82 0 0 0-.041 1.16l4.078 4.081L.237 9.57a.814.814 0 0 0 1.151 1.151l4.081-4.082 4.084 4.085a.817.817 0 0 0 1.15 0 .822.822 0 0 0 0-1.15z"
            style={{ fill: '#19365e' }}
          />
        </g>
      </g>
    </g>
  </svg>
);
