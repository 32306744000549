import React from 'react';

export const WalletIcon = ({ width = '24', height = '24' }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 24 24">
    <path
      data-name="Union 955"
      d="M10910-1194a12.014 12.014 0 0 1 12-12 12.014 12.014 0 0 1 12 12 12.014 12.014 0 0 1-12 12 12.014 12.014 0 0 1-12-12zm1.46 0a10.551 10.551 0 0 0 10.54 10.54 10.552 10.552 0 0 0 10.54-10.54 10.551 10.551 0 0 0-10.54-10.537 10.551 10.551 0 0 0-10.54 10.537zm6.962 6.5a1.735 1.735 0 0 1-1.595-1.688v-.019l.006-6.619a1.636 1.636 0 0 1 .362-1.141 1.616 1.616 0 0 1 1.072-.587.654.654 0 0 1 .074-.005h8.642c.024 0 .049 0 .076.005a1.628 1.628 0 0 1 1.438 1.729v1.046a.633.633 0 0 1-.634.631h-2.723a1.313 1.313 0 0 0-.905.53 1.78 1.78 0 0 0-.335 1.386 1.9 1.9 0 0 0 .475.938 1.086 1.086 0 0 0 .744.379h2.738a.636.636 0 0 1 .634.634v1.054a1.639 1.639 0 0 1-1.419 1.723.732.732 0 0 1-.082.005h-8.518a.347.347 0 0 1-.05-.001zm-.327-8.405v6.686a.467.467 0 0 0 .409.453h8.436a.37.37 0 0 0 .291-.384.317.317 0 0 1-.006-.055v-.447h-2.135a2.345 2.345 0 0 1-1.63-.768 3.142 3.142 0 0 1-.8-1.583 3.061 3.061 0 0 1 .576-2.385 2.537 2.537 0 0 1 1.828-1.021.3.3 0 0 1 .052 0h2.116v-.491a.359.359 0 0 0-.293-.387h-8.554a.353.353 0 0 0-.29.382zm7.456 4.747a.457.457 0 0 1-.088-.005 1.327 1.327 0 0 1-1.24-1.383 1.347 1.347 0 0 1 1.251-1.232.731.731 0 0 1 .077-.005h2.321a.6.6 0 0 1 .617.568v1.493a.594.594 0 0 1-.617.565zm-.094-1.361a.716.716 0 0 1 0 .074c-.006.066.058.132.15.154h1.655v-.359h-1.658c-.086.016-.149.073-.147.131zm-10.457.414v-7.283a1.56 1.56 0 0 1 1.448-1.611h8.909a.135.135 0 0 1 .033 0 1.556 1.556 0 0 1 1.457 1.611v.8a.635.635 0 0 1-.631.634.636.636 0 0 1-.634-.634v-.81a.106.106 0 0 1 0-.03.292.292 0 0 0-.253-.3h-8.813a.286.286 0 0 0-.253.3V-1192.092a.636.636 0 0 1-.634.634.636.636 0 0 1-.629-.642z"
      transform="translate(-10910 1206.001)"
      style={{ fill: '#1fe3ac' }}
    />
  </svg>
);
