import React from 'react';

export const Metrics = ({ width = '26', height = '26', color = '#1fe3ac' }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 48 48">
    <g data-name="Group 10322">
      <path
        data-name="Union 972"
        d="M1321-1310h28a10 10 0 0 1 10 10v28a10 10 0 0 1-10 10h-28a10 10 0 0 1-10-10v-28a10 10 0 0 1 10-10z"
        transform="translate(-1311 1310)"
        fill="none"
      />
      <g data-name="Group 10290">
        <path
          data-name="Union 974"
          d="M21.941 27.251A4.942 4.942 0 1 1 26.879 32a4.844 4.844 0 0 1-4.938-4.749zm1.865 0a3.075 3.075 0 1 0 3.073-2.951 3.019 3.019 0 0 0-3.072 2.951zM1.385 30.62a1.328 1.328 0 0 1-.307-1.629l9.722-18.96a.944.944 0 0 1 .631-.543 1.275 1.275 0 0 1 .176-.018.9.9 0 0 1 .619.269l8.836 8.632L30.193.555A.912.912 0 0 1 31.568.18a1.34 1.34 0 0 1 .27 1.629l-9.69 18.956a1.007 1.007 0 0 1-.658.543.913.913 0 0 1-.791-.247l-8.857-8.641-9.106 17.82a.964.964 0 0 1-.812.565.847.847 0 0 1-.539-.185zM0 4.865A4.9 4.9 0 0 1 4.938 0a4.9 4.9 0 0 1 4.941 4.865 4.9 4.9 0 0 1-4.941 4.857A4.9 4.9 0 0 1 0 4.865zm1.865 0a3.073 3.073 0 0 0 6.145 0 3.073 3.073 0 0 0-6.145 0z"
          transform="translate(7.982 7.992)"
          style={{ fill: 'white' }}
        />
        <path
          data-name="Rectangle 19218"
          transform="translate(8 8)"
          d="M0 0h31.98v31.992H0z"
          fill="none"
        />
      </g>
    </g>
  </svg>
);
