import { Checkbox, TableCell, TableRow } from '@mui/material';
import React from 'react';

import { entitiesEnum } from '../../../../constants';
import { CommonTableCell } from './components';
import { useStyles } from './generic-table-head.hooks';

export const GenericTableHead = ({
  entity,
  tableFields,
  filters,
  setFilters,
  isEntityCanBeEdited,
  isEntityCanBeRemoved,
}) => {
  const classes = useStyles();
  const isTeamEntity = entity === entitiesEnum.TEAM;
  const isUserprofileEntity = entity === entitiesEnum.USERPROFILE;
  const isTransaction = entity === entitiesEnum.TRANSACTION;
  const isCountries = entity === entitiesEnum.COUNTRY;

  const tableColumns = [...tableFields];

  return (
    <TableRow className={classes.tableHeadRow}>
      {isUserprofileEntity && <TableCell className={classes.tableHeadCell} />}

      {isTeamEntity && (
        <TableCell className={classes.tableHeadCell}>
          <Checkbox className={classes.checkbox} />
        </TableCell>
      )}

      {isTransaction && <TableCell className={classes.tableHeadCell}>#</TableCell>}

      {tableColumns.map(field => (
        <CommonTableCell
          entity={entity}
          field={field}
          filters={filters}
          setFilters={setFilters}
          key={field.label}
        />
      ))}

      {isUserprofileEntity && (
        <TableCell className={classes.tableHeadCell} style={{ textAlign: 'center' }}>
          Info
        </TableCell>
      )}

      {!isCountries && (isEntityCanBeEdited || isEntityCanBeRemoved) && (
        <TableCell className={classes.tableHeadCell} style={{ textAlign: 'center' }}>
          Options
        </TableCell>
      )}
    </TableRow>
  );
};
