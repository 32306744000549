import React from 'react';

export const MXN = ({ width = '45', height = '45' }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 48.001 47.998">
    <g data-name="Group 8511">
      <path
        data-name="Path 10053"
        d="M16440 960a24 24 0 1 0-24-24 24 24 0 0 0 24 24z"
        transform="translate(-16416 -912)"
        style={{ fill: '#f0f0f0' }}
      />
      <path
        data-name="Path 10054"
        d="M16795.914 958.365a23.377 23.377 0 0 0-12.614-20.965v41.926a23.376 23.376 0 0 0 12.614-20.961z"
        transform="translate(-16747.913 -934.486)"
        style={{ fill: '#d80027' }}
      />
      <path
        data-name="Path 10055"
        d="M16416 958.365a23.374 23.374 0 0 0 12.619 20.963V937.4a23.375 23.375 0 0 0-12.619 20.965z"
        transform="translate(-16416 -934.486)"
        style={{ fill: '#6da544' }}
      />
      <path
        data-name="Path 10056"
        d="M16605.2 1147.681a5.823 5.823 0 0 0 11.646 0v-1.941h-11.646z"
        transform="translate(-16587.024 -1123.722)"
        style={{ fill: '#6da544' }}
      />
      <path
        data-name="Path 10057"
        d="M16598.52 1103.161h-5.816a1.944 1.944 0 0 0-3.889 0H16583a2 2 0 0 0 2.006 1.941h-.07a1341.351 1341.351 0 0 1 3.879 3.882h3.889a1077.2 1077.2 0 0 1 3.881-3.882h-.07a2 2 0 0 0 2.005-1.941z"
        transform="translate(-16566.759 -1083.242)"
        style={{ fill: '#ff9811' }}
      />
    </g>
  </svg>
);
