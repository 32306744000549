import { Button } from '@mui/material';
import React, { useEffect, useState } from 'react';
import OtpInput from 'react18-input-otp';

import { SavedModal, WarningModal } from '../../../../components/modal/saved-warning-modal';
import { confirmCodeWithPw, sendConfirmationCodeToUser } from '../../../../services';
import { formatEmailPrivate, passwordValidator } from '../../../../utils';
import { LoginTextField } from '../textField/loginTextField';
import { useStyles } from './enterFourDigitSection.hooks';
import { PasswordValidation } from './password-validation';

export const EnterFourDigitSection = ({ email }) => {
  const [otp, setOtp] = useState('');
  const [disableBtn, setDisableBtn] = useState(true);
  const [newPw, setNewPw] = useState('');
  const [confirmPw, setConfirmPw] = useState('');
  const [showNewPw, setShowNewPw] = useState(false);
  const [showConfirmPw, setShowConfirmPw] = useState(false);
  const [openWarningModal, setOpenWarningModal] = useState(false);
  const [openSavedModal, setOpenSavedModal] = useState(false);
  const [message, setMessage] = useState('');
  const [savedMessage, setSavedMessage] = useState('');
  const [title, setTitle] = useState('');
  const [savedTitle, setSavedTitle] = useState('');
  const classes = useStyles();

  const PASSWORD_RESET_SUCCESS = 'Password Reset Successful';
  const RESEND_CODE = 'Resend Code';

  const inputOnChange = enteredOtp => {
    setOtp(enteredOtp);
  };

  const handleShowNewPw = () => {
    setShowNewPw(!showNewPw);
  };

  const handleShowConfirmPw = () => {
    setShowConfirmPw(!showConfirmPw);
  };
  const closeModal = () => {
    setOpenWarningModal(false);
  };

  const disableContinueBtn = () => {
    if (otp.length === 6 && newPw && confirmPw && newPw === confirmPw && passwordValidator(newPw)) {
      setDisableBtn(false);

      return;
    }

    setDisableBtn(true);
  };

  useEffect(() => {
    disableContinueBtn();
  }, [otp, newPw, confirmPw]);

  const handleSubmit = async event => {
    event.preventDefault();
    if (otp.length < 6 && !passwordValidator(newPw)) return;

    try {
      await confirmCodeWithPw(email, otp, newPw);
      setSavedTitle(PASSWORD_RESET_SUCCESS);
      setSavedMessage('You will now be taken to login page.');
      setOpenSavedModal(true);
    } catch (error) {
      setTitle('Error');
      setMessage(error.message);
      setOpenWarningModal(true);
    }
  };

  const resendCode = async () => {
    try {
      await sendConfirmationCodeToUser(email);
      setSavedTitle(RESEND_CODE);
      setSavedMessage(`Verification code sent to ${formatEmailPrivate(email)}`);
      setOpenSavedModal(true);
    } catch (error) {
      setTitle('Error');
      setMessage(error.message);
      setOpenWarningModal(true);
    }
  };

  const handleCloseSavedModal = () => {
    if (savedTitle === RESEND_CODE) setOpenSavedModal(false);
    if (savedTitle === PASSWORD_RESET_SUCCESS) {
      setOpenSavedModal(false);
      window.location.reload();
    }
  };

  return (
    <section className={classes.root}>
      <div className={classes.titleRow}>
        <aside>
          <p style={{ fontWeight: '500', marginBottom: '8px' }}>Enter 6-digit code in email:</p>
          <p
            style={{ color: '#a5a6ae', marginBottom: '16px' }}
          >{`We've sent it to: ${formatEmailPrivate(email)}`}</p>
        </aside>
        <aside>
          <p
            style={{
              color: '#a5a6ae',
              marginBottom: '16px',
              textAlign: 'center',
              fontSize: '16px',
            }}
          >
            Didn&apos;t receive it?
          </p>
          <Button type="button" className={classes.resendCodeBtn} onClick={resendCode}>
            Resend Code
          </Button>
        </aside>
      </div>
      <form onSubmit={handleSubmit}>
        <p style={{ marginBottom: '8px', fontWeight: '500' }}>6-Digit code</p>
        <div className={classes.otpContainer}>
          <OtpInput
            value={otp}
            onChange={inputOnChange}
            numInputs={6}
            isInputNum
            inputStyle={classes.inputStyle}
          />
        </div>
        <aside>
          <p style={{ fontWeight: '500', marginBottom: '13px', marginTop: '30px' }}>
            Enter your new password
          </p>
          <p style={{ fontSize: '16px', color: '#a5a6ae', marginBottom: '22px' }}>
            Password should apply below conditions.
          </p>
        </aside>
        <p style={{ marginBottom: '11px' }}>New Password</p>
        <LoginTextField
          showPw={showNewPw}
          value={newPw}
          onChange={e => setNewPw(e.target.value)}
          handleShowPw={handleShowNewPw}
        />
        <p style={{ marginBottom: '11px' }}>Confirm Password</p>
        <LoginTextField
          showPw={showConfirmPw}
          value={confirmPw}
          onChange={e => setConfirmPw(e.target.value)}
          handleShowPw={handleShowConfirmPw}
        />
        <PasswordValidation password={newPw} />
        <Button
          type="submit"
          color="primary"
          fullWidth
          size="large"
          variant="contained"
          className={classes.resetPwBtn}
          disabled={disableBtn}
          sx={{
            '&.Mui-disabled': {
              backgroundColor: '#d9e1e7',
              color: 'white',
            },
          }}
        >
          <p>Reset Password</p>
        </Button>
      </form>
      <WarningModal open={openWarningModal} confirm={closeModal} message={message} title={title} />
      <SavedModal
        open={openSavedModal}
        onClose={handleCloseSavedModal}
        header={savedTitle}
        content={savedMessage}
      />
    </section>
  );
};
