import React from 'react';

export const CustomersIcon = ({ className, color }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 30 30">
    {/* prettier-ignore */}
    <g data-name="Group 7286">
    <path data-name="Union 95" 
        d="M3400 15a15 15 0 1 1 15 15 15 15 0 0 1-15-15zm1.632 0A13.369 13.369 0 1 0 3415 1.632 13.382 13.382 0 0 0 3401.632 15zm13.179 7.917a7.315 7.315 0 0 1-7.095-7.031v-.033a.877.877 0 0 1 .287-.61.853.853 0 0 1 .579-.226h.054a.865.865 0 0 1 .831.826 5.5 5.5 0 0 0 1.764 3.855 5.538 5.538 0 0 0 9.308-3.858.861.861 0 0 1 .269-.6.879.879 0 0 1 .614-.237h.032a.868.868 0 0 1 .84.792 7.318 7.318 0 0 1-7.35 7.124z" 
        transform="translate(-3400)" 
        fill={color}
        className={className}/>
    <path data-name="Rectangle 17109" 
        style={{ fill: 'none' }} 
        d="M0 0h30v30H0z"/>
    </g>
  </svg>
);
