import React from 'react';

export const AddMoneyIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16.522"
    height="16.522"
    viewBox="0 0 16.522 16.522"
  >
    <g data-name="Group 8874">
      <g data-name="Group 8301">
        <path
          data-name="Path 9336"
          d="M0 0h15.022"
          transform="rotate(90 3.755 4.505)"
          style={{ fill: 'none', stroke: '#23c3a4', strokeLinecap: 'round', strokeWidth: '1.5px' }}
        />
      </g>
      <path
        data-name="Path 20153"
        d="M0 0h15.022"
        transform="translate(.75 8.261)"
        style={{ fill: 'none', stroke: '#23c3a4', strokeLinecap: 'round', strokeWidth: '1.5px' }}
      />
    </g>
  </svg>
);
