import React from 'react';

export const SGD = ({ width = '45', height = '45' }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 48 48">
    <g data-name="Group 8519">
      <path
        data-name="Path 10380"
        d="M16440.006 6432a24 24 0 1 0-24-24 24 24 0 0 0 24 24z"
        transform="translate(-16416.004 -6383.999)"
        style={{ fill: '#f0f0f0' }}
      />
      <path
        data-name="Path 10381"
        d="M16416 6406.07c0-12.189 10.742-22.071 24-22.071s23.379 11.15 24 22.071"
        transform="translate(-16416.004 -6383.999)"
        style={{ fill: '#d80027' }}
      />
      <path
        data-name="Path 10382"
        d="M16541.256 6446.312a6.666 6.666 0 0 1 5.232-6.507 6.662 6.662 0 1 0 0 13.015 6.666 6.666 0 0 1-5.232-6.508z"
        transform="translate(-16526.695 -6434.892)"
        style={{ fill: '#f0f0f0' }}
      />
      <path
        data-name="Path 10383"
        d="m16650.6 6445.221.473 1.454h1.527l-1.238.9.48 1.455-1.242-.9-1.238.9.48-1.455-1.242-.9h1.531z"
        transform="translate(-16626.598 -6439.986)"
        style={{ fill: '#f0f0f0' }}
      />
      <path
        data-name="Path 10384"
        d="m16607.2 6478.61.479 1.454h1.521l-1.232.9.471 1.455-1.238-.9-1.234.9.473-1.455-1.24-.9h1.531z"
        transform="translate(-16587.303 -6469.665)"
        style={{ fill: '#f0f0f0' }}
      />
      <path
        data-name="Path 10385"
        d="m16694 6478.61.469 1.454h1.531l-1.238.9.471 1.455-1.23-.9-1.242.9.473-1.455-1.232-.9h1.531z"
        transform="translate(-16665.895 -6469.665)"
        style={{ fill: '#f0f0f0' }}
      />
      <path
        data-name="Path 10386"
        d="m16677.3 6528.7.471 1.454h1.527l-1.236.9.469 1.455-1.23-.9-1.242.9.471-1.455-1.23-.9h1.531z"
        transform="translate(-16650.773 -6515.02)"
        style={{ fill: '#f0f0f0' }}
      />
      <path
        data-name="Path 10387"
        d="m16623.9 6528.7.471 1.454h1.527l-1.229.9.469 1.455-1.238-.9-1.23.9.469-1.455-1.24-.9h1.531z"
        transform="translate(-16602.426 -6515.02)"
        style={{ fill: '#f0f0f0' }}
      />
    </g>
  </svg>
);
