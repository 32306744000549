import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(theme => ({
  subEntityForm: {
    width: '100%',
  },
  contentBlock: {
    position: 'relative',
    padding: '2rem',
    borderBottom: '0.1rem solid white',
  },
  contentBlockTitle: {
    fontSize: '2rem',
    fontWeight: 700,
  },
  contentBlockButtons: {
    position: 'absolute',
    top: 0,
    right: 0,
    display: 'flex',
  },
  errorMessage: {
    color: 'red',
    marginRight: '2rem',
  },
  imgUploaded: {
    maxWidth: '20rem',
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalPaper: {
    backgroundColor: theme.palette.white,
    boxShadow: theme.shadows[5],
    padding: '4.2rem',
    outline: 'none',
    maxHeight: '90vh',
  },
  button: {
    fontSize: '1.6rem',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      opacity: '0.85',
    },
  },
}));
