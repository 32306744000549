import React from 'react';

export const NotificationTemp = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
    <g data-name="Group 10047" transform="translate(-1280 -992)">
      <rect
        data-name="Rectangle 17188"
        width="32"
        height="32"
        rx="16"
        transform="translate(1280 992)"
        style={{ fill: '#1fe3ac' }}
      />
      <g data-name="Group 10046">
        <path
          data-name="Icon zocial-email"
          d="M.609 14.892.593 4.076h0s2.94 2.571 6.023 5.145c.1.086-.467-.382 0 0L.609 14.892c-.042-.144 0 .062 0 0zm0-10.816h14.816c.094 0-.094.016-5.319 4.543l-.7.556L8.009 10.3S.5 4.076.609 4.076zm0 10.816 6.008-5.67L8.009 10.3 9.4 9.175l6.008 5.671c-.083.031.016.046.016.046-14.367 0-14.732.031-14.816 0zM9.4 9.175l6.023-5.1c.031.093 0-.1 0 0 .173 10.587.161 10.393.1 10.816z"
          transform="translate(1287.939 998.34)"
          style={{
            fill: 'none',
            stroke: '#19365e',
            strokeLinecap: 'round',
            strokeLinejoin: 'round',
          }}
        />
        <circle
          data-name="Ellipse 1833"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1295 1004)"
          style={{ fill: '#19365e' }}
        />
      </g>
    </g>
  </svg>
);
