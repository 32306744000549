import React from 'react';

export const QAR = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48">
    <g data-name="Group 8516">
      <path
        data-name="Path 10275"
        d="M16449 4608a24 24 0 1 0-24-24 24 24 0 0 0 24 24z"
        transform="translate(-16425 -4560)"
        style={{ fill: '#f0f0f0' }}
      />
      <path
        data-name="Path 10276"
        d="M16564.465 4584a24.206 24.206 0 0 0-24.41-24 25.162 25.162 0 0 0-13.055 4.083l5.42 1.832-7.217 3.015 7.217 3.014-7.217 3.014 7.217 3.015-7.217 3.014 7.217 3.015-7.217 3.014 7.217 3.014-7.217 3.014 7.217 3.014-7.217 3.015 7.217 3.014-5.213 1.954a24.038 24.038 0 0 0 37.258-20.042z"
        transform="translate(-16516.467 -4560)"
        style={{ fill: '#751a46' }}
      />
    </g>
  </svg>
);
