import { Button, Grid, Typography } from '@mui/material';
import React, { useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { uid } from 'react-uid';

import { getSubObjectValidations } from '../../utils';
import { FormField } from '../form-field-generic';
import { HSpacer } from '../h-spacer';
import { useStyles } from './sub-object-form.hooks';

export const SubObjectForm = ({
  onCancel,
  onSave,
  data,
  id,
  allData,
  dataIndex,
  fields,
  entity,
  subObject,
}) => {
  const classes = useStyles();
  const formRef = useRef();
  const [errorMessage, setErrorMessage] = useState('');

  const {
    handleSubmit,
    formState: { errors },
    setValue: nestedSetValue,
    control,
    register: nestedRegister,
    getValues,
    watch: nestedWatch,
  } = useForm({ defaultValues: data });

  const handleFormSubmit = (values, event) => {
    if (formRef.current !== event.target) {
      return false;
    }
    setErrorMessage('');

    const validation = getSubObjectValidations(entity, subObject.name);

    if (validation && !validation.validate(allData, values, dataIndex)) {
      setErrorMessage(validation.errorMessage);

      return false;
    }

    onSave(values, dataIndex);

    return true;
  };

  return (
    <>
      <Typography variant="h3">Edit item</Typography>
      <HSpacer />
      <form
        ref={formRef}
        className={classes.subEntityForm}
        onSubmit={handleSubmit(handleFormSubmit)}
      >
        <Grid className={classes.scrollContainer} container item xs={12} spacing={4}>
          {fields.map(subEntityField => {
            const fieldProps = {
              entity,
              field: subEntityField,
              control,
              getValues,
              register: nestedRegister,
              setValue: nestedSetValue,
              subObject: subObject.name,
              errors: errors[subObject.name],
              rules: { required: subObject.required },
              watch: nestedWatch,
            };

            return <FormField key={uid(subEntityField)} id={id} {...fieldProps} />;
          })}
        </Grid>
        <HSpacer />
        <Grid container item xs={12} justifyContent="flex-end" alignItems="center">
          <Typography align="right" className={classes.errorMessage}>
            {errorMessage}
          </Typography>
        </Grid>
        <HSpacer />
        <Grid container item justifyContent="flex-end" xs={12} spacing={2}>
          <Grid item xs={6}>
            <Button
              color="secondary"
              fullWidth
              onClick={onCancel}
              size="large"
              type="button"
              variant="contained"
            >
              Cancel
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              color="primary"
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              className={classes.button}
            >
              Confirm Edit
            </Button>
          </Grid>
        </Grid>
      </form>
    </>
  );
};
