import { entitiesEnum } from '../constants';
import { graphqlApiDecorator } from '../decorators';
import { mutations, queries } from '../graphql';
import { isDependentFieldHidden } from '../utils';

const response = ({ success, msg = null, data = null, paginationToken = null }) => ({
  success,
  message: !success && !msg ? 'UnexpectedError' : msg,
  data,
  paginationToken,
});

const parseTimestampString = timestampString => {
  const [date, hours] = timestampString.split('T');

  return `${date}T${hours.slice(0, 5)}`;
};

const parseTimestampField = timestamp => {
  if (typeof timestamp === 'string') {
    return parseTimestampString(timestamp);
  }

  if (typeof timestamp === 'number') {
    const dateTimestamp = new Date(timestamp);

    return parseTimestampString(dateTimestamp.toISOString());
  }

  return timestamp;
};

const listEntity = async ({
  entity,
  getAll = false,
  paginationToken,
  limit = 100,
  filter,
  key,
  value,
}) => {
  let entityListQuery = '';

  if (entity === entitiesEnum.TRANSACTION) {
    entityListQuery = 'searchTransactions';
  } else {
    entityListQuery = `list${entity}s`;
  }

  const graphqlOperationOptions = {
    limit,
    ...(paginationToken ? { nextToken: paginationToken } : null),
    ...(filter ? { filter } : null),
    ...(key ? { key } : null),
    ...(value ? { value } : null),
  };
  const data = await graphqlApiDecorator(queries, entityListQuery, graphqlOperationOptions);

  if (!data) {
    return response({ success: false });
  }

  let { items: totalItems, nextToken } = data;

  while (getAll && nextToken) {
    const graphqlOperationOptions = {
      nextToken,
      ...(getAll ? null : { limit }),
      ...(filter ? { filter } : null),
      ...(key ? { key } : null),
      ...(value ? { value } : null),
    };
    const { items, nextToken: token } = await graphqlApiDecorator(
      queries,
      entityListQuery,
      graphqlOperationOptions
    );
    totalItems = [...totalItems, ...items];
    nextToken = token;
  }
  return response({ success: true, data: totalItems, paginationToken: nextToken });
};

const getEntity = async (entity, id) => {
  const entityGetQuery = `get${entity}`;
  const entityData = await graphqlApiDecorator(queries, entityGetQuery, { id });

  if (!entityData) {
    response({ success: false });
  }

  return response({
    success: true,
    data: {
      ...entityData,
      ...(typeof entityData.createdAt === 'string' && entityData.createdAt
        ? {
            createdAt: parseTimestampField(
              entityData.createdAt < 1000000000000
                ? entityData.createdAt * 1000
                : entityData.createdAt
            ),
          }
        : null),
      ...(typeof entityData.createdAt === 'string' && entityData.updatedAt
        ? {
            updatedAt: parseTimestampField(
              entityData.updatedAt < 1000000000000
                ? entityData.updatedAt * 1000
                : entityData.updatedAt
            ),
          }
        : null),
    },
  });
};

const saveEntity = async ({ entity, values, id, hasOrder, forceCreate = false }) => {
  Object.keys(values).forEach(k => {
    if (
      k === 'createdAt' ||
      k === 'updatedAt' ||
      values[k] === undefined ||
      isDependentFieldHidden(values, k, entity)
    ) {
      delete values[k];
    }
  });

  if (hasOrder && (values.order === null || values.order === undefined)) {
    const { success, data } = await listEntity({ entity });
    if (success) values.order = data.length + 1;
  }

  const mutationName = !forceCreate && id && id !== '0' ? `update${entity}` : `create${entity}`;

  const data = await graphqlApiDecorator(mutations, mutationName, { input: values });

  if (!data) {
    return response({ success: false });
  }

  return response({ success: true, msg: 'success', data: data[mutationName] });
};

const removeEntity = async (entity, id) => {
  const mutationName = `delete${entity}`;
  const data = await graphqlApiDecorator(mutations, mutationName, { input: { id } });

  if (!data) {
    response({ success: false });
  }

  return response({ success: true, msg: 'success', data });
};

const editTransaction = async values => {
  const mutationName = 'editTransaction';
  const data = await graphqlApiDecorator(mutations, mutationName, { input: values });

  if (!data) {
    return response({ success: false });
  }

  return response({ success: true, msg: 'success', data: data[mutationName] });
};

export { editTransaction, getEntity, listEntity, removeEntity, saveEntity };
