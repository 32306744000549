import { TableCell } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';

import { entitiesEnum } from '../../../../../constants';
import {
  capitalizeString,
  getCurrencySymbol,
  getFormattedDisplayDate,
  getFormattedDisplayTime,
} from '../../../../../utils';
import {
  AddMoneyIcon,
  CopyToClipBoardIcon,
  DirectDebitIcon,
  ExchangeArrowIcon,
  ExchangedMoneyIcon,
  ReceivedMoneyIcon,
  SentAndExchangedMoneyIcon,
  SentMoneyIcon,
  SmallAttachmentIcon,
} from '../../../../icons';
import { useStyles } from './transaction-row.hooks';
const StatusMap = {
  before_pending: 'Processing',
  error: 'Failed',
  expired: 'Failed',
  failed: 'Failed',
  hold: 'Held',
  payment_failed: 'Failed',
  pending: 'Processing',
  rejected: 'Failed',
  canceled: 'Failed',
  started: 'Processing',
  success: 'Completed',
  accepted: 'Processing',
};

export const TransactionRow = ({ rowData, onClickedRow, entity = entitiesEnum.TRANSACTION }) => {
  const classes = useStyles();
  const [userId, setUserId] = useState();
  const formattedDisplayDate = getFormattedDisplayDate(rowData.createdAt);
  const displayTime = getFormattedDisplayTime(rowData.createdAt);

  const shouldDisplayBalanceFrom = rowData?.userFrom?.id === userId || rowData?.type === 'convert';
  const balanceFrom = rowData?.runningBalanceFrom
    ? Number(rowData.runningBalanceFrom).toLocaleString()
    : null;
  const balanceTo = rowData?.runningBalanceTo
    ? Number(rowData.runningBalanceTo).toLocaleString()
    : null;
  const currencySymbol = getCurrencySymbol(rowData?.fromCurrency);

  let totalBalance = '';

  if (entity === entitiesEnum.USERPROFILE) totalBalance = rowData.totalBalance;
  const isUserProfile = entity === entitiesEnum.USERPROFILE;

  let Icon = null;

  switch (rowData.type) {
    case 'send_money':
      if (rowData.fromCurrency === rowData.toCurrency) {
        Icon = SentMoneyIcon;
      } else {
        Icon = SentAndExchangedMoneyIcon;
      }
      break;
    case 'directDebit':
      Icon = DirectDebitIcon;
      break;
    case 'add_money':
      if (rowData.userFrom && !rowData.beneficiary) {
        Icon = AddMoneyIcon;
      } else {
        Icon = ReceivedMoneyIcon;
      }
      break;
    case 'convert':
      Icon = ExchangedMoneyIcon;
      break;
    default:
      Icon = null;
  }

  const userHasExchangedMoney = Icon === ExchangedMoneyIcon;
  const userHasAddedMoney = Icon === AddMoneyIcon;
  const userHasReceivedMoney = Icon === ReceivedMoneyIcon;
  const userHasSentMoney = Icon === SentMoneyIcon;
  const userHasSentAndExchangedMoney = Icon === SentAndExchangedMoneyIcon;
  const directDebit = rowData.type === 'directDebit';

  useEffect(() => {
    const currentUrl = window.location.href;
    const urlObj = new URL(currentUrl);
    const pathname = urlObj.pathname;
    const uuidMatch = pathname.match(/([0-9a-fA-F-]{36})/);
  
    if (uuidMatch) {
      const uuid = uuidMatch[0];
      setUserId(uuid);
    }
  }, []);  

  const displayBalance = () => {
    if (shouldDisplayBalanceFrom && balanceFrom) {
      return `${currencySymbol}${balanceFrom}`;
    } else if (balanceTo) {
      return `${currencySymbol}${balanceTo}`;
    } else {
      return '-';
    }
  };

  const alternativeCopyToClipboard = text => {
    const textArea = document.createElement('textarea');
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
    document.execCommand('copy');
    document.body.removeChild(textArea);
  };
  const copyToClipBoard = event => {
    event.stopPropagation();
    if (window.isSecureContext && navigator.clipboard) {
      navigator.clipboard.writeText(rowData.id);
    } else {
      alternativeCopyToClipboard(rowData.id);
    }
  };

  const clickedRow = event => {
    event.preventDefault();
    event.stopPropagation();
    onClickedRow(rowData);
  };

  const capitalizeNames = str => {
    const nameArr = str.split(' ');
    const capitalizedNameArr = nameArr.map(item => capitalizeString(item));

    return capitalizedNameArr.join(' ');
  };

  const removeUndefined = str => str.replace('undefined', '');

  const formatName = ({ name, lastName }) =>
    capitalizeNames(removeUndefined(`${name} ${lastName || ''}`));

  const userFromName = formatName({
    name: rowData.userFrom?.name,
    lastName: rowData.userFrom?.lastName,
  });
  const userToName = formatName({
    name: rowData.userTo?.name,
    lastName: rowData.userTo?.lastName,
  });
  const beneficiaryName = formatName({
    name: rowData.beneficiary?.name,
    lastName: rowData.userFrom?.lastName,
  });
  const externalUserFromName = formatName({
    name: rowData.externalUserFrom?.name,
    lastName: rowData.externalUserFrom?.lastName,
  });

  let topLabel = beneficiaryName;
  if (userHasExchangedMoney) topLabel = userFromName;

  if (userHasSentMoney) {
    let creditorAccountName = '';

    if (rowData.creditorAccount && rowData.creditorAccount?.ownerName !== 'Not Provided')
      creditorAccountName = rowData.creditorAccount.ownerName;

    if (rowData.creditorAccount?.transactionOwnerName)
      creditorAccountName = rowData.creditorAccount.transactionOwnerName;

    if (rowData.beneficiary && rowData.beneficiary.name)
      creditorAccountName = capitalizeNames(
        `${rowData.beneficiary.name} ${rowData.beneficiary.lastName || ''}`
      );

    topLabel = rowData.userTo ? `To: ${userToName}` : `To: ${creditorAccountName}`;
  }

  if (directDebit) {
    topLabel = `To: ${rowData.debtorAccount ? rowData.debtorAccount.transactionOwnerName : ''}`;
  }

  if (userHasReceivedMoney) {
    topLabel = rowData.externalUserFrom ? `From: ${externalUserFromName}` : `From: ${userFromName}`;
  }

  let userToSentAndExchangedMoney = '';
  if (userHasSentAndExchangedMoney) {
    topLabel = capitalizeNames(
      `From: ${rowData.userFrom?.name} ${rowData.userFrom?.lastName || ''}`
    );
    if (rowData.userTo)
      userToSentAndExchangedMoney = capitalizeNames(
        `To: ${rowData.userTo?.name} ${rowData.userTo?.lastName || ''}`
      );

    if (rowData.beneficiary)
      userToSentAndExchangedMoney = capitalizeNames(
        `To: ${rowData.beneficiary?.name} ${rowData.beneficiary?.lastName || ''}`
      );
  }

  if (userHasAddedMoney) {
    topLabel = capitalizeNames(
      `From: ${rowData.userFrom.name} ${rowData.userFrom?.lastName || ''}`
    );
  }

  topLabel = removeUndefined(topLabel);

  return (
    <TableCell onClick={clickedRow} sx={{ cursor: 'pointer' }}>
      <div className={classes.transactionRow}>
        <div className={classes.transactionRowDateTimeContainer}>
          <div>{formattedDisplayDate}</div>
          <div>{displayTime}</div>
        </div>
        {Icon !== null && (
          <div className={classes.transactionRowIconContainer}>
            <Icon />
          </div>
        )}
        <div className={classes.transactionRowVerticalLine} />
        <div
          className={
            userHasSentMoney || userHasReceivedMoney || directDebit
              ? classes.transactionRowPurposeNameContainerReverse
              : classes.transactionRowPurposeNameContainer
          }
        >
          <div
            className={clsx(
              (userHasExchangedMoney || userHasSentAndExchangedMoney || userHasAddedMoney) &&
                classes.transactionRowFontColor,
              classes.textFormat
            )}
          >
            {topLabel}
          </div>
          {userHasExchangedMoney ? (
            <div className={classes.transactionRowExchangeContainer}>
              <div>{rowData.fromCurrency}</div>
              <ExchangeArrowIcon />
              <div>{rowData.toCurrency}</div>
            </div>
          ) : (
            <>
              {/* {userHasAddedMoney ? null : (
                <div className={classes.textFormat}>
                  {rowData.userFrom ? `From: ${userFromName}` : ''}
                </div>
              )} */}
              {userHasReceivedMoney && (
                <div className={clsx(classes.textFormat, classes.transactionRowFontColor)}>
                  {rowData.userTo ? `To: ${userToName}` : ''}
                </div>
              )}
              {(userHasSentMoney || userHasExchangedMoney) && (
                <div className={clsx(classes.textFormat, classes.transactionRowFontColor)}>
                  {rowData.userFrom ? `From: ${userFromName}` : ''}
                </div>
              )}
              {userHasSentAndExchangedMoney && (
                <div className={classes.textFormat}>{userToSentAndExchangedMoney}</div>
              )}
              {directDebit && (
                <div className={clsx(classes.textFormat, classes.transactionRowFontColor)}>
                  {rowData.creditorAccount ? `From: ${userFromName}` : ''}
                </div>
              )}
            </>
          )}
        </div>
        <div
          className={clsx(
            classes.transactionRowClipBoardContainer,
            isUserProfile && classes.userTransactionRowClipBoardContainer
          )}
        >
          <div className={classes.transactionRowClipBoardTextContainer}>
            <div>{userHasExchangedMoney ? `Exchange ID: ` : `Transaction ID: `}</div>
            <div className={classes.transactionRowFontColor}>{rowData.id}</div>
          </div>
          <Tooltip title="Copy to clipboard">
            <div className={classes.transactionRowClipBoardIconContainer} onClick={copyToClipBoard}>
              <CopyToClipBoardIcon />
            </div>
          </Tooltip>
        </div>
        {!userHasExchangedMoney ? (
          <div
            className={
              entity === entitiesEnum.TRANSACTION
                ? classes.transactionRowReferenceContainer
                : classes.transactionRowReferenceContainerUser
            }
          >
            <div className={classes.transactionRowVerticalLine} />
            <div>
              <div>Reference</div>
              <div className={classes.transactionRowFontColor}>{rowData.reference}</div>
            </div>
          </div>
        ) : (
          <div
            className={
              entity === entitiesEnum.TRANSACTION
                ? classes.transactionRowReferenceContainer
                : classes.transactionRowReferenceContainerUser
            }
          />
        )}
        {StatusMap[rowData.status] === 'Completed' && (
          <div
            className={`${classes.transactionRowStatusContainer} ${classes.transactionRowStatusCompleted}`}
          >
            <p>Completed</p>
          </div>
        )}
        {StatusMap[rowData.status] === 'Failed' && (
          <>
            <div
              className={`${classes.transactionRowStatusContainer} ${classes.transactionRowStatusFailed}`}
            >
              <p>Failed</p>
            </div>
            <p className={classes.failedStatusMsg}>{rowData.status}</p>
          </>
        )}
        {StatusMap[rowData.status] !== 'Completed' && StatusMap[rowData.status] !== 'Failed' && (
          <div
            className={`${classes.transactionRowStatusContainer} ${classes.transactionRowStatusProcessing}`}
          >
            <p>{StatusMap[rowData.status]}</p>
          </div>
        )}
        {rowData.adminAttachments && (
          <div className={classes.attachmentIcon}>
            <SmallAttachmentIcon />
          </div>
        )}
        {(Icon === SentMoneyIcon || directDebit) && (
          <div className={classes.currencySendMoney}>
            <p>{`-${getCurrencySymbol(rowData?.toCurrency)}${Number(
              rowData?.sendingAmount
            ).toLocaleString()}`}</p>
          </div>
        )}
        {(rowData.type === 'convert' || Icon === SentAndExchangedMoneyIcon) && (
          <div className={classes.currencyConvertMoney}>
            <p className={classes.currencyConvertMoneyTop}>{`-${getCurrencySymbol(
              rowData?.fromCurrency
            )}${Number(rowData?.sendingAmount).toLocaleString()}`}</p>
            <p className={classes.currencyConvertMoneyBottom}>{`+${getCurrencySymbol(
              rowData?.toCurrency
            )}${Number(rowData?.receivingAmount).toLocaleString()}`}</p>
          </div>
        )}
        {rowData.type === 'add_money' && (
          <div className={classes.currencyAddMoney}>
            <p>{`+${getCurrencySymbol(rowData?.toCurrency)}${Number(
              rowData?.receivingAmount
            ).toLocaleString()}`}</p>
          </div>
        )}
        {entity === entitiesEnum.USERPROFILE && (
          <>
            <div className={classes.transactionRowVerticalLine} style={{ marginLeft: '8px' }} />
            <div className={classes.totalBalanceContainer}>
              <p>Total Balance:</p>
              <p style={{ color: '#19365e', fontWeight: '500' }}>
                {/* {(rowData?.userFrom?.id === userId || rowData.type === 'convert') &&
                rowData.runningBalanceFrom
                  ? `${getCurrencySymbol(rowData.fromCurrency)}${Number(
                      rowData.runningBalanceFrom
                    ).toLocaleString()}`
                  : rowData.runningBalanceTo
                  ? `${getCurrencySymbol(rowData.fromCurrency)}${Number(
                      rowData.runningBalanceTo
                    ).toLocaleString()}`
                  : '-'} */}
                {displayBalance()}
              </p>
            </div>
          </>
        )}
      </div>
    </TableCell>
  );
};
