import { Radio, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import React from 'react';
import { uid } from 'react-uid';

import { useStyles } from './modal-permissions.hooks';

export const ModalPermissions = ({ styles, permissions, setPermissions, permissionOptions }) => {
  const classes = useStyles();

  const handleChange = e => {
    const { value, name } = e.target;
    setPermissions({ ...permissions, [name]: value });
  };

  return (
    <Table>
      <TableHead>
        <TableRow className={styles.tableHeadRow} style={{ height: '4.2rem' }}>
          <TableCell className={styles.tableHeadCell} style={{ color: '#454846' }}>
            Permissions
          </TableCell>
        </TableRow>
      </TableHead>
      <TableHead>
        <TableRow className={classes.row}>
          <TableCell className={styles.tableHeadCell} style={{ color: '#454846' }} />
          <TableCell className={classes.columnLabel} style={{ padding: '1rem' }}>
            Write
          </TableCell>
          <TableCell className={classes.columnLabel} style={{ padding: '1rem' }}>
            Read
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody className={styles.tableRoot}>
        {permissionOptions.map(option => {
          const capitalizedLabel = option.charAt(0).toUpperCase() + option.slice(1);

          return (
            <TableRow key={uid(option)} className={classes.row}>
              <TableCell className={classes.options}>{capitalizedLabel}</TableCell>
              <TableCell className={classes.tableCell}>
                <Radio
                  checked={permissions[option] === 'write'}
                  onChange={handleChange}
                  value="write"
                  name={option}
                  inputProps={{ 'aria-label': 'A' }}
                  className={classes.radio}
                />
              </TableCell>
              <TableCell className={classes.tableCell}>
                <Radio
                  checked={permissions[option] === 'read'}
                  onChange={handleChange}
                  value="read"
                  name={option}
                  inputProps={{ 'aria-label': 'A' }}
                  className={classes.radio}
                />
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
};
