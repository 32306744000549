import HighchartsReact from "highcharts-react-official";
import Highcharts from 'highcharts'
import {Box} from "@mui/material";
import {useStyles} from "./Dashboard-content.hook";
export const Chart= ({numberOfHighRiskAccount, numberOfLowRiskAccount, numberOfMidRiskAccount})=>{
    const classes = useStyles();

    const chartOption={
        chart: {
            plotBackgroundColor: null,
            plotBorderWidth: null,
            plotShadow: false,
            type: 'pie'
        },
        title: {
            text: '',
            align: 'left',
            marginTop: '32px'
        },
        tooltip: {
            pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
        },
        accessibility: {
            point: {
                valueSuffix: '%'
            }
        },
        plotOptions: {
            pie: {
                allowPointSelect: true,
                cursor: 'pointer',
                colors:['#f8d753','#1fe3ac','#ff6d6d'],
                dataLabels: {
                    enabled: true,
                    format: '<b>{point.percentage:.1f} %</b>',
                    dataLabels: {
                        style: {
                            fontSize: '16px', // Set the desired font size here
                        },
                    }
                }
            }
        },
        series: [{
            name: 'Brands',
            colorByPoint: true,
            data: [{
                name: 'numberOfMidRiskAccount',
                y: parseInt(numberOfMidRiskAccount),
                sliced: true,
                selected: true,
            }, {
                name: 'numberOfLowRiskAccount',
                y: parseInt(numberOfLowRiskAccount)
            },  {
                name: 'numberOfHighRiskAccount',
                y: parseInt(numberOfHighRiskAccount)
            }]
        }]
    }

    return (
        <Box sx={{display: 'flex', flexDirection:'row', justifyContent:'center'}}>
            <Box sx={{display: 'flex', flexDirection: 'column'}}>
                <Box sx={{display:'flex', alignItems:'center', fontSize:'16px', marginTop: '16px'}}>
                    <span className={classes.colorSpan} style={{background:'#f8d753'}}></span>
                    Number of Mid Risk Account
                </Box>
                <Box sx={{display:'flex', alignItems:'center', fontSize:'16px', marginTop: '16px'}}>
                    <span className={classes.colorSpan} style={{background:'#1fe3ac'}}></span>
                    Number of Low Risk Account
                </Box>
                <Box sx={{display:'flex', alignItems:'center', fontSize:'16px', marginTop: '16px'}}>
                    <span className={classes.colorSpan} style={{background:'#ff6d6d'}}></span>
                    Number of High Risk Account
                </Box>
            </Box>
            <Box>
                <HighchartsReact
                    highcharts={Highcharts}
                    options={chartOption}
                />
            </Box>

        </Box>

    )
}