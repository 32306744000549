import React from 'react';

import { useStyles } from './sidebar-nav.hooks';

export const SidebarNavItemPopup = ({ title }) => {
  const classes = useStyles();

  return (
    <div className={classes.popup}>
      <div>{title}</div>
    </div>
  );
};
