import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(theme => ({
  toolbar: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    height: '112px',
    backgroundColor: 'white',
    borderRadius: '0 0 15px 15px',
    padding: '32px 32px 12px 48px',
    fontSize: '30px',
    color: theme.palette.icons.nav,
    fontFamily: theme.typography.default,
    '& header': {
      fontSize: '30px',
      fontWeight: 'bold',
    },
  },
  root: {
    padding: '3.2rem',
  },
  lastTab: {
    borderRadius: '0 0px 16px 16px',
    borderBottomStyle: 'none',
  },
  navigateBack: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    cursor: 'pointer',
    marginTop: '5px',
    gap: '11px',
    color: '#1fe3ac',
    fontWeight: '500',
    '& p': {
      fontSize: '18px',
    },
  },
  bigSaveButton: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    cursor: 'pointer',
    fontSize: '24px',
    fontWeight: '500',
    gap: '16px',
    paddingLeft: '16px',
    backgroundColor: '#c6ffef',
    borderRadius: '8px',
    width: '168px',
    height: '56px',
  },
  currencyTab: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '80px',
    backgroundColor: 'white',
    fontSize: '24px',
    fontWeight: 'bold',
    padding: '16px',
    color: theme.palette.icons.nav,
    fontFamily: theme.typography.default,
  },
  arrowIcon: {
    cursor: 'pointer',
  },
  localInternationalContainer: {
    display: 'flex',
    flexDirection: 'row',
    width: '50%',
    height: '56px',
    borderRadius: '28px',
    border: '1px solid #d9e1e7',
    padding: '8px',
  },
  saveAnddownArrowContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '32px',
    padding: '8px',
  },
  highlightedTab: {
    backgroundColor: '#e2ecff',
    borderRadius: '20px',
    textAlign: 'center',
    cursor: 'pointer',
    flex: '1',
    paddingTop: '5px',
  },
  defaultTab: {
    textAlign: 'center',
    cursor: 'pointer',
    flex: '1',
    color: '#a5a6ae',
    paddingTop: '5px',
  },
  downArrow: {
    marginBottom: '16px',
    cursor: 'pointer',
  },
  requirementsContainer: {
    backgroundColor: 'white',
    color: theme.palette.icons.nav,
    fontFamily: theme.typography.default,
    fontSize: '20px',
    fontWeight: '500',
    columnCount: '2',
    columnGap: '0px',
    columnFill: 'auto',
    columnRule: '1px solid #a5a6ae',
    padding: '0 16px',
  },
}));
