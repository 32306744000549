import React from 'react';

export const KWD = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48">
    <defs>
      <clipPath id="ue6nic64ta">
        <path
          data-name="Path 19905"
          d="M6408 10080a24 24 0 1 0-24-24 24 24 0 0 0 24 24z"
          transform="translate(-6032 -8656.001)"
          style={{ fill: '#f0f0f0' }}
        />
      </clipPath>
    </defs>
    <g
      data-name="Mask Group 68"
      transform="translate(-352 -1376)"
      style={{ clipPath: 'url(#ue6nic64ta)' }}
    >
      <g data-name="Group 8509">
        <path
          data-name="Path 19901"
          d="M3694.507 11107.477a24.071 24.071 0 0 0 0-16.687l-22.507-2.09-22.507 2.092a24.067 24.067 0 0 0 0 16.687l22.507 2.09z"
          transform="translate(-3295.999 -9699.133)"
          style={{ fill: '#f0f0f0' }}
        />
        <path
          data-name="Path 19902"
          d="M3686.427 11304.655a24.013 24.013 0 0 0 22.507-15.656h-45.014a24.012 24.012 0 0 0 22.507 15.656z"
          transform="translate(-3310.427 -9880.656)"
          style={{ fill: '#d80027' }}
        />
        <path
          data-name="Path 19903"
          d="M3686.427 10944a24.01 24.01 0 0 0-22.507 15.657h45.014a24.011 24.011 0 0 0-22.507-15.657z"
          transform="translate(-3310.427 -9568)"
          style={{ fill: '#6da544' }}
        />
        <path
          data-name="Path 19904"
          d="M359.03 1383.031a24 24 0 0 0 0 33.938c2.446-2.448 4.833-4.829 8.623-8.626v-16.686z"
        />
      </g>
    </g>
  </svg>
);
