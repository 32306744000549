import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import React from 'react';

import { currencies } from '../../../constants';
import { useStyles } from './modal.hooks';

export const BeneficiaryModal = ({ open, onClose, data }) => {
  const classes = useStyles();
  const accountNo = data?.bankDetails?.find(item => item?.name === 'acct_number')?.value || 'N/A';
  const sortCode = data?.bankDetails?.find(item => item?.name === 'sort_code')?.value || 'N/A';
  const iban = data?.bankDetails?.find(item => item?.name === 'iban')?.value;
  const swift = data?.bankDetails?.find(item => item?.name === 'bic_swift')?.value;

  const isInternational = !!iban && !!swift;
  let beneficiaryName = '';
  if (data?.name) beneficiaryName = `${data?.name} ${data?.lastName || ''}`;
  if (!beneficiaryName && data?.bankDetails) {
    beneficiaryName = data?.bankDetails.find(el => el.name === 'beneficiary_company_name')?.value;
  }

  return (
    <Modal open={open} onClose={onClose}>
      <Box>
        <div className={classes.modalBox}>
          <header>Beneficiary Info</header>

          <section>
            <p style={{ textAlign: 'center', marginTop: '31px', marginBottom: '14px' }}>
              Personal Details
            </p>
            <p>Full Name</p>
            <div className={classes.inputBox}>
              <p>{beneficiaryName}</p>
            </div>
            <p>Email</p>
            <div className={classes.inputBox}>
              <p>{data?.email || 'N/A'}</p>
            </div>
          </section>

          <section>
            <p style={{ textAlign: 'center', marginBottom: '30px' }}>Account Details</p>
            <p>Country</p>
            <div className={classes.inputBox}>
              <p>{data?.country?.name || 'N/A'}</p>
            </div>
            <p>Currency</p>
            <div className={classes.inputBox}>
              <p>{currencies[data?.beneficiaryCurrencyId] || 'N/A'}</p>
            </div>
            {!isInternational && (
              <>
                <p>Account Number</p>
                <div className={classes.inputBox}>
                  <p>{accountNo}</p>
                </div>
                <p>Sort Code</p>
                <div className={classes.inputBox}>
                  <p>{sortCode}</p>
                </div>
              </>
            )}
            {isInternational && (
              <>
                <p>IBAN</p>
                <div className={classes.inputBox}>
                  <p>{iban}</p>
                </div>
                <p>SWIFT</p>
                <div className={classes.inputBox}>
                  <p>{swift}</p>
                </div>
              </>
            )}
          </section>

          <section className={classes.btnContainer}>
            <button type="submit" onClick={onClose} className={classes.confirmBtn}>
              OK
            </button>
          </section>
        </div>
      </Box>
    </Modal>
  );
};
