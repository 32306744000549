import React from 'react';

export const CurrenciesIcon = ({ className, color }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 33 33">
    {/* prettier-ignore */}
    <g data-name="Group 7280">
      <path data-name="Union 88"
        d="M0 16a16 16 0 1 1 16 16A16 16 0 0 1 0 16zm1.833 0A14.167 14.167 0 1 0 16 1.832 14.167 14.167 0 0 0 1.832 16zM16 27.8a1.082 1.082 0 0 1-1.069-1.069.3.3 0 0 0 0-.06v-1.912a.758.758 0 0 0-.62-.74c-2.522-.464-4.34-1.856-4.788-3.606h-.009l-.014-.055-.008-.023a2.1 2.1 0 0 1 .1-1.235 1.062 1.062 0 0 1 1.845.156 5.5 5.5 0 0 0 .413 1.134 3.508 3.508 0 0 0 2.089 1.429.757.757 0 0 0 .95-.494v-.006a.8.8 0 0 0 .034-.221v-3.492a.761.761 0 0 0-.622-.739c-2.928-.539-4.9-2.323-4.9-4.437s1.967-3.9 4.9-4.445a.76.76 0 0 0 .622-.747v-1.97a1.081 1.081 0 0 1 2.161 0v1.97a.761.761 0 0 0 .623.747c2.56.471 4.4 1.894 4.811 3.683a2.245 2.245 0 0 1-.082 1.308 1.045 1.045 0 0 1-1.824-.157 4.325 4.325 0 0 0-.431-1.177c-.022-.033-.036-.055-.055-.081a3.532 3.532 0 0 0-2.046-1.376.762.762 0 0 0-.952.5.718.718 0 0 0-.035.226V14.4a.762.762 0 0 0 .621.74c2.93.54 4.9 2.323 4.9 4.437s-1.967 3.9-4.9 4.441a.762.762 0 0 0-.623.747v1.965a1.083 1.083 0 0 1-1.085 1.07zm1.4-10.356a.76.76 0 0 0-.311.613V21.1a.768.768 0 0 0 .311.612.752.752 0 0 0 .439.145.649.649 0 0 0 .242-.037c1.458-.473 2.366-1.333 2.366-2.24s-.908-1.768-2.367-2.24a.8.8 0 0 0-.238-.036.774.774 0 0 0-.451.139zm-3.415-7.276a.222.222 0 0 0-.056.013c-1.46.471-2.365 1.331-2.365 2.24s.9 1.767 2.366 2.24a.751.751 0 0 0 .229.036.759.759 0 0 0 .759-.759V10.9a.749.749 0 0 0-.221-.534.771.771 0 0 0-.715-.2z"
        transform="translate(.5 .5)"
        className={className}
        fill={color}
      />
      <path data-name="Rectangle 17103"
        transform="translate(.5 .5)"
        style={{ fill: 'none' }}
        d="M0 0h32v32H0z"/>
    </g>
  </svg>
);
