import React from 'react';

import { PairsSaveIcon } from '../../../icons/pairs';
import { useStyles } from './button.hooks';

export const SaveButton = ({ save }) => {
  const classes = useStyles();

  return (
    <button
      className={`${classes.defaultBtnStyle} ${classes.btnSave}`}
      type="button"
      onClick={save}
    >
      <PairsSaveIcon />
      Save
    </button>
  );
};
