import React from 'react';

export const CZK = ({ width = '45', height = '45' }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 48 48">
    <defs>
      <clipPath id="gxyqb1tdia">
        <path data-name="Rectangle 17996" style={{ fill: '#fff' }} d="M0 0h48v48H0z" />
      </clipPath>
    </defs>
    <g data-name="Group 8538" style={{ clipPath: 'url(#gxyqb1tdia)' }}>
      <path
        data-name="Path 19964"
        d="M2759.916 5519.831a23.915 23.915 0 1 0-23.916-23.915 23.916 23.916 0 0 0 23.916 23.915z"
        transform="translate(-2736.405 -5471.868)"
        style={{ fill: '#f0f0f0' }}
      />
      <path
        data-name="Path 19965"
        d="M2825.811 5728s-14.817 16.915-14.831 16.911a23.916 23.916 0 0 0 40.826-16.911z"
        transform="translate(-2804.38 -5703.952)"
        style={{ fill: '#d80027' }}
      />
      <path
        data-name="Path 19966"
        d="M2743.007 5546.979a23.917 23.917 0 0 0 0 33.822l16.911-16.911z"
        transform="translate(-2736.406 -5539.841)"
        style={{ fill: '#0052b4' }}
      />
    </g>
  </svg>
);
