import { Button, Menu, MenuItem } from '@mui/material';
import React, { useState } from 'react';
import { CSVLink } from 'react-csv';
import { useSelector } from 'react-redux';

import {
  currenciesFieldHeaders,
  customersFieldHeaders,
  entitiesEnum,
  transactionsFieldHeaders,
} from '../../constants';
import {
  exportCurrenciesTableDataToPdf,
  exportCustomersTableDataToPdf,
  exportTableDataToExcel,
  exportTableDataToPdf,
  formatCurrencyTableData,
  formatCustomerTableData,
  formatTableData,
} from '../../services';
import { ArrowDownIcon, ArrowRightIcon, ExportIcon } from '../icons';
import { useStyles } from './table-export.hooks';

export const TableExport = ({ entity, name }) => {
  const classes = useStyles();
  const USERTRANSACTIONS = 'userTransactions';
  const [arrowIconClicked, setArrowIconClicked] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const tableDataToExport = useSelector(state => state.tableDataToExport);
  const customersTableDataToExport = useSelector(state => state.customersTableDataToExport);
  const userTransactionsTableDataToExport = useSelector(
    state => state.userTransactionsTableDataToExport
  );
  const currenciesTableDataToExport = useSelector(state => state.currenciesTableDataToExport);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
    setArrowIconClicked(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setArrowIconClicked(false);
  };

  const handleCloseExcel = () => {
    setAnchorEl(null);
    setArrowIconClicked(false);
    if (entity === entitiesEnum.TRANSACTION) exportTableDataToExcel(tableDataToExport, entity);
    if (entity === entitiesEnum.USERPROFILE)
      exportTableDataToExcel(customersTableDataToExport, entity);
    if (entity === entitiesEnum.CURRENCY)
      exportTableDataToExcel(currenciesTableDataToExport, entity);
    if (entity === USERTRANSACTIONS)
      exportTableDataToExcel(userTransactionsTableDataToExport, entity, name);
  };

  let csvData = null;

  if (entity === entitiesEnum.TRANSACTION) csvData = formatTableData(tableDataToExport);
  if (entity === entitiesEnum.CURRENCY)
    csvData = formatCurrencyTableData(currenciesTableDataToExport);
  if (entity === entitiesEnum.USERPROFILE)
    csvData = formatCustomerTableData(customersTableDataToExport);
  if (entity === USERTRANSACTIONS) csvData = formatTableData(userTransactionsTableDataToExport);

  let csvHeaders = null;
  if (entity === entitiesEnum.TRANSACTION) csvHeaders = transactionsFieldHeaders;
  if (entity === entitiesEnum.USERPROFILE) csvHeaders = customersFieldHeaders;
  if (entity === USERTRANSACTIONS) csvHeaders = transactionsFieldHeaders;
  if (entity === entitiesEnum.CURRENCY) csvHeaders = currenciesFieldHeaders;

  const menuItemHoverStyle = { ':hover': { background: '#c6ffef' } };

  return (
    <div className={classes.exportButton} id="export-button">
      <Button
        id="export-button"
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          gap: '8px',
          justifyContent: 'space-evenly',
          width: '100%',
          fontSize: '14px',
          color: '#19365e',
          textTransform: 'none',
          fontFamily: 'Gilroy, Roboto , Helvetica, Arial, sans-serif',
        }}
        aria-controls={open ? 'export-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        <div className={classes.exportIcon}>
          <ExportIcon />
        </div>
        <p className={classes.exportLabel}>Export</p>
        <div className={classes.arrowIcon}>
          {!arrowIconClicked ? <ArrowRightIcon /> : <ArrowDownIcon />}
        </div>
      </Button>
      <Menu
        id="export-menu"
        sx={{
          '& .MuiPaper-root': {
            width: '152px',
            marginTop: '5px',
            border: '1px solid #1fe3ac',
            borderRadius: '16px',
            boxShadow: 'none',
          },
        }}
        open={open}
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'export-button',
        }}
      >
        <MenuItem
          sx={menuItemHoverStyle}
          onClick={() => {
            if (entity === entitiesEnum.TRANSACTION)
              exportTableDataToPdf(tableDataToExport, 'Transactions');
            if (entity === entitiesEnum.CURRENCY)
              exportCurrenciesTableDataToPdf(currenciesTableDataToExport, 'Currencies');
            if (entity === entitiesEnum.USERPROFILE)
              exportCustomersTableDataToPdf(customersTableDataToExport, 'Customers');
            if (entity === USERTRANSACTIONS)
              exportTableDataToPdf(userTransactionsTableDataToExport, `${name}-Transactions`);
          }}
        >
          PDF
        </MenuItem>
        <MenuItem sx={menuItemHoverStyle} onClick={handleCloseExcel}>
          Excel
        </MenuItem>
        <MenuItem sx={menuItemHoverStyle} onClick={handleClose}>
          <CSVLink
            filename={`${name || ''}${name ? '-' : ''}transactions-export.csv`}
            data={csvData}
            headers={csvHeaders}
            className={classes.exportCSV}
          >
            <span>CSV</span>
          </CSVLink>
        </MenuItem>
      </Menu>
    </div>
  );
};
