import React from 'react';

export const NumberOfPersonalAccounts = ({ className, color }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40">
    <g data-name="Group 9003">
      <path
        data-name="Path 23435"
        d="M8 0h24a8 8 0 0 1 8 8v24a8 8 0 0 1-8 8H8a8 8 0 0 1-8-8V8a8 8 0 0 1 8-8z"
        fill="#85e2ff"
      />
      <g data-name="Group 7126">
        <g data-name="Group 6098" transform="translate(11.001 9.286)">
          <ellipse
            data-name="Ellipse 1320"
            cx="4.697"
            cy="4.696"
            rx="4.697"
            ry="4.696"
            transform="translate(4.13 .821)"
            fill="#fff"
          />
          <path
            data-name="Path 8714"
            d="M-254.389-435.994a2.887 2.887 0 0 1 .232-1.146 4.2 4.2 0 0 1 2.978-1.913 15.941 15.941 0 0 1 2.475-.389 23.7 23.7 0 0 1 4.63 0 16.126 16.126 0 0 1 2.475.389c1.131.26 2.494.778 2.978 1.913a2.97 2.97 0 0 1 0 2.3c-.483 1.135-1.847 1.654-2.978 1.9a14.993 14.993 0 0 1-2.475.4 24.376 24.376 0 0 1-3.77.065 3.838 3.838 0 0 1-.86-.065 14.734 14.734 0 0 1-2.465-.4 4.022 4.022 0 0 1-2.987-1.9 2.962 2.962 0 0 1-.233-1.154z"
            transform="translate(255.21 453.246)"
            fill="#fff"
          />
        </g>
      </g>
    </g>
  </svg>
);
