import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(theme => ({
  container: {
    padding: '32px',
    marginTop: '32px',
    height: "auto",
    maxHeight: "700px",
    overflowY: "scroll"
  },
  formField: {
    marginBottom: theme.spacing(2),
  },
  textField: {
    padding: '10px',
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: theme.palette.primary.main,
      },
    },
  },
  input: {
    fontSize: '16px',
  },
  label: {
    fontSize: '14px',
    fontWeight: 600,
  },
}));
