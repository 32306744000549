const getDateByLocale = ({ date, withTime = true }) => {
  const formattedDate = new Date(date);

  return withTime
    ? formattedDate.toLocaleString('en-GB')
    : formattedDate.toLocaleDateString('en-GB');
};

const getFormattedDisplayTime = createdAtDate => {
  const displayTime = new Date(createdAtDate);

  return `${displayTime.getHours() < 10 ? `0${displayTime.getHours()}` : displayTime.getHours()}:${
    displayTime.getMinutes() < 10 ? `0${displayTime.getMinutes()}` : displayTime.getMinutes()
  } ${displayTime.getHours() > 11 ? `PM` : `AM`}`;
};

const getFormattedDisplayDate = createdAtDate => {
  const date = new Date(createdAtDate);

  return `${date.getDate() < 10 ? `0${date.getDate()}` : date.getDate()}/${
    date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1
  }/${date.getUTCFullYear().toString().slice(-2)}`;
};

const todayDate = (dtToday = new Date()) => {
  let month = dtToday.getMonth() + 1;
  let day = dtToday.getDate();
  const year = dtToday.getFullYear();

  if (month < 10) month = `0${month.toString()}`;
  if (day < 10) day = `0${day.toString()}`;

  return `${year}-${month}-${day}`;
};

const ONEDAYMILLISECONDS = 60 * 60 * 24 * 1000;

const monthNames = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sept',
  'Oct',
  'Nov',
  'Dec',
];

const getFormattedLongDisplayDate = createdAtDate => {
  const date = new Date(createdAtDate);

  return `${date.getDate() < 10 ? `0${date.getDate()}` : date.getDate()} ${
    monthNames[date.getMonth()]
  } ${date.getUTCFullYear().toString()}`;
};

export {
  getDateByLocale,
  getFormattedDisplayDate,
  getFormattedDisplayTime,
  getFormattedLongDisplayDate,
  ONEDAYMILLISECONDS,
  todayDate,
};
