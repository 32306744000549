import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import React from 'react';

import { Loader } from '../../../../loader';

export const LoadingModal = ({ open, onClose }) => (
  <Modal open={open} onClose={onClose}>
    <Box>
      <Loader />
    </Box>
  </Modal>
);
