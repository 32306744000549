import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(theme => ({
  menu: {
    margin: '0 2rem',
    display: 'flex',
    width: 'auto',
    overflow: 'scroll',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  selected: {
    color: theme.palette.primary.main,
    fontWeight: 'bold',
    width: 'max-content',
  },
  menuWrapper: {
    overflow: 'hidden',
    width: '100%',
    position: 'relative',
  },
  arrowDiv: {
    cursor: 'pointer',
    position: 'absolute',
    zIndex: 1,
    top: '50%',
    transform: 'translateY(-50%)',
    color: theme.palette.text.tertiary,
    margin: '0 2rem',
    '&:icon': {
      color: theme.palette.text.tertiary,
    },
  },
  right: {
    right: '-2rem',
  },
  left: {
    left: '-2rem',
  },
  menuDiv: {
    cursor: 'pointer',
    marginRight: '2.8rem',
    padding: '2rem 0',
  },
  menuContent: {
    display: 'flex',
  },
  divSelected: {
    cursor: 'pointer',
    borderBottom: `0.4rem solid ${theme.palette.primary.main}`,
    marginRight: '2.8rem',
    padding: '2rem 0',
  },
  menuItem: {
    width: 'max-content',
  },
}));
