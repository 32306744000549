import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(theme => ({
  root: {
    boxShadow: 'none',
  },
  content: {
    width: '100%',
  },
  icon: {
    color: theme.palette.greyMid.dark,
  },
  tableRoot: {
    borderSpacing: '0 1rem',
    borderCollapse: 'separate',
  },
  tableHeadRow: {
    border: 'none',
  },
  tableHeadCell: {
    maxWidth: '9rem',
    backgroundColor: theme.palette.white,
    border: 'none',
    color: theme.palette.text.secondary,
  },
  tableCell: {
    borderTop: `0.1rem solid ${theme.palette.greyMid.main}`,
    borderBottom: `0.1rem solid ${theme.palette.greyMid.main}`,
    color: theme.palette.text.secondary,
    fontSize: '1.4rem',
    padding: '1rem',

    '&:first-child': {
      borderTopLeftRadius: '0.4rem',
      borderBottomLeftRadius: '0.4rem',
      borderLeft: `0.1rem solid ${theme.palette.greyMid.main}`,
    },
    '&:last-child': {
      borderTopRightRadius: '0.4rem',
      borderBottomRightRadius: '0.4rem',
      borderRight: `0.1rem solid ${theme.palette.greyMid.main}`,
    },
    '& .MuiSvgIcon-root': {
      width: '2.4rem',
      height: '2.4rem',
    },
  },
  tableCellHead: {
    border: 'none',
    '&:first-child': {
      border: 'none',
    },
    '&:last-child': {
      border: 'none',
    },
  },
  tableRow: {
    backgroundColor: theme.palette.background.default,
    '&:hover': {
      backgroundColor: theme.palette.background.hoverDefault,
    },
    minHeight: '6.2rem',
  },
  iconsDiv: {
    display: 'flex',
  },
}));
