// import Button from '@mui/material/Button';
// import Menu from '@mui/material/Menu';
// import MenuItem from '@mui/material/MenuItem';
import React, { useEffect, useState } from 'react';

// import { adminRoles } from '../../../constants';
import { graphqlApiDecorator } from '../../../decorators';
import { queries } from '../../../graphql';
import { SmallSideRectangle } from '../../icons';
import { Loader } from '../../loader';
import { adminRows } from '../mockData';
import { useStyles } from '../styles/admin-list.hooks';
// import { AdminListSearchBar } from '../sub-components/adminListSearchBar';
import { AdminListTable } from '../sub-components/admin-list-table';

// ({ data }) => {
export const AdminList = ({ openEditMemberModal }) => {
  // const [query, setQuery] = useState('');
  // const [anchorEl, setAnchorEl] = useState(null);
  // const [itemSelected, setItemSelected] = useState('All Roles');
  // const [statusSelected, setStatusSelected] = useState('All Statuses');
  // const [assignedRole, setAssignedRole] = useState('Assign Role');
  const [selected, setSelected] = useState([]);
  const [membersData, setMembersData] = useState([]);
  const [loading, setLoading] = useState(true);
  const classes = useStyles();
  // const open = Boolean(anchorEl);
  // const menuItemHoverStyle = { ':hover': { background: '#c6ffef' } };

  useEffect(() => {
    const fetchMembers = async () => {
      try {
        const members = await graphqlApiDecorator(queries, 'listUsers');

        members.forEach(member => {
          member.attributes = member?.attributes.reduce((result, item) => {
            result[item.name] = item.value;

            return result;
          }, {});

          member.roles = member.attributes?.['custom:groups']?.split(',') ?? [];
          member.name = member.attributes?.name ?? '';
        });

        setMembersData(members);
        setLoading(false);
      } catch (error) {
        console.log('error fetching listUsers', error);
      }
    };
    fetchMembers();
  }, []);

  // const handleClose = () => {
  //   setAnchorEl(null);
  // };
  // const handleSubmit = event => {
  //   event.preventDefault();
  //   if (!query) {
  //     setMembersData(data);

  //     return;
  //   }
  //   const regex = new RegExp(query, 'gi');
  //   setMembersData(prev => prev.filter(item => item.name.match(regex) || item.email.match(regex)));
  // };

  const handleSelectAllClick = event => {
    if (event.target.checked) {
      const newSelected = adminRows.map(row => row.name);
      setSelected(newSelected);

      return;
    }
    setSelected([]);
  };

  return (
    <div className={classes.container}>
      <div className={classes.headerRow}>
        <aside>
          <div className={classes.label}>
            <SmallSideRectangle />
            <p style={{ fontSize: '24px', fontWeight: 'bold', marginLeft: '8px' }}>Admin List</p>
          </div>
          {/* <AdminListSearchBar
            query={query}
            changeQuery={setQuery}
            handleSubmit={handleSubmit}
            clearQuery={() => setQuery('')}
            itemSelected={itemSelected}
            setItemSelected={setItemSelected}
            statusSelected={statusSelected}
            setStatusSelected={setStatusSelected}
          /> */}
        </aside>
        <aside style={{ marginRight: '16px' }}>
          {/* <div className={classes.noOfMembersSelectedContainer}>
            <p>{`${selected.length} Members Selected`}</p>
          </div>
          <Button
            id="assignedRoleBtn"
            sx={{
              display: 'flex',
              flexDirection: 'row',
              gap: '16px',
              textTransform: 'none',
              borderRadius: '12px',
              width: 'auto',
              height: '24px',
              fontSize: '16px',
              color: '#19365e',
              backgroundColor: '#f2f2f4',
              '&:hover': {
                backgroundColor: '#f2f2f4',
              },
            }}
            aria-controls={open ? 'basic-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={e => setAnchorEl(e.currentTarget)}
          >
            <p>{assignedRole}</p>
            <ArrowDownIcon />
          </Button> */}
          {/* <Menu
            id="assignRoleMenu"
            anchorEl={anchorEl}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            transformOrigin={{ vertical: 'top', horizontal: 'center' }}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'assignedRoleBtn',
            }}
          >
            {adminRoles.map(role => (
              <MenuItem
                key={role}
                sx={menuItemHoverStyle}
                onClick={() => {
                  // setAssignedRole(role);
                  handleClose();
                }}
              >
                {role}
              </MenuItem>
            ))}
          </Menu>
          <button type="button" className={classes.saveBtn}>
            <BigSaveIcon width="16px" height="16px" />
          </button> */}
        </aside>
      </div>
      <div className={classes.adminListTableContainer}>
        {loading ? (
          <Loader />
        ) : (
          <AdminListTable
            onSelectAllClick={handleSelectAllClick}
            numSelected={selected.length}
            rowCount={membersData.length}
            rows={membersData}
            selected={selected}
            setSelected={setSelected}
            openEditMemberModal={openEditMemberModal}
          />
        )}
      </div>
    </div>
  );
};
