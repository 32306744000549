import React from 'react';

export const FilterIcon = ({ color, active = false }) => {
  let fillColor = active ? '#1fe3ac' : '#19365e';
  if (color) fillColor = color;

  return (
    <svg
      data-name="Group 8343"
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
    >
      <path
        data-name="Path 9334"
        d="M21.889 5.222h-1.3a3.335 3.335 0 0 0-6.287 0H4.111a1.111 1.111 0 0 0 0 2.222H14.3a3.335 3.335 0 0 0 6.287 0h1.3a1.111 1.111 0 0 0 0-2.222zm-4.445 2.222a1.111 1.111 0 1 0-1.111-1.111 1.111 1.111 0 0 0 1.111 1.111zM3 13a1.111 1.111 0 0 1 1.111-1.111h1.3a3.335 3.335 0 0 1 6.287 0h10.19a1.111 1.111 0 0 1 0 2.222H11.7a3.335 3.335 0 0 1-6.287 0h-1.3A1.111 1.111 0 0 1 3 13zm5.556 1.111A1.111 1.111 0 1 0 7.444 13a1.111 1.111 0 0 0 1.112 1.111zm-4.445 4.445a1.111 1.111 0 0 0 0 2.222H14.3a3.335 3.335 0 0 0 6.287 0h1.3a1.111 1.111 0 0 0 0-2.222h-1.3a3.335 3.335 0 0 0-6.287 0zm14.444 1.111a1.111 1.111 0 1 1-1.111-1.111 1.111 1.111 0 0 1 1.112 1.111z"
        transform="translate(-3 -3)"
        style={{ fill: fillColor, fillRule: 'evenodd' }}
      />
    </svg>
  );
};
