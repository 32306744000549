import React from 'react';

export const EUR = () => (
  <svg
    data-name="Group 8542"
    xmlns="http://www.w3.org/2000/svg"
    width="48"
    height="47.999"
    viewBox="0 0 48 47.999"
  >
    <path
      data-name="Path 9680"
      d="M7320 6432a24 24 0 1 0-24-24 24 24 0 0 0 24 24z"
      transform="translate(-7296.001 -6384.001)"
      style={{ fill: '#0052b4' }}
    />
    <path
      data-name="Path 9681"
      d="m7519.989 6484.17.731 2.251h2.367l-1.914 1.392.731 2.25-1.915-1.392-1.915 1.392.732-2.25-1.916-1.392h2.367z"
      transform="translate(-7495.988 -6474.696)"
      style={{ fill: '#ffda44' }}
    />
    <path
      data-name="Path 9682"
      d="m7435.628 6523.629 2.108 1.074 1.674-1.673-.37 2.337 2.108 1.074-2.337.371-.371 2.337-1.074-2.108-2.337.37 1.672-1.674z"
      transform="translate(-7421.876 -6509.875)"
      style={{ fill: '#ffda44' }}
    />
    <path
      data-name="Path 9683"
      d="m7396.171 6607.988 2.25-.73v-2.368l1.392 1.916 2.251-.731-1.392 1.914 1.392 1.915-2.251-.731-1.392 1.914v-2.366z"
      transform="translate(-7386.698 -6583.988)"
      style={{ fill: '#ffda44' }}
    />
    <path
      data-name="Path 9684"
      d="m7435.628 6693.141 1.074-2.108-1.672-1.674 2.337.371 1.074-2.109.371 2.337 2.337.371-2.108 1.073.37 2.338-1.674-1.673z"
      transform="translate(-7421.876 -6658.894)"
      style={{ fill: '#ffda44' }}
    />
    <path
      data-name="Path 9685"
      d="m7519.989 6734.943-.731-2.251h-2.367l1.916-1.392-.732-2.25 1.915 1.39 1.915-1.39-.731 2.25 1.914 1.392h-2.367z"
      transform="translate(-7495.988 -6696.417)"
      style={{ fill: '#ffda44' }}
    />
    <path
      data-name="Path 9686"
      d="m7605.141 6693.141-2.108-1.074-1.674 1.674.371-2.339-2.108-1.073 2.337-.371.371-2.337 1.073 2.109 2.338-.371-1.672 1.674z"
      transform="translate(-7570.895 -6658.894)"
      style={{ fill: '#ffda44' }}
    />
    <path
      data-name="Path 9687"
      d="m7646.935 6607.988-2.25.731v2.366l-1.393-1.914-2.25.731 1.392-1.915-1.392-1.914 2.25.731 1.393-1.916v2.368z"
      transform="translate(-7608.409 -6583.988)"
      style={{ fill: '#ffda44' }}
    />
    <path
      data-name="Path 9688"
      d="m7605.141 6523.629-1.074 2.108 1.673 1.674-2.339-.37-1.073 2.108-.371-2.337-2.337-.371 2.108-1.074-.371-2.337 1.674 1.673z"
      transform="translate(-7570.895 -6509.875)"
      style={{ fill: '#ffda44' }}
    />
  </svg>
);
