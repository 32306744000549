import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
  walletTemplate: {
    width: '200px !important',
    height: '136px',
    border: 'none',
    borderRadius: '16px',
    boxShadow: '0 0 8px 0 rgba(0, 0, 0, 0.16)',
  },
  walletSelected: {
    border: '1px solid #19365e',
  },
  topRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  walletDisableEnable: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '8px',
    marginRight: '16px',
    '& input[type=checkbox]': {
      height: '16px',
      width: '16px',
      cursor: 'pointer',
    },
    '& label': {
      fontSize: '14px',
      fontWeight: '500',
    },
  },
  label: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '8px',
    marginBottom: '24px',
  },
  carouselContainer: {
    width: "100%",
    padding: "5px 0",
    display: 'flex',
    overflow: "hidden",
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    '& .slick-slide': {
      margin: '0 27.4px',
      overFlow: 'hidden',
    },
    '& .slick-slide > div': {
      display: 'grid',
      placeItems: 'center',
    },
    '& .slick-list': {
      margin: '0 -5px',
      padding: '8px',
    },
    '& .slick-slider': {
      overFlow: 'hidden',
    },
    '& .slick-prev': {
      left: '-20px',
      zIndex: '1',
    },
    '& .slick-next': {
      right: '-20px',
      zIndex: '1',
    },
    '& .slick-track': {
      display: 'flex',
      width: '750px !important',
    },
  },
  singleWalletDisplay: {
    display: 'flex',
    flexDirection: 'row',
    gap: '16px',
    alignItems: 'center',
    justifyContent: 'center',
  },
  disableWalletBtn: {
    width: '120px',
    height: '32px',
    borderRadius: '16px',
    backgroundColor: '#c6ffef',
    fontWeight: '500',
    fontSize: '14px',
    textAlign: 'center',
    paddingTop: '8px',
    cursor: 'pointer',
  },
  walletBackground: {
    margin: '8px',
    borderRadius: '16px',
    width: '184px',
    height: '88px',
    padding: '8px',
  },
  disabledWallet: {
    color: '#a5a6ae',
  },
  enabledCountry: {
    width: '20px',
    height: '20px',
    borderRadius: '20px',
    backgroundColor: 'white',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  disabledCountry: {
    opacity: '0.5',
  },
  accountNumberRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: '12px',
  },
  ibanRow: {
    padding: '8px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  clipBoardIcon: {
    cursor: 'pointer',
  },
}));
