import React from 'react';

export const SearchIcon = ({ width = '16', height = '16' }) => (
  <svg
    data-name="Group 8342"
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 16 16"
  >
    <defs>
      <clipPath id="8qgywviyla">
        <path data-name="Rectangle 16551" style={{ fill: '#b5b5b5' }} d="M0 0h16v16H0z" />
      </clipPath>
    </defs>
    <g data-name="Group 6275" style={{ clipPath: 'url(#8qgywviyla)' }}>
      <path
        data-name="Path 9184"
        d="M15.631 14.677q-2.067-2.062-4.131-4.129c-.059-.059-.115-.121-.178-.175s-.051-.088-.009-.144c.1-.136.209-.272.3-.416a6.3 6.3 0 0 0 .987-2.606 6.4 6.4 0 0 0 .007-1.741A6.369 6.369 0 0 0 9.3.739 6.237 6.237 0 0 0 5.733.028a6.141 6.141 0 0 0-2.445.757 6.283 6.283 0 0 0-2.7 2.9 6.213 6.213 0 0 0-.511 3.632 6.091 6.091 0 0 0 .9 2.38 6.322 6.322 0 0 0 3.069 2.535 6.166 6.166 0 0 0 3.334.344 6.1 6.1 0 0 0 2.8-1.229c.1-.077.151-.072.239.017q2.094 2.1 4.194 4.2a1.959 1.959 0 0 0 .538.432h.312a.8.8 0 0 0 .531-.5v-.312a1.373 1.373 0 0 0-.365-.5M6.34 11.32a4.99 4.99 0 1 1 4.983-5 5 5 0 0 1-4.983 5"
        transform="translate(.002 .007)"
        style={{ fill: '#b5b5b5' }}
      />
    </g>
  </svg>
);
