import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(theme => ({
  tableRow: {
    background: theme.palette.white,
    paddingBottom: '19px',
    borderBottom: '1px solid #aeabab',
    color: theme.palette.icons.nav,
    whiteSpace: 'nowrap',
    padding: '1.2rem',
    fontSize: '16px',
    position: 'sticky',
    top: '0',
    zIndex: 3,
  },
  tableCell: {
    backgroundColor: 'white',
    fontSize: '14px',
    color: theme.palette.icons.nav,
  },
  nameTableCell: {
    fontSize: '14px',
    color: theme.palette.icons.nav,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '16px',
    paddingLeft: '40px',
    '& input[type="checkbox"]': {
      width: '24px',
      height: '24px',
      borderRadius: '16px',
      border: '1px solid #e6e6e6',
      cursor: 'pointer',
    },
  },
}));
