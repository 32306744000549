import { CircularProgress, FormControl, TextField } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import React, { useEffect, useState } from 'react';

import { useGraphList } from '../../../../hooks';
import { getEntityLabel } from '../../../../utils';
import { useStyles } from './form-entity-select-field.hooks';
import {
  ALL_NONE_OPTION,
  filterOptions,
  getSelectedOptions,
} from './form-entity-select-field.utils';

export const FormEntitySelectField = ({
  errors,
  entityFieldName,
  field,
  multiple = false,
  register,
  rules,
  setValue,
  watch,
}) => {
  const { label, required, relatedEntity } = field;

  const classes = useStyles();

  const [open, setOpen] = useState(false);
  const { data, loading } = useGraphList({ entity: relatedEntity, getAll: true, sorted: true });

  useEffect(() => {
    register(entityFieldName, rules);
  }, []);

  const handleAutocompleteChange = (event, value) => {
    if (!multiple) {
      return setValue(entityFieldName, value ? value.id : null);
    }

    const idsValue = value.map(itemSelected => itemSelected.id).filter(id => !!id);

    return setValue(entityFieldName, idsValue && idsValue.length ? idsValue : null);
  };
  const value = watch(entityFieldName, null);
  const autocompleteOptions = required || multiple ? data : [ALL_NONE_OPTION, ...data];
  const selectedOption = getSelectedOptions(autocompleteOptions, value, multiple) || [];

  const isOptionEqualToValueHandeler = (option, value) =>
    option && value
      ? option.id === value.id || (Array.isArray(value) && value.length === 0)
      : false;

  return (
    <FormControl className={classes.formControl} variant="outlined">
      <Autocomplete
        filterOptions={filterOptions}
        filterSelectedOptions={multiple}
        getOptionLabel={getEntityLabel}
        isOptionEqualToValue={(option, value) => isOptionEqualToValueHandeler(option, value)}
        id="asynchronous-demo"
        loading={loading}
        multiple={multiple}
        onChange={handleAutocompleteChange}
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
        open={open}
        options={autocompleteOptions}
        renderInput={params => (
          <TextField
            {...params}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {loading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
            error={!!errors}
            fullWidth
            label={label}
            focused
          />
        )}
        value={selectedOption}
      />
    </FormControl>
  );
};
