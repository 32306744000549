import React from 'react';

export const ThreeDots = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="4.001" height="20" viewBox="0 0 4.001 20">
    <path
      data-name="Union 912"
      d="M13127-1188a2 2 0 0 1 2-2 2 2 0 0 1 2 2 2 2 0 0 1-2 2 2 2 0 0 1-2-2zm0-8a2 2 0 0 1 2-2 2 2 0 0 1 2 2 2 2 0 0 1-2 2 2 2 0 0 1-2-2zm0-8a2 2 0 0 1 2-2 2 2 0 0 1 2 2 2 2 0 0 1-2 2 2 2 0 0 1-2-2z"
      transform="translate(-13127 1205.999)"
      style={{ fill: '#19365e' }}
    />
  </svg>
);
