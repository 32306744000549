import React from 'react';

export const SideRectangle = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="8" height="32" viewBox="0 0 8 32">
    <path
      data-name="Rectangle 18088"
      d="M0 0a8 8 0 0 1 8 8v16a8 8 0 0 1-8 8V0z"
      style={{ fill: '#1fe3ac' }}
    />
  </svg>
);
