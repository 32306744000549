import React, { useEffect, useState } from 'react';

import { DottedLineIcon } from '../../../components/icons';
import { useStyles } from '../../../views/generic-list/components/modal/index';

import { graphqlApiDecorator } from 'decorators';
import { mutations, queries } from '../../../graphql';
import { Loader } from 'components/loader';

export const LimitationFilterModal = ({ onClose, data }) => {
  const classes = useStyles();
  const [dateValue, setDateValue] = useState(null);
  const [defultDaily, setDefultDaily] = useState('');
  const [defultMonthly, setDefultMonthly] = useState('');
  const [defultPertransaction, setDefultPertransaction] = useState('');
  const [disableBtn, setDisableBtn] = useState(false);
  const [btnTitle, setBtnTitle] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleClick = e => {
    e.preventDefault();
    onClose();
  };

  const handleReset = input => {
    setLimitation(data.id, input);
  };

  const fetchLimitation = async id => {
    const queryTitle = 'getUserLimits';
    try {
      setLoading(true);
      const result = await graphqlApiDecorator(queries, queryTitle, { userId: id });
      if (result !== null) {
        setDefultDaily(result.dailyLimit);
        setDefultMonthly(result.monthlyLimit);
        setDefultPertransaction(result.perTransactionLimit);
        setLoading(false);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const fetchsWaiveUntil = async id => {
    const queryTitle = 'getUserLimitWaiver';

    try {
      const result = await graphqlApiDecorator(queries, queryTitle, { userId: id });
      if (result !== null) {
        const dateTimeString = result;
        const date = new Date(dateTimeString);
        const formattedDate = date.toISOString().split('T')[0];

        setBtnTitle(true);
        setDisableBtn(true);
        setDateValue(formattedDate);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchsWaiveUntil(data.id);
  }, []);

  const sanitizeLimit = (limit, resetType) => {
    if (resetType === 'Monthly' || resetType === 'Daily' || resetType === 'Pertransaction') {
      return null;
    }

    if (limit && typeof limit === 'string' && limit.includes(',')) {
      return limit.replace(/,/g, '');
    }

    return limit;
  };

  const setLimitation = async (id, reset) => {
    const mutationTitle = 'setUserLimits';

    const input = {
      monthlyLimit: sanitizeLimit(defultMonthly, reset),
      dailyLimit: sanitizeLimit(defultDaily, reset),
      perTransactionLimit: sanitizeLimit(defultPertransaction, reset),
    };
    
    try {
      setLoading(true);
      const result = await graphqlApiDecorator(mutations, mutationTitle, {
        userId: id,
        userLimitsInput: input,
      });
      if (result !== null) {
        fetchLimitation(data.id);
        setLoading(false);
        if (!reset) {
          onClose();
        }
      }
      if (!result.success) throw new Error('Error in sending app notification');
    } catch (err) {
      console.log(err);
    }
  };

  const setWaiveUntil = async (id, date) => {
    const mutationTitle = 'setUserLimitWaiver';

    const dateObject = new Date(date);
    var formattedDateWithTime = dateObject.toISOString();

    if (btnTitle) {
      formattedDateWithTime = null;
      setDateValue('');
      setBtnTitle(false);
      setDisableBtn(false);
    }

    try {
      setLoading(true);
      const result = await graphqlApiDecorator(mutations, mutationTitle, {
        userId: id,
        waiveUntil: formattedDateWithTime,
      });
      if (result.success) {
        fetchsWaiveUntil(data.id);
        setLoading(false);
      }
      if (!result.success) throw new Error('Error in sending app notification');
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchLimitation(data.id);
  }, []);

  const handleSubmit = e => {
    e.preventDefault();

    setLimitation(data.id);
  };

  function formatNumber(input) {
    let number = input.replace(/\D/g, '');
    number = number.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

    return number;
  }

  return (
    <>
      <div className={classes.transactionFilterModalContainer}>
        <div className={classes.modalTitleContainer}>User's Transaction Limits</div>
        <form onSubmit={e => handleSubmit(e)}>
          <DottedLineIcon />

          <div className={classes.filterRowContainer}>
            <div className="transactionTilte">Daily Limit</div>
            <div className={classes.transactionInputContainer}>
              <input
                className={classes.inputContainer}
                onChange={event => {
                  setDefultDaily(formatNumber(event.target.value));
                }}
                type="text"
                value={defultDaily.toLocaleString('en-US', { minimumFractionDigits: 2 })}
              />
            </div>
            <div
              onClick={() => {
                handleReset('Daily');
              }}
              className={loading ? classes.disableResetBtn : classes.resetBtn}
            >
              <a>Reset to defult</a>
            </div>
          </div>

          <DottedLineIcon />

          <div className={classes.filterRowContainer}>
            <div className="transactionTilte">Monthly Limit</div>
            <div className={classes.transactionInputContainer}>
              <input
                className={classes.inputContainer}
                onChange={event => {
                  setDefultMonthly(formatNumber(event.target.value));
                }}
                type="text"
                value={defultMonthly.toLocaleString('en-US', { minimumFractionDigits: 2 })}
              />
            </div>
            <div
              onClick={() => {
                handleReset('Monthly');
              }}
              className={loading ? classes.disableResetBtn : classes.resetBtn}
            >
              <a>Reset to defult</a>
            </div>
          </div>

          <DottedLineIcon />

          <div style={{ marginBottom: '30px' }} className={classes.filterRowContainer}>
            <div className="transactionTilte">Per-Transaction Limit</div>
            <div className={classes.transactionInputContainer}>
              <input
                className={classes.inputContainer}
                onChange={event => {
                  setDefultPertransaction(formatNumber(event.target.value));
                }}
                type="text"
                value={defultPertransaction.toLocaleString('en-US', { minimumFractionDigits: 2 })}
              />
            </div>
            <div
              onClick={() => {
                handleReset('Pertransaction');
              }}
              className={loading ? classes.disableResetBtn : classes.resetBtn}
            >
              <a>Reset to defult</a>
            </div>
          </div>

          <DottedLineIcon />
          <DottedLineIcon />

          <div style={{ marginTop: '40px' }} className={classes.filterRowContainer}>
            <div className="transactionTilte">Remove Limits Until</div>
            <div className={classes.transactionInputContainer}>
              {!btnTitle ? (
                <input
                  className={classes.inputContainer}
                  onChange={event => {
                    setDateValue(event.target.value);
                  }}
                  type="date"
                  disabled={disableBtn}
                  value={dateValue}
                />
              ) : (
                <p>{dateValue}</p>
              )}
            </div>
            <div
              onClick={() => {
                setWaiveUntil(data.id, dateValue);
              }}
              className={loading ? classes.disableResetBtn : classes.resetBtn}
            >
              <a>{btnTitle ? 'Enable Limits' : 'Submit'}</a>
            </div>
          </div>

          <div className={classes.btnBox}>
            <button type="submit" onClick={e => handleClick(e)} className={classes.cancelBtn}>
              <p className={classes.filterItLabel}>Cancel</p>
            </button>
            <button
              type="submit"
              className={`${loading && classes.filterItBtnDisabled} ${classes.filterItBtn}`}
            >
              <p className={classes.filterItLabel}>
                {loading ? <Loader width="70px" /> : 'Submit'}
              </p>
            </button>
          </div>
          <div />
        </form>
      </div>
    </>
  );
};
