import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
  blockContent: {
    display: 'flex',
    marginTop: '2rem',
  },
  blockTitle: {
    fontSize: '1.6rem',
    fontWeight: 700,
    flexBasis: '10rem',
  },
  blockValue: {
    flex: 1,
  },
}));
