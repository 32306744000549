import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';
import React, { useState } from 'react';

import { useStyles } from './accordion.hooks';

export const AccordionCard = ({ id, title, colorIndicator, labelConfig, children }) => {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(false);
  const handleExpanded = () => {
    setExpanded(prevState => !prevState);
  };

  return (
    <Accordion
      className={classes.card}
      TransitionProps={{ unmountOnExit: true }}
      expanded={expanded}
      onChange={handleExpanded}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel-content"
        id={id}
        className={classes.cardHeader}
      >
        {colorIndicator && (
          <div className={classes.colorBox} style={{ backgroundColor: colorIndicator }} />
        )}
        <Typography>{title}</Typography>
        {labelConfig && <div className={classes[labelConfig.class]}>{labelConfig.title}</div>}
      </AccordionSummary>
      <AccordionDetails>{React.cloneElement(children, { onClose: setExpanded })}</AccordionDetails>
    </Accordion>
  );
};
