import React from 'react';

export const TemplateIcon = ({ active = true }) => {
  const fillColor = active ? '#19365e' : '#a5a6ae';

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24.001" viewBox="0 0 24 24.001">
      <g data-name="Group 8843">
        <path
          data-name="Union 535"
          d="M-951.718-5148a1.283 1.283 0 0 1-1.282-1.284v-21.434a1.283 1.283 0 0 1 1.282-1.284h21.434a1.284 1.284 0 0 1 1.284 1.284v21.434a1.284 1.284 0 0 1-1.284 1.284zm.218-21.218v18.435a1.283 1.283 0 0 0 1.284 1.283h18.433a1.282 1.282 0 0 0 1.283-1.283v-18.435a1.282 1.282 0 0 0-1.283-1.282h-18.433a1.283 1.283 0 0 0-1.284 1.283zm14.406 17.087a.856.856 0 0 1-.855-.855.856.856 0 0 1 .855-.856h1.707v-12.052h-1.523a.855.855 0 0 1-.854-.856.855.855 0 0 1 .854-.856h2.379a.856.856 0 0 1 .855.856v13.765a.856.856 0 0 1-.855.855zm-10.716 0a.856.856 0 0 1-.855-.856v-13.763a.856.856 0 0 1 .855-.856h2.562a.856.856 0 0 1 .856.856.857.857 0 0 1-.856.856h-1.706v12.052h1.522a.855.855 0 0 1 .856.854.856.856 0 0 1-.856.856zm5.409 0a.856.856 0 0 1-.855-.856.856.856 0 0 1 .855-.855h2.461a.856.856 0 0 1 .856.855.856.856 0 0 1-.856.856zm0-13.763a.857.857 0 0 1-.855-.856.856.856 0 0 1 .855-.856h2.461a.856.856 0 0 1 .856.856.857.857 0 0 1-.856.856z"
          transform="translate(953 5172)"
          style={{ fill: fillColor }}
        />
      </g>
    </svg>
  );
};
