import React from 'react';

export const ArrowRightIcon = ({ width = '5.207', height = '9.414', color = '#19365e' }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 5.207 9.414">
    <path
      data-name="Path 19587"
      d="m1768.959 96.345 4 4 4-4"
      transform="rotate(-90 841.015 936.652)"
      style={{ fill: 'none', stroke: color, strokeLinecap: 'round', strokeLinejoin: 'round' }}
    />
  </svg>
);
