import React from 'react';

export const DefaultAvatar = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="56" height="56" viewBox="0 0 56 56">
    <g data-name="Group 10030" transform="translate(-560 -112)">
      <circle
        data-name="Ellipse 1830"
        cx="28"
        cy="28"
        r="28"
        transform="translate(560 112)"
        style={{ fill: '#f2f2f4' }}
      />
      <g data-name="Group 6274">
        <path
          data-name="Union 889"
          d="M775-2259a28 28 0 0 1 28-28 28 28 0 0 1 28 28 28 28 0 0 1-28 28 28 28 0 0 1-28-28zm17.686-24.418a26.4 26.4 0 0 0-8.424 5.68 26.393 26.393 0 0 0-5.68 8.424A26.328 26.328 0 0 0 776.5-2259a26.333 26.333 0 0 0 2.082 10.315 26.4 26.4 0 0 0 5.68 8.423 26.4 26.4 0 0 0 8.424 5.68A26.331 26.331 0 0 0 803-2232.5a26.331 26.331 0 0 0 10.315-2.083 26.413 26.413 0 0 0 8.424-5.68 26.4 26.4 0 0 0 5.679-8.423A26.334 26.334 0 0 0 829.5-2259a26.329 26.329 0 0 0-2.083-10.314 26.39 26.39 0 0 0-5.679-8.424 26.414 26.414 0 0 0-8.424-5.68A26.33 26.33 0 0 0 803-2285.5a26.33 26.33 0 0 0-10.314 2.082zm8.723 40.055a7.365 7.365 0 0 1-1.388-.094 25.267 25.267 0 0 1-3.721-.543c-2.711-.531-4.47-1.591-5.228-3.151a.048.048 0 0 0 0-.011 4.777 4.777 0 0 1-.456-2.036 4.674 4.674 0 0 1 .459-2.023.074.074 0 0 1 .009-.016c.958-1.918 3.36-2.773 5.206-3.153a27.422 27.422 0 0 1 3.8-.535 40.064 40.064 0 0 1 7 0 27.657 27.657 0 0 1 3.794.535c2.727.561 4.482 1.628 5.218 3.173a4.711 4.711 0 0 1 0 4.056c-.743 1.561-2.5 2.624-5.216 3.16a25.8 25.8 0 0 1-3.8.548c-1.156.1-2.33.148-3.487.148a36.337 36.337 0 0 1-2.19-.058zm-1.13-9.19h-.022a24.892 24.892 0 0 0-3.479.49h-.01c-1.822.374-3.149 1.075-3.557 1.878a2.3 2.3 0 0 0-.221.981 2.379 2.379 0 0 0 .226 1.013c.407.827 1.669 1.486 3.556 1.852l.031.007a22.882 22.882 0 0 0 3.452.5 1.312 1.312 0 0 1 .153.02 5.287 5.287 0 0 0 1.033.063h.064a39.515 39.515 0 0 0 5.393-.083h.02a23.365 23.365 0 0 0 3.469-.5.2.2 0 0 0 .029-.007c1.892-.371 3.15-1.028 3.541-1.849a2.331 2.331 0 0 0 0-2.011c-.392-.823-1.62-1.469-3.55-1.865h-.008a25.246 25.246 0 0 0-3.481-.49h-.022a38.107 38.107 0 0 0-6.618.002zm-5.31-14.414a8.633 8.633 0 0 1 8.623-8.623 8.633 8.633 0 0 1 8.624 8.623 8.632 8.632 0 0 1-8.624 8.623 8.632 8.632 0 0 1-8.624-8.623zm2.38 0a6.25 6.25 0 0 0 6.243 6.243 6.25 6.25 0 0 0 6.243-6.243 6.25 6.25 0 0 0-6.243-6.243 6.25 6.25 0 0 0-6.244 6.243z"
          transform="translate(-215 2399)"
          style={{ fill: '#a5a6ae' }}
        />
      </g>
    </g>
  </svg>
);
