import React from 'react';

export const UncheckedIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25">
    <rect
      data-name="Rectangle 19005"
      width="24"
      height="24"
      rx="4"
      transform="translate(.5 .5)"
      style={{ fill: 'none', stroke: '#b5b5b5' }}
    />
  </svg>
);
