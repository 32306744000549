import React from 'react';

export const PlusIcon = ({ active = true }) => {
  const fillColor = active ? '#19365e' : '#a5a6ae';

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24.001" height="24" viewBox="0 0 24.001 24">
      <path
        data-name="Union 544"
        d="M1386.283-6785a1.283 1.283 0 0 1-1.283-1.283v-21.434a1.283 1.283 0 0 1 1.283-1.283h21.434a1.283 1.283 0 0 1 1.283 1.283v21.434a1.283 1.283 0 0 1-1.283 1.283zm.219-21.218v18.435a1.283 1.283 0 0 0 1.282 1.284h18.433a1.283 1.283 0 0 0 1.283-1.284v-18.435a1.282 1.282 0 0 0-1.283-1.282h-18.433a1.282 1.282 0 0 0-1.284 1.282zm9.518 14.684v-4.616h-4.62a.85.85 0 0 1-.849-.851.849.849 0 0 1 .849-.849h4.615v-4.617a.851.851 0 0 1 .851-.85.849.849 0 0 1 .849.85v4.617h4.617a.849.849 0 0 1 .849.849.85.85 0 0 1-.849.851h-4.617v4.616a.849.849 0 0 1-.849.851.851.851 0 0 1-.846-.851z"
        transform="translate(-1384.999 6809)"
        style={{ fill: fillColor }}
      />
    </svg>
  );
};
