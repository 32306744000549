import React from 'react';

export const RefreshIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
    <g data-name="Group 10037">
      <g data-name="Ellipse 1831" style={{ stroke: '#1fe3ac', fill: 'none' }}>
        <circle cx="16" cy="16" r="16" style={{ stroke: 'none' }} />
        <circle cx="16" cy="16" r="15.5" style={{ fill: 'none' }} />
      </g>
      <path
        data-name="Icon ionic-ios-refresh"
        d="M19.254 12.518a.6.6 0 0 0-.6.544 6.491 6.491 0 1 1-6.637-6.993.156.156 0 0 1 .164.16V8.24a.641.641 0 0 0 .984.54l3.846-2.691a.639.639 0 0 0 0-1.084L13.172 2.35a.641.641 0 0 0-.984.54v1.827a.157.157 0 0 1-.156.16 7.644 7.644 0 1 0 7.817 8.284.594.594 0 0 0-.6-.644z"
        transform="translate(4.148 3.837)"
        style={{ fill: '#1fe3ac' }}
      />
    </g>
  </svg>
);
