// If overlap returns true
export const checkOverlap = (value, val) =>
  (Number(value.intervalIni) >= Number(val.intervalIni) &&
    Number(value.intervalIni) <= Number(val.intervalEnd)) ||
  (Number(value.intervalEnd) >= Number(val.intervalIni) &&
    Number(value.intervalEnd) <= Number(val.intervalEnd)) ||
  (Number(val.intervalIni) >= Number(value.intervalIni) &&
    Number(val.intervalIni) <= Number(value.intervalEnd)) ||
  (Number(val.intervalEnd) >= Number(value.intervalIni) &&
    Number(val.intervalEnd) <= Number(value.intervalEnd));
