/* eslint-disable react/jsx-no-useless-fragment */
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { Button, TextField } from '@mui/material';
// import ReCAPTCHA from 'react-google-recaptcha';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import React, { useRef, useState } from 'react';
import { useForm } from 'react-hook-form';

import { WarningModal } from '../../../components/modal/saved-warning-modal';
import { signIn } from '../../../services';
import { ForgotPasswordReset } from './forgotPassword/forgot-password';
import { useStyles } from './login-form.hooks';

export const LoginForm = ({ onLoginSuccess }) => {
  const classes = useStyles();
  const [error, setError] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [openWarningModal, setOpenWarningModal] = useState(false);
  const [showForgotPasswordComponent, setShowForgotPasswordComponent] = useState(false);
  // const [recaptchaValue, setRecaptchaValue] = useState(null);

  // const reRef = useRef(ReCAPTCHA);

  const handleClickShowPassword = () => setShowPassword(!showPassword);

  const visibilityIconStyle = { fontSize: 24, color: '#e6e6e6' };
  const textFieldStyle = { fontSize: '14px', color: '#19365e' };

  const closeModal = () => setOpenWarningModal(false);

  const {
    handleSubmit,
    register,
    formState: { errors },
    watch,
  } = useForm();

  // watch form for change username and password to disable login button
  const watchFields = watch(['username', 'password']);

  // this vareables use for disable login button
  // const isRecaptchaFilled = recaptchaValue !== null;
  const isFormFilled = watchFields.every(field => !!field);

  // const handleRecaptchaChange = value => {
  //   setRecaptchaValue(value);
  // };

  const handleSignIn = async values => {
    const { username, password } = values;
    setError('');

    // if (username && password && recaptchaValue) {
    //   const { success, data } = await signIn(username.toLowerCase(), password, recaptchaValue);

    if (username && password) {
      const { success, data } = await signIn(username.toLowerCase(), password);

      if (success) {
        onLoginSuccess(data);
      } else {
        setError(`${data}`);
        setOpenWarningModal(true);
      }
    }
  };

  return (
    <>
      {showForgotPasswordComponent ? (
        <ForgotPasswordReset />
      ) : (
        <form className={classes.form} onSubmit={handleSubmit(handleSignIn)}>
          <p>Email</p>
          <TextField
            {...register('username', { required: 'Required' })}
            className={classes.textField}
            error={!!errors.username}
            fullWidth
            type="text"
            InputProps={{
              style: textFieldStyle,
            }}
          />
          <p>Password</p>
          <TextField
            {...register('password', { required: 'Required' })}
            className={classes.textField}
            error={!!errors?.password}
            fullWidth
            type={showPassword ? 'text' : 'password'}
            InputProps={{
              style: textFieldStyle,
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                  >
                    {showPassword ? (
                      <VisibilityOffIcon sx={visibilityIconStyle} />
                    ) : (
                      <VisibilityIcon sx={visibilityIconStyle} />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <section
            className={classes.forgotPwContainer}
            onClick={() => setShowForgotPasswordComponent(true)}
          >
            <button type="button" className={classes.forgotPw}>
              <p>Forgot Password</p>
            </button>
          </section>
          {/* <ReCAPTCHA
            sitekey="6LdhQxcpAAAAAFBjvkb-9fxU0Vq8DQA1dyRF7wdj" // reCAPTCHA site key
            onChange={handleRecaptchaChange}
            ref={reRef}
          /> */}
          <WarningModal
            title="Error"
            open={openWarningModal}
            confirm={closeModal}
            message={error}
          />
          <Button
            className={classes.signInButton}
            color="primary"
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            // disabled={!isRecaptchaFilled || !isFormFilled}
            disabled={!isFormFilled}
          >
            Login
          </Button>
        </form>
      )}
    </>
  );
};
