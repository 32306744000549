import React from 'react';

export const THB = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48.002" viewBox="0 0 48 48.002">
    <g data-name="Group 8520">
      <path
        data-name="Path 10549"
        d="M11880 9168a24 24 0 1 0-24-24 24 24 0 0 0 24 24z"
        transform="translate(-11856.002 -9120)"
        style={{ fill: '#f0f0f0' }}
      />
      <path
        data-name="Path 10550"
        d="M11902.511 9286.96h-45.021a24.2 24.2 0 0 0 0 16.712h45.021a24.157 24.157 0 0 0 0-16.712z"
        transform="translate(-11856.002 -9271.316)"
        style={{ fill: '#0052b4' }}
      />
      <path
        data-name="Path 10551"
        d="M11936.209 9120a24.153 24.153 0 0 0-18.309 8.356h36.618a24.162 24.162 0 0 0-18.309-8.356z"
        transform="translate(-11912.207 -9120)"
        style={{ fill: '#d80027' }}
      />
      <path
        data-name="Path 10552"
        d="M11954.519 9542.959h-36.619a24.236 24.236 0 0 0 36.618 0z"
        transform="translate(-11912.207 -9503.312)"
        style={{ fill: '#d80027' }}
      />
    </g>
  </svg>
);
