import React from 'react';

export const SmallSaveicon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
    <g data-name="Group 9758" transform="translate(-480 -440)">
      <circle
        data-name="Ellipse 1515"
        cx="16"
        cy="16"
        r="16"
        transform="translate(480 440)"
        style={{ fill: '#c6ffef' }}
      />
      <path
        data-name="Path 19478"
        d="M15.777 4.245 11.755.223A.764.764 0 0 0 11.217 0H2.37A2.373 2.373 0 0 0 0 2.37v11.26A2.373 2.373 0 0 0 2.37 16h11.26A2.373 2.373 0 0 0 16 13.63V4.783a.761.761 0 0 0-.223-.539m-4.518 10.232H4.741v-4.91h6.519zm3.217-.846a.848.848 0 0 1-.846.846h-.846V8.8a.762.762 0 0 0-.762-.762H3.979a.762.762 0 0 0-.762.762v5.672H2.37a.848.848 0 0 1-.846-.846V2.37a.848.848 0 0 1 .846-.846h.846v2.9a.762.762 0 0 0 .762.762h6.434a.762.762 0 0 0 0-1.524H4.741V1.524H10.9L14.476 5.1z"
        transform="translate(488 448)"
        style={{ fill: '#19365e' }}
      />
    </g>
  </svg>
);
