import React, { useEffect, useState } from 'react';

import { graphqlApiDecorator } from '../../../../decorators';
import { mutations, queries } from '../../../../graphql';
import { resetUserPassword } from '../../../../services';
import { InfoInput } from '.';
import { SideRectangle, SmallSave } from './icons';
import { useStyles } from './info.hooks';
import { DeleteModal, FaileModal, SavedModal, WarningModal } from './modals';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { entitiesEnum } from 'constants';

/**
 * Sub-component of the CustomerDetailPage parent component. This renders the info that
 * the user has saved when creating an account with Kixy. This includes their address, phone no, email etc.
 * The component feature allows the admin to edit the info and save it to the user profile record.
 * @param {object} data the user profile data of type userProfile
 */
export const Info = ({ data, closedAccount }) => {
  const [country, setCountry] = useState(data.country || '');
  const [city, setCity] = useState(data.city || '');
  const [address, setAddress] = useState(data.address || '');
  const [dob, setDob] = useState(data.birthdate || '');
  const [postalCode, setPostalCode] = useState(data.postalCode || '');
  const [phoneCode, setPhoneCode] = useState(data.phoneCode || '');
  const [phoneNumber, setPhoneNo] = useState(data.phoneNumber || '');
  const [email, setEmail] = useState(data.email || '');
  const [showSavedModal, setShowSavedModal] = useState(false);
  const [showWarningModal, setShowWarningModal] = useState(false);
  const [showWarningModalCloseAccount, setShowWarningModalCloseAccount] = useState(false);
  const [showLastWarningModalCloseAccount, setShowLastWarningModalCloseAccount] = useState(false);
  const [message, setMessage] = useState('');
  const [header, setHeader] = useState('');
  const [content, setContent] = useState('');
  const classes = useStyles();
  const [signUpFinancialDetails, setSignUpFinancialDetails] = useState(
    JSON.parse(data.SignUpFinancialDetails) || ''
  );
  const [income, setIncome] = useState('');
  const [occupation, setOccupation] = useState('');
  const [occupationStatus, setOccupationStatus] = useState('');
  const [disableCloseBtn, setDisableCloseBtn] = useState(false);
  const [showSuccessCloseAccountModal, setShowSuccessCloseAccountModal] = useState(false);
  const [showFaileCloseAccountModal, setShowFaileCloseAccountModal] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    setCountry(data.country);
    setCity(data.city);
    setAddress(data.address);
    setDob(data.birthdate);
    setPostalCode(data.postalCode);
    setPhoneCode(data.phoneCode);
    setPhoneNo(data.phoneNumber);
    setEmail(data.email);
    setIncome(signUpFinancialDetails.income);
    setOccupation(signUpFinancialDetails.occupation);
    setOccupationStatus(signUpFinancialDetails.occupationStatus);
  }, [data]);

  const save = async event => {
    event.preventDefault();
    try {
      const mutation = 'updateUserProfile';
      const input = {
        id: data.id,
        country,
        city,
        email,
        address,
        postalCode,
        phoneCode,
        birthdate: dob,
        phoneNumber,
        SignUpFinancialDetails: JSON.stringify({ occupation, income, occupationStatus }),
      };
      const result = await graphqlApiDecorator(mutations, mutation, { input });
      if (result) {
        setHeader('Saved');
        setContent('User details saved');
        setShowSavedModal(true);
      }
    } catch (error) {
      setMessage(error.message);
      setShowWarningModal(true);
    }
  };

  const confirmedResetPw = async () => {
    setShowWarningModal(false);
    const result = await resetUserPassword(data.id);
    if (result) {
      setHeader('Code sent');
      setContent('Password code sent to user');
      setShowSavedModal(true);
    }
  };

  const confirmedResetPwCloseAccount = () => {
    setShowWarningModalCloseAccount(false);
    setShowLastWarningModalCloseAccount(true);
  };

  const lastconfirmedCloseAccount = async () => {
    const mutation = 'newCloseAccount';
    const input = { userId: data.id };
    try {
      closedAccount(true);
      const result = await graphqlApiDecorator(mutations, mutation, { input });
      if (result) {
        closedAccount(false);
        setShowLastWarningModalCloseAccount(false);
        if (result.success) {
          setShowSuccessCloseAccountModal(true);
          navigateUserProfile(data.id);
        } else {
          setShowFaileCloseAccountModal(true);
        }
      }
    } catch (error) {
      setShowLastWarningModalCloseAccount(true);
      closedAccount(false);
    }
  };

  const navigateUserProfile = userId => {
    console.log(userId);
    navigate(`/${entitiesEnum.USERPROFILE.toLowerCase()}/${userId}`);
    // the navigate() changes the URL but page does not change so need to reload

    window.location.reload();
  };

  const walletList = async () => {
    const getUserWallets = 'getUserWallets';
    try {
      const result = await graphqlApiDecorator(queries, getUserWallets, { id: data.id });

      if (result.length === 0) {
        setDisableCloseBtn(false);
      } else {
        result.map(wallet => {
          if (wallet.balance > 0) {
            setDisableCloseBtn(true);
          } else {
            setDisableCloseBtn(false);
          }
        });
      }
    } catch (error) {}
  };
  walletList();

  return (
    <div>
      <div className={classes.label}>
        <aside>
          <SideRectangle />
          <header>Info</header>
        </aside>
        <button type="submit" onClick={save}>
          <SmallSave />
        </button>
      </div>
      <main className={classes.infoContainer}>
        <section>
          <InfoInput
            id="Country"
            type="text"
            value={country}
            onChange={event => setCountry(event.target.value)}
          />
          <InfoInput
            id="City"
            type="text"
            value={city}
            onChange={event => setCity(event.target.value)}
          />
        </section>
        <section>
          <InfoInput
            id="Address"
            type="text"
            value={address}
            onChange={event => setAddress(event.target.value)}
            fullWidth
          />
        </section>
        <section>
          <InfoInput
            id="Date of Birth"
            type="date"
            value={dob}
            onChange={event => setDob(event.target.value)}
          />
          <InfoInput
            id="Postal Code"
            type="text"
            value={postalCode}
            onChange={event => setPostalCode(event.target.value)}
          />
        </section>
        <section>
          <InfoInput
            id="Phone Code"
            type="text"
            value={phoneCode}
            onChange={event => setPhoneCode(event.target.value)}
          />
          <InfoInput
            id="Phone Number"
            type="text"
            value={phoneNumber}
            onChange={event => setPhoneNo(event.target.value)}
          />
        </section>
        <section>
          <InfoInput
            id="Email"
            type="text"
            value={email}
            onChange={event => setEmail(event.target.value)}
            fullWidth
          />
        </section>
        <section>
          <InfoInput
            id="Occupation"
            type="text"
            value={occupation}
            onChange={event => setOccupation(event.target.value)}
            fullWidth
          />
        </section>
        <section>
          <InfoInput
            id="Income"
            type="text"
            value={income}
            onChange={event => setIncome(event.target.value)}
            fullWidth
          />
        </section>
        <section>
          <InfoInput
            id="Occupation Status"
            type="text"
            value={occupationStatus}
            onChange={event => setOccupationStatus(event.target.value)}
            fullWidth
          />
        </section>
        <section>
          <Button
            sx={{
              width: '100%',
              height: '32px',
              border: '1px solid #2BE4B0',
              color: '#093560',
              borderRadius: '10px',
              marginTop: '30px',
              textTransform: 'none',
            }}
            disabled={
              disableCloseBtn || data.accountStatus === 'closed' || data.accountStatus === 'created'
            }
            onClick={() => setShowWarningModalCloseAccount(true)}
          >
            <p>{data.accountStatus === 'closed' ? 'This account is closed' : 'Close Account'}</p>
          </Button>
        </section>
      </main>
      <SavedModal
        open={showSavedModal}
        onClose={() => setShowSavedModal(false)}
        header={header}
        content={content}
      />
      <SavedModal
        open={showSuccessCloseAccountModal}
        onClose={() => setShowSuccessCloseAccountModal(false)}
        header={"Close Account"}
        content={"Account closed successfully"}
      />
      <FaileModal
        open={showFaileCloseAccountModal}
        onClose={() => setShowFaileCloseAccountModal(false)}
        header={"Close Account"}
        content={"Something went wrong"}
      />
      <WarningModal
        open={showWarningModal}
        onClose={() => setShowWarningModal(false)}
        confirm={confirmedResetPw}
        message={message}
      />
      <WarningModal
        open={showWarningModalCloseAccount}
        onClose={() => setShowWarningModalCloseAccount(false)}
        confirm={confirmedResetPwCloseAccount}
        message={`Are you sure about closing the ${data.name} ${data.lastName} account?`}
        btnText={'Yes'}
      />
      <DeleteModal
        open={showLastWarningModalCloseAccount}
        cancel={() => setShowLastWarningModalCloseAccount(false)}
        deleteIt={lastconfirmedCloseAccount}
        onClose={() => setShowLastWarningModalCloseAccount(false)}
        header={'Close Account'}
        content={`You can close ${data.name} ${data.lastName} account after 5 seconds.`}
        buttonEnabled={false}
      />
    </div>
  );
};
