import { matchSorter } from 'match-sorter';

const ALL_NONE_OPTION = { id: null, label: 'None/All' };

const filterOptions = (options, { inputValue }) => {
  const filteredOptions = matchSorter(options, inputValue, {
    threshold: matchSorter.rankings.WORD_STARTS_WITH,
    keys: ['code', 'id', 'name', 'nameLang.es'],
  });

  return options.some(o => o.id === null) ? [ALL_NONE_OPTION, ...filteredOptions] : filteredOptions;
};

const getSelectedOptions = (options, value, multiple) => {
  if (multiple) {
    return value && value.length
      ? options.filter(op => value.filter(v => v === op.id).length > 0)
      : [];
  }

  return value ? options.filter(op => op.id === value)[0] : null;
};

export { ALL_NONE_OPTION, filterOptions, getSelectedOptions };
