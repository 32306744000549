import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(theme => ({
  root: {},
  row: {
    height: '6.4rem',
    display: 'flex',
    alignItems: 'center',
    marginTop: '0.8rem',
    padding: '0 3.2rem 0 0',
    borderRadius: '0.4rem',
    '& h2': {
      fontSize: '3rem',
    },
  },
  spacer: {
    flexGrow: 1,
  },
  topBarButton: {
    minWidth: '20rem',
    fontSize: '1.2rem',
    padding: '1rem',
  },
  importButton: {
    marginRight: '0.8rem',
  },
  exportButton: {
    marginRight: '0.8rem',
  },
  iconTitleWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginRight: '2rem',
    '& .MuiIcon-root': {
      width: '2.4rem',
      height: '2.4rem',
    },
    '& .MuiSvgIcon-root': {
      width: '2.4rem',
      height: '2.4rem',
    },
    '& .MuiTypography-root': {
      color: theme.palette.primary.nav,
    },
  },
  icon: {
    color: theme.palette.primary.nav,
    marginRight: '2.6rem',
  },
}));
