import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(theme => ({
  tableCell: {
    border: 'none',
    backgroundColor: theme.palette.white,
  },
  input: {
    border: `0.1rem solid ${theme.palette.secondary.dark}`,
    borderRadius: '0.4rem',
    padding: '0.5rem',
    width: '100%',
  },
  columnLabel: {
    color: theme.palette.greyMid.dark,
    fontSize: '1.6rem',
    width: '20%',
    backgroundColor: theme.palette.white,
    border: 'none',
  },
  row: {
    borderBottom: `0.2rem solid ${theme.palette.greyMid.mid}`,
  },
  radio: {
    padding: '0.5rem',
    color: theme.palette.secondary.dark,
    '&.Mui-checked': {
      color: theme.palette.primary.main,
    },
  },
  options: {
    fontSize: '1.6rem',
    color: theme.palette.text.secondary,
  },
}));
