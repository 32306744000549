import React from 'react';

import { CurrencyPairsList } from '../../components';
import { GenericToolbar } from '../generic-list/components/new';
import { useStyles } from './pairs-view.hooks';

export const PairsView = ({ entity }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <GenericToolbar entity={entity} />
      <div className={classes.content}>
        <CurrencyPairsList entity={entity} />
      </div>
    </div>
  );
};
