import React from 'react';

export const CountriesIcon = ({ className, color }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 32 32">
    <g data-name="Group 7279">
      <path
        data-name="Path 16652"
        d="M32 16A16.009 16.009 0 0 0 16.332.017V0c-.044 0-.085.01-.128.011C16.135.01 16.069 0 16 0a16 16 0 0 0 0 32c.069 0 .135-.01.2-.011.044 0 .085.011.128.011v-.017A16.009 16.009 0 0 0 32 16m-1.784-.87h-4.525c-.16-5.106-1.7-9.5-4.049-12.22a14.274 14.274 0 0 1 8.574 12.22M15.13 1.928v13.2H8.38c.237-6.621 3.132-12.173 6.75-13.2m0 14.942v13.2c-3.618-1.028-6.512-6.581-6.75-13.2zm1.74 13.3v-13.3h7.08c-.244 6.824-3.308 12.525-7.08 13.3m0-15.035V1.834c3.772.771 6.837 6.472 7.08 13.3zM10.844 2.719C8.41 5.42 6.8 9.908 6.641 15.13H1.784a14.27 14.27 0 0 1 9.06-12.41m-9.06 14.15h4.857c.163 5.221 1.769 9.711 4.2 12.41a14.269 14.269 0 0 1-9.06-12.41m19.861 12.22c2.345-2.717 3.889-7.115 4.049-12.221h4.525a14.276 14.276 0 0 1-8.574 12.221"
        fill={color}
        className={className}
      />
    </g>
  </svg>
);
