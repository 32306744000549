import React from 'react';

export const ExchangeArrowIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="17" height="9.412" viewBox="0 0 17 9.412">
    <g data-name="Group 8304">
      <path
        data-name="Path 9336"
        d="M-4372 2634.5h15.022"
        transform="translate(4372.5 -2629.794)"
        style={{ fill: 'none', stroke: '#19365e', strokeLinecap: 'round' }}
      />
      <path
        data-name="Path 9337"
        d="m-4349.505 2630.655 4.519 3.986-4.519 4.014"
        transform="translate(4361.486 -2629.949)"
        style={{ strokeLinejoin: 'round', fill: 'none', stroke: '#19365e', strokeLinecap: 'round' }}
      />
    </g>
  </svg>
);
