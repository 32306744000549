import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(theme => ({
  form: {
    paddingLeft: '10rem',
    paddingRight: '10rem',
    paddingBottom: '12.5rem',
    flexBasis: '70rem',
    [theme.breakpoints.down('sm')]: {
      paddingLeft: '1.6rem',
      paddingRight: '1.6rem',
    },
    color: theme.palette.icons.nav,
  },
  title: {
    marginTop: '2.4rem',
    marginBottom: '4rem',
    color: theme.palette.icons.nav,
    fontFamily: theme.typography.default,
  },
  error: {
    marginTop: '1.6rem',
    color: theme.palette.error.main,
  },
  sugestion: {
    marginTop: '1.6rem',
  },
  textField: {
    marginBottom: '3.2rem',
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderRadius: '8px',
        border: '1px solid #e6e6e6',
      },
    },
  },
  signInButton: {
    margin: '1.6rem 0',
    fontSize: '18px',
    backgroundColor: '#c6ffef',
    color: theme.palette.icons.nav,
    borderRadius: '8px',
    boxShadow: 'none',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      opacity: '0.85',
    },
  },
  confirmPwText: {
    fontSize: '14px',
    color: theme.palette.icons.nav,
    fontFamily: theme.typography.default,
    marginBottom: '16px',
  },
}));
