import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(theme => ({
  tableCell: {
    fontSize: '18px',
    color: theme.palette.icons.nav,
    height: '88px',
  },
  withArrowIcon: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
}));
