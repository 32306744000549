import Checkbox from '@mui/material/Checkbox';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import React, { useState } from 'react';

import { CheckedIcon, UncheckedIcon } from '../../icons';
import { SavedModal } from '../../modal/saved-warning-modal';
import { SmallMenu } from './small-menu';

export const AdminListTable = ({
  onSelectAllClick,
  numSelected,
  rowCount,
  rows,
  selected,
  setSelected,
}) => {
  const [members, setMembers] = useState(rows);
  const [openDeletedModal, setOpenDeletedModal] = useState(false);
  const [openUpdatedModal, setOpenUpdatedModal] = useState(false);

  const tableHeaderStyle = {
    backgroundColor: 'white',
    fontSize: '18px',
    color: '#19365e',
    fontWeight: '500',
    borderBottom: '1px solid #aeabab',
  };

  const tableRowDataStyle = { fontSize: '18px', color: '#19365e', borderBottom: 'none' };

  const lastTableRowDataStyle = { ...tableRowDataStyle, borderRadius: '0 8px 8px 0' };

  const handleUserDeleted = data => {
    const newMembersList = rows.filter(item => item.userName !== data.userName);
    setMembers(newMembersList);
    setOpenDeletedModal(true);
  };

  const handleUserUpdated = data => {
    members.forEach(item => {
      // eslint-disable-next-line no-unused-expressions
      item.userName === data.userName && Object.assign(item, data);
    });
    setMembers(members);
    setOpenUpdatedModal(true);
  };

  return (
    <>
      <TableContainer style={{ overflowX: 'unset' }}>
        <Table stickyHeader>
          <TableHead>
            <TableRow sx={{ fontSize: '18px', color: '#19365e' }}>
              <TableCell
                padding="checkbox"
                style={{ backgroundColor: 'white', borderBottom: '1px solid #aeabab' }}
              >
                <Checkbox
                  color="primary"
                  sx={{ '& .MuiSvgIcon-root': { fontSize: 24 } }}
                  checked={rowCount > 0 && numSelected === rowCount}
                  icon={<UncheckedIcon />}
                  checkedIcon={<CheckedIcon />}
                  onChange={onSelectAllClick}
                  inputProps={{
                    'aria-label': 'select all members',
                  }}
                />
              </TableCell>
              <TableCell style={tableHeaderStyle}>
                <p>Team Member</p>
              </TableCell>
              <TableCell style={tableHeaderStyle}>Role</TableCell>
              {/* <TableCell style={tableHeaderStyle}>Last Seen</TableCell> */}
              <TableCell style={tableHeaderStyle}>Status</TableCell>
              <TableCell style={tableHeaderStyle}>More</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {members.map(member => {
              const isItemSelected = selected.indexOf(member.userName) !== -1;

              return (
                <TableRow
                  key={member.userName}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  selected={isItemSelected}
                >
                  <TableCell
                    padding="checkbox"
                    sx={{ borderBottom: 'none', borderRadius: '8px 0 0 8px' }}
                  >
                    <Checkbox
                      color="primary"
                      sx={{ '& .MuiSvgIcon-root': { fontSize: 24 } }}
                      checked={isItemSelected}
                      inputProps={{
                        'aria-labelledby': 'tableRow-checkbox',
                      }}
                      icon={<UncheckedIcon />}
                      checkedIcon={<CheckedIcon />}
                      onChange={() => {
                        const selectedIndex = selected.indexOf(member.userName);
                        let newSelected = [];

                        if (selectedIndex === -1) {
                          newSelected = newSelected.concat(selected, member.userName);
                        } else if (selectedIndex === 0) {
                          newSelected = newSelected.concat(selected.slice(1));
                        } else if (selectedIndex === selected.length - 1) {
                          newSelected = newSelected.concat(selected.slice(0, -1));
                        } else if (selectedIndex > 0) {
                          newSelected = newSelected.concat(
                            selected.slice(0, selectedIndex),
                            selected.slice(selectedIndex + 1)
                          );
                        }

                        setSelected(newSelected);
                      }}
                    />
                  </TableCell>
                  <TableCell component="th" scope="row" sx={tableRowDataStyle}>
                    {member.userName}
                  </TableCell>
                  <TableCell sx={tableRowDataStyle}>{member.roles?.join(',')}</TableCell>
                  {/* <TableCell sx={tableRowDataStyle}>{row.lastSeen}</TableCell> */}
                  <TableCell sx={lastTableRowDataStyle}>{member.userStatus}</TableCell>
                  <TableCell component="th" scope="row" sx={tableRowDataStyle}>
                    <SmallMenu
                      data={member}
                      handleUserDeleted={handleUserDeleted}
                      handleUserUpdated={handleUserUpdated}
                    />
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      {openDeletedModal ? (
        <SavedModal onClose={() => setOpenDeletedModal(false)} content="User is deleted" />
      ) : null}
      {openUpdatedModal ? (
        <SavedModal onClose={() => setOpenUpdatedModal(false)} content="User is updated" />
      ) : null}
    </>
  );
};
