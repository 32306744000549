import RefreshIcon from '@mui/icons-material/Refresh';
import { Paper, Typography } from '@mui/material';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';

import { awsConfig } from '../../config/aws.config';
import { useStyles } from './exchange-rate.hooks';

export const ExchangeRate = ({ className, onChange, style, watch, ...rest }) => {
  const classes = useStyles();
  const [rate, setRate] = useState(0);
  const [pair, setPair] = useState({ from: '', to: '' });

  const fetchRates = async ({ from, to }) => {
    try {
      if (!from || !to) return;
      const result = await fetch(
        `${awsConfig.executeApi}/exchange-rate?fromCurrency=${from}&toCurrency=${to}&fromAmount=1&onlyXe=true`
      );
      const { exchangeRate } = await result.json();
      setRate(exchangeRate);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('IMTCHLG ~ file: ExchangeRate.jsx ~ line 30 ~ fetchRates ~ error', error);
    }
  };

  useEffect(() => {
    fetchRates(pair);
  }, [pair]);

  useEffect(() => {
    const from = watch('pairFromId');
    const to = watch('pairToId');

    setPair({ from, to });
  }, [watch('pairFromId'), watch('pairToId')]);

  return (
    <Paper {...rest} className={clsx(classes.root, className)} style={style}>
      <Typography>CurrencyCloud current rate: {rate}</Typography>
      <RefreshIcon onClick={() => fetchRates(pair)} className={classes.icon} />
    </Paper>
  );
};
