import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import React from 'react';

import { WarningIcon } from '../icons';
import { useStyles } from './modal.hooks';

export const WarningModal = ({ open, onClose, confirm, message, btnText = null }) => {
  const classes = useStyles();

  return (
    <Modal open={open} onClose={onClose}>
      <Box>
        <div className={classes.modalBox}>
          <div className={classes.iconContainer}>
            <WarningIcon />
          </div>
          <header>Warning</header>
          <p>{message}</p>
          <section className={classes.cancelDelSection}>
            <div
              style={{
                color: btnText && '#fc3333',
              }}
              className={classes.cancel}
              onClick={confirm}
            >
              {btnText ?? 'OK'}
            </div>
            {onClose && (
              <div
                style={{
                  color: btnText && '#0069f8',
                }}
                className={classes.deleteIt}
                onClick={onClose}
              >
                Cancel
              </div>
            )}
          </section>
        </div>
      </Box>
    </Modal>
  );
};
