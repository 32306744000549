import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(theme => ({
  walletHeader: {
    display: 'flex',
  },
  walletHeaderCurrency: {
    color: '#323c47',
    marginRight: 'auto',
  },
  walletHeaderBalance: {
    color: theme.palette.greyMid.dark,
    fontWeight: 'normal',
    marginLeft: 'auto',
  },
  cardNoShadow: {
    boxShadow: 'none',
    marginTop: '1em',
    borderColor: theme.palette.greyMid.mid,
  },
  card: {
    height: '6rem',
    width: '24rem',
    border: `0.2rem solid ${theme.palette.greyMid.mid}`,
  },
  cardMain: {
    boxShadow: 'none',
    border: `0.2rem solid ${theme.palette.primary.main}`,
  },
  divider: {
    width: '90%',
    margin: 'auto',
    borderTop: `0.1rem solid ${theme.palette.greyMid.dark}`,
  },
  cardFooter: {
    display: 'flex',
    alignItems: 'center',
  },
  sendIcon: {
    width: '3rem',
    height: '3rem',
  },
  iconText: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: 'auto',
  },
  icon: {
    color: theme.palette.greyMid.dark,
    fontSize: '2rem',
  },
  subText: {
    cursor: 'pointer',
    fontFamily: theme.typography.default,
    color: theme.palette.greyMid.dark,
    fontSize: '1.4rem',
    marginLeft: 'auto',
    width: 'fit-content',
  },
}));
