import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
  root: {
    overflowY: 'hidden',
  },
  content: {
    padding: '24px 24px 2px 24px',
  },
}));
