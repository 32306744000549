import React from 'react';

export const AddAttachmentIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40">
    <g data-name="Group 8883">
      <g data-name="Group 8800">
        <rect
          data-name="Rectangle 17010"
          width="40"
          height="40"
          rx="20"
          style={{ fill: '#c6ffef' }}
        />
      </g>
      <path
        data-name="Icon material-attach-file"
        d="M21.74 7.416v13.606a4.733 4.733 0 0 1-9.465 0V6.233a2.958 2.958 0 1 1 5.916 0v12.423a1.183 1.183 0 1 1-2.366 0V7.416h-1.776v11.24a2.958 2.958 0 1 0 5.916 0V6.233a4.733 4.733 0 0 0-9.465 0v14.79a6.507 6.507 0 1 0 13.015 0V7.416z"
        transform="translate(2.993 6)"
        style={{ fill: '#23c3a4' }}
      />
    </g>
  </svg>
);
