import React, {useState} from 'react';

import { useStyles } from './internationalPayment.hook';
import Select from "@mui/material/Select";
import InputBase from "@mui/material/InputBase";
import MenuItem from "@mui/material/MenuItem";
import {ReturnCountryIcon} from "../../../icons/countries/ReturnCountryIcon";

export const SelectWithSearch = ({ onChange, options, value, Icon, title }) => {
    const classes = useStyles();
    const [search, setSearch] = useState("");

    const handleSearchChange = (event) => {
        setSearch(event.target.value);
    };

    return (
        <div>
            <p style={{ marginBottom: '10px' }}>{title}</p>
            <Select
                id="Country"
                value={value}
                className={classes.fontStyle}
                IconComponent={Icon}
                sx={{
                    borderWidth: '0',
                    width: '100%',
                    borderRadius: '8px',
                    textAlign: 'left',
                }}
                onChange={(e)=> {
                    setSearch("");
                    onChange(e)
                }}
                MenuProps={{
                    PaperProps: {
                        style: {
                            maxHeight: 400,
                        },
                    },
                }}
            >

                <InputBase
                    onFocus={e => e.stopPropagation()}
                    onKeyDown={e => e.stopPropagation()}
                    onChange={handleSearchChange}
                    value={search}
                    className={classes.inputBase}
                />
                {Object.values(options).filter(i=> !!i.iso2).filter(i=> i.name.toLowerCase().includes(search.toLowerCase())).map(opt => (
                    <MenuItem autoFocus={false}  className={classes.selectOption} value={opt.iso2}>
                        {/* eslint-disable-next-line jsx-a11y/alt-text */}
                        <ReturnCountryIcon opt={opt?.iso} />
                        <p>{opt.name}</p>
                    </MenuItem>
                ))}
            </Select>
        </div>

    );
};
