import React from 'react';

export const PairsSaveIcon = ({ active = true }) => {
  const fillColor = active ? '#19365e' : '#a5a6ae';

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24.001" viewBox="0 0 24 24.001">
      <path
        data-name="Path 20094"
        d="M17.572.266A.92.92 0 0 0 16.924 0H3.375A3.4 3.4 0 0 0 0 3.376v17.249A3.391 3.391 0 0 0 3.375 24h17.25A3.392 3.392 0 0 0 24 20.624V7.073a.912.912 0 0 0-.267-.646zm-.328 21.907H6.755v-8.027h10.492zm4.929-1.551a1.558 1.558 0 0 1-1.552 1.551h-1.553v-8.94a.915.915 0 0 0-.914-.914H5.837a.917.917 0 0 0-.909.914v8.942H3.375a1.558 1.558 0 0 1-1.549-1.551V3.376a1.558 1.558 0 0 1 1.549-1.551h1.554v4.7a.917.917 0 0 0 .91.913h9.854a.914.914 0 0 0 0-1.826H6.755V1.826h9.8l.036.037 5.588 5.587z"
        transform="translate(-.001)"
        style={{ fill: fillColor }}
      />
    </svg>
  );
};
