import AWS from 'aws-sdk';

import { awsConfig } from '../config/aws.config';
import { requestDecorator } from '../decorators';
import { getCredentials } from './auth.services';

// TO DO: use the aws-sdk S3 to get the profile pictures
const getFileURL = fileLocation => {
  const {
    amplify: { Storage },
  } = awsConfig;
  const baseURL = Storage.imagesCloudfront
    ? `${Storage.imagesCloudfront}/200`
    : `${Storage.bucket}.s3.${Storage.region}.amazonaws.com`;

  return `https://${baseURL}/${fileLocation}`;
};

const uploadFile = async (file, path) => {
  const {
    amplify: { Storage },
  } = awsConfig;
  const { region, bucket } = Storage;

  const credentials = await getCredentials();
  AWS.config.update({ credentials, region });

  return requestDecorator(
    new AWS.S3.ManagedUpload({
      params: {
        Bucket: bucket,
        Key: path,
        Body: file,
        ACL: 'public-read',
      },
    }).promise()
  );
};

const getProfilePictureURL = async fileName => {
  const {
    amplify: { Storage },
  } = awsConfig;
  const { region, bucket } = Storage;

  const params = {
    Bucket: bucket,
    Key: `protected/${fileName}/${fileName}.JPEG`,
    Expires: 3600,
  };
  const credentials = await getCredentials();

  AWS.config.update({ credentials, region });
  const s3 = new AWS.S3();

  const url = await s3.getSignedUrlPromise('getObject', params);

  return url;
};

export { getFileURL, getProfilePictureURL, uploadFile };
