import React from 'react';

export const RedirectLinkIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
    <g data-name="Group 8211" transform="translate(-1256 -264)">
      <circle
        data-name="Ellipse 1491"
        cx="16"
        cy="16"
        r="16"
        transform="translate(1256 264)"
        style={{ fill: '#c6ffef' }}
      />
      <path
        data-name="Union 893"
        d="M.191 11.962A.668.668 0 0 1 .2 11.02L10 1.36l-6.208.108A.667.667 0 0 1 3.763.134L11.562 0a.658.658 0 0 1 .475.186.668.668 0 0 1 .2.468l.136 7.8a.667.667 0 1 1-1.333.023l-.107-6.169-9.8 9.663a.67.67 0 0 1-.944-.007z"
        transform="translate(1265.333 273.92)"
        style={{ fill: '#19365e' }}
      />
    </g>
  </svg>
);
