import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(theme => ({
  menu: {
    width: '100%',
  },
  tab: {
    minWidth: 'unset',
    textTransform: 'capitalize',
    fontSize: '1.6rem',
    fontWeight: 'normal',
    opacity: 1,
    color: theme.palette.text.tertiary,
    '&.Mui-selected': {
      fontWeight: 'bold',
      color: theme.palette.primary.midLight,
    },
  },
  indicator: {
    backgroundColor: theme.palette.primary.midLight,
  },
}));
