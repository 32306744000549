import React from 'react';

export const SentMoneyIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="10.117" height="17.5" viewBox="0 0 10.117 17.5">
    <g data-name="Group 8294">
      <path
        data-name="Path 9336"
        d="M0 0h15.022"
        transform="rotate(-90 10.905 5.845)"
        style={{ fill: 'none', stroke: '#fc3333', strokeLinecap: 'round', strokeWidth: '1.5px' }}
      />
      <path
        data-name="Path 9337"
        d="m0 0 4.519 3.986L0 8"
        transform="rotate(-90 3.164 2.105)"
        style={{
          strokeLinejoin: 'round',
          fill: 'none',
          stroke: '#fc3333',
          strokeLinecap: 'round',
          strokeWidth: '1.5px',
        }}
      />
    </g>
  </svg>
);
