import React from 'react';

export const EditFeesIcon = ({ active = true, edit = false }) => {
  let fillColor = active ? '#23c3a4' : '#a5a6ae';

  if (edit) fillColor = '#19365e';

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="23.751"
      height="23.751"
      viewBox="0 0 23.751 23.751"
    >
      <path
        data-name="Union 509"
        d="M-949.339-5632.25a3.664 3.664 0 0 1-3.661-3.661v-14.351a3.64 3.64 0 0 1 1.07-2.589 3.64 3.64 0 0 1 2.591-1.072h7.235a.807.807 0 0 1 .538.2.806.806 0 0 1 .275.561 1.1 1.1 0 0 1 0 .122.816.816 0 0 1-.762.867.733.733 0 0 1-.114 0h-7.181a1.9 1.9 0 0 0-1.347.554 1.906 1.906 0 0 0-.564 1.358v14.249a1.916 1.916 0 0 0 1.918 1.92h14.254a1.9 1.9 0 0 0 1.346-.554 1.91 1.91 0 0 0 .565-1.367v-7.084a.878.878 0 0 1 .8-.945.879.879 0 0 1 .944.8.731.731 0 0 1 0 .158v7.18a3.518 3.518 0 0 1-3.37 3.654h-.187zm.9-4.537a1.318 1.318 0 0 1-.287-.833l1.04-5.218a.446.446 0 0 1 .243-.4l12.493-12.485a.875.875 0 0 1 .636-.273.878.878 0 0 1 .6.24l4.192 4.191a.864.864 0 0 1 .273.612.867.867 0 0 1-.24.626l-12.483 12.486a2.146 2.146 0 0 1-.466.285l-5.165 1.036h-.241a.817.817 0 0 1-.597-.266zm2.485-5.384-.664 3.54 3.5-.656.037-.007 11.553-11.552.088-.088-2.877-2.876z"
        transform="translate(953 5656)"
        style={{ fill: fillColor }}
      />
    </svg>
  );
};
