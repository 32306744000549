import React from 'react';

export const ActiveTickIcon = ({ active = true }) => {
  const fillColor = active ? '#1fe3ac' : '#a5a6ae';

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <g data-name="Group 8419" transform="translate(-11164 -619)">
        <circle
          data-name="Ellipse 1547"
          cx="12"
          cy="12"
          r="12"
          transform="translate(11164 619)"
          style={{ fill: fillColor }}
        />
        <path
          data-name="Path 19571"
          d="m-77.212 1869.134 3.078 3.321 6.022-6.5"
          transform="translate(11248.712 -1238.456)"
          style={{
            fill: 'none',
            stroke: '#fff',
            strokeLinecap: 'round',
            strokeLinejoin: 'round',
            strokeWidth: '1.5px',
          }}
        />
      </g>
    </svg>
  );
};
