import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import React, { useRef } from 'react';

import { InstagramIcon, KixySmileLogo, KixyTitle, LinkedInIcon, TwitterIcon } from '../../../icons';
import { useStyles } from './emailTemplateEdit.hooks';

export const EmailTemplateEdit = ({
  emailSubject,
  setEmailSubject,
  emailContent,
  setEmailContent,
  emailTitle,
  setEmailTitle,
  handleClickDone,
  messageTo,
  setMessageTo,
  closingMessage,
  setClosingMessage,
  from,
  setFrom,
}) => {
  const classes = useStyles();
  const subjectRef = useRef(null);
  const mainTitleRef = useRef(null);
  const addressRef = useRef(null);
  const contentRef = useRef(null);
  const fromNameRef = useRef(null);

  return (
    <Box sx={{ padding: '16px', overFlowY: 'scroll' }}>
      <section className={classes.emailSection}>
        <div className={classes.emailHeader}>
          <div style={{ width: '24px' }} />
          <KixyTitle />
          <KixySmileLogo width="25px" height="32px" />
        </div>
        <section className={classes.emailContent}>
          <input
            placeholder="Your Subject*"
            value={emailSubject}
            onChange={e => {
              setEmailSubject(e.target.value);
            }}
            className={classes.emailSubjectInput}
            ref={subjectRef}
            onKeyDown={e => {
              if (e.key === 'Enter') {
                e.preventDefault();
                mainTitleRef.current.focus();
              }
            }}
          />
          <input
            placeholder="Your Main Title"
            value={emailTitle}
            onChange={e => setEmailTitle(e.target.value)}
            className={classes.emailTitleInput}
            ref={mainTitleRef}
            onKeyDown={e => {
              if (e.key === 'Enter') {
                e.preventDefault();
                addressRef.current.focus();
              }
            }}
          />
          <input
            placeholder="Dear {{firstName}} {{lastName}}"
            value={messageTo}
            onChange={e => setMessageTo(e.target.value)}
            className={classes.messageToInput}
            ref={addressRef}
            onKeyDown={e => {
              if (e.key === 'Enter') {
                e.preventDefault();
                contentRef.current.focus();
              }
            }}
          />
          <textarea
            rows="8"
            cols="50"
            placeholder="Your main content*"
            value={emailContent}
            onChange={e => setEmailContent(e.target.value)}
            ref={contentRef}
          />
          <input
            placeholder="Kind Regards"
            value={closingMessage}
            onChange={e => setClosingMessage(e.target.value)}
            onKeyDown={e => {
              if (e.key === 'Enter') {
                e.preventDefault();
                fromNameRef.current.focus();
              }
            }}
          />
          <input
            placeholder="Kixy Team"
            value={from}
            onChange={e => setFrom(e.target.value)}
            ref={fromNameRef}
          />
        </section>
        <footer className={classes.emailFooter}>
          <section className={classes.footerIcons}>
            <InstagramIcon />
            <TwitterIcon />
            <LinkedInIcon />
          </section>
          <p style={{ fontWeight: 500 }}>&copy; 2023 Kixy LTD</p>
          <p style={{ fontWeight: 500 }}>www.kixy.com</p>
        </footer>
      </section>
      <div className={classes.btnContainer}>
        <Button className={classes.doneBtn} onClick={handleClickDone}>
          Done
        </Button>
      </div>
    </Box>
  );
};
