import React from 'react';
import { uid } from 'react-uid';

import { splitAndCapitalizeString } from '../../utils';
import { useStyles } from './requirements-columns.hooks';

export const RequirementsColumns = ({ reqList, buttonChange, currency, type }) => {
  const classes = useStyles();

  return (
    <>
      {reqList.map((item, index) => (
        <div key={uid(currency, index)} className={classes.requirementItem}>
          <div className={classes.fieldName}>
            <p>{`${splitAndCapitalizeString({ str: item.field })}: `}</p>
          </div>
          <div className={classes.radioButtonsContainer}>
            <div>
              <input
                type="radio"
                id="hidden"
                value="hidden"
                name={`${currency} ${type} ${item.field}`}
                checked={item.status === 'hidden'}
                onChange={event => buttonChange(event.target.value, item.field, currency)}
              />
              <label htmlFor="hidden">Hidden</label>
            </div>
            <div>
              <input
                type="radio"
                id="visible"
                value="visible"
                name={`${currency} ${type} ${item.field}`}
                checked={item.status === 'visible'}
                onChange={event => buttonChange(event.target.value, item.field, currency)}
              />
              <label htmlFor="visible">Visible</label>
            </div>
            <div>
              <input
                type="radio"
                id="mandatory"
                value="mandatory"
                name={`${currency} ${type} ${item.field}`}
                checked={item.status === 'mandatory'}
                onChange={event => buttonChange(event.target.value, item.field, currency)}
              />
              <label htmlFor="mandatory">Mandatory</label>
            </div>
          </div>
        </div>
      ))}
    </>
  );
};
