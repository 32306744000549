import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(theme => ({
  title: {
    color: theme.palette.primary.nav,
    fontWeight: 'bold',
    fontSize: '1.6rem',
    padding: '1rem',
  },
  value: {
    color: theme.palette.primary.nav,
    fontSize: '1.6rem',
    padding: '1rem',
    textAlign: 'right',
  },
}));
