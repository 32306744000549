import { makeStyles } from '@mui/styles';

import deleteModalBckgrnd from './icons/alertPopupBckgrnd.svg';

export const useStyles = makeStyles(theme => ({
  toolbar: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingLeft: '48px',
    paddingRight: '48px',
    alignItems: 'center',
    background: theme.palette.white,
    minHeight: '112px',
    borderRadius: '0 0 15px 15px',
    color: theme.palette.icons.nav,
    '& header': {
      fontFamily: theme.typography.default,
      fontWeight: 'bold',
      fontSize: '24px',
    },
    '& aside': {
      display: 'flex',
      flexDirection: 'row',
      gap: '64px',
    },
  },
  defaultRHSaside: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '8px',
    fontFamily: theme.typography.default,
    fontSize: '12px',
  },
  defaultRHSasideLabel: {
    '& p:first-child': {
      fontSize: '14px',
      fontWeight: '600',
    },
  },
  outlineContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '8px',
    width: '200px',
    height: '56px',
    borderRadius: '28px',
    border: '1px solid #1fe3ac',
    padding: '8px 16px 8px 8px',
    position: 'relative',
  },
  icon: {
    cursor: 'pointer',
  },
  iconDropdown: {
    cursor: 'pointer',
    position: 'absolute',
    right: '16px',
  },
  listDropdown: {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
    position: 'absolute',
    top: '66px',
    left: '0px',
    borderRadius: '8px',
    width: '200px',
    border: '1px solid #1fe3ac',
    zIndex: 1,
    background: 'white',
    padding: '12px 0',
    color: theme.palette.icons.nav,
  },
  listRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: '0 16px',
    cursor: 'pointer',
    '&:hover': {
      background: '#c6ffef',
    },
  },
  listRowAttachmentItem: {
    '&:hover': {
      background: 'white',
    },
  },
  listDropdownDownload: {
    margin: '0px !important',
    padding: '12px',
  },
  transactionDetailsContainer: {
    borderRadius: '0 16px 16px 16px',
    margin: '0px 24px 24px 24px',
    background: 'white',
    paddingTop: '17.4px',
    paddingBottom: '25px',
    width: '100%',
  },
  balanceLogsContainer: {
    borderRadius: '0 16px 16px 16px',
    margin: '0px 24px 24px 24px',
    background: 'white',
    paddingTop: '17.4px',
    paddingBottom: '5px',
    width: '100%',
    minWidth: '900px',
  },
  mainTransactionContainer: {
    marginTop: '24px',
    borderRadius: '0 16px 16px 16px',
    margin: '0px 24px 24px 24px',
    background: 'white',
    paddingTop: '17.4px',
    paddingBottom: '25px',
    minWidth: '900px',
  },
  defaultContainerHeader: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginRight: '16px',
    fontSize: '18px',
    fontWeight: 'bold',
    color: theme.palette.icons.nav,
    fontFamily: theme.typography.default,
  },
  headerSection: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '8px',
  },
  transactionDetailsContent: {
    fontSize: '13px',
    fontFamily: theme.typography.default,
    color: theme.palette.icons.nav,
    paddingLeft: '40px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },
  transactionDetailsContentOneSide: {
    display: 'flex',
    flexDirection: 'column',
    gap: '13px',
    paddingTop: '20px',
    width: '400px',
  },
  transactionDetailsContentLHS: {
    display: 'flex',
    flexDirection: 'column',
    gap: '13px',
    paddingTop: '40px',
    width: '49%',
    minWidth: '400px',
  },
  verticalLine: {
    borderLeft: '2px solid #f2f2f4',
  },
  transactionDetailsContentRHS: {
    display: 'flex',
    flexDirection: 'column',
    gap: '13px',
    paddingTop: '40px',
    width: '50%',
    minWidth: '400px',
    paddingLeft: '66.5px',
  },
  sectionRow: {
    display: 'flex',
    flexDirection: 'row',
  },
  doubleArrow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  uploadImage: {
    maxWidth: '200px',
  },
  deleteModal: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '458px',
    height: '279px',
    borderRadius: '15px',
    backgroundImage: `url(${deleteModalBckgrnd})`,
    backgroundRepeat: 'none',
    border: 'none',
    boxShadow: 24,
    fontSize: '18px',
    fontFamily: theme.typography.default,
    color: theme.palette.icons.nav,
    '& > header': {
      marginTop: '44px',
      textAlign: 'center',
      fontWeight: '500',
    },
    '& > p': {
      textAlign: 'center',
      marginTop: '22px',
    },
  },
  redDeleteIcon: {
    position: 'relative',
    top: '18px',
    left: '196.5px',
  },
  cancelDelSection: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    gap: '145px',
    alignItems: 'center',
    marginTop: '32px',
    fontWeight: '500',
    '& div': {
      cursor: 'pointer',
    },
  },
  cancel: {
    color: '#0069f8',
  },
  deleteIt: {
    color: '#fc3333',
  },
  balanceLogs: {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
    fontSize: '14px',
    overflow: 'scroll',
  },
  userLogRow: {
    display: 'flex',
    flexDirection: 'row',
    gap: '8px',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingBottom: '8px',
  },
  bulletPoint: {
    width: '8px',
    height: '8px',
    borderRadius: '16px',
    backgroundColor: '#1fe3ac',
  },
  lhsRow: {
    display: 'flex',
    flexDirection: 'row',
    gap: '8px',
    alignItems: 'center',
  },
  rhsRow: {
    color: '#a5a6ae',
  },
}));
