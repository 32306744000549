import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(theme => ({
  sectionContainer: {
    background: theme.palette.white,
    borderRadius: '0px 16px 16px 16px',
    margin: '16px 20px',
    height: '256px',
    fontFamily: theme.typography.default,
    fontWeight: 'bold',
    overflowY: "hidden",
    color: theme.palette.icons.nav,
    '& > aside': {
      display: 'flex',
      flexDirection: 'row',
      gap: '11px',
      fontSize: '18px',
      justifyContent: 'flex-end',
      alignItems: 'center',
      marginRight: '48px',
      marginBottom: '23px',
      marginTop: '-8px',
      position: 'relative',
      fontWeight: '500',
    },
    '& > main': {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginLeft: '24px',
      marginRight: '24px',
    },
  },
  newRateUnderline: {
    position: 'absolute',
    width: '163px',
    borderTop: '3px solid #19365e',
    bottom: '-25px',
    right: '-24px',
    zIndex: '1',
  },
  newRateDisabledUnderline: {
    borderTop: '3px solid #a5a6ae',
  },
  headerContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: '16px',
    paddingTop: '25px',
    fontSize: '24px',
  },
  horizRule: {
    margin: '0 24px',
    borderTop: '1px solid #e6e6e6',
  },
  ratesInputsContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: '40px',
    marginTop: '23px',
    fontSize: '18px',
    fontWeight: '500',
    '& input': {
      width: '312px',
      height: '48px',
      border: '1px solid #e6e6e6',
      borderRadius: '8px',
      marginTop: '11px',
    },
  },
  flatRatesContainer: {
    width: '312px',
    height: '48px',
    border: '1px solid #e6e6e6',
    borderRadius: '8px',
    marginTop: '11px',
    paddingLeft: '16px',
    fontWeight: 'normal',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    '& > aside': {
      display: 'flex',
      flexDirection: 'row',
    },
  },
  ratesBtnContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: '20px',
    fontSize: '18px',
    fontWeight: '500',
    marginTop: '56px',
  },
  refreshIconContainer: {
    width: '49px',
    height: '48px',
    backgroundColor: '#c6ffef',
    border: '1px solid #e6e6e6',
    borderRight: 'none',
    padding: '12px',
    cursor: 'pointer',
  },
  flatRatesCurrencyContainer: {
    backgroundColor: '#f2f2f4',
    borderLeft: '1px solid #e6e6e6',
    borderRadius: '0 8px 8px 0px',
    width: '64px',
    height: '48px',
    textAlign: 'center',
    padding: '13px 11px 11px',
  },
  defaultMarginSellContainer: {
    position: 'relative',
    '& input::-webkit-outer-spin-button, input::-webkit-inner-spin-button': {
      WebkitAppearance: 'none',
      margin: 0,
    },
    '& input[type=number]': {
      MozAppearance: 'textfield',
      fontSize: '20px',
      fontFamily: theme.typography.default,
      color: theme.palette.icons.nav,
      paddingLeft: '8px',
    },
    '& input:focus + $rhsContainer': {
      border: '2px solid #085ccc',
      padding: '11px',
    },
  },
  profitMarginSelectInputContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    '& input': {
      marginLeft: '16px',
    },
  },
  profitMarginSelectBox: {
    height: '48px',
    borderRadius: '8px',
    border: '1px solid #e6e6e6',
    fontSize: '18px',
    color: theme.palette.icons.nav,
    marginTop: '11px',
  },
  rhsContainer: {
    position: 'absolute',
    height: '48px',
    width: '48px',
    borderRadius: '0px 8px 8px 0px',
    background: theme.palette.transactions.background,
    border: '1px solid #e6e6e6',
    textAlign: 'center',
    padding: '13px',
    top: '32.5px',
    right: '0px',
  },
  sellRatesRHS: {
    width: '64px',
    height: '48px',
    textAlign: 'center',
    padding: '13px 11px 11px',
    fontWeight: 'normal',
  },
  defaultBtnStyle: {
    borderRadius: '8px',
    border: 'none',
    height: '48px',
    width: '136px',
    fontSize: '18px',
    color: theme.palette.icons.nav,
    fontFamily: theme.typography.default,
    fontWeight: '500',
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flext-start',
    gap: '12px',
    alignItems: 'center',
    paddingLeft: '12px',
  },
  btnCancel: {
    backgroundColor: '#e2ecff',
    marginRight: "20px"
  },
  btnSave: {
    backgroundColor: '#c6ffef',
    marginLeft: "20px",
  },
  btnDisabled: {
    backgroundColor: '#f2f2f4',
    cursor: 'auto',
    color: '#a5a6ae',
  },
  disabledColor: {
    color: '#a5a6ae',
    '& input[type="number"][disabled]': {
      color: '#a5a6ae',
    },
    '& select': {
      color: '#a5a6ae',
    },
  },
}));
