import React from 'react';

import { useStyles } from './transaction-row-blank.hooks';

export const TransactionRowBlank = () => {
  const classes = useStyles();

  return (
    <div className={classes.rowContainer}>
      <div className={classes.dateContainer}>
        <aside />
        <aside />
      </div>
      <div className={classes.iconContainer}>
        <aside />
      </div>
      <div className={classes.nameContainer}>
        <aside />
        <aside />
      </div>
      <div className={classes.refContainer}>
        <aside />
      </div>
      <div className={classes.statusContainer}>
        <aside />
      </div>
      <div className={classes.amountContainer}>
        <aside />
      </div>
    </div>
  );
};
