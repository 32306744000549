import React from 'react';

export const SAR = ({ width = '45', height = '45' }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 48 48">
    <g data-name="Group 8517">
      <path
        data-name="Path 10349"
        d="M10968 6432a24 24 0 1 0-24-24 24 24 0 0 0 24 24z"
        transform="translate(-10944.001 -6384)"
        style={{ fill: '#6da544' }}
      />
      <path
        data-name="Path 10350"
        d="M11088.7 6690.09a2.91 2.91 0 0 0 2.913 2.912h8.736a2.423 2.423 0 0 0 2.424 2.425h2.912a2.427 2.427 0 0 0 2.423-2.425v-2.912z"
        transform="translate(-11074.404 -6661.122)"
        style={{ fill: '#f0f0f0' }}
      />
      <path
        data-name="Path 10351"
        d="M11293.745 6528.7v6.792a1.946 1.946 0 0 1-1.944 1.942v2.912a4.861 4.861 0 0 0 4.856-4.854v-6.792z"
        transform="translate(-11258.915 -6514.8)"
        style={{ fill: '#f0f0f0' }}
      />
      <path
        data-name="Path 10352"
        d="M11054.445 6535.493a1.945 1.945 0 0 1-1.944 1.942v2.912a4.862 4.862 0 0 0 4.856-4.854v-6.793h-2.912z"
        transform="translate(-11042.242 -6514.8)"
        style={{ fill: '#f0f0f0' }}
      />
      <path
        data-name="Path 10353"
        d="M11264 6528.7h2.913v6.792H11264z"
        transform="translate(-11233.854 -6514.965)"
        style={{ fill: '#f0f0f0' }}
      />
      <path
        data-name="Path 10354"
        d="M11132.661 6532.583a.484.484 0 1 1-.969 0v-3.883h-2.911v3.882a.484.484 0 1 1-.968 0v-3.882h-2.912v3.882a23.078 23.078 0 0 0 7.271 3.4 3.509 3.509 0 0 0 .436-.028 1.95 1.95 0 0 1-1.884 1.484v2.912a4.86 4.86 0 0 0 4.849-4.854v-6.796h-2.912v3.882z"
        transform="translate(-11107.569 -6514.8)"
        style={{ fill: '#f0f0f0' }}
      />
      <path
        data-name="Path 10355"
        d="M11124.9 6628.87h4.368v2.912h-4.368z"
        transform="translate(-11107.813 -6605.801)"
        style={{ fill: '#f0f0f0' }}
      />
    </g>
  </svg>
);
