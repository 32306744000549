import { GenericToolbar } from '../generic-list/components';
import { useSelector } from 'react-redux';
import { useGraphList } from '../../hooks';
import { useStyles } from './dashboard.hooks';
import { DashboardContent } from './components/Dashboard-content';

export const Dashboard = ({ entity }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <DashboardContent />
      </div>
    </div>
  );
};
