import React from 'react';

export const NotificationBlank = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="80" viewBox="0 0 777 80">
    <g data-name="Group 10069" transform="translate(-575 -840)">
      <path
        data-name="Rectangle 16644"
        d="M8 0h752a8 8 0 0 1 8 8v40a8 8 0 0 1-8 8H0V8a8 8 0 0 1 8-8z"
        transform="translate(584 840)"
        style={{ fill: '#f2f2f4' }}
      />
      <g data-name="Group 8444">
        <g
          data-name="Ellipse 1529"
          transform="translate(576 848)"
          style={{ stroke: '#fff', fill: '#f2f2f4' }}
        >
          <circle cx="8" cy="8" r="8" style={{ stroke: 'none' }} />
          <circle cx="8" cy="8" r="8.5" style={{ fill: 'none' }} />
        </g>
      </g>
      <g data-name="Group 8368">
        <g
          data-name="Ellipse 1547"
          transform="translate(576 872)"
          style={{ stroke: '#fff', fill: '#f2f2f4' }}
        >
          <circle cx="8" cy="8" r="8" style={{ stroke: 'none' }} />
          <circle cx="8" cy="8" r="8.5" style={{ fill: 'none' }} />
        </g>
      </g>
      <rect
        data-name="Rectangle 18945"
        width="48"
        height="16"
        rx="8"
        transform="translate(600 904)"
        style={{ fill: '#f2f2f4' }}
      />
      <circle
        data-name="Ellipse 1838"
        cx="8"
        cy="8"
        r="8"
        transform="translate(576 904)"
        style={{ fill: '#f2f2f4' }}
      />
      <rect
        data-name="Rectangle 18946"
        width="88"
        height="12"
        rx="6"
        transform="translate(1264 902)"
        style={{ fill: '#f2f2f4' }}
      />
    </g>
  </svg>
);
