import React from 'react';

import { useStyles } from './transaction-details-card.hooks';

export const TransactionDetailsCard = ({ detailsObj }) => {
  const classes = useStyles();

  return (
    <>
      {Object.keys(detailsObj).map(label => (
        <div key={label} className={classes.detailsRow}>
          <p>{label}</p>
          <p>{detailsObj[label]}</p>
        </div>
      ))}
    </>
  );
};
