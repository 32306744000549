/* eslint-disable no-unused-vars */
import { getAllISOByCurrencyOrSymbol } from 'iso-country-currency';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Loader } from '../../../loader';
import { useStyles } from './currencies-table.hook';
import { ActiveTickIcon, RightArrowIcon } from './icons';

export const CurrenciesTable = ({
  showCreateAdminModal,
  setShowCreateAdminModal,
  data,
  entity,
  error,
  loading,
  onRefreshData,
  onEditData,
  tableFields,
  onRemoveItem,
  canOrder,
  page,
  onNextPage,
  // onPrevPage,
  hasNextPage,
  setFilters,
  filters,
  limit,
  disableFullPageScroll = false,
}) => {
  const [checked, setChecked] = useState([]);
  const [allRecordsChecked, setAllRecordsChecked] = useState(false);
  const [isHovering, setIsHovering] = useState('');
  const searchCurrencyQuery = useSelector(state => state.searchCurrencyQuery);
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  let sortedCurrencies = [...data].sort((a, b) => {
    if (a.id > b.id) return 1;
    if (a.id < b.id) return -1;

    return 0;
  });

  if (searchCurrencyQuery !== '') {
    const regex = new RegExp(searchCurrencyQuery, 'gi');
    sortedCurrencies = sortedCurrencies.filter(
      currency => currency?.name?.match(regex) || currency?.iso?.match(regex)
    );
  }

  const getISODetails = iso => iso.slice(0, 2);

  useEffect(() => {
    dispatch({ type: 'SET_SEARCH_CURRENCY_QUERY', payload: '' });
  }, []);

  useEffect(() => {
    const payload = data.filter(currency => checked.includes(currency.id));
    dispatch({ type: 'SET_CURRENCIES_TABLE_DATA_TO_EXPORT', payload });
  }, [checked]);

  useEffect(() => {
    if (allRecordsChecked) {
      const newChecked = [];
      sortedCurrencies.forEach(item => {
        newChecked.push(item.id);
      });
      setChecked(newChecked);
    }

    if (!allRecordsChecked) setChecked([]);
  }, [allRecordsChecked]);

  const navigatePage = id => {
    navigate(`/currency/${id}`);
  };

  return (
    <div>
      {loading ? (
        <Loader />
      ) : (
        <table className={classes.tableContainer}>
          <thead className={classes.tableHead}>
            <tr className={classes.tableFields}>
              <th className={classes.idHeader}>
                <input
                  type="checkbox"
                  id="selectAllCheckbox"
                  checked={allRecordsChecked}
                  onChange={() => setAllRecordsChecked(!allRecordsChecked)}
                />
                <p>ID</p>
              </th>
              <th>ISO</th>
              <th>ISO2</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {sortedCurrencies.map(currency => (
              <tr
                key={currency.id}
                className={classes.tableRow}
                onClick={() => navigatePage(currency.id)}
                onMouseEnter={() => setIsHovering(currency.id)}
                onMouseLeave={() => setIsHovering('')}
              >
                <td className={classes.firstCol}>
                  <div className={classes.currencyId}>
                    <input
                      type="checkbox"
                      checked={checked.includes(currency.id)}
                      onChange={() => {
                        if (checked.includes(currency.id)) {
                          setChecked(prev => [...prev].filter(item => item !== currency.id));
                        } else {
                          setChecked(prev => {
                            const newChecked = [...prev];
                            newChecked.push(currency.id);

                            return newChecked;
                          });
                        }
                      }}
                    />
                    <p>{currency.id}</p>
                  </div>
                  <div>
                    <ActiveTickIcon active={currency.active} />
                    <p>{currency.name}</p>
                  </div>
                </td>
                <td>{currency.iso}</td>
                <td>{currency.iso && getISODetails(currency.iso)}</td>
                <td className={classes.statusData}>
                  <p>{currency.active ? 'Enabled' : 'Disabled'}</p>
                  <div className={classes.rightArrow}>
                    <RightArrowIcon active={isHovering === currency.id} />
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};
