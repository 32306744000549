import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(theme => ({
  form: {
    paddingLeft: '8rem',
    paddingRight: '8rem',
    paddingBottom: '12.5rem',
    flexBasis: '70rem',
    [theme.breakpoints.down('sm')]: {
      paddingLeft: '1.6rem',
      paddingRight: '1.6rem',
    },
    '& > p': {
      fontSize: '18px',
      color: theme.palette.icons.nav,
      fontFamily: theme.typography.default,
      fontWeight: '500',
      marginBottom: '11px',
    },
  },
  title: {
    marginTop: '2.4rem',
    marginBottom: '4rem',
  },
  error: {
    marginTop: '1.6rem',
    color: theme.palette.error.main,
  },
  sugestion: {
    marginTop: '1.6rem',
  },
  textField: {
    marginBottom: '3.2rem',
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderRadius: '8px',
        border: '1px solid #e6e6e6',
      },
    },
  },
  signInButton: {
    margin: '1.6rem 0',
    fontSize: '18px',
    backgroundColor: '#c6ffef',
    color: theme.palette.icons.nav,
    borderRadius: '8px',
    boxShadow: 'none',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      opacity: '0.85',
    },
  },
  forgotPwContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    marginBottom: '40px',
  },
  forgotPw: {
    border: 'none',
    fontSize: '16px',
    color: '#0069f8',
    backgroundColor: 'white',
    fontFamily: theme.typography.default,
    cursor: 'pointer',
  },
}));
