import clsx from 'clsx';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { TableExport, TableImport } from '../../../../components';
import { entitiesEnum } from '../../../../constants';
import { hasEntityExport, hasEntityImport } from '../../../../utils';
// import { pagesProperties } from './pages-properties';
import { getPagesProperties } from '../pages-properties';
import { ToolbarContent } from '../toolbar-content';
import { useStyles } from './toolbar.hooks';

export const GenericToolbar = ({
  entity,
  onCreateAdmin,
  setActiveCards,
  activeCards,
  listData,
}) => {
  const navigate = useNavigate();
  const classes = useStyles();
  const hasImport = hasEntityImport(entity);
  const hasExport = hasEntityExport(entity);
  const [pagesProperties, setPagesProperties] = useState([]);

  useEffect(() => {
    const fetchPages = async () => {
      const updatedPagesProperties = await getPagesProperties();
      setPagesProperties(updatedPagesProperties);
    };

    fetchPages();
  }, [])

  const onCreate = entity => {
    if (entity === entitiesEnum.TEAM) {
      onCreateAdmin();
    } else {
      navigate(`/${entity.toLowerCase()}/0`);
    }
  };

  return (
    <div className={clsx(classes.root)}>
      <div className={classes.row}>
        <ToolbarContent
          pagesProperties={pagesProperties}
          entity={entity}
          classes={classes}
          onCreate={() => onCreate(entity)}
          setActiveCards={setActiveCards}
          activeCards={activeCards}
        />
        <span className={classes.spacer} />
        {hasImport && <TableImport entity={entity} />}
        {hasExport && <TableExport entity={entity} listData={listData} />}
      </div>
    </div>
  );
};
