import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(theme => ({
  walletHeader: {
    backgroundImage: 'linear-gradient(287deg, #38e6c3, #25c3a3)',
  },
  walletHeaderText: {
    color: '#104138',
  },
  sectionDivider: {
    backgroundColor: theme.palette.greyMid.mid,
    margin: '0 1.6rem',
  },
  cardNoShadow: {
    boxShadow: 'none',
  },
  statusPill: {
    height: '2rem',
    borderRadius: '0.8rem',
    backgroundColor: theme.palette.secondary.dark,
    margin: '2rem 1rem',
  },
  statusUnstartedPillActive: {
    backgroundColor: theme.palette.primary.nav,
  },
  statusPendingPillActive: {
    backgroundColor: theme.palette.warning.main,
  },
  statusCompletedPillActive: {
    backgroundColor: theme.palette.primary.main,
  },
  statusCompletedFailedPillActive: {
    backgroundColor: theme.palette.error.main,
  },
  resultPassed: {
    color: theme.palette.primary.dark,
  },
  resultFailed: {
    color: theme.palette.error.main,
  },
  center: {
    textAlign: 'center',
  },
  resultTitle: {
    fontWeight: 'bold',
  },
  resultValue: {
    fontSize: '2rem',
    marginTop: '1rem',
  },
  sumsubLink: {
    textDecoration: 'underline',
    color: theme.palette.greyMid.dark,
    fontWeight: 'bold',
  },
  rightSide: {
    borderLeft: `0.1rem solid ${theme.palette.greyMid.dark}`,
  },
}));
