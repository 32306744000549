import { makeStyles } from '@mui/styles';

import { accountStatus } from '../../../../theme/styles';

export const useStyles = makeStyles(theme => ({
  sectionHeader: {
    ...theme.typography.h2,
    padding: '1.6rem',
  },
  sectionDivider: {
    borderColor: theme.palette.greyMid.mid,
    margin: '0 1.6rem',
  },
  smallText: {
    fontSize: '1.2rem',
  },
  boldText: {
    fontWeight: 'bold',
  },
  centerText: {
    textAlign: 'center',
  },
  cardNoShadow: {
    boxShadow: 'none',
  },
  accountStatus,
}));
