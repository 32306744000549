/* eslint-disable no-restricted-syntax */
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { entitiesEnum } from '../../../../constants';
import { saveEntity } from '../../../../services';
import { checkOverlap } from '../../../../utils';
import { TemplateBigEdit, TemplateDeleteIcon } from '../../../icons/pairs';
import { useStyles } from './allTemplates.hooks';
import { FeesInputs } from './fees-inputs';
import { FeesTable } from './feesTable';
import { DeleteModal } from './modal/delete-modal';

/**
 * This component displays all the saved templates the pairs fees in the browser local storage.
 * The user can select a template and appy it to the selected pair. They can edit/delete their templates.
 * @param {Array} feesData - this contains the interval rates
 * @param {Array} pairsData - contains the array of pairs data
 * @param {function} setWarningMessage - set the warning modal message
 * @param {function} setShowSavedModal - open the saved modal
 * @param {boolean} isActive - a boolean flag indicating whether template is enabled or disabled
 */
export const AllTemplates = ({
  feesData,
  pairsData,
  setWarningMessage,
  setShowWarningModal,
  setShowSavedModal,
  isActive,
}) => {
  const [selectedTemplate, setSelectedTemplate] = useState('templates_blank');
  const [openModal, setOpenModal] = useState(false);
  const [userTemplates, setUserTemplates] = useState({});
  const [showEditFeesInputs, setShowEditFeesInputs] = useState(false);

  const [startValue, setStartValue] = useState('');
  const [endValue, setEndValue] = useState('');
  const [percentage, setPercentage] = useState('');
  const [absValue, setAbsValue] = useState('');

  const classes = useStyles();
  const TEMPLATES = 'feesTemplates';
  const NO_TEMPLATES = 'templates_blank';
  const currenciesDataObj = useSelector(state => state.pairsCurrenciesObj);

  useEffect(() => {
    const currentTemplates = window.localStorage.getItem(TEMPLATES) || '{}';
    setUserTemplates(JSON.parse(currentTemplates));
    setSelectedTemplate(NO_TEMPLATES);
  }, []);

  const setValuesFromTemplate = templateName => {
    const templateToEdit = JSON.parse(window.localStorage.getItem(TEMPLATES))[templateName];
    setStartValue(Number(templateToEdit.startValue));
    setEndValue(Number(templateToEdit.endValue));
    setPercentage(Number(templateToEdit.percentage));
    setAbsValue(Number(templateToEdit.absValue));
  };

  const editTemplateFee = templateName => {
    setShowEditFeesInputs(true);
    setValuesFromTemplate(templateName);
  };

  const templateSelected = templateName => {
    setSelectedTemplate(templateName);
    setValuesFromTemplate(templateName);
  };

  const deleteTemplateFee = templateName => {
    const currentTemplates = JSON.parse(window.localStorage.getItem(TEMPLATES));
    delete currentTemplates[templateName];
    window.localStorage.setItem(TEMPLATES, JSON.stringify(currentTemplates));
    setUserTemplates(currentTemplates);
    setSelectedTemplate(NO_TEMPLATES);
    setOpenModal(false);
  };

  const saveInputs = () => {
    const currentTemplates = window.localStorage.getItem(TEMPLATES) || '{}';
    const updatedTemplates = {
      ...JSON.parse(currentTemplates),
      [selectedTemplate]: {
        startValue,
        endValue,
        percentage,
        absValue,
      },
    };

    window.localStorage.setItem(TEMPLATES, JSON.stringify(updatedTemplates));
    setUserTemplates(updatedTemplates);
    setShowEditFeesInputs(false);
  };

  const cancel = () => {
    setShowEditFeesInputs(false);
  };

  const applyTemplate = async () => {
    let newRates = [];
    const saveObj = {
      intervalIni: startValue,
      intervalEnd: endValue,
      fee: absValue,
      percentageFee: percentage,
    };

    if (startValue === '' || endValue === '') {
      setWarningMessage('You must specify start and end values');
      setShowWarningModal(true);

      return;
    }

    // Check whether this is applying to one pair or user selected multiple pairs.
    const sendingCurrencies = currenciesDataObj['Sending Currency'];
    const receivingCurrencies = currenciesDataObj['Receiving Currency'];

    if (!sendingCurrencies || !receivingCurrencies) {
      setWarningMessage('Please select both sending and receiving currencies');
      setShowWarningModal(true);

      return;
    }

    if (
      sendingCurrencies.length === 1 &&
      receivingCurrencies.length === 1 &&
      sendingCurrencies[0] === receivingCurrencies[0]
    ) {
      setWarningMessage('Sending and Receiving currencies are the same');
      setShowWarningModal(true);

      return;
    }

    if (feesData.length > 0) {
      for (const interval of feesData) {
        const overlap = checkOverlap(interval, saveObj);
        if (overlap) {
          setWarningMessage('The intervals must not overlap');
          setShowWarningModal(true);

          return;
        }
      }
    }

    try {
      for (const sendingCurrency of sendingCurrencies) {
        for (const receivingCurrency of receivingCurrencies) {
          if (sendingCurrency !== receivingCurrency) {
            const singlePair = pairsData.filter(pair => {
              if (pair.from?.iso === sendingCurrency && pair.to?.iso === receivingCurrency)
                return true;

              return false;
            })[0];

            if (singlePair.rates) {
              for (const interval of singlePair.rates) {
                const overlap = checkOverlap(interval, saveObj);
                if (overlap) {
                  setWarningMessage('The intervals must not overlap');
                  setShowWarningModal(true);

                  return;
                }
              }
            }

            newRates = [...feesData, saveObj];

            await saveEntity({
              entity: entitiesEnum.PAIR,
              id: singlePair.id,
              hasOrder: false,
              values: { id: singlePair.id, rates: newRates },
            });
          }
        }
      }
      setShowSavedModal(true);
    } catch (err) {
      setWarningMessage(err);
      setShowWarningModal(true);
    }
  };

  return (
    <>
      {showEditFeesInputs && (
        <FeesInputs
          setStartValue={setStartValue}
          setEndValue={setEndValue}
          setPercentage={setPercentage}
          setAbsValue={setAbsValue}
          startValue={startValue}
          endValue={endValue}
          percentage={percentage}
          absValue={absValue}
          mode="allTemplates"
          saveInputs={saveInputs}
          cancel={cancel}
          isActive={isActive}
        />
      )}
      <section className={classes.templatesRow}>
        <div className={classes.templatesContainer}>
          {userTemplates
            ? Object.keys(userTemplates).map(template => (
                <div key={template} className={classes.singleTemplateBtnContainer}>
                  <button
                    type="button"
                    onClick={() => {
                      templateSelected(template);
                    }}
                    className={
                      selectedTemplate !== template
                        ? classes.templateBtnDefault
                        : `${classes.templateBtnDefault} ${classes.templateBtnActive}`
                    }
                  >
                    <p>{template}</p>
                  </button>
                  {selectedTemplate === template && (
                    <div className={classes.templateDeleteIcon} onClick={() => setOpenModal(true)}>
                      <TemplateDeleteIcon />
                    </div>
                  )}
                  <DeleteModal
                    cancel={() => setOpenModal(false)}
                    deleteIt={() => deleteTemplateFee(template)}
                    onClose={() => setOpenModal(false)}
                    open={openModal}
                  />
                </div>
              ))
            : null}
        </div>
        <aside>
          {selectedTemplate !== 'templates_blank' && (
            <>
              <button type="button" className={classes.applyTemplateBtn} onClick={applyTemplate}>
                <p>Apply Template</p>
              </button>
              <div
                className={classes.templateBigEdit}
                onClick={() => editTemplateFee(selectedTemplate)}
              >
                <TemplateBigEdit />
              </div>
            </>
          )}
        </aside>
      </section>
      <hr />
      <FeesTable
        editTemplateFee={editTemplateFee}
        selectedTemplate={selectedTemplate}
        deleteTemplateFee={deleteTemplateFee}
        mode="allTemplates"
        isActive={isActive}
      />
    </>
  );
};
