import { BeneficiaryRequirements, ExchangeRate } from '../components';
import { entitiesEnum } from '../constants';
import {
  countryFields,
  currenciesFields,
  pairsFields,
  teamFields,
  transactionFields,
  userProfileFields,
} from './config.constants';

export const getBackofficeConfig = () => ({
  dateFormat: 'YYYY-MM-DD HH-mm',
  entitiesImport: [entitiesEnum.COUNTRY],
  entitiesExport: [entitiesEnum.TRANSACTION, entitiesEnum.CURRENCY, entitiesEnum.USERPROFILE],
  tableExcludesFields: [
    'order',
    'seoDesc',
    'seoTitle',
    'shortDesc',
    'updatedAt',
    'rates',
    'companyRequirements',
    'personalRequirements',
    'emailVerified',
    'phoneNumberVerified',
    'kyc',
    'beneficiaries',
    'externalBankAccounts',
    'invitedBy',
    'poundsAccount',
    'steps',
    'method',
    'discountUsers',
    'readNotifications',
    'readOnboardings',
    'payload',
    'userFromNote',
    'userToNote',
    'userFromAttachment',
    'userToAttachment',
    'accountType',
    'membership',
    'birthdate',
    'city',
    'address',
    'postalCode',
    'amountsSent',
    'profileImage',
    'paymentMethods',
    'pushNotificationsConfig',
    'companyInfo',
    'accountSuspended',
    'smsMarketingConsent',
    'pushMarketingConsent',
    'emailMarketingConsent',
    'forbiddenCurrency',
    'smsAllowed',
    'pushAllowed',
    'emailAllowed',
    'skipInternalCheck',
    'intercomProfileUrl',
    'passwordResetAt',
    'meta',
    'passwordResetBy',
    'createdAt',
  ],
  tableFields: {
    [entitiesEnum.TRANSACTION]: transactionFields,
    [entitiesEnum.USERPROFILE]: userProfileFields,
    [entitiesEnum.PAIR]: pairsFields,
    [entitiesEnum.CURRENCY]: currenciesFields,
    [entitiesEnum.COUNTRY]: countryFields,
  },
  entitiesWithManualIds: [entitiesEnum.COUNTRY, entitiesEnum.CURRENCY, entitiesEnum.MEMBERSHIP],
  labelsOverride: {
    showTopSteps: 'Show top steps',
    showBottomSteps: 'Show bottom steps',
    topText: 'Top text',
    bottomText: 'Bottom text',
    showLogo: 'Show logo',
    ctaText: 'CTA Text',
    slideImage: 'Image',
    intervalIni: 'Interval Init',
    intervalEnd: 'Interval End',
    manual_absolute: 'Manual - Absolute',
    auto_plus_manual_absolute: 'Auto + Manual - Absolute',
    auto_plus_manual_percent: 'Auto + Manual - Percent',
    rateFrom: 'Conversion Rate Buy',
    rateTo: 'Conversion Rate Sell',
    rateMode: 'Rate Mode',
    feeMode: 'Fee Mode',
    auto: 'Auto',
    absolute: 'Absolute',
    percent: 'Percent',
    accountType: 'Account Type',
    lastName: 'Surname',
    postalCode: 'Postal Code',
    phoneNumber: 'Phone Number',
    phoneCode: 'Phone Code',
    emailVerified: 'Email Verified',
    phoneNumberVerified: 'Phone Number Verified',
    userFrom: 'Sender',
    userTo: 'Receiver',
    fromCurrency: 'Origin Currency',
    toCurrency: 'Dest. Currency',
    sendingAmount: 'Sending Amount',
    exchangeRate: 'Exchange Rate',
    receivingAmount: 'Receiving Amount',
    amountPayable: 'Amount Payable',
    errorCode: 'Error Code',
    send_money: 'send',
    add_money: 'fund',
    defaultCurrency: 'Default Currency',
    feeFree: 'Free',
    totalUses: 'Total uses',
    totalUniqueUses: 'Total unique uses',
    lastExchangeRateBuy: 'Last Exchange Rate Buy',
    lastExchangeRateSell: 'Last Exchange Rate Sell',
    walletBgColor: 'Wallet BG Color',
    walletTextColor: 'Wallet Text Color',
    regionOrigin: 'Origin Region',
    regionDestination: 'Destination Region',
    permissions: 'Account type',
    smsMarketingConsent: 'SMS Marketing Consent',
    pushMarketingConsent: 'Push Marketing Consent',
    emailMarketingConsent: 'Email Marketing Consent',
    accountStatus: 'Account Status',
    city: 'City',
    address: 'Address',
    birthdate: 'Date of Birth',
    id: 'ID',
    region: 'Region',
    iso: 'ISO',
    iso2: 'ISO2',
    currency: 'Currency',
    active: 'Active',
    createdAt: 'Date&time',
  },
  tableFieldsOrder: {
    [entitiesEnum.TEAM]: teamFields,
    [entitiesEnum.TRANSACTION]: transactionFields,
    [entitiesEnum.COUNTRY]: countryFields,
  },
  fieldsBreakLineBefore: {
    Pair: ['feeMode'],
    Discount: ['expire'],
  },
  fieldsBackground: {
    Onboarding: { type: '#f5f7fc' },
  },
  fieldsBreakLineAfter: {
    Pair: ['intervalEnd'],
  },
  fieldsReadOnly: {
    Transaction: ['steps'],
    UserProfile: ['beneficiaries', 'bankDetails', 'externalBankAccounts'],
  },
  dependentFields: {
    Pair: {
      rates: {
        rateFrom: {
          rateMode: ['manual_absolute', 'auto_plus_manual_absolute', 'auto_plus_manual_percent'],
        },
        rateTo: {
          rateMode: ['manual_absolute', 'auto_plus_manual_absolute', 'auto_plus_manual_percent'],
        },
      },
    },
  },
  extraComponents: {
    Pair: [ExchangeRate],
  },
  customComponents: {
    Country: {
      companyRequirements: BeneficiaryRequirements,
      personalRequirements: BeneficiaryRequirements,
    },
    Discount: {
      discountUsers: () => null,
    },
  },
  subObjectValidations: {
    Pair: {
      rates: {
        validate: (allValues, value, index) => {
          if (Number(value.intervalIni) > Number(value.intervalEnd)) return false;

          return allValues.every(
            (val, i) =>
              (index && index === i) ||
              Number(value.intervalIni) > Number(val.intervalEnd) ||
              Number(value.intervalEnd) < Number(val.intervalIni)
          );
        },
        errorMessage: "The rate intervals can't overlap with the existing ones",
      },
    },
  },
  editExcludeEntities: [entitiesEnum.NOTIFICATION, entitiesEnum.CURRENCY, entitiesEnum.TRANSACTION],
  removeExcludeEntities: [
    entitiesEnum.USERPROFILE,
    entitiesEnum.NOTIFICATION,
    entitiesEnum.CURRENCY,
    entitiesEnum.TRANSACTION,
  ],
  createExcludeFields: ['id', 'order', 'createdAt', 'updatedAt'],
  features: {
    userProfileRiskScore: false,
    userProfileCurrencies: false,
    userProfileLogs: false,
    userProfileNotification: false,
    userProfileWalletInfo: false,
    userProfileCloseAccount: false,
    hiddenRoutes: false,
    tablePagination: false,
    notificationIcon: false,
  },
});
