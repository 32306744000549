import * as React from 'react';
import {useEffect, useState} from 'react';
import {useStyles} from '../multicurrency.hooks';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import {Snackbar} from '@mui/material';
import ReactPaginate from 'react-paginate';
import {TransactionButton} from './TransactionButton';
import {graphqlApiDecorator} from '../../../../decorators';
import {queries} from '../../../../graphql';
import {useGraphList} from '../../../../hooks';
import {entitiesEnum} from '../../../../constants';
import {ReturnCountryIcon} from '../../../icons/countries/ReturnCountryIcon';
import {Alert} from '@mui/lab';
import {Loader} from '../../../loader';
import {DemoContainer} from '@mui/x-date-pickers/internals/demo';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {DatePicker} from '@mui/x-date-pickers/DatePicker';
import dayjs from "dayjs";
import {CopyButton} from '../fX-trade-history/CopyButton';
import {SelectArrowDownIcon, DateIcon} from '../../../icons';
import {copyTextToClipboard} from "../../../../utils";
import {statusMap} from "../status";

const columns = ['endToEndId', 'status', 'amount', 'createdAt', 'ultimateBeneficiary', 'copyInfo'];


function convertToHumanReadable(dateString) {
  const date = new Date(dateString);
  const options = {
    year: 'numeric',
    month: 'long', 
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
    hour12: true
  };
  return new Intl.DateTimeFormat(undefined, options).format(date);
}

export function TransactionsTab() {
    const limit = 10
    const today = new Date(); // Get current date
    const [loading, setLoading] = useState(false);
    const [transactions, setTransactions] = useState([]);
    const [startDate, setStartDate] = useState(dayjs(today).startOf('D').format('YYYY-MM-DD'));
    const [endDate, setEndDate] = useState(dayjs(today).endOf('D').format('YYYY-MM-DD'));
    const [error, setError] = useState(false);
    const [total, setTotal] = useState(0);
    const [offset, setOffset] = useState(0);
    const [copied, setCopied] = useState(false);
    const [selectedType, setSelectedType] = useState('client');
    const {data: currencies} = useGraphList({
        entity: entitiesEnum.CURRENCY,
    });
    const [currency, setCurrency] = useState('EUR');
    const classes = useStyles();
    const copy = (endToEndId,row) => {
        graphqlApiDecorator(queries, 'getMCCYTransaction', {endToEndId}).then(res => {
            setTimeout(() => {copyTextToClipboard(typeof res==='string'?res: JSON.stringify(row)).then(r => setCopied(true)) }, 1000);
        })

    }

    const copyETEID = endToEndId => {
      setTimeout(() => {copyTextToClipboard(endToEndId).then(r => setCopied(true)) }, 1000);
    }

    const fetch = async (reload=false) => {
        if (reload && offset !== 0){
            setOffset(0);
            return ;
        }
        setLoading(true);
        return await graphqlApiDecorator(queries, 'listAllTransactions', {
            currency,
            startDate,
            endDate,
            limit: limit,
            offset,
            type: selectedType
        }).then(res => {
            setLoading(false);
            if (res){
              setTransactions(res.items?.map(item=>{
                item.ultimateBeneficiary = item.beneficiaryName;
                item.createdAt = convertToHumanReadable(item.createdAt); 
                return item} ))
                setTotal(res.total)
            }

        });
    };
    const colorFinder = status => {
      if (status !== undefined){
        switch (true) {
            case statusMap[status?.toLowerCase()]==='Cancelled':
                return '#fc3333';
            case statusMap[status?.toLowerCase()]==='Settled':
                return '#23c3a4';
            case statusMap[status?.toLowerCase()]==='In Progress':
                return '#0069f8';
        }
      }
    };


    useEffect(() => {

        fetch()
    }, [offset]);

    function handlePaginationPageClick({selected}) {
        setOffset(selected*limit)
    }

    return (
        <>
          {loading ? (
            <Loader />
        ) : (
            <div className={classes.sectionContainer}>
              <div className={classes.container}>
                <div className={classes.filterSection}>
                  <div
                      className={classes.comboInputSelectContainer}
                      style={{marginTop: '8px', flexGrow: '1'}}
                  >
                    <Select
                        id="currency"
                        value={currency}
                        className={classes.selectComboTransaction}
                        IconComponent={SelectArrowDownIcon}
                        sx={{
                          borderWidth: '0',
                          width: '100%',
                          borderRadius: '8px',
                          maxHeight: '250px',
                        }}
                        onChange={e => setCurrency(e.target.value)}
                        MenuProps={{
                          PaperProps: {
                            style: {
                              maxHeight: 250,
                            },
                          },
                        }}
                    >
                      {Object.values(currencies).map(currency => (
                          <MenuItem className={classes.selectOption} value={currency.iso}>
                            {/* eslint-disable-next-line jsx-a11y/alt-text */}
                            <ReturnCountryIcon country={currency?.iso}/>
                            <p>{currency.name}</p>
                          </MenuItem>
                      ))}
                    </Select>
                  </div>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer components={['DatePicker']}>
                      <DatePicker
                          disableFuture
                          value={dayjs(startDate).startOf('D')}
                          format={'DD/MM/YYYY'}
                          className={classes.dateField}
                          style={{width: '25%'}}
                          label="From Date (DD/MM/YYYY)"
                          onChange={e => setStartDate(e.format('YYYY-MM-DD'))}
                          slots={{
                            openPickerIcon: DateIcon,
                          }}
                      />
                      <DatePicker
                          disableFuture
                          value={dayjs(endDate).endOf('D')}
                          format={'DD/MM/YYYY'}
                          className={classes.dateField}
                          style={{width: '25%'}}
                          label="To Date (DD/MM/YYYY)"
                          onChange={e => {
                              setEndDate(e.format('YYYY-MM-DD'))
                          }}
                          slots={{
                            openPickerIcon: DateIcon,
                          }}

                      />
                    </DemoContainer>
                  </LocalizationProvider>
                  <div style={{marginTop: '8px', flexGrow: '1'}}>
                    <Select
                        value={selectedType}
                        className={classes.selectComboTransaction}
                        IconComponent={SelectArrowDownIcon}
                        sx={{
                          height: '48px',
                          width: '100%',
                          borderRadius: '8px',
                        }}
                        //   onChange={e => setSelectedDate(e.target.value)}
                        MenuProps={{
                          PaperProps: {
                            style: {
                              maxHeight: 400,
                            },
                          },
                        }}
                        onChange={(e) => setSelectedType(e.target.value)}
                    >
                      <MenuItem key={'all'} className={classes.selectCombo} value={'all'}>
                        <p>All Transactions</p>
                      </MenuItem>
                      <MenuItem key={'admin'} className={classes.selectCombo} value={'admin'}>
                        <p>Admin</p>
                      </MenuItem>
                      <MenuItem key={'client'} className={classes.selectCombo} value={'client'}>
                        <p>client</p>
                      </MenuItem>
                    </Select>
                  </div>
                  <TransactionButton onClick={()=>fetch(true)}/>
                </div>

                <table className={classes.tableContainer}>
                  <thead className={classes.tableHead}>
                  <tr className={classes.tableFields}>
                    <th>End To End Id</th>
                    <th>Status</th>
                    <th>Amount</th>
                    <th>Created at</th>
                    <th>Ultimate Beneficiary</th>
                    <th>Copy Info</th>
                  </tr>
                  </thead>
                  <tbody>
                  {transactions.map(row => (
                      <tr
                          className={classes.tableRow}
                          key={row.key}
                      >
                        {columns.map(head => {
                          if (head === 'endToEndId') {
                            return (
                                <td className={classes.tableCell} id={head}>
                                  <CopyButton
                                      onClick={() => copyETEID(row['endToEndId'])}
                                      text={row['endToEndId']}
                                  />
                                </td>
                            );
                          } else if (head === 'ultimateBeneficiary') {
                            return (
                                <td className={classes.tableCell} id={head}>
                                  {row[head]}
                                </td>
                            );
                          } else if (head === 'status') {
                            return (
                                <td
                                    className={classes.tableCell}
                                    style={{color: colorFinder(row[head])}}
                                    id={head}
                                >
                                  {statusMap[(row[head])?.toLowerCase()]}
                                </td>
                            );
                          } else if (head === 'copyInfo') {
                            return (
                                <td
                                    className={classes.tableCell}
                                    style={{color: colorFinder(row[head])}}
                                    id={head}
                                >
                                  <CopyButton onClick={() => copy(row['endToEndId'],row)}/>
                                </td>
                            );
                          } else {
                            return (
                                <td className={classes.tableCell} id={head}>
                                  {row[head]}
                                </td>
                            );
                          }
                        })}
                      </tr>
                  ))}
                  </tbody>
                </table>

              </div>
              <div className={classes.paginationContainer}>
                <ReactPaginate
                    className={classes.pageNumbers}
                    activeClassName={classes.activePage}
                    breakLabel="..."
                    nextLabel={() => setOffset(offset/limit + 1)}
                    onPageChange={handlePaginationPageClick}
                    pageRangeDisplayed={10}
                    pageCount={Math.floor(total / 10)}
                    previousLabel={() => setOffset(offset/limit - 1)}
                    renderOnZeroPageCount={null}
                    forcePage={offset/limit}

                />
              </div>
              <Snackbar open={!!error} autoHideDuration={6000} onClose={() => setError('')}>
                <Alert onClose={() => setError('')} severity="error" sx={{width: '100%'}}>
                  {error}
                </Alert>
              </Snackbar>

              <Snackbar open={copied} autoHideDuration={6000} onClose={() => setCopied(false)}>
                <Alert onClose={() => setCopied(false)} severity="info" sx={{width: '100%'}}>
                  copied
                </Alert>
              </Snackbar>
            </div>)}
        </>

    );
}
