import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(theme => ({
  sectionContainer: {
    background: theme.palette.white,
    borderRadius: '0 16px 16px 16px',
    color: theme.palette.icons.nav,
    fontSize: '18px',
    fontFamily: theme.typography.default,
    marginTop: '16px',
    marginLeft: '16px',
    marginRight: '16px',
    '& header': {
      fontWeight: 'bold',
      fontSize: '24px',
    },
  },
  endToEndColumn:{
    width:'20ch'
  },
  dateField: {
    '& div> .css-1d3z3hw-MuiOutlinedInput-notchedOutline': {
      borderRadius: '8px',
      border: '1px solid #e6e6e6',
    },
    '& div> input': {
      height: '15px',
      "font-size": "18px",
      "font-stretch": 'normal',
      "font-style": 'normal',
      "line-height": 'normal',
      "letter-spacing": 'normal',
      "text-align": 'left',
      "color": '#19365e',
    },
    '& label': {
      color: '#a5a6ae',
    },
  },
  loadButton: {
    backgroundColor: '#c6ffef',
    borderRadius: '8px',
    border: 'none',
    height: '48px',
    width: 'fit-content',
    textAlign: 'center',
    fontSize: '18px',
    color: theme.palette.icons.nav,
    fontFamily: theme.typography.default,
    fontWeight: '500',
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flext-start',
    gap: '12px',
    alignItems: 'center',
    paddingInline: '12px',
    marginTop: '8px',
  },
  fxTradesSection: {
    padding: '24px',
  },
  fxTradesInputSection: {
    width: '50%',
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    margin: 'auto',
  },
  inputItem: {
    flexBasis: '50%',
  },
  PaymentButton: {
    borderRadius: '8px',
    border: 'none',
    height: '48px',
    width: 'max(146px,136px)',
    textAlign: 'center',
    fontSize: '18px',
    color: theme.palette.icons.nav,
    fontFamily: theme.typography.default,
    fontWeight: '500',
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'row',
    gap: '12px',
    alignItems: 'center',
    paddingLeft: '12px',
    background: '#9fe3ff',
    marginLeft: 'auto',
    marginBottom: '20px',
  },
  FXTrade: {
    borderRadius: '8px',
    border: 'none',
    height: '48px',
    width: 'max(146px,136px)',
    textAlign: 'center',
    fontSize: '18px',
    color: theme.palette.icons.nav,
    fontFamily: theme.typography.default,
    fontWeight: '500',
    cursor: 'pointer',
    gap: '12px',
    padding: '12px',
    background: '#9fe3ff',
    marginLeft: 'auto',
    marginTop: 'auto',
  },
  copyInfoButton: {
    display: 'flex',
    alignItems: 'center',
    gap: '5px',
    border: `1px solid #f2f2f4`,
    borderRadius: '16px',
    padding: '5px',
    background: 'white',
    minHeight: '32px',
    fontSize: '14px',
    cursor: 'pointer',
    '&:hover': {
      background: '#f2f2f4',
    },
  },
  textOverflow: {
    whiteSpace: 'nowrap',
    display: 'block',
    maxWidth: '120px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    color: '#19365e',

  },
  defaultBtnStyle: {
    borderRadius: '8px',
    border: 'none',
    height: '48px',
    width: 'max(146px,136px)',
    textAlign: 'center',
    fontSize: '18px',
    color: theme.palette.icons.nav,
    fontFamily: theme.typography.default,
    fontWeight: '500',
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flext-start',
    gap: '12px',
    alignItems: 'center',
    paddingLeft: '12px',
  },
  container: {
    background: theme.palette.white,
    height: '100%',
    display: 'flex',
    flexDirection: 'column !important',
    '& .css-1as232s-MuiStack-root>.MuiTextField-root': {
      minWidth: '300px',
    },
  },
  tableContainer: {
    backgroundColor: theme.palette.white,
    fontSize: '18px',
    fontFamily: theme.typography.default,
    color: theme.palette.icons.nav,
    borderRadius: '0 16px 16px 16px',
    boxShadow: '0 2px 16px 0 rgba(0, 0, 0, 0.04)',
    width: '100%',
    borderCollapse: 'collapse',
    flexGrow: '1 1 0',
    padding: '0 16px',
    '& td': {
      padding: '16px 16px',
    },
  },
  tableHead: {
    borderBottom: '1px solid #aeabab',
    paddingTop: '16px',
    ...theme.typography.h4,
  },
  centerlize: {
    '& td': {
      width: '10%',
      textAlign: 'center',
    },
    '& th': {
      width: '10%',
      textAlign: 'center !important',
    },
  },
  tableFields: {
    '& th': {
      textAlign: 'left',
      padding: '16px 16px',
      color: '#19365e',
    },
    '& input[type="checkbox"]': {
      width: '24px',
      height: '24px',
      cursor: 'pointer',
    },
  },
  idHeader: {
    display: 'flex',
    flexDirection: 'row',
    gap: '16px',
  },
  tableRow: {
    minHeight: '88px',
    fontSize: '14px',
    cursor: 'pointer',
    borderBottom: '1px solid #e6e6e6',
    '&:hover': {
      backgroundColor: '#e9fff9 !important',
    },
  },
  tableCell: {
    wordBreak: 'break-all',
    maxWidth: 60,
    fontFamily:  theme.typography.default,
    fontSize: '20px',
    fontWeight: 500,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    textAlign: 'left',
    color: '#19365e',
  },
  filterSection: {
    display: 'flex',
    padding: '16px ',
    gap: '8px',
    '& .Mui-focused': {
      background: '#f2f2f4',
    },
  },
  comboInputSelectContainer: {
    display: 'flex',
    flexDirection: 'row',
    position: 'relative',
    // border: '1px solid #c0c0c0',
    borderRadius: '8px',
    color: '#19365e',
    fontSize: '20px',
    height: '48px',
  },
  inputBase: {
    border: '1px solid #c0c0c0',
    borderRadius: '8px',
    paddingInline: '8px',
    width: '100%',
    height: '48px',
  },
  inputCombo: {
    "font-size": "20px",
    "font-weight": "500",
    "font-stretch": 'normal',
    "font-style": 'normal',
    "line-height": 'normal',
    "letter-spacing": 'normal',
    "text-align": 'left',
    "color": '#19365e',
    position: 'absolute',
    top: 0,
    left: 0,
    width: '75%',
    zIndex: '5',
    height: '100%',
    borderRight: '1px solid #c0c0c0',
    paddingLeft: '16px',
  },
  selectOption: {
    display: 'flex',
    alignItems: 'center',
    color: '#19365e',

    '& p': {
      marginLeft: '8px',
    },
    '& svg': {
      height: '24px',
      width: '24px',
    },
  },
  selectCombo: {
    width: 'max(auto,100%)',
    textAlign: 'left',
    display: 'flex',
    '& fieldSet': {
      borderColor: '#e6e6e6',
    },
    '& .MuiSelect-select.MuiInputBase-input': {
      display: 'flex',
      direction: 'rtl',
      alignItems: 'center',
      '& p:last-child': {
        display: 'none',
      },
      '& svg': {
        width: '32px',
        height: '32px',
        marginLeft: '15px'
      },
    },
  },
  selectComboTransaction:{
    width: 'max(auto,100%)',
    textAlign: 'left',
    display: 'flex',
    '& fieldSet': {
      borderColor: '#e6e6e6',
    },
    '& .MuiSelect-select.MuiInputBase-input': {
      display: 'flex',
      alignItems: 'center',
      direction: 'rtl',
      color: '#19365e',

      '& svg': {
        width: '32px',
        height: '32px',
        marginLeft: '15px'
      },
      '& p':{
        marginRight: 'auto',
        color: '#19365e',

      }
    },
  },

  paginationContainer: {
    display: 'flex',
    flexDirection: 'row',
    fontSize: '16px',
    fontFamily: theme.typography.default,
    gap: '8px',
    justifyContent: 'center',
    alignContent: 'center',
    paddingTop: '28px',
    paddingBottom: '10px',
    '& button': {
      border: 'none',
      backgroundColor: '#f2f2f4',
    },
  },
  abbrCurrency: {
    fontWeight: '800',
    color: '#19365e',
    "font-size": "20px",
    "font-stretch": 'normal',
    "font-style": 'normal',
    "line-height": 'normal',
    "letter-spacing": 'normal',
    "text-align": 'left',
  },
  utilityClass: {
    '& .Mui-focused': {
      background: '#f2f2f4',
    },
  },
  pageNumbers: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '8px',
    listStyleType: 'none',
    color: theme.palette.icons.nav,
    '& li': {
      cursor: 'pointer',
    },
  },
  activePage: {
    color: '#1fe3ac',
  },
  fontStyle:{
    "font-size": "20px",
    "font-weight": "500",
    "font-stretch": 'normal',
    "font-style": 'normal',
    "line-height": 'normal',
    "letter-spacing": 'normal',
    "text-align": 'left',
    "color": '#19365e',
  }
}));
