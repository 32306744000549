import { ONEDAYMILLISECONDS } from './date.utils';

export const filterTransactionData = (tableBodyData, filterObj, isSearchMode) => {
  let data = [...tableBodyData];

  if (isSearchMode) {
    if (filterObj.fromDate) {
      data = data.filter(record => new Date(record.createdAt) > new Date(filterObj.fromDate));
    }

    if (filterObj.toDate) {
      const toDate = new Date(filterObj.toDate);
      toDate.setTime(toDate.getTime() + ONEDAYMILLISECONDS);
      data = data.filter(record => new Date(record.createdAt) < toDate);
    }
  }

  const INCOMING = 'add_money';
  const OUTGOING = 'send_money';
  const EXCHANGE = 'convert';

  if (filterObj.incoming || filterObj.outgoing || filterObj.exchange) {
    const arrayOfStatusToFilter = [];
    if (filterObj.incoming) arrayOfStatusToFilter.push(INCOMING);
    if (filterObj.outgoing) arrayOfStatusToFilter.push(OUTGOING);
    if (filterObj.exchange) arrayOfStatusToFilter.push(EXCHANGE);

    data = data.filter(record => arrayOfStatusToFilter.includes(record.type));
  }

  // Filter by status

  const statusFilters = { ...filterObj.statusFilters };

  const COMPLETED = ['success', 'accepted'];
  const PROCESSING = ['hold', 'before_pending', 'pending', 'started', 'hold'];
  const FAILED = ['error', 'expired', 'canceled', 'rejected', 'failed', 'payment_failed'];

  if (
    statusFilters.completedFilter ||
    statusFilters.failedFilter ||
    statusFilters.processingFilter
  ) {
    const arrayOfStatusToFilter = [];
    if (statusFilters.completedFilter) {
      COMPLETED.forEach(item => {
        arrayOfStatusToFilter.push(item);
      });
    }
    if (statusFilters.failedFilter) {
      FAILED.forEach(item => {
        arrayOfStatusToFilter.push(item);
      });
    }
    if (statusFilters.processingFilter) {
      PROCESSING.forEach(item => {
        arrayOfStatusToFilter.push(item);
      });
    }
    data = data.filter(record => arrayOfStatusToFilter.includes(record.status));
  }

  // Filter by Amount

  if (filterObj.minAmount) {
    data = data.filter(
      record =>
        Number(record.sendingAmount) >= Number(filterObj.minAmount) ||
        Number(record.receivingAmount) >= Number(filterObj.minAmount)
    );
  }

  if (filterObj.maxAmount) {
    data = data.filter(
      record =>
        Number(record.sendingAmount) <= Number(filterObj.maxAmount) ||
        Number(record.receivingAmount) <= Number(filterObj.maxAmount)
    );
  }

  return data;
};

export const checkIfEveryPropIsFalsy = formValues =>
  Object.values(formValues).every(value => !value);

export const replaceWhiteSpaceSingleSpace = str => str.replace(/\s\s+/g, ' ');

export const filterTransactionDataByName = (query, transactionData) => {
  if (query !== '') {
    const regex = new RegExp(query, 'gi');

    return transactionData.filter(
      record =>
        record.status !== 'started' &&
        record.status !== 'expired' &&
        record.status !== 'error' &&
        (replaceWhiteSpaceSingleSpace(
          `${record?.userFrom?.name} ${record?.userFrom?.lastName || ''}`
        ).match(regex) ||
          replaceWhiteSpaceSingleSpace(
            `${record?.beneficiary?.name} ${record?.beneficiary?.lastName || ''}`
          ).match(regex) ||
          replaceWhiteSpaceSingleSpace(
            `${record?.userTo?.name} ${record?.userTo?.lastName || ''}`
          ).match(regex) ||
          replaceWhiteSpaceSingleSpace(
            `${record?.externalUserFrom?.name} ${record?.externalUserFrom?.lastName || ''}`
          ).match(regex))
    );
  }

  return [];
};
