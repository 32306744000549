import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(theme => ({
  filterSummary: {
    position: 'relative',
    top: '22px',
  },
  tableContainer: {
    backgroundColor: theme.palette.white,
    fontSize: '14px',
    fontFamily: theme.typography.default,
    color: theme.palette.icons.nav,
    borderRadius: '16px',
    boxShadow: '0 2px 16px 0 rgba(0, 0, 0, 0.04)',
    width: '100%',
    borderCollapse: 'collapse',
    padding: '0 16px',
    '& thead': {
      position: 'sticky',
      insetBlockStart: 0,
      zIndex: 3,
      backgroundColor: 'white',
      borderBottom: '1px solid #aeabab',
    },
    '& tr': {
      borderRadius: '0 16px 0 0',
    },
  },
  tableHead: {
    borderBottom: '1px solid #aeabab',
  },
  tableFields: {
    '& th': {
      textAlign: 'left',
    },
    '& th:last-child': {
      borderRadius: '0 16px 0 0',
    },
  },
  nameField: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingTop: '12px',
    marginLeft: '16px',
    paddingBottom: '14px',
    paddingLeft: '24px',
    gap: '16px',
    '& > input[type="checkbox"]': {
      width: '24px',
      height: '24px',
      borderRadius: '16px',
      cursor: 'pointer',
    },
  },
  tableRow: {
    borderRadius: '8px',
    '&:hover': {
      backgroundColor: '#e9fff9',
    },
    '& td:first-child': {
      paddingLeft: '40px',
      paddingTop: '11px',
    },
    '& td': {
      paddingBottom: '8px',
    },
    borderBottom: '1px solid #e6e6e6',
    cursor: 'pointer',
  },
  nameTableData: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    '& > div': {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      gap: '16px',
      marginTop: '-4px',
    },
  },
  name: {
    '& input[type="checkbox"]': {
      width: '24px',
      height: '24px',
      borderRadius: '16px',
      border: '1px solid #e6e6e6',
      cursor: 'pointer',
    },
  },
  intercomRow: {
    display: 'flex',
    flexDirection: 'row',
    gap: '0px !important',
    marginLeft: '-12px !important',
  },
  sumSubRow: {
    display: 'flex',
    flexDirection: 'row',
    gap: '0px !important',
    marginLeft: '-16px !important',
    marginTop: '-17px !important',
    marginBottom: '-11px !important',
  },
  companyHouseRow: {
    display: 'flex',
    flexDirection: 'row',
    gap: '0px !important',
    marginLeft: '-16px !important',
    marginTop: '-7px !important',
    marginBottom: '-11px !important',
  },
  nameSmallDetails: {
    color: '#aeabab',
    paddingLeft: '4px',
  },
  internalNumber: {
    display: 'flex',
    flexDirection: 'row',
    gap: '4px',
    fontSize: '14px',
    '& p:nth-child(2)': {
      color: theme.palette.icons.nav,
      fontSize: '14px',
      fontWeight: '500',
    },
  },
  createdDetails: {
    display: 'flex',
    flexDirection: 'row',
    gap: '4px',
    fontSize: '14px',
    '& p:first-child': {
      color: '#aeabab',
    },
  },
}));
