import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import React from 'react';

import { useStyles } from './modal.hooks';
import { WarningIcon } from 'components/icons';

export const FaileModal = ({ open, onClose, header, content }) => {
  const classes = useStyles();

  return (
    <Modal open={open} onClose={onClose}>
      <Box>
        <div className={classes.modalBox}>
          <div className={classes.iconContainer}>
          <WarningIcon />
          </div>
          <header>{header}</header>
          <p>{content}</p>
          <section className={classes.cancelDelSection}>
            <div className={classes.cancel} onClick={onClose}>
              OK
            </div>
          </section>
        </div>
      </Box>
    </Modal>
  );
};
