import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(theme => ({
  x: {
    cursor: 'pointer',
    position: 'absolute',
    color: theme.palette.greyMid.mid,
    width: '2rem',
    right: '0.5rem',
  },
  cardNoShadow: {
    position: 'relative',
    boxShadow: 'none',
    width: '13.4rem',
    height: '12.2rem',
    backgroundColor: theme.palette.background.default,
    fontFamily: theme.typography.default,
    marginRight: '1rem',
  },
  content: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  flag: {
    fontSize: '2.5rem',
    width: 'fit-content',
  },
  code: {
    fontSize: '1.6rem',
    fontWeight: 'bold',
  },
  desc: {
    marginTop: '1rem',
  },
}));
