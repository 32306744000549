import React from 'react';

import { PairsCancelIcon, PairsSaveIcon } from '../../../icons/pairs';
import { useStyles } from './fees-inputs.hooks';

export const FeesInputs = ({
  setStartValue,
  setEndValue,
  setPercentage,
  setAbsValue,
  saveInputs,
  cancel,
  startValue,
  endValue,
  percentage,
  absValue,
  isActive,
}) => {
  const classes = useStyles();

  const preventMinus = event => {
    if (event.code === 'Minus') event.preventDefault();
  };

  return (
    <div className={classes.feeInputRow}>
      <aside className={classes.feesInputContainer}>
        <div
          className={
            isActive ? classes.valueContainer : `${classes.valueContainer} ${classes.disabledText}`
          }
        >
          <p>Start Value</p>
          <input
            type="number"
            value={startValue}
            min="0"
            onKeyPress={preventMinus}
            disabled={!isActive}
            onChange={event => {
              setStartValue(event.target.value);
            }}
          />
          <div className={classes.valueRhs} />
        </div>
        <div
          className={
            isActive ? classes.valueContainer : `${classes.valueContainer} ${classes.disabledText}`
          }
        >
          <p>End Value</p>
          <input
            type="number"
            value={endValue}
            min="0"
            onKeyPress={preventMinus}
            disabled={!isActive}
            onChange={event => setEndValue(event.target.value)}
          />
          <div className={classes.valueRhs} />
        </div>
        <div
          className={
            isActive ? classes.valueContainer : `${classes.valueContainer} ${classes.disabledText}`
          }
        >
          <p>Percentage</p>
          <input
            type="number"
            value={percentage}
            min="0"
            onKeyPress={preventMinus}
            disabled={!isActive}
            onChange={event => setPercentage(event.target.value)}
          />
          <div className={classes.valueRhsPercentage}>%</div>
        </div>
        <div
          className={
            isActive ? classes.valueContainer : `${classes.valueContainer} ${classes.disabledText}`
          }
        >
          <p>Absolute Value</p>
          <input
            type="number"
            value={absValue}
            min="0"
            onKeyPress={preventMinus}
            disabled={!isActive}
            onChange={event => setAbsValue(event.target.value)}
          />
          <div className={classes.valueRhs} />
        </div>
      </aside>
      <aside className={classes.btnContainer}>
        <button
          type="button"
          className={
            isActive
              ? `${classes.defaultBtnStyle} ${classes.btnSave}`
              : `${classes.defaultBtnStyle} ${classes.btnDisabled}`
          }
          onClick={saveInputs}
          disabled={!isActive}
        >
          <PairsSaveIcon active={isActive} />
          Save
        </button>
        <button
          type="button"
          className={
            isActive
              ? `${classes.defaultBtnStyle} ${classes.btnCancel}`
              : `${classes.defaultBtnStyle} ${classes.btnDisabled}`
          }
          onClick={cancel}
          disabled={!isActive}
        >
          <PairsCancelIcon active={isActive} />
          Cancel
        </button>
      </aside>
    </div>
  );
};
