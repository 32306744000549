import React from 'react';

export const SideRectangle = ({ color }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="8" height="40" viewBox="0 0 8 40">
    <path
      data-name="Rectangle 16839"
      d="M0 0a8 8 0 0 1 8 8v24a8 8 0 0 1-8 8V0z"
      style={{ fill: color }}
    />
  </svg>
);
