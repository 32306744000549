import { routingFields } from '../beneficiary-requirements.constants';
import {
  HIDDEN_VALUE,
  MANDATORY_VALUE,
  requirementStatuses,
  requirementStatusesEnum,
  VISIBLE_VALUE,
} from './requirement-field.constants';

const getRequirementFieldSliderValue = requirementField => {
  if (!requirementField || requirementField.status === requirementStatusesEnum.HIDDEN) {
    return HIDDEN_VALUE;
  }

  if (requirementField.status === requirementStatusesEnum.MANDATORY) {
    return MANDATORY_VALUE;
  }

  return VISIBLE_VALUE;
};

const getFieldRequirement = ({ country, requirements, currency, requirementFieldName }) => {
  const currencyRequirements = requirements[currency];
  const isRoutingCode = !!routingFields.find(f => f === requirementFieldName);
  const fieldIndex = currencyRequirements.findIndex(
    req => req.field === requirementFieldName || req.value === requirementFieldName
  );

  let fieldRequirement = { field: requirementFieldName, value: '' };

  if (fieldIndex >= 0) {
    [fieldRequirement] = currencyRequirements.splice(fieldIndex, 1);
  }

  if (country === 'CAD' && requirementFieldName === 'branch_code') {
    fieldRequirement = { field: 'routing_code_type_2', value: requirementFieldName };
  }

  if (isRoutingCode) {
    fieldRequirement = { field: 'routing_code_type_1', value: requirementFieldName };
  }

  return { fieldRequirement, fieldIndex };
};

const getFieldRequirementWithUpdatedStatus = ({
  fieldRequirement,
  requirements,
  currency,
  fieldIndex,
  value,
}) => {
  const currencyRequirements = requirements[currency];

  fieldRequirement.status = requirementStatuses[value];
  currencyRequirements.splice(fieldIndex, 0, fieldRequirement);

  return requirements;
};

export { getFieldRequirement, getFieldRequirementWithUpdatedStatus };

export { getRequirementFieldSliderValue };
