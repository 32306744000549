import React from 'react';

import { Currencies, Fees, Rates } from '.';

/**
 * This component is rendered if user clicks on 'Setup' tab on Pairs entity.
 * Parent component is PairsTable
 * @param {Array} data array of pairs data to render of type Pair
 * @param {function} handleRefreshData this eventually calles the fetchEntity() in the useGraphList() custom hook
 * @param {Array} rates array of {amount: float, from: string, to: string} the amount
 * represents the flat rate
 * @param {function} refreshFlatRates this calls the getFlatRates() in the PairsTable parent
 * component
 */
export const SetupPage = ({ data, handleRefreshData, rates, refreshFlatRates }) => (
  <>
    <Currencies data={data} handleRefreshData={handleRefreshData} />
    <Rates
      data={data}
      handleRefreshData={handleRefreshData}
      rates={rates}
      refreshFlatRates={refreshFlatRates}
    />
    <Fees data={data} handleRefreshData={handleRefreshData} />
  </>
);
