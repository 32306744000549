import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import React from 'react';

import { RedDeleteIcon } from '../../../../icons/pairs';
import { useStyles } from './delete-modal.hooks';

export const DeleteModal = ({ cancel, deleteIt, onClose, open }) => {
  const classes = useStyles();

  return (
    <Modal open={open} onClose={onClose}>
      <Box>
        <div className={classes.deleteModal}>
          <div className={classes.redDeleteIcon}>
            <RedDeleteIcon />
          </div>
          <header>Delete configuration</header>
          <p>Do you want to delete the configuration?</p>
          <section className={classes.cancelDelSection}>
            <div className={classes.cancel} onClick={cancel}>
              Cancel
            </div>
            <div className={classes.deleteIt} onClick={deleteIt}>
              Delete it
            </div>
          </section>
        </div>
      </Box>
    </Modal>
  );
};
