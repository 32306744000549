import { TableCell, TableRow } from '@mui/material';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { uid } from 'react-uid';

import { entitiesEnum } from '../../../../constants';
import {
  compareByCurrencyName,
  compareByDate,
  filterTransactionData,
  getKycInfo,
  sortTransactionData,
} from '../../../../utils';
import { addCreatedAtField } from '../../../../utils/fields.utils';
import { IntercomButton, SumsubButton } from '../../../social-buttons';
import { KycStatusFlags } from '../kyc-status-flags';
import { Row } from '../row';
import { CommonTableCell, TransactionRow } from './components';
import { useStyles } from './generic-table-body.hooks';

/**
 * GenericTableBody component that renders the entity data in the TableBody of the GenericTable parent
 * component.
 * @param {boolean} error - flag that indicates an error occurred.
 * @param {Object[]} data - the array of entity data to render
 * @param {Object[]} tableFields - the array of table fields related to the entity data
 * @param {string} entity - name of the entity. A full list is in the
 * entitiesEnum Object in src/constants/entities.js
 * @param {function} onClickedTransactionRow - this will navigate to a detailed page of the transaction passing
 * in the id.
 * @param {boolean} isSearchMode - flag indicating whether user is in search mode for transactions
 */
export const GenericTableBody = ({
  error,
  data,
  tableFields,
  entity,
  onClickedTransactionRow,
  isSearchMode,
}) => {
  const classes = useStyles();
  const isUserprofileEntity = entity === entitiesEnum.USERPROFILE;
  const isTransaction = entity === entitiesEnum.TRANSACTION;
  const isCurrency = entity === entitiesEnum.CURRENCY;
  const transactionFilterObj = useSelector(state => state.transactionFilterObj);
  const transactionSort = useSelector(state => state.transactionSort);
  const dispatch = useDispatch();

  let tableBodyData = data.length ? [...data] : [];

  useEffect(() => {
    dispatch({ type: 'SET_TABLE_DATA_TO_EXPORT', payload: tableBodyData });
  }, [tableBodyData]);

  if (error) {
    return <Row value={`Error: ${error}`} />;
  }

  const tableColumns = [...tableFields];

  if (isTransaction) {
    // don't show transactions that have status as 'started' because it isn't necessarily confirmed
    tableBodyData = tableBodyData?.filter(item => item?.status !== 'started');

    if (!isSearchMode) {
      // if there are transactions between Kixy users then these are duplicated but shown other way round
      const duplicatedTransactions = [];
      tableBodyData.forEach(item => {
        if (!!item.userFrom && !!item.userTo && item.type !== 'convert') {
          const duplicatedItem = { ...item };
          if (item.type === 'send_money') duplicatedItem.type = 'add_money';
          if (item.type === 'add_money') duplicatedItem.type = 'send_money';

          duplicatedTransactions.push(duplicatedItem);
        }
      });

      tableBodyData = tableBodyData.concat(duplicatedTransactions);
    }

    tableBodyData.sort(compareByDate());
    addCreatedAtField(tableColumns);

    if (Object.keys(transactionFilterObj).length !== 0) {
      // Filter the tableBodyData here
      tableBodyData = filterTransactionData(tableBodyData, transactionFilterObj, isSearchMode);
    }

    if (transactionSort) {
      // Sort the tableBodyData here
      tableBodyData = sortTransactionData(tableBodyData, transactionSort);
    }
  }

  if (isCurrency) tableBodyData.sort(compareByCurrencyName);

  if (tableBodyData.length === 0) return <p className={classes.noDataTag}>No data</p>;

  return (
    <>
      {tableBodyData.map((rowData, index) => {
        const { applicantId, kycLink } = getKycInfo(rowData?.kyc);

        return (
          <TableRow
            className={isTransaction ? classes.transactionsTableRow : classes.tableRow}
            key={uid(rowData, [index])}
          >
            {isUserprofileEntity && (
              <TableCell className={classes.tableCell}>
                <IntercomButton
                  profileUrl={rowData.intercomProfileUrl}
                  accountStatus={rowData.accountStatus}
                />
                <SumsubButton profileUrl={kycLink} disabled={!applicantId} />
              </TableCell>
            )}

            {!isTransaction &&
              tableColumns.map(tableField => (
                <CommonTableCell
                  key={uid(tableField)}
                  tableField={tableField}
                  entity={entity}
                  data={rowData}
                />
              ))}

            {isTransaction && (
              <TransactionRow rowData={rowData} onClickedRow={onClickedTransactionRow} />
            )}

            {isUserprofileEntity && (
              <KycStatusFlags
                status={rowData?.kyc?.status}
                result={rowData?.kyc?.result}
                appStatus={rowData?.accountStatus}
                classes={classes}
              />
            )}
          </TableRow>
        );
      })}
    </>
  );
};
