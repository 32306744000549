import React from 'react';

export const InstagramIcon = ({ height = '24px', width = '24px' }) => (
  <svg
    data-name="Group 10179"
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 24 24"
  >
    <path
      data-name="Path 23278"
      d="M12 0A12 12 0 1 1 0 12 12 12 0 0 1 12 0z"
      style={{ fill: '#19365e' }}
    />
    <path
      data-name="Path 23274"
      d="M11.2 4.076a.72.72 0 1 0 .72.72.72.72 0 0 0-.72-.72zm2.76 1.452a4.554 4.554 0 0 0-.276-1.458 2.964 2.964 0 0 0-.7-1.062 2.82 2.82 0 0 0-1.062-.69 4.38 4.38 0 0 0-1.458-.282C9.836 2 9.632 2 8 2s-1.836 0-2.472.036a4.38 4.38 0 0 0-1.458.282 2.868 2.868 0 0 0-1.062.69 2.82 2.82 0 0 0-.69 1.062 4.38 4.38 0 0 0-.282 1.458C2 6.164 2 6.368 2 8s0 1.836.036 2.472a4.38 4.38 0 0 0 .282 1.458 2.82 2.82 0 0 0 .69 1.062 2.868 2.868 0 0 0 1.062.69 4.38 4.38 0 0 0 1.458.282C6.164 14 6.368 14 8 14s1.836 0 2.472-.036a4.38 4.38 0 0 0 1.458-.282 2.82 2.82 0 0 0 1.062-.69 2.91 2.91 0 0 0 .7-1.062 4.554 4.554 0 0 0 .276-1.458c0-.636.036-.84.036-2.472s-.004-1.836-.04-2.472zM12.884 10.4a3.366 3.366 0 0 1-.2 1.116 1.836 1.836 0 0 1-.45.69 1.914 1.914 0 0 1-.69.45 3.366 3.366 0 0 1-1.116.2c-.6.03-.822.036-2.4.036s-1.8 0-2.4-.036a3.438 3.438 0 0 1-1.164-.18 1.962 1.962 0 0 1-.66-.45 1.8 1.8 0 0 1-.444-.69 3.324 3.324 0 0 1-.24-1.14c0-.6-.036-.822-.036-2.4s0-1.8.036-2.4a3.324 3.324 0 0 1 .21-1.14A1.8 1.8 0 0 1 3.8 3.8a1.884 1.884 0 0 1 .66-.48 3.438 3.438 0 0 1 1.14-.2c.6 0 .822-.036 2.4-.036s1.8 0 2.4.036a3.366 3.366 0 0 1 1.116.2 1.836 1.836 0 0 1 .714.48 1.836 1.836 0 0 1 .45.66 3.366 3.366 0 0 1 .2 1.14c.03.6.036.822.036 2.4s-.002 1.8-.032 2.4zM8 4.922a3.072 3.072 0 1 0 2.182.9A3.078 3.078 0 0 0 8 4.922zM8 10a2 2 0 1 1 2-2 2 2 0 0 1-2 2z"
      transform="translate(4 4)"
      style={{ fill: '#1fe3ac' }}
    />
  </svg>
);
