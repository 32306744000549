import React from 'react';

export const DeleteFeesIcon = ({ active = true }) => {
  const fillColor = active ? '#fc3333' : '#a5a6ae';

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24.003"
      height="23.999"
      viewBox="0 0 24.003 23.999"
    >
      <path
        data-name="Union 503"
        d="M11943.979-2123h-2.653a3.643 3.643 0 0 1-3.755-3.125c-.151-1.213-.19-2.437-.271-3.661q-.388-5.849-.763-11.7c-.011-.2-.083-.241-.274-.234a37.03 37.03 0 0 1-1.414 0 .886.886 0 0 1-.847-.792.871.871 0 0 1 .691-.918 2.1 2.1 0 0 1 .429-.036q3.013-.011 6.029-.007c.181 0 .249-.04.238-.223-.018-.3-.007-.6-.007-.9a2.39 2.39 0 0 1 2.451-2.373 159.81 159.81 0 0 1 4.324 0 2.406 2.406 0 0 1 2.419 1.955 5.939 5.939 0 0 1 .036 1.094c.007.443 0 .443.457.443q2.971.005 5.943.014a.9.9 0 0 1 .987.889.9.9 0 0 1-.983.861q-.665.011-1.325 0c-.155 0-.223.032-.229.194-.029.566-.073 1.131-.111 1.7q-.211 3.116-.426 6.232c-.165 2.362-.35 4.72-.493 7.081a3.7 3.7 0 0 1-3.877 3.521q-2.3.005-4.587 0c-.668-.015-1.326-.015-1.989-.015zm-5.6-18.393q.2 2.873.386 5.742l.367 5.663c.079 1.2.144 2.391.241 3.582a1.884 1.884 0 0 0 2.016 1.653q4.617.005 9.23 0a3.075 3.075 0 0 0 .4-.032 1.832 1.832 0 0 0 1.609-1.652q.27-4.293.529-8.59c.133-2.135.263-4.27.406-6.4.018-.241-.061-.281-.291-.277-2.441.007-12.193.047-14.628.04h-.018c-.212-.007-.259.064-.245.264zm10.159-2.092c.184 0 .245-.043.234-.223-.018-.281 0-.565 0-.85 0-.414-.212-.612-.665-.612q-1.238-.005-2.481 0h-1.728c-.465 0-.662.2-.666.641 0 .281.011.565 0 .85-.007.155.047.195.209.195.853-.007 1.71 0 2.566 0h1.45c.357-.016.721-.01 1.083-.005zm-3.459 14.9q-.006-4.66 0-9.314a.858.858 0 0 1 .169-.515.955.955 0 0 1 1.016-.349.892.892 0 0 1 .662.867v9.285a.906.906 0 0 1-.926.922.9.9 0 0 1-.919-.899z"
        transform="translate(-11933.999 2147)"
        style={{ fill: fillColor }}
      />
    </svg>
  );
};
